import ExpandIcon from '../../../icons/controls/ExpandIcon';
import classNames from 'clsx';
import TextField from '@mui/material/TextField';
import React, { forwardRef } from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useIsOpen from '../../../hooks/useIsOpen';
import PopperUnstyled from '@mui/base/PopperUnstyled/PopperUnstyled';

const useStyles = makeStyles(theme => ({
  autocompleteContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  autocompleteRoot: {
    position: 'relative',
  },
  autocompleteExpandIcon: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    transition: 'transform 0.2s ease-in-out',
  },
  autocompleteCloseIcon: {},
  clearIndicator: {
    display: 'none',
  },
}));

const Autocomplete = forwardRef(
  (
    {
      name,
      options,
      InputProps,
      isOptionEqualToValue,
      value,
      disabled,
      blurOnSelect,
      chips,
      fullWidth,
      getOptionLabel,
      filterOptions,
      loading,
      withExpandIcon = true,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const { isOpen, open, close } = useIsOpen();
    //
    // const optionValue =
    //   value && getOptionSelected
    //     ? options.find(item => getOptionSelected(item, value))
    //     : value;

    return (
      <div className={classes.autocompleteContainer}>
        <MuiAutocomplete
          ref={ref}
          name={name}
          autoComplete={true}
          blurOnSelect={blurOnSelect}
          isOptionEqualToValue={isOptionEqualToValue}
          filterOptions={filterOptions}
          PopperComponent={popperProps => (
            <PopperUnstyled
              {...popperProps}
              data-cy={`select-popper-${InputProps.name}`}
              data-loading={loading}
            />
          )}
          popupIcon={
            withExpandIcon && (
              <ExpandIcon
                className={classNames(classes.autocompleteExpandIcon, {
                  [classes.autocompleteCloseIcon]: !isOpen,
                })}
              />
            )
          }
          classes={{
            root: classes.autocompleteRoot,
            clearIndicator: classes.clearIndicator,
          }}
          value={value}
          onOpen={open}
          onClose={close}
          open={isOpen}
          options={options}
          fullWidth={fullWidth}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          renderInput={params => (
            <TextField
              {...params}
              margin={'dense'}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                style: {
                  overflow: 'hidden',
                },
              }}
              {...InputProps}
            />
          )}
          sx={{
            '& .MuiInputLabel-root': {
              overflow: 'hidden',
              lineHeight: '15.5px',
            },
          }}
          {...props}
        />
        {chips}
      </div>
    );
  }
);

export default Autocomplete;
