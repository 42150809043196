import React from 'react';
import { SvgIcon } from '@mui/material';

const DesktopRetailIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 12 18'} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V7.5V12.5V18H12V12.5V7.5V1C12 0.447715 11.5523 0 11 0H1ZM1.5 1.0002C1.22386 1.0002 1 1.22405 1 1.5002V7.5002C1 7.77634 1.22386 8.0002 1.5 8.0002H10.5C10.7761 8.0002 11 7.77634 11 7.5002V1.5002C11 1.22405 10.7761 1.0002 10.5 1.0002H1.5ZM1 9.5C1 9.22386 1.22386 9 1.5 9H10.5C10.7761 9 11 9.22386 11 9.5V16H1V9.5ZM6.5 10C6.22386 10 6 10.2239 6 10.5V11.5C6 11.7761 6.22386 12 6.5 12H9.5C9.77614 12 10 11.7761 10 11.5V10.5C10 10.2239 9.77614 10 9.5 10H6.5Z"
      />
    </SvgIcon>
  );
};

export default DesktopRetailIcon;
