import { gql } from '@apollo/client';

const getImages = gql`
  query getImages($input: ImageFilters) {
    data: getImages(input: $input) {
      items {
        id
        name
      }
      total
    }
  }
`;

export default getImages;
