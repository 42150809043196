import { isArray, isObject } from 'lodash';

function mergeDeep(target, source) {
  for (const key in source) {
    if (isArray(source[key])) {
      if (!target[key]) {
        target[key] = [];
      }
      target[key] = mergeArrays(target[key], source[key]);
    } else if (isObject(source[key])) {
      if (!target[key]) {
        target[key] = {};
      }
      mergeDeep(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
}

function mergeArrays(targetArray, sourceArray) {
  const resultArray = [...targetArray];

  sourceArray.forEach(sourceItem => {
    if (isObject(sourceItem) && !isArray(sourceItem)) {
      let found = false;
      for (let i = 0; i < resultArray.length; i++) {
        if (isObject(resultArray[i]) && !isArray(resultArray[i])) {
          mergeDeep(resultArray[i], sourceItem);
          found = true;
          break;
        }
      }
      if (!found) {
        resultArray.push(sourceItem);
      }
    } else {
      if (!resultArray.includes(sourceItem)) {
        resultArray.push(sourceItem);
      }
    }
  });

  return resultArray;
}

export function mergeObjectsArray(objectsArray) {
  return objectsArray.reduce((acc, obj) => {
    mergeDeep(acc, obj);
    return acc;
  }, {});
}
