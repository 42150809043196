import { connect } from 'react-redux';
import { compose } from 'recompose';
import TopMenu from '../components/TopMenu';
import { getGlobalLoading } from '../reducers/data';
import { showThemeModal } from '../actions/modalActions';
import withRouter from '../utils/withRouter';

const mapStateToProps = (state, { routes }) => ({
  loading: getGlobalLoading(state, routes),
});

const TopMenuContainer = compose(
  withRouter,
  connect(mapStateToProps, { showThemeModal })
)(TopMenu);

export default TopMenuContainer;
