import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  booleanField: {
    width: 12,
    height: 12,
    background: '#C2CAD5',
    borderRadius: '50%',
    margin: '0 auto',
  },

  booleanFieldActive: {
    background: '#3FDE91',
  },
});

const BooleanField = ({ active }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.booleanField, {
        [classes.booleanFieldActive]: active,
      })}
    />
  );
};

export default BooleanField;
