import { cloneElement, memo, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import usePrevious from '../../../hooks/usePrevious';
import makeStyles from '@mui/styles/makeStyles';
import InlineFieldSuccessIndicator from '../../icons/InlineFieldSuccessIndicator';
import { animated, config, useTransition } from 'react-spring';

const useStyles = makeStyles({
  inlineField: {
    position: 'relative',
    width: '100%',
  },
  inlineFieldIndicator: {
    position: 'absolute',
    right: 4,
    top: 7,

    '&>svg': {
      color: 'transparent',
      fontSize: '16px',
    },
  },
});

const InlineFormField = ({ name, children, ...props }) => {
  const classes = useStyles();
  const {
    control,
    defaultValues,
    formState: { errors },
  } = useFormContext();

  const [updatedIndicator, setUpdatedIndicator] = useState(false);

  const defaultValue = defaultValues[name];

  const previousDefaultValue = usePrevious(defaultValue);

  const hasError = errors && errors[name];

  const transitions = useTransition(updatedIndicator, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
    immediate: updatedIndicator,
    onRest: () => {
      setTimeout(() => {
        setUpdatedIndicator(false);
      }, 1000);
    },
  });

  useEffect(() => {
    if (
      !hasError &&
      typeof previousDefaultValue !== 'undefined' &&
      typeof defaultValue !== 'undefined' &&
      previousDefaultValue !== defaultValue
    ) {
      setUpdatedIndicator(true);
    }
  }, [defaultValue, previousDefaultValue, hasError]);

  const renderField = useCallback(
    ({ field }) => {
      return cloneElement(children, {
        ...field,
        error: hasError,
      });
    },
    [children, hasError]
  );

  return (
    <div className={classes.inlineField}>
      <Controller
        {...props}
        name={name}
        control={control}
        render={renderField}
      />
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              className={classes.inlineFieldIndicator}
              style={styles}
            >
              <InlineFieldSuccessIndicator />
            </animated.div>
          )
      )}
    </div>
  );
};

export default memo(InlineFormField);
