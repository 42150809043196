import React from 'react';
import CardContent from '@mui/material/Card';

export function ArrayFieldTemplate(props) {
  return (
    <CardContent style={{ paddingBottom: 0 }}>
      {props.items.map(element => {
        return element.children;
      })}
    </CardContent>
  );
}
