import { useMemo } from 'react';
import TableExportActionCard from '../components/TableActionCards/TableExportActionCardV2';
import { ActionCardTypeEnum } from '../../components/ActionCards';

const useExportActionCard = () => {
  return useMemo(
    () => ({
      type: ActionCardTypeEnum.EXPORT,
      key: ActionCardTypeEnum.EXPORT,
      span: 2,
      height: 56,
      render: style => <TableExportActionCard style={style} />,
    }),
    []
  );
};

export default useExportActionCard;
