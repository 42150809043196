import TextField from '../../components/Inputs/TextField';
import Card from '../../components/Card/Card';
import { useContext, useMemo, useState } from 'react';
import { RoutesContext } from '../../../providers/RoutesProvider';
import { getTableColumns } from '../../../uiComponents/Table/TableUtils';
import { getLinksAndSchemas } from '../../../utils/schemaHelper';
import get from 'lodash/get';
import CodeInput from '../../../components/inputs/CodeInput';
import { styled } from '@mui/system';

const ContentCard = styled(Card)`
  display: grid;
  gap: 16px;
  grid-template:
    'a a'
    'b c';
`;

const PathField = styled(TextField)`
  grid-area: a;
`;

const SchemaCode = styled(CodeInput)`
  grid-area: b;
`;

const ColumnsCode = styled(CodeInput)`
  grid-area: c;
`;

const V2MigratePage = () => {
  const [path, setPath] = useState(
    '/api/v1/ReporterAPI/report/events/detail/{event_id}/players/get'
  );

  const routes = useContext(RoutesContext);

  const [schema, uiSchema, columns] = useMemo(() => {
    if (!path) return [];
    const { schema, uiSchema, links } = getLinksAndSchemas(routes, path);

    const propertiesSchema = get(schema, 'properties.items', schema);

    const columns = getTableColumns(propertiesSchema, uiSchema, null, []);

    return [schema, uiSchema, columns];
  }, [routes, path]);

  const schemaFields = Object.keys(
    get(schema, 'properties.items.items.properties')
  );

  const renderedFields = columns.map(item => item.name);

  return (
    <ContentCard>
      <PathField
        name={'path'}
        label={'Route Path'}
        onChange={e => {
          setPath(e.target.value);
        }}
        value={path}
      />

      <SchemaCode value={JSON.stringify(schemaFields, null, 2)} />
      <ColumnsCode value={JSON.stringify(renderedFields, null, 2)} />
    </ContentCard>
  );
};

export default V2MigratePage;
