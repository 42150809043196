import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRoutes } from './RoutesProvider';
import {
  getFilters,
  getPathByOperationId,
  toEndpoint,
} from '../utils/schemaHelper';
import {
  useApiDelete,
  useApiGet,
  useApiPatch,
  useApiPost,
  useApiPut,
} from '../hooks/useApi';
import EditPresetDialogProvider from './EditPresetDialogProvider';
import CreatePresetDialogProvider from './CreatePresetDialogProvider';
import omit from 'lodash/omit';
import getDefaultValue from '../utils/prepareSchema/getDefaultValue';

export const PresetsProviderContext = createContext({
  loading: false,
  error: true,
  activePreset: null,
  presets: [],
});

export const usePresets = () => {
  return useContext(PresetsProviderContext);
};

const usePresetsByOperationId = operationId => {
  const [presets, setPresets] = useState();
  const routes = useRoutes();

  const presetsListPath = getPathByOperationId(routes, 'Presets.presets_list');

  const createPresetPath = getPathByOperationId(
    routes,
    'Presets.create_preset'
  );

  const updatePresetPath = getPathByOperationId(
    routes,
    'Presets.update_preset'
  ); // preset_id

  const updateDefaultPresetPath = getPathByOperationId(
    routes,
    'Presets.update_default_preset'
  );

  const deletePresetPath = getPathByOperationId(
    routes,
    'Presets.delete_preset'
  ); // preset_id

  const reorderPresetsPath = getPathByOperationId(
    routes,
    'Presets.reorder_presets'
  );

  const presetsListEndpoint = toEndpoint({
    path: presetsListPath,
    params: {
      operation_id: operationId,
    },
  });

  const updateDefaultPresetEndpoint = toEndpoint({
    path: updateDefaultPresetPath,
    params: {
      operation_id: operationId,
    },
  });

  const createPresetEndpoint = toEndpoint({ path: createPresetPath });

  const reorderPresetsEndpoint = toEndpoint({ path: reorderPresetsPath });

  const fetchPresetsApi = useApiGet(presetsListEndpoint);
  const createPresetApi = useApiPost();
  const reorderPresetsApi = useApiPost();
  const savePresetApi = useApiPut();
  const saveDefaultPresetApi = useApiPatch();
  const deletePresetApi = useApiDelete();

  const fillDefaultPreset = useCallback(
    preset => {
      const path = getPathByOperationId(routes, operationId);
      const routeParameters = routes[path].parameters;

      const filters = getFilters(routeParameters);

      const defaultValues = filters.reduce((defaultValues, f) => {
        defaultValues[f.name] = getDefaultValue(f.schema);
        return defaultValues;
      }, {});

      return {
        ...preset,
        data: {
          ...preset.data,
          filters: {
            ...defaultValues,
          },
          initial_filters: {
            ...defaultValues,
          },
        },
      };
    },
    [routes, operationId]
  );

  const fetchPresets = useCallback(async () => {
    fetchPresetsApi()
      .then(data => {
        if (data && data.items) {
          setPresets(
            data.items.map(item => {
              if (item.is_default) {
                return fillDefaultPreset(
                  data.items.find(item => item.is_default === true)
                );
              }

              return item;
            })
          );
        } else {
          throw new Error('Error loading presets');
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [fetchPresetsApi, setPresets, fillDefaultPreset]);

  const savePreset = useCallback(
    (preset_id, data) => {
      const endpoint = toEndpoint({
        path: updatePresetPath,
        params: {
          preset_id,
        },
      });

      return savePresetApi({
        url: endpoint,
        data: omit(data, [
          'id',
          'user_id',
          'operation_id',
          'order',
          'is_deleted',
          'is_default',
          'data.initial_filters',
        ]),
        throwError: true,
      });
    },
    [savePresetApi, updatePresetPath]
  );

  const saveDefaultPreset = useCallback(
    data => {
      return saveDefaultPresetApi(updateDefaultPresetEndpoint, {
        data: {
          rows_per_page: data.data.rows_per_page,
          columns_width: data.data.columns_width,
        },
      });
    },
    [saveDefaultPresetApi, updateDefaultPresetEndpoint]
  );

  const deletePreset = useCallback(
    preset_id => {
      const endpoint = toEndpoint({
        path: deletePresetPath,
        params: {
          preset_id,
        },
      });

      return deletePresetApi(endpoint);
    },
    [deletePresetApi, deletePresetPath]
  );

  const createPreset = useCallback(
    data => {
      return createPresetApi({
        url: createPresetEndpoint,
        data,
        throwError: true,
      });
    },
    [createPresetApi, createPresetEndpoint]
  );

  const reorderPresets = useCallback(
    ids => {
      return reorderPresetsApi({
        url: reorderPresetsEndpoint,
        data: {
          items: ids,
          operation_id: operationId,
        },
      });
    },
    [reorderPresetsApi, operationId, reorderPresetsEndpoint]
  );

  return useMemo(
    () => ({
      createPreset,
      reorderPresets,
      fetchPresets,
      presets,
      savePreset,
      deletePreset,
      saveDefaultPreset,
    }),
    [
      savePreset,
      reorderPresets,
      fetchPresets,
      presets,
      createPreset,
      saveDefaultPreset,
      deletePreset,
    ]
  );
};

const PresetsProvider = ({ operationId, children }) => {
  const {
    fetchPresets,
    createPreset,
    presets: persistedPresets,
    savePreset,
    deletePreset,
    reorderPresets: reorderPresetsApi,
    saveDefaultPreset,
  } = usePresetsByOperationId(operationId);

  const [state, setState] = useState({
    activePresetId: '0',
    presets: persistedPresets,
  });

  const activePresetId = state.activePresetId;

  useEffect(() => {
    fetchPresets();
  }, [fetchPresets]);

  useEffect(() => {
    if (persistedPresets) {
      setState({
        activePresetId: '0',
        presets: persistedPresets,
      });
    }
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedPresets]);

  const setActivePresetId = useCallback(activePresetId => {
    setState(currentState => {
      return {
        ...currentState,
        activePresetId,
      };
    });
  }, []);

  const updatePreset = useCallback(
    (id, data) => {
      setState(state => {
        return {
          ...state,
          presets: state.presets.map(preset => {
            if (preset.id === id) {
              return {
                ...preset,
                ...data,
              };
            }
            return preset;
          }),
        };
      });

      if (!data.is_default) {
        return savePreset(id, data);
      } else {
        return saveDefaultPreset(data);
      }
    },
    [saveDefaultPreset, savePreset, setState]
  );

  const reorderPresets = useCallback(
    presets => {
      setState(state => ({
        ...state,
        presets,
      }));

      return reorderPresetsApi(presets.map(preset => preset.id));
    },
    [reorderPresetsApi, setState]
  );

  const removePreset = useCallback(
    id => {
      setState(state => ({
        activePresetId:
          state.activePresetId === id ? '0' : state.activePresetId,
        presets: state.presets.filter(preset => preset.id !== id),
      }));

      return deletePreset(id);
    },
    [deletePreset]
  );

  const providerValue = useMemo(() => {
    return {
      reorderPresets,
      updatePreset,
      fetchPresets,
      activePresetId,
      activePreset: state.presets
        ? state.presets.find(item => item.id === state.activePresetId)
        : null,
      presets: state.presets,
      savePreset,
      setActivePresetId,
      removePreset,
      createPreset,
      loading: !state.presets,
    };
  }, [
    activePresetId,
    reorderPresets,
    updatePreset,
    savePreset,
    fetchPresets,
    state,
    setActivePresetId,
    createPreset,
    removePreset,
  ]);

  return (
    <PresetsProviderContext.Provider value={providerValue}>
      <EditPresetDialogProvider>
        <CreatePresetDialogProvider operationId={operationId}>
          {children}
        </CreatePresetDialogProvider>
      </EditPresetDialogProvider>
    </PresetsProviderContext.Provider>
  );
};

export default PresetsProvider;
