import VirtualizedTable from '../../components/Table/VirtualizedTable';
import TableCell from '../../components/Table/TableCell';
import pick from 'lodash/pick';
import TableHeaderColumn from '../../components/Table/TableHeaderColumn';
import WatchField from '../../components/Fields/WatchField/WatchField';
import StringField from '../../components/Fields/StringField/StringField';
import getCountryName from '../../utils/getCountryName';
import CopyField from '../../components/Fields/CopyField/CopyField';
import LinkField from '../../components/Fields/LinkField/LinkField';
import BetSelectionsField from '../../components/Fields/BetSelectionsField/BetSelectionsField';
import CurrencyField, {
  formatCurrency,
} from '../../components/Fields/CurrencyField/CurrencyField';
import EmptyField from '../../components/Fields/EmptyField/EmptyField';
import BadgeField from '../../components/Fields/BadgeField/BadgeField';
import LiveIcon from '../../../icons/controls/LiveIcon';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import TableConfigMenu from '../../components/Table/TableConfigMenu';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import Tabs from '../../components/Tabs/Tabs';
import Grid from '@mui/material/Grid';
import LabeledField from '../../components/Fields/LabeledField/LabeledField';
import useTableGrid from '../../hooks/useTableGrid';
import Tooltip from '../../components/Tooltip/Tooltip';
import CountryIcon from '../../icons/CountryIcon/CountryIcon';
import SportIcon from '../../icons/SportIcon/SportIcon';
import DeviceIcon from '../../icons/DeviceIcon/DeviceIcon';
import ExpandableTable from '../../components/Table/ExpandableTable';
import TableRow from '../../components/Table/TableRow';
import { expandCellStyles } from '../../components/Table/TableExpandCell';
import TableBulkCell, {
  bulkCellStyles,
} from '../../components/Table/TableBulkCell';
import TableStickyHeader from '../../components/Table/TableStickyHeader';
import InfiniteTable from '../../components/Table/InfiniteTable';
import TabContent from '../../components/Tabs/TabContent';
import EventPrematchIcon from '../../../icons/controls/EventPrematchIcon';
import EventPrematchPendingIcon from '../../../icons/controls/EventPrematchPendingIcon';
import DoubleSelectionsField from './components/DoubleSelectionsField';
import { Box } from '@mui/material';
import { format, toDate } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const commonCellStyle = {
  padding: '0px 4px',
};

const BET_STATUS_LABEL = {
  CASHED_OUT: 'Cashed Out',
};

export const COLUMN = {
  WATCH: 'watch',
  DATE_TIME: 'date_time',
  COUNTRY: 'country',
  DEVICE: 'device',
  USERNAME: 'username',
  EXT_PLAYER_ID: 'ext_player_id',
  CCF: 'ccf',
  BRAND_ID: 'brand_id',
  BET_TYPE: 'bet_type',
  SPORT: 'sport',
  TOURNAMENT: 'tournament',
  CATEGORY: 'category',
  EVENT_ID: 'event_id',
  MARKET: 'market',
  MARKET_PROVIDERS: 'market_providers',
  OUTCOME: 'outcome',
  TYPE: 'type',
  ODDS: 'odds',
  STAKE_EUR: 'stake_EUR',
  BONUS: 'bonus',
  OPERATOR_BONUS: 'operator_bonus',
  STATUS: 'status',
  BET_ID: 'bet_id',
  SCORE: 'score',
  DELAY: 'delay',
  RELIABILITY_COEFFICIENT: 'reliability_coefficient',
  NET_WIN_EUR: 'net_win_EUR',
  INFO: 'info',
};

export const columnsWidthConfig = {
  [COLUMN.WATCH]: 1,
  [COLUMN.DATE_TIME]: 1,
  [COLUMN.COUNTRY]: 1,
  [COLUMN.DEVICE]: 1,
  [COLUMN.USERNAME]: 1,
  [COLUMN.EXT_PLAYER_ID]: 1,
  [COLUMN.CCF]: 1,
  [COLUMN.BRAND_ID]: 1,
  [COLUMN.BET_TYPE]: 1,
  [COLUMN.SPORT]: 1,
  [COLUMN.TOURNAMENT]: 1,
  [COLUMN.CATEGORY]: 1,
  [COLUMN.EVENT_ID]: 1,
  [COLUMN.MARKET]: 1,
  [COLUMN.OUTCOME]: 1,
  [COLUMN.TYPE]: 1,
  [COLUMN.ODDS]: 1,
  [COLUMN.SCORE]: 1,
  [COLUMN.STAKE_EUR]: 1,
  [COLUMN.NET_WIN_EUR]: 1,
  [COLUMN.BONUS]: 1,
  [COLUMN.OPERATOR_BONUS]: 1,
  [COLUMN.STATUS]: 1,
  [COLUMN.BET_ID]: 1,
  [COLUMN.MARKET_PROVIDERS]: 1,
  [COLUMN.DELAY]: 1,
  [COLUMN.RELIABILITY_COEFFICIENT]: 1,
  [COLUMN.INFO]: 1,
};

export const adminColumns = [
  COLUMN.DELAY,
  COLUMN.RELIABILITY_COEFFICIENT,
  COLUMN.MARKET_PROVIDERS,
];

export const columnsOrderConfig = isOperator => {
  const order = [
    COLUMN.WATCH,
    COLUMN.DATE_TIME,
    COLUMN.COUNTRY,
    COLUMN.DEVICE,
    COLUMN.USERNAME,
    COLUMN.EXT_PLAYER_ID,
    COLUMN.CCF,
    COLUMN.DELAY,
    COLUMN.RELIABILITY_COEFFICIENT,
    COLUMN.BRAND_ID,
    COLUMN.BET_TYPE,
    COLUMN.SPORT,
    COLUMN.TOURNAMENT,
    COLUMN.CATEGORY,
    COLUMN.EVENT_ID,
    COLUMN.MARKET,
    COLUMN.MARKET_PROVIDERS,
    COLUMN.OUTCOME,
    COLUMN.SCORE,
    COLUMN.TYPE,
    COLUMN.ODDS,
    COLUMN.STAKE_EUR,
    COLUMN.NET_WIN_EUR,
    COLUMN.BONUS,
    COLUMN.OPERATOR_BONUS,
    COLUMN.STATUS,
    COLUMN.BET_ID,
    COLUMN.INFO,
  ];

  if (isOperator) {
    return order.filter(column => adminColumns.indexOf(column) === -1);
  }

  return order;
};

export const initialToggledColumns = [
  COLUMN.WATCH,
  COLUMN.DATE_TIME,
  COLUMN.COUNTRY,
  COLUMN.USERNAME,
  COLUMN.EXT_PLAYER_ID,
  COLUMN.CCF,
  COLUMN.DELAY,
  COLUMN.RELIABILITY_COEFFICIENT,
  COLUMN.BRAND_ID,
  COLUMN.BET_TYPE,
  COLUMN.SPORT,
  COLUMN.TOURNAMENT,
  COLUMN.CATEGORY,
  COLUMN.EVENT_ID,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.SCORE,
  COLUMN.TYPE,
  COLUMN.ODDS,
  COLUMN.STAKE_EUR,
  COLUMN.NET_WIN_EUR,
  COLUMN.BONUS,
  COLUMN.OPERATOR_BONUS,
  COLUMN.STATUS,
  COLUMN.BET_ID,
  COLUMN.MARKET_PROVIDERS,
  COLUMN.INFO,
];

//  device, external player id, bet type, virtual, bet id, status
const BetstreamTable = forwardRef(
  (
    {
      order,
      columnsWidth,
      onToggleColumns,
      toggledColumns: initialToggledColumns,
      data,
      loading,
      rowActions,
      onWatchBet,
      onChangeColumnsWidth,
      onReorder,
      isOperator,
      tableOffsetTop,
      setPagination,
    },
    ref
  ) => {
    const [toggledColumns, toggleColumn] = useTableColumnToggle(
      initialToggledColumns,
      onToggleColumns
    );

    const { template, changeColumnWidth } = useTableGrid({
      columns: columnsWidth,
      order,
      toggledColumns,
      onChangeColumnsWidth,

      expandable: true,
      rowActions: true,
    });

    const [selectionColumnsWidth, setSelectionColumnsWidth] = useState();

    const onWidthChange = useCallback((key, { width }) => {
      setSelectionColumnsWidth(current => {
        return {
          ...current,
          [key]: width,
        };
      });
    }, []);

    // const infoColumn = ;

    const selectionColumns = useMemo(() => {
      return [
        {
          label: 'Tournament',
          key: 'tournament',
          style: commonCellStyle,
          keepMinWidth: true,
          resizable: true,
          render: ({ tournament }) => {
            return <StringField title={tournament}>{tournament}</StringField>;
          },
          onWidthChange,
        },
        {
          label: 'Category',
          key: 'category',
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          render: ({ category }) => {
            return <StringField title={category}>{category}</StringField>;
          },
          onWidthChange,
        },
        {
          label: 'Event',
          key: 'event_id',
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          render: ({ event, event_id }) => {
            return (
              <CopyField value={event_id}>
                <LinkField
                  title={event}
                  href={`/events/${event_id}`}
                  target={'_blank'}
                >
                  {event}
                </LinkField>
              </CopyField>
            );
          },
          onWidthChange,
        },
        {
          label: 'Market',
          key: 'market',
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          render: ({ market }) => {
            return <StringField title={market}>{market}</StringField>;
          },
          onWidthChange,
        },
        {
          label: 'Market providers',
          key: COLUMN.MARKET_PROVIDERS,
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          render: ({ market_providers = [] }) => {
            const marketProviders = market_providers?.join(', ');

            return (
              <StringField title={marketProviders}>
                {marketProviders}
              </StringField>
            );
          },
          onWidthChange,
        },
        {
          label: 'Outcome',
          key: 'outcome',
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          render: ({ outcome }) => {
            return <StringField title={outcome}>{outcome}</StringField>;
          },
          onWidthChange,
        },
        {
          label: 'Type',
          key: 'type',
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          render: ({ type, event_scheduled, ...rest }) => {
            if (type && event_scheduled) {
              let component;

              const currentTs = Math.floor(Date.now() / 1000);
              const diffTs = event_scheduled - currentTs;
              const UTCDate = format(
                utcToZonedTime(toDate(event_scheduled * 1000), 'UTC'),
                'dd.MM.yyyy HH:mm'
              );

              if (type === 'live') {
                component = <LiveIcon />;
              } else {
                // less than 30 minutes
                if (diffTs <= 30 * 60) {
                  component = <EventPrematchPendingIcon />;
                }

                // more than 8 hours
                if (diffTs >= 8 * 60 * 60) {
                  component = <EventPrematchIcon />;
                }
              }

              return (
                <Tooltip title={UTCDate}>
                  <Box>{component}</Box>
                </Tooltip>
              );
            }
            return '-';
          },
          onWidthChange,
        },
        {
          label: 'Odds',
          key: 'odds',
          style: {
            ...commonCellStyle,
            justifyContent: 'flex-end',
            textAlign: 'right',
          },
          resizable: true,
          keepMinWidth: true,
          render: ({ odds }) => (
            <StringField
              number
              bold
              title={odds}
              style={{
                zIndex: 1,
              }}
            >
              {odds}
            </StringField>
          ),
          onWidthChange,
        },
        {
          label: 'Score',
          key: 'score',
          style: {
            ...commonCellStyle,
            justifyContent: 'center',
            textAlign: 'center',
          },
          resizable: true,
          keepMinWidth: true,
          render: ({ score }) => (
            <StringField number bold>
              {score}
            </StringField>
          ),
          onWidthChange,
        },
      ]
        .map(column => {
          return {
            ...column,
            toggled: toggledColumns.indexOf(column.key) !== -1,
          };
        })
        .sort((a, b) => {
          const aIndex = order.indexOf(a.key);
          const bIndex = order.indexOf(b.key);

          if (aIndex < bIndex) {
            return -1;
          }

          if (aIndex > bIndex) {
            return 1;
          }

          return 0;
        });
    }, [order, toggledColumns, onWidthChange]);

    const betSelectionsColumns = useMemo(() => {
      return [
        {
          label: 'Spt',
          key: 'sport',
          resizable: true,
          style: commonCellStyle,
          render: selection => {
            return (
              <Tooltip title={selection?.sport}>
                <SportIcon name={selection?.sport_id} />
              </Tooltip>
            );
          },
        },
        ...selectionColumns.filter(column => column.toggled),
      ];
    }, [selectionColumns]);

    const columns = useMemo(() => {
      const result = [
        {
          divider: false,
          label: 'Watch',
          key: 'watch',
          deps: ['watch', 'bet_id'],
          resizable: true,
          render: ({ bet_id, watch }) => (
            <WatchField
              toggled={!!watch}
              onToggle={toggled => onWatchBet(bet_id, toggled)}
            />
          ),
          style: {
            ...commonCellStyle,
            justifyContent: 'center',
            textAlign: 'center',
          },
        },
        {
          label: 'Time',
          key: 'date_time',
          style: commonCellStyle,
          resizable: true,
          deps: ['date_time'],
          render: ({ date_time }) => (
            <StringField title={date_time} number>
              {date_time}
            </StringField>
          ),
        },
        {
          label: 'Country',
          key: 'country',
          resizable: true,
          style: {
            ...commonCellStyle,
            justifyContent: 'center',
            textAlign: 'center',
          },
          render: ({ country }) =>
            country ? (
              <Tooltip title={getCountryName(country) || country}>
                <CountryIcon name={country} />
              </Tooltip>
            ) : (
              <EmptyField />
            ),
        },
        {
          label: 'Device',
          key: 'device',
          style: {
            ...commonCellStyle,
            justifyContent: 'center',
            textAlign: 'center',
          },
          resizable: true,
          deps: ['source'],
          render: ({ source }) => <DeviceIcon name={source} />,
        },
        {
          label: 'Player',
          key: 'username',
          style: commonCellStyle,
          cellClassName: ({
            statistics,
            ccf,
            segment_reviewed,
            deep_monitoring,
          }) => {
            if (deep_monitoring) {
              return 'columnFillBlack';
            }

            if (segment_reviewed) {
              if (ccf > 0 && ccf < 0.2) {
                return 'columnFillRed';
              }

              if (ccf >= 0.2 && ccf <= 0.5) {
                return 'columnFillOrange';
              }

              if (ccf > 1) {
                return 'columnFillGreen';
              }
            } else {
              if (statistics?.bets >= 1 && statistics?.bets <= 40) {
                return 'columnFillBlue';
              }

              if (statistics?.bets > 40 && statistics?.bets <= 100) {
                return 'columnFillBlueLight';
              }

              if (statistics?.bets > 100) {
                if (statistics?.ggr >= -1000 && statistics?.ggr <= 1000) {
                  return 'columnFillWhite';
                }

                if (statistics?.ggr >= 1000 || ccf > 1) {
                  return 'columnFillGreen';
                }

                if (statistics?.ggr < -1000 || ccf === 1) {
                  return 'columnFillYellow';
                }

                return 'columnFillGrey';
              }
            }
          },
          resizable: true,
          deps: [
            'username',
            'player_id',
            // 'statistics.ggr',
            // 'statistics.margin',
            // 'statistics.bets',
          ],
          render: ({ player_id, username, statistics, rm_comment }) => {
            const margin = statistics?.margin
              ? (statistics?.margin * 100).toFixed(2)
              : 0;
            const ggr = statistics?.ggr ? statistics?.ggr?.toFixed(2) : 0;

            return (
              <CopyField value={player_id}>
                <Tooltip
                  title={
                    <>
                      GGR: {ggr}, Margin: {margin}%, Bets: {statistics?.bets}
                      {rm_comment ? (
                        <>
                          <br />
                          Comment: {rm_comment}
                        </>
                      ) : null}
                    </>
                  }
                >
                  <LinkField
                    href={`/api/v1/OperatorAPI/players/v3/${player_id}/get`}
                    target={'_blank'}
                  >
                    {username}
                  </LinkField>
                </Tooltip>
              </CopyField>
            );
          },
        },

        {
          label: 'Ext Player Id',
          key: 'ext_player_id',
          style: commonCellStyle,
          resizable: true,
          render: ({ ext_player_id, bookmaker_id, limit_id, player_id }) => (
            <CopyField value={ext_player_id}>
              {isOperator ? (
                <StringField title={ext_player_id}>{ext_player_id}</StringField>
              ) : (
                <LinkField
                  title={ext_player_id}
                  target={'_blank'}
                  href={`//mtsc-its.betradar.com/admin/customer?view=customerInfo&standalone=0&sourceId=${bookmaker_id}:${limit_id}::::${player_id}&reportType=summary_customer&sourceName=${player_id}`}
                >
                  {ext_player_id}
                </LinkField>
              )}
            </CopyField>
          ),
        },
        {
          label: 'CCF',
          key: 'ccf',
          style: {
            ...commonCellStyle,
            justifyContent: 'center',
            textAlign: 'center',
          },
          resizable: true,
          deps: ['ccf'],
          render: ({ ccf }) => <StringField number>{ccf}</StringField>,
        },
        {
          label: 'Delay',
          key: 'delay',
          style: commonCellStyle,
          resizable: true,
          deps: ['delay'],
          render: ({ delay }) => <StringField number>{delay}</StringField>,
        },
        {
          label: 'Reliability coefficient',
          key: COLUMN.RELIABILITY_COEFFICIENT,
          style: commonCellStyle,
          resizable: true,
          deps: ['reliability_coefficient'],
          render: ({ reliability_coefficient, ...props }) => {
            return <StringField>{reliability_coefficient}</StringField>;
          },
        },
        {
          label: 'Brand',
          key: 'brand_id',
          style: commonCellStyle,
          resizable: true,
          deps: ['brand'],
          render: ({ brand, brand_id, operator }) => (
            <CopyField value={brand_id}>
              <Tooltip title={`Brand: ${brand}, Operator: ${operator}`}>
                <StringField>{brand}</StringField>
              </Tooltip>
            </CopyField>
          ),
        },
        {
          label: 'Bet Type',
          key: 'bet_type',
          style: commonCellStyle,
          resizable: true,
          deps: ['bet_type'],
          render: ({ bet_type }) => (
            <StringField title={bet_type}>{bet_type}</StringField>
          ),
        },
        {
          divider: ({ sport }) => {
            return sport?.length <= 2;
          },
          label: 'Spt',
          key: 'sport',
          style: commonCellStyle,
          resizable: true,
          keepMinWidth: true,
          // deps: ['sport'],
          columnWidthDeps: ({ sport: selections }) =>
            selections?.length !== 1 && [
              COLUMN.SPORT,
              COLUMN.TOURNAMENT,
              COLUMN.CATEGORY,
              COLUMN.EVENT_ID,
              COLUMN.MARKET,
              COLUMN.MARKET_PROVIDERS,
              COLUMN.OUTCOME,
              COLUMN.TYPE,
              COLUMN.SCORE,
              COLUMN.ODDS,
            ],
          onWidthChange,
          render: ({ sport: selections, odds }, d, columnWidth) => {
            if (selections?.length === 1) {
              const selection = selections[0];
              return (
                <Tooltip title={selection.sport}>
                  <SportIcon name={selection.sport_id} />
                </Tooltip>
              );
            }

            if (selections?.length === 2) {
              return (
                <DoubleSelectionsField
                  selection1={selections[0]}
                  selection2={selections[1]}
                  render={({ sport, sport_id }) => {
                    return (
                      <Tooltip title={sport}>
                        <SportIcon name={sport_id} />
                      </Tooltip>
                    );
                  }}
                />
              );
            }

            return (
              <BetSelectionsField
                oddsTotal={odds}
                selections={selections || []}
                columns={betSelectionsColumns}
                columnWidth={columnWidth}
              />
            );
          },
        },
        ...selectionColumns.reduce((acc, column) => {
          return [
            ...acc,
            {
              ...column,
              divider: ({ sport }) => {
                return sport?.length <= 2;
              },
              render: ({ sport, ...rowData }) => {
                if (sport && sport?.length > 2) {
                  if (column.key === 'odds') {
                    return column.render(rowData);
                  }

                  return null;
                }

                const selection1 = sport && sport[0];

                if (sport?.length === 1) {
                  return column.render(selection1);
                }

                const selection2 = sport && sport[1];

                return (
                  <DoubleSelectionsField
                    selection1={selection1}
                    selection2={selection2}
                    render={column.render}
                  />
                );
              },
            },
          ];
        }, []),
        {
          label: 'Stake EUR',
          key: 'stake_EUR',
          style: {
            ...commonCellStyle,
            justifyContent: 'flex-end',
            textAlign: 'right',
          },
          resizable: true,
          render: ({ stake_EUR, currency, stake }) => {
            if (stake && stake_EUR && currency) {
              return (
                <Tooltip title={formatCurrency(stake, currency)}>
                  <CurrencyField currency={''} amount={stake_EUR} number bold />
                </Tooltip>
              );
            }
            return '-';
          },
        },
        {
          label: 'Net Win EUR',
          key: 'net_win_EUR',
          style: {
            ...commonCellStyle,
            justifyContent: 'flex-end',
            textAlign: 'right',
          },
          resizable: true,
          render: ({ net_win_EUR, net_win, currency }) => {
            if (net_win && net_win_EUR && currency) {
              return (
                <Tooltip title={formatCurrency(net_win, currency)}>
                  <CurrencyField
                    currency={'EUR'}
                    amount={net_win_EUR}
                    number
                    bold
                  />
                </Tooltip>
              );
            }
            return '-';
          },
        },
        {
          label: 'Bonus',
          key: 'bonus',
          style: commonCellStyle,
          resizable: true,
          render: ({ bonus_type, bonus, bonus_id }) => {
            if (!bonus || !bonus_type || !bonus_id) {
              return <EmptyField />;
            }

            return (
              <CopyField value={bonus_id}>
                <Tooltip title={bonus}>
                  <LinkField
                    href={`/api/v1/BonusAdmin/bonus/${bonus_id}/get`}
                    target={'_blank'}
                  >
                    <BadgeField
                      green={
                        bonus_type === 'freebet' ||
                        bonus_type === 'comboboost' ||
                        bonus_type === 'boosted_odds'
                      }
                    >
                      {bonus_type}
                    </BadgeField>
                  </LinkField>
                </Tooltip>
              </CopyField>
            );
          },
        },
        {
          label: 'Operator bonus',
          key: COLUMN.OPERATOR_BONUS,
          style: commonCellStyle,
          resizable: true,
          render: ({ operator_bonus }) => {
            if (operator_bonus) {
              return (
                <Tooltip title={operator_bonus}>
                  <BadgeField blue>Operator Bonus</BadgeField>
                </Tooltip>
              );
            }
            return <EmptyField />;
          },
        },
        {
          label: 'Status',
          key: 'status',
          style: commonCellStyle,
          resizable: true,
          render: ({ status, bet_status, reject_reason, reject_code }) => (
            <Tooltip title={`${bet_status} ${reject_reason}`}>
              <BadgeField red={status === 'rejected'}>
                {BET_STATUS_LABEL[status?.toUpperCase()] || status}

                {reject_code ? ` (${reject_code})` : null}
              </BadgeField>
            </Tooltip>
          ),
        },
        {
          label: 'Bet Id',
          key: 'bet_id',
          style: {
            ...commonCellStyle,
          },
          resizable: true,
          render: ({ bet_id }) => (
            <CopyField value={bet_id}>
              <LinkField
                title={bet_id}
                target={'_blank'}
                href={`/api/v1/BetSlipsAdmin/ch/bet/${bet_id}/get`}
              >
                {bet_id}
              </LinkField>
            </CopyField>
          ),
        },
        {
          label: 'Info',
          key: 'info',
          style: {
            ...commonCellStyle,
          },
          resizable: true,
          render: ({ ...props }) => {
            const betInfo = `${props.bet_status}, ${props.sport?.[0]?.type}, ${props.sport?.[0]?.sport}, ${props.stake_EUR} eur\n${props.sport?.[0]?.category}/${props.sport?.[0]?.tournament}/${props.sport?.[0]?.event}\n${props.sport?.[0]?.market}, ${props.sport?.[0]?.outcome}\nOur odd ${props.sport?.[0]?.odds} vs`;

            return props.sport?.length === 1 ? (
              <CopyField value={betInfo}>
                <StringField>Get Info</StringField>
              </CopyField>
            ) : (
              <StringField>Not available</StringField>
            );
          },
        },
      ].map(column => {
        return {
          ...column,
          toggled: toggledColumns.indexOf(column.key) !== -1,
        };
      });

      return order
        .map(columnKey => {
          const found = result.find(item => item.key === columnKey);

          if (!found) {
            console.log('not found', columnKey);
          }

          return found;
        })
        .filter(item => item);
    }, [
      order,
      selectionColumns,
      toggledColumns,
      onWatchBet,
      onWidthChange,
      betSelectionsColumns,
      isOperator,
    ]);

    const renderHeaderRow = useMemo(() => {
      const filteredColumns = columns.filter(column => column.toggled);
      return (
        <>
          <TableStickyHeader
            className={'tableHeaderRow'}
            style={{
              gridTemplateColumns: template,
            }}
          >
            <TableHeaderColumn style={expandCellStyles} />
            <>
              {filteredColumns.map(
                (
                  { label, keepMinWidth, resizable, style, key, onWidthChange },
                  index
                ) => {
                  return (
                    <TableHeaderColumn
                      key={key}
                      columnKey={key}
                      label={label}
                      style={style}
                      isLast={index === filteredColumns.length - 1}
                      resizable={resizable}
                      onResize={changeColumnWidth}
                      keepMinWidth={keepMinWidth}
                      onWidthChange={onWidthChange}
                    />
                  );
                }
              )}
            </>
            <TableHeaderColumn style={commonCellStyle}>
              {(() => (
                <TableConfigMenu
                  columns={columns}
                  onToggleColumn={toggleColumn}
                  toggledColumns={toggledColumns}
                  onReorder={onReorder}
                />
              ))()}
            </TableHeaderColumn>
          </TableStickyHeader>
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      columns,
      toggleColumn,
      changeColumnWidth,
      onReorder,
      template,
      toggledColumns,
    ]);

    const getRowClassName = useCallback(rowData => {
      return clsx({
        rowFillGrey: rowData.status === 'rejected',
        rowFillYellow: rowData.status === 'cashed_out',
        doubleRow: rowData.sport?.length === 2,
      });
    }, []);

    const recomputeRowHeight = useCallback(
      () => ref && ref.current && ref.current.recalculateSizes(),
      [ref]
    );

    const loadRows = useCallback(
      (offset, limit) => {
        setPagination(curr => ({
          ...curr,
          offset,
        }));
      },
      [setPagination]
    );

    const renderCells = useCallback(
      rowData => {
        const rowClassName = getRowClassName(rowData);

        const filteredColumns = columns.filter(column => column.toggled);

        return filteredColumns.map((column, index) => {
          const widthDeps =
            typeof column.columnWidthDeps === 'function'
              ? column.columnWidthDeps(rowData)
              : column.columnWidthDeps;

          return (
            <TableCell
              divider={
                typeof column.divider === 'function'
                  ? column.divider(rowData)
                  : column.divider !== false
              }
              isLast={index === filteredColumns.length - 1}
              key={column.key}
              deps={column.deps}
              rowData={rowData}
              cellClassName={column.cellClassName}
              rowClassName={rowClassName}
              render={column.render}
              style={column.style}
              keepMinWidth={column.keepMinWidth}
              columnWidthDeps={
                widthDeps && pick(selectionColumnsWidth, widthDeps)
              }
            />
          );
        });
      },
      [selectionColumnsWidth, columns, getRowClassName]
    );

    const renderRow = useCallback(
      ({ rowIndex, rowData, rowId, isVisible }) => {
        return (
          <TableRow
            gridTemplate={template}
            rowId={rowId}
            rowIndex={rowIndex}
            rowData={rowData}
            className={getRowClassName(rowData)}
            renderCells={renderCells}
            rowActions={rowActions}
            isVisible={isVisible}
            bulk={false}
            recomputeRowHeight={recomputeRowHeight}
          />
        );
      },
      [getRowClassName, rowActions, template, recomputeRowHeight, renderCells]
    );

    const renderExpandedRow = useCallback(
      (rowIndex, { statistics, vip_request_result }) => {
        const { comment, resolved_by, result_type } = vip_request_result || {};
        const { ggr, bets, margin, payout, turnover } = statistics || {};

        return (
          <Tabs
            tabs={[
              {
                label: 'Statistics',
                content: (
                  <TabContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <LabeledField label={'Turnover'}>
                          <StringField>{turnover?.toFixed(3)}</StringField>
                        </LabeledField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <LabeledField label={'Payout'}>
                          <StringField>{payout?.toFixed(3)}</StringField>
                        </LabeledField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <LabeledField label={'GGR'}>
                          <StringField>{ggr?.toFixed(3)}%</StringField>
                        </LabeledField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <LabeledField label={'Margin'}>
                          <StringField>{margin?.toFixed(3)}</StringField>
                        </LabeledField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <LabeledField label={'Bets'}>
                          <StringField>{bets}</StringField>
                        </LabeledField>
                      </Grid>
                    </Grid>
                  </TabContent>
                ),
              },
              {
                label: 'Vip Request Result',
                content: (
                  <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <LabeledField label={'Comment'}>
                        {comment ? (
                          <StringField>{comment}</StringField>
                        ) : (
                          <EmptyField />
                        )}
                      </LabeledField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <LabeledField label={'Resolved By'}>
                        {resolved_by ? (
                          <StringField>{resolved_by}</StringField>
                        ) : (
                          <EmptyField />
                        )}
                      </LabeledField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <LabeledField label={'Result Type'}>
                        {result_type ? (
                          <StringField>{result_type}</StringField>
                        ) : (
                          <EmptyField />
                        )}
                      </LabeledField>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        );
      },
      []
    );

    const isRowLoaded = useCallback(
      ({ index }) => {
        return !!data[index];
      },
      [data]
    );

    return (
      <ExpandableTable
        id={COLUMN.BET_ID}
        ref={ref}
        loading={loading}
        renderHeaderRow={renderHeaderRow}
        renderRow={renderRow}
        getRowClassName={getRowClassName}
        renderExpandedRow={renderExpandedRow}
        data={data}
        rowActions={rowActions}
        tableOffsetTop={tableOffsetTop}
        persistToggledOnListChange
      >
        <InfiniteTable
          loadRows={loadRows}
          isRowLoaded={isRowLoaded}
          rowCount={1000}
        >
          <VirtualizedTable />
        </InfiniteTable>
      </ExpandableTable>
    );
  }
);

export default BetstreamTable;
