import { gql } from '@apollo/client';
import marginLadderFragment from '../fragments/marginLadderFragment';

const createMarginLadderMutation = gql`
  ${marginLadderFragment}
  mutation createMarginLadder($ladder: MarginLadderInput!) {
    createMarginLadder(ladder: $ladder) {
      ...MarginLadderFragment
    }
  }
`;

export default createMarginLadderMutation;
