import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  loadingIcon: {
    boxShadow: theme.shadows[1],
    backgroundColor: 'currentColor',
    width: 48,
    height: 48,
    top: -12,
    left: -12,
    borderRadius: '50%',
    position: 'relative',
    transform: 'scale(0.5)',
  },
  loadingIconProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -14,
    marginLeft: -14,
  },
}));

const Switch = ({
  value,
  onChange = () => {},
  loading = false,
  disabled = false,
  color,
  className,
}) => {
  const classes = useStyles();

  const LoadingIcon = () => (
    <span className={classes.loadingIcon}>
      <CircularProgress size={28} className={classes.loadingIconProgress} />
    </span>
  );

  return (
    <MuiSwitch
      classes={{}}
      checked={value}
      color={color}
      onChange={e => onChange(e.target.checked)}
      disabled={disabled || loading}
      {...(loading && {
        icon: <LoadingIcon />,
        checkedIcon: <LoadingIcon />,
      })}
      className={className}
    />
  );
};

export default Switch;
