import Menu from '@mui/material/Menu';
import React, { forwardRef } from 'react';

const ContextMenu = forwardRef(
  ({ children, keepMounted = true, style, ...props }, ref) => {
    return (
      <Menu
        ref={ref}
        PaperProps={{
          style: style,
        }}
        {...props}
        disablePortal={false}
        keepMounted={keepMounted}
      >
        {children}
      </Menu>
    );
  }
);

export default ContextMenu;
