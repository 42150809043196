import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const CREATE_EVENT = gql`
  ${eventModelFragment}
  mutation createEvent(
    $fixture: FixtureInput!
    $status: CreateEventStatus!
    $ladderId: LadderId
  ) {
    createEvent(fixture: $fixture, status: $status, ladder_id: $ladderId) {
      ...EventModelFragment
    }
  }
`;

export default CREATE_EVENT;
