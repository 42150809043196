import React from 'react';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',

    '&>*': {
      color: '#031224',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.2px',
    },
  },
};

const ValueContainer = ({ children, classes }) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(ValueContainer);
