import { ActionCardTypeEnum } from '../../components/ActionCards';
import TablePresetsActionCard from '../components/TableActionCards/TablePresetsActionCard';
import Presets from '../components/Presets/Presets';
import { useCallback, useMemo, useState } from 'react';
import useLocalStorageTableConfig, {
  tableConfigKeys,
  tableNames,
} from './useLocalStorageTableConfig';

const usePresetsActionCard = ({
  tableName = tableNames.GenericTable,
  spanMin = 2,
  spanMax = 4,
}) => {
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(tableName);
  const configIsOpenedValue = getTableConfigValue(
    tableConfigKeys.isPresetsOpened
  );

  const [isOpened, setOpened] = useState(configIsOpenedValue);

  const onChangeExpanded = useCallback(
    expanded => {
      setOpened(expanded);
      setTableConfigValue(tableConfigKeys.isPresetsOpened, expanded);
    },
    [setOpened, setTableConfigValue]
  );

  return useMemo(
    () => ({
      type: ActionCardTypeEnum.PRESETS,
      key: ActionCardTypeEnum.PRESETS,
      span: isOpened ? spanMax : spanMin,
      render: (style, onChangeHeight) => (
        <TablePresetsActionCard
          isOpen={isOpened}
          onChange={onChangeExpanded}
          onChangeHeight={onChangeHeight}
          style={style}
        >
          <Presets tableName={tableName} />
        </TablePresetsActionCard>
      ),
    }),
    [isOpened, onChangeExpanded, spanMax, spanMin, tableName]
  );
};

export default usePresetsActionCard;
