import { gql } from '@apollo/client';

const disableAutocreatableTournaments = gql`
  mutation disableAutocreatableTournaments(
    $autocreatableTournaments: [DisableAutocreatableTournament!]!
  ) {
    disableAutocreatableTournaments(
      autocreatableTournaments: $autocreatableTournaments
    )
  }
`;

export default disableAutocreatableTournaments;
