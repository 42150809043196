import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import useConfirmationPrompt from '../../hooks/useConfirmationPrompt';
import classNames from 'clsx';
import MoveIcon from '../../icons/controls/MoveIcon';
import PresetCardMenu from './PresetCardMenu';
import { useEditPresetDialog } from '../../providers/EditPresetDialogProvider';

const useStyles = makeStyles({
  presetCardRoot: {
    whiteSpace: 'initial',
    display: 'inline-block',
    paddingRight: 16,
    marginBottom: 16,
    position: 'relative',
    '&:last-of-type': {
      marginRight: 0,
    },
    '&:hover:not($presetInactiveEffects)': {
      '& $presetMoveButton': {
        opacity: 1,
      },
    },
    '&:not($presetInactiveEffects)': {
      '& $presetCard': {
        '&:hover:not($presetCardActive)': {
          borderColor: '#D0E6FF',
          cursor: 'pointer',
        },
      },

      '& $presetMenuButton': {
        '&:hover': {
          color: '#1581FF',
          backgroundColor: '#E0EBF8',
        },
      },
    },
  },
  presetCardRootDragging: {
    '& $presetCard': {
      boxShadow: '0px 4px 16px rgba(132, 150, 171, 0.5)',
    },
    '& $presetMoveButton': {
      opacity: 1,
    },
  },
  presetInactiveEffects: {},
  presetCard: {
    height: 60,
    width: 200,
    backgroundColor: '#F7F7F7',
    borderRadius: 11,
    fontWeight: 500,
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 18px 8px 2px',
    position: 'relative',
    border: '2px solid transparent',
    transition: 'border-color 0.2s ease-in',
  },
  presetCardActive: {
    borderColor: '#1581FF',
    cursor: 'default',
  },
  presetCardMarker: {
    height: 44,
    minWidth: 6,
    borderRadius: 8,
    marginRight: 16,
  },
  presetCardName: {
    maxHeight: '100%',
    overflow: 'hidden',
  },
  presetMoveButton: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in',
    color: '#031224',
    padding: 0,
    position: 'absolute',
    left: -14,
    top: 22,
    '& svg': {
      fontSize: '16px',
    },
    '&:hover': {
      backgroundColor: 'initial',
    },
  },

  presetMenuButton: {
    transition: 'color 0.3s ease-in-out',
    color: '#031224',
    padding: 0,
    position: 'absolute',
    right: 18,
    top: 3,
    width: 24,
    height: 24,
    borderRadius: 2,
    borderTopRightRadius: 8,
    '& svg': {
      fontSize: '10px',
    },
    '&:hover': {
      backgroundColor: 'initial',
      cursor: 'initial',
    },
  },
});

const PresetCard = ({
  preset,
  style,
  onRemove,
  onApply,
  isActive,
  draggableProvided,
  draggableSnapshot,
  isDragging,
}) => {
  const classes = useStyles();

  const { openConfirmation } = useConfirmationPrompt();
  const { isDragging: isPresetDragging } = draggableSnapshot;
  const { open: openEditDialog } = useEditPresetDialog();

  const isOtherDragging = isDragging && !isPresetDragging;

  const isDefault = preset.is_default;

  return (
    <div
      className={classNames(classes.presetCardRoot, {
        [classes.presetInactiveEffects]: isOtherDragging,
        [classes.presetCardRootDragging]: isPresetDragging,
      })}
      {...draggableProvided.draggableProps}
      style={draggableProvided.draggableProps.style}
      ref={draggableProvided.innerRef}
    >
      <IconButton
        {...draggableProvided.dragHandleProps}
        classes={{
          root: classes.presetMoveButton,
        }}
        aria-label="close"
        variant={'text'}
        disableRipple
        onClick={() => {}}
        size="large"
      >
        <MoveIcon />
      </IconButton>

      <div
        className={classNames(classes.presetCard, {
          [classes.presetCardActive]: isActive,
        })}
        style={style}
        onClick={() => {
          onApply();
        }}
      >
        <div
          className={classes.presetCardMarker}
          style={{
            backgroundColor: preset.data.color,
          }}
        />
        <span className={classes.presetCardName}>{preset.name}</span>
      </div>

      {!isDefault && (
        <PresetCardMenu
          onRemove={() => {
            openConfirmation({
              buttonLabel: 'Delete',
              title: 'Are you going to remove the preset?',
              message: `Note, that you cannot undo this action and will have to configure it again if needed.`,
              destructive: true,
              callback: async () => {
                onRemove();
              },
            });
          }}
          onEdit={() => {
            openEditDialog(preset);
          }}
          classes={{
            button: classes.presetMenuButton,
          }}
        />
      )}
    </div>
  );
};

export default PresetCard;
