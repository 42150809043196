import React from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  caption: {
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '11px',
    display: 'flex',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    color: '#8496AB',
  },
  valueContainer: {
    marginTop: 4,
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.2px',
  },
});

const LabeledField = ({ label, children, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="caption" className={classes.caption}>
        {label}
      </Typography>
      <div className={classes.valueContainer}>{children}</div>
    </div>
  );
};

export default LabeledField;
