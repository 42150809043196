/* eslint-disable no-undef */
import React from 'react';

import Form from 'react-jsonschema-form';

import FieldTemplate from './templates/FieldTemplate';
import { ObjectFieldTemplate } from './templates/ObjectFieldTemplate';
import { ArrayFieldTemplate } from './templates/ArrayFieldTemplate';
import {
  getDefaultFormState,
  setState,
  toIdSchema,
} from 'react-jsonschema-form/lib/utils';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import validateFormData from './validate';

import fields from './fields';
import widgets from './widgets';

class DForm extends Form {
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  validate(formData, schema) {
    const { validate, transformErrors } = this.props;
    return validateFormData(
      formData,
      schema || this.props.schema,
      validate,
      transformErrors
    );
  }

  getStateFromProps(props) {
    const state = this.state || { validateCounter: 0 };
    const schema = 'schema' in props ? props.schema : this.props.schema;
    const uiSchema = 'uiSchema' in props ? props.uiSchema : this.props.uiSchema;
    const edit = typeof props.formData !== 'undefined';
    const liveValidate = props.liveValidate || this.props.liveValidate;
    const mustValidate =
      edit && !props.noValidate && liveValidate && state.validateCounter > 0;
    const { definitions } = schema;
    const formData = getDefaultFormState(schema, props.formData, definitions);
    let { errors, errorSchema } = mustValidate
      ? this.validate(formData, schema)
      : {
          errors: state.errors || [],
          errorSchema: state.errorSchema || {},
        };
    const idSchema = toIdSchema(
      schema,
      uiSchema['ui:rootFieldId'],
      definitions,
      formData
    );
    // state.validateCounter+=1;
    if (props.errorSchema) {
      errorSchema = props.errorSchema;
    }
    return {
      schema,
      uiSchema,
      idSchema,
      formData,
      edit,
      errors,
      errorSchema,
    };
  }

  onChange = formData => {
    const mustValidate = !this.props.noValidate && this.props.liveValidate;
    let state = { formData };
    if (mustValidate) {
      const { errors, errorSchema } = this.validate(formData);
      state = { ...state, errors, errorSchema };
    }
    setState(this, state, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    });
  };

  onSubmit = event => {
    event.preventDefault();

    if (!this.props.noValidate) {
      const formData = this.state.formData;
      const requiredFields = this.state.schema.required || [];
      Object.keys(formData).map(key => {
        if (requiredFields.includes(key) && formData[key] === '') {
          delete formData[key];
        }
        return key;
      });

      const { errors, errorSchema } = this.validate(this.state.formData);

      if (Object.keys(errors).length > 0) {
        setState(this, { errors, errorSchema }, () => {
          if (this.props.onError) {
            this.props.onError(errors);
          } else {
          }
          console.error('Form validation failed', errors);
        });
        return;
      }
    }

    if (this.props.onSubmit) {
      this.props.onSubmit({
        ...this.state,
        status: 'submitted',
      });
    }

    this.setState({ errors: [], errorSchema: {} });
  };
}

const DynamicForm = props => {
  const formRef = React.createRef();

  const onSubmitHandler = (...args) => {
    return props.onSubmit && props.onSubmit(...args);
  };

  const onErrorHandler = () => {};

  return (
    <DForm
      {...props}
      ref={formRef}
      liveValidate={true}
      noHtml5Validate={true}
      showErrorList={false}
      uiSchema={props.uiSchema}
      fields={fields.default}
      widgets={widgets.default}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      ArrayFieldTemplate={ArrayFieldTemplate}
      onSubmit={onSubmitHandler}
      onError={onErrorHandler}
    >
      <div className={props.classes.actionContainer}>
        <Button type="submit" variant="contained" color="primary">
          Accept
        </Button>
      </div>
    </DForm>
  );
};

const styles = theme => ({
  actionContainer: {
    padding: theme.spacing(2),
  },
});

export default withStyles(styles)(DynamicForm);
