import styles from './index.styles';

import { DialogActions } from '@mui/material';
import Button from '../../../components/Button/Button';

const DialogButtons = ({
  onSubmit,
  onCancel,
  submitTitle = 'Create',
  cancelTitle = 'Cancel',
}) => {
  const classes = styles();

  return (
    <DialogActions className={classes.container}>
      <Button color={'lightBlue'} onClick={onCancel}>
        {cancelTitle}
      </Button>
      <Button onClick={onSubmit}>{submitTitle}</Button>
    </DialogActions>
  );
};
export default DialogButtons;
