import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const calcEventOutcomesMutation = gql`
  ${eventModelFragment}
  mutation calcEventOutcomes($eventId: EventId!, $version: Version!) {
    calcEventOutcomes(event_id: $eventId, version: $version) {
      ...EventModelFragment
    }
  }
`;

export default calcEventOutcomesMutation;
