import { capitalize, get, sortBy } from 'lodash';

//returns data in form to work with drag drop list
export const getItemsForDragList = (
  list,
  nameKey = 'source',
  checkboxKey = 'enabled',
  idKey = 'id',
  priorityKey = 'priority',
  disableKey = 'read_only'
) => {
  return list
    ? sortBy(
        list.map((value, index) => {
          return {
            id: get(value, idKey),
            key: get(value, nameKey),
            label: capitalize(get(value, nameKey) || ''),
            toggled: get(value, checkboxKey),
            priority: get(value, priorityKey) ?? index,
            disabled: get(value, disableKey),
          };
        }),
        ['priority']
      )
    : [];
};
