import { gql } from '@apollo/client';

const GetTournamentsNamesInLine = gql`
  query getTournamentsNamesInLine(
    $query: String!
    $limit: Int
    $offset: Int
    $sportIds: [SportId!]
    $excludeSportIds: Boolean
    $categoriesIds: [CategoryId!]
    $excludeCategoriesIds: Boolean
    $tournamentsIds: [TournamentId!]
  ) @api(name: autocomplete) {
    data: getTournamentsNamesInLine(
      query: $query
      limit: $limit
      offset: $offset
      sportIds: $sportIds
      excludeSportIds: $excludeSportIds
      categoriesIds: $categoriesIds
      excludeCategoriesIds: $excludeCategoriesIds
      tournamentsIds: $tournamentsIds
    ) {
      label: name
      value: id
    }
  }
`;

export default GetTournamentsNamesInLine;
