import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.2px',
  },
};

const Placeholder = ({ innerProps, children, classes }) => (
  <Typography color="textSecondary" className={classes.root} {...innerProps}>
    {children}
  </Typography>
);

export default withStyles(styles)(Placeholder);
