import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  readonly: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  textFieldRoot: {
    '& .MuiInputLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
}));

function BaseInput(props) {
  // Note: since React 15.2.0 we can't forward unknown element attributes, so we
  // exclude the "options" and "schema" ones here.
  const classes = useStyles();

  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    options,
    schema,
    errorSchema,
    formContext,
    registry,
    ...inputProps
  } = props;
  inputProps.type = options.inputType || inputProps.type || 'text';

  let _onChange = ({ target: { value } }) => {
    return onChange(value === '' ? options.emptyValue || value : value);
  };

  let _onBlur = onBlur && (event => onBlur(inputProps.id, event.target.value));
  let _onFocus =
    onFocus && (event => onFocus(inputProps.id, event.target.value));

  if (inputProps.type === 'select') {
    _onChange = onChange;
    _onBlur = onBlur;
    _onFocus = onFocus;
  }

  return readonly ? (
    <div className={classes.readonly}>
      <Typography variant="caption">{inputProps.label}</Typography>
      <Typography>{value}</Typography>
    </div>
  ) : (
    <TextField
      classes={{
        root: classes.textFieldRoot,
      }}
      margin={'dense'}
      variant="outlined"
      fullWidth
      disabled={disabled}
      autoFocus={autofocus}
      value={value == null ? '' : value}
      error={
        !!(errorSchema && errorSchema.__errors && errorSchema.__errors.length)
      }
      helperText={
        (errorSchema && errorSchema.__errors && errorSchema.__errors[0]) ||
        options.help ||
        null
      }
      {...inputProps}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
    />
  );
}

BaseInput.defaultProps = {
  type: 'text',
  required: false,
  disabled: false,
  readonly: false,
  autofocus: false,
};

BaseInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default BaseInput;
