import React, { useCallback, useMemo, useRef } from 'react';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useTablePagination from '../../../../hooks/useTablePagination';

import { useMutation, useQuery } from '@apollo/client';
import RangeDateInput from '../../../../components/Inputs/RangeDateInput';
import Select from '../../../../components/Inputs/Select/Select';
import NotificationAlert, {
  AlertVariant,
} from '../../../../components/Notifications/NotificationAlert';
import TableActionCards from '../../../../components/TableActionCards/TableActionCards';
import revertChanges from '../../../../gql/sportbook-config/change-log/mutations/revertChanges';
import getChangeLog from '../../../../gql/sportbook-config/change-log/queries/getChangeLog';
import useFilters from '../../../../hooks/useFilters';
import { useToasts } from '../../../../providers/ToastsProvider';
import { TEMPLATE_TYPE } from '../../components/TemplateTypeSelect';
import ChangeLogTable from './ChangeLogTable';
import { tableNames } from '../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../components/ErrorWidget';

export const ACTIONS = {
  REMOVED: 'removed',
  ASSIGNED: 'assigned',
  EDITED: 'edited',
  DUPLICATED: 'duplicated',
};

export const LOCATION = {
  ...TEMPLATE_TYPE,
  BRAND_SETTINGS: 'brand_settings',
};

export const defaultFilters = {
  action: null,
  template: null,
  rangeDate: {
    rangeFrom: null,
    rangeTo: null,
  },
};

const ChangeLogTab = ({ lineSettingsId }) => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.ChangeLogTab,
    onChangeLimit: () => {},
  });
  const { showToast } = useToasts();

  const refetchDataRef = useRef(() => {});

  const refetchData = useCallback(() => {
    return refetchDataRef.current();
  }, []);

  const { filtersCard, filters } = useFilters({
    tableName: tableNames.ChangeLogTab,
    resetOffset: setOffset,
    onResubmit: refetchData,
    defaultValues: defaultFilters,
    hideAddButton: true,
    filters: [
      {
        name: 'rangeDate',
        label: 'Date period',
        fullWidth: false,
        component: <RangeDateInput />,
        toggled: true,
      },
      {
        name: 'template',
        label: 'Location',
        component: (
          <Select
            options={[
              {
                label: 'Coverage template',
                value: LOCATION.COVERAGE,
              },
              {
                label: 'Sources template',
                value: LOCATION.SOURCES,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'action',
        label: 'Action',
        component: (
          <Select
            options={[
              {
                label: 'Removed',
                value: ACTIONS.REMOVED,
              },
              {
                label: 'Assigned',
                value: ACTIONS.ASSIGNED,
              },
              {
                label: 'Edited',
                value: ACTIONS.EDITED,
              },
            ]}
          />
        ),
        toggled: true,
      },
    ],
  });

  const queryFilters = useMemo(() => {
    return {
      limit,
      offset,
      ...filters,
    };
  }, [limit, offset, filters]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(getChangeLog, {
    variables: {
      settingsId: lineSettingsId,
      filters: queryFilters,
    },
    fetchPolicy: 'no-cache',
  });

  refetchDataRef.current = refetch;

  const [
    revertChangesCallback,
    { loading: revertChangesLoading, error: revertError },
  ] = useMutation(revertChanges, {
    refetchQueries: ['getChangeLog'],
  });

  const items = useMemo(() => {
    return (data && data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  const actions = useMemo(() => {
    return [filtersCard];
  }, [filtersCard]);

  const onUndo = useCallback(
    rowData => {
      const changeLogId = rowData.id;
      revertChangesCallback({
        variables: {
          changeLogId,
          LineSettingsId: lineSettingsId,
        },
      })
        .then(({ data: { data } = {} }) => {
          if (data) {
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={5000}>
                Changes reverted
              </NotificationAlert>
            );
          } else {
            showToast(
              <NotificationAlert variant={AlertVariant.WARNING} timeout={5000}>
                Changes not reverted
              </NotificationAlert>
            );
          }
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong ...
            </NotificationAlert>
          );
        });
    },
    [lineSettingsId, revertChangesCallback, showToast]
  );

  if (error || revertError) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TableActionCards animated actions={actions} />
      <ChangeLogTable
        data={items}
        loading={loading}
        error={error}
        onUndo={onUndo}
      />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default ChangeLogTab;
