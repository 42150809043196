import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import FancyAutocomplete from '../../components/Inputs/FancyAutocomplete/FancyAutocomplete';
import { COMPETITORS_QUERY } from '../../components/Inputs/RelatedContingencyAutocomplete/CompetitorAutocomplete';
import { MARKETS_QUERY } from '../../components/Inputs/RelatedContingencyAutocomplete/MarketAutocomplete';
import Page from '../../components/Page/Page';
import TableActionCards from '../../components/TableActionCards/TableActionCards';
import TablePagination from '../../components/TablePagination/TablePagination';
import SPORTS_AUTOCOMPLETE_QUERY from '../../gql/RiskManagement/queries/SPORTS_AUTOCOMPLETE_QUERY';
import GetCategoriesNames from '../../gql/RiskManagement/queries/getCategoriesNames';
import GetEventsNames from '../../gql/RiskManagement/queries/getEventsNames';
import GetTournamentsNames from '../../gql/RiskManagement/queries/getTournamentsNames';
import activateRelatedContingency from '../../gql/mutations/activateRelatedContingency';
import createRelatedContingency from '../../gql/mutations/createRelatedContingency';
import deleteRelatedContingencies from '../../gql/mutations/deleteRelatedContingencies';
import RELATED_CONTINGENCIES_QUERY from '../../gql/queries/RELATED_CONTINGENCIES_QUERY';
import usePresetFilters, { withoutEmpty } from '../../hooks/usePresetFilters';
import usePresetsActionCard from '../../hooks/usePresetsActionCard';
import useTableColumnsReorderPreset from '../../hooks/useTableColumnsReorderPreset';
import useTableDataQuery from '../../hooks/useTableDataQuery';
import useTablePresetColumnToggle from '../../hooks/useTablePresetColumnToggle';
import useTablePresetPagination from '../../hooks/useTablePresetPagination';
import RelatedContingenciesForm from './RelatedContingenciesForm';
import RelatedContingenciesTable, {
  columnsOrderConfig,
  initialToggledColumns,
} from './RelatedContingenciesTable';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../components/ErrorWidget';

const RelatedContingenciesPage = () => {
  const presetsActionCard = usePresetsActionCard({
    tableName: tableNames.RelatedContingencies,
  });
  const { limit, setLimit, offset, setOffset } = useTablePresetPagination({
    tableName: tableNames.RelatedContingencies,
  });
  const [tableOffsetTop, setTableOffsetTop] = useState(0);

  const actionCardsRef = useRef(null);

  useResizeObserver({
    ref: actionCardsRef,
    onResize: rect => {
      setTableOffsetTop(rect.height);
    },
  });

  const [toggledColumns, onToggleColumns] = useTablePresetColumnToggle(
    initialToggledColumns
  );

  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorderPreset(columnsOrderConfig);

  const { fetchData, refetchData, items, total, loading, fetchError, error } =
    useTableDataQuery(RELATED_CONTINGENCIES_QUERY);

  const { filtersCard, filters } = usePresetFilters({
    tableName: tableNames.RelatedContingencies,
    resetOffset: setOffset,
    onResubmit: refetchData,
    filters: [
      {
        name: 'sport_ids',
        label: 'Select Sport',
        fullWidth: false,
        component: ({ sport_ids, ...props }) => (
          <FancyAutocomplete
            multiple
            gqlQuery={SPORTS_AUTOCOMPLETE_QUERY}
            proxyValue
            params={{ sport_ids }}
            {...props}
          />
        ),
        toggled: true,
        deps: ['sport_ids'],
      },
      {
        name: 'categories_ids',
        label: 'Select Category',
        fullWidth: false,
        component: ({ sport_ids, categories_ids, ...props }) => (
          <FancyAutocomplete
            multiple
            gqlQuery={GetCategoriesNames}
            proxyValue
            params={{ sport_ids, categories_ids }}
            {...props}
          />
        ),
        toggled: true,
        deps: ['sport_ids', 'categories_ids'],
      },
      {
        name: 'tournaments_ids',
        label: 'Select Tournament',
        fullWidth: false,
        component: ({
          sport_ids,
          categories_ids,
          tournaments_ids,
          ...props
        }) => (
          <FancyAutocomplete
            multiple
            gqlQuery={GetTournamentsNames}
            proxyValue
            params={{ sport_ids, categories_ids, tournaments_ids }}
            {...props}
          />
        ),
        toggled: true,
        deps: ['sport_ids', 'categories_ids', 'tournaments_ids'],
      },
      {
        name: 'events_ids',
        label: 'Select Event',
        fullWidth: false,
        component: ({
          sport_ids,
          categories_ids,
          tournaments_ids,
          ...props
        }) => (
          <FancyAutocomplete
            multiple
            gqlQuery={GetEventsNames}
            proxyValue
            checkValueOptions={false}
            params={{
              sport_ids,
              categories_ids,
              tournaments_ids,
              events_ids: props.value ? [...props.value] : [],
            }}
            {...props}
          />
        ),
        toggled: true,
        deps: ['sport_ids', 'categories_ids', 'tournaments_ids'],
      },
      {
        name: 'markets_ids',
        label: 'Select Market',
        fullWidth: false,
        component: ({ sport_ids, markets_ids, ...props }) => (
          <FancyAutocomplete
            multiple
            gqlQuery={MARKETS_QUERY}
            proxyValue
            params={{ sport_ids, market_ids: markets_ids, limit: 40 }}
            {...props}
          />
        ),
        toggled: true,
        deps: ['sport_ids', 'markets_ids'],
      },
      {
        name: 'competitors_ids',
        label: 'Select Competitor',
        fullWidth: false,
        component: ({
          sport_ids,
          categories_ids,
          tournaments_ids,
          competitors_ids,
          ...props
        }) => (
          <FancyAutocomplete
            multiple
            gqlQuery={COMPETITORS_QUERY}
            proxyValue
            params={{
              sport_ids,
              categories_ids,
              tournaments_ids,
              competitors_ids,
            }}
            {...props}
          />
        ),
        toggled: true,
        deps: [
          'sport_ids',
          'categories_ids',
          'tournaments_ids',
          'competitors_ids',
        ],
      },
    ],
    spanMin: 1,
    spanMax: 4,
  });

  const actions = useMemo(() => {
    return [presetsActionCard, filtersCard];
  }, [presetsActionCard, filtersCard]);

  const navigate = useNavigate();

  const addMissingInputFields = input => {
    const requiredFields = {
      categories_ids: [],
      competitors_ids: [],
      events_ids: [],
      markets_ids: [],
      sport_ids: [],
      tournaments_ids: [],
    };

    return Object.keys(requiredFields).reduce((acc, field) => {
      acc[field] = input[field] || requiredFields[field];
      return acc;
    }, {});
  };

  const queryVariables = useMemo(() => {
    // TODO: ask backend to make all fields in RelatedContingencyFilters type optional, then remove this function
    return {
      ...addMissingInputFields(withoutEmpty(filters)),
      limit,
      offset,
    };
  }, [limit, offset, filters]);

  const presetsLoading = false;

  useEffect(() => {
    if (queryVariables && !presetsLoading) {
      fetchData({
        variables: {
          input: queryVariables,
        },
      });
    }
  }, [queryVariables, fetchData, presetsLoading]);

  const [create, { loading: formLoading, error: createError }] = useMutation(
    createRelatedContingency
  );

  const [activate, { loading: activateLoading, error: activateError }] =
    useMutation(activateRelatedContingency);

  const [remove, { loading: removeLoading, error: removeError }] = useMutation(
    deleteRelatedContingencies
  );

  const rowActions = useMemo(
    () => [
      ({ id, active }) => ({
        key: 'activate',
        label: active ? 'Deactivate' : 'Activate',
        onClick: () => {
          activate({
            variables: {
              id,
              activate: !active,
            },
          }).then(() => {
            refetchData();
          });
        },
      }),
      ({ id }) => ({
        key: 'edit',
        label: 'Edit',
        onClick: () => {
          navigate(`/related-contingencies/${id}`);
        },
      }),
      {
        key: 'delete',
        label: 'Delete',
        onClick: ({ id }) => {
          remove({
            variables: {
              ids: [id],
            },
          }).then(() => {
            refetchData();
          });
        },
        destructive: true,
      },
    ],
    [navigate, activate, refetchData, remove]
  );

  const onCreateRule = useCallback(
    input => {
      return new Promise((resolve, reject) => {
        create({
          variables: {
            input,
          },
        })
          .then(result => {
            resolve(result);
            refetchData();
          })
          .catch(reject);
      });
    },
    [create, refetchData]
  );

  if (error || fetchError || removeError || activateError || createError) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Related Contingencies'}>
      <TableActionCards animated actions={actions} ref={actionCardsRef} />

      <RelatedContingenciesForm loading={formLoading} onSubmit={onCreateRule} />

      <Box mt={2}>
        <RelatedContingenciesTable
          rowActions={rowActions}
          items={items}
          loading={loading}
          order={columnsOrder}
          onReorder={onReorderColumns}
          toggledColumns={toggledColumns}
          onToggleColumns={onToggleColumns}
          tableOffsetTop={tableOffsetTop}
        />
      </Box>

      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </Page>
  );
};

export default RelatedContingenciesPage;
