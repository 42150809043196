import React from 'react';
import DynamicComponent from './DynamicComponent';
import Dialog from '@mui/material/Dialog';

const DynamicModal = ({ path, params, formParams, onClose }) => {
  return (
    <Dialog open onClose={onClose}>
      <DynamicComponent path={path} params={params} formParams={formParams} />
    </Dialog>
  );
};

export default DynamicModal;
