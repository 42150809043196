import React from 'react';
import Box from '@mui/material/Box';

const FormFieldPlaceholder = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '40px',
        borderRadius: '8px',
        backgroundColor: '#F7F7F7',
      }}
    />
  );
};

export default FormFieldPlaceholder;
