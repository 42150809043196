import { gql } from '@apollo/client';

export const SOURCE_ENABLE_TYPE = gql`
  fragment SourceEnableType on SourceEnable {
    source
    enabled
    priority
  }
`;

export const SOURCES_MARKET_NODE_TYPE = gql`
  ${SOURCE_ENABLE_TYPE}
  fragment SourcesMarketNodeType on SourcesMarketNode {
    marketId
    specifier
    name
    prioritySources {
      ...SourceEnableType
    }
    isConfigured
  }
`;

export const SOURCES_EVENT_NODE_TYPE = gql`
  ${SOURCE_ENABLE_TYPE}
  fragment SourcesEventNodeType on SourcesEventNode {
    eventId
    name
    prioritySources {
      ...SourceEnableType
    }
    isConfigured
    scheduled
    total
  }
`;

export const SOURCES_TOURNAMENT_NODE_TYPE = gql`
  ${SOURCE_ENABLE_TYPE}
  fragment SourcesTournamentNodeType on SourcesTournamentNode {
    tournamentId
    name
    prioritySources {
      ...SourceEnableType
    }
    isConfigured
    total
  }
`;

export const SOURCES_CATEGORY_NODE_TYPE = gql`
  ${SOURCE_ENABLE_TYPE}
  fragment SourcesCategoryNodeType on SourcesCategoryNode {
    categoryId
    name
    countryCode
    prioritySources {
      ...SourceEnableType
    }
    isConfigured
    total
  }
`;

export const SOURCES_SPORT_NODE_TYPE = gql`
  ${SOURCE_ENABLE_TYPE}
  fragment SourcesSportNodeType on SourcesSportNode {
    sportId
    name
    prioritySources {
      ...SourceEnableType
    }
    isConfigured
  }
`;

export const SOURCES_TEMPLATE_NODES_TYPE = gql`
  ${SOURCES_SPORT_NODE_TYPE}
  ${SOURCES_CATEGORY_NODE_TYPE}
  ${SOURCES_TOURNAMENT_NODE_TYPE}
  ${SOURCES_EVENT_NODE_TYPE}
  ${SOURCES_MARKET_NODE_TYPE}
  fragment SourcesTemplateNodesType on SourcesTemplateNodes {
    marketsToNode
    sports {
      ...SourcesSportNodeType
    }
    categories {
      ...SourcesCategoryNodeType
    }
    tournaments {
      ...SourcesTournamentNodeType
    }
    events {
      ...SourcesEventNodeType
    }
    markets {
      ...SourcesMarketNodeType
    }
  }
`;

const getSourcesTemplate = gql`
  ${SOURCES_TEMPLATE_NODES_TYPE}
  query getSourcesTemplate(
    $path: NodeSelectorInput
    $templateId: TemplateId
    $lineType: LineType!
  ) {
    data: getSourcesTemplate(
      path: $path
      templateId: $templateId
      lineType: $lineType
    ) {
      ...SourcesTemplateNodesType
    }
  }
`;

export default getSourcesTemplate;
