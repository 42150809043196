import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  smallCrossIcon: {
    cursor: 'pointer',
    width: 7,
    height: 7,
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("/icons/SmallCrossIcon.svg")',
    backgroundPosition: 'center',
    backgroundColor: '#f8f4f4',
    backgroundSize: 'cover',
  },
});

const SmallCrossIcon = ({ ...props }) => {
  const classes = useStyles();

  return <div className={classes.smallCrossIcon} {...props} />;
};

export default SmallCrossIcon;
