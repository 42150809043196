import { gql } from '@apollo/client';

const addSportMarketMutation = gql`
  mutation addSportMarketMutation($sportId: SportId!, $marketId: MarketId!) {
    addSportMarket(sport_id: $sportId, market_id: $marketId) {
      id
      name
      enabled
    }
  }
`;

export default addSportMarketMutation;
