import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const enableEventMarketMutation = gql`
  ${eventModelFragment}
  mutation enableEventMarket(
    $eventId: EventId!
    $marketId: MarketId!
    $status: Boolean!
    $version: Version!
  ) {
    enableEventMarket(
      event_id: $eventId
      market_id: $marketId
      status: $status
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;
// TODO: add event model response
export default enableEventMarketMutation;
