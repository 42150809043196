import omit from 'lodash/omit';
import { getActiveSchemaUrl } from './schemaUrls';
import { handleActions } from 'redux-actions';
import { loginSuccess, logoutSuccess } from '../actions/authActions';
import { refreshToken } from '../actions/cmdActions';

const users = handleActions(
  {
    [loginSuccess]: (state, { payload: { schemaUrl, user } }) => ({
      ...state,
      [schemaUrl]: user,
    }),
    [logoutSuccess]: (state, { payload }) => omit(state, payload),
    [refreshToken]: (state, { payload }) => {
      const schemaUrl = payload.activeSchemaUrl;

      return {
        ...state,
        [schemaUrl]: {
          ...state[schemaUrl],
          token: payload.token,
        },
      };
    },
  },
  {}
);

export default users;

export const getUsers = state => state.persistable.users;

export const getUser = state => getUsers(state)[getActiveSchemaUrl(state)];

export const getUserToken = state => {
  const user = getUser(state);

  if (!user || !user.token) {
    return null;
  }

  return user.token;
};

export const getUserRefreshToken = state => {
  const user = getUser(state);

  if (!user || !user.refresh_token) {
    return null;
  }

  return user.refresh_token;
};

export const getUserRefreshUrl = state => {
  const user = getUser(state);

  if (!user || !user.refresh_url) {
    return null;
  }

  return user.refresh_url;
};
