import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const UPDATE_EVENT = gql`
  ${eventModelFragment}
  mutation updateEvent(
    $id: EventId!
    $fixture: FixtureInput
    $ladderId: LadderId
    $status: EventStatus
    $stats: [StatsModelInput]
    $version: Version!
  ) {
    updateEvent(
      id: $id
      fixture: $fixture
      ladder_id: $ladderId
      status: $status
      stats: $stats
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;

export default UPDATE_EVENT;
