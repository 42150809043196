import TableHeaderColumn from './TableHeaderColumn';
import TableBulkCell, { bulkCellStyles } from './TableBulkCell';
import TableConfigMenu from './TableConfigMenu';
import TableStickyHeader from './TableStickyHeader';
import { expandCellStyles } from './TableExpandCell';
import TableHeader from './TableHeader';
import ExpandAllButton from '../Button/ExpandAllButton';
import { commonCellStyle } from '../../hooks/useTableRowsRender';

const TableHeaderRow = ({
  sticky = false,
  expandable,
  toggledColumns,
  disabledColumns,
  template,
  bulk,
  visibleColumns,
  availableColumns,
  toggleColumn,
  onChangeColumnWidth = () => {},
  onReorder,
  configMenu = true,
  onExpandAll,
  everyExpanded,
  expandAllButtonVisible,
  initialOrderDirection,
  onChangeOrderBy,
  orderBy,
  scrollable,
}) => {
  const Header = sticky ? TableStickyHeader : TableHeader;

  return (
    <Header
      style={{
        gridTemplateColumns: template,
        // justifyContent: 'end',
      }}
    >
      {(bulk || expandable) && (
        <div
          style={{
            position: 'sticky',
            left: '0',
            display: 'flex',
            width: 'fit-content',
            zIndex: 1,
            backgroundColor: 'inherit',
          }}
        >
          {bulk && (
            <TableHeaderColumn style={bulkCellStyles}>
              <TableBulkCell
                value={
                  bulk.everyToggled
                    ? true
                    : bulk.someToggled
                    ? undefined
                    : bulk.someToggled
                }
                onToggle={bulk.toggleAll}
              />
            </TableHeaderColumn>
          )}

          {expandable && (
            <TableHeaderColumn style={expandCellStyles}>
              {expandAllButtonVisible && (
                <ExpandAllButton
                  expanded={everyExpanded}
                  onClick={onExpandAll}
                />
              )}
            </TableHeaderColumn>
          )}
        </div>
      )}

      <>
        {visibleColumns
          ? visibleColumns.map(
              (
                {
                  label,
                  isLabelHidden = false,
                  keepMinWidth,
                  resizable,
                  style,
                  key,
                  onWidthChange,
                  minWidth,
                  sortkey,
                  labelStyle,
                  headerTitle,
                },
                index
              ) => {
                return (
                  <TableHeaderColumn
                    key={key}
                    columnKey={key}
                    label={isLabelHidden ? '' : label}
                    headerTitle={headerTitle}
                    style={{
                      ...commonCellStyle,
                      ...style,
                    }}
                    labelStyle={labelStyle}
                    minWidth={minWidth}
                    isLast={index === visibleColumns.length - 1}
                    resizable={resizable}
                    onResize={onChangeColumnWidth}
                    keepMinWidth={keepMinWidth}
                    onWidthChange={onWidthChange}
                    sortkey={sortkey}
                    initialOrderDirection={initialOrderDirection}
                    orderBy={orderBy}
                    onChangeOrderBy={onChangeOrderBy}
                  />
                );
              }
            )
          : null}
      </>
      {
        <TableHeaderColumn
          style={{
            padding: configMenu && '0 4px',
            position: configMenu && 'sticky',
            right: configMenu && '0',
            boxShadow:
              configMenu &&
              'rgb(248, 244, 244) -10px 0px 30px 15px, inset rgb(248, 244, 244) 10px 0px 20px',
          }}
        >
          {configMenu && (
            <TableConfigMenu
              disabledColumns={disabledColumns}
              toggledColumns={toggledColumns}
              columns={availableColumns}
              onToggleColumn={toggleColumn}
              onReorder={onReorder}
            />
          )}
        </TableHeaderColumn>
      }
    </Header>
  );
};

export default TableHeaderRow;
