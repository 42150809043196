import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { isValid, getHours, getMinutes } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import TimePickerControl from './TimePickerControl';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import TimePickerRange from './TimePickerRange';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/lab';

const useStyles = makeStyles({
  calendarHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerPickers: {
    display: 'flex',
  },
  switchMonthButton: {
    width: 32,
    height: 32,
    marginLeft: 4,
    marginRight: 4,
  },
  switchMonthButtonDisabled: {},
  pickerFooter: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchMonthButtonHidden: {
    visibility: 'hidden',
  },
  timePicker: {
    marginTop: 24,
  },
  clearButton: {
    marginLeft: 8,
  },
});

const RangeDatePicker = ({ value, onChange }) => {
  const classes = useStyles();

  const hasHoursOrMinutes = useMemo(
    () =>
      !!value &&
      isValid(value[0]) &&
      isValid(value[1]) &&
      (getHours(value[0]) ||
        getMinutes(value[0]) ||
        getHours(value[1]) ||
        getMinutes(value[1])),
    [value]
  );

  const [isOpenTime, setIsOpenTime] = useState(hasHoursOrMinutes);
  const [dateRange, setDateRange] = useState(value);
  const buttonRef = useRef(null);
  const timePickerFocusRef = useRef(null);

  useEffect(() => {
    setDateRange(value);
  }, [value, setDateRange]);

  useEffect(() => {
    if (!dateRange || !isValid(dateRange[0]) || !isValid(dateRange[1])) {
      setIsOpenTime(false);
    }
  }, [dateRange, setIsOpenTime]);

  // const handleMonthChange = useCallback(
  //   (index, month) => {
  //     const updatedDateRange = [...dateRange];
  //
  //     updatedDateRange[index] = setMonth(dateRange[index] || new Date(), month);
  //
  //     setDateRange(updatedDateRange);
  //   },
  //   [dateRange, setDateRange]
  // );

  // const handleYearChange = useCallback(
  //   (index, year) => {
  //     const updatedDateRange = [...dateRange];
  //
  //     updatedDateRange[index] = setYear(dateRange[index] || new Date(), year);
  //
  //     setDateRange(updatedDateRange);
  //   },
  //   [dateRange, setDateRange]
  // );

  const handleApply = useCallback(() => {
    onChange(dateRange);
  }, [onChange, dateRange]);

  const handleTimeChange = useCallback(
    value => {
      setDateRange(value);
    },
    [setDateRange]
  );

  const handleCompleteTime = useCallback(() => {
    buttonRef.current && buttonRef.current.focus();
  }, []);

  const handleClear = useCallback(() => {
    onChange([null, null]);
  }, [onChange]);

  const focusTimePicker = useCallback(() => {
    if (timePickerFocusRef.current) {
      timePickerFocusRef.current.focus();
      timePickerFocusRef.current.select();
    }
  }, []);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDateRangePicker
          date={dateRange}
          displayStaticWrapperAs="desktop"
          value={dateRange}
          onChange={newValue => {
            setDateRange(newValue);
          }}
          renderInput={() => null}
        />
      </LocalizationProvider>
      {/*<div*/}
      {/*  headerLabelRender={({*/}
      {/*    index,*/}
      {/*    onLeftClick,*/}
      {/*    onRightClick,*/}
      {/*    isLeftHidden,*/}
      {/*    isRightHidden,*/}
      {/*    isLeftDisabled,*/}
      {/*    isRightDisabled,*/}
      {/*    leftArrowButtonProps,*/}
      {/*    leftArrowButtonText,*/}
      {/*    leftArrowIcon,*/}
      {/*    rightArrowButtonProps,*/}
      {/*    rightArrowButtonText,*/}
      {/*    rightArrowIcon,*/}
      {/*    text,*/}
      {/*    monthOnIteration,*/}
      {/*  }) => {*/}
      {/*    return (*/}
      {/*      <div className={classes.calendarHeader}>*/}
      {/*        <IconButton*/}
      {/*          size={'small'}*/}
      {/*          color={'primary'}*/}
      {/*          className={clsx(classes.switchMonthButton, {*/}
      {/*            [classes.switchMonthButtonDisabled]: isLeftDisabled,*/}
      {/*            [classes.switchMonthButtonHidden]: isLeftHidden,*/}
      {/*          })}*/}
      {/*          disabled={isLeftDisabled}*/}
      {/*          onClick={onLeftClick}*/}
      {/*        >*/}
      {/*          <ExpandIconLeft className={classes.expandIcon} />*/}
      {/*        </IconButton>*/}

      {/*        <div className={classes.headerPickers}>*/}
      {/*          <MonthSelect*/}
      {/*            onChange={month => handleMonthChange(index, month)}*/}
      {/*            currentMonth={getMonth(monthOnIteration)}*/}
      {/*          />*/}
      {/*          <YearSelect*/}
      {/*            onChange={year => handleYearChange(index, year)}*/}
      {/*            currentYear={getYear(monthOnIteration)}*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*        <IconButton*/}
      {/*          size={'small'}*/}
      {/*          color={'primary'}*/}
      {/*          className={clsx(classes.switchMonthButton, {*/}
      {/*            [classes.switchMonthButtonDisabled]: isRightDisabled,*/}
      {/*            [classes.switchMonthButtonHidden]: isRightHidden,*/}
      {/*          })}*/}
      {/*          disabled={isRightDisabled}*/}
      {/*          onClick={onRightClick}*/}
      {/*        >*/}
      {/*          <ExpandIconRight className={classes.expandIcon} />*/}
      {/*        </IconButton>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*  displayStaticWrapperAs="desktop"*/}
      {/*  value={dateRange}*/}
      {/*  onChange={newValue => {*/}
      {/*    setDateRange(newValue);*/}
      {/*  }}*/}
      {/*  renderInput={(startProps, endProps) => (*/}
      {/*    <React.Fragment></React.Fragment>*/}
      {/*  )}*/}
      {/*/>*/}

      <TimePickerControl
        isOpen={isOpenTime}
        onChange={setIsOpenTime}
        disabled={!dateRange || !dateRange[0] || !dateRange[1]}
      />

      <Collapse
        in={isOpenTime}
        unmountOnExit
        onEntered={() => {
          focusTimePicker();
        }}
      >
        <TimePickerRange
          focusRef={timePickerFocusRef}
          className={classes.timePicker}
          value={dateRange}
          onChange={handleTimeChange}
          onComplete={handleCompleteTime}
        />
      </Collapse>

      <div className={classes.pickerFooter}>
        <Button
          ref={buttonRef}
          onClick={handleApply}
          color="primary"
          disableRipple
          variant={'contained'}
        >
          Save
        </Button>
        <Button
          onClick={handleClear}
          color="secondary"
          disableRipple
          variant={'contained'}
          className={classes.clearButton}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

export default RangeDatePicker;
