/**
 * This will merge new query result with the old in the apollo cache
 without creating a new entry in cache, based on "offset" variable
 */
const offsetPaginationMerger = (
  existing = { items: [] },
  incoming = { items: [] },
  {
    args: {
      input: { offset = 0 },
    },
  }
) => ({
  ...existing,
  ...incoming,
  items: [...existing.items.slice(0, offset), ...incoming.items],
});

/**
 * Specified queries that require custom cache policies
 */
const typePoliciesQueryFields = {
  getImages: {
    keyArgs: ['input', ['brand_id', 'operator_id']],
    merge: offsetPaginationMerger,
  },
};

export default typePoliciesQueryFields;
