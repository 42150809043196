import { Box } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import ExpandIcon from '../../../../../icons/controls/ExpandIcon';
import TournamentItemWithPriority from './TournamentItemWithPriority';

const PrioritySelectionOption = ({
  selected,
  option,
  selectedOption,
  countryCode,
  ...props
}) => {
  const [showChangePlaceholder, setShowChangePlaceholder] = useState(false);

  const onOptionEnter = useCallback(() => {
    setShowChangePlaceholder(true);
  }, [setShowChangePlaceholder]);

  const onOptionLeave = useCallback(() => {
    setShowChangePlaceholder(false);
  }, [setShowChangePlaceholder]);

  return (
    <li
      {...props}
      style={{ textWrap: 'nowrap' }}
      aria-disabled={selected}
      key={option.tournamentId}
      data-cy-value={option.tournamentId}
      onMouseEnter={onOptionEnter}
      onMouseLeave={onOptionLeave}
    >
      <Box display={'flex'} gap={'8px'} alignItems={'center'}>
        <TournamentItemWithPriority
          option={option}
          countryCode={countryCode}
          textEllipse={showChangePlaceholder}
        />
        {showChangePlaceholder && (
          <Fragment>
            <ExpandIcon style={{ transform: 'rotate(270deg)' }} />
            <TournamentItemWithPriority option={selectedOption} textEllipse />
          </Fragment>
        )}
      </Box>
    </li>
  );
};

export default PrioritySelectionOption;
