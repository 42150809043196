import clsx from 'clsx';
import MoveIcon from '../../../../../icons/controls/MoveIcon';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const ItemMoveButton = ({
  dragHandleProps = {},
  selected,
  dragDropDisabled = {},
}) => {
  const classes = useTreeSelectStyles();

  return (
    <div className={classes.moveButton} {...dragHandleProps}>
      <MoveIcon
        className={clsx({
          [classes.whiteIcon]: selected,
          [classes.moveButtonDisabled]: dragDropDisabled,
        })}
      />
    </div>
  );
};

export default ItemMoveButton;
