import React from 'react';
import { SvgIcon } from '@mui/material';

const HideIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 21 21'} {...props}>
      <circle
        cx="10.5"
        cy="10.5001"
        r="7.42472"
        transform="rotate(45 10.5 10.5001)"
        fill="currentColor"
      />
      <path
        d="M12.6875 8.3125L8.31244 12.6876"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.6875 12.6875L8.31244 8.31244"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default HideIcon;
