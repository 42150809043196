import React from 'react';
import PropTypes from 'prop-types';
import ColorInput from '../../inputs/ColorInput';

const ColorWidget = ({
  disabled,
  readonly,
  errorSchema,
  label,
  value,
  options,
  onChange,
  id,
}) => (
  <ColorInput
    fullWidth
    margin={'dense'}
    variant="outlined"
    id={id}
    error={!!(errorSchema.__errors && errorSchema.__errors.length)}
    helperText={
      (errorSchema.__errors && errorSchema.__errors[0]) || options.help || ' '
    }
    value={value}
    onChange={onChange}
    label={label}
    disabled={disabled || readonly}
  />
);

ColorWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ColorWidget;
