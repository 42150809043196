import FinishFlagImg from '../../img/finish-flag.png';

export const EVENT_STATUSES = {
  prematch: 'prematch',
  finished: 'finished',
  canceled: 'canceled',
  inProgress: 'in_progress',
  draft: 'draft',
};

export const EVENT_STATUSES_OPTIONS = {
  [EVENT_STATUSES.prematch]: {
    label: 'Scheduled',
    value: EVENT_STATUSES.prematch,
    color: '#EDBE18',
  },
  [EVENT_STATUSES.inProgress]: {
    label: 'In progress',
    value: EVENT_STATUSES.inProgress,
    color: '#08CA6D',
  },
  [EVENT_STATUSES.finished]: {
    label: 'Finished',
    value: EVENT_STATUSES.finished,
    color: '#1581FF',
    backgroundImage: `url(${FinishFlagImg})`,
  },
  [EVENT_STATUSES.canceled]: {
    label: 'Canceled',
    value: EVENT_STATUSES.canceled,
    color: '#FF000F',
  },
  [EVENT_STATUSES.draft]: {
    label: 'Draft',
    value: EVENT_STATUSES.draft,
    color: '#8496AB',
  },
};

export const EVENT_TABS = {
  settings: 'settings',
  trading: 'trading',
};

export const ALL_MARKETS_TAB_VALUE = 'all';

export const EVENT_TYPES = {
  match: 'match',
  stage: 'stage',
  season: 'season',
  tournament: 'tournament',
};
