import NavigationMenuGroup from './NavigationMenuGroup';
import Stack from '@mui/material/Stack';
import { useCallback, useMemo, useRef } from 'react';

const NavigationMenu = ({ groups, activeRoute, onMenuItemClick }) => {
  const menuRef = useRef(null);

  const onItemClick = useCallback(
    (e, groupIndex, itemIndex) => {
      onMenuItemClick(groups[groupIndex].items[itemIndex]);
    },
    [onMenuItemClick, groups]
  );

  const withActive = useMemo(() => {
    return groups.reduce((acc, value) => {
      const activeIndex = value.items.findIndex(
        ({ link }) => link === activeRoute
      );

      let items = value.items;

      if (activeIndex !== -1) {
        items = value.items.map((item, itemIndex) => ({
          ...item,
          isActive: itemIndex === activeIndex,
        }));
      }

      return [
        ...acc,
        {
          ...value,
          hasActive: activeIndex !== -1,
          items,
        },
      ];
    }, []);
  }, [groups, activeRoute]);

  return (
    <Stack
      spacing={2}
      ref={menuRef}
      sx={{
        position: 'relative',
      }}
    >
      {withActive.map((group, groupIndex) => (
        <NavigationMenuGroup
          hasActive={group.hasActive}
          key={group.label}
          title={group.label}
          icon={group.icon || group.label}
          items={group.items}
          groupIndex={groupIndex}
          onItemClick={onItemClick}
        />
      ))}
    </Stack>
  );
};

export default NavigationMenu;
