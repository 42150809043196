import { useCallback, useMemo, useState } from 'react';

const useTableAnimatedRow = () => {
  const [animatedRows, setAnimatedRows] = useState({});

  const animateRow = useCallback(id => {
    setAnimatedRows(rows => {
      return {
        ...rows,
        [id]: true,
      };
    });

    setTimeout(() => {
      setAnimatedRows(rows => {
        return {
          ...rows,
          [id]: false,
        };
      });
    }, 200);
  }, []);

  return useMemo(() => {
    return {
      animateRow,
      animatedRows,
    };
  }, [animateRow, animatedRows]);
};

export default useTableAnimatedRow;
