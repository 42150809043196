import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const removeMarketSpecifierMutation = gql`
  ${eventModelFragment}
  mutation removeMarketSpecifier(
    $eventId: EventId!
    $marketId: MarketId!
    $specifier: Specifiers!
    $version: Version!
  ) {
    removeMarketSpecifier(
      event_id: $eventId
      market_id: $marketId
      specifier: $specifier
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;

export default removeMarketSpecifierMutation;
