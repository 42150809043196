import { capitalize } from 'lodash';
import { useCallback, useState } from 'react';
import useFancyAutocompleteAllToggle from '../../../../../hooks/useFancyAutocompleteAllToggle';
import FancyAutocompleteAllOption from '../../FancyAutocomplete/FancyAutocompleteAllOption';
import FancySelect from '../../FancySelect/FancySelect';
import FancySelectValueProxy from '../../FancySelect/FancySelectValueProxy';
import Select from '../../Select/Select';
import ModalContentContainer from './ModalContentContainer';

const ApplyPresetsModalContent = ({
  selectType,
  items,
  presetId,
  selectItem,
  presetNames,
  presetsLoading,
  sportId,
}) => {
  const [itemSelectValue, setItemSelectValue] = useState([]);

  const onItemChange = useCallback(
    value => {
      selectItem.current = value;
      setItemSelectValue(value);
    },
    [selectItem]
  );

  const onPresetChange = useCallback(
    value => {
      presetId.current = value;
    },
    [presetId]
  );

  const [toggledAll, onToggleAll] = useFancyAutocompleteAllToggle({
    onChange: onItemChange,
    options: items,
    value: itemSelectValue,
    valueKey: 'value',
  });

  return (
    <ModalContentContainer>
      {selectType !== 'sport' && (
        <FancySelectValueProxy
          value={itemSelectValue}
          options={items}
          onChange={onItemChange}
          valueKey={'value'}
        >
          <FancySelect
            label={capitalize(selectType)}
            options={items}
            value={itemSelectValue}
            getInputPlaceholder={defaultPlaceholder => {
              return toggledAll ? 'All' : defaultPlaceholder;
            }}
            extraOptions={
              <FancyAutocompleteAllOption
                label={'All'}
                onToggle={onToggleAll}
                toggled={toggledAll}
              />
            }
            inModal
            withExpandIcon
          />
        </FancySelectValueProxy>
      )}
      <Select
        disabled={presetsLoading}
        label={'Select preset'}
        options={presetNames}
        onChange={onPresetChange}
        valueKey={'id'}
        labelKey={'name'}
      />
    </ModalContentContainer>
  );
};

export default ApplyPresetsModalContent;
