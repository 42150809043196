import { gql } from '@apollo/client';

const GetCategoriesNamesInLine = gql`
  query getCategoriesNamesInLine(
    $query: String!
    $sportIds: [SportId!]
    $excludeSportIds: Boolean
    $categoriesIds: [CategoryId!]
    $limit: Int
    $offset: Int
  ) @api(name: autocomplete) {
    data: getCategoriesNamesInLine(
      query: $query
      sportIds: $sportIds
      excludeSportIds: $excludeSportIds
      categoriesIds: $categoriesIds
      limit: $limit
      offset: $offset
    ) {
      label: name
      value: id
    }
  }
`;

export default GetCategoriesNamesInLine;
