import { gql } from '@apollo/client';

const getMarginLaddersQuery = gql`
  query getMarginLadders($filters: MarginLadderFilters) {
    data: getMarginLadders(input: $filters) {
      items {
        id
        name
        created {
          user {
            email
          }
        }
        updated {
          date
          user {
            email
          }
        }
        sport_name
      }
      total
    }
  }
`;

export default getMarginLaddersQuery;
