import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { confirmationTypes } from './constants';

const useStyles = makeStyles({
  confirmationMessageBold: {
    fontWeight: 700,
  },
  confirmationMessageDanger: {
    fontWeight: 700,
    color: '#FF000F',
  },
  confirmationEventsInfo: {
    backgroundColor: '#E8F2FF',
    borderRadius: 8,
    padding: '8px 12px',
    marginBottom: 18,
  },
  confirmationEvent: {
    marginLeft: 16,
    fontSize: 12,
    '&::marker': {
      fontWeight: 700,
    },
  },
});

const EventManagementConfirmationMessage = ({
  type,
  firstAffiliation,
  secondAffiliation,
}) => {
  const classes = useStyles();

  const renderEventsInfo = () => {
    return (
      <ol className={classes.confirmationEventsInfo}>
        <li className={classes.confirmationEvent}>{firstAffiliation}</li>
        {secondAffiliation && (
          <li className={classes.confirmationEvent}>{secondAffiliation}</li>
        )}
      </ol>
    );
  };

  switch (type) {
    case confirmationTypes.merge: {
      return (
        <>
          {renderEventsInfo()}
          Are you sure you want to{' '}
          <span className={classes.confirmationMessageBold}>
            merge 2 events
          </span>
          ?
        </>
      );
    }
    case confirmationTypes.invertedMerge: {
      return (
        <>
          {renderEventsInfo()}
          Are you sure you want to{' '}
          <span className={classes.confirmationMessageBold}>
            merge 2 events
          </span>{' '}
          and{' '}
          <span className={classes.confirmationMessageDanger}>
            invert 2 events
          </span>
          ?
        </>
      );
    }

    case confirmationTypes.unmerge: {
      return (
        <>
          {renderEventsInfo()}
          Are you sure you want to{' '}
          <span className={classes.confirmationMessageBold}>unmerge event</span>
          ?
        </>
      );
    }
    default: {
      return null;
    }
  }
};

EventManagementConfirmationMessage.propTypes = {
  type: PropTypes.string.isRequired,
  firstAffiliation: PropTypes.string.isRequired,
  secondAffiliation: PropTypes.string,
};
EventManagementConfirmationMessage.defaultProps = {
  secondAffiliation: '',
};

export default EventManagementConfirmationMessage;
