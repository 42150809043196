import { useQuery } from '@apollo/client';
import getCompetitorsNames from '../../gql/EventsGroups/queries/getCompetitorsNames';

const useCompetitors = options => {
  const { data, loading, error, refetch } = useQuery(getCompetitorsNames, {
    context: {
      apiName: 'autocomplete',
    },
    fetchPolicy: 'network-only',
    ...options,
  });

  let competitors = [];

  if (data) {
    competitors = data.getCompetitorsNames;
  }

  return { competitors, loading, error, refetch };
};

export default useCompetitors;
