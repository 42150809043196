import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import { UiOptionsContext } from './UiOptionsProvider';

const useStyles = makeStyles({
  booleanField: {
    width: 12,
    height: 12,
    background: '#C2CAD5',
    borderRadius: '50%',
    margin: '0 auto',
  },

  booleanFieldActive: {
    background: '#3FDE91',
  },
});

const BooleanField = () => {
  const classes = useStyles();

  const { value } = useContext(UiOptionsContext) || {};
  const [width] = useState(value.width || 'initial');

  return (
    <div
      style={{
        width,
      }}
    >
      <div
        className={classNames(classes.booleanField, {
          [classes.booleanFieldActive]: value.data,
        })}
      />
    </div>
  );
};

export default BooleanField;
