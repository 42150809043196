import PresetIcon from '../../../icons/controls/PresetIcon';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  presetsTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },

  presetIcon: {
    fontSize: 11,
    marginLeft: 7,
  },
});

const PresetActionCardSummary = () => {
  const classes = useStyles();
  return (
    <div>
      <span className={classes.presetsTitle}>
        Presets
        <PresetIcon className={classes.presetIcon} />
      </span>
    </div>
  );
};

export default PresetActionCardSummary;
