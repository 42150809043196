import { useDispatch } from 'react-redux';
import { getEditPath } from '../utils/schemaHelper';
import { editData } from '../actions/dataActions';
import { showNotification } from '../actions/uiActions';
import { useCallback, useState } from 'react';
import get from 'lodash/get';
import { RESPONSE_TYPE_ENUM } from '../utils/getResponseTypeByCode';
import { useApiHost } from '../providers/ApiHostProvider';

const useInlineEdit = ({
  link,
  entity,
  requestQuery,
  requestPath,
  operationId,
  method,
  url,
  storeStrategy,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { apiHost } = useApiHost();

  const processEdit = useCallback(
    (editPath, body, responseBody) =>
      dispatch(
        editData({
          apiHost: apiHost,
          path: method + url,
          editPath,
          body,
          operationId,
          responseBody,
          storeStrategy,
        })
      ),
    [dispatch, apiHost, method, url, operationId, storeStrategy]
  );

  const showNotificationCb = useCallback(
    (...args) => {
      dispatch(showNotification(...args));
    },
    [dispatch]
  );

  return {
    isLoading,
    edit: async data => {
      setIsLoading(true);

      const editPath = getEditPath({
        editLink: link,
        entity,
        query: requestQuery,
        requestPath,
      });

      const result = await processEdit(editPath, data, entity);

      const errorMessage =
        result.error && get(result, 'payload.response.message');

      if (result.error && errorMessage) {
        showNotificationCb(RESPONSE_TYPE_ENUM.ERROR, errorMessage);
      }

      setIsLoading(false);
    },
  };
};

export default useInlineEdit;
