import { useSelector } from 'react-redux';
import Main from '../components/Main';
import { branch, compose, renderComponent } from 'recompose';
import GlobalError from '../components/GlobalError';
import { getUser } from '../reducers/users';
import React, { memo } from 'react';
import { useRawSchema } from '../providers/RawSchemaProvider';
import withRouter from '../utils/withRouter';

const withProps = BaseComponent => props => {
  const { error, loading } = useRawSchema();
  const user = useSelector(getUser);

  return (
    <BaseComponent error={error} loading={loading} user={user} {...props} />
  );
};

const withError = branch(({ error }) => error, renderComponent(GlobalError));

const MainContainer = compose(withRouter, withProps, withError)(Main);

export default memo(MainContainer);
