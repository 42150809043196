import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '../v2/components/Button/Button';
import ZoomTransition from '../utils/transitions/ZoomTransition';

const initialState = [];

export const NotificationPopupContext = createContext({});

export const useNotificationPopup = () => {
  return useContext(NotificationPopupContext);
};

const NotificationPopupProvider = ({ children }) => {
  const [stack, setStack] = useState(initialState);

  const onClose = useCallback(
    key => {
      if (key) {
        setStack(stack.filter(item => item.key !== key));
      } else {
        setStack(initialState);
      }
    },
    [setStack, stack]
  );

  const open = useCallback(
    config => {
      setStack([...stack, config]);
    },
    [setStack, stack]
  );

  const handleEntering = () => {};

  const providerValue = useMemo(() => {
    return {
      open: open,
      close: onClose,
    };
  }, [open, onClose]);

  return (
    <NotificationPopupContext.Provider value={providerValue}>
      {stack.map(modal => (
        <Dialog
          key={modal.key}
          TransitionComponent={ZoomTransition}
          onClose={onClose}
          maxWidth="sm"
          aria-labelledby="notification-dialog-title"
          open={true}
          TransitionProps={{
            onEntering: handleEntering,
          }}
        >
          <DialogTitle id="notification-dialog-title">
            {modal.title}
          </DialogTitle>
          <DialogContent>{modal.message}</DialogContent>
          <DialogActions>
            <Button
              disableRipple
              onClick={() => onClose(modal.key)}
              variant={'contained'}
              color={'lightBlue'}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ))}

      {children}
    </NotificationPopupContext.Provider>
  );
};

export default NotificationPopupProvider;
