import ArrayChipFilter from '../../../components/Filters/ArrayChipFilter';
import { Checkbox } from '../../../components/Inputs';
import BrandSelect from '../../../components/Inputs/BrandSelect/BrandSelect';
import CurrencySelect from '../../../components/Inputs/CurrencySelect/CurrencySelect';
import NumberRangeInput from '../../../components/Inputs/NumberRangeInput';
import OperatorSelect from '../../../components/Inputs/OperatorSelect/OperatorSelect';
import RMSettingsSelect from '../../../components/Inputs/RMSettingsSelect/RMSettingsSelect';
import Select from '../../../components/Inputs/Select/Select';
import SportsSelect from '../../../components/Inputs/SportsSelect/SportsSelect';
import TextField from '../../../components/Inputs/TextField';
import ClickhouseAutocomplete, {
  ClickhouseAutocompleteMethod,
} from '../../Events/EventDetails/components/GamingActivity/components/Inputs/ClickhouseAutocomplete';
import {
  ACCEPTED_BETS,
  BET_STATUS,
  BET_TYPE,
  FILTER_BONUS,
  LIFE_PREMATCH,
} from '../BetHistory';
import BetDateRangeBetHistory from '../filters/BetDateRangeBetHistory';
import TestPlayersFilterBetHistory from '../filters/TestPlayersFilterBetHistory';

const useBetHistoryFiltersConfig = ({ isOperator, customDefaultBetDate }) => {
  const filtersConfig = [
    {
      name: 'testPlayers',
      label: 'Test players',
      component: ({
        filterPlayerId,
        filterExtPlayerId,
        setFilterValue,
        ...props
      }) => {
        return (
          <TestPlayersFilterBetHistory
            filterPlayerId={filterPlayerId}
            filterExtPlayerId={filterExtPlayerId}
            setFilterValue={setFilterValue}
            {...props}
          />
        );
      },
      toggled: false,
      deps: ['filterPlayerId', 'filterExtPlayerId'],
    },
    {
      name: 'filterBetId',
      label: 'Bet Id',
      fullWidth: false,
      component: <ArrayChipFilter itemFormat={'betby_id'} />,
      toggled: true,
    },
    {
      name: 'filterPlayerId',
      label: 'Betby Player ID',
      fullWidth: false,
      component: <ArrayChipFilter itemFormat={'betby_id'} />,
      toggled: true,
    },
    {
      name: 'filterExtPlayerId',
      label: 'External Player ID',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterOperatorId',
      label: 'Choose Operator',
      component: <OperatorSelect multiple />,
      toggled: true,
      hidden: isOperator,
    },
    {
      name: 'filterOperatorBrandId',
      label: 'Choose Brand',
      component: ({ filterOperatorId, ...props }) => {
        return (
          <BrandSelect
            operatorIds={isOperator ? [] : filterOperatorId}
            multiple
            {...props}
            disabled={!isOperator && !filterOperatorId}
            withOperator={!isOperator}
          />
        );
      },
      toggled: true,
      deps: ['filterOperatorId'],
    },
    {
      name: 'filterEventId',
      label: 'Event id',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterUsername',
      label: 'Player username',
      fullWidth: false,
      component: <TextField />,
      toggled: true,
    },

    {
      name: 'filterBonusId',
      label: 'Bonus id',
      fullWidth: false,
      component: <TextField />,
      toggled: false,
    },
    {
      name: 'filterSportId',
      label: 'Choose Sport',
      fullWidth: false,
      component: <SportsSelect multiple />,
      toggled: true,
    },
    {
      name: 'filterCategoryName',
      label: 'Find category',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterTournamentName',
      label: 'Find tournament',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterEventName',
      label: 'Event',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterMarketName',
      label: 'Find market',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterOutcomeName',
      label: 'Find outcome',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: true,
    },
    {
      name: 'filterEventSource',
      label: 'Provider',
      component: (
        <ClickhouseAutocomplete
          method={ClickhouseAutocompleteMethod.PROVIDER}
          multiple
        />
      ),
      toggled: true,
      hidden: isOperator,
    },
    {
      name: 'filterRiskManagement',
      label: 'Risk management',
      fullWidth: false,
      component: <RMSettingsSelect multiple />,
      toggled: false,
      hidden: isOperator,
    },
    {
      name: 'filterCurrency',
      label: 'Choose currencies',
      fullWidth: false,
      component: <CurrencySelect multiple />,
      toggled: true,
    },
    {
      name: 'filterMarketId',
      label: 'Market ID',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: false,
    },
    {
      name: 'filterOutcomeId',
      label: 'Outcome ID',
      fullWidth: false,
      component: <ArrayChipFilter />,
      toggled: false,
    },
    {
      name: 'filterBetStatus',
      label: 'Bet Status',
      fullWidth: false,
      component: (
        <Select
          multiple
          options={[
            {
              label: 'Open',
              value: BET_STATUS.OPEN,
            },
            {
              label: 'Lost',
              value: BET_STATUS.LOST,
            },
            {
              label: 'New',
              value: BET_STATUS.NEW,
            },
            {
              label: 'Won',
              value: BET_STATUS.WON,
            },
            {
              label: 'Refund',
              value: BET_STATUS.REFUND,
            },
            {
              label: 'Rejected',
              value: BET_STATUS.REJECTED,
            },
            {
              label: 'Cashed out',
              value: BET_STATUS.CASHED_OUT,
            },
            {
              label: 'Canceled',
              value: BET_STATUS.CANCELED,
            },
            {
              label: 'Half-Won',
              value: BET_STATUS.HALF_WON,
            },
            {
              label: 'Half-Lost',
              value: BET_STATUS.HALF_LOST,
            },
          ]}
        />
      ),
      toggled: true,
    },
    {
      name: 'filterBonus',
      label: 'Bonus Type',
      fullWidth: false,
      component: (
        <Select
          multiple
          options={[
            {
              label: 'comboboost',
              value: FILTER_BONUS.COMBOBOOST,
            },
            {
              label: 'bet_refund',
              value: FILTER_BONUS.BET_REFUND,
            },
            {
              label: 'snr',
              value: FILTER_BONUS.SNR,
            },
            {
              label: 'free_money',
              value: FILTER_BONUS.FREE_MONEY,
            },
          ]}
        />
      ),
      toggled: true,
    },
    {
      name: 'filterLivePrematch',
      label: 'Live/Prematch/Virtual',
      fullWidth: false,
      component: (
        <Select
          multiple
          options={[
            {
              label: 'Live',
              value: LIFE_PREMATCH.LIVE,
            },
            {
              label: 'Prematch',
              value: LIFE_PREMATCH.PREMATCH,
            },
            {
              label: 'Virtual',
              value: LIFE_PREMATCH.VIRTUAL,
            },
          ]}
        />
      ),
      toggled: true,
    },
    {
      name: 'acceptedBets',
      label: 'Accepted bets',
      fullWidth: false,
      component: (
        <Select
          multiple
          options={[
            {
              label: 'Yes',
              value: ACCEPTED_BETS.YES,
            },
            {
              label: 'No',
              value: ACCEPTED_BETS.NO,
            },
          ]}
        />
      ),
      toggled: true,
    },
    {
      name: 'filterBetType',
      label: 'Bet type',
      fullWidth: false,
      component: (
        <Select
          multiple
          options={[
            {
              label: 'Single',
              value: BET_TYPE.SINGLE,
            },
            {
              label: 'Combo',
              value: BET_TYPE.COMBO,
            },
            {
              label: 'System',
              value: BET_TYPE.SYSTEM,
            },
          ]}
        />
      ),
      toggled: true,
    },
    {
      name: 'rBetDate',
      label: 'Bet Date',
      fullWidth: false,
      component: ({
        filterBonusId,
        filterBetId,
        resetFilter,
        getFilterValue,
        setFilterValue,
        isDefaultPreset,
        ...props
      }) => {
        return (
          <BetDateRangeBetHistory
            filterBetId={filterBetId}
            filterBonusId={filterBonusId}
            resetFilter={resetFilter}
            getFilterValue={getFilterValue}
            setFilterValue={setFilterValue}
            isDefaultPreset={isDefaultPreset}
            customDefaultValue={customDefaultBetDate}
            {...props}
          />
        );
      },
      toggled: true,
      deps: ['filterBonusId', 'filterBetId'],
    },
    {
      name: 'rSettleDate',
      label: 'Settlement date',
      fullWidth: false,
      component: ({
        filterBonusId,
        filterBetId,
        resetFilter,
        getFilterValue,
        ...props
      }) => {
        return (
          <BetDateRangeBetHistory
            filterBetId={filterBetId}
            filterBonusId={filterBonusId}
            resetFilter={resetFilter}
            getFilterValue={getFilterValue}
            {...props}
          />
        );
      },
      toggled: true,
      deps: ['filterBonusId', 'filterBetId'],
    },
    {
      name: 'rStakeEur',
      label: 'Stake (eur)',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: true,
    },
    {
      name: 'rStakeCur',
      label: 'Stake (currency)',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: false,
    },
    {
      name: 'rWonEur',
      label: 'Won (eur)',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: true,
    },
    {
      name: 'rOdd',
      label: 'Odd',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: true,
    },
    {
      name: 'rCcf',
      label: 'CCF',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: false,
    },
    {
      name: 'rDiffBetSettle',
      label: 'Timeframe(Bet, Settlement), sec',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: false,
    },
    {
      name: 'rDiffBetStart',
      label: 'Timeframe(Bet, Start), hour',
      fullWidth: false,
      component: <NumberRangeInput />,
      toggled: false,
    },
    {
      name: 'filterForced',
      label: 'Forced',
      component: <Checkbox />,
      hidden: isOperator,
    },
    {
      name: 'filterHasRollback',
      label: 'Has rollback',
      component: <Checkbox />,
      hidden: isOperator,
    },
    {
      name: 'filterUseMts',
      label: 'Use mts',
      component: <Checkbox />,
      hidden: isOperator,
    },
    {
      name: 'filterBetbuilderOnly',
      label: 'Show BetBuilder only',
      component: <Checkbox />,
    },
    {
      name: 'filterHasOperatorBonus',
      label: 'Has Operator Bonus',
      component: <Checkbox />,
    },
  ];

  return { filtersConfig };
};

export default useBetHistoryFiltersConfig;
