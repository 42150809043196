/* eslint-disable no-undef */

import { Component } from 'react';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? 'Something went wrong' : children;
  }
}

export default ErrorBoundary;
