import { gql } from '@apollo/client';

const getCoveragePresetNames = gql`
  query getCoveragePresetNames($sportId: StrSportId!) {
    data: getCoveragePresetNames(sportId: $sportId) {
      id
      name
    }
  }
`;

export default getCoveragePresetNames;
