import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Stack,
  Button,
  Step,
  StepButton,
  StepConnector,
  Stepper,
} from '@mui/material';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { paths } from '../../../routes';

const SetPasswordForm = ({ onSubmit, step, onFirstStepSubmit }) => {
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
  });

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(step || 1);

  const renderStepContent = step => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            onSubmit={values => {
              onFirstStepSubmit(values, () => {
                setFormData(prevState => ({
                  ...prevState,
                  ...values,
                }));
                setActiveStep(2);
              });
            }}
          />
        );
      case 2:
        return (
          <SecondStep
            onBack={() => setActiveStep(1)}
            onSubmit={(values, onError) => {
              onSubmit(
                {
                  email: formData.email,
                  password: values.password,
                },
                () => {
                  setActiveStep(4);
                },
                onError
              );
            }}
          />
        );
      case 4:
        return (
          <Stack gap={'24px'}>
            <Button
              onClick={() => {
                navigate(`../${paths.login}`);
              }}
              variant={'contained'}
              sx={{
                width: '100%',
              }}
            >
              Log in
            </Button>
          </Stack>
        );
    }
  };

  return (
    <Stack gap={'24px'} width={'100%'}>
      <Stepper
        connector={
          <StepConnector
            sx={{
              '& .MuiStepConnector-line': {
                borderRadius: '4px',
              },
            }}
          />
        }
        nonLinear
        activeStep={activeStep - 1}
        sx={{
          gap: '8px',
          '& .MuiStep-root': {
            '& .MuiSvgIcon-root': {
              color: '#E8F2FF',

              '&.MuiStepIcon-root': {
                width: '32px',
                height: '32px',
              },
              '& .MuiStepIcon-text': {
                fill: '#1581FF',
              },

              '&.Mui-active, &.Mui-completed': {
                color: '#1581FF',
                '& .MuiStepIcon-text': {
                  fill: 'white',
                },
              },
            },
          },

          '& .MuiStepConnector-root': {
            '& .MuiStepConnector-line': {
              borderColor: '#E8F2FF',
              borderWidth: '4px',
            },
            '&.Mui-active, &.Mui-completed': {
              '& .MuiStepConnector-line': {
                borderColor: '#1581FF',
              },
            },
          },
        }}
      >
        {[1, 2, 3].map((label, index) => (
          <Step
            key={label}
            completed={label < activeStep}
            disabled={label < activeStep}
          >
            <StepButton />
          </Step>
        ))}
      </Stepper>
      {renderStepContent(activeStep)}
    </Stack>
  );
};

export default SetPasswordForm;
