import IFrame from '../IFrame';
import GlobalLoading from '../../components/GlobalLoading';

const TwitchPlayer = ({ loading, data, classes }) => {
  if (loading || !data) {
    return <GlobalLoading />;
  }

  const url = data && data.url;

  if (!url) {
    return null;
  }

  const urlWithHost = new URL(url);

  urlWithHost.searchParams.set('parent', window.location.hostname);

  return (
    <IFrame
      loading={loading}
      data={{ ...data, url: urlWithHost.toString() }}
      classes={classes}
    />
  );
};

export default TwitchPlayer;
