import React, { useContext, useMemo } from 'react';
import uiContainers from '../uiContainers/index';
import {
  getLinksAndSchemas,
  getOperationIdByPath,
} from '../utils/schemaHelper';
import uiComponents from '../uiComponents';
import get from 'lodash/get';
import UiComponentSchemaProvider from '../providers/UiComponentSchemaProvider';
import { RoutesContext } from '../providers/RoutesProvider';
import SchemaProvider from '../providers/SchemaProvider';
import LinksProvider from '../providers/LinksProvider';
import PresetsProvider from '../providers/PresetsProvider';
import PresetUiComponentSchemaProvider from '../providers/PresetUiComponentSchemaProvider';

const DynamicComponent = ({
  path,
  params,
  formParams,
  parentKey,
  ...otherProps
}) => {
  const routes = useContext(RoutesContext);
  const { schema, uiSchema, links } = getLinksAndSchemas(routes, path);
  const uiComponentName = uiSchema['ui:component'];
  const uiContainerName = uiSchema['ui:container'];
  const operationId = getOperationIdByPath(routes, path);

  if (!uiContainerName) {
    const UiComponent = uiComponents[uiComponentName] || uiComponents.Form;
    const Component = (
      <SchemaProvider value={schema}>
        <LinksProvider links={links}>
          <UiComponent
            path={path}
            params={params}
            formParams={formParams}
            schema={schema}
            uiSchema={uiSchema}
            links={links}
            parentKey={parentKey}
            operationId={operationId}
            {...otherProps}
          />
        </LinksProvider>
      </SchemaProvider>
    );

    if (uiComponentName === 'Table') {
      return (
        <PresetsProvider operationId={operationId} key={operationId}>
          <PresetUiComponentSchemaProvider>
            {Component}
          </PresetUiComponentSchemaProvider>
        </PresetsProvider>
      );
    }

    return (
      <UiComponentSchemaProvider value={uiSchema}>
        {Component}
      </UiComponentSchemaProvider>
    );
  }

  const UiContainer = uiContainers[uiContainerName];

  return (
    <UiContainer schema={schema}>
      {links &&
        Object.keys(links)
          .sort((a, b) => links[a]['x-order'] - links[b]['x-order'])
          .reduce((children, linkKey) => {
            const linkPath = links[linkKey].path;
            const {
              schema,
              uiSchema,
              links: innerLinks,
            } = getLinksAndSchemas(routes, linkPath);

            if (
              uiSchema['ui:component'] ||
              uiSchema['ui:container'] ||
              !get(uiSchema, 'items.items.ui:component')
            ) {
              return [
                ...children,
                <DynamicComponent
                  key={linkKey}
                  path={linkPath}
                  params={params}
                  schema={schema}
                  uiSchema={uiSchema}
                  parentKey={parentKey}
                />,
              ];
            }

            [...new Array(schema.properties.items.minItems)].forEach(
              (_, index) => {
                const ItemComponent =
                  uiComponents[uiSchema.items.items['ui:component']];

                children.push(
                  <ItemComponent
                    parentKey={parentKey}
                    key={index}
                    source={`items[${index}]`}
                    path={linkPath}
                    params={params}
                    schema={schema.properties.items.items}
                    uiSchema={uiSchema.items.items}
                    links={innerLinks}
                  />
                );
              }
            );

            return children;
          }, [])}
    </UiContainer>
  );
};

export default DynamicComponent;
