import Select, { enumToOptions } from '../Select/Select';
import { forwardRef, useMemo } from 'react';

const marketProvidersBetstream = enumToOptions([
  'betradar',
  'betradar_wac',
  'betradar_tanktech',
  'betradar_herogaming',
  'betradar_replay',
  'cns',
  'betgenius',
  'dst',
  'oddin',
  'oddin_2',
  'redfeed',
  'lsport',
  'betby_trading',
  'tradeart',
  'pandascore',
]);

const marketProvidersReports = enumToOptions([
  'dst',
  'cns',
  'betgenius',
  'oddin',
  'oddin_2',
  'redfeed',
  'betradar_wac',
  'betradar_7777',
  'betradar_windaddy',
  'betradar_tanktech',
  'betradar_herogaming',
  'betradar_2',
  'betradar',
  'tvbet',
  'betradar_replay',
  'lsport',
  'tradeart',
  'betby_trading',
  'pandascore',
]);

const MarketProviderSelect = forwardRef(({ multiple, type, ...props }, ref) => {
  const options = useMemo(() => {
    switch (type) {
      case 'betstream':
        return marketProvidersBetstream;
      case 'reports':
        return marketProvidersReports;
      default:
        return marketProvidersBetstream;
    }
  }, [type]);

  return (
    <Select
      ref={ref}
      label={'Market provider'}
      multiple={multiple}
      options={options}
      {...props}
    />
  );
});

export default MarketProviderSelect;
