import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { UiOptionsContext } from './UiOptionsProvider';

const useStyles = makeStyles({
  emptyFieldPlaceholder: {
    color: '#8496AB',
    padding: '0 8px',
  },
});

const EmptyFieldPlaceholder = () => {
  const classes = useStyles();

  const { value } = useContext(UiOptionsContext) || {};
  const [width] = useState(value.width || 'initial');

  return (
    <span
      className={classes.emptyFieldPlaceholder}
      style={{
        width,
      }}
    >
      —
    </span>
  );
};

export default EmptyFieldPlaceholder;
