import { useCallback } from 'react';
import useDebounced from '../../../hooks/useDebounced';
import TextField from '../TextField';

const SearchInput = ({
  label = 'Input',
  searchValue = '',
  setSearchValue = () => {},
}) => {
  const debouncedOnChangeSearch = useDebounced(setSearchValue, 300);

  const onChange = useCallback(
    event => {
      debouncedOnChangeSearch && debouncedOnChangeSearch(event.target.value);
    },
    [debouncedOnChangeSearch]
  );

  return (
    <div style={{ width: '400px' }}>
      <TextField label={label} onChange={onChange} />
    </div>
  );
};

export default SearchInput;
