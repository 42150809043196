/* eslint-disable no-undef, no-restricted-globals */

export const SocketStatusEnum = {
  DISABLED: 'disabled',
  ACTIVE: 'active',
  CONNECTING: 'connecting',
  ERROR: 'error',
};

class Socket {
  constructor(url) {
    this.url = url;
    this.status = SocketStatusEnum.CONNECTING;
    this.connect(url);
    this.attempt = 0;
  }

  connect = url => {
    this.setStatus(SocketStatusEnum.CONNECTING);

    this.connection = new WebSocket(url);

    this.connection.onopen = () => {
      this.setStatus(SocketStatusEnum.ACTIVE);

      this.keepAlive();
    };

    this.connection.onmessage = message => {
      let data = message.data;

      try {
        data = JSON.parse(data);
      } catch (e) {
        console.error('Unable to parse socket response');
      }

      if (
        data.command &&
        data.command === 'ping' &&
        this.sentPingMessage === data.payload.message
      ) {
        this.keepAlive();
      }

      return this.onMessageCallback(data);
    };

    this.connection.onerror = e => {
      this.setStatus(SocketStatusEnum.ERROR);

      this.close();
      if (this.attempt < 3) {
        this.tryReconnect(this.attempt * 2000);
        this.attempt += 1;
      }
    };

    this.connection.onclose = e => {};
  };

  tryReconnect = delay => {
    setTimeout(() => {
      this.connect(this.url);
    }, delay);
  };

  keepAlive = () => {
    if (this.pingTimeout) {
      this.pingTimeout = clearTimeout(this.pingTimeout);
      this.attempt = 0;
    }

    this.pingTimeout = setTimeout(() => this.pingConnection(), 5000);
  };

  pingConnection = connection => {
    const message = new Date().getTime();

    this.sentPingMessage = message;

    this.connection.send(
      JSON.stringify({
        command: 'ping',
        payload: {
          message,
        },
      })
    );

    this.waitPingMessage(connection);
  };

  sendMessage = data => {
    if (this.connection && this.status === SocketStatusEnum.ACTIVE) {
      return this.connection.send(JSON.stringify(data));
    }
  };

  waitPingMessage = connection => {
    this.pingTimeout = setTimeout(() => {
      this.connection.onerror(new Error('Socket ping failed'));
    }, 5000);
  };

  setStatus = status => {
    this.status = status;
    this.onChangeStatusCallback && this.onChangeStatusCallback(status);
  };

  onMessage = onMessageCallback => {
    this.onMessageCallback = onMessageCallback;
  };

  onChangeStatus = onChangeStatusCallback => {
    this.onChangeStatusCallback = onChangeStatusCallback;
  };

  close = () => {
    clearTimeout(this.pingTimeout);
    this.connection.close();
  };
}

export default Socket;
