import React, { createContext, useContext } from 'react';
import StaticFieldListProvider, {
  StaticFieldListContext,
} from './StaticFieldListProvider';

export const TableContext = createContext(false);

export const useIsTableContext = () => {
  return useContext(TableContext);
};

const TableProvider = ({ value = true, ...props }) => {
  const staticFieldList = useContext(StaticFieldListContext);

  if (staticFieldList) {
    return (
      <StaticFieldListProvider value={false}>
        <TableContext.Provider value={value} {...props} />
      </StaticFieldListProvider>
    );
  }
  return <TableContext.Provider value={value} {...props} />;
};

export default TableProvider;
