import React, { useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import FancyTable from '../../components/Table/FancyTable';
import StringField from '../../components/Fields/StringField/StringField';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import useTableColumns from '../../hooks/useTableColumns';
import TextField from '../../components/Inputs/TextField';
import Button from '@mui/material/Button';
import LinkField from '../../components/Fields/LinkField/LinkField';
import Select from '../../components/Inputs/Select/Select';
import InlineFormField from '../../components/Form/InlineFormField';

const useStyles = makeStyles(() => ({
  resultSelect: {
    height: 30,
  },
  applyButton: {
    fontSize: 11,
    padding: '8px 24px',
    height: 30,
    minWidth: 'min(100%, 66px)',
  },
}));

const commonCellStyle = {
  padding: '0px 16px',
};
const centeredCellStyle = {
  ...commonCellStyle,
  justifyContent: 'center',
  textAlign: 'center',
};

export const COLUMN = {
  EVENT: 'event_id',
  MARKET_ID: 'market_id',
  SPORT: 'sport_id',
  MARKET_NAME: 'market_name',
  OUTCOME_NAME: 'outcome_name',
  SPECIFIERS: 'specifiers',
  OPEN_BETS: 'open_bets',
  OPEN_SINGLE_BETS: 'open_single_bets',
  RESULT: 'result',
  APPLY: 'apply',
  DEAD_HEAT_FACTOR: 'dead_heat_factor',
};

export const columnsWidthConfig = {
  [COLUMN.EVENT]: 2.5,
  [COLUMN.MARKET_ID]: 2,
  [COLUMN.SPORT]: 2,
  [COLUMN.MARKET_NAME]: 3,
  [COLUMN.OUTCOME_NAME]: 3,
  [COLUMN.SPECIFIERS]: 2,
  [COLUMN.OPEN_BETS]: 2,
  [COLUMN.OPEN_SINGLE_BETS]: 2,
  [COLUMN.RESULT]: 3,
  [COLUMN.APPLY]: 2,
  [COLUMN.DEAD_HEAT_FACTOR]: 2.5,
};

export const columnsOrderConfig = [
  COLUMN.EVENT,
  COLUMN.MARKET_ID,
  COLUMN.SPORT,
  COLUMN.MARKET_NAME,
  COLUMN.OUTCOME_NAME,
  COLUMN.SPECIFIERS,
  COLUMN.OPEN_BETS,
  COLUMN.OPEN_SINGLE_BETS,
  COLUMN.RESULT,
  COLUMN.APPLY,
  COLUMN.DEAD_HEAT_FACTOR,
];

export const initialToggledColumns = [
  COLUMN.EVENT,
  COLUMN.MARKET_ID,
  COLUMN.SPORT,
  COLUMN.MARKET_NAME,
  COLUMN.OUTCOME_NAME,
  COLUMN.SPECIFIERS,
  COLUMN.OPEN_BETS,
  COLUMN.OPEN_SINGLE_BETS,
  COLUMN.RESULT,
  COLUMN.APPLY,
  COLUMN.DEAD_HEAT_FACTOR,
];

const validationSchema = yup.object().shape({
  [COLUMN.RESULT]: yup.string(),
  [COLUMN.DEAD_HEAT_FACTOR]: yup.string().nullable(),
});

const resultSelectOptions = [
  { label: 'Win', value: 'win' },
  { label: 'Half-Win', value: 'half_win' },
  { label: 'Lost', value: 'lost' },
  { label: 'Half-Lost', value: 'half_lost' },
  { label: 'Refund', value: 'refund' },
  { label: 'Open', value: 'open' },
];

export const allOptions = [
  ...resultSelectOptions,
  { label: 'Canceled', value: 'canceled' },
];

const uniqueIdParts = [
  'outcome_id',
  'outcome_name',
  'market_id',
  'market_name',
  'specifiers',
];

const getUniqueOutcomeId = item => {
  return uniqueIdParts.reduce((acc, idPart) => {
    return `${acc}${item[idPart]}`;
  }, '');
};

const OutcomesTable = ({
  items,
  loading,
  tableOffsetTop,
  eventName,
  sportName,
  onUpdate,
  initialOrderDirection,
  orderBy,
  onChangeOrderBy = () => {},
}) => {
  const classes = useStyles();

  const [changedOutcomes, setChangedOutcomes] = useState({});

  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns
  );

  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    onChangeColumnsWidth: () => {},
  });

  const isShowApplyButton = useCallback(
    data => {
      return (
        changedOutcomes[getUniqueOutcomeId(data)] &&
        !changedOutcomes[getUniqueOutcomeId(data)].isSubmitted
      );
    },
    [changedOutcomes]
  );

  const isDeadHeatFactorDisabled = useCallback(
    data => {
      const initialResult = data[COLUMN.RESULT];
      const changedResult =
        changedOutcomes[getUniqueOutcomeId(data)]?.[COLUMN.RESULT];

      return !resultSelectOptions.find(
        ({ value }) => value === (changedResult || initialResult)
      );
    },
    [changedOutcomes]
  );

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'Event',
        key: COLUMN.EVENT,
        style: commonCellStyle,
        render: data => {
          return (
            <LinkField href={`/events/${data[COLUMN.EVENT]}`}>
              {eventName}
            </LinkField>
          );
        },
        resizable: true,
      },
      {
        label: 'Market Id',
        key: COLUMN.MARKET_ID,
        style: centeredCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.MARKET_ID]}</StringField>;
        },
        resizable: true,
        sortkey: 'market_id',
      },
      {
        label: 'Sport',
        key: COLUMN.SPORT,
        style: commonCellStyle,
        render: () => {
          return <StringField>{sportName}</StringField>;
        },
        resizable: true,
        sortkey: 'sport_id',
      },
      {
        label: 'Market Name',
        key: COLUMN.MARKET_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.MARKET_NAME]}</StringField>;
        },
        resizable: true,
        sortkey: 'market_id',
      },
      {
        label: 'Outcome Name',
        key: COLUMN.OUTCOME_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.OUTCOME_NAME]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Specifiers',
        key: COLUMN.SPECIFIERS,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.SPECIFIERS]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Open Bets',
        key: COLUMN.OPEN_BETS,
        style: centeredCellStyle,
        render: data => {
          return (
            <LinkField
              href={`/api/v1/ReporterAPI/report/outcome/detail/${data.event_id}/${data.market_id}/${data.outcome_id}/get`}
            >
              {data[COLUMN.OPEN_BETS]}
            </LinkField>
          );
        },
        resizable: true,
        sortkey: 'open_bets',
      },
      {
        label: 'Open Single Bets',
        key: COLUMN.OPEN_SINGLE_BETS,
        style: centeredCellStyle,
        render: data => {
          return (
            <LinkField
              href={`/api/v1/ReporterAPI/report/outcome/detail/${data.event_id}/${data.market_id}/${data.outcome_id}/get`}
            >
              {data[COLUMN.OPEN_SINGLE_BETS]}
            </LinkField>
          );
        },
        resizable: true,
        sortkey: 'open_single_bets',
      },
      {
        label: 'Result',
        key: COLUMN.RESULT,
        style: {
          ...commonCellStyle,
          minWidth: 120,
        },
        render: data => {
          return (
            <InlineFormField name={COLUMN.RESULT}>
              <Select
                label={null}
                multiple={false}
                options={resultSelectOptions}
                className={classes.resultSelect}
                size="small"
                disableClearable
                initialValue={{
                  label: allOptions.find(
                    option => option.value === data[COLUMN.RESULT]
                  )?.label,
                  value: data[COLUMN.RESULT],
                }}
              />
            </InlineFormField>
          );
        },
        resizable: true,
      },
      {
        label: 'Confirmation',
        isLabelHidden: true,
        key: COLUMN.APPLY,
        style: commonCellStyle,
        render: data => {
          return (
            isShowApplyButton(data) && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.applyButton}
              >
                Apply
              </Button>
            )
          );
        },
        resizable: true,
      },
      {
        label: 'Dead Heat Factor',
        key: COLUMN.DEAD_HEAT_FACTOR,
        style: {
          ...centeredCellStyle,
          minWidth: 100,
        },
        render: data => {
          return (
            <InlineFormField name={COLUMN.DEAD_HEAT_FACTOR}>
              <TextField
                disabled={isDeadHeatFactorDisabled(data)}
                label={null}
                type="number"
                size="small"
                inputProps={{
                  min: '0',
                  step: '0.1',
                  max: '1',
                }}
              />
            </InlineFormField>
          );
        },
        resizable: true,
      },
    ],
    order: columnsOrder,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    inlineForm: true,
    inlineFormProps: {
      mode: 'onSubmit',
      schema: validationSchema,
      onChange: (rowId, name, v) => {
        setChangedOutcomes({
          ...changedOutcomes,
          [rowId]: { ...changedOutcomes[rowId], [name]: v, isSubmitted: false },
        });
        return new Promise(() => {});
      },
      onSubmit: data => {
        onUpdate(data, () =>
          setChangedOutcomes({
            ...changedOutcomes,
            [getUniqueOutcomeId(data)]: {
              ...changedOutcomes[getUniqueOutcomeId(data)],
              isSubmitted: true,
            },
          })
        );
      },
      debounceTime: 0,
    },
  });

  return (
    <FancyTable
      id={uniqueIdParts}
      loading={loading}
      data={items}
      renderRow={renderRow}
      renderHeaderRow={
        <TableHeaderRow
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorderColumns}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
          onChangeColumnWidth={changeColumnWidth}
          initialOrderDirection={initialOrderDirection}
          orderBy={orderBy}
          onChangeOrderBy={onChangeOrderBy}
        />
      }
      rowHeight={38}
      tableOffsetTop={tableOffsetTop}
    />
  );
};

export default OutcomesTable;
