import makeStyles from '@mui/styles/makeStyles';
import HelpIcon from '../../../icons/controls/HelpIcon';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles({
  formGroupLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.5px',
    display: 'inline-flex',
  },

  labelHelpIcon: {
    fontSize: '16px',
    margin: '0 8px',
    cursor: 'pointer',
  },

  labelHelpTooltip: {
    maxWidth: 400,
  },
});

const FormGroupLabel = ({ children, helpText }) => {
  const classes = useStyles();

  return (
    <div className={classes.formGroupLabel}>
      {children}
      {helpText && (
        <Tooltip
          title={helpText}
          classes={{
            tooltip: classes.labelHelpTooltip,
          }}
        >
          <div>
            <HelpIcon className={classes.labelHelpIcon} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default FormGroupLabel;
