import Select from '../Select/Select';
import { forwardRef } from 'react';

const DeviceSelect = forwardRef(({ ...props }, ref) => {
  return (
    <Select
      ref={ref}
      options={[
        { label: 'mobile', value: 'mobile' },
        { label: 'desktop', value: 'desktop' },
        { label: 'unknown', value: 'unknown' },
      ]}
      {...props}
    />
  );
});

export default DeviceSelect;
