import FieldCopyAction from '../../../../uiComponents/Field/FieldCopyAction';
import React, { forwardRef, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  fieldWithCopy: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  fieldContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&>div': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  reversed: {
    flexDirection: 'row-reverse',
  },

  copyContainer: {
    flexGrow: 1,
  },
});

const CopyField = memo(
  forwardRef(({ value, children, reverse = false, ...props }, ref) => {
    const classes = useStyles();

    return (
      <div
        className={clsx(classes.fieldWithCopy, { [classes.reversed]: reverse })}
        ref={ref}
        {...props}
      >
        <div className={classes.fieldContainer}>{children}</div>
        <div className={classes.copyContainer}>
          <FieldCopyAction value={value} />
        </div>
      </div>
    );
  })
);

export default CopyField;
