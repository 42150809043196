import { forwardRef, useCallback, useMemo, useState } from 'react';
import BaseAutocomplete from '../Autocomplete';
import useAutocompleteData from '../../../hooks/useAutocompleteData';
import {
  getOptionLabel,
  isOptionEqualToValue,
  useProxyValue,
} from '../AutocompleteChips/AutocompleteChips';

const Autocomplete = forwardRef(
  (
    {
      name,
      value,
      onChange,
      helperText,
      error,
      disabled,
      label,
      required,
      url,
      additionalParams,
      valueKey = 'id',
      groupBy,
    },
    ref
  ) => {
    const [innerInputValue, setInnerInputValue] = useState(null);

    const params = useMemo(() => {
      return {
        query: innerInputValue,
        ...additionalParams,
      };
    }, [innerInputValue, additionalParams]);

    const { data: options = [], loading } = useAutocompleteData({
      url,
      params,
    });

    const [proxyValue, proxyOnChange] = useProxyValue(
      value,
      onChange,
      loading ? [] : options,
      valueKey
    );

    const onChangeCb = useCallback(
      (e, value) => proxyOnChange(value),
      [proxyOnChange]
    );

    return (
      <BaseAutocomplete
        ref={ref}
        name={name}
        disabled={disabled}
        popupIcon={null}
        required={required}
        clearOnBlur
        options={options}
        filterSelectedOptions
        blurOnSelect
        isOptionEqualToValue={isOptionEqualToValue(valueKey)}
        loading={loading}
        renderTags={() => null}
        getOptionLabel={getOptionLabel}
        groupBy={option => option.type}
        InputProps={{
          label,
          helperText,
          error,
        }}
        onInputChange={(e, v) => {
          if (e) {
            setInnerInputValue(v);
          }
        }}
        value={proxyValue}
        onChange={onChangeCb}
      />
    );
  }
);

export default Autocomplete;
