import get from 'lodash/get';

export const getListColumns = (schema, uiSchema = {}) => {
  const { 'ui:order': uiOrder } = uiSchema;

  return (uiOrder || Object.keys(schema.items.properties)).map(key => {
    const fieldSchema = get(schema, ['items', 'properties', key]);
    const fieldUiSchema = get(uiSchema, ['items', key]);

    return {
      fieldKey: key,
      schema: fieldSchema,
      uiSchema: fieldUiSchema,
    };
  });
};
