import DataProvider from '../../providers/DataProvider';
import * as React from 'react';
import CardContainer from './CardContainer';
import { useRoutes } from '../../providers/RoutesProvider';

const DataCardContainer = ({ path, params, ...props }) => {
  const routes = useRoutes();

  return (
    <DataProvider path={path} params={params} routes={routes} {...props}>
      <CardContainer />
    </DataProvider>
  );
};

export default DataCardContainer;
