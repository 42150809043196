import { gql } from '@apollo/client';
import marginLadderFragment from '../fragments/marginLadderFragment';

const deleteMarginLadderPairMutation = gql`
  ${marginLadderFragment}
  mutation deleteMarginLadderPair(
    $ladderId: LadderId!
    $pair: MarginPairInput!
  ) {
    deleteMarginLadderPair(ladder_id: $ladderId, pair: $pair) {
      ...MarginLadderFragment
    }
  }
`;

export default deleteMarginLadderPairMutation;
