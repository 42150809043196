import { gql } from '@apollo/client';
import { UPDATE_VERSIONED_RESULT_TYPE } from './updateSourcesTemplate';

const updateMarginTemplate = gql`
  ${UPDATE_VERSIONED_RESULT_TYPE}
  mutation updateMarginTemplate(
    $templateId: TemplateId!
    $input: MarginTemplateInput!
  ) {
    data: updateMarginTemplate(templateId: $templateId, input: $input) {
      ...UpdateVersionedResultType
    }
  }
`;

export default updateMarginTemplate;
