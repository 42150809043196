import RelatedContingencyAutocomplete from './RelatedContingencyAutocomplete';
import { gql } from '@apollo/client';
import { useMemo } from 'react';

export const MARKETS_QUERY = gql`
  query getMarketsNames(
    $query: String!
    $limit: Int
    $offset: Int
    $sport_ids: [ID!]
    $market_ids: [MarketId!]
  ) {
    data: getMarketsNames(
      query: $query
      limit: $limit
      offset: $offset
      sport_ids: $sport_ids
      market_ids: $market_ids
    ) {
      label
      value
    }
  }
`;

const MarketAutocomplete = ({ value, sports, onChange, ...props }) => {
  const params = useMemo(
    () => ({
      sport_ids: sports,
    }),
    [sports]
  );

  return (
    <RelatedContingencyAutocomplete
      allLabel={'All Markets'}
      label={'Select Market'}
      gqlQuery={MARKETS_QUERY}
      params={params}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default MarketAutocomplete;
