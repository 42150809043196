import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TableActionCards from '../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../components/TablePagination/TablePagination';
import getBrandNames from '../../../gql/sportbook-config/line-settings/getBrands';
import getBrandSettingsTable from '../../../gql/sportbook-config/line-settings/getLineSettingsTable';
import useSearchInput from '../../../hooks/useSearchInput';
import useTablePagination from '../../../hooks/useTablePagination';
import { ACCESS_ROLE } from '../../../providers/SportsBookUserAccessRolesProvider';
import LineSettingsTable from './LineSettingsTable';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../components/ErrorWidget';

const LineSettingsTab = ({ roles }) => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.LineSettingsTab,
    onChangeLimit: () => {},
  });

  const { data: { data: brandData } = {}, error: brandNamesError } =
    useQuery(getBrandNames);

  const searchOptions = useMemo(() => {
    const brands =
      brandData &&
      brandData.map(value => {
        const brand = value.brand;

        return (
          brand && {
            label: brand.name,
            value: brand.id,
          }
        );
      });

    return brands || [];
  }, [brandData]);

  const navigate = useNavigate();

  const { searchValue, searchInputCard } = useSearchInput({
    label: 'Brand name',
    options: searchOptions,
    multiple: true,
  });

  useEffect(() => {
    setOffset(0);
  }, [searchValue, setOffset]);

  const queryFilters = useMemo(() => {
    return {
      limit,
      offset,
    };
  }, [limit, offset]);

  const variables = useMemo(() => {
    return {
      limit,
      offset,
      brandIds: searchValue,
    };
  }, [limit, offset, searchValue]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(getBrandSettingsTable, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const items = useMemo(() => {
    return (data && data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  const actions = useMemo(() => {
    return [searchInputCard];
  }, [searchInputCard]);

  const rowActions = useMemo(() => {
    const editAction = {
      key: 'editLineSettings',
      label: 'Edit Settings',
      onClick: item => {
        navigate(`/line-settings/line/edit/${item.id}`, { state: item });
      },
    };
    const duplicateAction = {
      key: 'duplicateLineSettings',
      label: 'Duplicate',
      onClick: item => {
        navigate(`/line-settings/line/edit/${item.id}?duplicate=true`, {
          state: item,
        });
      },
    };
    const initialActions = [editAction];

    if (
      roles?.coverage === ACCESS_ROLE.WRITE &&
      roles?.sources === ACCESS_ROLE.WRITE
    ) {
      initialActions.push(duplicateAction);
    }

    return initialActions;
  }, [navigate, roles]);

  if (error || brandNamesError) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TableActionCards actions={actions} inline />
      <LineSettingsTable
        data={items}
        loading={loading}
        error={error}
        rowActions={rowActions}
      />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default LineSettingsTab;
