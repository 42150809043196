import { Box, Stack, Typography } from '@mui/material';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withoutEmpty } from '../../hooks/usePresetFilters';
import useQuery from '../../hooks/useQuery';
import Button from '../Button/Button';
import Checkbox from '../Inputs/Checkbox';
import RangeDateInput from '../Inputs/RangeDateInput';
import TextField from '../Inputs/TextField';
import Tooltip from '../Tooltip/Tooltip';
import styles from './FilterSidebar.styles';

const resolveDefaultValue = (item, filter) => {
  if (item.defaultValue) {
    return item.defaultValue;
  } else {
    switch (filter.type) {
      case 'checkbox': {
        return false;
      }
      case 'text': {
        return '';
      }
      case 'date': {
        return {
          rangeFrom: null,
          rangeTo: null,
        };
      }
      default:
        return null;
    }
  }
};

const normalizeToURL = (content, data) => {
  return _.omitBy(
    _.transform(
      data,
      (acc, value, key) => {
        const filter = content.filters.find(filter => filter.value === key);

        if (filter.type === 'checkbox') {
          acc[key] = _.omitBy(value, v => !v);
        } else if (filter.type === 'text') {
          if (value) {
            acc[key] = value;
          }
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    ),
    _.isEmpty
  );
};

const generateDefaultData = content =>
  content.filters.reduce((acc, el, index, arr) => {
    acc[el.value] = {
      ...el.items.reduce((acc, el) => {
        acc[el.value] = resolveDefaultValue(el, arr[index]);
        return acc;
      }, {}),
    };
    return acc;
  }, {});

const FilterSidebar = ({ content }) => {
  const classes = styles();
  const navigate = useNavigate();
  const queryString = useQuery();

  const [defaultValues, setDefaultValues] = useState({});
  const [data, setData] = useState({});
  const [limitedFilters, setLimitedFilters] = useState([]);

  useEffect(() => {
    const defaultData = generateDefaultData(content);

    setDefaultValues(defaultData);
    if (!Object.keys(data).length) {
      setData(defaultData);
    }
  }, [content, data]);

  useEffect(() => {
    const appliedFilters = JSON.parse(queryString.get('filters'));

    const newDefaultValues = _.merge(
      generateDefaultData(content),
      appliedFilters
    );

    if (appliedFilters) {
      setDefaultValues(newDefaultValues);
      if (!Object.keys(data).length) {
        setData(newDefaultValues);
        content.onApply(newDefaultValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!queryString.get('filters') && !isEmpty(defaultValues)) {
      setData(defaultValues);
      navigate({
        search: `filters=${JSON.stringify(
          normalizeToURL(content, defaultValues)
        )}`,
      });
      content.onApply(defaultValues);
    }
  }, [queryString, navigate, defaultValues, content]);

  const handleResetFiltersClick = () => {
    setData(defaultValues);
  };
  const handleApplyFiltersClick = () => {
    navigate({
      search: `filters=${JSON.stringify(normalizeToURL(content, data))}`,
    });
    content.onApply(data);
  };

  const handleApply = appliedFilters => {
    const { sport, date, providers, status, advanced, type } = appliedFilters;

    const newFilters = {
      date: date.range,
      sport: Object.keys(sport).filter(key => sport[key]),
      providers: Object.keys(providers).filter(key => providers[key]),
      status: Object.keys(status).filter(key => status[key]),
      ...((type.event || type.outright) && { isOutright: !!type.outright }),
      query: advanced.query,
    };

    const isTheSameFilters =
      JSON.stringify(selectedFilters) === JSON.stringify(newFilters);

    if (isTheSameFilters) {
      refetchGroups({
        limit,
        offset,
        ...withoutEmpty(selectedFilters, { removeEmptyArrays: true }),
      });
    } else {
      setSelectedFilters(newFilters);
    }
    setOffset(0);
  };

  const resolveItem = (item, filter) => {
    const size = item.fullWidth ? 268 : 126;

    switch (filter.type) {
      case 'checkbox':
        return (
          <Stack
            key={item.value}
            direction={'row'}
            justifyContent={'space-between'}
            className={classes.filterItem}
            style={{ minWidth: `${size}px`, maxWidth: `${size}px` }}
          >
            <Checkbox
              value={data?.[filter.value]?.[item.value] || false}
              extraClasses={{
                formControl: classes.checkboxLabel,
                checkboxRoot: classes.checkboxRoot,
              }}
              onChange={value =>
                setData(prevState => ({
                  ...prevState,
                  [filter.value]: {
                    ...prevState[filter.value],
                    [item.value]: value,
                  },
                }))
              }
            />

            <Tooltip
              tooltipClassName={classes.filterItemTooltip}
              title={item.label}
              overflowOnly
            >
              <div className={classes.filterItemLabel}>{item.label}</div>
            </Tooltip>

            {!_.isNil(item.caption) && (
              <Typography className={classes.filterItemCaption}>
                {item.caption}
              </Typography>
            )}
          </Stack>
        );
      case 'text':
        return (
          <TextField
            className={classes.textField}
            placeholder={item.placeholder}
            extraClasses={{
              root: classes.textField,
            }}
            value={data?.[filter.value]?.[item.value]}
            onChange={e => {
              const value = e.target.value;
              setData(prevState => ({
                ...prevState,
                [filter.value]: {
                  ...prevState[filter.value],
                  [item.value]: value,
                },
              }));
            }}
          />
        );
      case 'date':
        return (
          <RangeDateInput
            key={item.value}
            className={classes.datepicker}
            fullWidth
            label={item.label}
            value={data?.[filter.value]?.[item.value] || null}
            rangeFromKey={'rangeFrom'}
            rangeToKey={'rangeTo'}
            onChange={value =>
              setData(prevState => ({
                ...prevState,
                [filter.value]: {
                  ...prevState[filter.value],
                  [item.value]: value,
                },
              }))
            }
          />
        );
      default:
        return null;
    }
  };

  const renderMoreButton = filterValue => {
    return (
      <Box className={classes.showMoreContainer}>
        <Button
          variant={'text'}
          onClick={() =>
            setLimitedFilters(prevState => [...prevState, filterValue])
          }
        >
          Show more
        </Button>
      </Box>
    );
  };

  const renderLessButton = filterValue => {
    return (
      <Box className={classes.showMoreContainer}>
        <Button
          variant={'text'}
          onClick={() =>
            setLimitedFilters(prevState =>
              prevState.filter(el => el !== filterValue)
            )
          }
        >
          Show less
        </Button>
      </Box>
    );
  };

  const renderSelectAllOptionsCheckbox = filter => {
    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        style={{ minWidth: `126px`, maxWidth: `126px` }}
      >
        <Checkbox
          label={'All'}
          value={
            _.size(data[filter.value])
              ? Object.keys(data[filter.value]).every(
                  key => !!data[filter.value][key]
                )
              : false
          }
          extraClasses={{
            formControl: classes.checkboxLabel,
            checkboxRoot: classes.checkboxRoot,
          }}
          onChange={value =>
            setData(prevState => ({
              ...prevState,
              [filter.value]: {
                ...prevState[filter.value],
                ...filter.items.reduce((acc, el) => {
                  acc[el.value] = value;
                  return acc;
                }, {}),
              },
            }))
          }
        />
      </Stack>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Stack className={classes.body} gap={'18.5px'}>
          {content.filters.map((filter, index) => (
            <Stack key={filter.value} className={classes.filter} gap={'16.5px'}>
              {filter.label && (
                <Typography className={classes.filterLabel}>
                  {filter.label}
                </Typography>
              )}
              <Stack
                className={classes.filterItems}
                rowGap={'13px'}
                direction={'column'}
              >
                {filter.allowToSelectAll &&
                  renderSelectAllOptionsCheckbox(filter)}
                {(() => {
                  const chunks = _.chunk(filter.items, 2);
                  if (!limitedFilters.includes(filter.value)) {
                    const visible = _.slice(chunks, 0, filter.maxRows);
                    return visible.map(chunk => (
                      <Stack
                        key={chunk[0].value + chunk[1]?.value}
                        direction={'row'}
                        gap={'16px'}
                      >
                        {chunk.map(item =>
                          resolveItem(item, content.filters[index])
                        )}
                      </Stack>
                    ));
                  } else {
                    return chunks.map(chunk => (
                      <Stack
                        direction={'row'}
                        gap={'16px'}
                        key={chunk[0].value + chunk[1]?.value}
                      >
                        {chunk.map(item =>
                          resolveItem(item, content.filters[index])
                        )}
                      </Stack>
                    ));
                  }
                })()}
              </Stack>
              {(() => {
                const chunks = _.chunk(filter.items, 2);
                const visible = _.slice(chunks, 0, filter.maxRows);

                if (visible < chunks) {
                  return !limitedFilters.includes(filter.value)
                    ? renderMoreButton(filter.value)
                    : renderLessButton(filter.value);
                } else {
                  return null;
                }
              })()}
            </Stack>
          ))}
        </Stack>
        <Stack
          className={classes.footer}
          direction={'row'}
          justifyContent={'space-around'}
        >
          <Button onClick={handleResetFiltersClick} color={'lightBlue'}>
            Reset
          </Button>
          <Button onClick={handleApplyFiltersClick}>Apply</Button>
        </Stack>
      </>
    );
  };

  return (
    <Stack justifyContent={'space-between'} className={classes.sidebar}>
      {!content.loading && renderContent()}
    </Stack>
  );
};

export default FilterSidebar;
