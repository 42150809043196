import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Page from '../../../components/Page/Page';
import MarginLadderActionsPanel from './MarginLadderActionsPanel';
import MarginLadderTable from './MarginLadderTable';
import TablePagination from '../../../components/TablePagination/TablePagination';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import getMarginLaddersQuery from '../../../gql/TradingTool/queries/getMarginLaddersQuery';
import deleteMarginLadderMutation from '../../../gql/TradingTool/mutations/deleteMarginLadderMutation';
import useFilters from '../../../hooks/useFilters';
import { withoutEmpty } from '../../../hooks/usePresetFilters';
import useTablePagination from '../../../hooks/useTablePagination';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';
import { ConfirmationPromptContext } from '../../../../providers/ConfirmationPromptProvider';
import { useToasts } from '../../../providers/ToastsProvider';
import getMarginLadderDetailsQuery from '../../../gql/TradingTool/queries/getMarginLadderDetailsQuery';
import MarginLadderDetailsTable from './MarginLadderDetailsTable';

const defaultFilters = {
  name: null,
  sport: null,
};

const MarginLadderPage = () => {
  const navigate = useNavigate();
  const { openConfirmation } = useContext(ConfirmationPromptContext);
  const { showToast } = useToasts();

  const [activeRow, setActiveRow] = useState(null);

  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.MarginLadders,
    onChangeLimit: () => {},
  });

  const { filters, setFiltersQuery } = useFilters({
    tableName: tableNames.MarginLadders,
    resetOffset: setOffset,
    defaultValues: defaultFilters,
    hidePresets: true,
  });

  const queryFilters = useMemo(() => {
    return {
      name: filters.name,
      sport_id: filters.sport?.id,
    };
  }, [filters]);

  const {
    data: { data: { items = [], total = 0 } = {} } = {},
    loading,
    refetch,
  } = useQuery(getMarginLaddersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        ...queryFilters,
        limit,
        offset,
      },
    },
  });

  const {
    data: { data: { items: ladderItems = [] } = {} } = {},
    loading: ladderLoading,
  } = useQuery(getMarginLadderDetailsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ladderId: activeRow,
    },
    skip: !activeRow,
  });

  const [removeMarginLadder] = useMutation(deleteMarginLadderMutation);

  const onSearchSport = useCallback(
    value => {
      const newFilters = {
        ...filters,
        sport: value,
      };

      setFiltersQuery({ ...newFilters });
      setOffset(0);
    },
    [filters, setFiltersQuery, setOffset]
  );

  const onSearchLadder = useCallback(
    value => {
      const newFilters = {
        ...filters,
        name: value,
      };

      setFiltersQuery({ ...newFilters });
      setOffset(0);
    },
    [filters, setFiltersQuery, setOffset]
  );

  const onCreateButtonClick = useCallback(() => {
    navigate(`/trading-margin-ladders/create`);
  }, [navigate]);

  const rowActions = useMemo(() => {
    return [
      {
        key: 'edit',
        label: 'Edit',
        onClick: ({ id }) => {
          navigate(`/trading-margin-ladders/edit/${id}`);
        },
      },
      {
        key: 'remove',
        label: 'Remove',
        style: {
          color: '#FF000F',
        },
        onClick: ({ id }) => {
          openConfirmation({
            buttonLabel: 'Remove',
            title: 'Are you sure you want to remove this ladder?',
            destructive: true,
            callback: async () => {
              removeMarginLadder({
                variables: {
                  ladderId: id,
                },
              })
                .then(() => {
                  showToast(
                    <NotificationAlert variant={AlertVariant.SUCCESS}>
                      Ladder deleted successfully
                    </NotificationAlert>
                  );

                  if (id === activeRow) {
                    setActiveRow(null);
                  }

                  refetch();
                })
                .catch(e => {
                  showToast(
                    <NotificationAlert variant={AlertVariant.ERROR}>
                      An error occurred while deleting the ladder
                    </NotificationAlert>
                  );
                });
            },
          });
        },
      },
    ];
  }, [
    navigate,
    openConfirmation,
    removeMarginLadder,
    showToast,
    activeRow,
    setActiveRow,
    refetch,
  ]);

  const ladderDetailsTableHeight = Math.max(items.length, 10) * 40; // 36px height + 4px gap

  return (
    <Page title={'Margin ladders'}>
      <MarginLadderActionsPanel
        filters={filters}
        onSearchSport={onSearchSport}
        onSearchLadder={onSearchLadder}
        onCreateButtonClick={onCreateButtonClick}
      />

      <Grid container spacing={2} wrap="nowrap" height="100%">
        <Grid item flexGrow={3}>
          <Box mt={2}>
            <MarginLadderTable
              loading={!items.length && loading}
              items={items}
              rowActions={rowActions}
              activeRow={activeRow}
              onRowClick={rowId => {
                setActiveRow(activeRow === rowId ? null : rowId);
              }}
            />
          </Box>

          <TablePagination
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            totalRows={total}
          />
        </Grid>
        {!!activeRow && (
          <Grid item flexGrow={1} max>
            <Box mt={2}>
              <MarginLadderDetailsTable
                items={ladderItems}
                loading={ladderLoading}
                rowsMaxHeight={ladderDetailsTableHeight}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default MarginLadderPage;
