import { gql } from '@apollo/client';

const CATEGORIES_AUTOCOMPLETE_QUERY = gql`
  query getCategories($sportId: SportId!, $query: String) {
    data: getCategories(sport_id: $sportId, name: $query) {
      id
      name
    }
  }
`;

export default CATEGORIES_AUTOCOMPLETE_QUERY;
