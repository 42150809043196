/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getDefaultRegistry,
  getUiOptions,
  getWidget,
  orderProperties,
  retrieveSchema,
} from 'react-jsonschema-form/lib/utils';
import get from 'lodash/get';
import Grid from '@mui/material/Grid';

class ObjectField extends Component {
  static defaultProps = {
    uiSchema: {},
    formData: {},
    errorSchema: {},
    idSchema: {},
    required: false,
    disabled: false,
    readonly: false,
  };

  state = {
    additionalProperties: {},
  };

  isRequired(name) {
    const schema = this.props.schema;
    return (
      Array.isArray(schema.required) && schema.required.indexOf(name) !== -1
    );
  }

  onPropertyChange = name => {
    return (value, errorSchema) => {
      const newFormData = {
        ...this.props.formData,
        [name]: value,
      };
      this.props.onChange(
        newFormData,
        errorSchema &&
          this.props.errorSchema && {
            ...this.props.errorSchema,
            [name]: errorSchema,
          }
      );
    };
  };

  getAvailableKey = preferredKey => {
    let index = 0;
    let newKey = preferredKey;
    while (this.props.formData.hasOwnProperty(newKey)) {
      newKey = `${preferredKey}-${++index}`;
    }
    return newKey;
  };

  onKeyChange = oldValue => {
    return (value, errorSchema) => {
      value = this.getAvailableKey(value, this.props.formData);
      const newFormData = { ...this.props.formData };
      const property = newFormData[oldValue];
      delete newFormData[oldValue];
      newFormData[value] = property;
      this.props.onChange(
        newFormData,
        errorSchema &&
          this.props.errorSchema && {
            ...this.props.errorSchema,
            [value]: errorSchema,
          }
      );
    };
  };

  getDefaultValue(type) {
    switch (type) {
      case 'string':
        return 'New Value';
      case 'array':
        return [];
      case 'boolean':
        return false;
      case 'null':
        return null;
      case 'number':
        return 0;
      case 'object':
        return {};
      default:
        // We don't have a datatype for some reason (perhaps additionalProperties was true)
        return 'New Value';
    }
  }

  handleAddClick = schema => () => {
    const type = schema.additionalProperties.type;
    const newFormData = { ...this.props.formData };
    newFormData[this.getAvailableKey('newKey', newFormData)] =
      this.getDefaultValue(type);
    this.props.onChange(newFormData);
  };

  render() {
    const {
      uiSchema,
      formData,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      idPrefix,
      onBlur,
      onFocus,
      onChange,
      registry = getDefaultRegistry(),
    } = this.props;
    const { definitions, fields, formContext, widgets, ObjectFieldTemplate } =
      registry;
    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, definitions, formData);
    const title = schema.title === undefined ? name : schema.title;
    const description = uiSchema['ui:description'] || schema.description;
    let orderedProperties;

    try {
      const properties = schema.additionalProperties
        ? Object.keys(formData)
        : Object.keys(schema.properties);

      orderedProperties = orderProperties(
        properties,
        uiSchema['ui:order']
      ).filter(name => get(uiSchema, [name, 'ui:widget']) !== 'hidden');
    } catch (err) {
      return (
        <div>
          <p className="config-error" style={{ color: 'red' }}>
            Invalid {name || 'root'} object field configuration:
            <em>{err.message}</em>.
          </p>
          <pre>{JSON.stringify(schema)}</pre>
        </div>
      );
    }

    const { widget } = getUiOptions(uiSchema);
    const Widget =
      (widget === 'codemirror' || widget === 'ImageCropUploader') &&
      getWidget(schema, widget, widgets);

    return (
      <ObjectFieldTemplate
        title={uiSchema['ui:title'] || title}
        description={description}
        TitleField={TitleField}
        DescriptionField={DescriptionField}
        required={required}
        idSchema={idSchema}
        uiSchema={uiSchema}
        schema={schema}
        formData={formData}
        formContext={formContext}
        onAddClick={this.handleAddClick}
      >
        <Grid container spacing={2}>
          {Widget ? (
            <Grid item xs={12}>
              <Widget
                value={formData}
                onChange={onChange}
                errorSchema={errorSchema}
                options={getUiOptions(uiSchema)}
              />
            </Grid>
          ) : (
            orderedProperties.map(name => (
              <Grid
                key={name}
                item
                xs={12}
                {...get(uiSchema, [name, 'ui:settings', 'size'], {})}
              >
                <SchemaField
                  name={name}
                  required={this.isRequired(name)}
                  schema={
                    schema.additionalProperties
                      ? schema.additionalProperties
                      : schema.properties[name]
                  }
                  uiSchema={uiSchema[name]}
                  errorSchema={errorSchema[name]}
                  idSchema={idSchema[name]}
                  idPrefix={idPrefix}
                  formData={formData[name]}
                  onKeyChange={this.onKeyChange(name)}
                  onChange={this.onPropertyChange(name)}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  registry={registry}
                  disabled={disabled}
                  readonly={readonly}
                />
              </Grid>
            ))
          )}
        </Grid>
      </ObjectFieldTemplate>
    );
  }
}

ObjectField.propTypes = {
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object,
  errorSchema: PropTypes.object,
  idSchema: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  registry: PropTypes.shape({
    widgets: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    ).isRequired,
    fields: PropTypes.objectOf(PropTypes.func).isRequired,
    definitions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
  }),
};

export default ObjectField;
