import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  marginContainer: {
    margin: 30,
  },
  marginHeader: {
    fontSize: 24,
    marginBottom: 16,
  },
  marginValue: {
    fontSize: 48,
  },
  marginSetButton: {
    marginLeft: 24,
    width: 160,
    height: 35,
  },
  marginInput: {
    minWidth: 160,
    maxWidth: 320,
    width: '100%',
  },
});

const MarginSettings = props => {
  const { id, schema } = props;

  const tempValues = {
    marginValue: '120%',
  };

  const classes = useStyles();

  return (
    <div className={classes.marginContainer}>
      <Typography variant="h6" color="primary" className={classes.marginHeader}>
        {schema.title}
      </Typography>
      <Typography variant="h6" color="primary" className={classes.marginValue}>
        {tempValues.marginValue}
      </Typography>
      <TextField type="text" id={id} className={classes.marginInput} />
      <Button
        variant="contained"
        color="primary"
        className={classes.marginSetButton}
      >
        Set margin
      </Button>
    </div>
  );
};

MarginSettings.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default MarginSettings;
