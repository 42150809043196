import { useContext, useMemo } from 'react';
import { DynEnvsContext } from '../providers/DynEnvsProvider';

const useFeatureBranches = () => {
  const data = useContext(DynEnvsContext);

  const getCurrentFeatureBranch = () => {
    const path = window.location.href;
    const startIndex = path.indexOf('feature-bt-');
    const endIndex = path.indexOf('.dyn.sptenv.com');
    if (startIndex !== -1 && endIndex !== -1) {
      return path.slice(startIndex, endIndex);
    } else {
      return 'develop';
    }
  };

  const feBranches = useMemo(() => {
    return data?.frontend
      ? data.frontend.filter(b => b.includes('feature-BT-'))
      : [];
  }, [data]);

  const beEnvs = useMemo(() => {
    return data?.backend || [];
  }, [data]);

  return {
    feBranches,
    beEnvs,
    currentFeatureBranch: getCurrentFeatureBranch(),
    loading: !data,
  };
};

export default useFeatureBranches;
