import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, useMemo } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles({
  codeEditor: {
    fontFamily: `'B612 Mono', monospace`,
    width: '100%',

    display: 'inline-flex',
    gap: 13,
    border: '1px solid rgba(148, 162, 180, 0.3)',
    borderRadius: 4,
  },
  codeEditorError: {
    borderColor: '#FF000F',
  },
  lineNumbers: {
    paddingTop: 8,
    paddingBottom: 8,
    width: 21,
    textAlign: 'right',
    '&>span': {
      counterIncrement: 'linenumber',
      '&::before': {
        content: 'counter(linenumber)',
        display: 'block',
        color: '#8496AB',
        lineHeight: '16px',
        fontWeight: 400,
        fontSize: '11px',
      },
    },
  },
  codeEditorTextarea: {
    borderLeft: '1px solid rgba(148, 162, 180, 0.3)',

    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#031224',
    backgroundColor: '#F7F7F7',
    outline: 'none',
    resize: 'none',
    padding: 8,
    overflowY: 'hidden',
    border: 0,
    width: '100%',
    borderRadius: '0 4px 4px 0',
  },
  codeInputHelperText: {
    color: 'rgba(148, 162, 180, 0.3)',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '0.75rem',
    textAlign: 'left',
    marginTop: 3,
    marginRight: 14,
    marginBottom: 0,
    marginLeft: 14,
  },
  codeInputHelperTextError: {
    color: '#FF000F',
  },
});

const CodeInput = forwardRef(
  ({ name, value, onChange, error, helperText }, ref) => {
    const classes = useStyles();

    const lines = useMemo(() => (value || '').split('\n'), [value]);

    return (
      <>
        <div
          className={clsx(classes.codeEditor, {
            [classes.codeEditorError]: error,
          })}
        >
          <div className={classes.lineNumbers}>
            {lines.map((item, index) => (
              <span key={index} />
            ))}
          </div>
          <textarea
            name={name}
            onChange={onChange}
            spellCheck={'false'}
            ref={ref}
            className={classes.codeEditorTextarea}
          >
            {value}
          </textarea>
        </div>
        {helperText && (
          <div
            className={clsx(classes.codeInputHelperText, {
              [classes.codeInputHelperTextError]: error,
            })}
          >
            {helperText}
          </div>
        )}
      </>
    );
  }
);

export default CodeInput;
