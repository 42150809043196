import React, { createContext, useContext, useEffect, useState } from 'react';

export const TableApplyContext = createContext(false);

export const useTableApplyContext = () => {
  return useContext(TableApplyContext);
};

const TableApplyProvider = ({ children }) => {
  const [tableChanges, setTableChanges] = useState({ data: {}, payload: {} });
  const [isApplyAvailable, setIsApplyAvailable] = useState(false);

  useEffect(() => {
    const changesValues = Object.values(tableChanges.payload);

    if (changesValues.length > 0 && !isApplyAvailable) {
      setIsApplyAvailable(true);
    }
    if (changesValues.length <= 0 && isApplyAvailable) {
      setIsApplyAvailable(false);
    }
  }, [tableChanges.payload, isApplyAvailable]);

  return (
    <TableApplyContext.Provider
      value={{
        tableChanges,
        setTableChanges,
        isApplyAvailable,
      }}
    >
      {children}
    </TableApplyContext.Provider>
  );
};

export default TableApplyProvider;
