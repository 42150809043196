/* eslint-disable no-undef */
import React from 'react';
import withStyles from '@mui/styles/withStyles';

import List from './List';
import StoryBoard from './StoryBoard';
import ToolsPanel from './ToolsPanel';

class Library extends React.Component {
  state = {
    renderer: null,
  };

  handleStorySelect = (chapter, story) => {
    const s = this.props.getStory(chapter, story);
    this.setState({ renderer: s.handler });
  };

  render() {
    const { chapters, classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.storylist}>
          <List chapters={chapters} onStorySelect={this.handleStorySelect} />
        </div>
        <div className={classes.storyboard}>
          <ToolsPanel />
          <div className={classes.renderer}>
            <StoryBoard renderer={this.state.renderer} />
          </div>
        </div>
      </div>
    );
  }
}

const styles = () => ({
  container: {
    display: 'flex',
    background: '#fff',
  },
  storylist: {
    width: 200,
  },
  storyboard: {
    flex: 1,
  },
  renderer: {
    padding: 30,
  },
});

export default withStyles(styles)(Library);
