import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  bannerPreviewPlaceholder: {
    backgroundColor: 'currentColor',
    opacity: 0.2,
  },
});

const BannerPreviewPlaceholder = ({ ...props }) => {
  const classes = useStyles();

  return <div className={clsx(classes.bannerPreviewPlaceholder)} {...props} />;
};

export default BannerPreviewPlaceholder;
