import { cloneElement, useRef, useState } from 'react';
import { animated, config, useTransition } from 'react-spring';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  toastWrapper: {
    pointerEvents: 'auto',
    padding: 2,
    paddingRight: 16,
  },

  toastContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

const NotificationToast = ({ children }) => {
  const containerRef = useRef(null);
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const transitions = useTransition(show, {
    config: config.stiff,
    from: {
      opacity: 1,
      height: 0,
      transform: `translateY(-100%) scale(1)`,
    },
    enter: () => next =>
      next({
        opacity: 1,
        height: containerRef.current.getBoundingClientRect().height,
        transform: `translateY(0) scale(1)`,
      }),
    leave: {
      opacity: 0,
      height: 0,
      transform: `translateY(0 scale(0.9)`,
    },
    onRest: () => {
      // setShow(false);
    },
  });

  return transitions((style, item) => {
    return (
      item && (
        <animated.div
          key={item.key}
          style={{
            marginBottom: '4px',
            opacity: style.opacity,
            height: 56,
          }}
          className={classes.toastContainer}
        >
          <animated.div
            className={classes.toastWrapper}
            style={{
              transform: style.transform,
            }}
            ref={containerRef}
          >
            {cloneElement(children, {
              onRemove: () => {
                setShow(false);
              },
            })}
          </animated.div>
        </animated.div>
      )
    );
  });
};

export default NotificationToast;
