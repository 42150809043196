import FancySelect from '../FancySelect/FancySelect';
import { gql, useQuery } from '@apollo/client';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';
import { forwardRef, useMemo } from 'react';
import isoLangs from '../../../../constants/isoLangs';

export const LOCALE_LANGUAGES = gql`
  query getLanguages {
    data: getLanguages {
      items
    }
  }
`;

const LocaleLangSelect = forwardRef(
  (
    {
      multiple = false,
      disabled,
      value,
      onChange,
      error,
      helperText,
      label = 'Language',
    },
    ref
  ) => {
    const { data, loading } = useQuery(LOCALE_LANGUAGES, {
      context: { apiName: 'promo' },
    });

    const options = useMemo(() => {
      return data && data.data.items
        ? data.data.items.map(item => ({
            label:
              item === 'all'
                ? 'All Languages'
                : isoLangs[item]
                ? `${
                    isoLangs && isoLangs[item] && isoLangs[item].name
                  } (${item.toUpperCase()})`
                : item,
            value: item,
          }))
        : [];
    }, [data]);

    return (
      <FancySelectValueProxy
        multiple={multiple}
        value={value}
        options={options}
        onChange={onChange}
        valueKey={'value'}
      >
        <FancySelect
          error={error}
          helperText={helperText}
          ref={ref}
          disabled={disabled}
          multiple={false}
          labelKey={'label'}
          valueKey={'value'}
          loading={false}
          label={label}
          onChange={onChange}
          value={value}
          options={options}
        />
      </FancySelectValueProxy>
    );
  }
);

export default LocaleLangSelect;
