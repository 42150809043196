import { gql } from '@apollo/client';

const updateTournamentRule = gql`
  mutation updateTournamentRule($id: ID!, $input: InlineConfigInput!)
  @api(name: rm) {
    updateTournamentRule(id: $id, input: $input) {
      risk_management_id
      template_id
      live_template_id
      live_delay_k
    }
  }
`;

export default updateTournamentRule;
