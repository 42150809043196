import BannerPreviewPlaceholder from './BannerPreviewPlaceholder';
import BannerPreview from './BannerPreview';
import { forwardRef } from 'react';

const ButtonBannerPreview = forwardRef(({ active, onClick, ...props }, ref) => {
  return (
    <BannerPreview
      title={'Button'}
      active={active}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      <BannerPreviewPlaceholder
        style={{
          height: 21,
          width: 62,
          marginTop: 14,
          borderRadius: 11,
        }}
      />
    </BannerPreview>
  );
});

export default ButtonBannerPreview;
