export const SCHEMA_REQUEST = 'SCHEMA_REQUEST';
export const SCHEMA_SUCCESS = 'SCHEMA_SUCCESS';
export const SCHEMA_FAILURE = 'SCHEMA_FAILURE';
export const ADD_SCHEMA_URL = 'ADD_SCHEMA_URL';

export const DataTypeEnum = {
  INTEGER: 'integer',
  BOOLEAN: 'boolean',
  STRING: 'string',
  NUMBER: 'number',
};

export const FieldTypeEnum = {
  ICON: 'icon',
  CURRENCY: 'currency',
};

export const TableCellAlignEnum = {
  CENTER: 'center',
  RIGHT: 'right',
  LEFT: 'left',
};
export const TableCellAlignByDataType = {
  [DataTypeEnum.BOOLEAN]: TableCellAlignEnum.CENTER,
  [DataTypeEnum.INTEGER]: TableCellAlignEnum.RIGHT,
  [DataTypeEnum.NUMBER]: TableCellAlignEnum.RIGHT,
  [DataTypeEnum.STRING]: TableCellAlignEnum.LEFT,
};

export const TableCellAlignByFieldType = {
  [FieldTypeEnum.ICON]: TableCellAlignEnum.CENTER,
  [FieldTypeEnum.CURRENCY]: TableCellAlignEnum.RIGHT,
};
