import React, { Fragment, useCallback, useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import Grid from '@mui/material/Grid/Grid';
import Box from '@mui/material/Box';
import FormGroupLabel from '../../../../../components/Form/FormGroupLabel';
import FormField from '../../../../../components/Form/FormField';
import FormGroup from '../../../../../components/Form/FormGroup';
import NumberField from '../../../../../components/Inputs/NumberField';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import BannerImageUploader from '../../BannerImageUploader/BannerImageUploader';
import LineBannerBoostedOddsPreview from '../../BannerImagePreview/LineBannerBoostedOddsPreview';
import FancyEventAutocomplete from '../../../../../components/Inputs/EventAutocomplete/FancyEventAutocomplete';
import { useFormContext } from 'react-hook-form';
import FancyMarketSelect from '../../../../../components/Inputs/MarketSelect/FancyMarketSelect';
import FancyOutcomeSelect from '../../../../../components/Inputs/OutcomeSelect/FancyOutcomeSelect';
import { useQuery } from '@apollo/client';
import getBoostedOddsK from '../../../../../gql/Promo/queries/getBoostedOddsK';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import useFieldOnChangeDepsReset from '../../../../../hooks/useFieldOnChangeDepsReset';

const BoostedOddsView = ({ prefix }) => {
  const { watch, setValue } = useFormContext();
  const [tabIndex, setTabIndex] = useState(0);

  const [event, brand, market, outcome, multiplier] = watch([
    `${prefix}.locales.${tabIndex}.details.event`,
    `brand`,
    `${prefix}.locales.${tabIndex}.details.market`,
    `${prefix}.locales.${tabIndex}.details.outcome`,
    `${prefix}.locales.${tabIndex}.details.multiplier`,
  ]);

  const eventOnChange = useFieldOnChangeDepsReset(
    [
      `${prefix}.locales.${tabIndex}.details.market`,
      `${prefix}.locales.${tabIndex}.details.outcome`,
    ],
    null
  );
  const marketOnChange = useFieldOnChangeDepsReset(
    [`${prefix}.locales.${tabIndex}.details.outcome`],
    null
  );

  const { data: { odd: initialOdd } = {} } = useQuery(getBoostedOddsK, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: event?.value,
      brandId: brand?.id,
      marketId: market?.id,
      outcomeId: outcome?.outcome_id,
      specifiers: outcome?.specifiers,
    },
    skip: !event?.value || !brand?.id || !market?.id || !outcome?.outcome_id,
    onCompleted: ({ odd }) => {
      setValue(`${prefix}.locales.${tabIndex}.details.initial_odd`, odd);
      if (typeof odd === 'number' && typeof multiplier === 'number') {
        setValue(
          `${prefix}.locales.${tabIndex}.details.boosted_odd`,
          parseFloat((odd * multiplier).toFixed(3))
        );
      }
    },
  });

  const onChangeBoosted = useCallback(
    boostedOdd => {
      if (typeof boostedOdd === 'number') {
        setValue(
          `${prefix}.locales.${tabIndex}.details.multiplier`,
          parseFloat((boostedOdd / initialOdd).toFixed(3))
        );
        setValue(
          `${prefix}.locales.${tabIndex}.details.boosted_odd`,
          boostedOdd
        );
      }
    },
    [initialOdd, prefix, setValue, tabIndex]
  );

  const onChangeMultiplier = useCallback(
    multiplier => {
      if (typeof multiplier === 'number') {
        setValue(
          `${prefix}.locales.${tabIndex}.details.boosted_odd`,
          parseFloat((initialOdd * multiplier).toFixed(3))
        );
        setValue(
          `${prefix}.locales.${tabIndex}.details.multiplier`,
          multiplier
        );
      }
    },
    [initialOdd, prefix, setValue, tabIndex]
  );

  const boostedOdd = initialOdd && multiplier && initialOdd * multiplier;

  return (
    <Stack direction={'column'} spacing={2}>
      <ImagePreviewProvider>
        <LocaleTabs
          name={`${prefix}.locales`}
          onTabChange={i => setTabIndex(i)}
          content={(localeField, localeIndex) => (
            <Fragment
              key={localeField.locale.country + localeField.locale.lang}
            >
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup large>
                      <Grid container spacing={2} direction={'column'}>
                        <Grid item xs={12}>
                          <FormGroupLabel>
                            Select Event, Market, Outcome
                          </FormGroupLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <FormField
                                name={`${prefix}.locales.${localeIndex}.details.event`}
                                onChange={eventOnChange}
                              >
                                <FancyEventAutocomplete
                                  checkValueOptions={false}
                                  params={{ brand_id: brand?.id }}
                                />
                              </FormField>
                            </Grid>
                            <Grid item xs={4}>
                              <FormField
                                name={`${prefix}.locales.${localeIndex}.details.market`}
                                onChange={marketOnChange}
                              >
                                <FancyMarketSelect
                                  checkValueOptions={false}
                                  eventId={event?.value}
                                  brandId={brand?.id}
                                  disableFilterOptions={false}
                                />
                              </FormField>
                            </Grid>
                            <Grid item xs={4}>
                              <FormField
                                name={`${prefix}.locales.${localeIndex}.details.outcome`}
                              >
                                <FancyOutcomeSelect
                                  brandId={brand?.id}
                                  eventId={event?.value}
                                  marketId={market?.id}
                                  specifiers={market?.specifiers}
                                  checkValueOptions={false}
                                  disableFilterOptions={false}
                                />
                              </FormField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid container item sm={6} spacing={2} md={4}>
                              <Grid item xs={4}>
                                <FormField
                                  name={`${prefix}.locales.${localeIndex}.details.initial_odd`}
                                >
                                  <NumberField
                                    min={0}
                                    step={0.1}
                                    label={'Initial Odd'}
                                    disabled
                                  />
                                </FormField>
                              </Grid>
                              <Grid item xs={8} container>
                                <Grid item xs={4}>
                                  <FormField
                                    name={`${prefix}.locales.${localeIndex}.details.multiplier`}
                                    onChange={onChangeMultiplier}
                                  >
                                    <NumberField
                                      min={1.01}
                                      max={1.05}
                                      step={0.01}
                                      label={'Multiplier'}
                                      disabled={!initialOdd}
                                    />
                                  </FormField>
                                </Grid>
                                <Grid item xs={2}>
                                  <Box
                                    sx={{
                                      marginTop: 1,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.70999 12C4.70999 10.461 5.96098 9.20999 7.49998 9.20999H11.1V7.5H7.50002C5.01602 7.5 3 9.51597 3 12C3 14.484 5.01602 16.5 7.50002 16.5H11.1V14.79H7.50002C5.96102 14.79 4.70999 13.539 4.70999 12Z"
                                        fill="#8496AB"
                                      />
                                      <path
                                        d="M15.6 11.1H8.40001V12.9H15.6V11.1Z"
                                        fill="#8496AB"
                                      />
                                      <path
                                        d="M12.9 7.5H16.5C18.984 7.5 21 9.51597 21 12C21 14.484 18.984 16.5 16.5 16.5H12.9V14.79H16.5C18.039 14.79 19.29 13.539 19.29 12C19.29 10.461 18.039 9.20999 16.5 9.20999H12.9V7.5Z"
                                        fill="#8496AB"
                                      />
                                    </svg>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormField
                                    name={`${prefix}.locales.${localeIndex}.details.boosted_odd`}
                                    onChange={onChangeBoosted}
                                  >
                                    <NumberField
                                      min={initialOdd ?? 1}
                                      max={1.05 * initialOdd}
                                      value={boostedOdd}
                                      step={0.01}
                                      label={'Boosted Odd'}
                                      onChange={onChangeBoosted}
                                      disabled={!initialOdd}
                                    />
                                  </FormField>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              {brand && (
                <Grid item xs={12}>
                  <FormGroup large>
                    <FormField
                      name={`${prefix}.locales.${localeIndex}.images.desktop`}
                    >
                      <BannerImageUploader
                        useGradient
                        preview={LineBannerBoostedOddsPreview}
                        cropSize={{
                          width: 360,
                          height: 152,
                        }}
                      />
                    </FormField>
                  </FormGroup>
                </Grid>
              )}
            </Fragment>
          )}
        />
      </ImagePreviewProvider>
    </Stack>
  );
};

export default BoostedOddsView;
