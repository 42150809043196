import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

class StoryBoard extends React.Component {
  render() {
    const { renderer, classes } = this.props;

    return (
      <div>
        {renderer ? (
          renderer()
        ) : (
          <Typography className={classes.hint}>Choose your story...</Typography>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    width: '100%',
  },
  hint: {
    color: theme.palette.text.hint,
    textAlign: 'center',
  },
});

export default withStyles(styles)(StoryBoard);
