import { useLazyQuery } from '@apollo/client';
import getEventEditModel from '../../gql/EventsGroups/queries/getEventEditModel';

const useEventEditModel = options => {
  const [fetch, { data, loading, error }] = useLazyQuery(
    getEventEditModel,
    options
  );

  const getEditModel = variables => {
    fetch({ variables });
    return { data, loading, error };
  };

  let model;

  if (data) {
    model = data.getEventEditModel;
  }

  return { getEditModel, model, loading, error };
};

export default useEventEditModel;
