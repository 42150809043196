import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import escapeRegExp from '../../utils/escapeRegExp';

const useStyles = makeStyles(theme => ({
  highlight: {
    color: theme.palette.primary.main,
  },
}));

const HighlightedLabel = ({ text, query, className }) => {
  const classes = useStyles();

  if (!query || !text) {
    return text || null;
  }

  return text
    .split(new RegExp(`(${escapeRegExp(query.replace(' ', '|'))})`, 'ig'))
    .map((part, index) =>
      index % 2 ? (
        <span key={index} className={clsx(classes.highlight, className)}>
          {part}
        </span>
      ) : (
        part
      )
    );
};

export default HighlightedLabel;
