import { GLOBAL_DRAWER_WIDTH } from '../components/Root';
import { isDrawerOpen } from '../reducers/drawerOpen';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';

const useDrawerLeftShift = () => {
  const theme = useTheme();
  const drawerOpen = useSelector(isDrawerOpen);
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'));
  return mdMatch && drawerOpen ? GLOBAL_DRAWER_WIDTH + 24 : 24;
};

export default useDrawerLeftShift;
