import React, { Children, cloneElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& > *': {
      overflow: 'auto',
    },
  },
});

const TabItem = ({ children, classes: classesOverride = {}, ...props }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classesOverride.root)}>
      {cloneElement(Children.only(children), { ...props })}
    </div>
  );
};

export default TabItem;
