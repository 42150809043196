import { gql } from '@apollo/client';

const getPriorities = gql`
  query getPriorities($input: PriorityFilters!) {
    data: getPriorities(input: $input) {
      items {
        placeholder
        banners {
          id
          name
          banner_type
          banner_view
          locales {
            lang
            country
          }
          activate {
            type
            period {
              range_from
              range_to
            }
          }
        }
      }
    }
  }
`;

export default getPriorities;
