import Tab from './Tab';
import Stack from '@mui/material/Stack/Stack';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CreateTabButton from './CreateTabButton';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';
import TabsScroll from './TabsScroll';
import debounce from 'lodash.debounce';
import { Box } from '@mui/system';

const Tabs = ({
  activeTab,
  activeTabKey,
  setActiveTab,
  createButton,
  onClickCreate,
  fullWidthTabs = false,
  tabs = [],
  onChangeTab,
  tabKey,
  withoutSpacing = false,
  primary = false,
  style,
  tabsStyle,
  mt = 1,
}) => {
  const [innerActiveTab, setInnerActiveTab] = useState(
    activeTabKey ? tabs.findIndex(item => item.key === activeTabKey) : 0
  );
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [tabSize, setTabSize] = useState(180);

  const tabsContainerRef = useHorizontalScroll();

  const checkForScrollPosition = useCallback(() => {
    const { current } = tabsContainerRef;
    if (current) {
      const { scrollLeft, scrollWidth, clientWidth } = current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(
        Math.ceil(scrollLeft) !== Math.ceil(scrollWidth - clientWidth)
      );
    }
  }, [tabsContainerRef]);

  const debounceCheckForScrollPosition = debounce(checkForScrollPosition, 10);

  const scrollContainerBy = useCallback(
    distance => {
      tabsContainerRef.current?.scrollBy({
        left: distance,
        behavior: 'smooth',
      });
    },
    [tabsContainerRef]
  );
  const handleTabScrollLeft = useCallback(() => {
    scrollContainerBy(tabSize * -1);
  }, [scrollContainerBy, tabSize]);
  const handleTabScrollRight = useCallback(() => {
    scrollContainerBy(tabSize);
  }, [scrollContainerBy, tabSize]);

  const activeTabIndex =
    typeof activeTab === 'undefined'
      ? innerActiveTab
      : activeTabKey
      ? tabs.findIndex(item => item && item.key === activeTabKey)
      : activeTab;

  const changeTab = useCallback(
    (index, key) => {
      if (typeof setActiveTab !== 'undefined') {
        setActiveTab(index);
      } else {
        setInnerActiveTab(index);
      }

      onChangeTab && onChangeTab(key);
    },
    [onChangeTab, setActiveTab]
  );

  const onClickCreateCb = useCallback(() => {
    onClickCreate();

    setTimeout(() => {
      const element = tabsContainerRef?.current;
      if (element) {
        element.scrollLeft = element.scrollWidth;
      }
    }, 200);
  }, [onClickCreate, tabsContainerRef]);

  useEffect(() => {
    const { current } = tabsContainerRef;
    checkForScrollPosition();
    current?.addEventListener('scroll', debounceCheckForScrollPosition);

    return () => {
      current?.removeEventListener('scroll', debounceCheckForScrollPosition);
      debounceCheckForScrollPosition.cancel();
    };
  }, [
    debounceCheckForScrollPosition,
    tabsContainerRef,
    checkForScrollPosition,
  ]);

  return (
    <Stack direction={'column'} spacing={withoutSpacing ? 0 : mt} style={style}>
      <Stack
        direction={'row'}
        style={tabsStyle}
        sx={{
          backgroundColor: '#E6EAEE',
          borderRadius: withoutSpacing ? '4px 4px 0 0' : '4px',
          overflow: 'hidden',
        }}
      >
        <Stack
          ref={tabsContainerRef}
          direction={'row'}
          sx={{
            overflowX: 'auto',
            width: '100%',
            scrollWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                disabled={tab.disabled}
                fullWidth={fullWidthTabs}
                withoutSpacing={withoutSpacing}
                sx={{
                  height: '40px',
                }}
                key={tab.key || index}
                isActive={index === activeTabIndex}
                onClick={() => {
                  changeTab(index, tab.key);
                }}
                setTabSize={setTabSize}
                primary={primary}
                primary={primary}
              >
                {tab.label}
              </Tab>
            );
          })}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
          }}
        >
          {(canScrollLeft || canScrollRight) && (
            <TabsScroll
              canScrollLeft={canScrollLeft}
              canScrollRight={canScrollRight}
              onClickLeft={handleTabScrollLeft}
              onClickRight={handleTabScrollRight}
            />
          )}

          {createButton && <CreateTabButton onClick={onClickCreateCb} />}
        </Box>
      </Stack>

      {tabs[activeTabIndex] && tabs[activeTabIndex].content && (
        <Fragment key={tabs[activeTabIndex].key}>
          {tabs[activeTabIndex].content}
        </Fragment>
      )}
    </Stack>
  );
};

export default Tabs;
