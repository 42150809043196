import React, { useContext, useEffect, useMemo } from 'react';
import ECharts from './index';
import { RoutesContext } from '../../providers/RoutesProvider';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import useQueryByPath from '../../hooks/useQueryByPath';
import {
  getFilters,
  getRequiredPathParameters,
} from '../../utils/schemaHelper';
import getDefaultValue from '../../utils/prepareSchema/getDefaultValue';
import DataProvider from '../../providers/DataProvider';

const useFiltersQuery = (routes, filtersPath, path) => {
  const filtersParameters = useMemo(
    () => getFilters(routes[filtersPath].parameters),
    [routes, filtersPath]
  );

  const filters = useMemo(
    () =>
      filtersParameters.reduce((defaultValues, f) => {
        defaultValues[f.name] = getDefaultValue(f.schema);
        return defaultValues;
      }, {}),
    [filtersParameters]
  );

  const { query, setQuery } = useQueryByPath(path);

  useEffect(() => {
    if (filters && (!query || isEmpty(query))) {
      setQuery({
        ...filters,
      });
    }
  }, [query, filters, setQuery]);

  return useMemo(() => [query, setQuery], [query, setQuery]);
};

const getFiltersPath = (routes, path) => {
  for (let filtersPath in routes) {
    const route = routes[filtersPath];
    const component = get(
      route,
      'responses.200.content.application/json.x-ui-schema.ui:component'
    );
    const links = get(route, 'responses.200.links');
    if (component === 'Filters') {
      for (let operationId in links) {
        if (links[operationId].path === path) {
          return filtersPath;
        }
      }
    }
  }

  return path;
};

const checkRequiredParams = (routes, filtersPath, params) => {
  const pathParameters = getRequiredPathParameters(
    routes[filtersPath].parameters
  );

  return pathParameters.every(({ name }) => {
    return Object.keys(params).includes(name);
  });
};

const DataECharts = ({ path, params, ...props }) => {
  const routes = useContext(RoutesContext);

  const filtersPath = getFiltersPath(routes, path);
  // TODO: please check why useFiltersQuery is used
  const [query] = useFiltersQuery(routes, filtersPath, path);

  const hasRequiredParams = checkRequiredParams(routes, filtersPath, params);

  // TODO: why need to check query?
  if (!hasRequiredParams && (!query || isEmpty(query))) {
    return null;
  }

  return (
    <DataProvider path={path} params={params} routes={routes} {...props}>
      <ECharts {...props} />
    </DataProvider>
  );
};

export default DataECharts;
