import React, { Fragment } from 'react';
import { useWatch } from 'react-hook-form';
import {
  BannerSubformByType,
  BannerType,
  BannerView,
} from './BannerValidationSchema';

const getTypePrefix = (bannerType, bannerView) => {
  if (bannerType !== BannerType.CODE_BANNER) {
    return `${bannerType}.${bannerView}`;
  }

  return `${bannerType}`;
};

const getSubformComponent = (bannerType, bannerView) => {
  if (bannerType !== BannerType.CODE_BANNER) {
    return (
      BannerSubformByType?.[bannerType]?.[bannerView] ||
      BannerSubformByType[BannerType.HERO_BANNER][BannerView.TEXT]
    );
  }

  return BannerSubformByType[bannerType];
};

const BannerSubform = ({ extraParams }) => {
  const bannerType = useWatch({ name: 'banner_type' });
  const bannerView = useWatch({ name: `${bannerType}.view_type` });

  const bannerTypePrefix = getTypePrefix(bannerType, bannerView + '_view');

  const SubformComponent = getSubformComponent(bannerType, bannerView);

  return bannerType && (bannerView || bannerType === BannerType.CODE_BANNER) ? (
    <Fragment key={bannerTypePrefix}>
      <SubformComponent
        prefix={bannerTypePrefix}
        extraParams={bannerView === BannerView.EVENT ? extraParams : {}}
      />
    </Fragment>
  ) : null;
};

export default BannerSubform;
