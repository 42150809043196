import React from 'react';
import GlobalLoading from '../components/GlobalLoading';
import withStyles from '@mui/styles/withStyles';

const styles = {
  iframe: {
    width: '100%',
    minHeight: 'calc(100vh - 80px)',
    border: 'none',
  },
};

const IFrame = ({ loading, data, classes }) => {
  if (loading || !data) {
    return <GlobalLoading />;
  }
  var properties = {
    src: data.url,
    style: data.style ? data.style : {},
    className: !data.style ? classes.iframe : {},
  };
  if (data.id) {
    let id = '#' + data.id;
    properties.id = data.id;
    properties.onLoaded = document.querySelector(id)
      ? document.querySelector(id).contentWindow.focus()
      : null;
    properties.onFocus = document.querySelector(id)
      ? document.querySelector(id).contentWindow.focus()
      : null;
  }
  return data && data.url ? <iframe {...properties} title="IFrame" /> : null;
};

export default withStyles(styles)(IFrame);
