import { gql } from '@apollo/client';

const getTournamentsNames = gql`
  query getTournamentsNames(
    $query: String!
    $sportIds: [SportId!]
    $categoriesIds: [CategoryId!]
    $limit: Int
    $offset: Int
  ) @api(contextKey: "apiName") {
    getTournamentsNames(
      query: $query
      sportIds: $sportIds
      categoriesIds: $categoriesIds
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
  }
`;

export default getTournamentsNames;
