import styles from './index.styles';

import { forwardRef } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Zoom,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import DialogButtons from './Buttons';

const Transition = forwardRef(function (props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const Dialog = ({
  children,
  title,
  onSubmit,
  onClose,
  closable = true,
  ButtonsProps,
}) => {
  const classes = styles();
  return (
    <MuiDialog
      maxWidth={false}
      open={true}
      onBackdropClick={onClose}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{
        paper: classes.root,
      }}
    >
      {title && (
        <DialogTitle className={classes.title}>
          <Stack className={classes.titleText}>
            <span>{title}</span>
          </Stack>
          {closable && (
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent className={classes.content}>{children}</DialogContent>
      {onSubmit && (
        <DialogButtons
          onSubmit={onSubmit}
          onCancel={onClose}
          {...ButtonsProps}
        />
      )}
    </MuiDialog>
  );
};

export default Dialog;
