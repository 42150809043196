import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  optionValue: {
    marginLeft: 8,
    color: 'rgba(3, 18, 36, 0.5)',
    fontWeight: 400,
    fontSize: '0.75rem',
  },
});

const EventFormAutocompleteOption = ({ option, ...props }) => {
  const classes = useStyles();

  return (
    <li {...props}>
      <span>
        {option.label}
        <span className={classes.optionValue}>{option.value}</span>
      </span>
    </li>
  );
};

export default EventFormAutocompleteOption;
