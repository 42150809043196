import { gql } from '@apollo/client';
import { SOURCE_ENABLE_TYPE } from './getSourcesTemplate';

export const MARGIN_MARKET_NODE_TYPE = gql`
  fragment MarginMarketNodeType on MarginMarketNode {
    marketId
    specifier
    name
    prematch
    live
  }
`;

export const MARGIN_TOURNAMENT_NODE_TYPE = gql`
  fragment MarginTournamentNodeType on MarginTournamentNode {
    tournamentId
    name
  }
`;

export const MARGIN_CATEGORY_NODE_TYPE = gql`
  fragment MarginCategoryNodeType on MarginCategoryNode {
    categoryId
    name
    countryCode
    total
  }
`;

export const MARGIN_SPORT_NODE_TYPE = gql`
  fragment MarginSportNodeType on MarginSportNode {
    sportId
    name
  }
`;

export const MARGIN_TEMPLATE_TYPE = gql`
  ${MARGIN_SPORT_NODE_TYPE}
  ${MARGIN_CATEGORY_NODE_TYPE}
  ${MARGIN_TOURNAMENT_NODE_TYPE}
  ${MARGIN_MARKET_NODE_TYPE}
  fragment MarginTemplateType on MarginTemplateNodes {
    marketsToNode
    sports {
      ...MarginSportNodeType
    }
    categories {
      ...MarginCategoryNodeType
    }
    tournaments {
      ...MarginTournamentNodeType
    }
    markets {
      ...MarginMarketNodeType
    }
  }
`;

const getMarginTemplate = gql`
  ${MARGIN_TEMPLATE_TYPE}
  query getMarginTemplate($path: NodeSelectorInput, $templateId: TemplateId) {
    data: getMarginTemplate(path: $path, templateId: $templateId) {
      ...MarginTemplateType
    }
  }
`;

export default getMarginTemplate;
