import Button from '../../v2/components/Button/Button';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const ResetFiltersButton = ({ className, onClick }) => {
  const { formState } = useFormContext();
  const isDirty = formState.isDirty;

  return (
    <Button
      disabled={!isDirty}
      color="primary"
      className={className}
      onClick={onClick}
      variant={'text'}
    >
      Reset
    </Button>
  );
};

export default ResetFiltersButton;
