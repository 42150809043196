import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableLoadingRowPlaceholder from './TableLoadingRowPlaceholder';

const useStyles = makeStyles({
  tableLoadingPlaceholder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const TableLoadingPlaceholder = ({ rowHeight = 42, limit = 5 }) => {
  const classes = useStyles();

  const items = Array.from({ length: limit }, (_, i) => (
    <TableLoadingRowPlaceholder
      key={i}
      height={rowHeight}
      total={limit}
      index={i}
    />
  ));

  return <div className={classes.tableLoadingPlaceholder}>{items}</div>;
};

export default TableLoadingPlaceholder;
