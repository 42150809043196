import { RSAA } from 'redux-api-middleware';
import * as constants from '../constants/form';

export const initForm = (apiUrl, formPath) => ({
  [RSAA]: {
    endpoint: '/',
    method: 'GET',
    constants: [
      `${constants.FORM_INIT}_LOADING`,
      {
        type: `${constants.FORM_INIT}_SUCCESS`,
        meta: { formPath: formPath },
      },
      `${constants.FORM_INIT}_ERROR`,
    ],
  },
});

export const formSetState = (form, state) => ({
  type: constants.FORM_SET_STATE,
  form: form,
  payload: state,
});
