import TextBannerPreview from '../../../../components/BannerPreview/TextBannerPreview';
import ButtonBannerPreview from '../../../../components/BannerPreview/ButtonBannerPreview';
import EventBannerPreview from '../../../../components/BannerPreview/EventBannerPreview';
import AutoEventBannerPreview from '../../../../components/BannerPreview/AutoEventBannerPreview';
import BoostedOddsBannerPreview from '../../../../components/BannerPreview/BoostedOddsBannerPreview';
import CodeBannerPreview from '../../../../components/BannerPreview/CodeBannerPreview';
import TournamentBannerPreview from '../../../../components/BannerPreview/TournamentBannerPreview';

import HeroBannerTextView from './HeroBanner/TextView';
import HeroBannerButtonView from './HeroBanner/ButtonView';
import HeroBannerEventView from './HeroBanner/EventView';
import LineBannerTextView from './LineBanner/TextView';

import LineBannerButtonView from './LineBanner/ButtonView';
import LineBannerAutoEventsView from './LineBanner/AutoEventsView';
import LineBannerBoostedOddsView from './LineBanner/BoostedOddsView';
import LineBannerCodeView from './LineBanner/CodeView';

import PopularNowBannerTextView from './PopularNowBanner/TextView';
import PopularNowBannerTournamentView from './PopularNowBanner/TournamentView';

import * as yup from 'yup';
import CodeBannerView from './CodeBanner/CodeBannerView';
import dayjs from 'dayjs';

export const PLACEHOLDERS_ENUM = {
  LIVE_PAGE: 'live_page',
  SPORT_PAGE: 'sport_page',
  TOURNAMENT: 'tournament',
  EVENT_PAGE_ABOVE_TRACKER: 'event_page_above_tracker',
  EVENT_PAGE_BELOW_TRACKER: 'event_page_below_tracker',
  HOME_TOP: 'home_top',
  HOME_CENTER: 'home_center',
  CATEGORY_PAGE: 'category_page',
  OPERATOR_PAGE: 'operator_page',
  OPERATOR_PAGE1: 'operator_page1',
  OPERATOR_PAGE2: 'operator_page2',
  OPERATOR_PAGE3: 'operator_page3',
  OPERATOR_PAGE4: 'operator_page4',
  OPERATOR_PAGE5: 'operator_page5',
  HERO_BANNER: 'hero_banner',
  LEFT_SIDEBAR: 'left_sidebar',
  RIGHT_SIDEBAR: 'right_sidebar',
  ESPORTS_PAGE: 'esports_page',
};

export const PLACEHOLDER_LABEL = {
  [PLACEHOLDERS_ENUM.LIVE_PAGE]: 'Live Page',
  [PLACEHOLDERS_ENUM.SPORT_PAGE]: 'Sport Page',
  [PLACEHOLDERS_ENUM.TOURNAMENT]: 'Tournament',
  [PLACEHOLDERS_ENUM.EVENT_PAGE_ABOVE_TRACKER]: 'Event Page (above tracker)',
  [PLACEHOLDERS_ENUM.EVENT_PAGE_BELOW_TRACKER]: 'Event Page (below tracker)',
  [PLACEHOLDERS_ENUM.HOME_TOP]: 'Home Page (Top)',
  [PLACEHOLDERS_ENUM.HOME_CENTER]: 'Home Page (Center)',
  [PLACEHOLDERS_ENUM.CATEGORY_PAGE]: 'Category Page',
  [PLACEHOLDERS_ENUM.OPERATOR_PAGE]: 'Operator Page',
  [PLACEHOLDERS_ENUM.OPERATOR_PAGE1]: 'Operator Page (1)',
  [PLACEHOLDERS_ENUM.OPERATOR_PAGE2]: 'Operator Page (2)',
  [PLACEHOLDERS_ENUM.OPERATOR_PAGE3]: 'Operator Page (3)',
  [PLACEHOLDERS_ENUM.OPERATOR_PAGE4]: 'Operator Page (4)',
  [PLACEHOLDERS_ENUM.OPERATOR_PAGE5]: 'Operator Page (5)',
  [PLACEHOLDERS_ENUM.HERO_BANNER]: 'Hero Banner',
  [PLACEHOLDERS_ENUM.LEFT_SIDEBAR]: 'Left Sidebar',
  [PLACEHOLDERS_ENUM.RIGHT_SIDEBAR]: 'Right Sidebar',
  [PLACEHOLDERS_ENUM.ESPORTS_PAGE]: 'eSport Page',
};

export const ActivatePeriod = {
  PERMANENTLY: 'permanently',
  PERIOD: 'period',
};

export const BannerType = {
  HERO_BANNER: 'hero_banner',
  LINE_BANNER: 'line_banner',
  POPULAR_NOW: 'popular_now_banner',
  CODE_BANNER: 'code_banner',
};

export const BannerImageType = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

export const DisplayPlatform = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

export const BannerState = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  ENDED: 'ended',
};

export const BannerStateLabel = {
  [BannerState.ACTIVE]: 'Active',
  [BannerState.DRAFT]: 'Draft',
  [BannerState.SCHEDULED]: 'Scheduled',
  [BannerState.ENDED]: 'Ended',
};

export const BannerView = {
  TEXT: 'text',
  BUTTON: 'button',
  EVENT: 'event',
  AUTO_EVENTS: 'auto_events',
  BOOSTED_ODDS: 'boosted_odds',
  CODE: 'code',
  TOURNAMENT: 'tournament',
};

export const BannerTypeLabelMap = {
  [BannerType.HERO_BANNER]: 'Hero Banner',
  [BannerType.LINE_BANNER]: 'Line Banner',
  [BannerType.POPULAR_NOW]: 'Popular Now',
  [BannerType.CODE_BANNER]: 'Code Banner',
};

export const BannerViewLabelMap = {
  [BannerView.TEXT]: 'Text',
  [BannerView.BUTTON]: 'Button',
  [BannerView.EVENT]: 'Event',
  [BannerView.AUTO_EVENTS]: 'Auto Events',
  [BannerView.BOOSTED_ODDS]: 'Boosted Odds',
  [BannerView.CODE]: 'Code',
  [BannerView.TOURNAMENT]: 'Tournament',
};

export const BannerViewMap = {
  [BannerView.TEXT]: <TextBannerPreview />,
  [BannerView.BUTTON]: <ButtonBannerPreview />,
  [BannerView.EVENT]: <EventBannerPreview />,
  [BannerView.AUTO_EVENTS]: <AutoEventBannerPreview />,
  [BannerView.BOOSTED_ODDS]: <BoostedOddsBannerPreview />,
  [BannerView.CODE]: <CodeBannerPreview />,
  [BannerView.TOURNAMENT]: <TournamentBannerPreview reversed />,
};

export const ViewsByType = {
  [BannerType.HERO_BANNER]: [
    BannerView.TEXT,
    BannerView.BUTTON,
    BannerView.EVENT,
  ],
  [BannerType.LINE_BANNER]: [
    BannerView.TEXT,
    BannerView.BUTTON,
    BannerView.AUTO_EVENTS,
    BannerView.BOOSTED_ODDS,
    BannerView.CODE,
  ],
  [BannerType.POPULAR_NOW]: [BannerView.TEXT, BannerView.TOURNAMENT],
};

export const BannerSubformByType = {
  [BannerType.HERO_BANNER]: {
    [BannerView.TEXT]: HeroBannerTextView,
    [BannerView.BUTTON]: HeroBannerButtonView,
    [BannerView.EVENT]: HeroBannerEventView,
  },
  [BannerType.LINE_BANNER]: {
    [BannerView.TEXT]: LineBannerTextView,
    [BannerView.BUTTON]: LineBannerButtonView,
    [BannerView.AUTO_EVENTS]: LineBannerAutoEventsView,
    [BannerView.BOOSTED_ODDS]: LineBannerBoostedOddsView,
    [BannerView.CODE]: LineBannerCodeView,
  },
  [BannerType.POPULAR_NOW]: {
    [BannerView.TEXT]: PopularNowBannerTextView,
    [BannerView.TOURNAMENT]: PopularNowBannerTournamentView,
  },
  [BannerType.CODE_BANNER]: CodeBannerView,
};

const bannerLinkSchema = yup
  .object({
    url: yup.string().label('Banner link'),
    custom_action: yup.boolean(),
  })
  .default({
    url: '',
    custom_action: false,
  });

const bannerLinkRequiredSchema = yup
  .object({
    url: yup
      .string()
      .label('Banner link')
      .when('$activate', {
        is: true,
        then: schema => schema.required('Link is required'),
      }),
    custom_action: yup.boolean(),
  })
  .default({
    url: '',
    custom_action: false,
  });

const bannerImageSchema = yup
  .object()
  .shape({
    id: yup.string(),
    name: yup.string().strip(),
    source_id: yup.string().required(),
    start_x: yup.number().integer().round('floor'),
    start_y: yup.number().integer().round('floor'),
    height: yup.number().integer().round('floor'),
    width: yup.number().integer().round('floor'),
  })
  .when('$activate', {
    is: true,
    then: schema => schema.required().nonNullable(),
    otherwise: schema => schema.nullable().default(null),
  });

const bannerImagesSchema = yup
  .object()
  .shape({
    desktop: bannerImageSchema, // TODO check required desktop/tablet/mobile
    tablet: bannerImageSchema,
    mobile: bannerImageSchema,
    use_gradient: yup.boolean().default(false),
  })
  .default({
    use_gradient: false,
  })
  .transform(data => {
    if (!data || !data.desktop || !data.tablet || !data.mobile) {
      return null;
    } else {
      return data;
    }
  })
  .when('$activate', {
    is: true,
    then: schema => schema.required('Image is required'),
    otherwise: schema => schema.nullable(),
  });

const bannerDesktopOnlyImagesSchema = yup
  .object({
    desktop: bannerImageSchema,
    use_gradient: yup.boolean().default(false),
  })
  .nullable()
  .default({
    use_gradient: false,
  })
  .transform(data => {
    if (!data || !data.desktop) {
      return null;
    } else {
      return data;
    }
  })
  .when('$activate', {
    is: true,
    then: schema => schema.required('Image is required'),
  });
const getLocalesSchema = fields => {
  return yup
    .array()
    .of(
      yup.object({
        is_default: yup.boolean(),
        locale: yup
          .object()
          .shape({
            lang: yup
              .string()
              .nullable()
              .required('Please select the Language')
              .default(''),
            country: yup.string().nullable().default(''),
          })
          .required(),

        ...fields,
      })
    )
    .default([
      {
        locale: {
          lang: 'all',
          country: 'all',
        },

        ...yup.object(fields).getDefault(),
      },
    ]);
};

const BannerValidationSchema = yup.object({
  state: yup
    .string()
    .oneOf(Object.values(BannerState))
    .default(BannerState.DRAFT)
    .strip(),
  name: yup.string().required('Name is required').default('').label('Name'),
  operator: yup
    .object({
      name: yup.string(),
      id: yup.string(),
    })
    .required('Operator is required')
    .default(null)
    .when('$isOperator', {
      is: true,
      then: schema => schema.strip().optional(),
    })
    .label('Operator'),
  brand: yup
    .object({
      name: yup.string(),
      id: yup.string(),
    })
    .required('Brand is required')
    .default(null)
    .label('Brand'),

  activate: yup
    .object()
    .shape({
      type: yup.string().oneOf(Object.values(ActivatePeriod)),
      period: yup
        .object()
        .when(['type', '$currentDate'], ([type, $currentDate], schema) => {
          return type === ActivatePeriod.PERMANENTLY
            ? schema.nullable().transform(() => null)
            : schema
                .shape({
                  range_from: yup
                    .string()
                    .required('Activation period is required')
                    .default(''),
                  range_to: yup
                    .string()
                    .required('Activation period is required')
                    .test(
                      'isGreaterThanCurrent',
                      'Activation period must be greater than current time',
                      value => dayjs(value).unix() >= $currentDate
                    )
                    .default(''),
                })
                .required('Activation period is required');
        }),
    })
    .default({
      type: ActivatePeriod.PERMANENTLY,
      period: {
        range_from: '',
        range_to: '',
      },
    }),

  banner_type: yup
    .string()
    .nullable()
    .required()
    .oneOf(Object.values(BannerType))
    .default(BannerType.HERO_BANNER)
    .label('Banner Type'),

  display_platform: yup
    .array()
    .nullable()
    .required()
    .label('Display Platform')
    .of(yup.string().oneOf(Object.values(DisplayPlatform)))
    .min(1)
    .default([DisplayPlatform.MOBILE, DisplayPlatform.DESKTOP])
    .when('banner_type', {
      is: BannerType.CODE_BANNER,
      then: schema =>
        schema
          .default([DisplayPlatform.DESKTOP])
          .of(yup.string().oneOf([DisplayPlatform.DESKTOP])),
    }),

  hero_banner: yup
    .object()
    .shape({
      placeholders: yup
        .array()
        .label('Placeholders')
        .of(yup.string())
        .default([])
        .when('$activate', {
          is: true,
          then: schema => schema.min(1, '${label} is required').required(),
        }),
      view_type: yup
        .string()
        .oneOf(ViewsByType[BannerType.HERO_BANNER])
        .default(BannerView.TEXT),

      text_view: yup
        .object({
          view: yup.string().default(BannerView.TEXT),
          locales: getLocalesSchema({
            link: bannerLinkSchema,

            title: yup
              .string()
              .label('Title')
              .max(40, 'Must be at most ${max} characters')
              .default(''),
            subtitle: yup
              .string()
              .label('Subtitle')
              .max(162, 'Must be at most ${max} characters')
              .default(''),
            legal_text: yup
              .string()
              .label('Legal Text')
              .max(162, 'Must be at most ${max} characters')
              .default(''),

            images: bannerImagesSchema,
          }),
        })
        .when('view_type', {
          is: BannerView.TEXT,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      button_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.BUTTON),
          locales: getLocalesSchema({
            link: bannerLinkRequiredSchema,

            title: yup
              .string()
              .label('Title')
              .max(40, 'Must be at most ${max} characters')
              .default(''),
            subtitle: yup
              .string()
              .label('Subtitle')
              .max(162, 'Must be at most ${max} characters')
              .default(''),
            legal_text: yup
              .string()
              .label('Legal Text')
              .max(162, 'Must be at most ${max} characters')
              .default(''),

            button: yup.object({
              text: yup
                .string()
                .label('Button Text')
                .max(12, 'Must be at most ${max} characters')
                .default('')
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Button Text is required'),
                }),
              color: yup.string().label('Button Color').default(''),
            }),

            images: bannerImagesSchema,
          }),
        })
        .when('view_type', {
          is: BannerView.BUTTON,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      event_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.EVENT),
          locales: getLocalesSchema({
            title: yup
              .string()
              .label('Title')
              .max(40, 'Must be at most ${max} characters')
              .default(''),
            images: bannerImagesSchema,
            event: yup
              .object()
              .label('Event')
              .shape({
                value: yup.string(),
                label: yup.string(),
              })
              .nullable()
              .default(null)
              .when('$activate', {
                is: true,
                then: schema => schema.required('Event is required'),
              }),
          }),
        })
        .when('view_type', {
          is: BannerView.EVENT,
          otherwise: schema => schema.nullable().transform(() => null),
        }),
    })
    .when('banner_type', {
      is: BannerType.HERO_BANNER,
      otherwise: schema => schema.nullable().transform(() => null),
    }),

  line_banner: yup
    .object()
    .shape({
      placeholders: yup
        .array()
        .label('Placeholders')
        .of(yup.string())
        .default([])
        .when('$activate', {
          is: true,
          then: schema => schema.min(1, '${label} is required').required(),
        }),

      restrictions: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string(),
            value: yup.string(),
            name: yup.string(),
            secondary_text: yup.string(),
          })
        )
        .default([]),

      view_type: yup
        .string()
        .oneOf(ViewsByType[BannerType.LINE_BANNER])
        .default(BannerView.TEXT),

      text_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.TEXT),
          locales: getLocalesSchema({
            link: bannerLinkSchema,

            title: yup
              .string()
              .label('Title')
              .max(50, 'Must be at most ${max} characters')
              .default(''),

            images: bannerDesktopOnlyImagesSchema,
          }),
        })
        .when('view_type', {
          is: BannerView.TEXT,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      button_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.BUTTON),
          locales: getLocalesSchema({
            link: bannerLinkRequiredSchema,

            title: yup
              .string()
              .label('Title')
              .max(50, 'Must be at most ${max} characters')
              .default(''),

            button: yup.object({
              text: yup
                .string()
                .label('Button text')
                .max(12, 'Must be at most ${max} characters')
                .default('')
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Button Text is required'),
                }),
              color: yup.string().label('Button Color').default(''),
            }),

            images: bannerDesktopOnlyImagesSchema,
          }),
        })
        .when('view_type', {
          is: BannerView.BUTTON,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      auto_events_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.AUTO_EVENTS),
          locales: getLocalesSchema({
            images: bannerDesktopOnlyImagesSchema,
            events: yup
              .array()
              .label('Events')
              .of(
                yup.object({
                  item_type: yup.string().oneOf(['event', 'tournament']),
                  id: yup.string(),
                  label: yup.string(),
                })
              )
              .default([])
              .when('$activate', {
                is: true,
                then: schema => schema.min(1, 'Event is required').required(),
              }),
            events_limit: yup
              .number()
              .min(1, 'Limit is required')
              .integer()
              .default(1)
              .when('$activate', {
                is: true,
                then: schema => schema.required('Events Limit is required'),
              }),
          }),
        })
        .when('view_type', {
          is: BannerView.AUTO_EVENTS,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      code_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.CODE),
          locales: getLocalesSchema({
            code: yup
              .string()
              .label('Code')
              .default('')
              .when('$activate', {
                is: true,
                then: schema => schema.required('Code is required'),
              }),
          }),
        })
        .when('view_type', {
          is: BannerView.CODE,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      boosted_odds_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.BOOSTED_ODDS),

          locales: getLocalesSchema({
            images: bannerDesktopOnlyImagesSchema,
            details: yup.object({
              event: yup
                .object({
                  value: yup.string(),
                  label: yup.string(),
                })
                .label('Event')
                .nullable()
                .default(null)
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Event is required'),
                }),

              market: yup
                .object({
                  id: yup.string(),
                  name: yup.string(),
                  specifiers: yup.string(),
                })
                .label('Market')
                .nullable()
                .default(null)
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Market is required'),
                }),

              outcome: yup
                .object({
                  outcome_id: yup.string(),
                  outcome_name: yup.string(),
                  specifiers: yup.string(),
                })
                .label('Outcome')
                .nullable()
                .default(null)
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Outcome is required'),
                }),
              initial_odd: yup.number('Required').typeError('Required'),
              multiplier: yup
                .number('Required')
                .typeError('Required')
                .label('Multiplier')
                .nullable()
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Required'),
                })
                .test(
                  'isResultOfCalculation',
                  'Required',
                  value => value !== 0
                ),
              boosted_odd: yup
                .number('Required')
                .typeError('Required')
                .label('Boosted Odd')
                .nullable()
                .when('$activate', {
                  is: true,
                  then: schema => schema.required('Required'),
                })
                .test(
                  'isResultOfCalculation',
                  'Required',
                  value => value !== 0
                ),
            }),
          }),
        })
        .when('view_type', {
          is: BannerView.BOOSTED_ODDS,
          otherwise: schema => schema.nullable().transform(() => null),
        }),
    })
    .when('banner_type', {
      is: BannerType.LINE_BANNER,
      otherwise: schema => schema.nullable().transform(() => null),
    }),

  popular_now_banner: yup
    .object()
    .shape({
      placeholders: yup
        .array()
        .label('Placeholders')
        .of(yup.string())
        .default([])
        .when('$activate', {
          is: true,
          then: schema => schema.min(1, '${label} is required').required(),
        }),
      view_type: yup
        .string()
        .oneOf(ViewsByType[BannerType.POPULAR_NOW])
        .default(BannerView.TEXT),

      text_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.TEXT),
          locales: getLocalesSchema({
            link: bannerLinkSchema,

            title: yup
              .string()
              .label('Title')
              .max(33, 'Must be at most ${max} characters')
              .default(''),

            images: bannerDesktopOnlyImagesSchema,
          }),
        })
        .when('view_type', {
          is: BannerView.TEXT,
          otherwise: schema => schema.nullable().transform(() => null),
        }),

      tournament_view: yup
        .object()
        .shape({
          view: yup.string().default(BannerView.TOURNAMENT),
          locales: getLocalesSchema({
            images: bannerDesktopOnlyImagesSchema,
            tournament: yup
              .object({
                value: yup.string(),
                label: yup.string(),
              })
              .label('Tournament')
              .nullable()
              .default(null)
              .when('$activate', {
                is: true,
                then: schema => schema.required('Tournament is required'),
              }),
          }),
        })
        .when('view_type', {
          is: BannerView.TOURNAMENT,
          otherwise: schema => schema.nullable().transform(() => null),
        }),
    })
    .when('banner_type', {
      is: BannerType.POPULAR_NOW,
      otherwise: schema => schema.nullable().transform(() => null),
    }),

  code_banner: yup
    .object()
    .shape({
      placeholders: yup
        .array()
        .label('Placeholders')
        .of(yup.string())
        .default([])
        .when('$activate', {
          is: true,
          then: schema => schema.min(1, '${label} is required').required(),
        }),

      restrictions: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string(),
            value: yup.string(),
            name: yup.string(),
            secondary_text: yup.string(),
          })
        )
        .default([]),

      locales: getLocalesSchema({
        code: yup
          .string()
          .label('Code')
          .default('')
          .when('$activate', {
            is: true,
            then: schema => schema.required(),
          }),
      }),
    })
    .when('banner_type', {
      is: BannerType.CODE_BANNER,
      otherwise: schema => schema.nullable().transform(() => null),
    }),
});

export default BannerValidationSchema;
