import { useCallback } from 'react';
import BooleanField from '../../components/Fields/BooleanField/BooleanField';
import DateField from '../../components/Fields/DateField/DateField';
import EmptyField from '../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../components/Fields/LinkField/LinkField';
import StringField from '../../components/Fields/StringField/StringField';
import Table from '../../components/Table/Table';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import { useTableBulkContext } from '../../providers/TableBulkProvider';
import FailedRequestAttempts from './FailedRequestAttempts';

export const COLUMN = {
  requestId: 'id',
  playerId: 'player_id',
  betId: 'bet_id',
  brand: 'brand',
  method: 'method',
  url: 'url',
  verifySsl: 'verify_ssl',
  retry: 'retry',
  useRsa: 'use_rsa',
  marker: 'marker',
  executed: 'executed',
  timestamp: 'timestamp',
  lastAttempt: 'last_attempt',
  attempts: 'attempts',
};

export const columnsWidthConfig = {
  [COLUMN.requestId]: 0.4,
  [COLUMN.playerId]: 0.4,
  [COLUMN.betId]: 0.4,
  [COLUMN.brand]: 0.3,
  [COLUMN.method]: 0.15,
  [COLUMN.url]: 1,
  [COLUMN.verifySsl]: 0.15,
  [COLUMN.retry]: 0.15,
  [COLUMN.useRsa]: 0.15,
  [COLUMN.marker]: 0.2,
  [COLUMN.executed]: 0.2,
  [COLUMN.timestamp]: 0.31,
  [COLUMN.lastAttempt]: 0.2,
  [COLUMN.attempts]: 0.2,
};

export const columnsOrderConfig = [
  COLUMN.requestId,
  COLUMN.playerId,
  COLUMN.betId,
  COLUMN.brand,
  COLUMN.method,
  COLUMN.url,
  COLUMN.verifySsl,
  COLUMN.retry,
  COLUMN.useRsa,
  COLUMN.marker,
  COLUMN.executed,
  COLUMN.timestamp,
  COLUMN.lastAttempt,
  COLUMN.attempts,
];

export const initialToggledColumns = [
  COLUMN.requestId,
  COLUMN.playerId,
  COLUMN.betId,
  COLUMN.brand,
  COLUMN.method,
  COLUMN.url,
  COLUMN.verifySsl,
  COLUMN.retry,
  COLUMN.useRsa,
  COLUMN.marker,
  COLUMN.executed,
  COLUMN.timestamp,
  COLUMN.lastAttempt,
  COLUMN.attempts,
];

export const columnsMinWidthConfig = {
  [COLUMN.requestId]: '190px',
  [COLUMN.playerId]: '160px',
  [COLUMN.betId]: '160px',
  [COLUMN.brand]: '125px',
  [COLUMN.method]: '60px',
  [COLUMN.url]: '320px',
  [COLUMN.verifySsl]: '75px',
  [COLUMN.retry]: '50px',
  [COLUMN.useRsa]: '65px',
  [COLUMN.marker]: '105px',
  [COLUMN.executed]: '70px',
  [COLUMN.timestamp]: '140px',
  [COLUMN.lastAttempt]: '140px',
  [COLUMN.attempts]: '65px',
};

const columns = [
  {
    label: 'Request ID',
    key: COLUMN.requestId,
    render: ({ id }) => {
      return id ? (
        <StringField title={id}>
          <LinkField href={`/api/v1/OperatorAPI/request/failed/${id}/get`} copy>
            {id}
          </LinkField>
        </StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Player ID',
    key: COLUMN.playerId,
    render: ({ player_id }) => {
      return player_id ? (
        <StringField>{player_id}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Bet ID',
    key: COLUMN.betId,
    render: ({ bet_id }) => {
      return bet_id ? <StringField>{bet_id}</StringField> : <EmptyField />;
    },
  },
  {
    label: 'Brand',
    key: COLUMN.brand,
    render: ({ brand }) => {
      return brand ? (
        <StringField title={brand}>{brand}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Method',
    key: COLUMN.method,
    render: ({ method }) => {
      return method ? <StringField>{method}</StringField> : <EmptyField />;
    },
  },
  {
    label: 'Url',
    key: COLUMN.url,
    render: ({ url }) => {
      return url ? <StringField>{url}</StringField> : <EmptyField />;
    },
  },
  {
    label: 'Verify SSL',
    key: COLUMN.verifySsl,
    style: { textAlign: 'center' },
    render: ({ verify_ssl }) => {
      return <BooleanField active={verify_ssl} />;
    },
  },
  {
    label: 'Retry',
    key: COLUMN.retry,
    style: { textAlign: 'center' },
    render: ({ retry }) => {
      return <BooleanField active={retry} />;
    },
  },
  {
    label: 'Use RSA',
    key: COLUMN.useRsa,
    style: { textAlign: 'center' },
    render: ({ use_rsa }) => {
      return <BooleanField active={use_rsa} />;
    },
  },
  {
    label: 'Marker',
    key: COLUMN.marker,
    render: ({ marker }) => {
      return marker ? <StringField>{marker}</StringField> : <EmptyField />;
    },
  },
  {
    label: 'Executed',
    key: COLUMN.executed,
    style: { textAlign: 'center' },
    render: ({ executed }) => {
      return <BooleanField active={executed} />;
    },
  },
  {
    label: 'Date',
    key: COLUMN.timestamp,
    render: ({ timestamp }) => {
      return timestamp ? (
        <StringField>
          <DateField date={timestamp} format={'YYYY/MM/DD HH:mm:ss'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Last Attempt',
    key: COLUMN.lastAttempt,
    render: ({ last_attempt }) => {
      return last_attempt ? (
        <StringField>
          <DateField date={last_attempt} format={'YYYY/MM/DD HH:mm:ss'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Attempts',
    key: COLUMN.attempts,
    render: ({ attempts }) => {
      return attempts ? <StringField>{attempts}</StringField> : <EmptyField />;
    },
  },
];

const FailedRequestsTable = ({
  data,
  loading,
  rowActions,
  tableOffsetTop,
  onChangeOrderBy = () => {},
  onToggleColumns = () => {},
}) => {
  const bulk = useTableBulkContext();
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      const rowId = rowData[COLUMN.requestId];

      return <FailedRequestAttempts key={rowId} request_id={rowId} />;
    },
    []
  );

  return (
    <Table
      id={COLUMN.requestId}
      data={data}
      columns={columns}
      order={order}
      initialToggledColumns={initialToggledColumns}
      columnsWidth={columnsWidthConfig}
      minWidthConfig={columnsMinWidthConfig}
      expandAll={true}
      expandable={true}
      renderExpandedRow={renderExpandedRow}
      onReorder={onReorder}
      //   onChangeOrderBy={onChangeOrderBy}
      loading={loading}
      bulk={bulk}
      scrollable
      onToggleColumns={onToggleColumns}
      // configMenu={false}
    />
  );
};

export default FailedRequestsTable;
