import { InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  hideButton: {
    fontSize: '22px',
  },
  inputAdornmentEndRoot: {
    right: 9,
    position: 'absolute',
    zIndex: 1,
  },

  colorPicker: {
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    height: 24,
    width: 24,
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    '&::-webkit-color-swatch': {
      borderRadius: 4,
      border: '1px solid #D9DDE3',
      padding: 0,
      margin: 0,
      maxHeight: 24,
      maxWidth: 24,
      minHeight: 24,
      minWidth: 24,
      marginTop: -4,
      marginLeft: -2,
      boxSizing: 'border-box',
    },
    '&::-moz-color-swatch': {
      borderRadius: 4,
      border: '1px solid #D9DDE3',
      padding: 0,
      margin: 0,
      maxHeight: 24,
      maxWidth: 24,
      minHeight: 24,
      minWidth: 24,
      marginTop: -4,
      marginLeft: -2,
      boxSizing: 'border-box',
    },
  },
});

const ColorPickerAdornment = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <InputAdornment
      position="end"
      classes={{
        root: classes.inputAdornmentEndRoot,
      }}
    >
      <input
        type="color"
        value={value}
        onChange={onChange}
        className={classes.colorPicker}
      />
    </InputAdornment>
  );
};

export default ColorPickerAdornment;
