import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ExtendableField from '../ExtendableField';
import FormattedValueText from '../FormattedValueText';
import { DecoratorsContext } from '../DecoratorsProvider';
import PercentageValue from '../PercentageValue';
import { useTableSummaryContext } from '../../../providers/TableSummaryProvider';

const useStyles = makeStyles({
  stringField: {},
});

const StringField = () => {
  const classes = useStyles();

  const { showPercentage } = useContext(DecoratorsContext);

  const isSummaryContext = useTableSummaryContext();

  return (
    <ExtendableField>
      <div className={classes.stringField}>
        <FormattedValueText />
        {!isSummaryContext && showPercentage && <PercentageValue />}
      </div>
    </ExtendableField>
  );
};

export default StringField;
