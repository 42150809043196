import PopperUnstyled from '@mui/base/PopperUnstyled';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import isObject from 'lodash/isObject';
import { forwardRef } from 'react';
import useFancyAutocomplete from '../../../../hooks/useFancyAutocomplete';
import ExpandIcon from '../../../../icons/controls/ExpandIcon';
import mergeRefs from '../../../../utils/mergeRefs';
import Highlighted from '../../Highlighted/Highlighed';
import Checkbox from '../Checkbox';
import TextField from '../TextField';

export const Input = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    paddingRight: 8,
    paddingLeft: 8,
    textOverflow: 'ellipsis',
  },
}));

const ImagePreview = styled('img')(() => ({
  marginRight: '8px',
  borderRadius: '4px',
}));

const FancySelectGroup = styled('div')(({}) => ({
  position: 'relative',
}));

const FancySelectGroupHeader = styled('div')(({}) => ({
  padding: '11px 12px',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '0.2px',
  position: 'sticky',
  backgroundColor: '#fff',
  color: '#8496AB',
  top: 0,
}));

export const FancySelectLoading = styled('div')(() => ({
  padding: '11px 12px',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '0.2px',
  color: '#8496AB',
  gridColumn: '1 / -1',
}));

export const FancySelectOption = styled('li')(({ disabled }) => ({
  padding: '11px 12px',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '0.2px',
  display: 'flex',
  wordBreak: 'break-word',
  '&:active': {
    backgroundColor: '#E8F2FF',
    color: '#1581FF',
  },
  ...(disabled
    ? {
        pointerEvents: disabled && 'none',
        opacity: disabled && 0.5,
      }
    : {
        '&:hover': {
          color: '#1581FF',
          cursor: 'pointer',
          backgroundColor: '#E8F2FF',
        },
      }),
}));

export const FancySelectOptionRight = styled('div')`
  margin-left: auto;
`;

const FancySelectDivider = styled('div')(() => ({
  height: 1,
  width: '100%',
  backgroundColor: '#8496AB',
  opacity: 0.2,
}));

export const SelectContainer = styled('div')(() => ({
  position: 'relative',
}));

const defaultFilterOptions = createFilterOptions({
  stringify: option => {
    return isObject(option) ? Object.values(option).join('') : option;
  },
});

export const Listbox = styled('ul')(({ theme }) => ({
  borderRadius: 8,
  width: '100%',
  margin: '4px 0 0',
  padding: 0,
  listStyle: 'none',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  maxHeight: 195,
  boxShadow: '0px 2px 16px rgba(132, 150, 171, 0.25)',
  '& li[data-focus="true"]': {
    backgroundColor: '#4a8df6',
    color: 'white',
    cursor: 'pointer',
  },
}));

const FancySelect = forwardRef(
  (
    {
      id,
      name,
      value,
      loading,
      valueKey = 'value',
      labelKey = 'label',
      uniqueKey,
      imageKey,
      onChange,
      options,
      extraOptions = null,
      isOptionSelected,
      disabled = false,
      isOptionDisabled = () => {
        return false;
      },
      label,
      getInputPlaceholder,
      onChangeSearch = () => {},
      multiple = true,
      error,
      helperText,
      groupBy,
      groupHeader,
      required,
      filterOptions,
      disableFilterOptions = false,
      inModal = false,
      withExpandIcon = false,
      alwaysShowClearButton = false,
    },
    ref
  ) => {
    const {
      anchorEl,
      getOptionProps,
      searchInputValue,
      onSearchInputChange,
      inputPlaceholder,
      inputProps,
      popupOpen,
      groupedOptions,
      focused,
      isOptionSelectedCb,
      getRootProps,
      getListboxProps,
      popperProps,
    } = useFancyAutocomplete({
      id,
      value,
      onChange,
      label,
      options,
      valueKey,
      labelKey,
      multiple,
      groupBy,
      isOptionSelected,
      onChangeSearch,
      getInputPlaceholder,
      filterOptions: disableFilterOptions
        ? x => x
        : filterOptions || defaultFilterOptions,
      inModal,
    });

    const renderOption = (option, index) => {
      const { className, ...optionProps } = getOptionProps({
        option,
        index,
      });

      return (
        <FancySelectOption
          {...optionProps}
          key={
            uniqueKey
              ? Array.isArray(uniqueKey)
                ? uniqueKey.reduce((acc, k) => `${acc}_${option[k]}`, '')
                : option[uniqueKey]
              : optionProps.key
          }
          disabled={isOptionDisabled(option)}
        >
          {imageKey && (
            <ImagePreview
              width={60}
              alt={option[labelKey]}
              src={
                typeof imageKey === 'string'
                  ? option[imageKey]
                  : imageKey(option)
              }
            />
          )}
          <Highlighted highlight={searchInputValue}>
            {option[labelKey]}
          </Highlighted>{' '}
          <div
            style={{
              marginLeft: 'auto',
            }}
          >
            {multiple && <Checkbox value={isOptionSelectedCb(option)} />}
          </div>
        </FancySelectOption>
      );
    };

    return (
      <SelectContainer ref={anchorEl}>
        <div {...getRootProps()}>
          {/*<Label {...getInputLabelProps()}>useAutocomplete</Label>*/}
          {/*It is styled component based on custom TextField*/}
          <Input
            {...inputProps}
            name={name}
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
            popupIconComponent={
              withExpandIcon && (
                <ExpandIcon
                  expanded={popupOpen}
                  style={{ marginRight: '6px' }}
                />
              )
            }
            inputProps={{
              ref: mergeRefs(ref, inputProps.ref),
              autocomplete: 'off',
            }}
            onBlur={inputProps.onBlur}
            onFocus={inputProps.onFocus}
            onMouseDown={!disabled ? inputProps.onMouseDown : undefined}
            value={inputPlaceholder}
            onChange={onSearchInputChange}
            label={label}
            type={'text'}
            margin={'dense'}
            variant="outlined"
            fullWidth
            clearButton={focused || alwaysShowClearButton}
          />
        </div>
        {!loading && popupOpen ? (
          <PopperUnstyled
            {...popperProps}
            data-cy={`select-popper-${name}`}
            data-loading={loading}
            keepMounted
          >
            <Listbox {...getListboxProps()}>
              {loading && <FancySelectLoading>Loading ...</FancySelectLoading>}
              {groupedOptions.length === 0 && !loading && (
                <FancySelectLoading>No options</FancySelectLoading>
              )}
              {extraOptions}
              {extraOptions && groupedOptions.length > 0 && (
                <FancySelectDivider />
              )}
              {groupBy
                ? groupedOptions.map(group => {
                    return (
                      <FancySelectGroup>
                        <FancySelectGroupHeader>
                          {groupHeader ? groupHeader(group) : group.group}
                        </FancySelectGroupHeader>
                        {group.options.map((option, index) =>
                          renderOption(option, index)
                        )}
                      </FancySelectGroup>
                    );
                  })
                : groupedOptions.map((option, index) => {
                    return option && renderOption(option, index);
                  })}
            </Listbox>
          </PopperUnstyled>
        ) : (
          <PopperUnstyled {...popperProps}>
            <Listbox>
              {loading && popupOpen && (
                <FancySelectLoading>Loading ...</FancySelectLoading>
              )}
            </Listbox>
          </PopperUnstyled>
        )}
      </SelectContainer>
    );
  }
);

export default FancySelect;
