import MoreVertIcon from '../../../icons/controls/MoreVertIcon';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from 'react';
import DragDropMenu from './DragDropMenu';

const useStyles = makeStyles(theme => ({
  tableConfigMenu: {
    backgroundColor: '#F7F7F7',
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
  tableConfigIcon: {
    fontSize: '10px',
  },
  rowActionsButtonRoot: {
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
    color: '#031224',
    '&:hover': {
      color: '#8496AB',
    },
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  },
}));

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const isToggled = (column, toggledColumns) => {
  return (
    column.toggled ||
    (toggledColumns && toggledColumns.indexOf(column.key) !== -1)
  );
};

const TableConfigMenu = ({
  disabledColumns,
  toggledColumns,
  columns = [],
  onToggleColumn,
  onReorder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnsToToggle, setColumnsToToggle] = useState([]);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    setColumnsToToggle(toggledColumns);
  }, [toggledColumns]);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    onToggleColumn(columnsToToggle);
  }, [columnsToToggle, onToggleColumn]);

  const classes = useStyles();

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const reordered = reorder(
        columns.map(column => column.key),
        result.source.index,
        result.destination.index
      );

      onReorder(reordered);
      // onToggleColumn(reordered)
    },
    [columns, onReorder]
  );

  return (
    <div className={classes.tableConfigMenu}>
      <button
        disabled={!columns || !columns.length}
        className={classes.rowActionsButtonRoot}
        onClick={e => {
          if (e.currentTarget) {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <MoreVertIcon className={classes.tableConfigIcon} />
      </button>
      <DragDropMenu
        onDragEnd={onDragEnd}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        columns={columns}
        disabledColumns={disabledColumns}
        onToggleColumn={(newColumn, toggled) => {
          setColumnsToToggle(prevState =>
            toggled
              ? [...prevState, newColumn]
              : prevState.filter(c => c !== newColumn)
          );
        }}
        toggledColumns={columnsToToggle}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default TableConfigMenu;
