import { combineReducers } from 'redux';
import users from './users';
import schemaUrls from './schemaUrls';
import drawerOpen from './drawerOpen';

const persistable = combineReducers({
  drawerOpen,
  schemaUrls,
  users,
});

export default persistable;
