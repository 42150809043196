import React, { useState } from 'react';
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import emailIcon from '../../../assets/email.svg';
import warning from '../../../assets/warning.svg';
import isEmail from 'validator/lib/isEmail';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';

const FirstStep = ({ onSubmit }) => {
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      email: searchParams.get('email') || '',
    },
    validationSchema: object({
      email: string().test({
        name: 'email',
        message: 'Enter a valid email address',
        test: (value = '') => isEmail(value),
      }),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Stack gap={'24px'}>
        <Stack gap={'8px'}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={emailIcon} />
                </InputAdornment>
              ),
            }}
            placeholder={'Email'}
            name={'email'}
            onChange={v => {
              formik.handleChange(v);
              formik.setFieldTouched('email', false, false);
            }}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '14px',
              },
              '& .MuiInputAdornment-root': {
                width: '30px',
                '&.MuiInputAdornment-positionStart': {
                  color: '#1481ff',
                },
              },
            }}
          />
          <Stack
            direction={'row'}
            gap={'8px'}
            sx={{
              backgroundColor: '#EDBE181A',
              padding: '12px',
              borderRadius: '8px',
              alignItems: 'flex-start',
            }}
          >
            <img src={warning} />
            <Typography fontSize={'12px'} lineHeight={'16px'} color={'#846700'}>
              Please double-check that you have entered your email correctly. If
              the email address is entered incorrectly, the process of setting
              up a new password will not be completed.
            </Typography>
          </Stack>
        </Stack>
        <Button
          type={'submit'}
          variant={'contained'}
          disabled={
            !formik.values.email ||
            (formik.touched.email && Boolean(formik.errors.email))
          }
        >
          Continue
        </Button>
      </Stack>
    </form>
  );
};

export default FirstStep;
