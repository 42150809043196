import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ImageCrop from './ImageCrop';

const ImageCropModal = ({
  src,
  width,
  height,
  resultSize,
  onSubmit,
  onChangeCrop,
  onClose,
}) => {
  const [image, setImage] = useState(src);
  const pixelCrop = useRef(null);

  return (
    <Dialog open onClose={onClose} maxWidth={'xl'}>
      <DialogTitle>Move selection</DialogTitle>
      <DialogContent>
        <div
          style={
            {
              // height: height * scaleContainer,
              // width: width * scaleContainer,
            }
          }
        >
          <ImageCrop
            src={src}
            width={width}
            height={height}
            resultSize={resultSize}
            onCrop={setImage}
            onComplete={crop => {
              pixelCrop.current = crop;
              onChangeCrop && onChangeCrop(crop);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          onClick={() => {
            onSubmit(image, pixelCrop.current);
            onClose();
          }}
          color="primary"
          autoFocus
        >
          Use cropped image
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ImageCropModal.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageCropModal;
