import { useContext } from 'react';
import { DataContext } from '../providers/DataProvider';
import { useRawSchema } from '../providers/RawSchemaProvider';
import { removeLastSegment } from '../uiComponents/Table/TableUtils';

export const useRouteCheck = (method = 'put') => {
  const { path } = useContext(DataContext);

  const { paths } = useRawSchema();

  const pathKey = removeLastSegment(path);

  return {
    isRouteProvided: !!paths[pathKey]?.[method],
  };
};
