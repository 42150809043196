/* eslint-disable no-undef */

import React from 'react';

export default class Documentation extends React.Component {
  state = {
    rawDoc: null,
  };

  componentDidMount() {
    // fetch(doc)
    //   .then(response => response.text())
    //   .then(text => {
    //     this.setState({
    //       doc: text,
    //     });
    //   });
  }

  render() {
    return (
      <div>
        <h1>Documentation</h1>
        <div>Docs here</div>
      </div>
    );
  }

  updateCode = () => {};
}
