import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';

const useStyles = makeStyles({
  cell: {
    height: 38,
  },

  row: {
    animationName: '$tablePlaceholderLoading',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },

  '@keyframes tablePlaceholderLoading': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
});

const TableBodyPlaceholder = () => {
  const classes = useStyles();

  return (
    <TableBody>
      {[1, 0.8, 0.6, 0.4, 0.2].map((opacity, index) => (
        <TableRow
          key={index}
          className={classes.row}
          style={{
            animationDelay: `${index / 5}s`,
          }}
        >
          <TableCell
            colSpan={'100%'}
            className={classes.cell}
            style={{
              opacity,
            }}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableBodyPlaceholder;
