import React from 'react';
import { connect } from 'react-redux';
import DynamicModal from '../components/DynamicModal';
import {
  CONFIRMATION_MODAL,
  DYNAMIC_MODAL,
  IMAGE_CROP_MODAL,
} from '../constants/modalConstants';
import { getModal } from '../reducers/modal';
import { hideModal } from '../actions/modalActions';
import ConfirmationModal from '../components/ConfirmationModal';
import ImageCropModal from '../components/ImageCropModal';

const MODAL_COMPONENTS = {
  [DYNAMIC_MODAL]: DynamicModal,
  [CONFIRMATION_MODAL]: ConfirmationModal,
  [IMAGE_CROP_MODAL]: ImageCropModal,
};

const ModalRoot = ({ modalType, modalProps, hideModal }) => {
  const SpecificModal = MODAL_COMPONENTS[modalType];

  return (
    !!modalType && <SpecificModal {...modalProps} onClose={() => hideModal()} />
  );
};

export default connect(state => getModal(state), { hideModal })(ModalRoot);
