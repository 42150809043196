import { makeStyles } from '@mui/styles';

export const useTreeSelectStyles = makeStyles({
  treeSelectContainer: {
    height: 'inherit',
    display: 'flex',
    overflow: 'hidden',
    width: 'max-content',
    minWidth: '100%',
    //for loader
    position: 'relative',
  },
  treeSelectElement: {
    marginTop: '3px',
    minWidth: 250,
    width: 'max-content',
    height: 'inherit',
    paddingLeft: '5px',
  },
  treeSelectSearchField: {
    paddingBottom: 16,
  },
  treeSelectMenu: {
    marginTop: '5px',
    overflow: 'hidden',
    paddingRight: '4px',
    height: 'calc(100% - 86px)',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      background: 'rgba(132, 150, 171, 0.1)',
      padding: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(132, 150, 171, 0.4)',
    },
  },
  treeSelectMenuItem: {
    borderRadius: '4px',
    marginTop: '4px',
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundColor: 'rgba(247, 247, 247, 1)',
  },
  orderedList: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '20px',
    paddingBottom: '5px',
  },
  treeSelectItemSelected: {
    backgroundColor: 'rgba(21, 129, 255, 1)!important',
    color: 'white',
  },
  treeSelectItemFullWidth: {
    width: '100%',
  },
  sportOptionContainer: {
    cursor: 'pointer',
    padding: '8px 0 8px 6px',
    width: '100%',
  },
  sportOptionCaption: {
    fontSize: '14px!important',
    lineHeight: '20px',
  },

  virtualizedMenu: {
    paddingRight: '6px',
    height: '90%',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      background: 'rgba(132, 150, 171, 0.1)',
      padding: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(132, 150, 171, 0.4)',
    },
    overflowY: 'auto !important',
    overflowX: 'hidden !important',
  },

  dragListContainer: {
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dragMenuItemRoot: {
    backgroundColor: 'rgba(247, 247, 247, 1)',
    marginBottom: '4px',
    borderRadius: '4px',
    // padding: '8px',
    justifyContent: 'flex-start',

    // '&>$menuItemLabel': {
    //   marginRight: 'auto',
    //   marginLeft: 6,
    // },
  },
  dragMenuItemRootDragging: {
    borderRadius: 8,
    boxShadow: '0px 4px 16px rgba(132, 150, 171, 0.5)',
  },
  dragMenuItemLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dragMenuItemCheckbox: {
    padding: 0,
  },
  whiteIcon: {
    filter: 'brightness(0) invert(1)',
  },
  virtualizedDragging: {
    left: '0 !important',
    top: 'auto !important',
  },
  moveButton: {
    display: 'flex',
    color: '#8496AB',
    padding: '12px 2px 12px 6px',
    '&>svg': {
      fontSize: '16px',
    },
  },
  moveButtonDisabled: {
    color: '#E0E0E0',
  },
  menuCaptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '12px',
  },
  menuCaption: {
    fontWeight: 600,
    lineHeight: '17.07px',
  },

  fancySelectOption: {
    cursor: 'pointer',
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    padding: '8px 0 8px 6px',
    width: '100%',
  },
  flagField: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
  bigCountryIcon: {
    width: '24px',
    height: '24px',
    border: 'none',
    borderRadius: '100%',
    backgroundSize: '24px',
  },
  fancyOptionLabelContainer: {
    whiteSpace: 'nowrap',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
  },
  underlinedContainer: {
    textDecoration: 'line-through',
  },

  savePresetField: {
    display: 'flex',
    height: '44px',
    marginTop: '4px',
    padding: '10px 0 10px 0',
    justifyContent: 'end',
  },
  savePresetButton: {
    height: 'auto',
    width: '60px',
    fontSize: '10px',
  },

  modalContentBox: {
    marginTop: '17px',
    paddingTop: '3px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    overflow: 'hidden',
  },
  modalTittle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalCloseButton: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  },
  expandListDisabled: {
    pointerEvents: 'none',
  },
  expandedButtonDisabled: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
  sourcesListExpandButton: {
    padding: '0 5px 0 5px',
    zIndex: 2,
  },
  //
});

export default useTreeSelectStyles;
