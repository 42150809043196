/**
 * Basically checks if child element has bigger content width than a parent.
 * For now supports only single child.
 * This method can account for such cases as text-overflow: ellipsis,
 * when it's not possible to check real overflow with simple clientWidth - scrollWidth comparison
 */
const isElementOverflowed = el => {
  const childEl = el.children[0];

  let measureScrollWidth = childEl.clientWidth;
  const initialStyleWidth = childEl.style.width;

  childEl.style.width = 'max-content';
  const childElRect = childEl.getBoundingClientRect();

  if (childElRect.width > measureScrollWidth) {
    measureScrollWidth = childElRect.width;
  }

  childEl.style.width = initialStyleWidth;

  //clientWidth width rounds each time, but rect width can be with a small decimal part
  return Math.trunc(measureScrollWidth) > childEl.clientWidth;
};

export default isElementOverflowed;
