import {
  FancySelectLoading,
  Input,
  Listbox,
  SelectContainer,
} from '../FancySelect/FancySelect';
import React, { forwardRef, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import getImages from '../../../gql/Promo/queries/getImages';
import useFancyAutocomplete from '../../../../hooks/useFancyAutocomplete';
import mergeRefs from '../../../../utils/mergeRefs';
import PopperUnstyled from '@mui/base/PopperUnstyled/PopperUnstyled';
import Highlighted from '../../Highlighted/Highlighed';
import { styled } from '@mui/material/styles';
import { createFilterOptions } from '@mui/material/Autocomplete';
import useDebounced from '../../../hooks/useDebounced';

const ImageSelectOptionStyled = styled('li')(({ disabled }) => ({
  maxWidth: 90,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '4px 0px',
  borderRadius: '4px',
  transition: 'background-color 0.2s ease-in-out',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#E8F2FF',
  },
}));

const ImagePreviewStyled = styled('img')(() => ({
  borderRadius: '4px',
}));

const ImageDescriptionBlockStyled = styled('div')(() => ({
  lineHeight: '13px',
  height: '2rem',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ImageLabelStyled = styled('div')(() => ({
  fontWeight: 500,
  fontSize: '11px',
  letterSpacing: '0.2px',
  marginTop: '4px',
  overflow: 'hidden',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  textAlign: 'center',
}));

export const ImageMetaStyled = styled('div')(() => ({
  fontFamily: `'B612 Mono', monospace`,
  fontWeight: 400,
  fontSize: '9px',
  letterSpacing: '0.2px',
  color: '#8496AB',
  marginTop: '4px',
}));

export const ImagesListboxStyled = styled(Listbox)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(90px, 1fr))',
  gap: '10px 2px',
  padding: '8px',
}));

const ImageLibrarySelect = forwardRef(
  (
    {
      id,
      disabled,
      withOperator,
      operatorId,
      brandId,
      value,
      onChange,
      label,
      valueKey = 'id',
      labelKey = 'name',
      multiple = false,
      helperText,
      error,
      required,
      imageKey,
      metaKey,
      isOptionDisabled = () => {
        return false;
      },
    },
    ref
  ) => {
    const params = useMemo(
      () => ({
        input: {
          operator_id: withOperator ? operatorId : null,
          brand_id: brandId,
          offset: 0,
          limit: 60,
          // image_type: ,
        },
      }),
      [withOperator, operatorId, brandId]
    );

    const { data, loading, fetchMore } = useQuery(getImages, {
      variables: params,
      skip: (withOperator && !operatorId) || !brandId,
    });
    const { items: options = [], total = 0 } = data?.data || {};

    const filterOptions = useMemo(
      () =>
        createFilterOptions({
          stringify: option => option[labelKey],
        }),
      [labelKey]
    );

    const loadMoreImages = () =>
      fetchMore({
        variables: {
          ...params,
          input: {
            ...params.input,
            offset: options.length,
          },
        },
      });

    const debouncedLoadMoreImages = useDebounced(loadMoreImages, 600, {
      leading: true,
      trailing: false,
    });

    const handleListScroll = useCallback(
      e => {
        const { scrollHeight, scrollTop, offsetHeight } = e.target;
        if (
          scrollTop > scrollHeight - offsetHeight - 120 &&
          options.length < total
        ) {
          debouncedLoadMoreImages();
        }
      },
      [debouncedLoadMoreImages, options.length, total]
    );

    const {
      anchorEl,
      popperProps,
      getOptionProps,
      searchInputValue,
      onSearchInputChange,
      inputPlaceholder,
      inputProps,
      popupOpen,
      groupedOptions,
      focused,
      isOptionSelectedCb,
      getRootProps,
      getListboxProps,
    } = useFancyAutocomplete({
      id,
      value,
      onChange,
      label,
      options,
      valueKey,
      labelKey,
      multiple,
      filterOptions,
    });

    const renderOption = (option, index) => {
      const { className, ...optionProps } = getOptionProps({
        option,
        index,
      });

      return (
        <ImageSelectOptionStyled
          {...optionProps}
          disabled={isOptionDisabled(option)}
          key={option[valueKey]}
        >
          {imageKey && (
            <ImagePreviewStyled
              width={60}
              alt={option[labelKey]}
              src={
                typeof imageKey === 'string'
                  ? option[imageKey]
                  : imageKey(option)
              }
            />
          )}
          <ImageDescriptionBlockStyled>
            <ImageLabelStyled>
              <Highlighted highlight={searchInputValue}>
                {option[labelKey]}
              </Highlighted>{' '}
            </ImageLabelStyled>
            <ImageMetaStyled>
              <Highlighted highlight={searchInputValue}>
                {typeof metaKey === 'function'
                  ? metaKey(option)
                  : option[metaKey]}
              </Highlighted>{' '}
            </ImageMetaStyled>
          </ImageDescriptionBlockStyled>
        </ImageSelectOptionStyled>
      );
    };

    return (
      <SelectContainer ref={anchorEl}>
        <div {...getRootProps()}>
          <Input
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
            inputProps={{
              ref: mergeRefs(ref, inputProps.ref),
            }}
            onBlur={inputProps.onBlur}
            onFocus={inputProps.onFocus}
            onMouseDown={!disabled ? inputProps.onMouseDown : undefined}
            value={inputPlaceholder}
            onChange={onSearchInputChange}
            label={label}
            type={'text'}
            margin={'dense'}
            variant="outlined"
            fullWidth
            clearButton={focused}
            endAdornmentText={
              inputPlaceholder ? `${groupedOptions.length} results` : null
            }
          />
        </div>
        {popupOpen ? (
          <PopperUnstyled
            {...popperProps}
            style={{
              ...popperProps.style,
              zIndex: 9999,
            }}
          >
            <ImagesListboxStyled
              {...getListboxProps()}
              onScroll={e => handleListScroll(e)}
            >
              {loading && <FancySelectLoading>Loading ...</FancySelectLoading>}
              {groupedOptions.length === 0 && !loading && (
                <FancySelectLoading>No options</FancySelectLoading>
              )}
              {groupedOptions.map((option, index) =>
                renderOption(option, index)
              )}
            </ImagesListboxStyled>
          </PopperUnstyled>
        ) : null}
      </SelectContainer>
    );
  }
);

export default ImageLibrarySelect;
