import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import usePrevious from '../../hooks/usePrevious';

const getKeyed = value => {
  return {
    key: uuid(),
    value,
  };
};

const getKeyedArray = items => {
  return items.map(getKeyed);
};

const getPlain = items => {
  return items.map(item => item.value);
};

const useKeyedArray = (value = [], onChange = () => {}) => {
  const [keyed, setKeyed] = useState(getKeyedArray(value));
  const prevKeyed = usePrevious(keyed);

  const append = useCallback(item => {
    const value = Array.isArray(item)
      ? item.map(i => getKeyed(i))
      : [getKeyed(item)];
    setKeyed(items => [...items, ...value]);
  }, []);

  const prepend = useCallback(() => {
    setKeyed(items => [getKeyed(item), ...items]);
  }, []);

  const removeByKey = useCallback(key => {
    setKeyed(items => items.filter(item => item.key !== key));
  }, []);

  const remove = useCallback(index => {
    setKeyed(items => items.filter((item, i) => i !== index));
  }, []);

  useEffect(() => {
    if (!isEqual(getPlain(keyed), value) && keyed === prevKeyed) {
      setKeyed(getKeyedArray(value));
    }
  }, [keyed, value, prevKeyed]);

  useEffect(() => {
    if (keyed !== prevKeyed && typeof prevKeyed !== 'undefined') {
      onChange(getPlain(keyed));
    }
  }, [onChange, keyed, prevKeyed]);

  return useMemo(() => {
    return { items: keyed, remove, removeByKey, append, prepend };
  }, [keyed, remove, removeByKey, append, prepend]);
};

export default useKeyedArray;
