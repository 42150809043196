import Table from '../../components/Table/Table';

const commonCellStyle = {
  padding: '0px 16px',
};

const TableDesignerPage = () => {
  return (
    <div>
      <Table />
    </div>
  );
};

export default TableDesignerPage;
