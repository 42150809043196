import { gql } from '@apollo/client';

const SPORTS_AUTOCOMPLETE_QUERY = gql`
  query getSportsNames(
    $query: String!
    $limit: Int
    $offset: Int
    $sport_ids: [ID!]
  ) @api(name: rm) {
    data: getSportsNames(
      query: $query
      limit: $limit
      offset: $offset
      sport_ids: $sport_ids
    ) {
      label
      value
    }
  }
`;

export default SPORTS_AUTOCOMPLETE_QUERY;
