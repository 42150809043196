import { isEmpty } from 'lodash';
import omit from 'lodash/omit';
import some from 'lodash/some';

export const getSubmitMethodByPath = path => {
  const pathChunks = path.split('/');
  const method = pathChunks[pathChunks.length - 1];
  return method === 'get' ? 'put' : 'post';
};

export const replaceMethodByPath = (path, method) => {
  return path.replace(/\/([a-z]+)$/, method);
};

function isNestedEmpty(value) {
  if (isEmpty(value) && value !== null) {
    return false;
  }

  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).every(prop => isNestedEmpty(value[prop]));
  }

  return false;
}

export const hasNestedRequired = schema => {
  if (schema && schema.required && schema.required.length) {
    return true;
  }

  if (schema && schema.properties) {
    return some(schema.properties, hasNestedRequired);
  }

  return false;
};

export function removeOptionalEmptyValues(data, schema) {
  if (schema.type === 'object' && !isEmpty(data) && data) {
    let required = schema.required || [];

    Object.keys(data).forEach(prop => {
      let newVal = data[prop];

      if (
        required.indexOf(prop) < 0 &&
        isNestedEmpty(data[prop]) &&
        (!schema.properties || !hasNestedRequired(schema.properties[prop]))
      ) {
        newVal = undefined;
      } else if (schema.properties && schema.properties[prop]) {
        newVal = removeOptionalEmptyValues(data[prop], schema.properties[prop]);
      }

      data = { ...data, [prop]: newVal };

      if (
        typeof newVal === 'undefined' &&
        !(schema.properties && schema.properties[prop])
      ) {
        data = omit(data, [prop]);
      }
    });
  } else if (schema.type === 'array' && Array.isArray(data)) {
    data = data
      .map(item => {
        if (schema.items && !isEmpty(item)) {
          item = removeOptionalEmptyValues(item, schema.items);
        }

        return item;
      })
      .filter(item => !isNestedEmpty(item));
  }

  return data;
}
