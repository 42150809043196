import React, { useContext } from 'react';
import DynamicComponent from '../../components/DynamicComponent';
import Table from './Table';
import SchemaProvider from '../../providers/SchemaProvider';
import UiComponentSchemaProvider from '../../providers/UiComponentSchemaProvider';
import { RoutesContext } from '../../providers/RoutesProvider';
import { getParams } from '../../utils/schemaHelper';
import DataProvider, { DataContext } from '../../providers/DataProvider';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import FieldList from '../FieldList/FieldList';
import LocalQueryProvider from '../../providers/LocalQueryProvider';
import useQueryByPath from '../../hooks/useQueryByPath';
import LinksProvider from '../../providers/LinksProvider';
import NestedTableProvider from '../../providers/NestedTableProvider';

export const NESTED_COMPONENT_TYPE_ENUM = {
  TABLE: 'table',
  FIELD_LIST: 'field_list',
  DYNAMIC: 'dynamic',
};

const NestedTableComponent = ({
  component: { type, schema, uiSchema, link, title },
}) => {
  const NESTED_COMPONENT_TYPE_MAP = {
    [NESTED_COMPONENT_TYPE_ENUM.DYNAMIC]: DynamicComponent,
    [NESTED_COMPONENT_TYPE_ENUM.TABLE]: Table,
    [NESTED_COMPONENT_TYPE_ENUM.FIELD_LIST]: FieldList,
  };
  const routes = useContext(RoutesContext);
  const { data: parentRowData, path } = useContext(DataContext);
  const { query } = useQueryByPath(path);
  const matchParams = useParams();

  if (type === NESTED_COMPONENT_TYPE_ENUM.DYNAMIC && link) {
    return (
      <NestedTableProvider>
        <DynamicComponent
          parentKey={0}
          responseBody={parentRowData}
          routes={routes}
          path={link.path}
          params={
            getParams({
              link,
              responseBody: parentRowData,
              requestQuery: query,
              requestPath: matchParams,
            }).params
          }
        />
      </NestedTableProvider>
    );
  }

  const Component =
    NESTED_COMPONENT_TYPE_MAP[type] ||
    (() => <div>Unknown nested component</div>);

  return (
    <NestedTableProvider>
      <SchemaProvider value={schema}>
        <UiComponentSchemaProvider value={uiSchema} path={title}>
          <LinksProvider links={{}}>
            <LocalQueryProvider>
              <DataProvider data={get(parentRowData, title, [])}>
                {<Component nestedKey={title} />}
              </DataProvider>
            </LocalQueryProvider>
          </LinksProvider>
        </UiComponentSchemaProvider>
      </SchemaProvider>
    </NestedTableProvider>
  );
};

export default NestedTableComponent;
