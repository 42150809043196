import { gql } from '@apollo/client';

const getBrandsWithAssignedTemplates = gql`
  query getBrandsWithAssignedTemplates {
    data: getBrandsWithAssignedTemplates {
      brand {
        id
        name
      }
      margin {
        id
        name
      }
      coverage {
        id
        name
      }
      sources {
        id
        name
      }
      settings {
        settingsId
        version
      }
    }
  }
`;

export default getBrandsWithAssignedTemplates;
