import { gql } from '@apollo/client';

const getCompetitorLangs = gql`
  query getCompetitorLangs($sportId: SportId!, $competitorId: CompetitorId!) {
    getCompetitorLangs(sportId: $sportId, competitorId: $competitorId) {
      langs
      id
      countryCode
    }
  }
`;

export default getCompetitorLangs;
