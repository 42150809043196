import React from 'react';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import ExpandIcon from '../../icons/controls/ExpandIcon';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandCell: {
    width: 1,
    height: 38,
    paddingLeft: 0,
    paddingRight: 0,
  },
  expandCellLoading: {
    background: theme.palette.background.paper,
  },
  expandIcon: {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },

  expandCellBackground: {
    height: '100%',
    width: '100%',
    paddingLeft: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRightWidth: 0,
  },
}));

const ExpandCell = ({
  isOpen,
  onClick,
  loading,
  className,
  cellStyles,
  classes: cellClasses,
}) => {
  const classes = useStyles();

  return (
    <TableCell
      classes={cellClasses}
      className={clsx(
        classes.expandCell,
        {
          [classes.expandCellLoading]: loading,
        },
        className
      )}
    >
      <div
        className={classes.expandCellBackground}
        style={{
          ...cellStyles,
          // boxShadow: `12px 0px 6px -6px ${toRgba(cellStyles.backgroundColor, 0.9)}`,
        }}
      >
        <IconButton
          size={'small'}
          color={'primary'}
          className={clsx(classes.expand, {
            [classes.expandOpen]: isOpen,
          })}
          onClick={onClick}
          aria-expanded={isOpen}
          aria-label="Details"
        >
          <ExpandIcon className={classes.expandIcon} />
        </IconButton>
      </div>
    </TableCell>
  );
};

export default ExpandCell;
