import React from 'react';
import TableActionCard from './TableActionCard';
import ExportButton from '../Table/ExportButton';

const TableExportActionCard = ({ style }) => {
  return (
    <TableActionCard style={style}>
      <ExportButton />
    </TableActionCard>
  );
};

export default TableExportActionCard;
