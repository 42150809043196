import Autocomplete from './Autocomplete';
import useAutocompleteData from '../../hooks/useAutocompleteData';
import { forwardRef, useMemo } from 'react';

// TODO replace with BrandSelect
const BrandAutocomplete = forwardRef(
  (
    {
      operatorId,
      value: brandId,
      onChange,
      helperText,
      error,
      disabled,
      required,
    },
    ref
  ) => {
    const params = useMemo(() => {
      return {
        query: operatorId,
      };
    }, [operatorId]);

    const {
      data: { items: options = [] },
    } = useAutocompleteData({ url: `/api/v1/Autocomplete/brands`, params });

    const getOptionSelected = (option, value) => {
      return option.id === value;
    };

    const value =
      options.find(option => getOptionSelected(option, brandId)) || null;

    return (
      <Autocomplete
        ref={ref}
        disabled={disabled}
        required={required}
        error={error}
        options={options}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={option => {
          return option.name;
        }}
        InputProps={{
          label: 'Brand ID',
          helperText,
          error,
        }}
        value={value}
        onChange={(e, value) => {
          onChange(value ? value.id : null);
        }}
      />
    );
  }
);

export default BrandAutocomplete;
