import React from 'react';
import PropTypes from 'prop-types';

const REQUIRED_FIELD_SYMBOL = '*';

function TitleField(props) {
  const { id, title, required } = props;
  const legend = required ? title + REQUIRED_FIELD_SYMBOL : title;
  return <legend id={id}>{legend}</legend>;
}

TitleField.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
};

export default TitleField;
