import React, { useCallback, useContext, useState } from 'react';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { editData } from '../../actions/dataActions';
import { UiOptionsContext } from './UiOptionsProvider';
import { DataContext } from '../../providers/DataProvider';
import { getEditPath } from '../../utils/schemaHelper';
import { useParams } from 'react-router-dom';
import { LinksContext } from '../../providers/LinksProvider';
import { getByIndexes } from '../Table/TableUtils';
import { NestedIndexesContext } from '../../providers/NestedIndexProvider';
import { showNotification } from '../../actions/uiActions';
import { RESPONSE_TYPE_ENUM } from '../../utils/getResponseTypeByCode';
import get from 'lodash/get';
import { useApiHost } from '../../providers/ApiHostProvider';

const BooleanInputField = () => {
  const dispatch = useDispatch();

  const { apiHost } = useApiHost();
  const { value } = useContext(UiOptionsContext);
  const indexes = useContext(NestedIndexesContext);
  const [isLoading, setIsLoading] = useState(false);

  const { editLink, key } = value;

  const {
    method,
    url,
    operationId,
    query: requestQuery,
    data,
  } = useContext(DataContext);

  const requestPath = useParams();
  const processEdit = useCallback(
    (editPath, body, responseBody) =>
      dispatch(
        editData({
          apiHost,
          path: method + url,
          editPath,
          body,
          operationId,
          responseBody,
        })
      ),
    [dispatch, apiHost, method, url, operationId]
  );

  const showNotificationCb = useCallback(
    (...args) => {
      dispatch(showNotification(...args));
    },
    [dispatch]
  );

  const links = useContext(LinksContext);

  return (
    <Switch
      checked={value.data}
      disabled={isLoading}
      onChange={async e => {
        setIsLoading(true);

        const link = links[editLink];
        const entity = getByIndexes(data, indexes);

        if (!link) {
          return;
        }

        const editPath = getEditPath({
          editLink: link,
          entity,
          query: requestQuery,
          requestPath,
        });

        const checked = e.target.checked;

        const result = await processEdit(
          editPath,
          {
            [key]: checked,
          },
          entity
        );

        const errorMessage =
          result.error && get(result, 'payload.response.message');

        if (result.error && errorMessage) {
          showNotificationCb(RESPONSE_TYPE_ENUM.ERROR, errorMessage);
        }

        setIsLoading(false);
      }}
      value="toggleValue"
    />
  );
};

export default BooleanInputField;
