import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserToken } from '../../../reducers/users';
import { getActiveSchemaUrl } from '../../../reducers/schemaUrls';

const TableuDashboardPage = () => {
  const [token, setToken] = useState(null);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const authToken = useSelector(getUserToken);
  const apiURL = useSelector(getActiveSchemaUrl);

  useEffect(() => {
    const url = new URLSearchParams(window.location.search).get('url');

    const getToken = () => {
      fetch(apiURL.replace('schema.json', 'TableauAdmin/tableau/token'), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setToken(data.token);
        })
        .catch(error => console.log(error));
    };

    const handleError = e => {
      if (e.detail._errorCode == 'auth-failed') {
        const parsed = JSON.parse(e.detail._message);
        const message = JSON.parse(parsed.errorMessage);
        const JWTExpiredError = message.result.errors.find(
          e => e.code === 10083
        );
        setError({ name: e.detail._errorCode, errors: message.result.errors });

        if (JWTExpiredError) {
          getToken();
        }
      }
    };

    if (url) {
      setUrl(url);
      getToken();
    }

    const viz = document.getElementById('tableau-viz');
    viz.addEventListener('vizloaderror', handleError);

    return () => {
      viz.removeEventListener('vizloaderror', handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{ padding: '25px' }}>
      {error && (
        <Typography variant={'h5'}>
          Error on load dashboard: <b>{error.name}</b> (
          {error.errors.map(e => `[code: ${e.code}]`).join(' | ')})
        </Typography>
      )}
      <div style={{ width: '100%', height: '100%' }}>
        <tableau-viz
          height="100vh"
          width="100%"
          id="tableau-viz"
          src={'https://tableau.devspt.com/#/views/' + url}
          token={token}
          toolbar="bottom"
        ></tableau-viz>
      </div>
    </Stack>
  );
};

export default TableuDashboardPage;
