import { memo } from 'react';
import ExpandButton from '../Button/ExpandButton';

export const expandCellStyles = {
  padding: '0 4px',
  minWidth: 34,
};

const TableExpandCell = ({ expanded, onExpand }) => {
  return <ExpandButton expanded={expanded} onClick={onExpand} />;
};

export default memo(TableExpandCell);
