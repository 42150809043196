import Stack from '@mui/material/Stack/Stack';
import Button from '../../../components/Button/Button';

import React, { forwardRef, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import mergeRefs from '../../../../utils/mergeRefs';
import RefreshIcon from '../../../icons/RefreshIcon';

export const IFrame = forwardRef(({ children, ...props }, ref) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={mergeRefs(ref, setContentRef)}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
});

const CodeBannerPreview = ({ code, width, height }) => {
  const [reload, setReload] = useState(false);

  const onReload = useCallback(() => {
    setReload(true);

    setTimeout(() => {
      setReload(false);
    }, 1);
  }, []);

  return (
    <Stack direction={'column'} alignItems={'center'} spacing={2}>
      <IFrame
        frameBorder="0"
        width={width}
        height={height}
        style={{
          backgroundColor: '#F7F7F7',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: reload ? null : code }} />
      </IFrame>
      <Button color={'lightBlue'} onClick={onReload} disabled={!code}>
        <RefreshIcon />
        Refresh
      </Button>
    </Stack>
  );
};

export default CodeBannerPreview;
