import { useEffect, useMemo, useState } from 'react';
import Select from '../../../components/Inputs/Select/Select';
import { TEST_PLAYER } from '../BetHistory';

const TestPlayersFilterBetHistory = ({
  filterPlayerId,
  filterExtPlayerId,
  setFilterValue,
  toggled,
  ...props
}) => {
  const [stashedValue, setStashedValue] = useState(null);

  const value = useMemo(() => {
    if (
      (filterPlayerId?.length > 0 || filterExtPlayerId?.length > 0) &&
      props.value !== TEST_PLAYER.INCLUDE
    ) {
      setFilterValue(TEST_PLAYER.INCLUDE);
      setStashedValue(props.value);

      return TEST_PLAYER.INCLUDE;
    }

    if (
      stashedValue &&
      filterPlayerId?.length === 0 &&
      filterExtPlayerId?.length === 0
    ) {
      setStashedValue(null);
      setFilterValue(stashedValue);

      return stashedValue;
    }

    return props.value;
  }, [
    filterPlayerId,
    filterExtPlayerId,
    setFilterValue,
    props.value,
    stashedValue,
  ]);

  return (
    <Select
      options={[
        {
          label: 'Exclude',
          value: TEST_PLAYER.EXCLUDE,
        },
        {
          label: 'Include',
          value: TEST_PLAYER.INCLUDE,
        },
        {
          label: 'Lookup',
          value: TEST_PLAYER.LOOKUP,
        },
      ]}
      {...props}
      value={value}
      disabled={filterPlayerId?.length > 0 || filterExtPlayerId?.length > 0}
    />
  );
};

export default TestPlayersFilterBetHistory;
