import { useCallback, useEffect, useRef } from 'react';

const useHorizontalScroll = () => {
  const containerRef = useRef();

  const handleScroll = useCallback(event => {
    const isTouchPad = event.wheelDeltaY
      ? event.wheelDeltaY === -3 * event.deltaY
      : event.deltaMode === 0;

    if (isTouchPad) {
      return;
    }

    event.preventDefault();

    let [x, y] = [event.deltaX, event.deltaY];
    let magnitude;

    if (x === 0) {
      magnitude = y > 0 ? -30 : 30;
    } else {
      magnitude = x;
    }

    containerRef.current.scrollBy({
      left: magnitude,
    });
  }, []);

  useEffect(() => {
    const element = containerRef?.current;
    if (element) {
      element.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', handleScroll);
      }
    };
  }, [containerRef, handleScroll]);

  return containerRef;
};

export default useHorizontalScroll;
