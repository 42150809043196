import * as React from 'react';
import { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';

export const StickyHeaderContext = React.createContext({});

const useStyles = makeStyles({
  stickyHeaderContainer: {
    position: 'fixed',
    top: 48,
    zIndex: 3,
    padding: '0',
    left: 0,
    right: 0,
    width: '100%',
    overflow: 'hidden',
    display: 'none',
  },

  stickyHeaderBackground: {
    width: '100%',
    position: 'fixed',
    zIndex: 2,
    top: 42,
    left: 0,
    backgroundColor: '#F7F7F7',
    boxShadow: '0px 2px 4px rgb(132 150 171 / 40%)',
    height: 44,
    visibility: 'hidden',
  },
});

const StickyHeaderProvider = ({ children }) => {
  const classes = useStyles();
  const container = React.useRef(null);
  const containerBackground = React.useRef(null);

  return (
    <StickyHeaderContext.Provider
      value={{
        container,
        containerBackground,
      }}
    >
      <div ref={container} className={classes.stickyHeaderContainer} />
      <div
        ref={containerBackground}
        className={classes.stickyHeaderBackground}
      />
      {children}
    </StickyHeaderContext.Provider>
  );
};

export const useStickyHeaderContainer = () => {
  return useContext(StickyHeaderContext);
};

export default StickyHeaderProvider;
