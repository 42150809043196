import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppPageTitle } from '../../../providers/AppPageTitleProvider';
import FilterSidebar from '../FilterSidebar/FilterSidebar';

const useStyles = makeStyles(theme => ({
  page: {
    width: '100%',
  },
  content: {
    width: '100%',
    padding: 16,
  },
  [theme.breakpoints.up('md')]: {
    content: {
      padding: 16,
    },
  },
}));

const PageProviderContext = createContext({ currentPage: {} });

export const usePageProvider = () => {
  return useContext(PageProviderContext);
};

const Page = ({ title, children, filters, extraClass }) => {
  const classes = useStyles();

  useAppPageTitle(title);

  const location = useLocation();

  const url = location.pathname + location.search;

  return (
    <PageProviderContext.Provider
      value={{
        currentPage: {
          title,
          url,
        },
      }}
    >
      <Stack className={clsx(classes.page, extraClass)} direction={'row'}>
        <Box className={classes.content}>{children}</Box>
        {filters && !filters.loading && <FilterSidebar content={filters} />}
      </Stack>
    </PageProviderContext.Provider>
  );
};

export default Page;
