import useAutocompleteData from '../../../hooks/useAutocompleteData';
import Select from '../Select/Select';
import { forwardRef } from 'react';

const RMSettingsSelect = forwardRef(({ multiple, ...props }, ref) => {
  const { data: options = [] } = useAutocompleteData({
    url: '/api/v1/RiskManagementAdmin/rm_settings',
    withoutParams: true,
  });

  return (
    <Select
      ref={ref}
      label={'RM Settings'}
      multiple={multiple}
      options={options}
      labelKey={'name'}
      valueKey={'id'}
      {...props}
    />
  );
});

export default RMSettingsSelect;
