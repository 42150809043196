import RelatedContingencyAutocomplete from './RelatedContingencyAutocomplete';
import { useMemo } from 'react';
import GetCategoriesNames from '../../../gql/RiskManagement/queries/getCategoriesNames';

const CategoryAutocomplete = ({ value, onChange, sports, ...props }) => {
  const params = useMemo(
    () => ({
      sport_ids: sports,
    }),
    [sports]
  );

  return (
    <RelatedContingencyAutocomplete
      allLabel={'All Categories'}
      label={'Select Category'}
      gqlQuery={GetCategoriesNames}
      params={params}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default CategoryAutocomplete;
