import { cloneElement, forwardRef, useCallback, useRef } from 'react';
import { InfiniteLoader } from 'react-virtualized';

const InfiniteTable = forwardRef(
  ({ loadRows, isRowLoaded, rowCount = 500, children, ...props }, ref) => {
    const onRowsRenderedRef = useRef(null);

    const loadMoreRows = useCallback(
      ({ startIndex, stopIndex }) => {
        loadRows(startIndex, stopIndex - startIndex);
      },
      [loadRows]
    );

    const onSectionRendered = useCallback(
      ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }) => {
        const startIndex = rowStartIndex + columnStartIndex;
        const stopIndex = rowStopIndex + columnStopIndex;

        onRowsRenderedRef.current({
          startIndex,
          stopIndex,
        });
      },
      []
    );

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
        minimumBatchSize={50}
      >
        {({ onRowsRendered, registerChild }) => {
          onRowsRenderedRef.current = onRowsRendered;

          return cloneElement(children, {
            ref,
            ...props,
            onSectionRendered,
            registerChild,
          });
        }}
      </InfiniteLoader>
    );
  },
  []
);

export default InfiniteTable;
