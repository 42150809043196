import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';

export const AppPageTitleContext = React.createContext(null);

const AppPageTitleProvider = ({ children }) => {
  const [value, setValue] = useState('Betby backoffice');

  const setTitle = useCallback(title => {
    setValue(title);
  }, []);

  useDocumentTitle(value);

  const providerValue = useMemo(() => {
    return { title: value, setTitle };
  }, [value, setTitle]);

  return (
    <AppPageTitleContext.Provider value={providerValue}>
      {children}
    </AppPageTitleContext.Provider>
  );
};

export const useAppPageTitle = title => {
  const providerValue = useContext(AppPageTitleContext);
  const { setTitle } = providerValue;

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title, setTitle]);

  return providerValue;
};

export default AppPageTitleProvider;
