import { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import CloseIcon from '../../../icons/controls/CloseIcon';

const useStyles = makeStyles({
  chip: {
    position: 'relative',
    backgroundColor: '#E8F2FF',
    borderRadius: 4,
    boxSizing: 'border-box',
    fontSize: '11px',
    letterSpacing: '0.2px',
    lineHeight: '16px',
    color: '#1581FF',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    height: 32,
    padding: 8,
  },

  chipWithDelete: {
    paddingRight: 32,
  },

  chipSm: {
    padding: 4,
    height: 22,
    lineHeight: '13px',
  },

  deleteIcon: {
    fontSize: '16px',
    position: 'absolute',
    right: 8,
    top: 8,
    cursor: 'pointer',
  },
});

const Chip = forwardRef(
  ({ sm = false, onDelete, children, className, ...props }, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={clsx(
          classes.chip,
          {
            [classes.chipSm]: sm,
            [classes.chipWithDelete]: onDelete,
          },
          className
        )}
        {...props}
      >
        {children}
        {onDelete && (
          <CloseIcon className={classes.deleteIcon} onClick={onDelete} />
        )}
      </div>
    );
  }
);

export default Chip;
