import { SCHEMA_URLS } from '../constants/schemaUrls';
import { ADD_SCHEMA_URL } from '../constants/appConstants';
import union from 'lodash/union';
import { handleAction } from 'redux-actions';

const schemaUrls = handleAction(
  ADD_SCHEMA_URL,
  (state, { meta: { schemaUrl } }) => union([schemaUrl], state),
  SCHEMA_URLS
);

export default schemaUrls;

export const getSchemaUrls = state => state.persistable.schemaUrls;

export const getActiveSchemaUrl = state => {
  const sessionSchemaUrl = JSON.parse(
    sessionStorage.getItem('sessionSchemaUrl')
  );
  const savedSchemaUrls = getSchemaUrls(state);

  if (savedSchemaUrls.includes(sessionSchemaUrl)) {
    return sessionSchemaUrl;
  } else {
    return savedSchemaUrls[0];
  }
};
