import { useDynamicSelectData } from '../../../providers/SelectDataProvider';
import Select from '../../../v2/components/Inputs/Select/Select';
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getHelperText } from '../../../uiComponents/Filters/FilterField';
import usePrevious from '../../../hooks/usePrevious';

const DynamicSelect = ({
  operationId,
  params,
  requestBody,
  SelectProps: {
    id,
    disabled,
    value,
    onResetValue,
    onChange,
    multiple,
    error,
    helperText,
    valueKey,
    ...SelectProps
  },
}) => {
  const {
    items,
    loading,
    error: dataError,
    isEmptyQuery,
  } = useDynamicSelectData({
    id,
    params,
    operationId,
    requestBody,
  });

  const isLoaded = !loading && !!items;

  const prevItems = usePrevious(items);

  const isEmptyValue = isEmpty(value);

  useEffect(() => {
    if (isEmptyQuery && !isEmptyValue) {
      if (onResetValue) {
        onResetValue();
      } else {
        isLoaded && onChange(multiple ? [] : '');
      }
    } else if (
      isLoaded &&
      !isEmptyValue &&
      items &&
      items !== prevItems &&
      (!onResetValue || prevItems)
    ) {
      const v = multiple
        ? value.filter(
            val => items && items.find(item => item[valueKey] === val)
          )
        : items.find(item => item[valueKey] === value)
        ? value
        : '';

      if (onResetValue) {
        onResetValue(v);
      } else {
        onChange(v);
      }
    }
  }, [
    isEmptyQuery,
    isEmptyValue,
    isLoaded,
    items,
    multiple,
    onChange,
    onResetValue,
    value,
    valueKey,
    prevItems,
  ]);

  if (
    id === 'root_combo_of_the_day_locales_0_combo_list_2_set_of_combo_1_outcome'
  ) {
    console.log('VALUE:', id, value);
  }

  error = !!(error || dataError);
  helperText = helperText || (dataError && getHelperText(dataError));

  return (
    <Select
      id={id}
      options={items}
      isLoading={loading}
      disabled={disabled || loading || isEmptyQuery}
      value={value}
      onChange={onChange}
      multiple={multiple}
      error={error}
      helperText={helperText}
      valueKey={valueKey}
      {...SelectProps}
    />
  );
};

export default DynamicSelect;
