import React, { useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import passwordIcon from '../../../assets/password.svg';
import eye from '../../../assets/eye.svg';
import eyeOff from '../../../assets/eye-off.svg';
import check from '../../../assets/check.svg';
import cross from '../../../assets/cross.svg';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { passRequirements } from '../../../constants';
import { ERROR_CODES } from '../../../utils';
import errorIcon from '../../../assets/error.svg';

const SecondStep = ({ onSubmit, onBack }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordRepeat: '',
    },
    validationSchema: object({
      password: string()
        .min(8, passRequirements.charLength)
        .matches(/[A-Z]/, passRequirements.uppercase)
        .matches(/[a-z]/, passRequirements.lowercase)
        .matches(/[0-9]/, passRequirements.number)
        .matches(/[!@#$%^&*(),.?":{}|<>]/, passRequirements.specialChar)
        .required(passRequirements.required),
      passwordRepeat: string()
        .test('password_same', passRequirements.bothMatch, function (value) {
          const { password } = this.parent;

          return password === value;
        })
        .required(passRequirements.repeat),
    }),
    onSubmit: values => {
      setError(null);

      onSubmit(values, errors => {
        const codes = errors ? errors.map(e => e.code) : [];
        if (codes.includes(ERROR_CODES.THE_SAME_PASSWORD)) {
          setError('You can not use the same password');
        }
      });
    },
  });

  const getValidationErrors = name => {
    const errors = [];

    if (name === 'passwordRepeat') {
      if (
        formik.values.password !== formik.values.passwordRepeat ||
        formik.values.passwordRepeat === ''
      ) {
        errors.push(passRequirements.bothMatch);
      }
    }

    if (formik.values[name].length < 8) {
      errors.push(passRequirements.charLength);
    }

    if (!/[A-Z]/.test(formik.values[name])) {
      errors.push(passRequirements.uppercase);
    }

    if (!/[a-z]/.test(formik.values[name])) {
      errors.push(passRequirements.lowercase);
    }

    if (!/[0-9]/.test(formik.values[name])) {
      errors.push(passRequirements.number);
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(formik.values[name])) {
      errors.push(passRequirements.specialChar);
    }

    return errors;
  };

  const handleToggleVisibility = (repeat = false) => {
    if (repeat) {
      setShowPasswordRepeat(prevState => !prevState);
    } else {
      setShowPassword(prevState => !prevState);
    }
  };

  const renderConstraint = (name, content) => {
    const errors = getValidationErrors(name);
    const isValid = !errors.includes(content);

    return (
      <Stack direction={'row'} alignItems={'center'}>
        {isValid ? (
          <img src={check} style={{ padding: '9px 8px ' }} />
        ) : (
          <img src={cross} style={{ padding: '9px' }} />
        )}
        <Typography
          color={isValid ? '#008545' : '#8496AB'}
          fontSize={'12px'}
          letterSpacing={'-.2px'}
          lineHeight={'16px'}
        >
          {content}
        </Typography>
      </Stack>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Stack gap={'16px'}>
        <Stack gap={'4px'}>
          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            name={'password'}
            error={formik.touched.password && Boolean(formik.errors.password)}
            label={'New password'}
            type={showPassword ? 'text' : 'password'}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '14px',
              },
              '& .MuiInputAdornment-root': {
                width: '30px',
                '&.MuiInputAdornment-positionStart': {
                  color: '#1481ff',
                },
                '&.MuiInputAdornment-positionEnd': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px',
                  },
                },
              },
            }}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <img src={passwordIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleToggleVisibility()}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <img src={showPassword ? eye : eyeOff} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !!formik.values.password,
              style: {
                overflow: 'hidden',
                marginLeft: formik.values.password ? 0 : 30,
              },
            }}
          />
          <Stack gap={'4px'}>
            {renderConstraint('password', passRequirements.charLength)}
            {renderConstraint('password', passRequirements.uppercase)}
            {renderConstraint('password', passRequirements.lowercase)}
            {renderConstraint('password', passRequirements.number)}
            {renderConstraint('password', passRequirements.specialChar)}
          </Stack>
        </Stack>
        <Stack gap={'4px'}>
          <TextField
            value={formik.values.passwordRepeat}
            onChange={formik.handleChange}
            name={'passwordRepeat'}
            error={
              formik.touched.passwordRepeat &&
              Boolean(formik.errors.passwordRepeat)
            }
            type={showPasswordRepeat ? 'text' : 'password'}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '14px',
              },
              '& .MuiInputAdornment-root': {
                width: '30px',
                '&.MuiInputAdornment-positionStart': {
                  color: '#1481ff',
                },
                '&.MuiInputAdornment-positionEnd': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px',
                  },
                },
              },
            }}
            label={'Repeat new password'}
            InputProps={{
              type: showPasswordRepeat ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <img src={passwordIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleToggleVisibility(true)}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <img src={showPasswordRepeat ? eye : eyeOff} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !!formik.values.passwordRepeat,
              style: {
                overflow: 'hidden',
                marginLeft: formik.values.passwordRepeat ? 0 : 30,
              },
            }}
          />
          {renderConstraint('passwordRepeat', passRequirements.bothMatch)}
        </Stack>
        {error && (
          <Stack
            direction={'row'}
            gap={'8px'}
            padding={'12px'}
            sx={{
              backgroundColor: '#FFE6E8',
              borderRadius: '8px',
            }}
            alignItems={'flex-start'}
          >
            <img src={errorIcon} />
            <Typography
              fontSize={'14px'}
              color={'#820008'}
              lineHeight={'20px'}
              letterSpacing={'0.2px'}
            >
              {error}
            </Typography>
          </Stack>
        )}
        <Stack direction={'row'} gap={'8px'} justifyContent={'stretch'}>
          <Button
            variant={'contained'}
            sx={{
              backgroundColor: '#E8F2FF',
              color: '#1581FF',
              width: '100%',
            }}
            onClick={onBack}
          >
            Back
          </Button>
          <LoadingButton
            type={'submit'}
            variant={'contained'}
            sx={{
              width: '100%',
            }}
            disabled={
              getValidationErrors('password').length ||
              getValidationErrors('passwordRepeat').length
            }
          >
            Continue
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default SecondStep;
