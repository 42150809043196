import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  tableInlinePopup: {
    bottom: 24,
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'fixed',
    zIndex: 1100,
    padding: '8px 24px',
    borderRadius: 8,
    background: '#fff',
    boxShadow: '0px 2px 16px 0px rgba(132, 150, 171, 0.25)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableInlinePopupText: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    fontWeight: 600,
  },
});

const TableInlinePopup = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.tableInlinePopup}>{children}</div>;
};

export default TableInlinePopup;
