import { isArray } from 'lodash';

export const getConfigByLineType = ({ templateConfigs, key, lineType }) => {
  return isArray(templateConfigs[key])
    ? templateConfigs[key].map(value => ({
        ...value,
        lineType,
        version: 1,
      }))
    : {
        ...templateConfigs[key],
        lineType,
        version: 1,
      };
};
