import mapValuesDeep from './mapValuesDeep';
import getSchemaByPath from './getSchemaByPath';

const convertToSchemaTypes = (data, schema) => {
  return schema
    ? mapValuesDeep(data, (value, pathArr) => {
        const subSchema = getSchemaByPath(schema, pathArr);

        return subSchema &&
          subSchema.type === 'number' &&
          typeof value === 'string'
          ? +value
          : value;
      })
    : data;
};

export default convertToSchemaTypes;
