import React from 'react';

import Library from './Library';
import store from './store';

// Import all files from components folder and subfolder with name __storie.js
const req = require.context('../../components', true, /__stories\.js/);
req.keys().forEach(req);

const storybook = () => (
  <Library chapters={store.getChapters()} getStory={store.getStory} />
);

export default storybook;
