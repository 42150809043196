import { connect } from 'react-redux';
import { logout } from '../actions/authActions';
import UserMenu from '../components/UserMenu';
import { getUser } from '../reducers/users';

const mapStateToProps = state => ({
  user: getUser(state),
});

const UserMenuContainer = connect(mapStateToProps, {
  logout,
})(UserMenu);

export default UserMenuContainer;
