import FancySelect from '../FancySelect/FancySelect';
import useAutocompleteData from '../../../hooks/useAutocompleteData';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';
import { forwardRef } from 'react';
import FancyAutocompleteAllOption from '../FancyAutocomplete/FancyAutocompleteAllOption';
import useFancyAutocompleteAllToggle from '../../../../hooks/useFancyAutocompleteAllToggle';

const filterOptions = createFilterOptions({
  stringify: option => {
    return option.id + option.name;
  },
});

const OperatorFilterSelect = forwardRef(
  ({ multiple, value, ...props }, ref) => {
    const { data: options = [] } = useAutocompleteData({
      url: '/api/v1/Autocomplete/operators',
      withoutParams: true,
    });

    const [toggledAll, onToggleAll] = useFancyAutocompleteAllToggle({
      onChange: props.onChange,
      options,
      value,
      valueKey: 'id',
    });

    return (
      <FancySelectValueProxy
        multiple={multiple}
        value={multiple ? value || [] : value}
        options={options}
        onChange={props.onChange}
        valueKey={'id'}
      >
        <FancySelect
          getInputPlaceholder={defaultPlaceholder =>
            toggledAll ? 'All' : defaultPlaceholder
          }
          ref={ref}
          label={'Operator'}
          multiple={multiple}
          options={options}
          labelKey={'name'}
          filterOptions={filterOptions}
          value={multiple ? value || [] : value}
          valueKey={'id'}
          {...props}
          extraOptions={
            <FancyAutocompleteAllOption
              label={'All Operators'}
              onToggle={onToggleAll}
              toggled={toggledAll}
            />
          }
        />
      </FancySelectValueProxy>
    );
  }
);

export default OperatorFilterSelect;
