import React, { Fragment, useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../Button/Button';
import { useExportCSV } from '../../providers/ExportCSVProvider';
import DownloadIcon from '../../../icons/controls/DownloadIcon';
import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles({
  exportButtonRoot: {
    padding: 0,
    minWidth: 'initial',
    width: '100%',
    justifyContent: 'flex-start',
    height: 22,
  },
  exportIcon: {
    fontSize: '16px',
  },

  withTooltip: {
    pointerEvents: 'none',
    width: 'auto',
  },

  exportButtonLabel: {
    color: '#1581FF',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.5px',
    marginLeft: '8px',
  },
});

const ExportButton = () => {
  const classes = useStyles();

  const { exportCSV, isExporting, isFiltersEmpty } = useExportCSV();

  const ButtonContainer = ({ children }) => {
    return isFiltersEmpty ? (
      <Tooltip title={'Apply filters to export CSV'} overflowOnly={false}>
        <span>{children}</span>
      </Tooltip>
    ) : (
      <Fragment>{children}</Fragment>
    );
  };

  return (
    <ButtonContainer>
      <Button
        variant={'text'}
        disableRipple={true}
        classes={{
          root: clsx(classes.exportButtonRoot, {
            [classes.withTooltip]: isFiltersEmpty,
          }),
        }}
        disabled={isFiltersEmpty || isExporting}
        onClick={exportCSV}
      >
        {isExporting ? (
          <>
            <CircularProgress size={16} />
            &nbsp;Exporting...
          </>
        ) : (
          <>
            <DownloadIcon
              className={classes.exportIcon}
              disabled={isFiltersEmpty}
            />
            Export CSV
          </>
        )}
      </Button>
    </ButtonContainer>
  );
};

export default ExportButton;
