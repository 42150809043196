import { gql } from '@apollo/client';
import ADMIN_BETSTREAM_FIELDS from '../fragments/ADMIN_BETSTREAM_FIELDS';
import OPERATOR_BETSTREAM_FIELDS from '../fragments/OPERATOR_BETSTREAM_FIELDS';

const ADMIN_BETSTREAM_SUBSCRIPTION = gql`
  ${OPERATOR_BETSTREAM_FIELDS}
  ${ADMIN_BETSTREAM_FIELDS}
  subscription ($input: BetStreamModelFilters!) {
    BetStreamModel(input: $input) {
      ...OperatorBetstreamFields
      ...AdminBetstreamFields
    }
  }
`;

export default ADMIN_BETSTREAM_SUBSCRIPTION;
