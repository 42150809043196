/* eslint-disable no-undef */
import classnames from 'clsx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Form from '../../../components/DynamicForm/index';

import { samples } from './samples/index';

import withStyles from '@mui/styles/withStyles';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import * as form from '../../../actions/form';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 75,
  },
  flexGrow: {
    flex: '1 1 auto',
  },
  paper: {
    padding: 16,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  caption: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  expand: {
    float: 'right',
    height: 'auto',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  collaps: {
    width: '100%',
  },
  divider: {
    marginTop: '14px',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const formName = '/dev/form/simple/';

class DevForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formName: props.match.params.formId,
      samples: samples,
      selectSchema: { example: props.match.params.formId },
      type: props.match.params.type,
      expanded: {
        python: false,
        schema: false,
        uiSchema: false,
        formData: false,
      },
    };
  }

  componentDidMount() {
    const { path, params } = this.props.match;

    if (!Object.keys(this.state.samples).includes(params.formId)) {
      const defaultForm = Object.keys(this.state.samples)[0];
      this.props.history.push(
        path.replace(':formId', defaultForm).replace(':type', params.type)
      );
      this.setState({
        formName: defaultForm,
        selectSchema: { example: defaultForm },
      });
      this.props.dispatch(form.formSetState(defaultForm, samples[defaultForm]));
      return;
    }

    if (this.props.match.params.type === 'server') {
      this.setState({
        formName: formName,
        selectSchema: { example: formName },
      });
      this.props.dispatch(form.initForm(this.props.apiUrl, formName));
    } else {
      this.props.dispatch(
        form.formSetState(this.state.formName, samples[this.state.formName])
      );
    }
  }

  handleExpandClick = section => {
    this.setState({
      ...this.state,
      expanded: { [section]: !this.state.expanded[section] },
    });
  };

  handleChangeCode = (key, code) => {
    this.props.dispatch(
      form.formSetState(this.state.formName, {
        [key]: JSON.parse(code),
      })
    );
  };

  handleFormChange = args => {
    const { formData } = args;
    this.props.dispatch(
      form.formSetState(this.state.formName, {
        formData: formData,
      })
    );
  };

  handleSelectChange = ({ formData }) => {
    const { path, params } = this.props.match;
    if (Object.keys(this.state.samples).includes(formData.example)) {
      this.setState({
        formName: formData.example,
        selectSchema: { example: formData.example },
      });
      this.props.dispatch(
        form.formSetState(formData.example, samples[formData.example])
      );
      this.props.history.push(
        path.replace(':formId', formData.example).replace(':type', params.type)
      );
    }
  };

  render() {
    const { classes } = this.props;

    const form = this.props.form[this.state.formName];
    const selectSchema = {
      schema: {
        type: 'object',
        required: ['example'],
        properties: {
          example: {
            type: 'string',
            enum: Object.keys(this.state.samples),
            default: 'Simple',
            title: 'Chose example from list',
          },
        },
      },

      uiSchema: {
        // example: {
        //   "ui:help": "Chose example from list",
        // },
      },
      formData: {
        example: 'Arrays',
      },
    };

    if (!form) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={1} justifyContent={'center'}>
          <Grid item xs={12} md={6} sm={12}>
            <Paper className={classes.paper}>
              <Form
                schema={selectSchema.schema}
                uiSchema={selectSchema.uiSchema}
                formData={this.state.selectSchema}
                onChange={this.handleSelectChange}
              >
                <span />
              </Form>
            </Paper>
            <Paper className={classes.paper}>
              <Typography
                className={classnames(classes.caption)}
                onClick={this.handleExpandClick.bind(this, 'python')}
              >
                Python Model
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded['python'],
                  })}
                  size="large"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Typography>
              <Collapse
                className={classes.collaps}
                in={this.state.expanded['python']}
                timeout={500}
                unmountOnExit
              >
                <Divider className={classes.divider} />
              </Collapse>
            </Paper>
            <Paper className={classes.paper}>
              <Typography
                className={classnames(classes.caption)}
                onClick={this.handleExpandClick.bind(this, 'schema')}
              >
                JSONSchema
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded['schema'],
                  })}
                  size="large"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Typography>
              <Collapse
                className={classes.collaps}
                in={this.state.expanded['schema']}
                timeout={500}
                unmountOnExit
              >
                <Divider className={classes.divider} />
              </Collapse>
            </Paper>
            <Paper className={classes.paper}>
              <Typography
                className={classnames(classes.caption)}
                onClick={this.handleExpandClick.bind(this, 'uiSchema')}
              >
                UISchema
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded['uiSchema'],
                  })}
                  size="large"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Typography>
              <Collapse
                className={classes.collaps}
                in={this.state.expanded['uiSchema']}
                timeout={500}
                unmountOnExit
              >
                <Divider className={classes.divider} />
              </Collapse>
            </Paper>
            <Paper className={classes.paper}>
              <Typography
                className={classnames(classes.caption)}
                onClick={this.handleExpandClick.bind(this, 'formData')}
              >
                formData
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded['formData'],
                  })}
                  size="large"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Typography>
              <Collapse
                className={classes.collaps}
                in={this.state.expanded['formData']}
                timeout={500}
                unmountOnExit
              >
                <Divider className={classes.divider} />{' '}
              </Collapse>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card className={classes.card}>
              <Form
                schema={form.schema}
                uiSchema={form.uiSchema}
                formData={form.formData}
                onSubmit={this.handleSubmit}
                onChange={this.handleFormChange}
              >
                <CardActions>
                  <div className={classes.flexGrow} />
                  <Button size="small" color="primary" type={'submit'}>
                    Connect
                  </Button>
                </CardActions>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DevForm.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(state => ({
    apiUrl: state.app && state.app.apiUrl,
    form: state.form.form,
  }))
)(DevForm);
