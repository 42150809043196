import { useCallback, useMemo, useState } from 'react';
import useFiltersActionCard from './useFiltersActionCard';
import useFiltersQuery from './useFiltersQuery';
import { isEmpty } from 'lodash';
import useInitialFiltersList from './useInitialFiltersList';
import useQuery from './useQuery';

const useFilters = ({
  tableName,
  filters: filtersList,
  onSubmit: onSubmitCb = () => {},
  onResubmit: onResubmitCb = () => {},
  resetOffset,
  spanMin,
  spanMax,
  extraFilters,
  defaultValues = {},
  applyRequired = false,
  hideAddButton = false,
}) => {
  const initialFiltersList = useInitialFiltersList({ filtersList });

  const [filters, setFilters] = useState(initialFiltersList);

  const query = useQuery();
  const queryFilters = JSON.parse(query.get('filters'));

  const initialFilters = useMemo(() => {
    return queryFilters && filters
      ? filters.reduce((acc, item) => {
          return {
            ...acc,
            [item.name]: queryFilters[item.name],
          };
        }, {})
      : defaultValues;
    // eslint-disable-next-line
  }, [filters, defaultValues]);

  const [filtersQuery, setFiltersQuery] = useFiltersQuery(
    initialFilters,
    'filters'
  );

  const onSubmit = useCallback(
    (...args) => {
      resetOffset && resetOffset(0);

      setFiltersQuery(...args);
      onSubmitCb(...args);
    },
    [onSubmitCb, setFiltersQuery, resetOffset]
  );

  const onResubmit = useCallback(
    (...args) => {
      resetOffset && resetOffset(0);

      if (isEmpty(filtersQuery)) {
        setFiltersQuery(...args);
      }

      onResubmitCb(...args);
    },
    [onResubmitCb, resetOffset, setFiltersQuery, filtersQuery]
  );

  const filtersCard = useFiltersActionCard({
    filters,
    setFilters,
    defaultValues: initialFilters,
    filtersQuery,
    onSubmit,
    onResubmit,
    spanMin,
    spanMax,
    hidePresets: true,
    extraFilters,
    tableName,
    hideAddButton,
  });

  return useMemo(
    () => ({
      filtersCard,
      filters: filtersQuery,
      setFiltersQuery,
    }),
    [filtersCard, filtersQuery, setFiltersQuery]
  );
};

export default useFilters;
