export const DYNAMIC_REQUEST = 'DYNAMIC_REQUEST';
export const DYNAMIC_SUCCESS = 'DYNAMIC_SUCCESS';
export const DYNAMIC_FAILURE = 'DYNAMIC_FAILURE';

export const DYNAMIC_EDIT_REQUEST = 'DYNAMIC_EDIT_REQUEST';
export const DYNAMIC_EDIT_SUCCESS = 'DYNAMIC_EDIT_SUCCESS';
export const DYNAMIC_EDIT_FAILURE = 'DYNAMIC_EDIT_FAILURE';
export const DYNAMIC_CLEAR_SUCCESS = 'DYNAMIC_CLEAR_SUCCESS';

export const DYNAMIC_UPDATE_SUCCESS = 'DYNAMIC_UPDATE_SUCCESS';

export const DYNAMIC_DATA_STORE_STRATEGY = {
  INSERT: 'insert',
  APPEND: 'append',
  MERGE: 'merge',
  REPLACE: 'replace',
  REMOVE: 'remove',
};
