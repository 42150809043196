import Select from '../Select/Select';
import { forwardRef } from 'react';

export const betTypeSelectOptions = {
  single: { label: 'Single', value: 'single' },
  combo: { label: 'Combo', value: 'combo' },
  system: { label: 'System', value: 'system' },
};

const BetTypeSelect = forwardRef(({ ...props }, ref) => {
  return (
    <Select
      ref={ref}
      options={Object.values(betTypeSelectOptions)}
      multiple
      {...props}
    />
  );
});

export default BetTypeSelect;
