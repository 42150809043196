import React from 'react';
import MuiButton from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
  button: {
    textDecoration: 'none',
    '&>svg': {
      marginRight: theme.spacing(1),
    },
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  loadingButton: {
    pointerEvents: 'auto',
    cursor: 'progress',
  },

  lightRedButton: {
    backgroundColor: theme.palette.red[100],
    color: theme.palette.red[500],

    '&:hover, &:active': {
      backgroundColor: theme.palette.red[200],
      color: theme.palette.red[500],
      boxShadow: 'none',
    },
  },

  lightBlueButton: {
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.blue[500],

    '&:hover, &:active': {
      backgroundColor: theme.palette.blue[200],
      color: theme.palette.blue[500],
      boxShadow: 'none',
    },
  },
}));

export const ButtonColorEnum = {
  RED: 'redButton',
  LIGHT_RED: 'lightRed',
  LIGHT_BLUE: 'lightBlue',
};

const ButtonColorClassEnum = {
  [ButtonColorEnum.RED]: 'redButton',
  [ButtonColorEnum.LIGHT_RED]: 'lightRedButton',
  [ButtonColorEnum.LIGHT_BLUE]: 'lightBlueButton',
};

const Button = ({
  color = 'primary',
  type,
  variant = 'contained',
  children,
  disabled,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const customVariantClass = ButtonColorClassEnum[color];

  return (
    <MuiButton
      type={type}
      {...props}
      variant={variant}
      disabled={disabled}
      disableRipple
      className={classNames(
        {
          [classes.loadingButton]: loading,
        },
        classes.button,
        classes[customVariantClass],
        props.className
      )}
      color={!customVariantClass ? color : undefined}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
