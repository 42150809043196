import { styled } from '@mui/system';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Text = styled('div')`
  margin-top: 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
`;

const BackButton = styled(Button)`
  margin-top: 24px;
`;

const NotFoundIcon = (
  <svg
    width="116"
    height="106"
    viewBox="0 0 116 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 58.2753C0 26.0907 25.9675 0 58 0C90.0325 0 116 26.0907 116 58.2753V102.536C116 104.699 113.376 105.757 111.888 104.194L100.713 92.4583C98.5078 90.1424 94.8255 90.1424 92.6203 92.4583L81.3797 104.263C79.1745 106.579 75.4922 106.579 73.2869 104.263L62.0464 92.4583C59.8412 90.1424 56.1588 90.1424 53.9536 92.4583L42.713 104.263C40.5078 106.579 36.8255 106.579 34.6203 104.263L23.3797 92.4583C21.1745 90.1424 17.4922 90.1424 15.287 92.4583L4.11207 104.194C2.62419 105.757 0 104.699 0 102.536V58.2753Z"
      fill="#E1E6EC"
    />
    <path
      d="M23.759 40.6153C23.759 38.041 23.759 36.7538 23.9278 35.6766C24.8695 29.668 29.5828 24.9547 35.5913 24.0131C36.6686 23.8442 37.9557 23.8442 40.5301 23.8442C43.1044 23.8442 44.3915 23.8442 45.4688 24.0131C51.4774 24.9547 56.1906 29.668 57.1323 35.6766C57.3011 36.7538 57.3011 38.041 57.3011 40.6153V44.9433C57.3011 47.5176 57.3011 48.8048 57.1323 49.882C56.1906 55.8906 51.4774 60.6039 45.4688 61.5455C44.3915 61.7144 43.1044 61.7144 40.5301 61.7144C37.9557 61.7144 36.6686 61.7144 35.5913 61.5455C29.5828 60.6039 24.8695 55.8906 23.9278 49.882C23.759 48.8048 23.759 47.5176 23.759 44.9433V40.6153Z"
      fill="white"
    />
    <path
      d="M71.2771 40.6153C71.2771 38.041 71.2771 36.7538 71.4459 35.6766C72.3876 29.668 77.1009 24.9547 83.1094 24.0131C84.1867 23.8442 85.4739 23.8442 88.0482 23.8442C90.6225 23.8442 91.9097 23.8442 92.9869 24.0131C98.9955 24.9547 103.709 29.668 104.65 35.6766C104.819 36.7538 104.819 38.041 104.819 40.6153V44.9433C104.819 47.5176 104.819 48.8048 104.65 49.882C103.709 55.8906 98.9955 60.6039 92.9869 61.5455C91.9097 61.7144 90.6225 61.7144 88.0482 61.7144C85.4739 61.7144 84.1867 61.7144 83.1094 61.5455C77.1009 60.6039 72.3876 55.8906 71.4459 49.882C71.2771 48.8048 71.2771 47.5176 71.2771 44.9433V40.6153Z"
      fill="white"
    />
    <path
      d="M39.1326 46.2557C39.1326 44.9685 39.1326 44.3249 39.217 43.7863C39.6878 40.782 42.0445 38.4254 45.0487 37.9545C45.5874 37.8701 46.2309 37.8701 47.5181 37.8701C48.8053 37.8701 49.4489 37.8701 49.9875 37.9545C52.9918 38.4254 55.3484 40.782 55.8192 43.7863C55.9037 44.3249 55.9037 44.9685 55.9037 46.2557V47.7183C55.9037 49.0055 55.9037 49.6491 55.8192 50.1877C55.3484 53.192 52.9918 55.5486 49.9875 56.0195C49.4489 56.1039 48.8053 56.1039 47.5181 56.1039C46.2309 56.1039 45.5874 56.1039 45.0487 56.0195C42.0445 55.5486 39.6878 53.192 39.217 50.1877C39.1326 49.6491 39.1326 49.0055 39.1326 47.7183V46.2557Z"
      fill="#BEC8D5"
    />
    <path
      d="M86.6506 46.2557C86.6506 44.9685 86.6506 44.3249 86.735 43.7863C87.2058 40.782 89.5625 38.4254 92.5667 37.9545C93.1054 37.8701 93.749 37.8701 95.0361 37.8701C96.3233 37.8701 96.9669 37.8701 97.5055 37.9545C100.51 38.4254 102.866 40.782 103.337 43.7863C103.422 44.3249 103.422 44.9685 103.422 46.2557V47.7183C103.422 49.0055 103.422 49.6491 103.337 50.1877C102.866 53.192 100.51 55.5486 97.5055 56.0195C96.9669 56.1039 96.3233 56.1039 95.0361 56.1039C93.749 56.1039 93.1054 56.1039 92.5667 56.0195C89.5625 55.5486 87.2058 53.192 86.735 50.1877C86.6506 49.6491 86.6506 49.0055 86.6506 47.7183V46.2557Z"
      fill="#BEC8D5"
    />
  </svg>
);

const ErrorIcon = (
  <svg
    width="183"
    height="86"
    viewBox="0 0 183 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M151.903 62.9146C151.106 67.0565 153.873 71.0564 157.992 71.8496C162.111 72.6429 166.095 69.8547 166.892 65.7128C167.689 61.571 164.922 57.571 160.803 56.7778C156.684 55.9845 152.7 58.7727 151.903 62.9146ZM0 56.5247C4.8754 70.1288 16.6258 80.8169 31.8387 83.6104C57.8544 88.4329 66.8728 64.7106 84.2454 67.8648L158.136 85.4848C169.399 88.1804 180.503 79.9688 182.643 68.5403C184.76 57.0446 177.237 45.7499 165.832 43.9364L90.4737 34.1016C73.168 30.9234 73.1618 5.51826 47.1461 0.695755C31.9332 -2.09782 17.1959 3.70989 7.75944 14.7271C20.069 16.9725 29.0031 18.6121 41.3363 20.9248C47.5482 22.1027 47.2292 21.8388 49.3374 27.8137C50.6876 31.6402 52.0615 35.534 53.4117 39.3606C55.5199 45.3354 55.6019 44.9279 51.5219 49.792C48.8674 52.9357 46.256 55.9883 43.6014 59.132C39.5214 63.9961 39.8556 63.8764 33.6437 62.6985C21.1768 60.4338 12.3095 58.7702 0 56.5247Z"
      fill="#E1E6EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.0465 25.6784C44.7179 24.7758 43.9386 24.114 42.9966 23.9375L24.5989 20.4891C23.6569 20.3125 22.6918 20.6474 22.0601 21.37L9.96311 35.2078C9.30981 35.9552 9.11497 37.0002 9.45488 37.9337L15.749 55.2187C16.0777 56.1213 16.8569 56.783 17.7989 56.9596L36.1967 60.408C37.1387 60.5845 38.1037 60.2497 38.7354 59.5271L50.8324 45.6892C51.4857 44.9419 51.6806 43.8969 51.3407 42.9634L45.0465 25.6784ZM28.8524 48.7372C33.4181 49.593 37.8113 46.5757 38.6648 41.998C39.5183 37.4202 36.509 33.0155 31.9433 32.1597C27.3775 31.3039 22.9844 34.3212 22.1308 38.8989C21.2773 43.4767 24.2866 47.8814 28.8524 48.7372Z"
      fill="#BEC8D5"
    />
  </svg>
);

const NotFoundPage = ({ error = false }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {error ? ErrorIcon : NotFoundIcon}
      <Text>This page doesn’t exist</Text>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </BackButton>
    </Container>
  );
};

export default NotFoundPage;
