import React from 'react';
import { Stack, Typography } from '@mui/material';

const AutocreatedConfirmationModal = ({ amount }) => {
  return (
    <Stack>
      <Typography>
        Remove{' '}
        <b>{amount > 1 ? `${amount} tournaments` : `${amount} tournament`}</b>{' '}
        from auto event page?
      </Typography>
    </Stack>
  );
};

export default AutocreatedConfirmationModal;
