// import React from 'react';
import PropTypes from 'prop-types';

function CustomFieldTemplate(props) {
  const { children } = props;
  return [children];
}

CustomFieldTemplate.propTypes = {
  id: PropTypes.string,
  classNames: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  errors: PropTypes.element,
  rawErrors: PropTypes.arrayOf(PropTypes.string),
  help: PropTypes.element,
  rawHelp: PropTypes.string,
  description: PropTypes.element,
  rawDescription: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  displayLabel: PropTypes.bool,
  fields: PropTypes.object,
  formContext: PropTypes.object,
};

export default CustomFieldTemplate;
