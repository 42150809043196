import * as pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import pick from 'lodash/pick';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import without from 'lodash/without';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import getQueryString from './getQueryString';
import { matchPath } from 'react-router';
import isEmpty from 'lodash/isEmpty';

const getReqOrRes = (pathByMethod, method) =>
  method === 'get'
    ? pathByMethod.responses && pathByMethod.responses[200]
    : pathByMethod.requestBody;

export const getResponseSchemas = response => {
  const content = get(response, 'content.application/json', {});

  return {
    schema: content.schema,
    uiSchema: content['x-ui-schema'],
  };
};

export const getLinksAndSchemas = (routes, path) => {
  const chunks = path.split('/');

  const method = chunks[chunks.length - 1];
  const reqOrRes = getReqOrRes(routes[path], method);
  if (!reqOrRes) {
    return {};
  }

  const { content, links } = reqOrRes;

  const {
    [Object.keys(content)[0]]: { schema, 'x-ui-schema': uiSchema },
  } = content;

  return { schema, uiSchema, links };
};

export const getXStreamByOperationId = (routes, operationId) => {
  const routeKey = Object.keys(routes).find(
    path => routes[path].operationId === operationId
  );
  const route = routes[routeKey];

  return route && route.responses && route.responses['x-stream'];
};

export const getXStreamByPath = (routes, path) => {
  if (
    !routes[path] ||
    !routes[path].responses ||
    !routes[path].responses['x-stream']
  ) {
    return null;
  }

  return routes[path].responses['x-stream'];
};

export const getStoreStrategy = (routes, path) => {
  return get(routes[path], 'responses.200.x-options.store');
};

export const getOperationIdByPath = (routes, path) => {
  const route = routes && routes[path];

  if (!route) {
    return null;
  }

  return route.operationId || null;
};

export const getPathByOperationId = (routes, operationId) =>
  Object.keys(routes).find(path => routes[path].operationId === operationId);

export const getPathForRouter = path =>
  path.replace(/{/g, ':').replace(/}/g, '');

export const toEndpoint = ({ path, params }) => {
  const pathChunks = path.split('/');
  const pathWithColons = getPathForRouter(
    pathChunks.slice(0, pathChunks.length - 1).join('/')
  );
  const toPath = pathToRegexp.compile(pathWithColons);
  return toPath(params);
};

export const getMethodAndUrl = ({ path, params, query = {} }) => {
  const pathChunks = path.split('/');
  const method = pathChunks[pathChunks.length - 1];
  const url = `${toEndpoint({ path, params })}${qs.stringify(query, {
    addQueryPrefix: true,
    indices: false,
  })}`;

  return { method, url };
};

export const getShallowAndNested = ({
  properties,
  additionalProperties,
  entity,
  uiSchema,
}) => {
  const results =
    (additionalProperties && entity
      ? mapValues(entity, (v, title) => ({
          ...additionalProperties,
          title,
        }))
      : properties) || {};

  const inlineDropdownFields = uiSchema
    ? getInlineTableDropdown(properties, uiSchema)
    : {};

  const nestedPropertiesKeys = Object.keys(results).filter(
    prop =>
      results[prop].type === 'object' ||
      (results[prop].type === 'array' &&
        (!uiSchema || (uiSchema && !isInlineTableColumn(uiSchema[prop]))))
  );

  return {
    nested: pick(results, nestedPropertiesKeys),
    shallow: {
      ...results,
      ...inlineDropdownFields,
    },
  };
};

export const isInlineTableColumn = column => {
  return (
    column &&
    column.items &&
    column.items['ui:component'] &&
    column.items['ui:component'] === 'InlineTableDropdownList'
  );
};

export const getInlineTableDropdown = (columns, uiSchema) => {
  const dropdowns = {};

  for (let columnKey in columns) {
    const column = columns[columnKey];

    if (uiSchema[columnKey] && isInlineTableColumn(uiSchema[columnKey])) {
      dropdowns[columnKey] = column.items.properties;
    }
  }

  return dropdowns;
};

export const getLinkFields = (routes, links) =>
  mapValues(
    pickBy(links, link => link.path.endsWith('/get')),
    link => ({
      title: link.route.summary,
      label: link['x-label'],
      uiSchema: {
        'ui:options': {
          link: link.operationId,
        },
      },
      schema: {
        title: link.route.summary,
      },
    })
  );

const toFieldsArray = (keys, values) =>
  keys.map(field => ({
    name: field,
    ...values[field],
  }));

const getHref = ({ link, requestQuery, responseBody, requestPath }) => {
  const { params, query } = getParams({
    link,
    requestQuery,
    responseBody,
    requestPath,
  });
  const pathChunks = link.path.split('/');
  const method = pathChunks[pathChunks.length - 1];
  return `${toEndpoint({
    path: link.path,
    params,
  })}/${method}${getQueryString({
    [link.path]: query,
  })}`;
};

export const getTo = ({
  link,
  routes,
  requestQuery,
  requestPath,
  responseBody,
}) => {
  return (
    link &&
    !link['x-modal'] &&
    !isDownloadLink(link, routes) &&
    Object.values(
      getParams({
        link,
        requestQuery,
        requestPath,
        responseBody,
      }).params
    ).every(p => p !== undefined) &&
    getHref({
      link,
      responseBody,
      requestQuery,
      requestPath,
    })
  );
};

export const isDownloadLink = (link, routes) =>
  link && getLinksAndSchemas(routes, link.path).schema.format === 'binary';

export const isModalAction = action => {
  return action.link['x-modal'] || (action.uiSchema && action.uiSchema.modal);
};

export const isDirectLink = link => {
  return !!link.parameters?.absolutePath;
};

export const getOrderedKeys = (allKeys, uiOrder) => {
  if (isEmpty(uiOrder)) {
    return allKeys;
  }

  const isFullyOrdered = !uiOrder.find(item => item === '*');

  uiOrder =
    uiOrder && uniq(uiOrder.filter(item => allKeys.indexOf(item) !== -1));

  return isFullyOrdered
    ? uiOrder
    : [...uiOrder, ...without(allKeys, ...uiOrder)];
};

export const isEditLink = link => link.path.endsWith('/patch');

export const isExportLink = link => link.path.endsWith('/export/get');

export const getEditLink = links => Object.values(links).find(isEditLink);

export const getExportLink = links => Object.values(links).find(isExportLink);

export const getEditPath = ({ editLink, entity, query, requestPath }) =>
  toEndpoint({
    path: editLink.path,
    params: getParams({
      link: editLink,
      responseBody: entity,
      requestQuery: query,
      requestPath,
    }).params,
  });

export const getLabelField = label => {
  const labelParts = label.split('/');
  return labelParts[labelParts.length - 1];
};

export const getVisibleFields = ({
  entity,
  shallow,
  routes,
  links,
  uiSchema,
}) => {
  const hideEmpty = get(uiSchema, 'ui:options.hide_empty');
  const uiFieldsSchema = get(uiSchema, 'items.items', uiSchema);
  const allFields = { ...shallow, ...getLinkFields(routes, links) };
  const orderedKeys = getOrderedKeys(
    Object.keys(allFields),
    uiSchema && uiSchema['ui:order']
  );

  const visibleOrderedKeys = orderedKeys.filter(
    fieldName =>
      get(uiFieldsSchema, `${fieldName}.[ui:widget]`) !== 'hidden' &&
      fieldName in allFields &&
      (!entity ||
        !hideEmpty ||
        fieldName in entity ||
        (allFields[fieldName].label &&
          getLabelField(allFields[fieldName].label) in entity))
  );

  return toFieldsArray(
    visibleOrderedKeys,
    merge({}, allFields, uiFieldsSchema)
  );
};

const getDefaultParameterValue = (rawParameters = [], paramName) => {
  const parameter = rawParameters.find(({ name }) => name === paramName);
  return parameter && parameter.schema && parameter.schema.default;
};

export const getParameterValue = ({
  param,
  requestQuery,
  requestPath,
  requestBody,
  responseBody,
  rawParameters,
  id,
}) => {
  if (Array.isArray(param)) {
    return param.map(p =>
      getParameterValue({
        param: p,
        id,
        requestQuery,
        requestPath,
        requestBody,
        responseBody,
        rawParameters,
      })
    );
  }

  if (typeof param === 'object') {
    return mapValues(param, p =>
      getParameterValue({
        param: p,
        id,
        requestQuery,
        requestPath,
        requestBody,
        responseBody,
        rawParameters,
      })
    );
  }

  if (param.startsWith('$request.query')) {
    const paramName = param.replace('$request.query.', '');
    return get(
      requestQuery,
      paramName,
      getDefaultParameterValue(rawParameters, paramName)
    );
  }

  if (param.startsWith('$request.path')) {
    const paramName = param.replace('$request.path.', '');

    return get(
      requestPath,
      paramName,
      getDefaultParameterValue(rawParameters, paramName)
    );
  }

  if (param.startsWith('$request.body')) {
    const paramName = param.replace('$request.body#/', '');

    if (id && id.startsWith('root_')) {
      id = id.split('root_')[1];
    }

    const pathParts = paramName.split('/');
    const path = [];

    for (let pathPart of pathParts) {
      if (!id) {
        path.push(pathPart);
        continue;
      }

      if (pathPart === 'items') {
        const pathIndex = id.indexOf('_');
        path.push(id.substr(0, pathIndex));
        id = id.substr(pathIndex + 1);
      } else {
        id = id.split(pathPart + '_')[1];
        path.push(pathPart);
      }
    }

    return get(
      requestBody,
      path,
      getDefaultParameterValue(rawParameters, paramName)
    );
  }

  if (param.startsWith('$response.body')) {
    const paramChunks = param.split('/');
    const paramName = paramChunks[paramChunks.length - 1];

    return get(
      responseBody,
      paramName,
      getDefaultParameterValue(rawParameters, paramName)
    );
  }

  return param;
};

export const getParameters = ({ params, idSchema, id, ...dataSources }) => {
  return mapValues(params, param =>
    getParameterValue({ param, idSchema, id, ...dataSources })
  );
};

export const replaceParamsInDirectLink = ({
  link,
  requestQuery,
  requestPath,
  responseBody,
}) => {
  const paramMatchRegexp = /"(\$[\s\S]*?)"/g;
  const path = link?.parameters.absolutePath;

  return path.replace(paramMatchRegexp, (p, param) => {
    return encodeURIComponent(
      getParameterValue({
        param,
        requestQuery,
        requestPath,
        responseBody,
        rawParameters: link.route.parameters,
      }).replace(/["]/g, '\\$&') // convert values like 'Anthony "Babyface" Blackburn' to valid json format 'Anthony \"Babyface\" Blackburn'
    );
  });
};

const getObjectParamName = paramName => {
  const split = paramName.split('[');

  if (split.length > 1) {
    return split[0];
  }

  return paramName;
};

export const getParams = ({
  link,
  responseBody,
  requestQuery,
  requestPath,
  parentPath,
}) => {
  const allParams = getParameters({
    params: link.parameters,
    requestQuery,
    requestPath,
    responseBody,
    rawParameters: link.route.parameters,
    parentPath,
  });

  const params = pickBy(allParams, (_, paramName) =>
    link.route.parameters.find(
      linkP => linkP.name === paramName && linkP.in === 'path'
    )
  );

  const query = pickBy(allParams, (_, paramName) =>
    link.route.parameters.find(
      linkP =>
        linkP.name === paramName ||
        (linkP.name === getObjectParamName(paramName) && linkP.in === 'query')
    )
  );

  const formParams = pickBy(
    allParams,
    (_, paramName) => !query[paramName] && !params[paramName]
  );

  return {
    params,
    query,
    formParams,
  };
};

export const getLoginRoute = routes =>
  Object.keys(routes).find(routeName => {
    return get(getLinksAndSchemas(routes, routeName).uiSchema, 'ui:auth');
  });

export const isPathSortable = path => {
  const parameters = get(path, 'parameters', []);

  return !!parameters.find(f => f.in === 'query' && f.name === 'order_by');
};

export const getPathParameters = parameters =>
  parameters.filter(parameter => parameter.in === 'path');

export const getRequiredPathParameters = parameters =>
  parameters.filter(parameter => parameter.in === 'path' && parameter.required);

export const getFilters = parameters =>
  parameters.filter(
    f =>
      get(f, 'x-ui-schema.ui:widget') !== 'hidden' &&
      f.in === 'query' &&
      f.name !== 'limit' &&
      f.name !== 'offset' &&
      f.name !== 'order_by' &&
      f.name !== 'order_by_asc_desc'
  );

export const isUrlValid = url => {
  try {
    return new URL(url);
  } catch (e) {
    return false;
  }
};

export const getRouteByPathname = (routes, pathname) => {
  const path = Object.keys(routes).find(path => {
    return matchPath(getPathForRouter(path), pathname);
  });

  return path && routes[path];
};

export const hasSchemaProperty = (schema, path) => {
  return (
    path &&
    get(
      schema,
      path
        .split('.')
        .map(property => `properties.${property}`)
        .join('.')
    )
  );
};
