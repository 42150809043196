import { makeStyles } from '@mui/styles';
import SportIcon from '../../../icons/SportIcon/SportIcon';
import Tooltip from '../../Tooltip/Tooltip';
import StringField from '../StringField/StringField';
import { Fragment } from 'react';

const useStyles = makeStyles({
  sportField: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
  bigSportIcon: {
    width: '24px',
    height: '24px',
    backgroundSize: '24px',
  },
});

const SportField = ({
  name,
  id,
  captionClassName,
  white,
  bigIcon = false,
  withTooltip = true,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.sportField} onClick={onClick}>
      <SportIcon
        name={id}
        white={white}
        className={bigIcon && classes.bigSportIcon}
      />
      <StringField className={captionClassName}>{name}</StringField>
    </div>
  );
};

export default SportField;
