import React, { Fragment } from 'react';
import BannerLinkField from '../../BannerFields/BannerLinkField';
import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import BannerTitleField from '../../BannerFields/BannerTitleField';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import BannerImageUploader from '../../BannerImageUploader/BannerImageUploader';
import LineBannerTextPreview from '../../BannerImagePreview/LineBannerTextPreview';
import FormField from '../../../../../components/Form/FormField';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import { useFormContext } from 'react-hook-form';

const TextView = ({ prefix }) => {
  const { watch } = useFormContext();
  const brand = watch('brand');

  return (
    <ImagePreviewProvider>
      <LocaleTabs
        name={`${prefix}.locales`}
        content={(localeField, localeIndex) => (
          <Fragment key={localeField.locale.country + localeField.locale.lang}>
            <BannerLinkField name={`${prefix}.locales.${localeIndex}.link`} />

            <Grid item xs={12}>
              <FormGroup large>
                <Grid container spacing={2} direction={'column'}>
                  <BannerTitleField
                    maxLength={50}
                    name={`${prefix}.locales.${localeIndex}.title`}
                  />
                </Grid>
              </FormGroup>
            </Grid>

            {brand && (
              <Grid item xs={12}>
                <FormGroup large>
                  <FormField
                    name={`${prefix}.locales.${localeIndex}.images.desktop`}
                  >
                    <BannerImageUploader
                      useGradient
                      preview={LineBannerTextPreview}
                      previewFields={[`${prefix}.locales.${localeIndex}.title`]}
                      cropSize={{
                        width: 360,
                        height: 152,
                      }}
                    />
                  </FormField>
                </FormGroup>
              </Grid>
            )}
          </Fragment>
        )}
      />
    </ImagePreviewProvider>
  );
};

export default TextView;
