import { gql } from '@apollo/client';

const GamingActivityFields = gql`
  fragment GamingActivityFields on GamingActivityTableItem {
    username
    extPlayerId

    operator
    operatorId
    brand
    brandId

    created_at__day
    created_at__month
    created_at__week
    created_at__year
    sport
    sportId
    category
    categoryId
    tournament
    tournamentId
    event
    eventId
    marketId
    marketName
    marketExample
    outcomeId
    outcomeName
    outcomeExample
    provider
    currency
    betType
    bonusType
    deviceType
    country
    lang
    playerCcf
    betStatus
    isLive
    sourceLayout
    sourcePage
    sourceSection

    firstBetDate
    firstBetDateBet
    lastBetDate
    lastBetDateBet

    players
    playerId
    bets
    turnover
    turnoverOpen
    avgStake
    ggr
    margin
    turnoverOdd
    ggrOdd
    marginOdd
    maxStakeEur
    maxStakeBet
    maxGgrEur
    maxGgrBet
    minGgrEur
    minGgrBet
    freebetBets
    freebetPlayers
    freebetTurnover
    freebetGgr

    playersChange
    playersChangeAbs
    avgStakeChange
    betsChange
    betsChangeAbs
    turnoverChange
    turnoverChangeAbs
    ggrChange
    ggrChangeAbs
    marginChange
  }
`;

const getGamingActivityList = gql`
  ${GamingActivityFields}
  query getGamingActivityList($inputFilters: GamingActivityTableFilters) {
    data: getGamingActivityList(inputFilters: $inputFilters) {
      items {
        ...GamingActivityFields
      }
      total
      results {
        players
        avgStake
        bets
        turnover
        turnoverOpen
        ggr
        margin
        turnoverOdd
        ggrOdd
        marginOdd
        maxStakeEur
        maxGgrEur
        minGgrEur
        maxStakeBet
        maxGgrBet
        minGgrBet
        lastBetDateBet
        firstBetDateBet
        lastBetDate
        firstBetDate
        playersChange
        avgStakeChange
        betsChange
        turnoverChange
        ggrChange
        playersChangeAbs
        betsChangeAbs
        turnoverChangeAbs
        ggrChangeAbs
        marginChange
        freebetBets
        freebetPlayers
        freebetTurnover
        freebetGgr
      }
    }
  }
`;

export default getGamingActivityList;
