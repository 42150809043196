import { gql } from '@apollo/client';

const getSportNamesInLine = gql`
  query getSportsNamesInLine(
    $query: String!
    $limit: Int
    $offset: Int
    $sportIds: [SportId!]
  ) @api(name: autocomplete) {
    data: getSportsNamesInLine(
      query: $query
      limit: $limit
      offset: $offset
      sportIds: $sportIds
    ) {
      label: name
      value: id
    }
  }
`;

export default getSportNamesInLine;
