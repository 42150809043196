import React from 'react';
import StringField from '../../../components/Fields/StringField/StringField';
import DateField from '../../../components/Fields/DateField/DateField';
import TableHeaderRow from '../../../components/Table/TableHeaderRow';
import FancyTable from '../../../components/Table/FancyTable';
import useTableRowsRender from '../../../hooks/useTableRowsRender';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableGrid from '../../../hooks/useTableGrid';
import useTableColumnToggle from '../../../hooks/useTableColumnToggle';
import useTableColumnsReorder from '../../../hooks/useTableColumnsReorder';

const commonCellStyle = {
  padding: '0px 16px',
};

export const COLUMN = {
  NAME: 'name',
  SPORT_NAME: 'sport_name',
  DATE: 'date',
  USER: 'user',
  CREATOR: 'creator',
};

export const initialToggledColumns = [
  COLUMN.NAME,
  COLUMN.SPORT_NAME,
  COLUMN.DATE,
  COLUMN.USER,
  COLUMN.CREATOR,
];

export const columnsOrderConfig = [
  COLUMN.NAME,
  COLUMN.SPORT_NAME,
  COLUMN.DATE,
  COLUMN.USER,
  COLUMN.CREATOR,
];

export const columnsWidthConfig = {
  [COLUMN.NAME]: 1,
  [COLUMN.SPORT_NAME]: 1,
  [COLUMN.DATE]: 1,
  [COLUMN.USER]: 1,
  [COLUMN.CREATOR]: 1,
};

const MarginLadderTable = ({
  loading,
  items,
  rowActions,
  activeRow,
  onRowClick,
}) => {
  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns
  );
  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    initialColumnWidth: columnsWidthConfig,
    onChangeColumnsWidth: () => {},
    rowActions: true,
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'Name',
        key: COLUMN.NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.NAME]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Sport',
        key: COLUMN.SPORT_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.SPORT_NAME] || 'All'}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Last Modified',
        key: COLUMN.DATE,
        style: commonCellStyle,
        render: data => {
          return (
            <StringField>
              <DateField date={data.updated.date} />
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: 'Modified By',
        key: COLUMN.USER,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data.updated.user.email}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Creator',
        key: COLUMN.CREATOR,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data.created.user.email}</StringField>;
        },
        resizable: true,
      },
    ],
    order: columnsOrder,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    rowActions,
    activeRow,
    withHover: true,
    onRowClick,
  });

  return (
    <FancyTable
      loading={loading}
      data={items}
      renderRow={renderRow}
      renderHeaderRow={
        <TableHeaderRow
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorderColumns}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
          onChangeColumnWidth={changeColumnWidth}
          rowActions={rowActions}
        />
      }
      rowHeight={38}
    />
  );
};

export default MarginLadderTable;
