import { isEmpty, isEqual, omit, sortBy } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ACCESS_ROLE,
  useSportsBookUserAccessRoles,
} from '../../../../../../providers/SportsBookUserAccessRolesProvider';
import { reorderDragItemsData } from '../../../components/DraggableSelectMenu';
import { getKeySelector } from '../../../utils/getKeySelector';

export const useCoverageTemplateSelect = ({
  options,
  pathToSelect = {},
  initialTabKey,
  nodePath = {},
  idKey = 'sportId',
  getFormValues = () => {},
  setFormValue = () => {},
}) => {
  const [tabKey, setTabKey] = useState(initialTabKey);
  const selectOptions = tabKey ? options[tabKey] : options;
  const optionsFullListRef = useRef(selectOptions);
  const inputValueRef = useRef('');
  const [displayOptions, setDisplayOptions] = useState(
    sortBy(selectOptions, ['priority'])
  );
  //drag drop must be disabled while search (if text field contain some value)
  const [dragDropDisabled, setDragDropDisabled] = useState(false);

  const [lineType] = getFormValues(['configLineType']);
  const { roles } = useSportsBookUserAccessRoles();
  const isCoverageWriter = roles?.coverage === ACCESS_ROLE.WRITE;
  const isPresetsAvailable =
    roles?.coverage === ACCESS_ROLE.WRITE &&
    roles?.sources === ACCESS_ROLE.WRITE;

  const filterDisplayedOptions = useCallback(fullOption => {
    const filteredOptions = fullOption.filter(
      option =>
        option && option.name.toLowerCase().includes(inputValueRef.current)
    );

    setDisplayOptions(filteredOptions);
  }, []);

  const saveReorderChanges = useCallback(
    ({ startIndex, endIndex, reorderedOptions }) => {
      const template = getFormValues('template');

      reorderedOptions.forEach((value, index) => {
        //we need to add changes for values in range of startIndex and endIndex
        //start index can be greater than final (but indexes cant be equal because in this case nothing changed)
        if (
          (endIndex > startIndex
            ? index >= startIndex && index <= endIndex
            : index >= endIndex && index <= startIndex) ||
          tabKey === 'allMarkets'
        ) {
          const nodeSelector =
            idKey === 'marketId'
              ? {
                  ...pathToSelect,
                  market: {
                    [idKey]: value[idKey],
                  },
                }
              : {
                  ...pathToSelect,
                  [idKey]: value[idKey],
                };
          const excludedCategorySelector = omit(nodeSelector, 'categoryId');
          const keySelector = getKeySelector(
            idKey === 'tournamentId'
              ? {
                  nodeSelector: excludedCategorySelector,
                  lineType,
                  tabKey,
                }
              : { nodeSelector, lineType }
          );
          if (template[keySelector]) {
            template[keySelector].priority = index + 1;
          }
          if (index === endIndex) {
            template[keySelector] && delete template[keySelector];
            const defaultValue = selectOptions.find(
              item => item[idKey] === value[idKey]
            );
            template[keySelector] = {
              lineType,
              nodeSelector:
                tabKey === 'sport' ? excludedCategorySelector : nodeSelector,
              status:
                defaultValue?.status === value.status
                  ? undefined
                  : value.status,
              priority: endIndex + 1,
            };
          }
        }
      });
      setFormValue('template', template);
    },
    [
      lineType,
      idKey,
      tabKey,
      pathToSelect,
      selectOptions,
      setFormValue,
      getFormValues,
    ]
  );

  const applyChanges = useCallback(
    (newItems, tabKey) => {
      const template = getFormValues('template');
      const currentOptions = structuredClone(
        newItems || displayOptions || options[tabKey] || options
      );

      //if a new items do not passed we need to use current
      //also we need to set not configured values only if we have a new items
      if (!isEmpty(template)) {
        const configuredOptions = Object.values(template).reduce(
          (accumulator, currentValue) => {
            if (currentValue.lineType === lineType) {
              const nodeSelector = currentValue.nodeSelector;
              const path =
                tabKey === 'sport'
                  ? omit(pathToSelect, 'categoryId')
                  : pathToSelect;
              const optionIndex = accumulator.findIndex(option =>
                isEqual(
                  {
                    ...path,
                    [idKey]: option[idKey],
                  },
                  nodeSelector
                )
              );
              if (optionIndex === -1) {
                return accumulator;
              }

              if (currentValue.status !== undefined) {
                accumulator[optionIndex].status = currentValue.status;
              }

              //if item priority saved, we need to 'move' this item on its new place
              if (currentValue.priority !== undefined) {
                return reorderDragItemsData({
                  startIndex: accumulator[optionIndex].priority - 1,
                  endIndex: currentValue.priority - 1,
                  options: accumulator,
                });
              }
              return accumulator;
            }

            return accumulator;
          },
          currentOptions
        );
        optionsFullListRef.current = configuredOptions;

        return configuredOptions;
      }
      return currentOptions;
    },
    [idKey, options, lineType, pathToSelect, getFormValues, displayOptions]
  );

  const onManualPriorityChange = useCallback(
    async (startIndex, endIndex) => {
      if (endIndex !== -1) {
        optionsFullListRef.current = reorderDragItemsData({
          startIndex,
          endIndex,
          options: optionsFullListRef.current,
        });

        saveReorderChanges({
          startIndex,
          endIndex,
          reorderedOptions: optionsFullListRef.current,
        });
        filterDisplayedOptions(optionsFullListRef.current);
      }
    },
    [saveReorderChanges, filterDisplayedOptions]
  );

  //if user starts search we need to disable drag drop ability, and hide after ends
  const onSearchFieldChange = useCallback(
    e => {
      inputValueRef.current = e.target
        ? e.target.value.toLowerCase()
        : e.toLowerCase();
      if (inputValueRef.current.length === 1) {
        setDragDropDisabled(true);
      } else if (inputValueRef.current.length === 0) {
        setDragDropDisabled(false);
      }

      const relevantOptions = applyChanges(optionsFullListRef.current);

      filterDisplayedOptions(relevantOptions);
    },
    [applyChanges, filterDisplayedOptions]
  );

  return useMemo(
    () => ({
      displayOptions,
      dragDropDisabled,
      tabKey,
      optionsFullListRef,
      isCoverageWriter,
      isPresetsAvailable,
      onManualPriorityChange,
      setDragDropDisabled,
      setTabKey,
      onSearchFieldChange,
      saveReorderChanges,
      applyChanges,
      setDisplayOptions,
    }),
    [
      displayOptions,
      dragDropDisabled,
      tabKey,
      optionsFullListRef,
      isCoverageWriter,
      isPresetsAvailable,
      onManualPriorityChange,
      setDragDropDisabled,
      setTabKey,
      onSearchFieldChange,
      saveReorderChanges,
      applyChanges,
      setDisplayOptions,
    ]
  );
};
