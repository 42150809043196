import { gql } from '@apollo/client';
import { CONTINGENCY_ITEM_FIELDS } from '../queries/RELATED_CONTINGENCIES_QUERY';

const updateRelatedContingency = gql`
  ${CONTINGENCY_ITEM_FIELDS}
  mutation updateRelatedContingency(
    $id: ID!
    $input: RelatedContingencyRuleInput!
  ) {
    data: updateRelatedContingency(id: $id, input: $input) {
      id
      left {
        ...ContingencyItemFields
      }
      right {
        ...ContingencyItemFields
      }
      active
      rule_type
      user_email
      update_date
    }
  }
`;

export default updateRelatedContingency;
