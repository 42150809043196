import toNumber from 'lodash/toNumber';

const toNumberOrNull = value => {
  if (!value) {
    if (value === 0) {
      return value;
    }
  } else {
    return toNumber(value);
  }

  return null;
};

export default toNumberOrNull;
