import { gql } from '@apollo/client';

const LocaleFields = gql`
  fragment LocaleFields on LangInfo {
    lang
    country
  }
`;

const ImageFields = gql`
  fragment ImageFields on ImageExtended {
    id
    source_id
    width
    height
    start_x
    start_y
  }
`;

const MultipleImagesFields = gql`
  ${ImageFields}
  fragment MultipleImagesFields on BannerImages {
    desktop {
      ...ImageFields
    }
    tablet {
      ...ImageFields
    }
    mobile {
      ...ImageFields
    }
    use_gradient
  }
`;

const SingleImageFields = gql`
  ${ImageFields}
  fragment SingleImageFields on BannerImages {
    desktop {
      ...ImageFields
    }
    use_gradient
  }
`;

const getPromoBanner = gql`
  ${MultipleImagesFields}
  ${SingleImageFields}
  ${LocaleFields}
  query getPromoBanner($id: ID!) @api(name: promo) {
    data: getPromoBanner(id: $id) {
      state
      name
      operator {
        name
        id
      }
      brand {
        name
        id
      }
      activate {
        type
        period {
          range_from
          range_to
        }
      }
      banner_type
      display_platform
      hero_banner {
        placeholders
        view_type
        text_view {
          view
          locales {
            locale {
              ...LocaleFields
            }

            link {
              url
              custom_action
            }
            title
            subtitle
            legal_text

            images {
              ...MultipleImagesFields
            }
          }
        }
        button_view {
          view
          locales {
            locale {
              ...LocaleFields
            }

            title
            subtitle
            legal_text

            link {
              url
              custom_action
            }

            button {
              text
              color
            }

            images {
              ...MultipleImagesFields
            }
          }
        }
        event_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            title

            images {
              ...MultipleImagesFields
            }
            event {
              label
              value
            }
          }
        }
      }
      line_banner {
        placeholders
        restrictions {
          type
          value
          name
          secondary_text
        }
        view_type
        text_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            link {
              url
              custom_action
            }
            title
            images {
              ...SingleImageFields
            }
          }
        }
        button_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            link {
              url
              custom_action
            }
            title
            button {
              text
              color
            }
            images {
              ...SingleImageFields
            }
          }
        }
        auto_events_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            images {
              ...SingleImageFields
            }
            events {
              item_type
              id
              label
            }
            events_limit
          }
        }
        code_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            code
          }
        }
        boosted_odds_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            images {
              ...SingleImageFields
            }
            details {
              event {
                value
                label
              }
              market {
                id
                name
                specifiers
              }
              outcome {
                outcome_id
                outcome_name
                specifiers
              }
              multiplier
            }
          }
        }
      }
      popular_now_banner {
        placeholders
        view_type
        text_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            link {
              url
              custom_action
            }
            title
            images {
              ...SingleImageFields
            }
          }
        }
        tournament_view {
          view
          locales {
            locale {
              ...LocaleFields
            }
            images {
              ...SingleImageFields
            }
            tournament {
              value
              label
            }
          }
        }
      }
      code_banner {
        locales {
          code
          locale {
            ...LocaleFields
          }
        }
        placeholders
        restrictions {
          type
          value
          name
          secondary_text
        }
      }

      display_platform
    }
  }
`;

export default getPromoBanner;
