import React, { useContext } from 'react';
import FieldCopyAction from './FieldCopyAction';
import { UiOptionsContext } from './UiOptionsProvider';

const FieldActions = () => {
  const { actions, value } = useContext(UiOptionsContext);

  return actions ? (
    <div>{actions.copy && value && <FieldCopyAction value={value.data} />}</div>
  ) : null;
};

export default FieldActions;
