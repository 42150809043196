import Stack from '@mui/material/Stack/Stack';
import FormGroup from './FormGroup';
import { Box } from '@mui/material';

const FormActions = ({ mt, children }) => {
  return (
    <Box mt={mt}>
      <FormGroup>
        <Stack spacing={2} justifyContent={'flex-end'} direction={'row'}>
          {children}
        </Stack>
      </FormGroup>
    </Box>
  );
};

export default FormActions;
