import Checkbox from '../Inputs/Checkbox';
import { memo } from 'react';

export const bulkCellStyles = {
  padding: '0 4px',
};

const TableBulkCell = ({ value, disabled, onToggle }) => {
  return (
    <Checkbox
      disabled={disabled}
      value={value}
      onChange={checked => {
        onToggle(checked);
      }}
    />
  );
};

export default memo(TableBulkCell);
