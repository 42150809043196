import { gql } from '@apollo/client';

const TOURNAMENTS_AUTOCOMPLETE_QUERY = gql`
  query getTournaments($categoryId: CategoryId!, $query: String) {
    data: getTournaments(category_id: $categoryId, name: $query) {
      id
      name
    }
  }
`;

export default TOURNAMENTS_AUTOCOMPLETE_QUERY;
