import Autocomplete from '../../Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getError, getLoading } from '../../../reducers/data';
import { debouncedGetRouteData } from '../../../actions/dataActions';
import {
  getMethodAndUrl,
  getParameters,
  getPathByOperationId,
} from '../../../utils/schemaHelper';
import MultiSelect from '../../MultiSelect/MultiSelect';
import usePrevious from '../../../hooks/usePrevious';
import { useRoutes } from '../../../providers/RoutesProvider';
import { useApiHost } from '../../../providers/ApiHostProvider';

const AutocompleteWidget = ({
  params,
  helperText,
  value,
  label,
  options,
  formData,
  ...otherProps
}) => {
  const routes = useRoutes('data fetcher');
  const { apiHost } = useApiHost('data fetcher');
  const [query, setQuery] = useState({ query: '' });
  const prevQuery = usePrevious(query);
  const path = getPathByOperationId(routes, options.operationId);
  const queryParams = getParameters({
    params: options.params,
    requestBody: formData,
    requestQuery: formData,
  });
  const { method, url } = getMethodAndUrl({
    path,
    params,
    query: { ...query, ...queryParams },
  });

  const dataUrl = method + url;
  const items = useSelector(state =>
    getData(state, routes, dataUrl, undefined, path)
  );
  const error = useSelector(state => getError(state, routes, dataUrl, path));
  const isLoading = useSelector(state =>
    getLoading(state, routes, dataUrl, path)
  );
  const onInputChange = useCallback(value => setQuery({ query: value }), []);

  const dispatch = useDispatch();

  const fetchData = useCallback(
    () =>
      dispatch(
        debouncedGetRouteData(
          {
            routes,
            apiHost,
          },
          { method, url, path }
        )
      ),
    [dispatch, routes, apiHost, method, url, path]
  );

  useEffect(() => {
    if (query.query.length >= 2 && query.query !== prevQuery.query) {
      fetchData();
    }
  }, [query, prevQuery, fetchData]);

  const AutocompleteComponent = otherProps.isMulti ? MultiSelect : Autocomplete;

  return (
    <AutocompleteComponent
      menuPortalTarget={document.body}
      error={error}
      isLoading={isLoading}
      onInputChange={onInputChange}
      uiOptions={options}
      options={items}
      getOptionLabel={option => option[options.label_key || 'name']}
      secondaryKey={options.secondary_key || 'secondary_text'}
      filterOption={() => true}
      variant={'outlined'}
      margin={'dense'}
      isClearable={false}
      label={options.title || label}
      helperText={options.help || helperText}
      value={value}
      {...otherProps}
    />
  );
};

export default AutocompleteWidget;
