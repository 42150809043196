import { isFilterEmpty } from '../components/Filters/Filters';
import useQuery from './useQuery';

const useInitialFiltersList = ({ filtersList = [] }) => {
  const query = useQuery();
  const queryFilters = JSON.parse(query.get('filters'));

  const initialFilters =
    queryFilters && filtersList
      ? filtersList.map(value => {
          return {
            ...value,
            toggled: value.toggled || !isFilterEmpty(queryFilters[value.name]),
          };
        })
      : filtersList;

  return initialFilters;
};

export default useInitialFiltersList;
