import { SvgIcon } from '@mui/material';

const ClearInputIcon = ({ ...props }) => {
  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.23033 6.52324C7.03606 6.32699 6.71948 6.32539 6.52324 6.51967C6.32699 6.71394 6.32539 7.03052 6.51967 7.22676L7.23033 6.52324ZM14.6867 15.4768C14.881 15.673 15.1976 15.6746 15.3938 15.4803C15.5901 15.2861 15.5917 14.9695 15.3974 14.7732L14.6867 15.4768ZM15.4803 7.22676C15.6746 7.03052 15.673 6.71394 15.4768 6.51967C15.2805 6.32539 14.9639 6.32699 14.7697 6.52324L15.4803 7.22676ZM6.60258 14.7732C6.40831 14.9695 6.40991 15.2861 6.60615 15.4803C6.8024 15.6746 7.11898 15.673 7.31325 15.4768L6.60258 14.7732ZM6.51967 7.22676L14.6867 15.4768L15.3974 14.7732L7.23033 6.52324L6.51967 7.22676ZM14.7697 6.52324L6.60258 14.7732L7.31325 15.4768L15.4803 7.22676L14.7697 6.52324Z"
        fill="#8496AB"
      />
    </SvgIcon>
  );
};

export default ClearInputIcon;
