import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import getSourcesPreset from '../../../../../../gql/sportbook-config/templates/queries/getSourcesPreset';
import getSourcesPresetNames from '../../../../../../gql/sportbook-config/templates/queries/getSourcesPresetNames';
import TextField from '../../../../TextField';
import {
  ApplyPreset,
  MenuCaption,
  MenuTitle,
  SelectContainer,
  VirtualizedMenu,
} from '../../../components/';
import { useSourcesTemplateSelect } from '../../../hooks/sources/select/useSourcesTemplateSelect';
import SourcesSportOption from '../Options/SourcesSportOption';

const SourcesSportSelect = memo(
  ({
    templateId,
    nodePath,
    setPath = () => {},
    selectKey,
    nodeIndex,
    options,
  }) => {
    const selectedId = nodePath.sportId;

    const { getValues } = useFormContext();

    const lineType = getValues('lineType');

    const {
      displayOptions,
      isSourcesWriter,
      isPresetsAvailable,
      onSearchFieldChange,
    } = useSourcesTemplateSelect({
      options,
      getFormValues: getValues,
    });

    return (
      <SelectContainer style={{ width: '350px' }}>
        <TextField
          style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
          label={`Search Sport`}
          onChange={onSearchFieldChange}
          clearButton={true}
        />
        <MenuCaption>
          <MenuTitle name={'Sports'} />
          {isPresetsAvailable && (
            <ApplyPreset
              templateType={'source'}
              selectKey={'sport'}
              menuOptions={options}
              sportId={selectedId}
              idKey={'sportId'}
              getPresetNamesQuery={getSourcesPresetNames}
              getPresetQuery={getSourcesPreset}
            />
          )}
        </MenuCaption>
        <VirtualizedMenu
          rowCount={displayOptions.length}
          itemRender={({ measure, index }) => {
            const option = displayOptions[index];

            return (
              <SourcesSportOption
                key={option.sportId}
                templateId={templateId}
                nodePath={nodePath}
                selectKey={selectKey}
                setPath={setPath}
                nodeIndex={nodeIndex}
                selected={option.sportId === selectedId}
                itemValue={option}
                lineType={lineType}
                onMenuOpen={measure}
                configurationDisabled={!isSourcesWriter}
              />
            );
          }}
        />
      </SelectContainer>
    );
  }
);

export default SourcesSportSelect;
