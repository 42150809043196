import { useCallback, useMemo, useState } from 'react';
import useTablePresetPaginationLimit from './useTablePresetPaginationLimit';
import { tableNames } from './useLocalStorageTableConfig';

const useTablePresetPagination = ({
  tableName = tableNames.GenericTable,
  onChangeLimit = () => {},
}) => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useTablePresetPaginationLimit(tableName);

  const onChangeLimitCb = useCallback(
    limit => {
      setLimit(limit);
      onChangeLimit(limit);
    },
    [onChangeLimit, setLimit]
  );

  return useMemo(
    () => ({
      setLimit: onChangeLimitCb,
      limit,
      offset,
      setOffset,
    }),
    [limit, onChangeLimitCb, offset, setOffset]
  );
};

export default useTablePresetPagination;
