import Button from './Button';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const LinkButton = ({ to, replace, ...props }) => {
  return (
    <StyledLink to={to} replace={replace}>
      <Button {...props} />
    </StyledLink>
  );
};

export default LinkButton;
