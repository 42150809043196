import copyToClipboard from 'copy-to-clipboard';
import React, { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CopyIcon from '../../v2/icons/CopyIcon.svg';
import NotificationAlert, {
  AlertVariant,
} from '../../v2/components/Notifications/NotificationAlert';
import { useToasts } from '../../v2/providers/ToastsProvider';

const useStyles = makeStyles({
  copyAction: {
    color: '#8496AB',
    padding: 0,
    margin: 2,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundImage: `url(${CopyIcon})`,
    backgroundRepeat: 'no-repeat',
    width: 24,
    height: 23,
  },
});

const FieldCopyAction = memo(({ value }) => {
  const classes = useStyles();
  const { showToast } = useToasts();

  return value ? (
    <div
      className={classes.copyAction}
      onClick={() => {
        copyToClipboard(value);
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS} timeout={1500}>
            Copied to clipboard
          </NotificationAlert>
        );
      }}
    />
  ) : null;
});

export default FieldCopyAction;
