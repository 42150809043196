import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import BannerCodeField from '../../BannerFields/BannerCodeField';
import React, { Fragment } from 'react';
import CodeBannerPreview from '../../CodeBannerPreview';
import Stack from '@mui/material/Stack/Stack';
import WatchFormValue from '../../../../../components/Form/WatchField';
import FormGroupLabel from '../../../../../components/Form/FormGroupLabel';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';

const CodeBannerView = ({ prefix }) => {
  return (
    <ImagePreviewProvider>
      <LocaleTabs
        name={`${prefix}.locales`}
        content={(localeField, localeIndex) => (
          <Fragment key={localeField.locale.country + localeField.locale.lang}>
            <Stack direction={'column'} spacing={2}>
              <FormGroup large>
                <Grid container spacing={2} direction={'column'}>
                  <Grid item>
                    <FormGroupLabel
                      helpText={
                        'Insert code snippet of your custom promotion that you want to display'
                      }
                    >
                      Place your code
                    </FormGroupLabel>
                  </Grid>
                  <BannerCodeField
                    name={`${prefix}.locales.${localeIndex}.code`}
                  />
                </Grid>
              </FormGroup>

              <FormGroup large>
                <WatchFormValue
                  name={{ code: `${prefix}.locales.${localeIndex}.code` }}
                >
                  {({ code }) => (
                    <CodeBannerPreview code={code} width={304} height={296} />
                  )}
                </WatchFormValue>
              </FormGroup>
            </Stack>
          </Fragment>
        )}
      />
    </ImagePreviewProvider>
  );
};

export default CodeBannerView;
