import { useCallback, useMemo, useState } from 'react';
import useToggleList from './useToggleList';

const useTableExpandRows = (items, idKey, persistToggledOnListChange) => {
  const {
    toggledItems,
    setToggledItems,
    resetHidden,
    reset,
    toggle,
    toggleAll,
    someToggled,
    everyToggled,
  } = useToggleList(items, idKey, persistToggledOnListChange);

  const [rowHeight, setRowHeight] = useState({});

  const onExpand = useCallback(
    (id, expanded) => {
      setToggledItems(currentExpanded => {
        return {
          ...currentExpanded,
          [id]:
            typeof expanded === 'undefined' ? !currentExpanded[id] : expanded,
        };
      });
    },
    [setToggledItems]
  );

  const onChangeRowHeight = useCallback((id, index, height) => {
    setRowHeight(currentRowHeight => {
      return {
        ...currentRowHeight,
        [id]: height,
      };
    });
  }, []);

  return useMemo(() => {
    return {
      onExpand,
      expandedRows: toggledItems,
      onChangeRowHeight,
      rowHeight,
      expandAll: toggleAll,
      everyToggled,
      someToggled,
    };
  }, [
    someToggled,
    onExpand,
    toggledItems,
    rowHeight,
    onChangeRowHeight,
    everyToggled,
    toggleAll,
  ]);
};

export default useTableExpandRows;
