import { capitalize } from 'lodash';
import { useCallback } from 'react';
import DateField from '../../../components/Fields/DateField/DateField';
import EmptyField from '../../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../../components/Fields/LinkField/LinkField';
import StringField from '../../../components/Fields/StringField/StringField';
import Table from '../../../components/Table/Table';
import useTableColumnsReorder from '../../../hooks/useTableColumnsReorder';
import TemplateBrandsRow from './TemplateBrands/TemplateBrandsRow';

export const COLUMN = {
  name: 'name',
  childTemplates: 'childTemplates',
  type: 'type',
  brands: 'brands',
  updateBy: 'updatedBy',
  created: 'created',
  updated: 'updated',
};

export const columnsWidthConfig = {
  [COLUMN.name]: 0.75,
  [COLUMN.childTemplates]: 0.5,
  [COLUMN.type]: 0.5,
  [COLUMN.brands]: 0.5,
  [COLUMN.updateBy]: 1,
  [COLUMN.created]: 0.5,
  [COLUMN.updated]: 1,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.name,
  COLUMN.childTemplates,
  COLUMN.type,
  COLUMN.brands,
  COLUMN.updateBy,
  COLUMN.created,
  COLUMN.updated,
];

export const initialToggledColumns = [
  COLUMN.name,
  COLUMN.childTemplates,
  COLUMN.type,
  COLUMN.brands,
  COLUMN.updateBy,
  COLUMN.created,
  COLUMN.updated,
];

const TemplatesTable = ({ data, loading, error, rowActions }) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Name',
      key: COLUMN.name,
      render: rowData => {
        return rowData.name ? (
          <StringField title={rowData.name}>
            <LinkField
              href={`/line-settings/template/edit/${rowData.id}`}
              state={rowData}
            >
              {rowData.name}
            </LinkField>
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Child Templates',
      key: COLUMN.childTemplates,
      render: ({ childTemplates }) => {
        return childTemplates ? (
          <StringField>{childTemplates}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Type',
      key: COLUMN.type,
      render: ({ type }) => {
        return type ? (
          <StringField>{capitalize(type)}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Brands',
      key: COLUMN.brands,
      render: ({ brandsCount }) => {
        return brandsCount ? (
          <StringField title={brandsCount}>{brandsCount}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Updated by',
      key: COLUMN.updateBy,
      render: ({ updatedBy }) => {
        return updatedBy ? (
          <StringField title={updatedBy}>{updatedBy}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Created',
      key: COLUMN.created,
      render: ({ created }) => {
        return created ? (
          <StringField>
            <DateField date={created} format={'YYYY.MM.DD'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Updated',
      key: COLUMN.updated,
      render: ({ updated }) => {
        return updated ? (
          <StringField>
            <DateField date={updated} format={'YYYY.MM.DD, HH:mm:ss'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  //in v1 we cant see the template brands
  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      console.log(rowData);
      return <TemplateBrandsRow key={rowData.id} templateId={rowData.id} />;
    },
    []
  );

  const checkIfRowExpandable = rowData => !!rowData.brandsCount;

  return (
    <Table
      // expandable
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      error={error}
      rowActions={rowActions}
      // renderExpandedRow={renderExpandedRow}
      // checkIfRowExpandable={checkIfRowExpandable}
    />
  );
};

export default TemplatesTable;
