import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  initialPreset,
  useTreeSelectContext,
} from '../../../../../../../providers/TreeSelectProvider';
import getMarginPreset from '../../../../../../gql/sportbook-config/templates/queries/getMarginPreset';
import getMarginPresetNames from '../../../../../../gql/sportbook-config/templates/queries/getMarginPresetNames';
import TextField from '../../../../TextField';
import ApplyPreset from '../../../components/ApplyPreset';
import MenuCaption from '../../../components/MenuCaption';
import MenuTitle from '../../../components/MenuTitle';
import SelectContainer from '../../../components/SelectContainer';
import VirtualizedMenu from '../../../components/VirtualizedMenu';
import { getKeySelector } from '../../../utils/getKeySelector';
import { applyMarginPresets } from '../MarginTreeSelect';
import MarginSportOption from '../Options/MarginSportOption';

const MarginSportSelect = ({
  templateId,
  nodePath,
  setPath = () => {},
  selectKey,
  nodeIndex,
  // selectedId,
  options,
  onUndoButtonClick = () => {},
}) => {
  const selectedId = nodePath.sportId;

  const { getValues, setValue } = useFormContext();
  const { preset, setPreset, setChangedMarginItems } = useTreeSelectContext();

  const [switchedFrom, template] = getValues(['switchedFrom', 'template']);

  const lineType = 'prematch';

  const [displayOptions, setDisplayOptions] = useState(options);

  const onChange = useCallback(
    e => {
      const inputValue = e.target.value.toLowerCase();

      setDisplayOptions(
        options.filter(
          option => option && option.name.toLowerCase().includes(inputValue)
        )
      );
    },
    [setDisplayOptions, options]
  );

  const updateUndoButtons = useCallback(() => {
    preset.itemIds.forEach(value => {
      const key = getKeySelector({
        nodeSelector: {
          sportId: value,
        },
      });
      setChangedMarginItems(currentItems => ({
        ...currentItems,
        [key]: true,
      }));
    });
  }, [preset, setChangedMarginItems]);

  useEffect(() => {
    if (preset.idKey === 'sportId') {
      applyMarginPresets({
        preset,
        template,
        setValue,
        lineType,
        switchedFrom,
      });
      updateUndoButtons();
      setPreset(initialPreset);
    }
  }, [
    preset,
    template,
    setValue,
    setPreset,
    lineType,
    switchedFrom,
    updateUndoButtons,
  ]);

  return (
    <SelectContainer>
      <TextField
        style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
        label={`Search Sport`}
        onChange={onChange}
        clearButton={true}
      />
      <MenuCaption>
        <MenuTitle name={'Sports'} />
        <ApplyPreset
          templateType={'margin'}
          selectKey={selectKey}
          menuOptions={options}
          sportId={selectedId}
          idKey={'sportId'}
          getPresetNamesQuery={getMarginPresetNames}
          getPresetQuery={getMarginPreset}
        />
      </MenuCaption>
      <VirtualizedMenu
        rowCount={displayOptions.length}
        itemRender={({ measure, index }) => {
          const option = displayOptions[index];

          return (
            <MarginSportOption
              key={selectKey + '-' + option.sportId}
              templateId={templateId}
              selectKey={selectKey}
              nodePath={nodePath}
              setPath={setPath}
              nodeIndex={nodeIndex}
              selected={option.sportId === selectedId}
              itemValue={option}
              lineType={lineType}
              onUndoButtonClick={onUndoButtonClick}
            />
          );
        }}
      />
    </SelectContainer>
  );
};

export default MarginSportSelect;
