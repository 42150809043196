import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { isDefinedCrop } from '../BannerImageUploader/BannerImageUploader';

export const getInitialCrop = (cropSize, sourceSize) => {
  const { width: cropWidth, height: cropHeight } = cropSize;
  const { width: sourceWidth, height: sourceHeight } = sourceSize;

  const sourceAspect = sourceWidth / sourceHeight;
  const cropAspect = cropWidth / cropHeight;

  if (cropAspect === sourceAspect) {
    return {
      start_x: 0,
      start_y: 0,
      width: sourceWidth,
      height: sourceHeight,
    };
  }

  if (cropAspect > sourceAspect) {
    const height = (cropHeight * sourceWidth) / cropWidth;

    return {
      start_y: (sourceHeight - height) / 2,
      start_x: 0,
      width: sourceWidth,
      height,
    };
  } else {
    const width = (cropWidth * sourceHeight) / cropHeight;

    return {
      start_y: 0,
      start_x: (sourceWidth - width) / 2,
      height: sourceHeight,
      width,
    };
  }
};

const getPreviewCrop = (sourceSize, previewSize, crop) => {
  const { width: sourceWidth, height: sourceHeight } = sourceSize;
  const { width: previewWidth, height: previewHeight } = previewSize;
  const { width: cropWidth, height: cropHeight, start_x, start_y } = crop;

  const sourceAspect = sourceWidth / sourceHeight;
  const cropAspect = cropWidth / cropHeight;

  const previewToCropXRatio = previewWidth / cropWidth;
  const previewToCropYRation = previewHeight / cropHeight;

  const width = sourceWidth * previewToCropXRatio;
  const height = sourceHeight * previewToCropYRation;

  if (cropAspect === sourceAspect) {
    return {
      start_x: start_x * previewToCropXRatio,
      start_y: start_y * previewToCropYRation,
      width,
      height,
    };
  }

  if (cropAspect > sourceAspect) {
    return {
      start_x: start_x * previewToCropXRatio,
      start_y: start_y * previewToCropYRation,
      width,
    };
  } else {
    return {
      start_x: start_x * previewToCropXRatio,
      start_y: start_y * previewToCropYRation,
      height,
    };
  }
};

const getPreviewImgStyle = (sourceSize, previewSize, crop) => {
  const previewCrop = getPreviewCrop(sourceSize, previewSize, crop);

  return {
    marginTop: `${-previewCrop.start_y}px`,
    marginLeft: `${-previewCrop.start_x}px`,
    width: previewCrop.width && `${previewCrop.width}px`,
    height: previewCrop.height && `${previewCrop.height}px`,
  };
};

export const getHeroBannerFontSizes = previewSize => {
  const width = previewSize.width;

  if (width < 336) {
    return {
      title: 20,
      subtitle: 12,
      legal: 7,
    };
  } else if (width >= 336 && width < 1171) {
    return {
      title: 24,
      subtitle: 12,
      legal: 9,
    };
  } else {
    return {
      title: 36,
      subtitle: 16,
      legal: 12,
    };
  }
};

const useStyles = makeStyles({
  cropPreview: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '4px',
  },
  cropPreviewImg: {
    position: 'absolute',
  },
  cropPreviewComponent: {
    position: 'relative',

    '&>*': {
      maxWidth: '100%',
    },
  },
  bannerImagePreview: {},
});

const BannerImagePreview = ({
  fields,
  src,
  crop,
  onLoad: onLoadCb = () => {},
  Component,
  previewSize = {},
  cropSize = {},
  className = null,
}) => {
  const { watch } = useFormContext();
  const previewFields = watch(fields);
  const classes = useStyles();

  const imgRef = useRef();

  const sourceWidth = imgRef.current && imgRef.current.naturalWidth;
  const sourceHeight = imgRef.current && imgRef.current.naturalHeight;

  const sourceSize = useMemo(
    () => ({
      width: sourceWidth,
      height: sourceHeight,
    }),
    [sourceWidth, sourceHeight]
  );

  const onLoad = useCallback(() => {
    const sourceSize = {
      width: imgRef.current.naturalWidth,
      height: imgRef.current.naturalHeight,
    };

    if (!isDefinedCrop(crop)) {
      const initialCrop = getInitialCrop(cropSize, sourceSize);

      const previewImgStyle = getPreviewImgStyle(
        sourceSize,
        previewSize,
        initialCrop
      );

      imgRef.current.style.width = previewImgStyle.width;
      imgRef.current.style.height = previewImgStyle.height;
      imgRef.current.style.marginLeft = previewImgStyle.marginLeft;
      imgRef.current.style.marginTop = previewImgStyle.marginTop;
      imgRef.current.style.opacity = previewImgStyle.marginTop;
      onLoadCb(imgRef.current, initialCrop);
    } else {
      onLoadCb(imgRef.current, crop);
    }
  }, [onLoadCb, cropSize, previewSize, crop]);

  const previewProps = useMemo(() => {
    if (isDefinedCrop(crop) && sourceSize.width && sourceSize.height) {
      return getPreviewImgStyle(sourceSize, previewSize, crop);
    }

    return { width: '100%', filter: 'blur(22px)' };
  }, [crop, previewSize, sourceSize]);

  return (
    <div
      className={clsx(classes.cropPreview, className)}
      style={{
        width: '100%',
        maxWidth: previewSize.width,
        height: previewSize.height,
      }}
    >
      <img
        src={src}
        alt={'preview'}
        ref={imgRef}
        onLoad={onLoad}
        className={classes.cropPreviewImg}
        style={previewProps}
      />
      <div className={classes.cropPreviewComponent}>
        <Component fields={previewFields} previewSize={previewSize} />
      </div>
    </div>
  );
};

export default BannerImagePreview;
