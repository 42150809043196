import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { identity, omit, pickBy } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  initialNodePath,
  useTreeSelectContext,
} from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import UndoChangeButton from '../../../../../Button/UndoChangeButton';
import { SelectItem } from '../../../components/';
import { getKeySelector } from '../../../utils/getKeySelector';

const useStyles = makeStyles({
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
  },
});

const MarginTournamentOption = ({
  templateId,
  nodePath = [],
  setPath = () => {},
  nodeIndex,
  selected,
  itemValue,
  selectKey,
  lineType,
  onUndoButtonClick = () => {},
}) => {
  const classes = useStyles();

  const { getValues, setValue } = useFormContext();

  const nodeSelector = {
    sportId: nodePath.sportId,
    categoryId: nodePath.categoryId,
    tournamentId: itemValue.tournamentId,
  };

  const keySelector = getKeySelector({ nodeSelector });

  const {
    setNodePath,
    setData,
    setTreeSelectLoading,
    getMarginTemplateQuery,
    changedMarginItems: { [keySelector]: isMarketsChanged },
  } = useTreeSelectContext();

  //remove omit
  const onSelect = useCallback(() => {
    let path = structuredClone(nodePath);
    path.tournamentId = itemValue.tournamentId;
    Object.keys(path).forEach((key, index) => {
      if (index > nodeIndex) {
        path[key] = initialNodePath[key];
      }
    });
    setNodePath(currentPath => {
      return {
        ...currentPath,
        [lineType]: path,
      };
    });
    getMarginTemplateQuery({
      templateId,
      path: pickBy(omit(path, ['market']), identity),
    }).then(data => {
      const newData = withoutEmpty(data.data.data);
      setTreeSelectLoading(false);

      setData(currentData => {
        return {
          ...currentData,
          [lineType]: {
            ...currentData[lineType],
            marketsToNode: newData.marketsToNode,
            markets: newData.markets,
          },
        };
      });
    });
  }, [
    itemValue,
    nodeIndex,
    nodePath,
    lineType,
    setNodePath,
    getMarginTemplateQuery,
    setData,
    templateId,
    setTreeSelectLoading,
  ]);

  return (
    <SelectItem selected={selected}>
      <Box className={classes.optionContainer}>
        {/* <TournamentOption name={itemValue.name} onClick={onSelect} /> */}
        {isMarketsChanged && (
          <UndoChangeButton
            onClick={() => onUndoButtonClick({ nodeSelector, keySelector })}
          />
        )}
      </Box>
    </SelectItem>
  );
};

export default MarginTournamentOption;
