import { gql } from '@apollo/client';

// export const MARKET_GROUP_ENABLE_TYPE = gql`
//   fragment MarketGroupEnableType on MarketGroupEnable {
//     marketGroup {
//       marketId
//       name
//     }
//     status
//     priority
//     read_only
//   }
// `;

// ${MARKET_GROUP_ENABLE_TYPE}
export const COVERAGE_MARKET_NODE_TYPE = gql`
  fragment CoverageMarketNodeType on CoverageMarketNode {
    allMarkets {
      marketId
      specifier
      name
      status
      marketGroups
      cashOut {
        value
        disabled
      }
      priority
      readOnly
    }
    mainMarkets {
      marketId
      # specifier
      name
      priority
      readOnly
    }
    totalMarkets
    totalMainMarkets
    marketsParentInherit
    # marketGroups
  }
`;

export const COVERAGE_EVENT_NODE_TYPE = gql`
  fragment CoverageEventNodeType on CoverageEventNode {
    eventId
    name
    status
    priority
    scheduled
    readOnly
  }
`;

export const COVERAGE_TOURNAMENT_NODE_TYPE = gql`
  fragment CoverageTournamentNodeType on CoverageTournamentNode {
    sport {
      tournamentId
      categoryId
      name
      status
      priority
      total
      countryCode
      readOnly
    }
    category {
      tournamentId
      name
      status
      priority
      total
      countryCode
      readOnly
    }
  }
`;

export const COVERAGE_CATEGORY_NODE_TYPE = gql`
  fragment CoverageCategoryNodeType on CoverageCategoryNode {
    categoryId
    name
    countryCode
    status
    priority
    total
    readOnly
  }
`;

export const COVERAGE_SPORT_NODE_TYPE = gql`
  fragment CoverageSportNodeType on CoverageSportNode {
    sportId
    name
    status
    priority
    readOnly
    marketGroupPriorities
  }
`;

export const COVERAGE_TEMPLATE_NODE_TYPE = gql`
  ${COVERAGE_SPORT_NODE_TYPE}
  ${COVERAGE_CATEGORY_NODE_TYPE}
  ${COVERAGE_TOURNAMENT_NODE_TYPE}
  ${COVERAGE_MARKET_NODE_TYPE}
  ${COVERAGE_EVENT_NODE_TYPE}
  fragment CoverageTemplateNodesType on CoverageTemplateNodes {
    marketsToNode
    sports {
      ...CoverageSportNodeType
    }
    categories {
      ...CoverageCategoryNodeType
    }
    tournaments {
      ...CoverageTournamentNodeType
    }
    events {
      ...CoverageEventNodeType
    }
    markets {
      ...CoverageMarketNodeType
    }
  }
`;

const getCoverageTemplate = gql`
  ${COVERAGE_TEMPLATE_NODE_TYPE}
  query getCoverageTemplate(
    $path: NodeSelectorInput
    $templateId: TemplateId
    $lineType: LineType!
  ) {
    data: getCoverageTemplate(
      path: $path
      templateId: $templateId
      lineType: $lineType
    ) {
      ...CoverageTemplateNodesType
    }
  }
`;

export default getCoverageTemplate;
