import React, { forwardRef } from 'react';
import DateTimeInput from './DateTimeInput';
import {
  getValueForTimePicker,
  getValueFromTimePicker,
} from '../../../utils/datePickerUtils';

const DATETIME_FORMAT = 'dd.MM.yyyy, HH:mm';

const DateTimeUtcInput = forwardRef(
  ({ form, value, onChange, ...props }, ref) => {
    return (
      <DateTimeInput
        {...props}
        margin="dense"
        formatDate={DATETIME_FORMAT}
        value={getValueForTimePicker(value)}
        onChange={v => {
          onChange(getValueFromTimePicker(v, DATETIME_FORMAT));
        }}
      />
    );
  }
);

export default DateTimeUtcInput;
