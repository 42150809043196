import { gql } from '@apollo/client';

const getMarginTemplatesDiff = gql`
  query getMarginTemplatesDiff(
    $limit: Int!
    $offset: Int!
    $searchQuery: String
    $templateId: TemplateId!
  ) {
    data: getMarginTemplatesDiff(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      templateId: $templateId
    ) {
      items {
        sport {
          name
          id
        }
        tournament {
          name
          id
        }
        market {
          name
          id
        }
        prematch
        live
        user
        lastUpdated
      }
      parent_template {
        id
        name
      }
      total
    }
  }
`;

export default getMarginTemplatesDiff;
