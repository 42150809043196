import React, { useState, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Select from '../Inputs/Select/Select';
import { Typography } from '@mui/material';
import useFeatureBranches from '../../hooks/useFeatureBranches';

const useStyles = makeStyles(theme => ({
  featureBranchInput: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  showSelectToggle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#1581FF',
    padding: '8px 0',
    marginBottom: 4,
    cursor: 'pointer',
    alignSelf: 'flex-end',
  },
  branchSelect: {
    marginBottom: 12,
  },
}));

const FeatureBranchInput = ({ className }) => {
  const classes = useStyles();

  const { feBranches, currentFeatureBranch } = useFeatureBranches();
  const [isShowBranchSelect, setShowBranchSelect] = useState(false);
  const [value, setValue] = useState(currentFeatureBranch);

  const options = useMemo(() => {
    return [
      { label: 'develop', value: 'develop' },
      ...feBranches.map(b => ({ label: b, value: b.toLowerCase() })),
    ];
  }, [feBranches]);

  const onBranchSelect = v => {
    if (v === value) return;

    setValue(v);
    const { pathname, search } = window.location;
    if (v === 'develop') {
      window.location.href = `https://backoffice-dev.sptenv.com${pathname}${search}`;
    } else {
      window.location.href = `https://backoffice-dev-${v}.dyn.sptenv.com${pathname}${search}`;
    }
  };

  return (
    <div className={clsx(classes.featureBranchInput, className)}>
      <Typography
        className={classes.showSelectToggle}
        onClick={() => setShowBranchSelect(!isShowBranchSelect)}
      >
        {isShowBranchSelect ? 'Hide' : 'Change feature branch'}
      </Typography>
      {isShowBranchSelect && (
        <Select
          onChange={onBranchSelect}
          value={value}
          initialValue={currentFeatureBranch}
          label={'Select feature branch'}
          options={options}
          className={classes.branchSelect}
          withTooltip
        />
      )}
    </div>
  );
};

export default FeatureBranchInput;
