import Checkbox from '../Checkbox';
import { FancySelectOption } from '../FancySelect/FancySelect';

const FancyAutocompleteAllOption = ({ label = 'All', onToggle, toggled }) => {
  return (
    <FancySelectOption onClick={onToggle}>
      {label}{' '}
      <div
        style={{
          marginLeft: 'auto',
        }}
      >
        <Checkbox value={toggled} />
      </div>
    </FancySelectOption>
  );
};

export default FancyAutocompleteAllOption;
