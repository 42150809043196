import makeStyles from '@mui/styles/makeStyles';
import AlertIcon from './AlertIcon';
import AlertCloseIcon from './AlertCloseIcon';
import { useEffect, useState } from 'react';
import useTimeout from '../../hooks/useTimeout';

const useStyles = makeStyles(() => ({
  notificationAlert: {
    backgroundColor: '#fff',
    boxShadow: `0px 1px 4px rgba(132, 150, 171, 0.25), 0px 0px 8px rgba(132, 150, 171, 0.16)`,
    borderRadius: 8,
    padding: 12,
    marginTop: '-40px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    lineHeight: '16px',
    maxWidth: 400,
  },
  alertIcon: {
    minWidth: 16,
    marginRight: 8,
  },
  closeIcon: {
    marginTop: 4,
    transform: `rotate(-90deg)`,
    cursor: 'pointer',
    marginLeft: 8,
  },

  alertContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
}));

export const AlertVariant = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const NotificationAlert = ({
  timeout = 10000,
  variant,
  closable = true,
  onRemove,
  children,
}) => {
  const classes = useStyles();

  const [progress, setProgress] = useState(100);

  useEffect(() => {
    setProgress(0);
  }, []);

  useTimeout(onRemove, timeout);

  return (
    <div className={classes.notificationAlert}>
      {variant && <AlertIcon variant={variant} className={classes.alertIcon} />}

      <div className={classes.alertContent}>{children}</div>

      {closable && (
        <AlertCloseIcon
          className={classes.closeIcon}
          progress={progress}
          timeout={timeout}
          onClick={onRemove}
        />
      )}
    </div>
  );
};

export default NotificationAlert;
