import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import copyToClipboard from 'copy-to-clipboard';
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import InputClearAdornment from '../../../components/inputs/InputClearAdornment';
import CopyIcon from '../../../icons/controls/CopyIcon';
import HideIcon from '../../../icons/controls/HideIcon';
import SmallCrossIcon from '../../../icons/controls/SmallCrossIcon';
import { useToasts } from '../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../Notifications/NotificationAlert';
import Field from './Field';
import SearchTextMatchNavigator from './SearchTextMatchNavigator';
import SearchIcon from '../../icons/SearchIcon';

const useStyles = makeStyles(theme => ({
  input: {
    overflow: 'hidden',
    '&>input': {
      textOverflow: 'ellipsis',
    },
  },
  textFieldInputClass: {
    appearance: 'textfield',
  },
  textFieldContainer: {
    position: 'relative',
  },
  textFieldRoot: {
    // maxWidth: 400,
  },
  numberFieldRoot: {
    width: 80,
  },
  textFieldRootMultiline: {
    maxWidth: 'initial',
    height: 'initial',
  },
  textFieldRemove: {
    position: 'absolute',
    right: -8,
    top: -8,
    color: '#8496AB',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  textFieldMatchNavigator: {
    position: 'absolute',
    right: 24,
    top: 8,
  },
  textFieldCopy: {
    cursor: 'pointer',
    position: 'absolute',
    right: 8,
    top: 8,
    width: 24,
    height: 24,
    color: '#8496AB',
    transition: 'color 0.2s ease-in',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  textFieldEndAdornmentText: {
    fontFamily: `'B612 Mono', monospace`,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: theme.palette.grey.main,
    paddingLeft: 4,
    paddingRight: 12,
    whiteSpace: 'nowrap',
  },
  textFieldEndAdornmentTextWithClear: {
    marginRight: 24,
  },
  textFieldEndAdornmentTextMultiline: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    position: 'absolute',
    right: 12,
    marginTop: -23,
    color: theme.palette.grey.main,
    backgroundColor: '#F7F7F7',
    paddingLeft: 4,
    borderTopLeftRadius: 4,
  },

  textFieldResetToDefault: {
    position: 'absolute',
    right: '4px',
    top: '4px',
  },

  inputLabelText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  formHelperTextRoot: {
    marginLeft: '8px 0 0 10px',
    fontWeight: 500,
    fontSize: '11px',
    letterSpacing: '0.5px',
    color: '#8496AB',
  },
  textInputWithLimit: {
    paddingBottom: '16px!important',
  },
  searchIcon: {
    position: 'absolute',
    right: 16,
    top: 'calc(50% - 6px)',
    height: 12,
    width: 12,
  },
}));

const TextField = forwardRef(
  (
    {
      multiline,
      maxLength,
      onRemove,
      error,
      helperText,
      required,
      disabled = false,
      clearButton = false,
      onChange,
      value,
      defaultValue,
      type,
      onClear,
      copy,
      endAdornmentText,
      highlightSearch,
      matchCount,
      searchSelectedIndex,
      setSearchSelectedIndex,
      withResetToDefault,
      onResetToDefault,
      extraClasses = {},
      shrinkable = false,
      popupIconComponent,
      withSearchIcon = false,
      ...props
    },
    ref
  ) => {
    const inputRef = props.inputProps?.ref || useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const classes = useStyles();
    const { showToast } = useToasts();

    const isShowClearButton = clearButton && !!value;
    const onCopy = useCallback(() => {
      copyToClipboard(value);
      showToast(
        <NotificationAlert variant={AlertVariant.SUCCESS} timeout={1500}>
          Copied to clipboard
        </NotificationAlert>
      );
    }, [value, showToast]);

    const limitLabel = useMemo(() => {
      return maxLength && `${(value && value.length) || 0}/${maxLength}`;
    }, [maxLength, value]);

    return (
      <Field className={clsx(classes.textFieldContainer, props.className)}>
        <MuiTextField
          disabled={disabled}
          FormHelperTextProps={{
            classes: {
              root: classes.formHelperTextRoot,
            },
          }}
          classes={{
            root: clsx(classes.textFieldRoot, extraClasses?.root, {
              [classes.textFieldRootMultiline]: multiline,
            }),
          }}
          inputProps={{
            ref: inputRef,
            maxLength,
            style: props.inputStyle,
          }}
          InputProps={{
            classes: {
              input: clsx(classes.textFieldInputClass, extraClasses?.input),
              root: clsx(classes.input, {
                [classes.textInputWithLimit]: multiline && maxLength,
              }),
            },
            endAdornment: (
              <>
                {endAdornmentText && (
                  <div
                    className={clsx(classes.textFieldEndAdornmentText, {
                      [classes.textFieldEndAdornmentTextWithClear]:
                        isShowClearButton,
                    })}
                  >
                    {endAdornmentText}
                  </div>
                )}
                {isShowClearButton && !disabled && (
                  <InputClearAdornment
                    onClick={onClear ? onClear : () => onChange('')}
                  />
                )}
                {maxLength && (
                  <div className={classes.textFieldEndAdornmentText}>
                    {limitLabel}
                  </div>
                )}
                {popupIconComponent && !isShowClearButton ? (
                  popupIconComponent
                ) : (
                  <></>
                )}
              </>
            ),
          }}
          multiline={multiline}
          error={error}
          helperText={helperText}
          required={required}
          onChange={e => {
            if (onChange) {
              onChange(e);
            }
          }}
          value={value}
          type={type}
          defaultValue={defaultValue}
          fullWidth
          {...props}
          InputLabelProps={{
            style: {
              overflow: 'hidden',
            },
            shrink: isFocused || !!value || !!inputRef.current?.value,
          }}
          //rewrite props value to correctly control input focus
          onFocus={e => {
            props.onFocus && props.onFocus(e);

            setIsFocused(true);
          }}
          onBlur={e => {
            props.onBlur && props.onBlur(e);

            setIsFocused(false);
          }}
        />

        {maxLength && multiline && (
          <div className={classes.textFieldEndAdornmentTextMultiline}>
            {limitLabel}
          </div>
        )}

        {onRemove && (
          <div className={classes.textFieldRemove} onClick={onRemove}>
            <HideIcon />
          </div>
        )}

        {copy && value !== '' && (
          <div className={classes.textFieldCopy} onClick={onCopy}>
            <CopyIcon />
          </div>
        )}
        {highlightSearch && (
          <div className={classes.textFieldMatchNavigator}>
            <SearchTextMatchNavigator
              matchCount={matchCount || 0}
              searchSelectedIndex={searchSelectedIndex}
              setSearchSelectedIndex={setSearchSelectedIndex}
            />
          </div>
        )}

        {withResetToDefault && value !== defaultValue && (
          <div
            className={classes.textFieldResetToDefault}
            onClick={() => onResetToDefault(ref && ref.current)}
          >
            <SmallCrossIcon />
          </div>
        )}

        {withSearchIcon && !value && (
          <SearchIcon className={classes.searchIcon} />
        )}
      </Field>
    );
  }
);

export default TextField;
