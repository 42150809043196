import makeStyles from '@mui/styles/makeStyles';

export const useAppStyles = makeStyles({
  '@global': {
    html: {
      height: '100%',
      boxSizing: 'border-box',
    },
    mark: {
      fontWeight: 700,
      backgroundColor: 'transparent',
      color: '#1581FF',
    },
    body: {
      height: '100%',
      margin: 0,
      padding: 0,
      letterSpacing: '0.2px',
      fontFamily: 'Inter',
      fontFeatureSettings: 'tnum',
      fontVariantNumeric: 'tabular-nums',
      color: '#031224',
      fontSize: '14px',
      fontWeight: '400',
      backgroundColor: '#F7F7F7',
    },
    '#app-root': {
      height: '100%',
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      overflowX: 'clip',
    },
    'input:-webkit-autofill': {
      backgroundColor: '#f7f7f7',
      boxShadow: '0 0 0 30px #F7F7F7 inset !important',
    },
  },
});

export const AppStyles = ({ children }) => {
  useAppStyles();
  return children;
};
