import VirtualizedTable from '../../components/Table/VirtualizedTable';
import StringField from '../../components/Fields/StringField/StringField';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import { useTableBulkContext } from '../../providers/TableBulkProvider';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import useTableColumns from '../../hooks/useTableColumns';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import ExpandableTable from '../../components/Table/ExpandableTable';
import { useCallback, useState } from 'react';
import TournamentsConfigTable from './TournamentConfigTable';

const commonCellStyle = {
  padding: '0px 16px',
};

export const COLUMN = {
  CATEGORY: 'category',
  ID: 'id',
  SPORT: 'sport',
  TOURNAMENT: 'tournament',
  RM_RULES: 'rules_count',
};

export const columnsWidthConfig = {
  [COLUMN.ID]: 1,
  [COLUMN.SPORT]: '150px',
  [COLUMN.TOURNAMENT]: 1,
  [COLUMN.CATEGORY]: '200px',
  [COLUMN.RM_RULES]: '150px',
};

export const columnsOrderConfig = [
  COLUMN.ID,
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.RM_RULES,
];

export const initialToggledColumns = [
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.RM_RULES,
];

const TournamentsTable = ({
  onToggleColumns,
  toggledColumns: initialToggledColumns,
  order,
  onReorder,
  data,
  loading,
  rowActions,
  tableOffsetTop,
}) => {
  const bulk = useTableBulkContext();
  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns,
    onToggleColumns
  );
  const [expandedItems, setExpandedItems] = useState([]);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order,
    toggledColumns,
    bulk: !!bulk,
    rowActions: true,
    expandable: true,
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'Id',
        key: COLUMN.ID,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.ID]}</StringField>;
        },
      },
      {
        label: 'Sport',
        key: COLUMN.SPORT,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.SPORT]}</StringField>;
        },
      },
      {
        label: 'Category',
        key: COLUMN.CATEGORY,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.CATEGORY]}</StringField>;
        },
      },
      {
        label: 'Tournament',
        key: COLUMN.TOURNAMENT,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.TOURNAMENT]}</StringField>;
        },
      },
      {
        label: 'RM Rules',
        key: COLUMN.RM_RULES,
        style: {
          ...commonCellStyle,
          justifyContent: 'center',
          textAlign: 'center',
        },
        render: data => {
          return <StringField>{data[COLUMN.RM_RULES]}</StringField>;
        },
      },
    ],
    order,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    rowActions,
    bulk,
  });

  const onExpand = useCallback(
    tournamentId => {
      if (expandedItems.includes(tournamentId)) {
        setExpandedItems(expandedItems.filter(id => id !== tournamentId));
      } else {
        setExpandedItems([...expandedItems, tournamentId]);
      }
    },
    [expandedItems, setExpandedItems]
  );

  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      return (
        <TournamentsConfigTable
          onHeightChanged={onHeightChanged}
          key={rowData[COLUMN.ID]}
          tournamentId={rowData[COLUMN.ID]}
          onExpand={onExpand}
          isInitialExpanded={expandedItems.includes(rowData[COLUMN.ID])}
        />
      );
    },
    [onExpand, expandedItems]
  );

  return (
    <ExpandableTable
      expandAll
      renderExpandedRow={renderExpandedRow}
      id={COLUMN.ID}
      loading={loading}
      rowActions={rowActions}
      renderHeaderRow={
        <TableHeaderRow
          sticky
          bulk={bulk}
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorder}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
          onChangeColumnWidth={changeColumnWidth}
        />
      }
      renderRow={renderRow}
      data={data}
      tableOffsetTop={tableOffsetTop}
    >
      <VirtualizedTable />
    </ExpandableTable>
  );
};

export default TournamentsTable;
