import { useMemo } from 'react';

const useTableColumns = ({ columns, order, toggledColumns }) => {
  const availableColumns = useMemo(
    () =>
      columns &&
      [...columns].sort((a, b) => {
        const aIndex = order.indexOf(a.key);
        const bIndex = order.indexOf(b.key);

        if (aIndex < bIndex) {
          return -1;
        }

        if (aIndex > bIndex) {
          return 1;
        }

        return 0;
      }),
    [columns, order]
  );

  const visibleColumns = useMemo(
    () =>
      toggledColumns
        ? availableColumns.filter(
            column => toggledColumns.indexOf(column.key) !== -1
          )
        : availableColumns,
    [availableColumns, toggledColumns]
  );

  return useMemo(
    () => ({
      availableColumns,
      visibleColumns,
    }),
    [visibleColumns, availableColumns]
  );
};

export default useTableColumns;
