import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  skeletonGroupItem: {
    display: 'flex',
    '&+$skeletonGroupItem': {
      marginTop: 32,
    },
  },

  skeletonItem: {
    borderRadius: 2,
    maxWidth: 'initial',
    height: 16,
    '&:last-child': {
      marginLeft: 'auto',
    },
  },
});

const TransferListSkeletonGroupItem = React.memo(({ width = 180 }) => {
  const classes = useStyles();
  const randomWidth = Math.random() * width + width / 2;

  return (
    <div className={classes.skeletonGroupItem}>
      <Skeleton
        className={classes.skeletonItem}
        animation="wave"
        variant="rectangular"
        style={{ width: randomWidth }}
      >
        &zwnj;
      </Skeleton>
      <Skeleton
        className={classes.skeletonItem}
        animation="wave"
        variant="circular"
        style={{ width: 16 }}
      >
        &zwnj;
      </Skeleton>
    </div>
  );
});

export default TransferListSkeletonGroupItem;
