import { gql } from '@apollo/client';

const getLineSettingsTable = gql`
  query getLineSettingsTable(
    $limit: Int!
    $offset: Int!
    $brandIds: [BrandId]
  ) {
    data: getLineSettingsTable(
      brandIds: $brandIds
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        brandIds
        name
        brandsCount
        updatedBy
        created
        updated
        margin {
          id
          name
        }
        coverage {
          id
          name
        }
        sources {
          id
          name
        }
        version
      }
      total
    }
  }
`;

export default getLineSettingsTable;
