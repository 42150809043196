import { gql } from '@apollo/client';

const getTournamentLangs = gql`
  query getTournamentLangs(
    $categoryId: CategoryId!
    $tournamentId: TournamentId!
  ) {
    getTournamentLangs(categoryId: $categoryId, tournamentId: $tournamentId) {
      langs
      id
    }
  }
`;

export default getTournamentLangs;
