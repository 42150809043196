import React, { useContext } from 'react';
import { getShallowAndNested } from '../../utils/schemaHelper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SchemaContext } from '../../providers/SchemaProvider';
import StaticFieldListProvider from '../../providers/StaticFieldListProvider';
import { RoutesContext } from '../../providers/RoutesProvider';
import makeStyles from '@mui/styles/makeStyles';
import { useUiComponentSchema } from '../../providers/UiComponentSchemaProvider';
import NestedFields from './NestedFields';
import LabeledField from './LabeledField';
import { getTableColumns } from '../Table/TableUtils';
import { DataContext } from '../../providers/DataProvider';

const useStyles = makeStyles(theme => ({
  fieldsetRoot: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    // borderTopRightRadius: 0,
    // borderTopLeftRadius: 0,
  },
  nestedFields: {
    paddingTop: theme.spacing(3),
  },
}));

const FieldList = ({ parentPath = [], links = {} }) => {
  const classes = useStyles();

  const routes = useContext(RoutesContext);
  const schema = useContext(SchemaContext);
  const uiSchema = useUiComponentSchema();
  const { data, loading } = useContext(DataContext);

  const parentEntity = data;

  const { title, properties, additionalProperties, description } = schema;
  let { nested } = getShallowAndNested({
    properties,
    additionalProperties,
    entity: data,
  });

  const fields = getTableColumns(schema, uiSchema, data);

  return (
    <StaticFieldListProvider>
      <Paper className={classes.fieldsetRoot}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {description}
        </Typography>
        <Grid container spacing={2}>
          {fields.map(field => (
            <Grid key={field.name} item xs={12} sm={6} md={4} lg={3}>
              <LabeledField
                loading={loading}
                value={data && data[field.name]}
                field={field}
              />
            </Grid>
          ))}
        </Grid>
        {!loading && !!Object.keys(nested).length && (
          <div className={classes.nestedFields}>
            <NestedFields
              entity={data}
              nested={nested}
              uiSchema={uiSchema}
              links={links}
              routes={routes}
              parentPath={parentPath}
              parentEntity={parentEntity}
            />
          </div>
        )}
      </Paper>
    </StaticFieldListProvider>
  );
};

export default FieldList;
