import dayjs from 'dayjs';
import StringField from '../../components/Fields/StringField/StringField';
import EmptyField from '../../components/Fields/EmptyField/EmptyField';
import Table from '../../components/Table/Table';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import DateField from '../../components/Fields/DateField/DateField';

export const COLUMN = {
  id: 'id',
  requestId: 'requestId',
  statusCode: 'statusCode',
  respBody: 'respBody',
  timestamp: 'timestamp',
  url: 'url',
  error: 'error',
};

export const columnsWidthConfig = {
  [COLUMN.id]: 0.525,
  [COLUMN.requestId]: 0.525,
  [COLUMN.statusCode]: 0.3,
  [COLUMN.respBody]: 1,
  [COLUMN.timestamp]: 0.45,
  [COLUMN.url]: 1.45,
  [COLUMN.error]: 0.55,
};

export const minColumnsWidthConfig = {
  [COLUMN.id]: '120px',
  [COLUMN.requestId]: '120px',
  [COLUMN.statusCode]: '110px',
  [COLUMN.respBody]: '300px',
  [COLUMN.timestamp]: '125px',
  [COLUMN.url]: '330px',
  [COLUMN.error]: '120px',
};

export const initialToggledColumns = [
  COLUMN.id,
  COLUMN.requestId,
  COLUMN.statusCode,
  COLUMN.respBody,
  COLUMN.timestamp,
  COLUMN.url,
  COLUMN.error,
];

export const columnsOrderConfig = [
  COLUMN.id,
  COLUMN.requestId,
  COLUMN.statusCode,
  COLUMN.respBody,
  COLUMN.timestamp,
  COLUMN.url,
  COLUMN.error,
];

const columns = [
  {
    label: 'Id',
    key: COLUMN.id,
    render: ({ id }) => {
      return id ? <StringField>{id}</StringField> : <EmptyField />;
    },
  },
  {
    label: 'Request Id',
    key: COLUMN.requestId,
    render: ({ request_id }) => {
      return request_id ? (
        <StringField>{request_id}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Status Code',
    key: COLUMN.statusCode,
    render: ({ status_code }) => {
      return status_code ? (
        <StringField>{status_code}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Resp Body',
    key: COLUMN.respBody,
    render: ({ resp_body }) => {
      return resp_body ? (
        <StringField title={resp_body}>{resp_body}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Date',
    key: COLUMN.timestamp,
    render: ({ timestamp }) => {
      return timestamp ? (
        <StringField>
          <DateField date={timestamp} format={'YYYY/MM/DD HH:mm:ss'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Url',
    key: COLUMN.url,
    render: ({ url }) => {
      return url ? <StringField>{url}</StringField> : <EmptyField />;
    },
  },
  {
    label: 'Error',
    key: COLUMN.error,
    render: ({ error }) => {
      return error ? <StringField>{error}</StringField> : <EmptyField />;
    },
  },
];

const FailedRequestAttemptsTable = ({
  initialToggledColumns,
  data,
  loading,
  onToggleColumns,
  onHeightChanged,
}) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  return (
    <Table
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      columnsWidth={columnsWidthConfig}
      order={order}
      onReorder={onReorder}
      columns={columns}
      minWidthConfig={minColumnsWidthConfig}
      onToggleColumns={onToggleColumns}
      rowActions={false}
      scrollable
    />
  );
};

export default FailedRequestAttemptsTable;
