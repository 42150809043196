import FormField from '../../../../components/Form/FormField';
import Grid from '@mui/material/Grid/Grid';
import CodeInput from '../../../../../components/inputs/CodeInput';

const BannerCodeField = ({ name }) => {
  return (
    <Grid item xs={12}>
      <FormField name={name}>
        <CodeInput />
      </FormField>
    </Grid>
  );
};

export default BannerCodeField;
