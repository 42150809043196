import { TEMPLATE_TYPE } from '../components/TemplateForm';

export const getSubmitMutation = (templateType, sources, coverage, margin) => {
  switch (templateType) {
    case TEMPLATE_TYPE.SOURCES: {
      return sources;
    }
    case TEMPLATE_TYPE.COVERAGE: {
      return coverage;
    }
    case TEMPLATE_TYPE.MARGIN: {
      return margin;
    }
  }
};
