import { identity, isEmpty, isEqual, omit, pickBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  initialPreset,
  useTreeSelectContext,
} from '../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import {
  LINE_TYPES,
  SELECT_NODE_TYPES,
  TEMPLATE_CONFIG_NODES,
} from '../../../../../pages/LineSettingsPage/FormPages/Template/components/TemplateConfigurationField';
import Loader from '../../components/Loader';
import TreeSelectContainer from '../../components/TreeSelectContainer';
import { getKeySelector } from '../../utils/getKeySelector';
import MarginCategorySelect from './Selects/MarginCategorySelect';
import MarginMarketSelect from './Selects/MarginMarketSelect';
import MarginSportSelect from './Selects/MarginSportSelect';
import MarginTournamentSelect from './Selects/MarginTournamentSelect';

export const getMarketChangesForItem = ({ selector, template }) => {
  return Object.values(template).reduce((acc, currValue) => {
    if (isEqual(omit(currValue.nodeSelector, ['market']), selector)) {
      return [...acc, currValue];
    }
    return acc;
  }, []);
};

//to removes market in path if marketId null
const pathPredicate = (value, key) => {
  return key === 'market' ? !!value.marketId : !!value;
};

//resolve received presets to array of config (in template form change format)
export const applyMarginPresets = ({
  preset,
  template,
  lineType,
  setValue,
  pathToSelect = {},
}) => {
  if (!isEqual(preset, initialPreset)) {
    const commands = preset.commands;
    const config = commands.reduce((acc, value) => {
      preset.itemIds.forEach(id => {
        //in case preset mast to be applied for each selected item in apply modal
        //then we creates selector to markets for all chosen items
        const nodeSelector = pickBy(
          {
            ...pathToSelect,
            [preset.idKey]: id,
            market: {
              marketId: value.nodeSelector.market.marketId,
            },
          },
          pathPredicate
        );
        const key = getKeySelector({ nodeSelector });
        const command = {
          lineType,
          nodeSelector,
          prematchSameAsLiveFrom: true,
          liveMargin: {
            value: value.live,
          },
          prematchMargin: {
            value: value.prematch,
          },
        };
        acc[key] = command;
      });
      return acc;
    }, {});

    setValue('template', { ...template, ...config });
  }
};

const MarginTreeSelect = ({ templateId }) => {
  const lineType = 'prematch';

  const selectNames = [
    TEMPLATE_CONFIG_NODES.SPORT,
    TEMPLATE_CONFIG_NODES.CATEGORY,
    TEMPLATE_CONFIG_NODES.TOURNAMENT,
    TEMPLATE_CONFIG_NODES.EVENT,
    TEMPLATE_CONFIG_NODES.MARKET,
  ];

  const selectComponents = {
    [TEMPLATE_CONFIG_NODES.SPORT]: MarginSportSelect,
    [TEMPLATE_CONFIG_NODES.CATEGORY]: MarginCategorySelect,
    [TEMPLATE_CONFIG_NODES.TOURNAMENT]: MarginTournamentSelect,
    [TEMPLATE_CONFIG_NODES.MARKET]: MarginMarketSelect,
  };

  const { getValues, setValue } = useFormContext();

  const switchedFrom = getValues('switchedFrom');

  const template = getValues('template');

  const {
    nodePath: { [lineType]: nodePath },
    setNodePath,
    data: { [lineType]: data },
    setData,
    treeSelectLoading,
    setTreeSelectLoading,
    getMarginTemplateQuery,
    setChangedMarginItems,
  } = useTreeSelectContext();

  const variables = useMemo(() => {
    return nodePath.sportId
      ? {
          templateId,
          path: pickBy(
            {
              sportId: nodePath.sportId,
              categoryId: nodePath.categoryId,
              tournamentId: nodePath.tournamentId,
              eventId: nodePath.eventId,
            },
            identity
          ),
        }
      : {
          templateId,
        };
  }, [nodePath, templateId]);

  //finds remove in template configs for passed item
  //also set false flag for undo button showing
  const onUndoButtonClick = useCallback(
    ({ nodeSelector, keySelector }) => {
      setChangedMarginItems(currentItems => ({
        ...currentItems,
        [keySelector]: false,
      }));
      //check template selector without market value, to choose market 'parent' selector
      const newConfig = Object.keys(template).reduce((acc, currValue) => {
        const templateValue = template[currValue];
        if (
          !isEqual(omit(templateValue.nodeSelector, ['market']), nodeSelector)
        ) {
          acc[currValue] = template[currValue];
        }
        return acc;
      }, {});
      setValue('template', newConfig);
    },
    [setChangedMarginItems, setValue, template]
  );

  useEffect(() => {
    setNodePath(currentState => {
      if (lineType === LINE_TYPES.all) {
        return {
          ...currentState,
          all: currentState[switchedFrom],
        };
      } else if (
        switchedFrom === LINE_TYPES.all &&
        !isEmpty(currentState.all)
      ) {
        return {
          live: currentState.all,
          prematch: currentState.all,
          all: {},
        };
      }
      return currentState;
    });
  }, [lineType, setNodePath, switchedFrom]);

  useEffect(() => {
    isEmpty(data) &&
      getMarginTemplateQuery(variables).then(data => {
        const newData = withoutEmpty(data.data.data);
        setTreeSelectLoading(false);

        setData(currentData => ({
          ...currentData,
          [lineType]: { ...newData },
        }));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <TreeSelectContainer>
      {treeSelectLoading && <Loader onTopOfContent />}
      {selectNames.map((value, index) => {
        if (data[value] && !isEmpty(nodePath)) {
          const selectOptions = data[value];
          const TreeSelectElement = selectComponents[value];

          //this used to change key only after changes data for this menu
          const selectOptionsJson = JSON.stringify(selectOptions);

          //markets have to change key also every time when changed nodePath
          const uniqKey =
            value === TEMPLATE_CONFIG_NODES.MARKET
              ? selectOptionsJson + JSON.stringify(nodePath)
              : selectOptionsJson;

          return (
            <TreeSelectElement
              key={uniqKey}
              templateId={templateId}
              options={selectOptions}
              nodePath={nodePath}
              selectKey={value}
              selectNodeType={SELECT_NODE_TYPES[value]}
              nodeIndex={index}
              marketType={data.marketsToNode}
              onUndoButtonClick={onUndoButtonClick}
            />
          );
        }
      })}
    </TreeSelectContainer>
  );
};

export default MarginTreeSelect;
