import React from 'react';

import useDialog from '../hooks/useDialog';
import Dialog from '../components/Dialog';

const DialogProvider = () => {
  const {
    data: { component: Component, options, props },
  } = useDialog();

  return Component && options ? (
    <>
      <Dialog {...options}>
        <Component {...props} />
      </Dialog>
    </>
  ) : null;
};

export default DialogProvider;
