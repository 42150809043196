import { gql } from '@apollo/client';

const TOURNAMENTS_QUERY = gql`
  query getRmTournaments($input: RMTournamentsFilters!) {
    data: getRmTournaments(input: $input) {
      total
      items {
        id
        sport
        category
        tournament
        rules_count
      }
    }
  }
`;

export default TOURNAMENTS_QUERY;
