import makeStyles from '@mui/styles/makeStyles';
import EventPrematchIconSvg from '../../v2/icons/EventPrematchIcon.svg';

const useStyles = makeStyles({
  prematchIcon: {
    backgroundImage: `url(${EventPrematchIconSvg})`,
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },
});

const EventPrematchIcon = ({ ...props }) => {
  const classes = useStyles();
  return <div {...props} className={classes.prematchIcon} />;
};

export default EventPrematchIcon;
