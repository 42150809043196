import { gql } from '@apollo/client';
import mergerGroupFragment from '../fragments/mergerGroupFragment';

const unmergeGroupItem = gql`
  ${mergerGroupFragment}
  mutation unmergeGroupItem($inputGroupItem: GroupItemToUnmerge!) {
    unmergeGroupItem(inputGroupItem: $inputGroupItem) {
      ...MergerGroupFragment
    }
  }
`;

export default unmergeGroupItem;
