import Box from '@mui/material/Box';
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import throttle from 'lodash/throttle';
import { animated } from 'react-spring';
import clsx from 'clsx';
import WithMeasure from '../../hoc/WithMeasure';
import SortIcon from '../../../icons/controls/SortIcon';
import Tooltip from '../Tooltip/Tooltip';

export const OrderDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const TableHeaderColumn = memo(
  ({
    columnKey,
    resizable,
    isLast,
    label,
    headerTitle,
    orderBy,
    initialOrderDirection = OrderDirection.ASC,
    onChangeOrderBy = () => {},
    onResize = () => {},
    onResetResize = () => {},
    style: { ...style } = {},
    children,
    onWidthChange,
    minWidth,
    sortkey,
    labelStyle,
  }) => {
    const resizeRef = useRef();
    const startX = useRef();
    const startWidth = useRef();
    const sortClickedRef = useRef();
    const sortByThisColumn = useRef();
    const [isUpSort, setUpSort] = useState(
      initialOrderDirection === OrderDirection.DESC
    );
    const [sortIcon, setSortIcon] = useState(false);

    const throttledOnResize = useMemo(() => throttle(onResize, 20), [onResize]);

    const handler = useCallback(
      e => {
        e.preventDefault();

        function onMouseMove(e) {
          const diffPx = e.pageX - startX.current;
          const diff = (diffPx * 100) / startWidth.current;
          const newWidth = startWidth.current + diffPx;
          if (newWidth >= 20) {
            startWidth.current = startWidth.current + diffPx;
            startX.current = e.pageX;

            throttledOnResize(columnKey, diff, diffPx);
          }
        }
        function onMouseUp() {
          document.documentElement.removeEventListener(
            'mousemove',
            onMouseMove
          );
          document.documentElement.removeEventListener('mouseup', onMouseUp);
          document.body.style.cursor = 'default';
        }
        document.body.style.cursor = 'ew-resize';
        document.documentElement.addEventListener('mousemove', onMouseMove);
        document.documentElement.addEventListener('mouseup', onMouseUp);
        startX.current = e.pageX;
        startWidth.current = e.target.parentNode.getBoundingClientRect().width;
      },
      [columnKey, throttledOnResize]
    );

    const onSortChangeCb = useCallback(() => {
      const orderBy = {
        field: sortkey,
        direction: isUpSort ? OrderDirection.DESC : OrderDirection.ASC,
      };
      sortClickedRef.current = true;
      sortByThisColumn.current = true;
      onChangeOrderBy(orderBy);
      setSortIcon(true);
      setUpSort(!isUpSort);
    }, [onChangeOrderBy, sortkey, isUpSort]);

    const onResetCb = useCallback(() => {
      onResetResize(columnKey);
    }, [onResetResize, columnKey]);

    const showSortIconCb = useCallback(() => {
      !sortByThisColumn.current && setSortIcon(true);
    }, []);

    const hideSortIconCb = useCallback(() => {
      !sortByThisColumn.current && setSortIcon(false);
    }, []);

    useEffect(() => {
      if (sortClickedRef.current) {
        sortClickedRef.current = false;
      } else {
        setSortIcon(false);
        // setUpSort(false);
        sortByThisColumn.current = false;
      }
    }, [orderBy]);

    const HeaderContainer = headerTitle || label ? Tooltip : Fragment;

    const content = ref => (
      <animated.div
        ref={ref}
        id={columnKey}
        className={clsx('tableHeaderColumn', {
          tableHeaderColumnLast: isLast,
          tableHeaderColumnResizable: resizable,
        })}
        style={{
          ...style,
          minWidth: minWidth && `${minWidth}px`,
          // width,
          // minWidth: keepMinWidth ? width : style.minWidth,
        }}
      >
        <HeaderContainer
          {...(headerTitle || label ? { title: headerTitle || label } : {})}
        >
          <div
            className={clsx('columnLabel', sortkey && 'tableSortableColumn')}
            onClick={sortkey && onSortChangeCb}
            onMouseEnter={sortkey && showSortIconCb}
            onMouseLeave={sortkey && hideSortIconCb}
            style={labelStyle}
          >
            {label || children}
            {sortIcon && <SortIcon up={isUpSort} />}
          </div>
        </HeaderContainer>

        {resizable && !isLast && (
          <Box
            onDoubleClick={onResetCb}
            onMouseDown={handler}
            ref={resizeRef}
            sx={{
              right: -4,
              position: 'absolute',
              zIndex: 1,
              cursor: 'ew-resize',
              padding: '0 4px',
              height: 16,

              '.resizeIndicator': {
                width: '1px',
                borderRadius: '0.5px',
                height: '100%',
                pointerEvents: 'none',
              },
            }}
          >
            <Box className={'resizeIndicator'} />
          </Box>
        )}
      </animated.div>
    );

    if (onWidthChange) {
      return (
        <WithMeasure
          onMeasure={width => onWidthChange(columnKey, width)}
          throttle={1000}
        >
          {content}
        </WithMeasure>
      );
    }

    return content();
  }
);

export default TableHeaderColumn;
