import { gql } from '@apollo/client';

const getChangeLog = gql`
  query getChangeLog(
    $settingsId: LineSettingsId!
    $filters: ChangeLogTableFilter!
  ) {
    data: getChangeLog(settingsId: $settingsId, filters: $filters) {
      items {
        id
        settingsId
        templateId
        template
        template_name
        action
        user
        date
      }
      total
    }
  }
`;

export default getChangeLog;
