import { gql } from '@apollo/client';

const getBoostedOddsK = gql`
  query getBoostedOddsK(
    $brandId: BrandId!
    $eventId: EventId!
    $marketId: MarketId!
    $outcomeId: OutcomeId!
    $specifiers: String!
  ) {
    odd: getBoostedOddsK(
      brand_id: $brandId
      event_id: $eventId
      market_id: $marketId
      outcome_id: $outcomeId
      specifiers: $specifiers
    )
  }
`;

export default getBoostedOddsK;
