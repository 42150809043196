import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import TwoFactorForm from './components/TwoFactorForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '../../routes';

const TwoFactorVerificationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleSubmit = () => {
    navigate(`../${paths.set}?afterSuccess2FA=true`);
  };

  const renderStep = (number, content) => {
    return (
      <Stack direction={'row'} gap={'12px'} alignItems={'center'}>
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            borderRadius: '50%',
            backgroundColor: '#E8F2FF',
            color: '#1581FF',
            padding: '11px 20px',
          }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={600}
            letterSpacing={'0.5px'}
          >
            {number}
          </Typography>
        </Stack>
        <Typography fontSize={'14px'} lineHeight={'20px'} color={'#031224'}>
          {content}
        </Typography>
      </Stack>
    );
  };

  const renderGuide = () => {
    return (
      <Stack gap={'24px'}>
        <Stack gap={'16px'}>
          {renderStep(1, 'Install Google Authorizer on your mobile device.')}
          {renderStep(2, 'Log in to Google Authorizer.')}
          {renderStep(
            3,
            'Click on the "+" (Add account) button to add a new account.'
          )}
          {renderStep(
            4,
            'Scan the QR code available at the link and enter authorizing code.'
          )}
        </Stack>
        <Button
          onClick={() => {
            navigate(
              `../${paths.tfa}?hash=${searchParams.get(
                'hash'
              )}&id=${searchParams.get('id')}`
            );
          }}
          variant={'contained'}
          sx={{
            width: '100%',
          }}
        >
          Show QR code
        </Button>
      </Stack>
    );
  };

  return (
    <Stack gap={'24px'} justifyContent={'center'} alignItems={'center'}>
      <Stack gap={'12px'}>
        <Typography textAlign={'center'} fontWeight={600} fontSize={'24px'}>
          {searchParams.get('welcome')
            ? 'Two-factor authentication is used for authorization in Betby Backoffice'
            : 'Complete two-factor verification'}
        </Typography>
        {searchParams.get('welcome') && (
          <Typography
            textAlign={'center'}
            color={'#93a3b5'}
            fontSize={'14px'}
            lineHeight={'20px'}
          >
            To connect two-factor authentication to your account, you will need
            to:
          </Typography>
        )}
      </Stack>
      {searchParams.get('welcome') ? (
        renderGuide()
      ) : (
        <TwoFactorForm
          hash={searchParams.get('hash')}
          onSubmit={handleSubmit}
        />
      )}
    </Stack>
  );
};

export default TwoFactorVerificationPage;
