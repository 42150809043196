import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import clsx from 'clsx';
import copyToClipboard from 'copy-to-clipboard';
import React, { forwardRef, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CopyIcon from '../../../../icons/controls/CopyIcon';
import ExtraLinkIcon from '../../../../icons/controls/ExtraLinkIcon';
import { useToasts } from '../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../Notifications/NotificationAlert';
import { usePageProvider } from '../../Page/Page';

const useStyles = makeStyles(theme => ({
  linkField: {
    color: '#1581FF',
    letterSpacing: '0.2px',
    textDecoration: 'none',
    fontSize: '11px',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  extraLink: {
    display: 'flex',
    gap: '2px',
  },

  linkBold: {
    fontWeight: 600,
  },

  linkFieldLarge: {
    fontSize: '14px',
    letterSpacing: '0.5px',
  },
  linkFieldCopy: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    color: '#8496AB',
    transition: 'color 0.2s ease-in',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const LinkField = forwardRef(
  (
    {
      href,
      target,
      children,
      copy,
      large,
      state,
      bold = true,
      extraLink,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const { currentPage } = usePageProvider();
    const { showToast } = useToasts();

    const onCopy = useCallback(() => {
      copyToClipboard(children);
      showToast(
        <NotificationAlert variant={AlertVariant.SUCCESS} timeout={1500}>
          Copied to clipboard
        </NotificationAlert>
      );
    }, [children, showToast]);

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
        <RouterLink
          {...props}
          target={target}
          className={clsx(classes.linkField, {
            [classes.linkFieldLarge]: large,
            [classes.linkBold]: bold,
            [classes.extraLink]: extraLink,
          })}
          to={href}
          ref={ref}
          state={
            state
              ? state
              : {
                  from: currentPage,
                }
          }
        >
          {children}
          {extraLink && <ExtraLinkIcon />}
        </RouterLink>
        {copy && (
          <div className={classes.linkFieldCopy} onClick={onCopy}>
            <CopyIcon />
          </div>
        )}
      </Box>
    );
  }
);

export default LinkField;
