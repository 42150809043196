import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { forwardRef, memo, useCallback } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import CopyIcon from '../../../../icons/controls/CopyIcon';
import NotificationAlert, {
  AlertVariant,
} from '../../Notifications/NotificationAlert';
import { useToasts } from '../../../providers/ToastsProvider';

const useStyles = makeStyles(theme => ({
  stringField: {
    fontFamily: 'Inter',
    fontFeatureSettings: 'tnum',
    fontVariantNumeric: 'tabular-nums',
    fontSize: '11px',
    lineHeight: '13px',
    // letterSpacing: '0.2px',
    color: 'currentColor',
    fontWeight: 400,
  },
  noWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  left: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  number: {},
  bold: {
    fontWeight: 'bold',
  },

  code: {
    whiteSpace: 'pre',
    fontFamily: 'monospace',
  },

  colorSuccess: {
    color: '#08CA6D',
  },

  colorPrimary: {
    color: '#1581FF',
  },
  colorDisabled: {
    color: '#8496AB',
  },
  colorConfirm: {
    color: '#01CC6A',
  },
  colorRed: {
    color: '#FF000F',
  },
  colorOrange: {
    color: '#EDBE18',
  },

  stringFieldCopy: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    color: '#8496AB',
    transition: 'color 0.2s ease-in',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const StringField = memo(
  forwardRef(
    (
      {
        number = false,
        color,
        bold = false,
        code = false,
        left = false,
        children,
        wrap = false,
        copy = false,
        className,
        ...props
      },
      ref
    ) => {
      const classes = useStyles();
      const { showToast } = useToasts();

      const onCopy = useCallback(() => {
        copyToClipboard(children);
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS} timeout={1500}>
            Copied to clipboard
          </NotificationAlert>
        );
      }, [children, showToast]);

      return (
        <>
          <div
            {...props}
            ref={ref}
            className={clsx(
              classes.stringField,
              {
                [classes.number]: number,
                [classes.bold]: bold,
                [classes.code]: code,
                [classes.colorSuccess]: color === 'success',
                [classes.colorPrimary]: color === 'primary',
                [classes.colorDisabled]: color === 'disabled',
                [classes.colorConfirm]: color === 'confirm',
                [classes.colorRed]: color === 'red',
                [classes.colorOrange]: color === 'orange',
                [classes.left]: left,
                [classes.noWrap]: !wrap,
              },
              className
            )}
          >
            {children}
          </div>
          {copy && (
            <div className={classes.stringFieldCopy} onClick={onCopy}>
              <CopyIcon />
            </div>
          )}
        </>
      );
    }
  )
);

export default StringField;
