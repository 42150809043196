import * as React from 'react';

export const UiSchemaContext = React.createContext({});

const UiSchemaProvider = ({ children, value }) => {
  return (
    <UiSchemaContext.Provider value={value}>
      {children}
    </UiSchemaContext.Provider>
  );
};

export default UiSchemaProvider;
