import saveBannerMutation from '../../../gql/Promo/mutations/saveBanner';
import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

const useBannerSave = bannerId => {
  const [saveBanner, { loading, error }] = useMutation(saveBannerMutation);

  return useMemo(
    () => ({
      loading,
      error,
      saveBanner: data => {
        return saveBanner({
          variables: {
            id: bannerId,
            input: data,
          },
        });
      },
    }),
    [saveBanner, bannerId, loading, error]
  );
};

export default useBannerSave;
