import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import React, { createContext, useState } from 'react';
import ButtonWithLoader from '../components/ButtonWithLoader';
import Button from '../v2/components/Button/Button';

export const ConfirmationPromptContext = createContext({});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const useStyles = makeStyles({
  destructiveDialogActions: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    '&>*+*': {
      marginLeft: 0,
      marginRight: 16,
    },
  },
});

const ConfirmationPromptProvider = ({ children }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const initialState = {
    callback: () => {},
    isOpen: false,
    title: '',
    message: '',
  };

  const [config, setConfig] = useState(initialState);

  const onClose = () => {
    setSubmitDisabled(false);
    setConfig({
      ...config,
      isOpen: false,
    });
  };

  const openConfirmation = config => {
    setConfig({
      ...config,
      isOpen: true,
    });
  };

  const handleEntering = () => {};

  return (
    <ConfirmationPromptContext.Provider
      value={{
        openConfirmation,
        closeConfirmation: onClose,
        setSubmitDisabled,
      }}
    >
      <Dialog
        TransitionComponent={Transition}
        onClose={onClose}
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={config.isOpen}
        TransitionProps={{
          onEntering: handleEntering,
        }}
      >
        <DialogTitle id="confirmation-dialog-title">{config.title}</DialogTitle>
        <DialogContent>{config.message}</DialogContent>
        <DialogActions
          classes={{
            root: classNames({
              [classes.destructiveDialogActions]: config.destructive,
            }),
          }}
        >
          <Button
            data-cy={'confirmation-modal-cancel'}
            disableRipple
            onClick={onClose}
            variant={config.destructive ? 'contained' : 'text'}
            color={config.destructive ? 'lightBlue' : 'primary'}
          >
            Cancel
          </Button>
          <ButtonWithLoader
            data-cy={'confirmation-modal-confirm'}
            disableRipple
            disabled={submitDisabled}
            variant={'contained'}
            loading={loading}
            onClick={() => {
              if (!config.callback) {
                return onClose();
              }

              setLoading(true);

              config
                .callback()
                .then(() => {
                  setLoading(false);
                  onClose();
                })
                .catch(() => {
                  setLoading(false);
                  onClose();
                });
            }}
            color={config.destructive ? 'lightRed' : 'primary'}
          >
            {config.buttonLabel || 'ok'}
          </ButtonWithLoader>
        </DialogActions>
      </Dialog>

      {children}
    </ConfirmationPromptContext.Provider>
  );
};

export default ConfirmationPromptProvider;
