import { gql } from '@apollo/client';

const marginLadderFragment = gql`
  fragment MarginLadderFragment on MarginLadder {
    id
    name
    sport_id
    sport_name
    items {
      low
      high
    }
  }
`;

export default marginLadderFragment;
