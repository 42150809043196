import { Box } from '@mui/material';
import { isEmpty, sortBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import getCoveragePreset from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePreset';
import getCoveragePresetNames from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePresetNames';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import TabLabel from '../../../../../Tabs/TabLabel';
import Tabs from '../../../../../Tabs/Tabs';
import {
  ApplyPreset,
  DisabledItemArea,
  DraggableSelectItem,
  DraggableSelectMenu,
  MenuCaption,
  MenuTitle,
  SelectContainer,
  SelectSearch,
  VirtualizedMenu,
} from '../../../components/';
import { useCoverageTemplateSelect } from '../../../hooks/coverage/select/useCoverageTemplateSelect';
import CoverageTournamentOption from '../Options/CoverageTournamentOption';

const CoverageTournamentSelect = ({
  templateId,
  selectKey,
  nodeIndex,
  options,
}) => {
  const { getValues, setValue } = useFormContext();

  const lineType = getValues('lineType');

  const {
    nodePath: { [lineType]: nodePath },
  } = useTreeSelectContext();

  const selectedId = nodePath.tournamentId;

  const {
    displayOptions,
    dragDropDisabled,
    tabKey,
    optionsFullListRef,
    isCoverageWriter,
    isPresetsAvailable,
    onManualPriorityChange,
    setDragDropDisabled,
    setTabKey,
    onSearchFieldChange,
    saveReorderChanges,
    setDisplayOptions,
    applyChanges,
  } = useCoverageTemplateSelect({
    options,
    nodePath,
    pathToSelect: withoutEmpty({
      sportId: nodePath.sportId,
      categoryId: nodePath.categoryId,
    }),
    initialTabKey: !isEmpty(options['category']) ? 'category' : 'sport',
    idKey: 'tournamentId',
    getFormValues: getValues,
    setFormValue: setValue,
  });

  const tabs = [
    {
      key: 'category',
      label: <TabLabel>Category</TabLabel>,
      disabled: isEmpty(options['category']),
    },
    {
      key: 'sport',
      label: <TabLabel>Sport</TabLabel>,
    },
  ];

  const changeTabContent = useCallback(
    key => {
      const template = getValues('template');

      setTabKey(key);
      setDragDropDisabled(false);
      //apply config if config not empty and search not active or just set values sorted by priority
      if (!isEmpty(template) && !dragDropDisabled) {
        setDisplayOptions(applyChanges(options[key], key));
      } else {
        setDisplayOptions(sortBy(options[key], ['priority']));
      }
    },
    [
      options,
      getValues,
      setTabKey,
      setDisplayOptions,
      applyChanges,
      setDragDropDisabled,
      dragDropDisabled,
    ]
  );

  //apply changes for each option (to save order)'
  useEffect(() => {
    if (!dragDropDisabled) {
      setDisplayOptions(applyChanges(options[tabKey], tabKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineType, nodePath]);

  return (
    <SelectContainer style={{ width: '450px' }}>
      <SelectSearch
        label={`Search Tournament`}
        onSearchFieldChange={onSearchFieldChange}
        tabKey={tabKey}
      />
      <MenuCaption>
        <MenuTitle name={'Tournament'} />
        {isPresetsAvailable && (
          <ApplyPreset
            templateType={'coverage'}
            selectKey={'tournament'}
            menuOptions={options[tabKey]}
            sportId={nodePath.sportId}
            idKey={'tournamentId'}
            getPresetNamesQuery={getCoveragePresetNames}
            getPresetQuery={getCoveragePreset}
          />
        )}
      </MenuCaption>
      <Tabs
        activeTabKey={!isEmpty(options['category']) ? 'category' : 'sport'}
        fullWidthTabs
        primary
        tabs={tabs}
        onChangeTab={changeTabContent}
        tabsStyle={{
          marginBottom: '8px',
          width: 'calc(100% - 12px)',
        }}
      />
      <DraggableSelectMenu
        afterDrop={saveReorderChanges}
        options={displayOptions}
        setDisplayOptions={setDisplayOptions}
      >
        <VirtualizedMenu
          containerStyle={{ height: 'calc(100% - 131px)' }}
          rowCount={displayOptions.length}
          itemRender={({ index }) => {
            const option = displayOptions[index];

            return (
              <Box position={'relative'}>
                {option.readOnly && <DisabledItemArea />}
                <DraggableSelectItem
                  selected={option.tournamentId === selectedId}
                  index={index}
                  itemValue={option}
                  idKey={'tournamentId'}
                  virtualized
                >
                  <CoverageTournamentOption
                    key={tabKey}
                    templateId={templateId}
                    nodePath={nodePath}
                    nodeIndex={nodeIndex}
                    selected={option.tournamentId === selectedId}
                    tabKey={tabKey}
                    itemValue={option}
                    itemIndex={index}
                    lineType={lineType}
                    options={options}
                    optionsFullListRef={optionsFullListRef}
                    displayOptions={displayOptions}
                    setDisplayOptions={setDisplayOptions}
                    onManualPriorityChange={onManualPriorityChange}
                    dragDropDisabled={dragDropDisabled}
                    configurationDisabled={!isCoverageWriter}
                  />
                </DraggableSelectItem>
              </Box>
            );
          }}
        />
      </DraggableSelectMenu>
    </SelectContainer>
  );
};

export default CoverageTournamentSelect;
