import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack/Stack';
import Grid from '@mui/material/Grid/Grid';
import { useFormContext } from 'react-hook-form';
import some from 'lodash/some';
import FormGroup from '../../../../components/Form/FormGroup';
import FormGroupLabel from '../../../../components/Form/FormGroupLabel';
import FormField from '../../../../components/Form/FormField';
import PromoRestrictionAutocomplete from '../../../../components/Inputs/PromoRestrictionAutocomplete/PromoRestrictionAutocomplete';
import { BannerType } from '../BannerForm/BannerValidationSchema';

const BannerRestrictions = ({ bannerType }) => {
  const { watch, setValue } = useFormContext();

  const placeholders = watch(`${bannerType}.placeholders`);

  const isShowRestrictions =
    bannerType === BannerType.LINE_BANNER &&
    !!placeholders &&
    some(
      [
        'sport_page',
        'tournament',
        'event_page_above_tracker',
        'event_page_below_tracker',
      ],
      item => placeholders.includes(item)
    );

  // useEffect(() => {
  //   if (!isShowRestrictions) {
  //     setValue(`${bannerType}.restrictions`, []);
  //   }
  // }, [isShowRestrictions, setValue, bannerType]);

  return (
    isShowRestrictions && (
      <FormGroup large>
        <Stack spacing={2} direction={'column'}>
          <FormGroupLabel
            helpText={
              'Select sports, tournaments or events where you want the banner to appear'
            }
          >
            Placeholder Restrictions
          </FormGroupLabel>
          <Grid container>
            <Grid item xs={4}>
              <FormField name={`${bannerType}.restrictions`}>
                <PromoRestrictionAutocomplete
                  required
                  multiple
                  label={'Add sports, events, categories'}
                />
              </FormField>
            </Grid>
          </Grid>
        </Stack>
      </FormGroup>
    )
  );
};

export default BannerRestrictions;
