import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const changeMarketNameMutation = gql`
  ${eventModelFragment}
  mutation changeMarketName(
    $eventId: EventId!
    $marketId: MarketId!
    $name: String!
    $version: Version!
  ) {
    changeMarketName(
      event_id: $eventId
      market_id: $marketId
      name: $name
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;

export default changeMarketNameMutation;
