import activateBannerMutation from '../../../gql/Promo/mutations/activateBanner';
import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

const useBannerActivate = bannerId => {
  const [activateBanner, { loading, error }] = useMutation(
    activateBannerMutation
  );

  return useMemo(
    () => ({
      loading,
      error,
      activateBanner: data => {
        return activateBanner({
          variables: {
            id: bannerId,
            input: data,
          },
        });
      },
    }),
    [activateBanner, bannerId, loading, error]
  );
};

export default useBannerActivate;
