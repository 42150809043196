import FormField from '../../../../components/Form/FormField';
import TextField from '../../../../components/Inputs/TextField';
import Grid from '@mui/material/Grid/Grid';

const BannerSubtitleField = ({ name, ...props }) => {
  return (
    <Grid item xs={8}>
      <FormField name={name}>
        <TextField label={'Banner Subtitle'} {...props} />
      </FormField>
    </Grid>
  );
};

export default BannerSubtitleField;
