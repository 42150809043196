import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const InlineFieldSuccessIndicator = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#08CA6D" />
      <path
        d="M5 9L7 10.5L11 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default InlineFieldSuccessIndicator;
