import React, { forwardRef, memo } from 'react';
import { animated, config, useSpring } from 'react-spring';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  expandIcon: {
    width: 8,
    height: 6,
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("/icons/ExpandIcon.svg")',
    backgroundPosition: 'center',
  },
});

const ExpandAllIcon = memo(
  forwardRef(({ expanded, className, style, ...props }, ref) => {
    const classes = useStyles();

    const { rotate } = useSpring({
      rotate: expanded ? 180 : 0,
      config: config.gentle,
    });

    return (
      <>
        <animated.div
          ref={ref}
          className={clsx(classes.expandIcon, className)}
          style={{
            ...style,
            transform: rotate.to(deg => `rotateZ(${deg + 180}deg)`),
          }}
          {...props}
        />

        <animated.div
          ref={ref}
          className={clsx(classes.expandIcon, className)}
          style={{
            ...style,
            transform: rotate.to(deg => `rotateZ(${-deg}deg)`),
            marginTop: 2,
          }}
          {...props}
        />
      </>
    );
  })
);

export default ExpandAllIcon;
