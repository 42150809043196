import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import Checkbox from '../../../../Checkbox';
import { FancyOptionTitle, ItemMoveButton } from '../../../components/';
import { useCoverageTemplateOption } from '../../../hooks/coverage/options/useCoverageTemplateOption';
import { changePath } from '../../../utils/changePath';
import { clearPath } from '../../../utils/getKeySelector';

const useStyles = makeStyles(theme => ({
  menuItemCheckbox: {
    padding: 0,
  },

  optionName: {
    maxWidth: '200px',
  },
}));

const CoverageCategoryOption = ({
  templateId,
  nodePath = {},
  nodeIndex,
  selectKey,
  selected,
  itemValue,
  itemIndex,
  options,
  optionsFullListRef,
  setDisplayOptions,
  lineType,
  dragDropDisabled,
  configurationDisabled,
  provided,
}) => {
  const classes = useStyles();
  const { showToast } = useToasts();

  const [searchParams] = useSearchParams();
  const duplicate = !!searchParams.get('duplicate');

  const { getValues, setValue } = useFormContext();
  const {
    setNodePath,
    setData,
    setTreeSelectLoading,
    getCoverageTemplateQuery,
    selectedCoverageItemsConfig: { [lineType]: selectedCoverageItemsConfig },
    setSelectedCoverageItemsConfig,
  } = useTreeSelectContext();
  const categoryInheritStatus = selectedCoverageItemsConfig.sport.status;

  const nodeSelector = withoutEmpty({
    sportId: nodePath.sportId,
    categoryId: itemValue.categoryId,
  });
  const { checked, onChangeStatus } = useCoverageTemplateOption({
    itemValue,
    options,
    optionsFullListRef,
    nodeSelector,
    getFormValues: getValues,
    setFormValue: setValue,
    idKey: 'categoryId',
    setDisplayOptions,
    inheritStatus: categoryInheritStatus,
  });
  // need strict compare because it is possible to be null
  const status = categoryInheritStatus === false ? false : checked;

  const refreshSelectedItemStatus = status => {
    setSelectedCoverageItemsConfig(curr => ({
      ...curr,
      [lineType]: {
        ...curr[lineType],
        category: {
          ...curr[lineType].category,
          [itemValue.categoryId]: status,
        },
      },
    }));
  };

  const handleStatusCheckbox = value => {
    onChangeStatus(value);
    refreshSelectedItemStatus(value);
  };

  //on option click we need to highlight this option, after this request new lists of options
  //for get new option list we need to pass ids of previous selected items
  const onSelect = () => {
    const prevNodePath = nodePath;
    let path = changePath({
      nodeId: itemValue.categoryId,
      nodeKey: 'categoryId',
      nodeIndex,
      nodePath,
      setNodePath,
      lineType,
    });
    getCoverageTemplateQuery(
      {
        templateId,
        path: clearPath(path),
        lineType,
      },
      duplicate
    )
      .then(data => {
        const newData = withoutEmpty(data.data.data);
        setTreeSelectLoading(false);
        refreshSelectedItemStatus(checked);

        setData(currentData => {
          return {
            ...currentData,
            [lineType]: {
              marketsToNode: newData.marketsToNode,
              sports: currentData[lineType].sports,
              categories: currentData[lineType].categories,
              tournaments: {
                ...currentData[lineType].tournaments,
                category: newData.tournaments
                  ? newData.tournaments.category
                  : {},
              },
              markets: newData.markets,
            },
          };
        });
      })
      .catch(() => {
        setTreeSelectLoading(false);
        setNodePath(currentPath => ({
          ...currentPath,
          [lineType]: prevNodePath,
        }));
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can not fetch data
          </NotificationAlert>
        );
      });
  };
  const dragHandleProps = !configurationDisabled &&
    !dragDropDisabled && { ...provided.dragHandleProps };

  return (
    <Box display={'flex'} alignItems={'center'}>
      <ItemMoveButton
        dragHandleProps={dragHandleProps}
        selected={selected}
        dragDropDisabled={configurationDisabled || dragDropDisabled}
      />

      <Checkbox
        value={status}
        onChange={handleStatusCheckbox}
        disabled={
          configurationDisabled || itemValue.readOnly || !categoryInheritStatus
        }
      />
      <FancyOptionTitle
        tooltip={true}
        code={itemValue.countryCode}
        total={itemValue.total}
        name={itemValue.name}
        textClassName={classes.optionName}
        onClick={onSelect}
      >
        {itemValue.name}
      </FancyOptionTitle>
    </Box>
  );
};

export default memo(CoverageCategoryOption);
