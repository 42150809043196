import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import ClearInputIcon from '../../../../../icons/controls/ClearInputIcon';
import { confirmationTypes } from './constants';

const useStyles = makeStyles({
  confirmationTittle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmationCloseButton: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  },
});

const EventManagementConfirmationTitle = ({ type, onClearIconClick }) => {
  const classes = useStyles();

  const getTitle = () => {
    switch (type) {
      case confirmationTypes.merge: {
        return 'Merge events';
      }
      case confirmationTypes.invertedMerge: {
        return 'Merge & Invert events';
      }
      case confirmationTypes.unmerge: {
        return 'Unmerge events';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Box className={classes.confirmationTittle}>
      {getTitle()}
      <ClearInputIcon
        className={classes.confirmationCloseButton}
        onClick={onClearIconClick}
      />
    </Box>
  );
};

EventManagementConfirmationTitle.propTypes = {
  type: PropTypes.string.isRequired,
  onClearIconClick: PropTypes.func.isRequired,
};

export default EventManagementConfirmationTitle;
