import ExpandCell from './ExpandCell';
import { TableCell, TableRow as MuiTableRow } from '@mui/material';
import Field from '../Field/Field/Field';
import DataProvider, { DataContext } from '../../providers/DataProvider';
import NestedTableRow from './NestedTableRow';
import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InlineTableDropdownRow from './InlineTableDropdownRow';
import UiSchemaProvider from '../../providers/UiSchemaProvider';
import { TableRowActionsContext } from '../../providers/TableRowActionsProvider';
import TableRowActions from './TableRowActions';
import { RowDecoratorsContext } from '../../providers/RowDecoratorsProvider';
import { NestedIndexesContext } from '../../providers/NestedIndexProvider';
import { usePaletteColor } from '../../hooks/usePaletteColor';
import clsx from 'clsx';
import useDataContext from '../../hooks/useDataContext';
import { compileConditions } from '../Field/FieldUtils';

const RowBackgroundColorContext = React.createContext('#fff');

export const useRowBackgroundColorContext = () => {
  return useContext(RowBackgroundColorContext);
};

const useStyles = makeStyles({
  cellRoot: {
    border: '1px solid #fff',
    borderLeft: 'none',
    borderRight: 'none',
    position: 'relative',
  },

  expandedCellRoot: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&:first-child': {
      borderBottomRightRadius: 'initial',
      borderBottomLeftRadius: 'initial',
    },
  },

  borderDecorator: {
    border: '1px solid #fff',
    borderLeft: 'none',
    borderRight: 'none',
    '&:first-of-type': {
      // borderLeft: '4px solid #fff',
      // borderTopLeftRadius: 6,
      // borderBottomLeftRadius: 6,
    },
    '&:last-of-type': {
      borderRight: '1px solid #fff',
    },
  },

  rowExpandCell: {
    zIndex: 1,
    minWidth: 30,
    left: 0,
    position: 'sticky',
    background: '#F7F7F7',
    // background: 'linear-gradient(to left, rgba(255, 255, 255, 0.90), #fff)',
    // boxShadow: '6px 0px 6px 0px rgba(255, 255, 255, 0.90)',
    borderTopLeftRadius: '0!important',
    borderBottomLeftRadius: '0!important',
    border: 'none!important',
  },

  rowActionsCell: {
    minWidth: 30,
    maxWidth: 30,
    right: 0,
    position: 'sticky',
    background: '#F7F7F7',
    borderTopRightRadius: '0!important',
    borderBottomRightRadius: '0!important',
    border: 'none!important',
    height: 38,
    paddingLeft: 0,
    paddingRight: 0,
  },

  rowActionsCellBackground: {
    height: 38,
    width: '100%',
    paddingRight: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderLeftWidth: 0,
  },
});

const TableRow = ({ data, columns, nested = [], summaryRow = false }) => {
  const hasNested = !!nested.length;
  const [nestedActive, setNestedActive] = useState(false);
  const rowActions = useContext(TableRowActionsContext);
  const responseDataIndexes = useContext(NestedIndexesContext);

  const {
    fetchData,
    path,
    operationId,
    query: requestQuery,
    data: responseData,
  } = useDataContext(DataContext);
  const compiledRowActions =
    rowActions &&
    rowActions
      .map(action => {
        const { uiSchema } = action;

        const decorators =
          uiSchema && uiSchema.conditions
            ? compileConditions(
                uiSchema.conditions,
                responseData,
                responseDataIndexes
              )
            : {};

        return {
          ...action,
          uiSchema: {
            ...action.uiSchema,
            ...decorators,
          },
        };
      })
      .filter(action => {
        return !action.uiSchema.hide;
      });

  const indexes = useContext(NestedIndexesContext);

  const decorators = useContext(RowDecoratorsContext)(indexes);
  const borderDecorator = decorators.borderColor;

  const classes = useStyles();

  const cellClasses = {
    root: clsx(classes.cellRoot, {
      [classes.expandedCellRoot]: nestedActive,
      [classes.borderDecorator]: borderDecorator,
    }),
  };

  const paletteColor = usePaletteColor(
    borderDecorator && borderDecorator.color
  );

  const cellStyles = {
    borderColor: (paletteColor && paletteColor.main) || '#fff',
    backgroundColor: (paletteColor && paletteColor.light) || '#fff',
  };

  return (
    <>
      <RowBackgroundColorContext.Provider value={paletteColor}>
        <MuiTableRow>
          {hasNested &&
            (summaryRow ? (
              <TableCell classes={cellClasses} style={cellStyles} />
            ) : (
              <ExpandCell
                className={classes.rowExpandCell}
                isOpen={nestedActive}
                onClick={() => setNestedActive(!nestedActive)}
                classes={cellClasses}
                cellStyles={cellStyles}
              />
            ))}
          {columns.map((column, index) => {
            if (column.inlineTableDropdown) {
              return (
                <UiSchemaProvider value={column.uiSchema} key={index}>
                  <InlineTableDropdownRow
                    columnName={column.name}
                    parentData={data}
                    cellStyles={cellStyles}
                    cellClasses={cellClasses}
                    data={data[column.name]}
                    columns={column.inlineTableDropdown}
                  />
                </UiSchemaProvider>
              );
            }
            return (
              <TableCell
                key={column.name}
                classes={cellClasses}
                style={cellStyles}
                align={column.align}
              >
                <Field
                  align={column.align}
                  schema={column.schema}
                  uiSchema={column.uiSchema}
                  fieldValue={column.name ? data[column.name] : data}
                  fieldKey={column.name}
                  rowData={data}
                />
              </TableCell>
            );
          })}

          <TableCell
            classes={cellClasses}
            className={classes.rowActionsCell}
            align={'right'}
          >
            <div
              className={classes.rowActionsCellBackground}
              style={cellStyles}
            >
              {!!(compiledRowActions && compiledRowActions.length) && (
                <TableRowActions
                  requestQuery={requestQuery}
                  fetchData={fetchData}
                  operationId={operationId}
                  path={path}
                  responseBody={data}
                  actions={compiledRowActions}
                  responseData={responseData}
                />
              )}
            </div>
          </TableCell>
        </MuiTableRow>
        <DataProvider data={data}>
          <NestedTableRow nested={nested} active={nestedActive} />
        </DataProvider>
      </RowBackgroundColorContext.Provider>
    </>
  );
};

export default TableRow;
