import React from 'react';
import Card from '@mui/material/Card';
import classnames from 'clsx';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  card: {
    borderRadius: 3,
    padding: '16px 18px',
    display: 'inline-block',
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    fontSize: 36,
    color: theme.palette.secondary.contrastText,
  },
});

const CardIcon = ({ Icon, classes, className }) => (
  <Card className={classnames(classes.card, className)}>
    <Icon className={classes.icon} />
  </Card>
);

export default withStyles(styles)(CardIcon);
