import { Box, Stack } from '@mui/material';
import { forwardRef, useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  matchNavigator: {
    display: 'flex',
    alignItems: 'center',
  },

  stepButton: {
    cursor: 'pointer',
    userSelect: 'none',
  },
});

const SearchTextMatchNavigator = forwardRef(
  ({ matchCount, searchSelectedIndex, setSearchSelectedIndex }, ref) => {
    const classes = useStyles();

    const onStepBack = useCallback(() => {
      setSearchSelectedIndex(currentState => {
        if (currentState > 1) {
          return currentState - 1;
        }
        return 1;
      });
    }, [setSearchSelectedIndex]);

    const onStepForward = useCallback(() => {
      setSearchSelectedIndex(currentState => {
        if (currentState < matchCount) {
          return currentState + 1;
        }
        return currentState;
      });
    }, [setSearchSelectedIndex, matchCount]);

    return (
      <Box className={classes.matchNavigator}>
        <Box className={classes.stepButton} onClick={onStepBack}>
          {'<'}
        </Box>
        {` ${matchCount === 0 ? 1 : searchSelectedIndex}/${matchCount} `}
        <Box className={classes.stepButton} onClick={onStepForward}>
          {'>'}
        </Box>
      </Box>
    );
  }
);

export default SearchTextMatchNavigator;
