import { gql } from '@apollo/client';

const login = gql`
  mutation login($email: Email!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      refresh_token
    }
  }
`;

export default login;
