import { gql } from '@apollo/client';

const setPassword = gql`
  mutation setPassword(
    $email: Email!
    $newPassword: Password!
    $token: InviteToken!
  ) {
    setPassword(email: $email, newPassword: $newPassword, token: $token)
  }
`;

export default setPassword;
