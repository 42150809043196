import React from 'react';
import TableActionCard from './TableActionCard';
import ExportButton from '../../../uiComponents/Table/ExportButton';

const TableExportActionCard = ({ style, link, params, query }) => {
  return (
    <TableActionCard style={style}>
      <ExportButton params={params} query={query} link={link} />
    </TableActionCard>
  );
};

export default TableExportActionCard;
