import AddIconCircle from '../../../icons/controls/AddIconCircle';
import Button from './Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  addButton: {
    padding: 0,

    '&:disabled': {
      '& $addButtonIcon': {
        color: theme.palette.grey.main,
      },

      '& $addButtonLabel': {
        color: theme.palette.grey.main,
      },
    },
  },

  addButtonIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  addButtonLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '0.5px',
  },
}));

const AddButton = ({ onClick, disabled, children }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classes.addButton}
      disabled={disabled}
      variant={'text'}
    >
      <AddIconCircle className={classes.addButtonIcon} />
      <Typography noWrap className={classes.addButtonLabel}>
        {children}
      </Typography>
    </Button>
  );
};

export default AddButton;
