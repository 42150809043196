import React from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import classnames from 'clsx';

const styles = {
  root: {
    display: 'flex',
    height: 'unset',
  },
};

const inputComponent = withStyles(styles)(
  ({ inputRef, classes, className, ...props }) => (
    <div
      ref={inputRef}
      className={classnames(classes.root, className)}
      {...props}
    />
  )
);

const Control = ({ selectProps, innerRef, children, innerProps }) => (
  <TextField
    fullWidth
    variant={selectProps.variant}
    margin={selectProps.margin}
    InputProps={{
      inputComponent,
      inputProps: {
        inputRef: innerRef,
        children: children,
        ...innerProps,
      },
    }}
    InputLabelProps={{
      shrink: true,
    }}
    {...selectProps.textFieldProps}
  />
);

export default Control;
