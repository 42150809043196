import { makeStyles } from '@mui/styles';
import SportIcon from '../../../icons/SportIcon/SportIcon';
import Tooltip from '../../Tooltip/Tooltip';
import StringField from '../StringField/StringField';
import CountryIcon from '../../../icons/CountryIcon/CountryIcon';
import getCountryName from '../../../utils/getCountryName';

const useStyles = makeStyles({
  countryField: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
  bigCountryIcon: {
    width: '24px',
    height: '24px',
    border: 'none',
    borderRadius: '100%',
    backgroundSize: '24px',
  },
});

const CountryField = ({ name, cationClassName, bigIcon = false, onClick }) => {
  const classes = useStyles();
  const countryFullName =
    name === 'international'
      ? 'International Clubs'
      : getCountryName(name) || name;

  return (
    <div className={classes.countryField} onClick={onClick}>
      <Tooltip title={countryFullName}>
        <CountryIcon
          name={name}
          className={bigIcon && classes.bigCountryIcon}
        />
      </Tooltip>
      <StringField className={cationClassName}>{countryFullName}</StringField>
    </div>
  );
};

export default CountryField;
