import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './scrollbar.css';
import React from 'react';

const Scrollbar = ({ children, disabled, scrollableNodeRef, ...props }) => {
  if (disabled) return children;

  return (
    <SimpleBar
      autoHide={false}
      {...props}
      scrollableNodeProps={{ ref: scrollableNodeRef }}
    >
      {children}
    </SimpleBar>
  );
};

export default Scrollbar;
