import { without } from 'lodash';
import some from 'lodash/some';
import { useCallback, useMemo, useState } from 'react';

const useTableBulkFullSelection = (totalItems = 0) => {
  const [bulkState, setBulkState] = useState({
    exclude: false,
    ids: [],
    itemsSelected: 0,
  });

  const everyToggled = useMemo(
    () =>
      (bulkState.exclude && bulkState.ids.length === 0) ||
      (!bulkState.exclude && bulkState.ids.length === totalItems),
    [bulkState, totalItems]
  );

  const someToggled = useMemo(
    () =>
      (bulkState.exclude && bulkState.ids.length > 0) || some(bulkState.ids),
    [bulkState]
  );

  const reset = useCallback(() => {
    setBulkState({
      exclude: false,
      ids: [],
      itemsSelected: 0,
    });
  }, [setBulkState]);

  // const resetHidden = useCallback(() => {
  //   setBulkState(currentState =>
  //     pickBy(currentState.ids, (value, key) => {
  //       return items.find(item => item[id] === key);
  //     })
  //   );
  // }, [setBulkState, items, id]);

  // useEffect(() => {
  //   resetHidden();
  // }, [resetHidden]);

  const toggle = useCallback(
    (rowData, toggled) => {
      const rowId = rowData.id;

      setBulkState(currentState => {
        const exclude = currentState.exclude;
        const newIds = exclude
          ? toggled
            ? without(currentState.ids, rowId)
            : [...currentState.ids, rowId]
          : toggled
          ? [...currentState.ids, rowId]
          : without(currentState.ids, rowId);

        return {
          exclude: exclude,
          ids: newIds,
          itemsSelected: exclude ? totalItems - newIds.length : newIds.length,
        };
      });
    },
    [setBulkState, totalItems]
  );

  const toggleAll = useCallback(
    toggled => {
      setBulkState(currentState => {
        const everyToggled =
          (currentState.exclude && currentState.ids.length === 0) ||
          (!currentState.exclude && currentState.ids.length === totalItems);

        if (everyToggled && !toggled) {
          return {
            exclude: false,
            ids: [],
            itemsSelected: 0,
          };
        }

        return {
          exclude: true,
          ids: [],
          itemsSelected: totalItems,
        };
      });
    },
    [setBulkState, totalItems]
  );

  return useMemo(() => {
    return {
      toggle,
      bulkState,
      setBulkState,
      // resetHidden,
      reset,
      toggleAll,
      someToggled,
      everyToggled,
    };
  }, [
    bulkState,
    setBulkState,
    // resetHidden,
    reset,
    toggle,
    toggleAll,
    someToggled,
    everyToggled,
  ]);
};

export default useTableBulkFullSelection;
