import { useCallback } from 'react';
import DateField from '../../../../components/Fields/DateField/DateField';
import EmptyField from '../../../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../../../components/Fields/LinkField/LinkField';
import StringField from '../../../../components/Fields/StringField/StringField';
import Table from '../../../../components/Table/Table';
import useTableColumnsReorder from '../../../../hooks/useTableColumnsReorder';
import BrandTemplate from './BrandTemplate';

export const COLUMN = {
  name: 'name',
  //   childTemplates: 'childTemplates',
  //   type: 'type',
  //   brands: 'brands',
  createdBy: 'createdBy',
  created: 'created',
  updated: 'updated',
};

export const columnsWidthConfig = {
  [COLUMN.name]: 2,
  //   [COLUMN.childTemplates]: 0.5,
  //   [COLUMN.type]: 0.5,
  //   [COLUMN.brands]: 0.5,
  [COLUMN.createdBy]: 0.9,
  [COLUMN.created]: 0.45,
  [COLUMN.updated]: 0.825,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.name,
  //   COLUMN.childTemplates,
  //   COLUMN.type,
  //   COLUMN.brands,
  COLUMN.createdBy,
  COLUMN.created,
  COLUMN.updated,
];

export const initialToggledColumns = [
  COLUMN.name,
  //   COLUMN.childTemplates,
  //   COLUMN.type,
  //   COLUMN.brands,
  COLUMN.createdBy,
  COLUMN.created,
  COLUMN.updated,
];

const TemplateBrandsTable = ({
  data,
  loading,
  templateId,
  error,
  rowActions,
}) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Name',
      key: COLUMN.name,
      render: ({ name }) => {
        return name ? (
          <StringField title={name}>
            <LinkField href={``}>{name}</LinkField>
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    // {
    //   label: 'Child Templates',
    //   key: COLUMN.childTemplates,
    //   render: ({ childTemplates }) => {
    //     //   return childTemplates ? (
    //     //     <StringField>{childTemplates}</StringField>
    //     //   ) : (
    //     //     <EmptyField />
    //     //   );
    //   },
    // },
    // {
    //   label: 'Type',
    //   key: COLUMN.type,
    //   render: ({ type }) => {
    //     //   return type ? <StringField>{type}</StringField> : <EmptyField />;
    //   },
    // },
    // {
    //   label: 'Brands',
    //   key: COLUMN.brands,
    //   render: ({ brandsCount }) => {
    //     //   return brandsCount ? (
    //     //     <StringField title={brandsCount}>{brandsCount}</StringField>
    //     //   ) : (
    //     //     <EmptyField />
    //     //   );
    //   },
    // },
    {
      label: 'Created by',
      key: COLUMN.createdBy,
      render: ({ createdBy }) => {
        return createdBy ? (
          <StringField title={createdBy}>{createdBy}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Created',
      key: COLUMN.created,
      render: ({ created }) => {
        return created ? (
          <StringField>
            <DateField date={created} format={'YYYY.MM.DD'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Updated',
      key: COLUMN.updated,
      render: ({ updated }) => {
        return updated ? (
          <StringField>
            <DateField date={updated} format={'YYYY.MM.DD, HH:mm:ss'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      return (
        <BrandTemplate
          key={rowIndex}
          templateId={templateId}
          type={rowData.type}
        />
      );
    },
    [templateId]
  );

  return (
    <Table
      id={COLUMN.name}
      withoutHeader
      expandable
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      renderExpandedRow={renderExpandedRow}
      error={error}
    />
  );
};

export default TemplateBrandsTable;
