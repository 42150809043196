import MultiSelect from '../MultiSelect/MultiSelect';
import Autocomplete from '../Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMethodAndUrl,
  getParameters,
  getPathByOperationId,
} from '../../utils/schemaHelper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getData, getError, getLoading } from '../../reducers/data';
import { debouncedGetRouteData, fetchDynamic } from '../../actions/dataActions';
import merge from 'lodash/merge';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import usePrevious from '../../hooks/usePrevious';
import { useRoutes } from '../../providers/RoutesProvider';
import { useApiHost } from '../../providers/ApiHostProvider';

const SearchSelectInput = ({
  params,
  operationId,
  onChange,
  valueKey,
  labelKey,
  value,
  multiple,
  options,
  helperText,
  ...otherProps
}) => {
  const { formContext: { formData } = {} } = otherProps;
  const matchParams = useParams();

  const queryParams = getParameters({
    params,
    requestPath: matchParams,
    requestBody: formData,
    requestQuery: formData,
  });

  const [query, setQuery] = useState({
    id_list: [value],
  });

  useEffect(() => {
    setQuery({
      ...query,
      id_list: [...(query.id_list || []), value],
    });
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const prevQuery = usePrevious(query);
  const [selectedValuesMap, setSelectedValuesMap] = useState({});
  const routes = useRoutes('data fetcher');
  const { apiHost } = useApiHost('data fetcher');

  const path = getPathByOperationId(routes, operationId);
  const { method, url } = getMethodAndUrl({
    path,
    params,
    query: {
      ...query,
      ...queryParams,
    },
  });
  const initialUrl = useMemo(() => {
    return url;
  }, [url]);

  const dataUrl = method + url;

  const data = useSelector(state =>
    getData(state, routes, dataUrl, undefined, path)
  );
  const error = useSelector(state => getError(state, routes, dataUrl, path));
  const isLoading = useSelector(state =>
    getLoading(state, routes, dataUrl, path)
  );

  const onInputChange = useCallback(value => {
    if (value) {
      setQuery({
        query: value,
      });
    }
  }, []);

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    return dispatch(
      debouncedGetRouteData(
        {
          routes,
          apiHost,
        },
        { method, url, path }
      )
    );
  }, [dispatch, routes, apiHost, method, url, path]);

  const fetchLabels = useCallback(() => {
    return dispatch(
      fetchDynamic(
        {
          routes,
          apiHost,
        },
        { method, url: initialUrl }
      )
    );
  }, [dispatch, routes, apiHost, method, initialUrl]);

  const AutocompleteComponent = multiple ? MultiSelect : Autocomplete;

  const addSelectedValues = useCallback(
    selectedValues => {
      setSelectedValuesMap(
        merge({}, selectedValues, keyBy(selectedValues, valueKey))
      );
    },
    [valueKey]
  );

  const preventDeleteChipsOnBackspace = e => {
    if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    // we need to refetch this only when input text empty
    if (value && !query.query) {
      const [res] = fetchLabels();
      res.then(
        ({
          payload: {
            data: { items },
          },
        }) => {
          addSelectedValues(items);
        }
      );
    }
  }, [fetchLabels, addSelectedValues, value, query]);

  useEffect(() => {
    if (
      query.query &&
      query.query.length >= 2 &&
      query.query !== prevQuery.query
    ) {
      fetchData();
    }
  }, [query, prevQuery, fetchData]);

  return (
    <AutocompleteComponent
      {...otherProps}
      value={
        (multiple
          ? (value || [])
              .filter(v => selectedValuesMap[v])
              .map(v => selectedValuesMap[v])
          : selectedValuesMap[value]) || (multiple ? [] : { [valueKey]: '' })
      }
      error={error}
      isLoading={isLoading}
      onInputChange={onInputChange}
      onKeyDown={e => {
        preventDeleteChipsOnBackspace(e);
      }}
      uiOptions={{}}
      options={
        data &&
        (data.items ||
          data.filter(option => !value.find(v => isEqual(v, option))))
      }
      getOptionLabel={option => option[labelKey]}
      getOptionValue={option => option[valueKey]}
      variant={'outlined'}
      margin={'dense'}
      isClearable={false}
      labelKey={labelKey}
      helperText={helperText}
      filterOption={() => true}
      isMulti={multiple}
      onChange={value => {
        addSelectedValues(multiple ? value : [value]);
        onChange(
          value && (multiple ? value.map(v => v[valueKey]) : value[valueKey])
        );
      }}
    />
  );
};

export default SearchSelectInput;
