import React from 'react';

import { makeVar, useReactiveVar } from '@apollo/client';

export const DialogReactiveVar = makeVar({});

const useDialog = (options = {}) => {
  const data = useReactiveVar(DialogReactiveVar);

  const handleEscKeyPress = e => {
    if (e.keyCode === 27) {
      close();
    }
  };

  const close = () => {
    DialogReactiveVar({});

    if (options.closeOnEsc) {
      document.removeEventListener('keydown', handleEscKeyPress);
    }
  };

  const open = ({ component, props: newProps, options: newOptions }) => {
    DialogReactiveVar({ component, props: newProps, options: newOptions });

    if (options.closeOnEsc) {
      document.addEventListener('keydown', handleEscKeyPress);
    }
  };

  return { open, close, data };
};

export default useDialog;
