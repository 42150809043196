import { gql } from '@apollo/client';

const removeSportMarketMutation = gql`
  mutation removeSportMarketMutation($sportId: SportId!, $marketId: MarketId!) {
    removeSportMarket(sport_id: $sportId, market_id: $marketId) {
      id
      name
      enabled
    }
  }
`;

export default removeSportMarketMutation;
