import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import HideIcon from '../../icons/controls/HideIcon';
import ArrayChipFilter from './FilterFields/ArrayChipFilter';
import BooleanFilter from './FilterFields/BooleanFilter';
import DateFilter from './FilterFields/DateFilter';
import DateTimeFilter from './FilterFields/DateTimeFilter';
import IntegerFilter from './FilterFields/IntegerFilter';
import RangeFilter from './FilterFields/RangeFilter';
import SelectFilter from './FilterFields/SelectFilter/SelectFilter';
import StringFilter from './FilterFields/StringFilter';

const FORM_FIELD_TYPES = {
  integer: IntegerFilter,
  boolean: BooleanFilter,
  string: StringFilter,
  'date-time': DateTimeFilter,
  date: DateFilter,
  select: SelectFilter,
  array: ArrayChipFilter,
};

export const FORM_FIELD_COMPONENTS = {
  range: RangeFilter,
  search_select: SelectFilter,
};

const getFieldComponent = (type, uiSchema) => {
  if (
    uiSchema &&
    uiSchema['ui:widget'] &&
    FORM_FIELD_COMPONENTS[uiSchema['ui:widget']]
  ) {
    return FORM_FIELD_COMPONENTS[uiSchema['ui:widget']];
  }
  return FORM_FIELD_TYPES[type] || FORM_FIELD_TYPES.string;
};

const useStyles = makeStyles({
  hideFilterButton: {
    fontSize: '20px',
  },
  inputAdornmentEndRoot: {
    top: 4,
    right: -9,
    position: 'absolute',
    marginLeft: 8,
    zIndex: 1,
    '& > .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

export const getHelperText = error => {
  if (typeof error.message === 'object') {
    return Object.values(error.message).join(', ');
  }

  return error.message;
};

const FilterField = ({
  name,
  onHideFilterClick,
  type,
  className,
  alwaysVisible,
  persistFilters,
  uiSchema,
  schema,
  label,
  onResetFilterValue,
  title,
  fullWidth,
  // initialVisible,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();
  const FieldComponent = getFieldComponent(type, uiSchema);
  const error = get(errors, name);

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <FieldComponent
            fullWidth={fullWidth}
            title={title}
            schema={schema}
            label={label}
            onResetFilterValue={onResetFilterValue}
            onChange={(...args) => {
              field.onChange(...args);
              if (
                type === 'select' ||
                type === 'number' ||
                type === 'boolean' ||
                type === 'search_select' ||
                type === 'array' ||
                type === 'date' ||
                type === 'date-time' ||
                type === 'range'
              ) {
                persistFilters();
              } else {
                // throw new Error(`Unsupported filter type: "${type}"`);
              }
            }}
            onBlur={(...args) => {
              persistFilters();
              field.onBlur(...args);
            }}
            value={field.value}
            name={name}
            id={`filter-${name}`}
            error={!!error}
            helperText={error && getHelperText(error)}
            uiSchema={uiSchema}
            InputProps={{
              ...(alwaysVisible
                ? {}
                : {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        classes={{
                          root: classes.inputAdornmentEndRoot,
                        }}
                      >
                        <IconButton
                          onClick={onHideFilterClick}
                          variant={'contained'}
                          color={'primary'}
                          size={'small'}
                        >
                          <HideIcon className={classes.hideFilterButton} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
            }}
            className={className}
          />
        );
      }}
      control={control}
    />
  );
};

export default FilterField;
