import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ArrowLeft = props => {
  return (
    <SvgIcon viewBox={'0 0 12 14'} {...props}>
      <path
        fill={'none'}
        d="M8 1L2 7L8 13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ArrowLeft;
