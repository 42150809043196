import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '../../../icons/controls/LockIcon';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { getActiveSchemaUrl } from '../../../reducers/schemaUrls';

const useStyles = makeStyles({
  lockIcon: {
    fontSize: '13px',
  },
});

const PasswordWidget = props => {
  const classes = useStyles();
  const activeSchemaUrl = useSelector(getActiveSchemaUrl);
  const { BaseInput } = props.registry.widgets;

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <LockIcon className={classes.lockIcon} />
      </InputAdornment>
    ),
  };

  return (
    <BaseInput
      type={'password'}
      {...props}
      InputProps={inputProps}
      id={`password-${activeSchemaUrl}`}
    />
  );
};

export default PasswordWidget;
