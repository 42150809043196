import { makeStyles } from '@mui/styles';

export default makeStyles({
  languageRow: {
    width: '100%',
  },
  languageFieldsRow: {
    width: '100%',
    '& > *:first-child': {
      flexBasis: '220px',
    },
    '& > *:nth-child(2)': {
      flex: 'auto',
    },
  },
  removeButton: {
    cursor: 'pointer',
    fill: 'none',
    '&:hover': {
      fill: '#ccc',
    },
  },
  actionButton: {
    padding: 0,
    height: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: 0,
      '& .MuiSvgIcon-root': {
        fontSize: '12px',
      },
    },
    fontSize: '12px',
  },
});
