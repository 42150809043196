import { useQuery } from '@apollo/client';
import getTournamentsNames from '../../gql/EventsGroups/queries/getTournamentsNames';

const useTournaments = options => {
  const { data, loading, error, refetch } = useQuery(getTournamentsNames, {
    context: {
      apiName: 'autocomplete',
    },
    ...options,
  });

  let tournaments = [];

  if (data) {
    tournaments = data.getTournamentsNames;
  }

  return { tournaments, loading, error, refetch };
};

export default useTournaments;
