import TableActionCard from './TableActionCard';
import TableActionCardExpansion from './TableActionCardExpansion';
import PresetActionCardSummary from '../Presets/PresetActionCardSummary';

const TablePresetsActionCard = ({
  isOpen,
  onChange,
  onChangeHeight,
  style,
  children,
}) => {
  return (
    <TableActionCard style={style}>
      <TableActionCardExpansion
        summary={<PresetActionCardSummary />}
        isOpen={isOpen}
        onChange={onChange}
        onChangeHeight={onChangeHeight}
      >
        {children}
      </TableActionCardExpansion>
    </TableActionCard>
  );
};

export default TablePresetsActionCard;
