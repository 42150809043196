import Checkbox from './Checkbox';
import Grid from '@mui/material/Grid';
import { useCallback } from 'react';

const CheckboxGroupField = ({ value, onChange, options = [] }) => {
  const handleChange = useCallback(
    (v, checked) => {
      onChange(
        options
          .filter(option =>
            option.value === v ? checked : value.indexOf(option.value) !== -1
          )
          .map(option => option.value)
      );
    },
    [value, options, onChange]
  );

  return (
    <Grid container alignItems={'center'} spacing={2}>
      {options.map(option => (
        <Grid item key={option.value}>
          <Checkbox
            label={option.label}
            disableRipple
            value={option.value}
            checked={value.indexOf(option.value) !== -1}
            onChange={checked => handleChange(option.value, checked)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxGroupField;
