import { gql } from '@apollo/client';

const getEventEditModel = gql`
  query getEventEditModel(
    $sportId: SportId!
    $groupId: GroupId!
    $eventId: EventId!
    $descType: DescType!
  ) {
    getEventEditModel(
      sportId: $sportId
      groupId: $groupId
      eventId: $eventId
      descType: $descType
    ) {
      category {
        langs
        id
        countryCode
      }
      tournament {
        langs
        id
      }
      competitors {
        langs
        id
        countryCode
      }
      autocreate
    }
  }
`;

export default getEventEditModel;
