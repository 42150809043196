import { gql } from '@apollo/client';

const getAllLineSettingsNames = gql`
  query getLineSettingsTable($limit: Int!, $offset: Int!) {
    data: getLineSettingsTable(limit: $limit, offset: $offset) {
      items {
        name
      }
    }
  }
`;

export default getAllLineSettingsNames;
