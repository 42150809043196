import { getOperationIdByPath, toEndpoint } from '../utils/schemaHelper';
import { getLoading } from '../reducers/data';
import { useDispatch, useSelector } from 'react-redux';
import { editData } from '../actions/dataActions';
import React, { useCallback } from 'react';
import { useRoutes } from '../providers/RoutesProvider';
import { getSubmitMethodByPath } from '../containers/Form/helpers';
import { useApiHost } from '../providers/ApiHostProvider';

const withUpdate = BaseComponent => props => {
  const { path, params } = props;
  const { submitting, updateData, hasUpdate } = useUpdate(path, params);

  return (
    <BaseComponent
      {...props}
      submitting={submitting}
      updateData={updateData}
      hasUpdate={hasUpdate}
    />
  );
};

export const useUpdate = (path = '', params) => {
  const submitMethod = getSubmitMethodByPath(path);
  const submitUrl = toEndpoint({ path, params });
  const routes = useRoutes();

  const submitting = useSelector(state =>
    getLoading(state, routes, path, path)
  );

  const dispatch = useDispatch();

  const operationId = getOperationIdByPath(routes, path);
  const { apiHost } = useApiHost();

  // const editPath = replaceMethodByPath(path, '/patch');
  // const createPath = replaceMethodByPath(path, '/put');
  // const postPath = replaceMethodByPath(path, '/post');

  // const editOperationId = getOperationIdByPath(routes, editPath);
  // const createOperationId = getOperationIdByPath(routes, createPath);
  // const postOperationId = getOperationIdByPath(routes, postPath);

  // console.log(createPath, createOperationId);
  // console.log(routes);

  const updateData = useCallback(
    body => {
      return dispatch(
        editData({
          apiHost,
          path,
          method: submitMethod,
          editPath: submitUrl,
          body,
          operationId,
          responseBody: body,
        })
      );
    },
    [dispatch, apiHost, path, submitMethod, submitUrl, operationId]
  );

  return {
    submitting,
    updateData,
    hasUpdate: true,
  };
};

export default withUpdate;
