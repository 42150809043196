import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const AddIcon = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#1581FF" />
      <path d="M12 7V17" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M17 12L7 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default AddIcon;
