import { useCallback, useMemo } from 'react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';

const useFancyAutocompleteAllToggle = ({
  onChange,
  value,
  options,
  valueKey,
}) => {
  const toggledAll = useMemo(() => {
    return (
      !isEmpty(value) &&
      !difference(
        options.map(option => option[valueKey]),
        value
      ).length
    );
  }, [value, options, valueKey]);

  const onToggleAll = useCallback(() => {
    onChange(toggledAll ? [] : options.map(option => option[valueKey]));
  }, [toggledAll, valueKey, options, onChange]);

  return [toggledAll, onToggleAll];
};

export default useFancyAutocompleteAllToggle;
