export const ENDPOINT = '/api/v2/auth';

export const ERROR_CODES = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  INVALID_CREDENTIALS: 'INCORRECT_CREDENTIALS',
  ATTEMPTS_EXCEEDED: 'USER_TEMPORARILY_BLOCKED',
  THE_SAME_PASSWORD: 'PASSWORD_ALREADY_USED',
};

export const decodeToken = token => {
  const decoded = new TextDecoder().decode(
    Uint8Array.from(atob(token), m => m.codePointAt(0))
  );
  const [url, expiredAt, uid] = decoded.split('|');
  return {
    url,
    expiredAt,
    uid,
  };
};
