import { TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import classNames from 'clsx';

import useKeyedArray from '../../../hooks/useKeyedArray';
import ChipList from '../../ChipList/ChipList';
import makeStyles from '@mui/styles/makeStyles';
import uniq from 'lodash/uniq';

const CUSTOM_INPUT_FORMATS = {
  betbyId: 'betby_id',
};

const useStyles = makeStyles(() => ({
  idTextField: {
    appearance: 'textfield',

    '&::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    '&::-webkit-inner-outer-button': {
      appearance: 'none',
    },
  },
}));

const ArrayChipField = ({
  name,
  value = [],
  onChange = () => {},
  label,
  helperText,
  error,
  fullWidth,
  type,
  format,
  inputValue,
  setInputValue,
  InputProps,
  maxItems,
  minItems,
}) => {
  const classes = useStyles();
  const {
    items,
    append: addChip,
    removeByKey,
  } = useKeyedArray(value, onChange);

  const appendChip = useCallback(
    value => {
      if (!error) {
        addChip(value);
      }
    },
    [addChip, error]
  );

  const [innerInputValue, setInnerInputValue] = useState('');

  inputValue = inputValue || innerInputValue;
  setInputValue = setInputValue || setInnerInputValue;

  const isIdInput = format === CUSTOM_INPUT_FORMATS.betbyId;

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 13 && inputValue) {
        appendChip(inputValue);
        setInputValue('');
        e.preventDefault();
        return false;
      }
    },
    [appendChip, inputValue, setInputValue]
  );

  const handleChange = useCallback(
    e => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const handleBlur = useCallback(
    e => {
      if (inputValue) {
        appendChip(inputValue);
        setInputValue('');

        e.persist();

        e.stopPropagation();

        setTimeout(() => {
          e.relatedTarget?.click();
        }, 0);
      }
    },
    [appendChip, inputValue, setInputValue]
  );

  const isValid = useCallback(
    value => {
      if (isIdInput) {
        return value.match(/^\d+$/);
      }

      return !!value;
    },
    [isIdInput]
  );

  return (
    <>
      <TextField
        name={name}
        onPaste={event => {
          const text = event.clipboardData.getData('text');
          event.preventDefault();

          const splitRegexp = isIdInput ? /[\s,;]+/ : /[\n\r;]+/;
          const values = uniq(text.split(splitRegexp).filter(isValid));

          addChip(values);
        }}
        disabled={maxItems && value.length >= maxItems}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        label={label}
        helperText={helperText}
        error={error}
        fullWidth={fullWidth}
        type={isIdInput ? 'number' : type}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
        }}
        InputProps={{
          ...InputProps,
          classes: {
            input: classNames({
              [classes.idTextField]: isIdInput,
            }),
          },
        }}
      />
      <ChipList
        minItems={minItems}
        items={items}
        onChange={onChange}
        removeByKey={removeByKey}
      />
    </>
  );
};

export default ArrayChipField;
