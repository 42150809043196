import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef, memo, useCallback, useState } from 'react';
import { animated, config, useTransition } from 'react-spring';
import MenuIcon from '../../../icons/MenuIcon';
import ExpandIcon from '../../../icons/controls/ExpandIcon';
import MenuActiveItemIndicator from './MenuActiveItemIndicator';
import NavigationMenuItem from './NavigationMenuItem';

const useStyles = makeStyles(theme => ({
  menuGroupItem: {
    height: 32,
    width: '100%',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    padding: '0px 24px 0px 16px',
    boxSizing: 'border-box',
    fontSize: '16px',

    '&:hover $menuGroupTitle': {
      color: theme.palette.primary.main,
    },
  },

  menuGroupIcon: {
    color: theme.palette.primary.main,
    width: 32,
    height: 32,
    verticalAlign: 'middle',
  },

  menuGroupTitle: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    color: theme.palette.text.primary,
    transition: 'color 0.2s ease-in',
  },

  menuListRoot: {
    width: '100%',
  },

  menuGroupContent: {
    paddingLeft: 48,
    paddingRight: 16,
    // paddingTop: 4,
    overflow: 'hidden',
    boxSizing: 'border-box',
    willChange: 'height,opacity',
  },
}));

const NavigationMenuGroup = memo(
  forwardRef(
    ({ title, icon, hasActive, items, groupIndex, onItemClick }, ref) => {
      const [expanded, setExpanded] = useState(false);

      const classes = useStyles();

      const transitions = useTransition(expanded, {
        from: { opacity: 0, height: 0 },
        enter: { opacity: 1, height: items.length * 32 },
        leave: { opacity: 0, height: 0 },
        config: config.default,
      });

      const toggleExpanded = useCallback(() => {
        setExpanded(expanded => !expanded);
      }, []);

      return (
        <div>
          <Stack
            ref={ref}
            className={classes.menuGroupItem}
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            onClick={toggleExpanded}
          >
            <MenuIcon className={classes.menuGroupIcon} name={icon} />
            <Box flexGrow={1}>
              <div className={clsx(classes.menuGroupTitle)}>{title}</div>
            </Box>
            <ExpandIcon expanded={expanded} />
            <MenuActiveItemIndicator hidden={!hasActive || expanded} />
          </Stack>
          {transitions(
            (styles, item) =>
              item && (
                <animated.div
                  className={classes.menuGroupContent}
                  style={styles}
                >
                  {items.map((item, itemIndex) => (
                    <NavigationMenuItem
                      isActive={item.isActive}
                      key={item.link}
                      href={item.link}
                      initialQuery={item.initialQuery}
                      label={item.label}
                      badge={item.badge}
                      groupIndex={groupIndex}
                      itemIndex={itemIndex}
                      onClick={onItemClick}
                    />
                  ))}
                </animated.div>
              )
          )}
        </div>
      );
    }
  )
);

export default NavigationMenuGroup;
