import { useCallback } from 'react';
import TextField from '../TextField';
import { debounce } from 'lodash';
import useDebounced from '../../../hooks/useDebounced';
import Autocomplete from '../Autocomplete';
import Select from '../Select/Select';

const AutocompleteSearchInput = ({
  label = 'Input',
  options = [],
  multiple,
  searchValue = '',
  setSearchValue = () => {},
}) => {
  const onChange = useCallback(
    value => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  return (
    <div style={{ width: '352px' }}>
      <Select
        multiple={multiple}
        withExpandIcon={false}
        label={label}
        options={options}
        onChange={onChange}
      />
    </div>
  );
};

export default AutocompleteSearchInput;
