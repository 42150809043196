import WithMeasure from '../../hoc/WithMeasure';
import MuiChip from '@mui/material/Chip';
import CloseIcon from '../../../icons/controls/CloseIcon';
import React, { useCallback, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { animated, useTransition } from 'react-spring';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack/Stack';

const useStyles = makeStyles({
  chips: {
    marginTop: 4,
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: '100%',
    marginLeft: '-4px',
  },
});

const Chip = styled(MuiChip)({
  maxWidth: '100%',
  margin: '4px!important',
});

const ChipListItem = ({
  itemKey,
  style,
  value,
  setWidth,
  label,
  renderChip,
  removeByKey,
  removable,
}) => {
  const onMeasureCb = useCallback(() => {
    return ({ width }) => {
      setWidth(current => {
        return {
          ...current,
          [itemKey]: width + 4,
        };
      });
    };
  }, [setWidth, itemKey]);

  const onDeleteCb = useCallback(() => {
    removeByKey(itemKey);
  }, [itemKey, removeByKey]);

  return (
    <WithMeasure onMeasure={onMeasureCb}>
      {ref => {
        const chip = (
          <Chip
            ref={ref}
            tabIndex={-1}
            color="primary"
            label={label(value)}
            onDelete={removable && onDeleteCb}
            deleteIcon={<CloseIcon />}
          />
        );

        if (renderChip) {
          return renderChip(chip, value);
        }

        return chip;
      }}
    </WithMeasure>
  );
};

const ChipList = ({
  minItems,
  items,
  removeByKey,
  renderChip,
  label = item => item,
}) => {
  const classes = useStyles();
  const [widths, setWidth] = useState({});

  items = useMemo(() => {
    return items.map(item => {
      return {
        ...item,
        width: widths[item.key],
      };
    });
  }, [items, widths]);

  const transitions = useTransition(items, {
    keys: item => {
      return item.key;
    },
    enter: () => {
      return { opacity: 1 };
    },
    update:
      ({ opacity }) =>
      async next => {
        await next({ opacity });
      },
    leave: () => async next => {
      await next({ display: 'none', opacity: 0 });
    },
    from: { opacity: 0 },
  });

  return (
    <Stack spacing={0.5} className={classes.chips} direction={'row'}>
      {transitions((style, { key, value }) => {
        return (
          <ChipListItem
            removeByKey={removeByKey}
            renderChip={renderChip}
            key={key}
            itemKey={key}
            style={style}
            value={value}
            setWidth={setWidth}
            label={label}
            removable={!minItems || items.length > minItems}
          />
        );
      })}
    </Stack>
  );
};

export default ChipList;
