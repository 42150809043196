import { gql } from '@apollo/client';

const getLanguageInfo = gql`
  query getLanguageInfo {
    getLanguageInfo {
      code
      name
    }
  }
`;

export default getLanguageInfo;
