import { gql } from '@apollo/client';

const TOURNAMENTS_FILTER_AUTOCOMPLETE_QUERY = gql`
  query getTournamentsEvents($sportId: SportId!, $categoryId: CategoryId!) {
    data: getTournamentsEvents(sport_id: $sportId, category_id: $categoryId) {
      id
      name
      active_events_count
    }
  }
`;

export default TOURNAMENTS_FILTER_AUTOCOMPLETE_QUERY;
