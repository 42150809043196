import Box from '@mui/material/Box';
import { getHeroBannerFontSizes } from './BannerImagePreview';

const HeroBannerTextPreview = ({
  src,
  previewSize,
  fields: [title, subtitle, legalText],
}) => {
  const fontSizes = getHeroBannerFontSizes(previewSize);

  return (
    <Box
      sx={{
        fontFamily: 'IBM Plex Sans',
        borderRadius: '4px',
        backgroundSize: 'contain',
        width: 1088,
        height: previewSize.height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#fff',
        padding: '0 16px',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: `${fontSizes.title}px`,
            textShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
            lineHeight: '109.71%',
            overflowWrap: 'anywhere',
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            marginTop: '8px',
            fontWeight: 600,
            fontSize: `${fontSizes.subtitle}px`,
            lineHeight: '16px',
            textShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
            letterSpacing: '0.02em',
            overflowWrap: 'anywhere',
          }}
        >
          {subtitle}
        </Box>
      </Box>

      <Box
        sx={{
          height: 24,
          marginBottom: '8px',
          fontWeight: 400,
          fontSize: `${fontSizes.legal}px`,
          lineHeight: '10px',
          textShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
          maxWidth: 600,
          overflowWrap: 'anywhere',
        }}
      >
        {legalText}
      </Box>
    </Box>
  );
};

export default HeroBannerTextPreview;
