import get from 'lodash/get';

export const isDateArray = schema => get(schema, 'items.format') === 'date';
export const isDateTimeArray = schema =>
  get(schema, 'items.format') === 'date-time';

const getDefaultValue = schema => {
  if (schema.type === 'object') {
    return Object.keys(schema.properties).reduce((defaultValues, name) => {
      defaultValues[name] = getDefaultValue(schema.properties[name]);
      return defaultValues;
    }, {});
  }

  return (
    schema.default ||
    (isDateArray(schema) || isDateTimeArray(schema)
      ? ''
      : schema.items
      ? []
      : schema.type === 'boolean'
      ? undefined
      : schema.type === 'integer' || schema.type === 'number'
      ? null
      : '')
  );
};

export default getDefaultValue;
