import React from 'react';
import { useParams } from 'react-router-dom';
import DynamicComponent from './DynamicComponent';

export const RouteWithParams = ({ path }) => {
  const params = useParams();
  return <DynamicComponent path={path} params={params} />;
};

const DynamicRoutes = () => {
  return;
};

export default DynamicRoutes;
