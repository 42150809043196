const getSchemaByPath = (schema, pathArr) => {
  const isArray = schema.type === 'array';
  const properties = isArray
    ? schema.items.type === 'object'
      ? schema.items.properties
      : schema.items
    : schema.properties || schema.additionalProperties;
  const [curPath, ...nextPathArr] = isArray ? pathArr.slice(1) : pathArr;

  return nextPathArr.length
    ? properties[curPath] && getSchemaByPath(properties[curPath], nextPathArr)
    : properties[curPath];
};

export default getSchemaByPath;
