import TextField from '@mui/material/TextField';
import React from 'react';

const StringFilter = ({
  label,
  fullWidth,
  InputProps,
  className,
  error,
  helperText,
  ...props
}) => (
  <TextField
    variant="outlined"
    margin="dense"
    InputProps={InputProps}
    className={className}
    fullWidth={fullWidth}
    error={error}
    helperText={helperText}
    InputLabelProps={{
      shrink: props.value ? true : undefined,
      style: {
        overflow: 'hidden',
      },
    }}
    {...props}
    label={label}
  />
);

export default StringFilter;
