import { gql } from '@apollo/client';

const getLineSettingsById = gql`
  query getLineSettingsById($lineSettingsId: LineSettingsId!) {
    data: getLineSettingsById(lineSettingsId: $lineSettingsId) {
      id
      brandIds
      name
      brandsCount
      updatedBy
      created
      updated
      margin {
        id
        name
      }
      coverage {
        id
        name
      }
      sources {
        id
        name
      }
    }
  }
`;

export default getLineSettingsById;
