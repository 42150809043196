import { usePresets } from '../providers/PresetsProvider';
import { useCallback, useMemo } from 'react';
import get from 'lodash/get';

const usePresetUiSchema = () => {
  const { activePreset, updatePreset } = usePresets();

  const presetUiSchema = useMemo(
    () => get(activePreset, ['data', 'ui_schema']),
    [activePreset]
  );

  const updatePresetUiSchema = useCallback(
    uiSchema => {
      // setUiSchema(uiSchema);

      updatePreset(activePreset.id, {
        ...activePreset,
        data: {
          ...activePreset.data,
          ui_schema: uiSchema,
        },
      });
    },
    [updatePreset, activePreset]
  );

  return useMemo(
    () => ({
      updatePresetUiSchema,
      uiSchema: presetUiSchema,
    }),
    [presetUiSchema, updatePresetUiSchema]
  );
};

export default usePresetUiSchema;
