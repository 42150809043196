import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserToken } from '../../reducers/users';
import useApiHost from './useApiHost';
import { getApiUrl } from '../../hooks/useApi';

const useFileUpload = ({ brandId }) => {
  const { apiHost } = useApiHost();

  const token = useSelector(getUserToken);

  const endpoint = `/api/v1/Files/brand/${brandId}/upload_images`;

  const uploadFiles = useCallback(
    files => {
      const apiUrl = getApiUrl(endpoint, apiHost);

      const formData = new FormData();

      formData.append('files', files);

      return fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
    },
    [apiHost, endpoint, token]
  );

  return useMemo(
    () => ({
      uploadFiles,
    }),
    [uploadFiles]
  );
};

export default useFileUpload;
