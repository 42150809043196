import React, { memo } from 'react';
import TableCell from './TableCell';
import TableRowActions from './TableRowActions';

const TableRowActionsCell = ({ className, rowActions, rowData }) => {
  const hasValidAction =
    rowActions &&
    !!rowActions
      .map(action => (typeof action === 'function' ? action(rowData) : action))
      .filter(action => !!action).length;

  return (
    <TableCell
      rowClassName={className}
      style={{
        padding: '0 4px',
        position: 'sticky',
        right: '0',
      }}
    >
      {hasValidAction && (
        <TableRowActions rowActions={rowActions} row={rowData} />
      )}
    </TableCell>
  );
};

export default memo(TableRowActionsCell);
