import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../components/Button/Button';
import FormGroup from '../../components/Form/FormGroup';

const FailedRequestsRetryForm = ({
  selected,
  onSubmit = () => {},
  loading,
}) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <Box mt={1} px={0.25}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormGroup>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid item>
                  {selected
                    ? `${selected} Failed requests selected `
                    : 'No Failed requests selected'}
                </Grid>

                <Grid item>
                  <Button
                    disabled={loading || !selected || !isValid}
                    color={'primary'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    Retry
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
        </form>
      </Box>
    </FormProvider>
  );
};

export default FailedRequestsRetryForm;
