import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import {
  DisabledItemArea,
  DraggableSelectItem,
  DraggableSelectMenu,
  MenuCaption,
  MenuTitle,
  SelectContainer,
  SelectSearch,
  VirtualizedMenu,
} from '../../../components/';
import { useCoverageTemplateSelect } from '../../../hooks/coverage/select/useCoverageTemplateSelect';
import CoverageEventOption from '../Options/CoverageEventOption';

const CoverageEventSelect = ({ templateId, selectKey, nodeIndex, options }) => {
  const { getValues } = useFormContext();

  const lineType = getValues('lineType');
  const {
    nodePath: { [lineType]: nodePath },
  } = useTreeSelectContext();

  const selectedId = nodePath.eventId;

  const {
    displayOptions,
    optionsFullListRef,
    isCoverageWriter,
    setDisplayOptions,
    onSearchFieldChange,
  } = useCoverageTemplateSelect({
    options,
    idKey: 'eventId',
    getFormValues: getValues,
  });

  return (
    <SelectContainer style={{ width: '450px' }}>
      <SelectSearch
        label={`Search Event`}
        onSearchFieldChange={onSearchFieldChange}
      />
      <MenuCaption>
        <MenuTitle name={'Event'} />
      </MenuCaption>
      <DraggableSelectMenu setDisplayOptions={setDisplayOptions}>
        <VirtualizedMenu
          rowCount={displayOptions.length}
          itemRender={({ index }) => {
            const option = displayOptions[index];

            return (
              <Box position={'relative'}>
                {option.readOnly && <DisabledItemArea />}
                <DraggableSelectItem
                  selected={option.eventId === selectedId}
                  index={index}
                  itemValue={option}
                  idKey={'sportId'}
                  virtualized
                >
                  <CoverageEventOption
                    key={selectKey + '-' + option.eventId}
                    templateId={templateId}
                    nodePath={nodePath}
                    nodeIndex={nodeIndex}
                    selected={option.eventId === selectedId}
                    itemValue={option}
                    itemIndex={index}
                    options={options}
                    optionsFullListRef={optionsFullListRef}
                    setDisplayOptions={setDisplayOptions}
                    lineType={lineType}
                    configurationDisabled={!isCoverageWriter}
                  />
                </DraggableSelectItem>
              </Box>
            );
          }}
        />
      </DraggableSelectMenu>
    </SelectContainer>
  );
};

export default CoverageEventSelect;
