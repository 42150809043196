import { useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useConfirmationPrompt from './useConfirmationPrompt';

const useNavigationPreventPrompt = ({ prevent }) => {
  const { openConfirmation } = useConfirmationPrompt();
  const { navigator } = useContext(NavigationContext);
  const retryRef = useRef();
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (confirmed && retryRef.current) {
      retryRef.current();
    }
  }, [confirmed]);

  useEffect(() => {
    if (!prevent || confirmed) {
      return;
    }

    const unblock = navigator.block(({ retry }) => {
      retryRef.current = retry;
      if (prevent) {
        openConfirmation({
          buttonLabel: 'Leave',
          title: 'Are you sure you want to leave?',
          message: `Changes that you made may not be saved.`,
          destructive: true,
          callback: async () => {
            setConfirmed(true);
          },
        });
        return false;
      } else {
        return true;
      }
    });

    return () => {
      unblock();
    };
  }, [openConfirmation, navigator, prevent, confirmed]);
};

export default useNavigationPreventPrompt;
