import clsx from 'clsx';
import { cloneElement, useMemo, useState } from 'react';
import DraggableRef from '../../../../../uiComponents/Table/DraggableRef';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const DraggableSelectItem = ({
  selected = false,
  index,
  itemValue,
  children,
  idKey,
  virtualized = false,
}) => {
  const classes = useTreeSelectStyles();
  const [checked, setChecked] = useState(true);

  return (
    <DraggableRef
      key={itemValue[idKey]}
      draggableId={`id-${itemValue[idKey]}`}
      index={index}
    >
      {(provided, snapshot) => {
        const renderSelectItem = useMemo(() => {
          return (
            children &&
            cloneElement(children, {
              provided: provided,
            })
          );
        }, [provided]);

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={clsx(classes.dragMenuItemRoot, {
              [classes.dragMenuItemRootDragging]: snapshot.isDragging,
              [classes.treeSelectItemSelected]: selected,
              [classes.virtualizedDragging]: virtualized,
            })}
            onClick={() => {
              setChecked(!checked);
            }}
          >
            {renderSelectItem}
          </div>
        );
      }}
    </DraggableRef>
  );
};

export default DraggableSelectItem;
