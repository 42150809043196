import { gql } from '@apollo/client';

const getCoverageNames = gql`
  query getCoverageNames {
    data: getCoverageNames {
      id
      name
    }
  }
`;

export default getCoverageNames;
