import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import data from './data';
import form from './form';
import notification from './notification';
import modal from './modal';
import persistable from './persistable';
import { LOGOUT_SUCCESS } from '../actions/authActions';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    data,
    form,
    notification,
    modal,
    persistable,
  });

const createRootReducer = history => (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    const { persistable } = state;
    state = { persistable };
  }

  return rootReducer(history)(state, action);
};

export default createRootReducer;
