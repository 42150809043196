import { gql } from '@apollo/client';

const getCoveragePreset = gql`
  query getCoveragePreset($presetId: PresetId!) {
    data: getCoveragePreset(presetId: $presetId) {
      nodeSelector {
        sportId
        categoryId
        tournamentId
        eventId
        market {
          marketId
          specifier
        }
      }
      status
      priority
      cashOut
      mainMarketPriority
      groups {
        selector {
          marketId
          specifier
        }
        group
        status
        priority
      }
    }
  }
`;

export default getCoveragePreset;
