import { useCallback, useEffect, useMemo, useReducer } from 'react';
import throttle from 'lodash/throttle';

function reducer(state, action) {
  switch (action.type) {
    case 'onChangeColumnWidth':
      return {
        ...state,
        width: {
          ...state.width,
          [action.payload.key]: action.payload.width,
          [action.payload.nextKey]:
            state.width[action.payload.nextKey] +
            (state.width[action.payload.key] - action.payload.width),
        },
      };
    case 'onChangeTotalWidth':
      return {
        ...state,
        totalWidth: action.payload.totalWidth,
      };
    default:
      throw new Error();
  }
}

const useTableColumnWidth = (initialWidth, order) => {
  const [{ width, totalWidth }, dispatch] = useReducer(reducer, {
    width: initialWidth,
  });

  const changeTotalWidth = useCallback(totalWidth => {
    dispatch({
      type: 'onChangeTotalWidth',
      payload: {
        totalWidth,
      },
    });
  }, []);

  const throttledChangeTotal = useMemo(
    () => throttle(changeTotalWidth, 500),
    [changeTotalWidth]
  );

  const changeColumnWidth = useCallback(
    (key, width) => {
      const index = order.indexOf(key);
      const nextIndex = index + 1;

      dispatch({
        type: 'onChangeColumnWidth',
        payload: {
          key,
          nextKey: order[nextIndex] || null,
          width,
        },
      });
    },
    [order]
  );

  useEffect(() => {
    throttledChangeTotal(
      Object.values(width).reduce((acc, value) => {
        return acc + value;
      }, 0)
    );
  }, [width, throttledChangeTotal]);

  return useMemo(() => {
    return {
      changeColumnWidth,
      width,
      totalWidth,
    };
  }, [changeColumnWidth, width, totalWidth]);
};

export default useTableColumnWidth;
