import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  emptyField: {
    color: '#8496AB',
    padding: '0 8px',
  },
});

const EmptyField = () => {
  const classes = useStyles();

  return <span className={classes.emptyField}>—</span>;
};

export default EmptyField;
