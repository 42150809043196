import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TradingEventSpecifierCard from './TradingEventSpecifierCard';
import Button from '../../../../../components/Button/Button';
import { prefixSpecifierName } from '../../TradingEventEditContainer';

const useStyles = makeStyles({
  specifiersList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  specifierCard: {
    marginRight: 16,
    marginBottom: 16,
  },
  addOutcomeButton: {
    minWidth: 'unset',
    marginBottom: 16,
  },
});

const TradingEventSpecifiersList = ({
  market,
  markets,
  specifiers,
  form,
  getSpecifiersList,
  isEventUntradable,
  getOutcomeName,
  isOutcomeDirty,
  onToggleSpecifier,
  onUpdateOutcome,
  onRemoveSpecifier,
  onAddOutcomeClick,
  onAddMarketFreeSpecifierClick,
  isSpecifierEditable,
  isFreeTextMarket,
  isMain,
  marginLadderValues,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.specifiersList}>
      {Object.keys(specifiers).map(specifier => {
        const outcomes = market.outcomes[specifier];

        const specifierName = prefixSpecifierName(specifier);

        return (
          <TradingEventSpecifierCard
            form={form}
            marketId={market.id}
            specifierName={specifierName}
            isEventUntradable={isEventUntradable}
            outcomes={outcomes}
            markets={markets}
            getOutcomeName={getOutcomeName}
            specifier={specifier}
            isOutcomeDirty={isOutcomeDirty}
            onToggleSpecifier={onToggleSpecifier}
            onUpdateOutcome={onUpdateOutcome}
            onRemoveSpecifier={onRemoveSpecifier}
            isShowCheckbox={!isMain}
            isShowDeleteButton={
              !isMain && isSpecifierEditable({ marketId: market.id })
            }
            marginLadderValues={marginLadderValues}
            className={classes.specifierCard}
          />
        );
      })}
      {!isMain &&
        isSpecifierEditable({
          marketId: market.id,
        }) && (
          <Button
            color={'lightBlue'}
            onClick={() =>
              isFreeTextMarket({ marketId: market.id })
                ? onAddMarketFreeSpecifierClick({ marketId: market.id })
                : onAddOutcomeClick({
                    marketId: market.id,
                    specifiers: getSpecifiersList({ marketId: market.id }),
                  })
            }
            className={classes.addOutcomeButton}
          >
            Add Outcome
          </Button>
        )}
    </div>
  );
};

export default TradingEventSpecifiersList;
