import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WatchIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 32 32'} {...props}>
      <g fill={'none'}>
        <path
          d="M9 16C9 16 12.134 12 16 12C19.866 12 23 16 23 16C23 16 19.866 20 16 20C12.134 20 9 16 9 16Z"
          stroke="currentColor"
        />
        <circle cx="16" cy="16" r="3" fill="currentColor" />
      </g>
    </SvgIcon>
  );
};

export default WatchIcon;
