import React from 'react';
import NewLoginPage from './pages/Login';
import SetPasswordPage from './pages/SetPassword';
import TwoFactorVerificationPage from './pages/2FA';
import ForgotPasswordPage from './pages/ForgotPassword';
import ChangePasswordPage from './pages/ChangePassword';

const paths = {
  login: '/',
  set: 'set',
  tfa: 'tfa',
  forgot: 'forgot',
  change: 'change',
};

const routes = [
  {
    path: `${paths.login}*`,
    element: <NewLoginPage />,
  },
  {
    path: paths.set,
    element: <SetPasswordPage />,
  },
  {
    path: paths.forgot,
    element: <ForgotPasswordPage />,
  },
  {
    path: paths.change,
    element: <ChangePasswordPage />,
  },
  {
    path: paths.tfa,
    element: <TwoFactorVerificationPage />,
  },
];

export { routes, paths };
