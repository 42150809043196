import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
  indeterminateCheckbox: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
}));

const CheckboxIndeterminateIcon = ({ className, ...props }) => {
  const { indeterminateCheckbox } = useStyles();

  return (
    <SvgIcon
      viewBox={'0 0 14 14'}
      className={classNames(indeterminateCheckbox, className)}
      {...props}
    >
      <path
        d="M0 2C0 0.89543 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V12C14 13.1046 13.1046 14 12 14H2C0.89543 14 0 13.1046 0 12V2Z"
        fill="currentColor"
      />
      <path
        d="M10 7H4"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CheckboxIndeterminateIcon;
