import React from 'react';
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  virtualizedList: {
    '&.ReactVirtualized__Grid__innerScrollContainer': {
      overflow: 'auto !important',
    },
    '&.ReactVirtualized__Grid': {
      overflow: 'auto !important',
    },
  },
});

const VirtualizedList = ({ items, renderItem, className }) => {
  const classes = useStyles();
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 54,
  });

  const rowRenderer = ({ index, key, style, parent }) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div style={style} ref={registerChild}>
            {renderItem(items[index])}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          rowHeight={cache.rowHeight}
          rowCount={items.length}
          overscanRowCount={6}
          rowRenderer={rowRenderer}
          className={clsx(classes.virtualizedList, className)}
        />
      )}
    </AutoSizer>
  );
};

export default VirtualizedList;
