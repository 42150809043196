import { gql } from '@apollo/client';

const getAttemptsList = gql`
  query getAttemptsList($request_id: ID!) {
    data: getAttemptsList(request_id: $request_id) {
      items {
        id
        request_id
        error
        status_code
        resp_body
        timestamp
        url
      }
      total
    }
  }
`;

export default getAttemptsList;
