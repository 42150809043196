import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { apiMiddleware } from 'redux-api-middleware';
import authApiInjector from '../enhancers/authApiInjector';
import apiError from '../enhancers/apiError';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from '../reducers';
import history from '../history';
import throttle from 'lodash/throttle';
import {
  loadFromLocalState,
  saveToLocalState,
  localStorageKeys,
} from './localStorage';

const preloadedState = loadFromLocalState(localStorageKeys.sptState);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 10,
    })
  : compose;

const store = createStore(
  createRootReducer(history),
  preloadedState,
  composeEnhancers(
    applyMiddleware(
      authApiInjector,
      apiMiddleware,
      apiError,
      thunkMiddleware,
      routerMiddleware(history)
    )
  )
);

store.subscribe(
  throttle(() => {
    const { persistable } = store.getState();
    saveToLocalState(localStorageKeys.sptState, { persistable });
  }, 1000)
);

if (module.hot) {
  module.hot.accept('../reducers', () => {
    const nextReducer = require('../reducers').default;
    store.replaceReducer(nextReducer);
  });
}

export default store;
