import React from 'react';
import { Divider, Stack, Typography, Button } from '@mui/material';
import logo from './assets/logo.svg';
import ApolloClientProvider from '../../v2/providers/ApolloClientProvider';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { ENDPOINT } from './utils';
import DialogProvider from '../../v2/providers/DialogProvider';

const AuthorizationLayout = () => {
  const authRoutes = useRoutes(routes);

  return (
    <ApolloClientProvider
      endpoint={ENDPOINT}
      allowSubscription={false}
      key={'auth'}
    >
      <DialogProvider />
      <Stack
        justifyContent={'space-around'}
        height={'100vh'}
        padding={'75px 0'}
        backgroundColor={'red'}
        width={'368px'}
      >
        <Stack
          gap={'10px'}
          direction={'row'}
          justifyContent={'space-between'}
          divider={<Divider flexItem orientation={'vertical'} />}
        >
          <img src={logo} style={{ maxWidth: '160px' }} />
          <Typography
            sx={{
              letterSpacing: '3px',
              color: '#8496AB',
              fontSize: '20px',
            }}
          >
            BACKOFFICE
          </Typography>
        </Stack>
        <Stack
          sx={{
            backgroundColor: '#fff',
            borderRadius: 8,
            padding: '24px',
          }}
        >
          {authRoutes}
        </Stack>
        {/*<Button variant={'text'}>Privacy policy</Button>*/}
      </Stack>
    </ApolloClientProvider>
  );
};

export default AuthorizationLayout;
