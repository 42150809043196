import { useCallback, useMemo } from 'react';
import { usePresets } from '../providers/PresetsProvider';

const useTableColumnsReorderPreset = initialOrder => {
  const { activePreset, updatePreset } = usePresets();

  const order = useMemo(() => {
    if (activePreset) {
      // TODO refactor presets
      if (
        activePreset.data.columns_order.length &&
        activePreset.data.columns_order.length === initialOrder.length
      ) {
        return activePreset.data.columns_order;
      }

      return initialOrder;
    }

    return initialOrder;
  }, [initialOrder, activePreset]);

  const onReorder = useCallback(
    columnsOrder => {
      if (activePreset) {
        updatePreset(activePreset.id, {
          ...activePreset,
          data: {
            ...activePreset.data,
            columns_order: columnsOrder,
          },
        });
      }
    },
    [activePreset, updatePreset]
  );

  return useMemo(() => [order, onReorder], [order, onReorder]);
};

export default useTableColumnsReorderPreset;
