import * as React from 'react';

export const TableRowActionsContext = React.createContext({});

const TableRowActionsProvider = ({ children, value }) => {
  return (
    <TableRowActionsContext.Provider value={value}>
      {children}
    </TableRowActionsContext.Provider>
  );
};
export default TableRowActionsProvider;
