import { initialNodePath } from '../../../../../providers/TreeSelectProvider';

export const changePath = ({
  nodePath,
  setNodePath,
  nodeId,
  nodeKey,
  nodeIndex,
  lineType,
}) => {
  let path = structuredClone(nodePath);
  path[nodeKey] = nodeId;
  Object.keys(path).forEach((key, index) => {
    if (index > nodeIndex && key !== nodeKey) {
      path[key] = initialNodePath[key];
    }
  });

  setNodePath(currentPath => ({
    ...currentPath,
    [lineType]: path,
  }));

  return path;
};
