import React, { useMemo } from 'react';
import TemplateBrandsTable from './TemplateBrandsTable';
import { useQuery } from '@apollo/client';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useTablePagination from '../../../../hooks/useTablePagination';
import getBrandsByTemplateId from '../../../../gql/sportbook-config/templates/queries/getBrandsByTemplateId';
import { tableNames } from '../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../components/ErrorWidget';

const TemplateBrandsRow = ({ templateId }) => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.TemplateBrands,
    onChangeLimit: () => {},
  });

  const queryFilters = useMemo(() => {
    return {
      limit,
      offset,
      templateId,
    };
  }, [templateId, limit, offset]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(getBrandsByTemplateId, {
    variables: {
      input: queryFilters,
    },
  });

  const items = useMemo(() => {
    return (data && data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TemplateBrandsTable
        data={items}
        loading={loading}
        templateId={templateId}
        error={error}
      />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default TemplateBrandsRow;
