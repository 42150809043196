import { isEmpty } from 'lodash';
import { reorderDragItemsData } from '../components/DraggableSelectMenu';

export const getConfiguredSourcesOption = ({
  prioritySources,
  providerConfigs,
  parentConfig,
  isSourcesConfiguredInitially = false,
}) => {
  const prioritySourcesForMutate = structuredClone(prioritySources);

  //resolve with configs
  const configuredList = prioritySourcesForMutate.map((value, index) => {
    const change =
      providerConfigs &&
      providerConfigs.find(
        changeValue => changeValue.provider === value.source
      );

    if (change) {
      return {
        source: change.provider ?? value.source,
        priority: change.priority ?? value.priority,
        enabled: change.enabled ?? value.enabled,
      };
    }
    return value;
  });

  const notConfigured =
    isEmpty(providerConfigs) && !isSourcesConfiguredInitially;

  //after apply changes that was configured, we need check is sources need to be inherited
  //sources inherits if curr list after config havnt enabled items
  if (
    configuredList.every(value => !value.enabled) &&
    !isEmpty(parentConfig) &&
    notConfigured
  ) {
    return parentConfig.reduce((accumulator, currentValue) => {
      const sourceIndex = accumulator.findIndex(
        searchedValue => searchedValue.source === currentValue.source
      );
      if (sourceIndex === -1) {
        return accumulator;
      }

      if (currentValue.enabled) {
        accumulator[sourceIndex].enabled = true;
      }
      if (accumulator[sourceIndex].enabled) {
        return reorderDragItemsData({
          startIndex: sourceIndex,
          endIndex: currentValue.priority - 1,
          options: accumulator,
        });
      }

      return accumulator;
    }, configuredList);
  }
  return configuredList;
};
