import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  cell: {
    height: 38,
    width: '100%',
    backgroundColor: '#fff',
  },

  row: {
    animationName: '$tablePlaceholderLoading',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    width: '100%',
    padding: 2,
  },

  '@keyframes tablePlaceholderLoading': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
});

const TableLoadingRowPlaceholder = ({ height = 42, total = 5, index }) => {
  const classes = useStyles();

  return (
    <div
      key={index}
      className={clsx(classes.row, 'tableRow')}
      style={{
        animationDelay: `${index / 5}s`,
        height,
      }}
    >
      <div
        className={clsx(classes.cell, 'tableRowColumn')}
        style={{
          opacity: 1 - Math.max(5 + (index - total), 0) * 0.2,
        }}
      />
    </div>
  );
};

export default TableLoadingRowPlaceholder;
