import React, { useState } from 'react';
import Chart from '../../components/Chart';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '../../components/Accordion';

const useStyles = makeStyles(theme => ({
  chartsList: {
    marginTop: 8,
  },
  chartsTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },

  chartsAccordionRoot: {
    padding: 16,
  },

  content: {
    marginTop: -2 * theme.spacing(1),
    marginBottom: -2 * theme.spacing(1),
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  chart: {
    flex: '1 0 100%',
    marginTop: 2 * theme.spacing(1),
    marginBottom: 2 * theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    content: {
      padding: `${theme.spacing(1)} ${3 * theme.spacing(1)} ${
        3 * theme.spacing(1)
      }px`,
    },
  },
}));

const ChartList = ({ defaultExpanded, charts, data }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className={classes.chartsList}>
      <Accordion
        classes={{
          root: classes.chartsAccordionRoot,
        }}
        summary={
          <div>
            <span className={classes.chartsTitle}>Charts</span>
          </div>
        }
        expanded={expanded}
        onChange={(event, isExpanded) => {
          setExpanded(isExpanded);
        }}
      >
        {charts.map(({ type, ...config }, i) => (
          <Chart
            key={i}
            className={classes.chart}
            type={type}
            config={config}
            data={data && data.slice().reverse()}
          />
        ))}
      </Accordion>
    </div>
  );
};

export default ChartList;
