import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BurgerIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 16 14'} {...props}>
      <path d="M1 13H15" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 7H15" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 1H15" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
};

export default BurgerIcon;
