import { gql } from '@apollo/client';

const ADMIN_BETSTREAM_FIELDS = gql`
  fragment AdminBetstreamFields on BetStreamModel {
    sport {
      market_providers
    }
    delay
    reliability_coefficient
    deep_monitoring
  }
`;

export default ADMIN_BETSTREAM_FIELDS;
