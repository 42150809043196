import { useCallback, useEffect, useMemo, useState } from 'react';
import useTableMaxContentSizesInColumns from './useTableMaxContentSizesInColumns';
import { isEmpty } from 'lodash';

const useTableGrid = ({
  columns,
  order = [],
  toggledColumns,
  expandable,
  onChangeColumnsWidth,
  minWidthConfig,
  bulk,
  initialColumnWidth,
  hideConfigMenu,
  withUndo = false,
  //for adjust column sizes by maximal in cell when we need it
  tableRef,
  loading,
  data,
  columnSizesInPixel = false,
}) => {
  const columnKeys = useMemo(() => {
    return order.filter(key => toggledColumns.indexOf(key) !== -1);
  }, [order, toggledColumns]);

  const fixedColumns = useMemo(() => {
    return (
      columns &&
      Object.keys(columns).reduce((acc, key) => {
        if (typeof columns[key] === 'string') {
          return {
            ...acc,
            [key]: true,
          };
        }

        return acc;
      }, {})
    );
  }, [columns]);

  const [columnWidth, setColumnWidth] = useState(columns);

  useEffect(() => {
    setColumnWidth(columns);
  }, [columns]);

  columnSizesInPixel &&
    useTableMaxContentSizesInColumns({
      tableRef,
      toggledColumns,
      loading,
      data,
      columnWidth,
      setColumnWidth,
      fixedColumns,
      initialColumnWidth,
    });

  //just making column and table wider
  const changeSizeByPixels = useCallback(({ current, key, diffPx }) => {
    const currentSize = current[key];

    const newWidth = currentSize + diffPx;

    const allowResize = newWidth > 0;

    return {
      ...current,
      [key]: allowResize ? newWidth : currentSize,
    };
  }, []);

  //resize by decrease neighbor column size
  const changeSizeByFr = useCallback(
    ({ current, key, diff }) => {
      const index = columnKeys.indexOf(key);
      const nextIndex = index + 1;
      const nextKey = columnKeys[nextIndex];

      const currentSize = current[key];
      const nextCurrentFr = current[nextKey];

      const frDiff = (currentSize * diff) / 100;
      const newWidth = currentSize + frDiff;
      const nextNewWidth = nextCurrentFr - frDiff;

      const allowResize = newWidth > 0 && nextNewWidth > 0;

      return {
        ...current,
        [key]: allowResize ? newWidth : currentSize,
        [nextKey]: allowResize ? nextNewWidth : nextCurrentFr,
      };
    },
    [columnKeys]
  );

  const changeColumnWidth = useCallback(
    (key, diff, diffPx) => {
      setColumnWidth(current => {
        const result = columnSizesInPixel
          ? changeSizeByPixels({ current, key, diffPx })
          : changeSizeByFr({ current, key, diff });

        onChangeColumnsWidth(result);

        return result;
      });
    },
    [
      onChangeColumnsWidth,
      columnSizesInPixel,
      changeSizeByFr,
      changeSizeByPixels,
    ]
  );

  const columnsTemplate = useMemo(() => {
    return order
      .filter(key => toggledColumns.indexOf(key) !== -1)
      .map(key => {
        const value = columnWidth[key];

        if (columnSizesInPixel) {
          return value && !isEmpty(data)
            ? columnWidth.px
              ? `${value}px`
              : `minmax(${value}px, auto)`
            : 'fit-content(100%)';
        }

        if (fixedColumns[key]) {
          return value;
        }

        if (minWidthConfig) {
          const minWidth = minWidthConfig[key] || '24px';
          return `minmax(${minWidth}, ${value}fr)`;
        }

        return `minmax(24px, ${value}fr)`;
      });
  }, [
    data,
    order,
    columnWidth,
    toggledColumns,
    fixedColumns,
    minWidthConfig,
    columnSizesInPixel,
  ]);

  const resetColumnWidth = useCallback(
    key => {
      const index = columnKeys.indexOf(key);
      const nextIndex = index + 1;
      const nextKey = columnKeys[nextIndex];

      setColumnWidth(current => {
        const result = {
          ...current,
          [key]: initialColumnWidth && initialColumnWidth[key],
          [nextKey]: initialColumnWidth && initialColumnWidth[nextKey],
        };

        onChangeColumnsWidth(result);

        return result;
      });
    },
    [onChangeColumnsWidth, initialColumnWidth, columnKeys]
  );

  return useMemo(() => {
    let template = columnsTemplate;
    let rowButtonsWidth = 0;

    if (expandable) {
      rowButtonsWidth += 34;
    }

    if (withUndo) {
      template = [...template, hideConfigMenu ? '0' : '28px'];
    }

    template = [...template, hideConfigMenu ? '0' : '28px'];

    if (bulk) {
      rowButtonsWidth += 34;
    }

    if (bulk || expandable) {
      template = [`${rowButtonsWidth}px`, ...template];
    }

    return {
      template: template.join(' '),
      changeColumnWidth,
      resetColumnWidth,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnsTemplate,
    changeColumnWidth,
    expandable,
    bulk,
    resetColumnWidth,
    withUndo,
  ]);
};

export default useTableGrid;
