import React, { useCallback, useContext } from 'react';
import { TableBody as MuiTableBody } from '@mui/material';
import { TableRowsDataContext } from '../../providers/TableRowsDataProvider';
import NestedIndexesProvider from '../../providers/NestedIndexProvider';
import TableRow from './TableRow';
import TableEmptyState from './TableEmptyState';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import TableSummary from './TableSummary';
import { DataContext, useData } from '../../providers/DataProvider';
import get from 'lodash/get';
import { compileTemplate } from '../Field/FieldUtils';
import useTableSummary from '../../hooks/useTableSummary';
import TableSummaryProvider from '../../providers/TableSummaryProvider';

const useStyles = makeStyles({
  emptyTableRoot: {
    display: 'block',
    width: '100%',
  },
});

const TableBody = ({
  columns = [],
  nested = [],
  setIsScrollDisabled = () => {},
  isEmpty,
  firstSkipped,
  nestedKey,
}) => {
  const rows = useContext(TableRowsDataContext);
  const classes = useStyles();

  const dataContext = useData();
  const { data, xStream } = dataContext;

  const summary = useTableSummary();

  const uniqueKey = get(xStream, 'x-options.store.key', 'id');

  const renderEmptyPlaceholder = useCallback(
    isEmpty => {
      setIsScrollDisabled(isEmpty);
      return isEmpty && <TableEmptyState firstSkipped={firstSkipped} />;
    },
    [setIsScrollDisabled, firstSkipped]
  );

  return (
    <MuiTableBody
      className={classNames({
        [classes.emptyTableRoot]: isEmpty,
      })}
    >
      {rows.map((row, index) => {
        const indexes = {
          [nestedKey || 'items']: index,
          nested: index,
        };

        const uniqueKeyValue = compileTemplate(
          '{{0}}',
          [uniqueKey],
          data,
          indexes
        );

        return (
          <NestedIndexesProvider
            key={
              uniqueKeyValue && uniqueKeyValue !== 'undefined'
                ? uniqueKeyValue
                : index
            }
            indexes={indexes}
          >
            <TableRow data={row} nested={nested} columns={columns} />
          </NestedIndexesProvider>
        );
      })}

      {renderEmptyPlaceholder(isEmpty)}
      {summary && (
        <TableSummaryProvider summary>
          <TableSummary />
          {summary.map((summaryRow, index) => {
            const indexes = {
              items: index,
            };

            return (
              <DataContext.Provider
                key={index}
                value={{
                  ...dataContext,
                  data: {
                    ...dataContext.data,
                    items: dataContext.data.results,
                  },
                }}
              >
                <NestedIndexesProvider indexes={indexes}>
                  <TableRow
                    data={summaryRow}
                    nested={nested}
                    columns={columns}
                    summaryRow
                  />
                </NestedIndexesProvider>
              </DataContext.Provider>
            );
          })}
        </TableSummaryProvider>
      )}
    </MuiTableBody>
  );
};

export default TableBody;
