import React, { useEffect, useState } from 'react';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { SelectComponents } from '../Autocomplete';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '../../icons/controls/CloseIcon';

const useStyles = makeStyles({
  multiSelectChips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: 8,
    marginRight: 4,
    position: 'relative',
  },
  chipLabel: {
    maxWidth: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },
  closeChip: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    margin: 0,
    width: 12,
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: 3.5,
    alignItems: 'center',
  },
  closeIcon: {
    color: '#fff',
    fontSize: '10px',
  },
});

const MultiSelect = ({
  label = '',
  helperText = '',
  isCreatable,
  onChange,
  uiOptions,
  isDisabled,
  placeholder = 'Enter at least 3 characters...',
  getOptionValue,
  value,
  ...otherProps
}) => {
  const [values, setValues] = useState(value || []);
  const SelectComponent = isCreatable ? Creatable : Select;
  const theme = useTheme();
  const classes = useStyles();

  const limitExceeded = uiOptions.limit && values.length >= uiOptions.limit;

  if (limitExceeded) {
    isDisabled = true;
    placeholder = 'Limit exceeded';
  }

  useEffect(() => {
    setValues(value || []);
  }, [value]);

  if (!getOptionValue) {
    getOptionValue = item => item.value;
  }

  return (
    <>
      <SelectComponent
        noOptionsMessage={() => null}
        controlShouldRenderValue={false}
        styles={{
          input: base => ({
            ...base,
            margin: 0,
            padding: 0,
            color: theme.palette.text.primary,
            '& input': {
              font: 'inherit',
            },
          }),
          menuList: base => ({
            ...base,
            paddingTop: 0,
          }),
          indicatorsContainer: base => ({
            ...base,
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'flex-end',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
        textFieldProps={{
          label,
          variant: 'outlined',
          margin: 'dense',
        }}
        components={SelectComponents}
        placeholder={placeholder}
        {...otherProps}
        value={values}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        onChange={value => {
          setValues(value);
          onChange(value);
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <div className={classes.multiSelectChips}>
        {values.map(chip => (
          <Tooltip
            key={getOptionValue(chip)}
            title={
              <div>
                <div>{chip.name}</div>
                <div>{chip.secondaryKey}</div>
              </div>
            }
            interactive
          >
            <Chip
              tabIndex={-1}
              color="primary"
              label={chip.name}
              className={classes.chip}
              classes={{
                label: classes.chipLabel,
              }}
              onDelete={() => {
                const newValue = values.filter(
                  item => getOptionValue(item) !== getOptionValue(chip)
                );

                setValues(newValue);
                onChange(newValue);
              }}
              deleteIcon={<CloseIcon />}
            />
          </Tooltip>
        ))}
      </div>
    </>
  );
};

export default MultiSelect;
