import { useQuery } from '@apollo/client';
import getCompetitorLangsQuery from '../../gql/EventsGroups/queries/getCompetitorLangs';
import getCategoryLangsQuery from '../../gql/EventsGroups/queries/getCategoryLangs';
import getTournamentLangsQuery from '../../gql/EventsGroups/queries/getTournamentLangs';

const useLangsAPI = () => {
  const {
    data: categoryLangs,
    loading: categoryLangsLoading,
    refetch: refetchCategories,
    error: categoriesError,
  } = useQuery(getCategoryLangsQuery, {
    fetchPolicy: 'network-only',
    skip: true,
  });
  const {
    data: tournamentLangs,
    loading: tournamentLangsLoading,
    refetch: refetchTournaments,
    error: tournamentsError,
  } = useQuery(getTournamentLangsQuery, {
    fetchPolicy: 'network-only',
    skip: true,
  });
  const {
    data: competitorLangs,
    loading: competitorLangsLoading,
    refetch: refetchCompetitors,
    error: competitorsError,
  } = useQuery(getCompetitorLangsQuery, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const getCategoryLangs = async variables => {
    return refetchCategories(variables);
  };
  const getTournamentLangs = async variables => {
    return refetchTournaments(variables);
  };
  const getCompetitorLangs = async variables => {
    return refetchCompetitors(variables);
  };

  const sortLanguages = data => {
    const langs = data.langs;
    const { en, ...rest } = langs;
    return {
      ...data,
      langs: {
        ...(en && { en }),
        ...rest,
      },
    };
  };

  return {
    getCategoryLangs,
    getTournamentLangs,
    getCompetitorLangs,
    categoryLangs: categoryLangs
      ? sortLanguages(categoryLangs.getCategoryLangs)
      : categoryLangs,
    categoryLangsLoading,
    tournamentLangs: tournamentLangs
      ? sortLanguages(tournamentLangs.getTournamentLangs)
      : tournamentLangs,
    tournamentLangsLoading,
    competitorLangs: competitorLangs
      ? sortLanguages(competitorLangs.getCompetitorLangs)
      : competitorLangs,
    competitorLangsLoading,
    error: competitorsError || categoriesError || tournamentsError,
  };
};

export default useLangsAPI;
