import { gql } from '@apollo/client';
import { CONTINGENCY_ITEM_FIELDS } from '../queries/RELATED_CONTINGENCIES_QUERY';

const createRelatedContingency = gql`
  ${CONTINGENCY_ITEM_FIELDS}
  mutation createRelatedContingency($input: RelatedContingencyRuleInput!) {
    createRelatedContingency(input: $input) {
      id
      left {
        ...ContingencyItemFields
      }
      right {
        ...ContingencyItemFields
      }
      active
      rule_type
    }
  }
`;

export default createRelatedContingency;
