import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs';
import Page from '../components/Page/Page';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import React from 'react';
import FormGroup from '../components/Form/FormGroup';
import Button from '../components/Button/Button';
import FormGroupLabel from '../components/Form/FormGroupLabel';
import TextField from '../components/Inputs/TextField';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import NumberField from '../components/Inputs/NumberField';
import DateTimeInput from '../../components/inputs/DateTimeInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import OperatorAutocomplete from '../components/Inputs/OperatorAutocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import LocaleField from '../components/Inputs/LocaleField';
import BonusRestrictionAutocomplete from '../components/Inputs/BonusRestrictionAutocomplete/BonusRestrictionAutocomplete';
import CheckboxGroupField from '../components/Inputs/CheckboxGroupField';
import MultipliersField from '../components/Inputs/MultipliersField/MultipliersField';
import BrandAutocomplete from '../components/Inputs/BrandAutocomplete';

export const EVENT_TYPE = {
  LIVE: 'live',
  PREMATCH: 'prematch',
  VIRTUAL: 'virtual',
};

const BONUS_TYPE = {
  FREE_BET: 'freeBet',
  ODDS_BOOST: 'oddsBoost',
  GLOBAL_ODDS_BOOST: 'globalOddsBoost',
};

const FREE_BET_TYPE = {
  BET_REFUND: 'betRefund',
  NO_RISK_BET: 'noRiskBet',
  FREE_MONEY: 'freeMoney',
};

// const schema = yup.object()

const schema = yup.object().shape({
  base_data: yup.object().shape({
    name: yup.string().required(),
    operator_id: yup.string().required(),
    brand_id: yup.string().required(),
    type: yup.string().oneOf(Object.values(BONUS_TYPE)).required(),
    max_bonus_number: yup.number().integer().required(),
    days_to_use: yup.number().integer().required(),
    from_time: yup.date().required(),
    to_time: yup.date().required(),
    restrictions_type: yup
      .array()
      .of(yup.string().oneOf(Object.values(EVENT_TYPE)))
      .min(1),
  }),

  locals: yup.array().of(
    yup.object().shape({
      lang: yup.string().required(),
      lang_subtag: yup.string().required(),
      desc: yup.string().required(),
    })
  ),

  freebet_data: yup.object().when('base_data.type', {
    is: BONUS_TYPE.FREE_BET,
    then: yup.object().shape({
      type: yup.mixed().oneOf(Object.values(FREE_BET_TYPE)).required(),
      min_odd: yup.number().integer().required(),
      max_odd: yup.number().integer().required(),
      min_selection: yup.number().integer().required(),
      max_selection: yup.number().integer().required(),
    }),
  }),

  comboboost_data: yup.object().when('base_data.type', {
    is: BONUS_TYPE.ODDS_BOOST,
    then: yup.object().shape({
      min_odd: yup.number().integer().required(),
      multipliers: yup
        .array()
        .of(
          yup.object().shape({
            from_selections: yup
              .number()
              .required()
              .min(2, 'Must be more than 1 selection'),
            multiplier: yup.number().required(),
          })
        )
        .min(1, 'Must be at least 1 multiplier'),
    }),
  }),

  restrictions: yup.array().of(
    yup.object().shape({
      value: yup.string().required(),
      name: yup.string().required(),
      secondary_text: yup.string().required(),
      type: yup.string().required(),
    })
  ),
});

const BonusCreatePage = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      comboboost_data: {
        multipliers: [
          {
            from_selections: 1,
            multiplier: 1.1,
          },
        ],
      },
    },
  });

  // const apiPost = useApiPost();
  const bonusType = watch('base_data.type');
  const operatorId = watch('base_data.operator_id');

  const isFreeBet = bonusType === BONUS_TYPE.FREE_BET;
  const isOddsBoost = bonusType === BONUS_TYPE.ODDS_BOOST;
  const isGlobalOddsBoost = bonusType === BONUS_TYPE.GLOBAL_ODDS_BOOST;

  const onSubmit = data => {
    console.log(data);
    console.log(errors);
    // apiPost({
    //   url: '/api/v1/BonusAdmin/bonus/template',
    //   data,
    // })
    //   .then(res => {
    //     console.log('submit result', res);
    //   })
    //   .catch(e => {
    //     console.log('submit error', e);
    //   });
  };

  const restrictionEventType = watch('base_data.restrictions_type');

  return (
    <Page title={'New template'}>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Bonuses',
            to: '/bonuses',
          },
          {
            label: 'New template',
          },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormGroup large>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    name="base_data.name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        required
                        error={!!errors.base_data?.name}
                        helperText={errors.base_data?.name?.message}
                        label={'Template Name'}
                        type={'text'}
                        margin={'dense'}
                        variant="outlined"
                        fullWidth
                        clearButton
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="base_data.operator_id"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <OperatorAutocomplete
                        value={field.value}
                        onChange={value => {
                          setValue('base_data.brand_id', null);
                          field.onChange(value);
                        }}
                        field={field}
                        errors={errors}
                        helperText={errors.base_data?.operator_id?.message}
                        error={!!errors.base_data?.operator_id}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="base_data.brand_id"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <BrandAutocomplete
                        operatorId={operatorId}
                        disabled={!operatorId}
                        value={field.value}
                        onChange={field.onChange}
                        field={field}
                        errors={errors}
                        helperText={errors.base_data?.brand_id?.message}
                        error={!!errors.base_data?.brand_id}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <LocaleField />
          </Grid>

          <Grid item xs={12}>
            <FormGroup large>
              <FormControl
                required
                error={!!errors.base_data?.restrictions_type}
                component="fieldset"
                // className={classes.formControl}
              >
                <Grid container spacing={2} direction={'column'}>
                  <Grid item>
                    <FormLabel component="legend">
                      <FormGroupLabel>Select Event Type</FormGroupLabel>
                    </FormLabel>
                  </Grid>

                  <Grid item>
                    <Controller
                      name="base_data.restrictions_type"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <CheckboxGroupField
                          onChange={field.onChange}
                          value={field.value}
                          options={[
                            {
                              label: 'Live',
                              value: 'live',
                            },
                            {
                              label: 'Prematch',
                              value: 'prematch',
                            },
                            {
                              label: 'Virtual',
                              value: 'virtual',
                            },
                          ]}
                        />
                      )}
                    />

                    <FormHelperText>
                      {errors.base_data?.restrictions_type?.message}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup large>
              <Grid container spacing={2} direction={'column'}>
                <Grid item xs={12}>
                  <FormGroupLabel
                    helpText={
                      'Bonus will be applied only to sports, categories, events, tournaments that you have selected here. If the field is left empty, the bonus will be applied to all events.'
                    }
                  >
                    Restrict Bonus to selected events (All Events if empty)
                  </FormGroupLabel>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="restrictions"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <BonusRestrictionAutocomplete
                            eventType={restrictionEventType}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={isFreeBet ? 6 : 12}>
                <FormGroup large>
                  <Grid container spacing={2} direction={'column'}>
                    <Grid item xs={12}>
                      <FormGroupLabel>Select Bonus Type</FormGroupLabel>
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name="base_data.type"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <RadioGroup
                            aria-label="bonusType"
                            row
                            {...field}
                            onChange={(e, value) => field.onChange(value)}
                          >
                            <FormControlLabel
                              value={BONUS_TYPE.FREE_BET}
                              control={<Radio color={'primary'} />}
                              label="Free bet"
                            />

                            <FormControlLabel
                              value={BONUS_TYPE.ODDS_BOOST}
                              control={<Radio color={'primary'} />}
                              label="Odds boost"
                            />

                            <FormControlLabel
                              value={BONUS_TYPE.GLOBAL_ODDS_BOOST}
                              control={<Radio color={'primary'} />}
                              label="Global odds boost"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>

              {(isOddsBoost || isGlobalOddsBoost) && (
                <>
                  <Grid item xs={isOddsBoost ? 3 : 12}>
                    <FormGroup large>
                      <Grid container spacing={2} direction={'column'}>
                        <Grid item>
                          <FormGroupLabel helpText={'Some text'}>
                            Minimum Odd
                          </FormGroupLabel>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid
                              item
                              xs={isOddsBoost ? 6 : 1}
                              xl={isOddsBoost ? 4 : 1}
                            >
                              <Controller
                                name="comboboost_data.min_odd"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <NumberField
                                    error={!!errors.comboboost_data?.min_odd}
                                    required
                                    placeholder={'Min'}
                                    step={0.1}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>

                  {isOddsBoost && (
                    <Grid item xs={9}>
                      <FormGroup large>
                        <Grid container spacing={2} direction={'column'}>
                          <Grid item>
                            <FormGroupLabel helpText={'Some text'}>
                              Max Bonus Number
                            </FormGroupLabel>
                          </Grid>
                          <Grid item xs={2} xl={1}>
                            <Controller
                              name="base_data.max_bonus_number"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <NumberField
                                  required
                                  placeholder={'Number'}
                                  error={!!errors.base_data?.max_bonus_number}
                                  step={1}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <MultipliersField
                      minSelections={2}
                      errors={errors}
                      name={'comboboost_data.multipliers'}
                      control={control}
                    />
                  </Grid>
                </>
              )}
              {isFreeBet && (
                <>
                  <Grid item xs={6}>
                    <FormGroup large>
                      <FormControl
                        required
                        error={!!errors.freebet_data?.type}
                        component="fieldset"
                      >
                        <Grid container spacing={2} direction={'column'}>
                          <Grid item xs={12}>
                            <FormLabel component="legend">
                              <FormGroupLabel>
                                Select Free bet Type
                              </FormGroupLabel>
                            </FormLabel>
                          </Grid>

                          <Grid item xs={12}>
                            <Controller
                              name="freebet_data.type"
                              control={control}
                              defaultValue={FREE_BET_TYPE.BET_REFUND}
                              render={({ field }) => (
                                <RadioGroup
                                  aria-label="freeBetType"
                                  row
                                  {...field}
                                >
                                  <FormControlLabel
                                    value={FREE_BET_TYPE.BET_REFUND}
                                    control={<Radio color={'primary'} />}
                                    label="Bet refund"
                                  />

                                  <FormControlLabel
                                    value={FREE_BET_TYPE.NO_RISK_BET}
                                    control={<Radio color={'primary'} />}
                                    label="No Risk Bet"
                                  />

                                  <FormControlLabel
                                    value={FREE_BET_TYPE.FREE_MONEY}
                                    control={<Radio color={'primary'} />}
                                    label="Free money"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={3}>
                    <FormGroup large>
                      <Grid container spacing={2} direction={'column'}>
                        <Grid item>
                          <FormGroupLabel helpText={'Some text'}>
                            Min / Max Odd
                          </FormGroupLabel>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item xs={6} xl={4}>
                              <Controller
                                name="freebet_data.min_odd"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <NumberField
                                    error={!!errors.freebet_data?.min_odd}
                                    required
                                    placeholder={'Min'}
                                    step={1}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} xl={4}>
                              <Controller
                                name="freebet_data.max_odd"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <NumberField
                                    error={!!errors.freebet_data?.max_odd}
                                    required
                                    placeholder={'Max'}
                                    step={1}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={3}>
                    <FormGroup large>
                      <Grid container spacing={2} direction={'column'}>
                        <Grid item>
                          <FormGroupLabel helpText={'Some text'}>
                            Max Bonus Number
                          </FormGroupLabel>
                        </Grid>
                        <Grid item xs={6} xl={4}>
                          <Controller
                            name="base_data.max_bonus_number"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <NumberField
                                required
                                placeholder={'Number'}
                                error={!!errors.base_data?.max_bonus_number}
                                step={1}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={6}>
                    <FormGroup large>
                      <Grid container spacing={2} direction={'column'}>
                        <Grid item>
                          <FormGroupLabel helpText={'Some text'}>
                            Minimum / Maximum Bets
                          </FormGroupLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={4} xl={2}>
                              <Controller
                                name="freebet_data.min_selection"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <NumberField
                                    required
                                    error={!!errors.freebet_data?.min_selection}
                                    placeholder={'Min'}
                                    step={1}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={4} xl={2}>
                              <Controller
                                name="freebet_data.max_selection"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <NumberField
                                    required
                                    placeholder={'Max'}
                                    error={!!errors.freebet_data?.max_selection}
                                    step={1}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {(isFreeBet || isOddsBoost) && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormGroup large>
                      <Grid container direction={'column'} spacing={2}>
                        <Grid item>
                          <FormGroupLabel helpText={'Some text'}>
                            Time period to activate
                          </FormGroupLabel>
                        </Grid>
                        <Grid item>
                          <Controller
                            name="base_data.from_time"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              return (
                                <DateTimeInput
                                  formatDate={'%Y/%m/%d %H:%M:%S'}
                                  error={!!errors.base_data?.from_time}
                                  helperText={
                                    errors.base_data?.from_time?.message
                                  }
                                  margin="dense"
                                  variant="outlined"
                                  // fullWidth
                                  {...field}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={6}>
                    <FormGroup large>
                      <Grid container direction={'column'} spacing={2}>
                        <Grid item>
                          <FormGroupLabel helpText={'Some text'}>
                            Days to use
                          </FormGroupLabel>
                        </Grid>
                        <Grid item>
                          <Controller
                            name="base_data.days_to_use"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <NumberField
                                required
                                error={!!errors.base_data?.days_to_use}
                                fullWidth={false}
                                placeholder={'Days to use'}
                                step={1}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                      <FormGroupLabel helpText={'Some test'}>
                        Expire after
                      </FormGroupLabel>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="base_data.to_time"
                        control={control}
                        defaultValue=""
                        render={({ field }) => {
                          return (
                            <DateTimeInput
                              error={!!errors.base_data?.to_time}
                              helperText={errors.base_data?.to_time?.message}
                              formatDate={'%Y/%m/%d %H:%M:%S'}
                              margin="dense"
                              variant="outlined"
                              {...field}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FormGroup>
              <Grid
                container
                justifyContent={'flex-end'}
                spacing={3}
                alignItems={'center'}
              >
                <Grid item>
                  <Button color={'primary'}>Cancel</Button>
                </Grid>

                <Grid item>
                  <Button
                    color={'lightBlue'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid item>
                  <Button color={'primary'} variant={'contained'}>
                    Activate
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      </form>
      §
    </Page>
  );
};

export default BonusCreatePage;
