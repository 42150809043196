import classNames from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import React, { forwardRef, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  contextMenuItemRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  destructiveContextMenuItem: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      background: '#FFE5E7',
    },
  },
}));

const ContextMenuItem = forwardRef(
  (
    { destructive = false, children, classes: customClasses = {}, ...props },
    ref
  ) => {
    const classes = useStyles();

    return (
      <MenuItem
        classes={{
          ...customClasses,
          root: classNames(
            classes.contextMenuItemRoot,
            {
              [classes.destructiveContextMenuItem]: destructive,
            },
            customClasses.root
          ),
        }}
        {...props}
        ref={ref}
      >
        {children}
      </MenuItem>
    );
  }
);

export default ContextMenuItem;
