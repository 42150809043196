import { useMemo, useState } from 'react';
import RangeDateInput from '../../../components/Inputs/RangeDateInput';

const BetDateRangeBetHistory = ({
  filterBonusId,
  filterBetId,
  resetFilter,
  getFilterValue,
  setFilterValue,
  customDefaultValue,
  isDefaultPreset,
  ...props
}) => {
  const [stashedValue, setStashedValue] = useState(null);

  const value = useMemo(() => {
    const prevValue = props.value;

    if (
      (filterBonusId || filterBetId?.length > 0) &&
      (prevValue?.rangeFrom || prevValue?.rangeTo)
    ) {
      setFilterValue({ rangeFrom: null, rangeTo: null });
      setStashedValue(prevValue);

      return { rangeFrom: null, rangeTo: null };
    }

    if (stashedValue && !filterBonusId && filterBetId?.length === 0) {
      setStashedValue(null);
      setFilterValue(stashedValue);

      return stashedValue;
    }

    if (isDefaultPreset && customDefaultValue && !props.value) {
      setFilterValue(customDefaultValue);

      return customDefaultValue;
    }

    return props.value;
  }, [
    filterBonusId,
    filterBetId,
    setFilterValue,
    stashedValue,
    isDefaultPreset,
    customDefaultValue,
    props.value,
  ]);

  return (
    <RangeDateInput
      {...props}
      value={value}
      disabled={filterBonusId || filterBetId?.length > 0}
    />
  );
};

export default BetDateRangeBetHistory;
