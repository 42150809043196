import React from 'react';
import { ObjectInspector } from 'react-inspector';
import { useRawSchema } from '../../../providers/RawSchemaProvider';

const BaseList = () => {
  const { schema } = useRawSchema();
  return <ObjectInspector data={schema} />;
};

export default BaseList;
