import StringField from '../../../../components/Fields/StringField/StringField';
import {
  ActivatePeriod,
  BannerState,
  BannerStateLabel,
  BannerViewLabelMap,
} from '../BannerForm/BannerValidationSchema';
import DraggableList from '../../../../components/DraggableList/DraggableList';
import { useCallback, useEffect, useState, useRef } from 'react';
import sortBy from 'lodash/sortBy';
import isoLangs from '../../../../../constants/isoLangs';
import dayjs from 'dayjs';

const sortByPriority = items => {
  return sortBy(items, 'priority');
};

const getPriorityList = (
  banner,
  banners,
  currentBannerId,
  currentIndex = 0
) => {
  const sortedBanners = sortByPriority(
    banners.filter(item => item.id !== currentBannerId)
  );

  return [
    ...sortedBanners.slice(0, currentIndex),
    {
      ...banner,
      priority: -1,
      current: true,
    },
    ...sortedBanners.slice(currentIndex),
  ].map(item => {
    if (currentBannerId && item.id === currentBannerId) {
      return {
        ...item,
        current: true,
      };
    }
    return item;
  });
};

const BannerPriorityDraggable = ({
  placeholder,
  bannerId,
  banner,
  banners,
  onChangePriority,
}) => {
  const [priority, setPriority] = useState([]);

  const currentIndexRef = useRef(
    Math.max(
      banners.findIndex(({ id }) => id === banner.id),
      0
    )
  );

  useEffect(() => {
    setPriority(
      getPriorityList(banner, banners, bannerId, currentIndexRef.current)
    );
  }, [banners, banner, bannerId]);

  const onReorder = useCallback(
    (startIndex, endIndex) => {
      const result = Array.from(priority);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      currentIndexRef.current = parseInt(endIndex);
      setPriority(result);
      onChangePriority(result);
    },
    [priority, onChangePriority]
  );

  return (
    <DraggableList
      onReorder={onReorder}
      key={placeholder}
      items={priority}
      getId={item => item.id}
      isActive={({ current }) => current}
      columnsTemplate={`32px 3fr 1fr 1fr 3fr 3fr 48px`}
      columns={[
        ({ name }) => <StringField>{name}</StringField>,
        ({ state }) => <StringField>{BannerStateLabel[state]}</StringField>,
        ({ banner_view }) => (
          <StringField>
            {BannerViewLabelMap[banner_view] || 'Unknown'}
          </StringField>
        ),
        ({ locales = [] }) => (
          <StringField>
            {locales
              .filter(locale => locale.lang)
              .map(locale => {
                if (
                  locale.lang === 'all' &&
                  (!locale.country || locale.country === 'all')
                ) {
                  return 'All Locales';
                }

                const lang = isoLangs[locale.lang]?.name || locale.lang;
                const country =
                  !!locale.country && locale.country !== 'all'
                    ? locale.country
                    : '';

                return `${lang}${country ? `-${country}` : ''}`;
              })
              .join(', ')}
          </StringField>
        ),
        ({ activate }) =>
          activate && (
            <StringField>
              {activate.type === ActivatePeriod.PERMANENTLY
                ? 'Permanent'
                : activate.period
                ? `${dayjs(activate.period.range_from)
                    .utc()
                    .format('DD.MM.YYYY, HH:mm')} - ${dayjs(
                    activate.period.range_to
                  )
                    .utc()
                    .format('DD.MM.YYYY, HH:mm')}`
                : 'N/A'}
            </StringField>
          ),
      ]}
    />
  );
};

export default BannerPriorityDraggable;
