import TableCell from './TableCell';
import TableBulkCell, { bulkCellStyles } from './TableBulkCell';
import TableRowExpandContent from './TableRowExpandContent';
import clsx from 'clsx';
import { memo, useCallback, useMemo } from 'react';
import TableRowExpandCell from './TableRowExpandCell';
import TableRowActionsCell from './TableRowActionsCell';
import UndoChangeCell from './UndoChangeCell';

const TableRow = ({
  rowId,
  rowIndex,
  rowData,
  summary,
  expandable = false,
  expanded,
  expandedContent,
  onExpand,
  className,
  renderCells,
  rowActions,
  onChangeRowHeight,
  bulk,
  gridTemplate,
  isSummary,
  onUndo = () => {},
  withUndo = false,
  withExpandButton = true,
  isHighlighted = false,
  isActive = false,
  withHover = false,
  onClick = () => {},
}) => {
  const onExpandCb = useCallback(() => {
    onExpand(rowId, rowIndex);
  }, [rowId, rowIndex, onExpand]);

  const onUndoButtonClick = useCallback(() => {
    onUndo(rowData);
  }, [onUndo, rowData]);

  const tableRowContent = useMemo(() => {
    return (
      <>
        {(bulk || expandable) && (
          <div
            style={{
              position: 'sticky',
              left: '0',
              display: 'flex',
              width: 'fit-content',
            }}
          >
            {bulk && (
              <TableCell style={bulkCellStyles} rowClassName={className}>
                <TableBulkCell
                  value={
                    bulk.bulkState
                      ? bulk.bulkState.exclude
                        ? !bulk.bulkState.ids.includes(rowId)
                        : bulk.bulkState.ids.includes(rowId)
                      : Boolean(bulk.chosenItems[rowId])
                  }
                  disabled={bulk.checkboxesDisabled}
                  onToggle={checked => {
                    bulk.toggle(rowData, checked);
                  }}
                />
              </TableCell>
            )}
            {expandable &&
              (isSummary ? (
                <TableCell />
              ) : (
                <TableRowExpandCell
                  className={className}
                  expanded={expanded}
                  onExpandCb={onExpandCb}
                  withExpandButton={withExpandButton}
                />
              ))}
          </div>
        )}

        {renderCells(rowData, summary)}
        {withUndo && <UndoChangeCell onUndoButtonClick={onUndoButtonClick} />}
        <TableRowActionsCell
          className={className}
          rowActions={rowActions}
          rowData={rowData}
        />
      </>
    );
  }, [
    rowId,
    bulk,
    className,
    expandable,
    renderCells,
    rowActions,
    rowData,
    expanded,
    onExpandCb,
    summary,
    isSummary,
    withUndo,
    onUndoButtonClick,
    withExpandButton,
  ]);

  const onChangeRowHeightCb = useCallback(() => {
    onChangeRowHeight(rowIndex);
  }, [rowIndex, onChangeRowHeight]);

  return (
    <>
      <div
        data-cy-table-row={rowId}
        className={clsx('tableRowContent', className, {
          tableRowContentWithHover: withHover,
          tableRowContentExpanded: expanded,
          tableRowContentHighlighted: isHighlighted,
          tableRowContentActive: isActive,
        })}
        style={{
          gridTemplateColumns: gridTemplate,
        }}
        onClick={() => onClick(rowId)}
      >
        {tableRowContent}
      </div>

      {expandable && expanded && (
        <TableRowExpandContent onChangeHeight={onChangeRowHeightCb}>
          {expandedContent(rowIndex, rowData, onChangeRowHeightCb)}
        </TableRowExpandContent>
      )}
    </>
  );
};

export default memo(TableRow);
