import { gql } from '@apollo/client';

const getEventCreateModel = gql`
  query getEventCreateModel(
    $sportId: SportId!
    $groupId: GroupId!
    $providerId: ProviderId!
    $provider: Provider!
    $descType: DescType!
  ) {
    getEventCreateModel(
      sportId: $sportId
      groupId: $groupId
      providerId: $providerId
      provider: $provider
      descType: $descType
    ) {
      category {
        langs
        id
        countryCode
      }
      tournament {
        langs
        id
      }
      competitors {
        langs
        id
        countryCode
      }
      autocreate
    }
  }
`;

export default getEventCreateModel;
