import { gql } from '@apollo/client';

const SPORTS_FILTER_AUTOCOMPLETE_QUERY = gql`
  query getSportsEvents {
    data: getSportsEvents {
      id
      name
      active_events_count
    }
  }
`;

export default SPORTS_FILTER_AUTOCOMPLETE_QUERY;
