import React, { useContext, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export const ContainerBoundsContext = React.createContext({});

const ContainerBoundsProvider = ({ containerRef, children }) => {
  const [width, setWidth] = useState();

  const parent = useContext(ContainerBoundsContext);

  useResizeObserver({
    ref: containerRef,
    onResize: rect => {
      setWidth(rect.width);
    },
  });

  return (
    <>
      <ContainerBoundsContext.Provider
        value={{
          width,
          parent,
        }}
      >
        {children}
      </ContainerBoundsContext.Provider>
    </>
  );
};

export default ContainerBoundsProvider;
