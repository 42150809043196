import { gql } from '@apollo/client';

export const CONTINGENCY_ITEM_FIELDS = gql`
  fragment ContingencyItemDefinitionFields on RelatedContingencyItemDefinition {
    type
    value {
      label
      value
    }
  }

  fragment ContingencyItemFields on RelatedContingencyItem {
    sports {
      ...ContingencyItemDefinitionFields
    }
    categories {
      ...ContingencyItemDefinitionFields
    }
    tournaments {
      ...ContingencyItemDefinitionFields
    }
    events {
      ...ContingencyItemDefinitionFields
    }
    markets {
      ...ContingencyItemDefinitionFields
    }
    competitors {
      ...ContingencyItemDefinitionFields
    }
  }
`;

const RELATED_CONTINGENCIES_QUERY = gql`
  ${CONTINGENCY_ITEM_FIELDS}
  query getRelatedContingencies($input: RelatedContingencyFilters) {
    data: getRelatedContingencies(input: $input) {
      total
      items {
        id
        left {
          ...ContingencyItemFields
        }
        right {
          ...ContingencyItemFields
        }
        active
        rule_type
        user_email
        update_date
      }
    }
  }
`;

export default RELATED_CONTINGENCIES_QUERY;
