import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';
import withStyles from '@mui/styles/withStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  picker: {
    boxShadow: `${theme.shadows[5]} !important`,
    fontFamily: theme.typography.fontFamily,
  },
  triangle: {
    position: 'absolute',
    left: '50%',
    bottom: -14,
    marginLeft: -7,
    borderStyle: 'solid',
    borderWidth: 7,
    borderColor: 'transparent transparent white white',
    transformOrigin: '0 0',
    transform: 'rotate(-45deg)',
    boxShadow: '-3px 3px 3px 0 rgba(0,0,0,0.2)',
  },
});

const ColorPickerTooltip = ({
  initialColor,
  placement,
  onSubmit,
  style,
  classes,
}) => {
  const [color, setColor] = useState(initialColor);
  return (
    <ClickAwayListener
      onClickAway={e => {
        e.preventDefault();
        onSubmit(color);
      }}
    >
      <div
        className={classes.root}
        style={{
          ...style,
          top: placement === 'top' ? -10 : 10,
        }}
      >
        <BlockPicker
          color={color}
          className={classes.picker}
          triangle={placement === 'top' ? 'hide' : 'top'}
          onChangeComplete={({ hex }) => setColor(hex)}
        />
        {placement === 'top' && <div className={classes.triangle} />}
      </div>
    </ClickAwayListener>
  );
};

ColorPickerTooltip.propTypes = {
  initialColor: PropTypes.string,
  style: PropTypes.object,
  placement: PropTypes.oneOf(['top', 'bottom']).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(ColorPickerTooltip);
