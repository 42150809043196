import WatchFormValue from '../../../../components/Form/WatchField';
import ImageLibrarySelect from '../../../../components/Inputs/ImageLibrarySelect/ImageLibrarySelect';
import { useApiHost } from '../../../../../providers/ApiHostProvider';
import { useIsOperator } from '../../../../providers/OperatorProvider';

const ImageLibraryField = ({ onChange }) => {
  const { apiHost } = useApiHost();
  const isOperator = useIsOperator();

  return (
    <WatchFormValue name={['operator', 'brand']}>
      {({ operator, brand }) => {
        return (
          <ImageLibrarySelect
            imageKey={({ id }) =>
              `${apiHost}/api/v1/PromoAdmin/source_image/preview/${id}`
            }
            // metaKey={({ name }) => `${name}`}
            disabled={
              (!isOperator && !(operator && operator.id)) ||
              !(brand && brand.id)
            }
            label={'Select from already uploaded images'}
            withOperator={!isOperator}
            operatorId={operator && operator.id}
            brandId={brand && brand.id}
            onChange={onChange}
          />
        );
      }}
    </WatchFormValue>
  );
};

export default ImageLibraryField;
