import React, { useCallback, useEffect, useRef, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import TimePicker from './TimePicker';
import Button from '@mui/material/Button';
// import { PickersCalendarView, PickersDay } from 'ph-material-pickers';
import makeStyles from '@mui/styles/makeStyles';
import TimePickerControl from './TimePickerControl';
import { StaticDatePicker } from '@mui/lab';

const useStyles = makeStyles({
  calendarHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerPickers: {
    display: 'flex',
  },
  switchMonthButton: {
    width: 32,
    height: 32,
    marginLeft: 4,
    marginRight: 4,
  },
  switchMonthButtonDisabled: {},
  pickerFooter: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timePicker: {
    marginTop: 24,
  },
  clearButton: {
    marginLeft: 8,
  },
});

const DatePicker = ({ dateOnly = false, onChange, value, withTime }) => {
  const [isOpenTime, setIsOpenTime] = useState(withTime);

  const buttonRef = useRef(null);
  const hoursRef = useRef(null);

  const classes = useStyles();

  const [date, setDate] = useState(value || new Date());

  const handleApply = useCallback(() => {
    onChange(date, isOpenTime);
  }, [onChange, date, isOpenTime]);

  const handleClear = useCallback(() => {
    onChange(null);
  }, [onChange]);

  // const handleMonthChange = useCallback(
  //   month => {
  //     setDate(setMonth(date, month));
  //   },
  //   [date, setDate]
  // );

  // const handleYearChange = useCallback(
  //   year => {
  //     setDate(setYear(date, year));
  //   },
  //   [date, setDate]
  // );

  const focusTimePicker = useCallback(() => {
    if (hoursRef.current) {
      hoursRef.current.focus();
      hoursRef.current.select();
    }
  }, []);

  const handleDateChange = useCallback(
    value => {
      setDate(value);
      focusTimePicker();
    },
    [setDate, focusTimePicker]
  );

  const handleTimeChange = useCallback(
    value => {
      setDate(value);
    },
    [setDate]
  );

  useEffect(() => {
    setDate(value || new Date());
  }, [value, setDate]);

  const handleCompleteTime = useCallback(() => {
    buttonRef.current && buttonRef.current.focus();
  }, []);

  return (
    <div>
      {/*<div*/}
      {/*  headerLabelRender={({*/}
      {/*    date,*/}
      {/*    monthLabel,*/}
      {/*    yearLabel,*/}
      {/*    selectPreviousMonth,*/}
      {/*    selectNextMonth,*/}
      {/*    isPreviousMonthDisabled,*/}
      {/*    isNextMonthDisabled,*/}
      {/*  }) => {*/}
      {/*    return (*/}
      {/*      <div className={classes.calendarHeader}>*/}
      {/*        <IconButton*/}
      {/*          size={'small'}*/}
      {/*          color={'primary'}*/}
      {/*          className={clsx(classes.switchMonthButton, {*/}
      {/*            [classes.switchMonthButtonDisabled]: isPreviousMonthDisabled,*/}
      {/*          })}*/}
      {/*          disabled={isPreviousMonthDisabled}*/}
      {/*          onClick={selectPreviousMonth}*/}
      {/*        >*/}
      {/*          <ExpandIconLeft className={classes.expandIcon} />*/}
      {/*        </IconButton>*/}

      {/*        <div className={classes.headerPickers}>*/}
      {/*          <MonthSelect*/}
      {/*            onChange={handleMonthChange}*/}
      {/*            currentMonth={getMonth(date)}*/}
      {/*          />*/}
      {/*          <YearSelect*/}
      {/*            onChange={handleYearChange}*/}
      {/*            currentYear={getYear(date)}*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*        <IconButton*/}
      {/*          size={'small'}*/}
      {/*          color={'primary'}*/}
      {/*          className={clsx(classes.switchMonthButton, {*/}
      {/*            [classes.switchMonthButtonDisabled]: isNextMonthDisabled,*/}
      {/*          })}*/}
      {/*          disabled={isNextMonthDisabled}*/}
      {/*          onClick={selectNextMonth}*/}
      {/*        >*/}
      {/*          <ExpandIconRight className={classes.expandIcon} />*/}
      {/*        </IconButton>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*  renderDay={(date, _selectedDates, DayComponentProps) => {*/}
      {/*    return (*/}
      {/*      <PickersDay*/}
      {/*        {...DayComponentProps}*/}
      {/*        disableMargin*/}
      {/*        className={clsx()}*/}
      {/*      />*/}
      {/*    );*/}
      {/*  }}*/}
      {/*  views={['date']}*/}
      {/*  date={date}*/}
      {/*  view={'date'}*/}
      {/*  onChange={handleDateChange}*/}
      {/*/>*/}

      <StaticDatePicker
        views={['day']}
        displayStaticWrapperAs="desktop"
        date={date}
        value={value}
        onChange={newValue => {
          handleDateChange(newValue);
        }}
        renderInput={params => <TextField {...params} />}
      />

      <div>
        {!dateOnly && (
          <>
            <TimePickerControl
              isOpen={isOpenTime}
              disabled={!date || dateOnly}
              onChange={setIsOpenTime}
            />

            <Collapse
              in={isOpenTime}
              unmountOnExit
              onEntered={() => {
                focusTimePicker();
              }}
            >
              <TimePicker
                hoursRef={hoursRef}
                onComplete={handleCompleteTime}
                value={date}
                onChange={handleTimeChange}
                className={classes.timePicker}
              />
            </Collapse>
          </>
        )}

        <div className={classes.pickerFooter}>
          <Button
            ref={buttonRef}
            onClick={handleApply}
            color="primary"
            disableRipple
            variant={'contained'}
          >
            Save
          </Button>
          <Button
            onClick={handleClear}
            color="secondary"
            disableRipple
            variant={'contained'}
            className={classes.clearButton}
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
