import React, { useContext } from 'react';
import { UiOptionsContext } from './UiOptionsProvider';

const CodeField = () => {
  const { value } = useContext(UiOptionsContext);

  return <pre>{value.label.text}</pre>;
};

export default CodeField;
