import { capitalize } from 'lodash';
import DateField from '../../../../components/Fields/DateField/DateField';
import EmptyField from '../../../../components/Fields/EmptyField/EmptyField';
import StringField from '../../../../components/Fields/StringField/StringField';
import Table from '../../../../components/Table/Table';
import useTableColumnsReorder from '../../../../hooks/useTableColumnsReorder';

export const COLUMN = {
  location: 'location',
  action: 'action',
  user: 'user',
  date: 'date',
};

export const columnsWidthConfig = {
  [COLUMN.location]: 2,
  [COLUMN.action]: 1,
  [COLUMN.user]: 1,
  [COLUMN.date]: 1,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.location,
  COLUMN.action,
  COLUMN.user,
  COLUMN.date,
];

export const initialToggledColumns = [
  COLUMN.location,
  COLUMN.action,
  COLUMN.user,
  COLUMN.date,
];

const ChangeLogLocations = {
  margin: 'Margin',
  coverage: 'Coverage',
  sources: 'Sources',
  line_settings: 'Line Settings',
};

const ChangeLogTable = ({ data, loading, error, onUndo }) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Location',
      key: COLUMN.location,
      render: props => {
        const template = props.template;
        const template_name = props.template_name;
        const locationType = ChangeLogLocations[template];
        const locationTitle = template_name
          ? `${locationType} template ${template_name}`
          : locationType;

        return template ? (
          <StringField title={locationTitle}>{locationTitle}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Action',
      key: COLUMN.action,
      render: ({ action }) => {
        return action ? (
          <StringField>{capitalize(action)}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'User',
      key: COLUMN.user,
      render: ({ user }) => {
        return user ? (
          <StringField title={user}>{user}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Date',
      key: COLUMN.date,
      render: ({ date }) => {
        return date ? (
          <StringField>
            <DateField date={date} format={'YYYY.MM.DD, HH:mm'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  return (
    <Table
      //in this case we use rowIndex by default
      id={null}
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      configMenu={false}
      // onUndo={onUndo}
      error={error}
      // withUndo
    />
  );
};

export default ChangeLogTable;
