import RelatedContingencyAutocomplete from './RelatedContingencyAutocomplete';
import { useMemo } from 'react';
import GetEventsNames from '../../../gql/RiskManagement/queries/getEventsNames';

const EventAutocomplete = ({
  value,
  onChange,
  categories,
  tournaments,
  sports,
  ...props
}) => {
  const params = useMemo(
    () => ({
      categories_ids: categories,
      tournaments_ids: tournaments,
      sport_ids: sports,
    }),
    [categories, tournaments, sports]
  );

  return (
    <RelatedContingencyAutocomplete
      allLabel={'All Events'}
      label={'Select Event'}
      params={params}
      gqlQuery={GetEventsNames}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default EventAutocomplete;
