import { ActionCardTypeEnum } from '../../components/ActionCards';
import { useMemo } from 'react';
import TableCreateActionCard from '../components/TableActionCards/TableCreateActionCard';
import CreateActionCard from '../components/TableActionCards/TableCreateActionCard';

const useCreateActionCard = ({ key, link, label, span = 2 } = {}) => {
  return useMemo(
    () => ({
      span,
      key: key || ActionCardTypeEnum.CREATE,
      height: 56,
      render: style => (
        <CreateActionCard style={style} link={link} label={label} />
      ),
    }),
    [span, link, label, key]
  );
};

export default useCreateActionCard;
