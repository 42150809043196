import { useEnumSelectData } from '../../../providers/SelectDataProvider';
import Select from '../../../v2/components/Inputs/Select/Select';
import React from 'react';

const EnumSelect = ({ schema, SelectProps }) => {
  const options = useEnumSelectData(schema);

  return <Select options={options} {...SelectProps} />;
};

export default EnumSelect;
