import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

const useStyles = makeStyles({
  tab: {
    position: 'relative',
    minWidth: 180,
    backgroundColor: '#E6EAEE',
    transition: 'background-color 0.2s ease-in, color 0.2s ease-in',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#031224',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.5px',
    fontWeight: 500,
    height: 40,
    borderRadius: 4,
    '&:hover:not($tabActive)': {
      opacity: 0.6,
      // '&>$divider': {
      //   display: 'none',
      // },
      //
      // '&+$tab>$divider': {
      //   display: 'none',
      // },
    },

    '&:first-of-type $divider': {
      display: 'none',
    },
  },

  fullWidth: {
    flex: 1,
  },

  withoutSpacing: {
    borderRadius: '4px 4px 0 0',
  },

  tabActive: {
    pointerEvents: 'none',
    backgroundColor: '#FFFFFF',

    '&>$divider': {
      display: 'none',
    },

    '&+$tab>$divider': {
      display: 'none',
    },
  },
  primaryActive: {
    pointerEvents: 'none',
    backgroundColor: '#1581FF',
    color: '#FFFFFF',

    '&>$divider': {
      display: 'none',
    },

    '&+$tab>$divider': {
      display: 'none',
    },
  },

  divider: {
    zIndex: 1,
    height: 24,
    backgroundColor: '#F7F7F7',
    borderRadius: 2,
    left: -1,
    width: 2,
    position: 'absolute',
  },

  disabled: {
    color: '#A4B1C0',
    pointerEvents: 'none',
  },
});

const Tab = ({
  disabled,
  fullWidth = false,
  withoutSpacing = false,
  primary = false,
  isActive,
  onClick,
  children,
  setTabSize,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  useResizeObserver({
    ref: ref,
    onResize: rect => {
      ref && setTabSize(rect.width);
    },
  });

  return (
    <div
      ref={ref}
      onClick={!disabled ? onClick : undefined}
      className={clsx(
        classes.tab,
        isActive && (primary ? classes.primaryActive : classes.tabActive),
        {
          [classes.tabActive]: isActive,
          [classes.fullWidth]: fullWidth,
          [classes.withoutSpacing]: withoutSpacing,
          [classes.disabled]: disabled,
        }
      )}
    >
      {children}
      <div className={classes.divider} />
    </div>
  );
};

export default Tab;
