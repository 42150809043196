import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import FormGroup from '../../Form/FormGroup';
import FormGroupLabel from '../../Form/FormGroupLabel';
import Button from '../../Button/Button';
import RemoveIcon from '../../../../icons/controls/RemoveIcon';
import NumberField from '../NumberField';
import { useCallback } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import AddButton from '../../Button/AddButton';
import get from 'lodash/get';
import { animated, useTransition } from 'react-spring';

const useStyles = makeStyles(() => ({
  multipliersContainer: {
    backgroundColor: '#F7F7F7',
    borderRadius: 8,
    padding: 8,
    marginLeft: -14,
    marginRight: -14,
    marginTop: 16,
  },
  animatedMultiplierItem: {
    overflow: 'hidden',

    '&+$animatedMultiplierItem': {
      marginTop: 4,
    },
  },
  multiplierItem: {
    backgroundColor: '#fff',
    borderRadius: 4,
    padding: '8px 0 8px 8px',
  },
  removeButton: {
    color: '#FF000F',
    minWidth: 'initial',

    '&:hover': {
      color: 'rgba(255,0,15,0.8)',
    },

    '&:disabled': {
      color: '#C1CBD5',
    },
  },
  removeIcon: {
    fontSize: '16px',
  },
  rowActions: {
    padding: '0 16px',
  },
  inputCell: {},
  inputLabel: {
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.5px',
    color: '#031224',
    padding: '0 16px',
  },

  multipliersActions: {
    marginTop: 16,
  },
}));

const MultipliersField = ({
  errors,
  control,
  name,
  minItems = 1,
  onAddMultiplier = () => {},
  onRemoveMultiplier = () => {},
}) => {
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
    shouldUnregister: true,
  });

  const addMultiplier = useCallback(() => {
    const multiplier = {
      from_selections: '',
      multiplier: '',
    };

    append(multiplier);
    onAddMultiplier(multiplier);
  }, [append, onAddMultiplier]);

  const transitions = useTransition(fields, {
    keys: item => {
      return item.id;
    },
    enter: { opacity: 1, height: 56 },
    from: { opacity: 0, height: 0 },
  });

  return (
    <FormGroup large>
      <Box display={'flex'} flexDirection={'column'}>
        <Box>
          <FormGroupLabel>Multipliers</FormGroupLabel>
        </Box>
        <Box className={classes.multipliersContainer}>
          <Box display={'flex'} flexDirection={'column'}>
            {transitions(({ opacity, height, ...rest }, field, t, index) => {
              return (
                <Box
                  style={{
                    opacity,
                    height,
                    ...rest,
                  }}
                  className={classes.animatedMultiplierItem}
                  component={animated.div}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    className={classes.multiplierItem}
                  >
                    <Box display={'flex'} flexGrow={1}>
                      <Box
                        flexGrow={1}
                        display={'flex'}
                        alignItems={'center'}
                        className={classes.inputCell}
                      >
                        <Box className={classes.inputLabel}>From Selection</Box>
                        <Box flexGrow={1}>
                          <Controller
                            name={`${name}.${index}.from_selections`}
                            control={control}
                            render={({ field }) => (
                              <NumberField
                                error={
                                  !!get(
                                    errors,
                                    `${name}[${index}].from_selections`
                                  )
                                }
                                helperText={get(
                                  errors,
                                  `${name}[${index}].from_selections.message`
                                )}
                                placeholder={'Number'}
                                step={1}
                                // min={minSelections}
                                {...field}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      <Box
                        flexGrow={1}
                        display={'flex'}
                        alignItems={'center'}
                        className={classes.inputCell}
                      >
                        <Box className={classes.inputLabel}>Odd Multiplier</Box>
                        <Box flexGrow={1}>
                          <Controller
                            name={`${name}.${index}.multiplier`}
                            control={control}
                            render={({ field }) => (
                              <NumberField
                                placeholder={'Number'}
                                step={0.1}
                                {...field}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box className={classes.rowActions}>
                      <Button
                        disabled={fields.length <= minItems}
                        disableRipple
                        variant={'text'}
                        classes={{
                          root: classes.removeButton,
                          text: classes.enumRemoveButtonText,
                          label: classes.enumRemoveButtonLabel,
                        }}
                        onClick={() => {
                          remove(index);
                          onRemoveMultiplier({
                            ...fields[index],
                          });
                        }}
                      >
                        <RemoveIcon className={classes.removeIcon} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className={classes.multipliersActions}>
          <AddButton onClick={addMultiplier}>Add Multipliers</AddButton>
        </Box>
      </Box>
    </FormGroup>
  );
};

export default MultipliersField;
