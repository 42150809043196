import { handleActions } from 'redux-actions';
import { addNotification, removeNotification } from '../actions/uiActions';

const notification = handleActions(
  {
    [addNotification]: (state, { payload }) => payload,
    [removeNotification]: () => null,
  },
  null
);

export default notification;

export const getGlobalNotification = state => state.notification;
