import React, { Fragment } from 'react';
import BannerLinkField from '../../BannerFields/BannerLinkField';
import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import BannerTitleField from '../../BannerFields/BannerTitleField';
import BannerSubtitleField from '../../BannerFields/BannerSubtitleField';
import BannerLegalTextField from '../../BannerFields/BannerLegalTextField';
import BannerImageTabs from '../../BannerImageTabs/BannerImageTabs';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import HeroBannerTextPreview from '../../BannerImagePreview/HeroBannerTextPreview';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import { useFormContext, useWatch } from 'react-hook-form';

const TextView = ({ prefix }) => {
  const { watch } = useFormContext();
  const brand = watch('brand');

  return (
    <ImagePreviewProvider>
      <LocaleTabs
        name={`${prefix}.locales`}
        content={(localeField, localeIndex) => (
          <Fragment key={localeField.locale.country + localeField.locale.lang}>
            <BannerLinkField name={`${prefix}.locales.${localeIndex}.link`} />

            <Grid item xs={12}>
              <FormGroup large>
                <Grid container spacing={2} direction={'column'}>
                  <Grid item container xs={12} spacing={2}>
                    <BannerTitleField
                      maxLength={40}
                      name={`${prefix}.locales.${localeIndex}.title`}
                    />

                    <BannerSubtitleField
                      maxLength={162}
                      name={`${prefix}.locales.${localeIndex}.subtitle`}
                    />
                  </Grid>
                  <BannerLegalTextField
                    maxLength={162}
                    name={`${prefix}.locales.${localeIndex}.legal_text`}
                  />
                </Grid>
              </FormGroup>
            </Grid>

            {brand && (
              <Grid item xs={12}>
                <BannerImageTabs
                  key={localeField.locale.country + localeField.locale.lang}
                  prefix={`${prefix}.locales.${localeIndex}.images`}
                  preview={HeroBannerTextPreview}
                  previewFields={[
                    `${prefix}.locales.${localeIndex}.title`,
                    `${prefix}.locales.${localeIndex}.subtitle`,
                    `${prefix}.locales.${localeIndex}.legal_text`,
                  ]}
                />
              </Grid>
            )}
          </Fragment>
        )}
      />
    </ImagePreviewProvider>
  );
};

export default TextView;
