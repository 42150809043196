import { gql } from '@apollo/client';

const getMarginPreset = gql`
  query getMarginPreset($presetId: PresetId!) {
    data: getMarginPreset(presetId: $presetId) {
      nodeSelector {
        sportId
        categoryId
        tournamentId
        eventId
        market {
          marketId
          specifier
        }
      }
      live
      prematch
    }
  }
`;

export default getMarginPreset;
