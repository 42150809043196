import React from 'react';
import FilterField from './FilterField';
import makeStyles from '@mui/styles/makeStyles';
import ObjectFilter from './FilterFields/ObjectFilter';
import get from 'lodash/get';

const useStyles = makeStyles(theme => {
  return {
    filters: {
      width: 'calc(100% + 16px)',
      marginRight: -theme.spacing(1),
      marginLeft: -theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
    },
    cell: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      width: `calc(100% - ${theme.spacing(2)})`,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginTop: 12,
      marginBottom: 12,
    },

    fullWidthCell: {
      display: 'inline-block',
      width: `calc(100% - ${theme.spacing(2)})`,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginTop: 12,
      marginBottom: 12,
    },

    fullWidthFilters: {
      width: `calc(100% + ${theme.spacing(2)})`,
      marginRight: -theme.spacing(1),
      marginLeft: -theme.spacing(1),
    },

    [theme.breakpoints.up('sm')]: {
      cell: {
        width: `calc(50% - ${theme.spacing(2)})`,
      },
    },
    [theme.breakpoints.up('md')]: {
      cell: {
        width: `calc(33.3333% - ${theme.spacing(2)})`,
      },
    },
    [theme.breakpoints.up('xl')]: {
      cell: {
        width: `calc(16.6666% - ${theme.spacing(2)})`,
      },
    },
  };
});

const FiltersFormGroup = ({
  fullWidth,
  filters,
  onHideFilterClick,
  onResetFilterValue,
  persistFilters,
}) => {
  const classes = useStyles();

  return (
    <div className={fullWidth ? classes.fullWidthFilters : classes.filters}>
      {filters.map(filter => {
        return (
          <div
            key={filter.name}
            className={
              fullWidth || get(filter, ['uiSchema', 'ui:options', 'fullwidth'])
                ? classes.fullWidthCell
                : classes.cell
            }
          >
            {filter.type === 'object' ? (
              <ObjectFilter
                fullWidth={true}
                onHideFilterClick={() => onHideFilterClick(filter)}
                onResetFilterValue={value => onResetFilterValue(filter, value)}
                name={filter.name}
                label={filter.title}
                key={filter.name}
                filter={filter}
                persistFilters={persistFilters}
              />
            ) : (
              <FilterField
                name={filter.name}
                label={filter.title}
                onHideFilterClick={() => onHideFilterClick(filter)}
                onResetFilterValue={value => onResetFilterValue(filter, value)}
                fullWidth={true}
                persistFilters={persistFilters}
                {...filter}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FiltersFormGroup;
