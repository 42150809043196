import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';

const options = {
  lineNumbers: true,
  mode: {
    name: 'javascript',
    json: true,
  },
};

const useDebounce = (fn, ms = 0, args = []) => {
  useEffect(() => {
    const handle = setTimeout(fn.bind(null, args), ms);

    return () => clearTimeout(handle);
  }, [fn, ms, args]);
};

const Codemirror = ({ value, onChange }) => {
  const [innerValue, setInnerValue] = useState(JSON.stringify(value, null, 2));
  const [prevValue, setPrevValue] = useState(value);
  const [valid, setValid] = useState(true);

  if (value !== prevValue) {
    setInnerValue(JSON.stringify(value, null, 2));
    setPrevValue(value);
  }

  useDebounce(
    () => {
      try {
        const parsedValue = JSON.parse(innerValue);
        onChange(parsedValue);
        setValid(true);
      } catch {
        setValid(false);
      }
    },
    300,
    [innerValue]
  );

  return (
    <>
      <CodeMirror
        autoCursor={false}
        value={innerValue}
        options={options}
        onChange={(editor, data, value) => setInnerValue(value)}
      />
      {!valid && <Typography color="error">Invalid JSON</Typography>}
    </>
  );
};

export default Codemirror;
