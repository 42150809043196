import useSearch from './useSearch';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import getQuery from '../utils/getQuery';
import getQueryString from '../utils/getQueryString';
import omit from 'lodash/omit';

export const omitOrders = query => {
  return Object.keys(query).reduce((res, path) => {
    return {
      ...res,
      [path]: omit(query[path], ['order_by', 'order_by_asc_desc']),
    };
  }, {});
};

const useResetOrders = () => {
  const navigate = useNavigate();
  const search = useSearch();
  const queryPaths = getQuery(search);

  return useCallback(
    (override = {}) => {
      const withOverrides = {
        ...queryPaths,
        ...override,
      };

      navigate(
        {
          search: getQueryString({
            ...omitOrders(withOverrides),
          }),
        },
        {
          replace: true,
        }
      );
    },
    [navigate, queryPaths]
  );
};

export default useResetOrders;
