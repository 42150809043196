import Grid from '@mui/material/Grid';
import SportAutocomplete from '../../components/Inputs/RelatedContingencyAutocomplete/SportAutocomplete';
import FormField from '../../components/Form/FormField';
import CategoryAutocomplete from '../../components/Inputs/RelatedContingencyAutocomplete/CategoryAutocomplete';
import MarketAutocomplete from '../../components/Inputs/RelatedContingencyAutocomplete/MarketAutocomplete';
import CompetitorAutocomplete from '../../components/Inputs/RelatedContingencyAutocomplete/CompetitorAutocomplete';
import EventAutocomplete from '../../components/Inputs/RelatedContingencyAutocomplete/EventAutocomplete';
import TournamentAutocomplete from '../../components/Inputs/RelatedContingencyAutocomplete/TournamentAutocomplete';
import RelatedContingencyItemType from './constants/RelatedContingencyItemType';
import * as yup from 'yup';
import { useCallback, useMemo } from 'react';

export const relatedContingencyItemDefinitionSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(Object.values(RelatedContingencyItemType))
    .required(),
  value: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .when('type', {
      is: RelatedContingencyItemType.LIST,
      then: schema => schema.min(1).required(),
    }),
});

export const relatedContingencyItemSchema = yup.object().shape({
  sports: relatedContingencyItemDefinitionSchema.required(),
  categories: relatedContingencyItemDefinitionSchema.required(),
  tournaments: relatedContingencyItemDefinitionSchema.required(),
  events: relatedContingencyItemDefinitionSchema.required(),
  markets: relatedContingencyItemDefinitionSchema.required(),
  competitors: relatedContingencyItemDefinitionSchema.required(),
});

const extractIds = item => {
  return item.value && item.value.map(item => item.value);
};

const getPrefixed = (prefix, name) => {
  return prefix ? `${prefix}.${name}` : name;
};

const RelatedContingencyFields = ({ prefix, withSameCompetitorOption }) => {
  const withPrefix = useCallback(
    name => {
      return getPrefixed(prefix, name);
    },
    [prefix]
  );

  const fields = useMemo(() => {
    return {
      sports: withPrefix('sports'),
      categories: withPrefix('categories'),
      tournaments: withPrefix('tournaments'),
      events: withPrefix('events'),
      markets: withPrefix('markets'),
      competitors: withPrefix('competitors'),
    };
  }, [withPrefix]);

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormField name={fields.sports}>
              <SportAutocomplete />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField
              name={fields.categories}
              deps={{ sports: fields.sports }}
            >
              {({ sports = [] }) => (
                <CategoryAutocomplete sports={extractIds(sports)} />
              )}
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField
              name={fields.tournaments}
              deps={{ categories: fields.categories, sports: fields.sports }}
            >
              {({ sports = [], categories = [] }) => (
                <TournamentAutocomplete
                  withSameOption={withSameCompetitorOption}
                  categories={extractIds(categories)}
                  sports={extractIds(sports)}
                />
              )}
            </FormField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormField
              name={fields.events}
              deps={{
                tournaments: fields.tournaments,
                categories: fields.categories,
                sports: fields.sports,
              }}
            >
              {({ tournaments = [], categories = [], sports = [] }) => (
                <EventAutocomplete
                  tournaments={extractIds(tournaments)}
                  categories={extractIds(categories)}
                  sports={extractIds(sports)}
                />
              )}
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField name={fields.markets} deps={{ sports: fields.sports }}>
              {({ sports = [] }) => {
                return <MarketAutocomplete sports={extractIds(sports)} />;
              }}
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField
              name={fields.competitors}
              deps={{
                categories: fields.categories,
                tournaments: fields.tournaments,
                events: fields.events,
              }}
            >
              {({ categories = [], tournaments = [], events = [] }) => (
                <CompetitorAutocomplete
                  withSameOption={withSameCompetitorOption}
                  categories={extractIds(categories)}
                  tournaments={extractIds(tournaments)}
                  events={extractIds(events)}
                />
              )}
            </FormField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatedContingencyFields;
