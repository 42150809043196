import React, { useCallback, useRef } from 'react';
import TimePicker from './TimePicker';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  rangeTimeLabel: {
    color: '#8496AB',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.5px',
    margin: '0 8px 0 16px',
  },

  timeRange: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const TimePickerRange = ({
  className,
  focusRef,
  value,
  onChange,
  onComplete,
}) => {
  const classes = useStyles();
  const secondFocusRef = useRef();

  const focusTimePicker = useCallback(() => {
    if (secondFocusRef.current) {
      secondFocusRef.current.focus();
      secondFocusRef.current.select();
    }
  }, []);

  return (
    <div className={clsx(classes.timeRange, className)}>
      <div className={classes.rangeTimeLabel}>Starts</div>
      <TimePicker
        value={value[0]}
        onChange={v => onChange([v, value[1]])}
        hoursRef={focusRef}
        onComplete={focusTimePicker}
      />
      <div className={classes.rangeTimeLabel}>Ends</div>
      <TimePicker
        value={value[1]}
        onChange={v => onChange([value[0], v])}
        hoursRef={secondFocusRef}
        onComplete={onComplete}
      />
    </div>
  );
};

export default TimePickerRange;
