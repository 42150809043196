import React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import FieldLabel from '../FieldLabel';
import get from 'lodash/get';

const useStyles = makeStyles({
  rangeField: {
    display: 'flex',
    alignItems: 'center',
  },
  rangeFieldRoot: {
    width: 80,
    marginLeft: 16,
  },
  rangeFieldDivider: {
    height: 60,
    width: 2,
    borderRadius: 1,
    background: '#F7F7F7',
    margin: '0 16px',
  },
  rangeFieldLabel: {
    paddingBottom: 'initial',
  },
});

const RangeField = ({ schema }) => {
  const classes = useStyles();

  const inputClasses = {
    root: classes.rangeFieldRoot,
  };

  const startProperty = get(schema, 'properties.start', {});
  const endProperty = get(schema, 'properties.end', {});

  return (
    <div className={classes.rangeField}>
      <FieldLabel className={classes.rangeFieldLabel}>
        {startProperty.title}
      </FieldLabel>
      <TextField
        classes={inputClasses}
        type={'number'}
        margin={'dense'}
        variant="outlined"
        onChange={() => {}}
        onBlur={() => {}}
        onFocus={() => {}}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      />
      <div className={classes.rangeFieldDivider} />
      <FieldLabel className={classes.rangeFieldLabel}>
        {endProperty.title}
      </FieldLabel>
      <TextField
        classes={inputClasses}
        type={'number'}
        margin={'dense'}
        variant="outlined"
        onChange={() => {}}
        onBlur={() => {}}
        onFocus={() => {}}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      />
    </div>
  );
};

export default RangeField;
