import useAutocompleteData from '../../../hooks/useAutocompleteData';
import { forwardRef } from 'react';
import FancySelect from '../FancySelect/FancySelect';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';
import useFancyAutocompleteAllToggle from '../../../../hooks/useFancyAutocompleteAllToggle';
import FancyAutocompleteAllOption from '../FancyAutocomplete/FancyAutocompleteAllOption';

const CountrySelect = forwardRef(
  ({ multiple, value: v, onChange, ...props }, ref) => {
    const { data: options = [] } = useAutocompleteData({
      url: '/api/v1/Autocomplete/country/code',
      withoutParams: true,
    });

    const value = multiple ? v || [] : v;

    const [toggledAll, onToggleAll] = useFancyAutocompleteAllToggle({
      onChange,
      options,
      value,
      valueKey: 'code',
    });

    return (
      <FancySelectValueProxy
        multiple={multiple}
        value={value}
        options={options}
        onChange={onChange}
        valueKey={'code'}
      >
        <FancySelect
          ref={ref}
          label={'Country'}
          multiple={multiple}
          options={options}
          labelKey={'name'}
          valueKey={'code'}
          value={value}
          {...props}
          extraOptions={
            multiple && (
              <FancyAutocompleteAllOption
                label={'All Countries'}
                onToggle={onToggleAll}
                toggled={toggledAll}
              />
            )
          }
        />
      </FancySelectValueProxy>
    );
  }
);

export default CountrySelect;
