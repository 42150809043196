module.exports = {
  schema: {
    title: 'Set margin',
    type: 'object',
    required: ['Margin'],
    properties: {
      margin: {
        type: 'number',
      },
    },
  },
  uiSchema: {
    margin: {
      'ui:widget': 'MarginSettings',
    },
  },
  formData: {
    margin: '',
  },
};
