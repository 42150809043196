import React, { useCallback } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Highlighted from '../../../../components/Highlighted/Highlighed';

const useStyles = makeStyles({
  tournamentLine: {
    padding: '12px 0 12px 36px',
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tournamentName: {
    marginRight: 4,
    fontWeight: 600,
  },
  selectedLine: {
    backgroundColor: '#E8F2FF',
    transition: 'backgroundColor 0.1s linear',
    borderRadius: 4,
  },
});

const TradingToolSidebarTournament = ({
  tournament,
  tournamentSearchValue,
  selectedTournament,
  setSelectedTournament,
  onTournamentSelected,
}) => {
  const classes = useStyles();

  const handleTournamentSelected = useCallback(() => {
    if (tournament.id !== selectedTournament?.id) {
      onTournamentSelected();
      setSelectedTournament({ id: tournament.id, name: tournament.name });
    } else {
      setSelectedTournament(null);
    }
  }, [
    tournament,
    selectedTournament,
    setSelectedTournament,
    onTournamentSelected,
  ]);

  return (
    <span
      className={clsx(classes.tournamentLine, {
        [classes.selectedLine]: tournament.id === selectedTournament?.id,
      })}
      onClick={handleTournamentSelected}
    >
      <span className={classes.tournamentName}>
        <Highlighted highlight={tournamentSearchValue}>
          {tournament.name}
        </Highlighted>
      </span>
      {`(${tournament.active_events_count})`}
    </span>
  );
};

export default TradingToolSidebarTournament;
