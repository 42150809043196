import useFilters from '../../../hooks/useFilters';
import usePresetFilters from '../../../hooks/usePresetFilters';
import useBetHistoryFiltersConfig from './useBetHistoryFiltersConfig';
import useDefaultBetHistoryFiltersValues from './useDefaultBetHistoryFiltersValues';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';

const useBetHistoryFilters = ({
  withPresets = false,
  eventId,
  resetOffset,
  onResubmit,
  isOperator,
  customDefaultBetDate,
}) => {
  const { defaultFiltersValues } = useDefaultBetHistoryFiltersValues({
    eventId,
  });

  const { filtersConfig } = useBetHistoryFiltersConfig({
    isOperator,
    customDefaultBetDate: withPresets && customDefaultBetDate,
  });

  const filtersHookObject = {
    resetOffset,
    onResubmit,
    filters: filtersConfig,
    spanMin: 1,
    spanMax: 4,
  };

  const useFiltersHook = withPresets ? usePresetFilters : useFilters;

  //if we have preset on, we can get defaultValues from BE with preset
  const filtersConfiguration = withPresets
    ? filtersHookObject
    : { ...filtersHookObject, defaultValues: defaultFiltersValues };

  const { filtersCard, filters } = useFiltersHook({
    tableName: tableNames.BetHistory,
    ...filtersConfiguration,
  });

  return {
    filtersCard,
    filters,
  };
};

export default useBetHistoryFilters;
