import { getMethodAndUrl } from '../../utils/schemaHelper';
import { saveCsvFile } from '../../utils/saveFile';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { getUserToken } from '../../reducers/users';
import CircularProgress from '@mui/material/CircularProgress';
import { RoutesContext } from '../../providers/RoutesProvider';
import Button from '../../v2/components/Button/Button';
import DownloadIcon from '../../icons/controls/DownloadIcon';
import makeStyles from '@mui/styles/makeStyles';
import { useApiHost } from '../../providers/ApiHostProvider';

const useStyles = makeStyles({
  exportButtonRoot: {
    padding: 0,
    minWidth: 'initial',
    width: '100%',
    justifyContent: 'flex-start',
    height: 22,
  },
  exportIcon: {
    fontSize: '16px',
  },

  exportButtonLabel: {
    color: '#1581FF',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.5px',
    marginLeft: '8px',
  },
});

const ExportButton = ({ link, params, query, token }) => {
  const routes = useContext(RoutesContext);
  const [isExporting, setIsExporting] = useState(false);
  const { apiHost } = useApiHost();

  const classes = useStyles();

  return (
    <Button
      variant={'text'}
      disableRipple={true}
      classes={{
        root: classes.exportButtonRoot,
      }}
      disabled={isExporting}
      onClick={() => {
        setIsExporting(true);

        const { method: exportMethod, url: exportUrl } = getMethodAndUrl({
          path: link.path,
          params,
          query,
        });

        fetch(`${apiHost}${exportUrl}`, {
          method: exportMethod,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async response => {
            const data = await response.text();
            const filename = `${routes[link.path].summary || 'items'}.csv`;

            saveCsvFile(data, filename);

            setIsExporting(false);
          })
          .catch(error => {
            setIsExporting(false);
            console.error(error);
          });
      }}
    >
      {isExporting ? (
        <>
          <CircularProgress size={16} />
          &nbsp;Exporting...
        </>
      ) : (
        <>
          <DownloadIcon className={classes.exportIcon} />
          Export CSV
        </>
      )}
    </Button>
  );
};

export default connect(state => ({
  token: getUserToken(state),
}))(ExportButton);
