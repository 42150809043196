import { Box } from '@mui/material';

const DisabledItemArea = ({ loading = false }) => {
  const pulsAnimation = {
    '@keyframes puls-animation': {
      '0%': {
        opacity: 1,
      },
      '50%': {
        opacity: 0.5,
      },
      '100%': {
        opacity: 1,
      },
    },
    animation: 'puls-animation 1s ease infinite',
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 2,
        backgroundColor: 'rgba(247, 247, 247, 0.38)',
        opacity: 1,
        ...(loading ? pulsAnimation : {}),
      }}
    />
  );
};

export default DisabledItemArea;
