import { object, string, array } from 'yup';

const itemSchema = object({
  type: string()
    .oneOf(
      Object.values({
        ALL: 'all',
        SAME: 'same',
        LIST: 'list',
      })
    )
    .required(),
  value: array()
    .nullable()
    .of(
      object().shape({
        label: string().required(),
        value: string().required(),
      })
    )
    .when('type', {
      is: 'list',
      then: schema => schema.min(1).required(),
    }),
});

const EventFormValidationSchema = object({
  categories: itemSchema.required(),
  tournaments: itemSchema.required(),
  competitors: array(itemSchema).required().default([]),
});

export default EventFormValidationSchema;
