import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import CategorySelect from '../../components/Inputs/CategorySelect/CategorySelect';
import FancyAutocomplete from '../../components/Inputs/FancyAutocomplete/FancyAutocomplete';
import RMSettingsSelect from '../../components/Inputs/RMSettingsSelect/RMSettingsSelect';
import Select from '../../components/Inputs/Select/Select';
import NotificationAlert, {
  AlertVariant,
} from '../../components/Notifications/NotificationAlert';
import Page from '../../components/Page/Page';
import TableActionCards from '../../components/TableActionCards/TableActionCards';
import TablePagination from '../../components/TablePagination/TablePagination';
import SPORTS_AUTOCOMPLETE_QUERY from '../../gql/RiskManagement/queries/SPORTS_AUTOCOMPLETE_QUERY';
import GetTournamentsNames from '../../gql/RiskManagement/queries/getTournamentsNames';
import TOURNAMENTS_UPDATE_CONFIG from '../../gql/mutations/TOURNAMENTS_UPDATE_CONFIG';
import TOURNAMENTS_QUERY from '../../gql/queries/TOURNAMENTS_QUERY';
import usePresetFilters, { withoutEmpty } from '../../hooks/usePresetFilters';
import useTableBulkSelection from '../../hooks/useTableBulkSelection';
import useTableColumnsReorderPreset from '../../hooks/useTableColumnsReorderPreset';
import useTablePresetColumnToggle from '../../hooks/useTablePresetColumnToggle';
import useTablePresetPagination from '../../hooks/useTablePresetPagination';
import { usePresets } from '../../providers/PresetsProvider';
import TableBulkProvider from '../../providers/TableBulkProvider';
import { useToasts } from '../../providers/ToastsProvider';
import TournamentRiskRulesForm from './TournamentRiskRulesForm';
import TournamentsTable, {
  COLUMN,
  columnsOrderConfig,
  initialToggledColumns,
} from './TournamentsTable';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../components/ErrorWidget';

const TournamentsPage = ({}) => {
  const navigate = useNavigate();

  const [toggledColumns, onToggleColumns] = useTablePresetColumnToggle(
    initialToggledColumns
  );

  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorderPreset(columnsOrderConfig);

  const { limit, setLimit, offset, setOffset } = useTablePresetPagination({
    tableName: tableNames.Tournaments,
  });
  const [tableOffsetTop, setTableOffsetTop] = useState(0);

  const refetchTournamentsRef = useRef(() => {});
  const actionCardsRef = useRef(null);

  useResizeObserver({
    ref: actionCardsRef,
    onResize: rect => {
      setTableOffsetTop(rect.height);
    },
  });

  const refetchTournaments = useCallback(loader => {
    return refetchTournamentsRef
      .current()
      .then(({ data: { getRmTournaments: tournaments } }) => {
        // setTournaments(tournaments);
      });
  }, []);

  const resubmit = useCallback(() => {
    refetchTournaments(true);
  }, [refetchTournaments]);

  const { filtersCard, filters } = usePresetFilters({
    tableName: tableNames.Tournaments,
    resetOffset: setOffset,
    onResubmit: resubmit,
    hidePresets: true,
    extraFilters: false,
    filters: [
      {
        name: 'sport_id',
        label: 'Sport',
        fullWidth: false,
        component: (
          <FancyAutocomplete
            gqlQuery={SPORTS_AUTOCOMPLETE_QUERY}
            proxyValue
            multiple={false}
          />
        ),
        toggled: true,
      },
      {
        name: 'category_id',
        label: 'Category',
        fullWidth: false,
        component: ({ sport_id, ...props }) => (
          <CategorySelect sportId={sport_id} disabled={!sport_id} {...props} />
        ),
        toggled: true,
        deps: ['sport_id'],
      },
      {
        name: 'risk_management_id',
        label: 'Global RM Settings',
        fullWidth: false,
        component: <RMSettingsSelect label={'Global RM Settings'} />,
        toggled: true,
      },
      {
        name: 'tournament_id',
        label: 'Tournament',
        fullWidth: false,
        component: (
          <FancyAutocomplete
            multiple={false}
            gqlQuery={GetTournamentsNames}
            proxyValue
          />
        ),
        toggled: true,
      },
      {
        name: 'period',
        label: 'Period',
        fullWidth: false,
        component: (
          <Select
            label={'Period'}
            options={[
              {
                label: 'All time periods',
                value: 'whole',
              },
              {
                label: 'Today',
                value: 'event_date_today',
              },
              {
                label: '48h',
                value: 'event_date_48h',
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'template_available',
        label: 'Template',
        fullWidth: false,
        component: (
          <Select
            label={'Period'}
            options={[
              {
                label: 'All tournaments',
                value: 'all',
              },
              {
                label: 'With Template',
                value: 'have',
              },
              {
                label: 'Without Template',
                value: 'without',
              },
            ]}
          />
        ),
        toggled: true,
      },
    ],
  });

  const queryVariables = useMemo(() => {
    return {
      ...withoutEmpty(filters),
      limit,
      offset,
    };
  }, [limit, offset, filters]);

  const { loading, error, data, refetch } = useQuery(TOURNAMENTS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: queryVariables,
    },
  });

  const items = useMemo(() => {
    return (data && data.data && data.data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return data && data.data && data.data.total;
  }, [data]);

  refetchTournamentsRef.current = refetch;

  const [
    updateConfig,
    { loading: updateConfigLoading, error: updateConfigError },
  ] = useMutation(TOURNAMENTS_UPDATE_CONFIG, {
    refetchQueries: ['getRmTournaments', 'getTournamentRules'],
  });

  const actions = useMemo(() => {
    return [filtersCard];
  }, [filtersCard]);

  const bulk = useTableBulkSelection(items, COLUMN.ID);

  const chosenIds = useMemo(
    () => Object.keys(bulk.chosenItems).filter(id => bulk.chosenItems[id]),
    [bulk.chosenItems]
  );

  const [configFormMessage, setConfigFormMessage] = useState(null);

  const toggleConfigFormMessage = useCallback((message, timeout = 2000) => {
    setConfigFormMessage(message);

    setTimeout(() => {
      setConfigFormMessage(null);
    }, timeout);
  }, []);

  const client = useApolloClient();

  const { showToast } = useToasts();

  const onSubmitConfigForm = useCallback(
    values => {
      updateConfig({
        variables: {
          ids: chosenIds,
          input: values,
        },
      })
        .then(({ data: { data } = {} }) => {
          toggleConfigFormMessage(`Applied to ${chosenIds.length} Tournaments`);
          client.resetStore();

          if (data && data.success) {
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={10000}>
                {data.success} rule(s) created successfully.
              </NotificationAlert>
            );
          }

          if (data && data.errors && data.errors.length) {
            showToast(
              <NotificationAlert variant={AlertVariant.WARNING} timeout={10000}>
                {data.errors.length} rule(s) creating failed (reason — existing
                rule).
              </NotificationAlert>
            );
          }
        })
        .catch(() => {
          toggleConfigFormMessage(`Something went wrong...`);
        });
    },
    [updateConfig, chosenIds, toggleConfigFormMessage, client, showToast]
  );

  const rowActions = useMemo(
    () => [
      {
        key: 'events',
        label: "Tournament's events",
        onClick: item => {
          navigate(
            `/api/v1/RiskManagementAdmin/tournaments/${
              item[COLUMN.ID]
            }/events/get`
          );
        },
      },
    ],
    [navigate]
  );

  if (error || updateConfigError) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Tournaments'}>
      <TableActionCards animated actions={actions} ref={actionCardsRef} />

      <TournamentRiskRulesForm
        formMessage={configFormMessage}
        selected={chosenIds.length}
        loading={updateConfigLoading}
        disabled={!bulk.someToggled}
        onSubmit={onSubmitConfigForm}
      />

      <Box mt={2} sx={{ position: 'relative', zIndex: 2 }}>
        <TableBulkProvider bulk={bulk}>
          <TournamentsTable
            toggledColumns={toggledColumns}
            onToggleColumns={onToggleColumns}
            order={columnsOrder}
            onReorder={onReorderColumns}
            loading={loading}
            data={items}
            rowActions={rowActions}
            tableOffsetTop={tableOffsetTop}
          />
        </TableBulkProvider>
      </Box>

      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </Page>
  );
};

export default TournamentsPage;
