import { Box } from '@mui/material';
import useTreeSelectStyles from '../styles/TreeSelect.styles';

const ModalContentContainer = ({ children }) => {
  const classes = useTreeSelectStyles();

  return <Box className={classes.modalContentBox}>{children}</Box>;
};

export default ModalContentContainer;
