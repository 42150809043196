import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormField from '../../../../components/Form/FormField';
import TabContent from '../../../../components/Tabs/TabContent';
import Tabs from '../../../../components/Tabs/Tabs';
import DeviceIcon, {
  DeviceEnum,
} from '../../../../icons/DeviceIcon/DeviceIcon';
import { useImagePreviewByName } from '../../providers/ImagePreviewProvider';
import { BannerImageType } from '../BannerForm/BannerValidationSchema';
import { getInitialCrop } from '../BannerImagePreview/BannerImagePreview';
import BannerImageUploader from '../BannerImageUploader/BannerImageUploader';
import BannerImageTabLabel from './BannerImageTabLabel';

const BannerImageTabs = ({ prefix, preview, previewFields }) => {
  const { setValue } = useFormContext();

  const [tabNotifications, setTabNotifications] = useState({});

  const getPrefixByType = useCallback(
    type => {
      return `${prefix}.${type}`;
    },
    [prefix]
  );

  const { setPreviewValue: setTabletPreviewValue } = useImagePreviewByName(
    getPrefixByType(BannerImageType.TABLET)
  );
  const { setPreviewValue: setMobilePreviewValue } = useImagePreviewByName(
    getPrefixByType(BannerImageType.MOBILE)
  );

  const onResetMultiple = useCallback(() => {
    setValue(getPrefixByType(BannerImageType.TABLET), {});

    setTabletPreviewValue('');

    setValue(getPrefixByType(BannerImageType.MOBILE), {});

    setMobilePreviewValue('');
  }, [setValue, setMobilePreviewValue, setTabletPreviewValue, getPrefixByType]);

  const onUploadedMultiple = useCallback(
    (id, preview, size) => {
      setValue(getPrefixByType(BannerImageType.TABLET), {
        source_id: id,
        ...getInitialCrop(
          {
            width: 960,
            height: 370,
          },
          size
        ),
      });

      setTabletPreviewValue(preview);

      setValue(getPrefixByType(BannerImageType.MOBILE), {
        source_id: id,
        ...getInitialCrop(
          {
            width: 580,
            height: 370,
          },
          size
        ),
      });

      setMobilePreviewValue(preview);

      setTabNotifications({
        tablet: true,
        mobile: true,
      });
    },
    [setValue, setMobilePreviewValue, setTabletPreviewValue, getPrefixByType]
  );

  return (
    <Tabs
      onChangeTab={key => {
        if (tabNotifications[key]) {
          setTabNotifications(curr => ({
            ...curr,
            [key]: false,
          }));
        }
      }}
      fullWidthTabs
      tabs={[
        {
          key: 'desktop',
          label: (
            <BannerImageTabLabel>
              <DeviceIcon name={DeviceEnum.DESKTOP} />
              Desktop Banner
            </BannerImageTabLabel>
          ),
          content: (
            <TabContent>
              <FormField name={getPrefixByType(BannerImageType.DESKTOP)}>
                <BannerImageUploader
                  uploadButtonLabel={'Upload image for Desktop'}
                  multipleUpload
                  onUploadedMultiple={onUploadedMultiple}
                  onResetMultiple={onResetMultiple}
                  preview={preview}
                  previewFields={previewFields}
                  previewSize={{
                    width: 1171,
                    height: 268,
                  }}
                  cropSize={{
                    width: 1660,
                    height: 370,
                  }}
                  useGradient
                />
              </FormField>
            </TabContent>
          ),
        },
        {
          key: 'tablet',
          disabled: false,
          label: (
            <BannerImageTabLabel notification={tabNotifications.tablet}>
              <DeviceIcon name={DeviceEnum.TABLET} />
              Tablet Banner
            </BannerImageTabLabel>
          ),
          content: (
            <TabContent>
              <FormField name={getPrefixByType(BannerImageType.TABLET)}>
                <BannerImageUploader
                  uploadButtonLabel={'Upload image for Tablet'}
                  preview={preview}
                  previewFields={previewFields}
                  cropSize={{
                    width: 960,
                    height: 221,
                  }}
                  useGradient
                />
              </FormField>
            </TabContent>
          ),
        },
        {
          key: 'mobile',
          disabled: false,
          label: (
            <BannerImageTabLabel notification={tabNotifications.mobile}>
              <DeviceIcon name={DeviceEnum.MOBILE} />
              Mobile Banner
            </BannerImageTabLabel>
          ),
          content: (
            <TabContent>
              <FormField name={getPrefixByType(BannerImageType.MOBILE)}>
                <BannerImageUploader
                  uploadButtonLabel={'Upload image for Mobile'}
                  preview={preview}
                  previewFields={previewFields}
                  cropSize={{
                    width: 335,
                    height: 224,
                  }}
                  useGradient
                />
              </FormField>
            </TabContent>
          ),
        },
      ]}
    />
  );
};

export default BannerImageTabs;
