import { styled } from '@mui/material/styles';

const Selections = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SelectionField = styled('div')`
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DoubleSelectionsField = ({ selection1, selection2, render }) => {
  return (
    <Selections>
      <SelectionField>{render(selection1)}</SelectionField>
      <SelectionField>{render(selection2)}</SelectionField>
    </Selections>
  );
};

export default DoubleSelectionsField;
