import { makeStyles } from '@mui/styles';

export default makeStyles({
  container: {
    width: '100%',
    '& .MuiDialogContent-root': {
      padding: 0,
    },
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0.5px',
  },
  multiField: {
    '& > *': {
      '&:last-child': {
        flexBasis: '108px',
      },
    },
  },
  autocomplete: {
    width: '100%',
  },
  loader: {
    height: '300px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
