import { makeStyles } from '@mui/styles';

export default makeStyles({
  root: {
    padding: '24px 24px 14px',
    minWidth: '520px',
    '& .MuiDialogContent-root': {
      padding: '8px 0 12px 0',
      overflowY: 'scroll',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  content: {
    display: 'flex',
    overflow: 'hidden',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
    padding: 0,
  },
  titleText: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
  },
});
