import FancySelect, { FancySelectOption } from '../FancySelect/FancySelect';
import Checkbox from '../Checkbox';
import { useCallback, useMemo, useEffect, useState } from 'react';
import useAutocompleteQuery from '../../../../hooks/useAutocompleteQuery';
import useSelectCheckValueOptions from '../../../../hooks/useSelectCheckValueOptions';
import usePrevious from '../../../../hooks/usePrevious';
import uniqBy from 'lodash/uniqBy';

export const RelatedContingencySelectMode = {
  LIST: 'list',
  SAME: 'same',
  ALL: 'all',
};

const RelatedContingencyAutocomplete = ({
  gqlQuery,
  params,
  label,
  allLabel = 'All',
  sameLabel = 'Same',
  valueKey = 'value',
  labelKey = 'label',
  value = {
    type: RelatedContingencySelectMode.LIST,
    value: [],
  },
  onChange,
  extraOptions = null,
}) => {
  const {
    data: options = [],
    loading,
    setQuery: onChangeSearch,
  } = useAutocompleteQuery(gqlQuery, params);
  const [initialValue, setInitialValue] = useState([]);
  const previousParams = usePrevious(params);
  const mode = value.type;

  const onChangeMode = useCallback(
    type => {
      onChange({
        value: [],
        type,
      });
    },
    [onChange]
  );

  const onChooseAll = useCallback(() => {
    if (mode !== RelatedContingencySelectMode.ALL) {
      onChangeMode(RelatedContingencySelectMode.ALL);
    } else {
      onChangeMode(RelatedContingencySelectMode.LIST);
    }
  }, [mode, onChangeMode]);

  const onChangeSelect = useCallback(
    selectValue => {
      onChange({
        type: RelatedContingencySelectMode.LIST,
        value: selectValue,
      });
    },
    [onChange]
  );

  const selectValue = useMemo(() => {
    return value.value || [];
  }, [value]);

  const selectOptions = useMemo(() => {
    return uniqBy([...options, ...initialValue], 'value');
  }, [options, initialValue]);

  useSelectCheckValueOptions({
    value: value.value,
    options,
    valueKey,
    multiple: true,
    onChange: onChangeSelect,
    isValueObject: true,
    loading,
    check: false,
  });

  const isOptionSelected = useCallback(
    option => {
      return (
        mode === RelatedContingencySelectMode.ALL ||
        !!selectValue.find(item => item[valueKey] === option[valueKey])
      );
    },
    [selectValue, mode, valueKey]
  );

  const isOptionDisabled = useCallback(() => {
    return mode === RelatedContingencySelectMode.ALL;
  }, [mode]);

  const getInputPlaceholder = useCallback(() => {
    if (mode === RelatedContingencySelectMode.ALL) {
      return allLabel;
    }

    if (mode === RelatedContingencySelectMode.SAME) {
      return sameLabel;
    }

    if (mode === RelatedContingencySelectMode.LIST) {
      return selectValue.map(item => item[labelKey]).join(', ');
    }
  }, [allLabel, sameLabel, mode, selectValue, labelKey]);

  useEffect(() => {
    if (!initialValue.length && value.value) {
      setInitialValue(value.value);
    }
  }, [value, initialValue, setInitialValue]);

  useEffect(() => {
    if (params !== previousParams) {
      setInitialValue([]);
    }
  }, [params, previousParams, setInitialValue]);

  return (
    <FancySelect
      loading={loading}
      label={label}
      onChange={onChangeSelect}
      value={selectValue}
      options={selectOptions}
      isOptionSelected={isOptionSelected}
      isOptionDisabled={isOptionDisabled}
      getInputPlaceholder={getInputPlaceholder}
      onChangeSearch={onChangeSearch}
      extraOptions={
        <>
          {extraOptions}
          <FancySelectOption onClick={onChooseAll}>
            {allLabel}{' '}
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              <Checkbox value={mode === RelatedContingencySelectMode.ALL} />
            </div>
          </FancySelectOption>
        </>
      }
    />
  );
};

export default RelatedContingencyAutocomplete;
