import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback, useMemo, useState } from 'react';
import TabLabel from '../../../components/Tabs/TabLabel';
import Tabs from '../../../components/Tabs/Tabs';
import ChangeLogTab from './ChangeLog/ChangeLogTab';
import CoverageTab from './Coverage/CoverageTab';
import SourcesTab from './Sources/SourcesTab';

const LineSettingsTableTabContent = styled(Box)`
  background-color: #f7f7f7;
  border-radius: 4px;
`;

const LineSettingsExpanded = ({ lineSettingsId, rowData }) => {
  const [withoutSpacing, setWithoutSpacing] = useState(true);

  const tabs = [
    rowData.coverage && {
      key: 'coverage',
      label: <TabLabel>Coverage</TabLabel>,
      content: (
        <LineSettingsTableTabContent key={'coverage'}>
          <CoverageTab templateId={rowData.coverage.id} />
        </LineSettingsTableTabContent>
      ),
    },
    rowData.sources && {
      key: 'sources',
      label: <TabLabel>Sources (pm/live)</TabLabel>,
      content: (
        <LineSettingsTableTabContent key={'sources'}>
          <SourcesTab templateId={rowData.sources.id} />
        </LineSettingsTableTabContent>
      ),
    },
    //margin value temporary mocked
    // rowData.margin && {
    //   key: 'margin',
    //   label: <TabLabel>Margin</TabLabel>,
    //   content: (
    //     <LineSettingsTableTabContent key={'margin'}>
    //       <MarginTab templateId={rowData.margin.id} />
    //     </LineSettingsTableTabContent>
    //   ),
    // },
    {
      key: 'change-log',
      label: <TabLabel>Change Log</TabLabel>,
      content: (
        <LineSettingsTableTabContent key={'change-log'}>
          <ChangeLogTab lineSettingsId={lineSettingsId} />
        </LineSettingsTableTabContent>
      ),
    },
  ];

  const activeTab = useMemo(() => {
    return rowData.coverage
      ? 'coverage'
      : rowData.sources
      ? 'sources'
      : rowData.margin
      ? 'margin'
      : 'change-log';
  }, [rowData]);

  const onChangeTab = useCallback(
    key => {
      setWithoutSpacing(key !== 'change-log');
    },
    [setWithoutSpacing]
  );

  return (
    <Tabs
      activeTabKey={activeTab}
      fullWidthTabs
      onChangeTab={onChangeTab}
      withoutSpacing={withoutSpacing}
      tabs={tabs.filter(item => item)}
      mt={0.5}
    />
  );
};

export default LineSettingsExpanded;
