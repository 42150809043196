import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  field: {},
});

const Field = ({ className, children }) => {
  const classes = useStyles();

  return <div className={clsx(classes.field, className)}>{children}</div>;
};

export default Field;
