import { Box } from '@mui/material';
import useTreeSelectStyles from '../styles/TreeSelect.styles';

const TreeSelectContainer = ({ children }) => {
  const classes = useTreeSelectStyles();

  return <Box className={classes.treeSelectContainer}>{children}</Box>;
};

export default TreeSelectContainer;
