import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
} from 'react-virtualized';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';
import SelectMenu from './SelectMenu';

const InfiniteMenu = ({
  itemRender,
  isRowLoaded = () => {},
  loadMoreRows = () => {},
  containerStyle,
  rowCount,
}) => {
  const classes = useTreeSelectStyles();
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 86,
  });

  return (
    <SelectMenu style={containerStyle} autoSize>
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            threshold={1}
            rowCount={1500}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                width={width}
                height={height}
                ref={registerChild}
                deferredMeasurementCache={cache}
                onRowsRendered={onRowsRendered}
                rowHeight={cache.rowHeight}
                rowRenderer={({ index, key, style, parent }) => {
                  return (
                    <CellMeasurer
                      key={index}
                      cache={cache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                      style={style}
                    >
                      {({ measure }) => {
                        return (
                          <div
                            // ref={registerChild}
                            style={{ ...style, width: '100% ' }}
                          >
                            {itemRender({ measure, index })}
                          </div>
                        );
                      }}
                    </CellMeasurer>
                  );
                }}
                rowCount={rowCount}
                className={classes.virtualizedMenu}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </SelectMenu>
  );
};

export default InfiniteMenu;
