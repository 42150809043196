import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UserIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 10 15'} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0C3.34315 0 2 1.34315 2 3C2 4.65685 3.34315 6 5 6C6.65685 6 8 4.65685 8 3C8 1.34315 6.65685 0 5 0ZM0 8H3L5 10L7 8H10V15H0V8Z"
        fill="#1581FF"
      />
    </SvgIcon>
  );
};

export default UserIcon;
