import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import CopyField from '../../../../../Fields/CopyField/CopyField';
import StringField from '../../../../../Fields/StringField/StringField';
import Tooltip from '../../../../../Tooltip/Tooltip';
import Checkbox from '../../../../Checkbox';
import ExpandableOrderedList from '../../../components/ExpandableOrderedList';
import ItemMoveButton from '../../../components/ItemMoveButton';
import useCoverageStyles from '../../../styles/Coverage.style';
import { getKeySelector } from '../../../utils/getKeySelector';

const CoverageMarketOption = ({
  nodePath = {},
  selected,
  itemValue,
  lineType,
  onMenuOpen,
  isMenuOpen,
  toggleMenu,
  listItems,
  marketType,
  dragDropDisabled,
  configurationDisabled,
  provided,
}) => {
  const classes = useCoverageStyles();

  const { getValues, setValue } = useFormContext();
  const configLineType = getValues('configLineType');
  const {
    setNodePath,
    preset,
    selectedCoverageItemsConfig: { [lineType]: selectedCoverageItemsConfig },
  } = useTreeSelectContext();

  const marketInheritedStatus = useMemo(() => {
    const selectedCategoryId = nodePath.categoryId;
    const tournamentCategoryId =
      selectedCoverageItemsConfig.tournament.categoryId;
    const categoryId = tournamentCategoryId || selectedCategoryId;
    const selectedEventStatus = selectedCoverageItemsConfig.event.status;
    const selectTournamentStatus =
      selectedCoverageItemsConfig.tournament.status;
    const selectedCategoryStatus =
      selectedCoverageItemsConfig.category[categoryId];
    const selectedSportStatus = selectedCoverageItemsConfig.sport.status;

    if (selectedSportStatus === false) {
      return selectedSportStatus;
    }
    if (marketType === 'category' && selectedCategoryStatus === false) {
      return selectedCategoryStatus;
    }
    if (
      marketType === 'tournament' &&
      (selectTournamentStatus === false || selectedCategoryStatus === false)
    ) {
      return false;
    }
    if (
      marketType === 'event' &&
      (selectedEventStatus === false ||
        selectTournamentStatus === false ||
        selectedCategoryStatus === false)
    ) {
      return false;
    }
  }, [marketType, nodePath, selectedCoverageItemsConfig]);

  const template = getValues('template');

  const nodeSelector = withoutEmpty({
    sportId: nodePath.sportId,
    categoryId: nodePath.tournamentId ? undefined : nodePath.categoryId,
    tournamentId: nodePath.tournamentId,
    eventId: nodePath.eventId,
    market: {
      marketId: itemValue.marketId,
    },
  });

  const keySelector = getKeySelector({
    nodeSelector,
    lineType: configLineType,
  });

  const rootMarketsNodeSelector = {
    sportId: nodePath.sportId,
    market: {
      marketId: itemValue.marketId,
    },
  };

  const rootMarketsKeySelector = getKeySelector({
    nodeSelector: rootMarketsNodeSelector,
    lineType: configLineType,
  });

  const getCurrentConfigs = () => {
    const template = getValues('template');

    return {
      config: template[keySelector] || {},
      rootConfig: template[rootMarketsKeySelector] || {},
    };
  };

  const getMarketStatus = () => {
    const { rootConfig, config } = getCurrentConfigs();

    if (config.status !== undefined && config.status !== null) {
      return config.status;
    }

    if (rootConfig.status !== undefined && rootConfig.status !== null) {
      return rootConfig.status;
    }

    return itemValue.status;
  };

  const [checked, setChecked] = useState(getMarketStatus());

  //functional moved to next version
  // const [cashOut, setCashOut] = useState(
  //   getParentCashOut({
  //     template,
  //     config,
  //     marketType,
  //     nodePath,
  //     lineType,
  //     itemValue,
  //   })
  // );

  const onSelect = useCallback(() => {
    if (!selected) {
      let path = structuredClone(nodePath);
      path.market.marketId = itemValue.marketId;
      setNodePath(currentPath => {
        return {
          ...currentPath,
          [lineType]: path,
        };
      });
    }
  }, [itemValue, nodePath, selected, lineType, setNodePath]);

  const dragHandleProps = !configurationDisabled &&
    !dragDropDisabled && { ...provided.dragHandleProps };

  useEffect(() => {
    onMenuOpen && onMenuOpen();
  }, [isMenuOpen, onMenuOpen]);

  useEffect(() => {
    setChecked(getMarketStatus());

    //functional moved to next version
    // setCashOut(
    //   getParentCashOut({
    //     template,
    //     config,
    //     marketType,
    //     nodePath,
    //     lineType,
    //     itemValue,
    //   })
    // );

    // eslint-disable-next-line
  }, [preset, template, marketType]);

  const onChangeStatus = value => {
    const { rootConfig, config } = getCurrentConfigs();

    setChecked(value);
    setValue(`template.${rootMarketsKeySelector}`, {
      lineType,
      nodeSelector: rootMarketsNodeSelector,
      priority: rootConfig.priority,
      // cashOut: rootConfig.cashOut,
      status: value,
      groups: rootConfig.groups ?? [],
      mainMarketPriority:
        config.mainMarketPriority || rootConfig.mainMarketPriority || undefined,
    });
  };

  //functional moved to next version
  // const onToggleCashout = value => {
  //   const { rootConfig, config } = getCurrentConfigs();

  //   setCashOut(currentValue => ({ ...currentValue, value }));
  //   setValue(`template.${keySelector}`, {
  //     lineType,
  //     nodeSelector,
  //     priority: config.priority || itemValue.priority,
  //     cashOut: value,
  //     status: rootConfig.status ?? itemValue.status,
  //     groups: rootConfig.groups ?? [],
  //     mainMarketPriority:
  //       config.mainMarketPriority || rootConfig.mainMarketPriority || undefined,
  //   });
  //   const template = getValues('template');

  //   //we need to change saved configs because cashout affects on bottom levels markets
  //   Object.keys(template).forEach(key => {
  //     const currentNodeSelectorKeys = Object.keys(template[key].nodeSelector);
  //     const currentLineType = template[key].lineType;
  //     const itemNodeSelectorKeys = Object.keys(nodeSelector);

  //     if (
  //       nodeSelector.market.marketId === itemValue.marketId &&
  //       currentLineType === lineType &&
  //       currentNodeSelectorKeys.length > itemNodeSelectorKeys.length
  //     ) {
  //       template[key].cashOut = value;
  //     }
  //   });
  // };

  return (
    <Box sx={{ width: '100%' }}>
      <Box display={'flex'} alignItems={'center'}>
        <ItemMoveButton
          dragHandleProps={dragHandleProps}
          selected={selected}
          dragDropDisabled={configurationDisabled || dragDropDisabled}
        />

        <Checkbox
          value={marketInheritedStatus === false ? false : checked}
          onChange={onChangeStatus}
          style={{ margin: 0 }}
          disabled={
            configurationDisabled ||
            itemValue.readOnly ||
            marketInheritedStatus === false
          }
        />
        <Box className={classes.coverageMarketOptionContent}>
          <Box className={classes.coverageMarketNameContainer}>
            <Box className={classes.coverageMarketName}>
              <CopyField
                value={itemValue.marketId}
                style={{ width: 'fit-content' }}
              />
              <Tooltip overflowOnly title={itemValue.name}>
                <StringField
                  className={classes.coverageOptionCaption}
                  onClick={onSelect}
                >
                  {itemValue.name}
                </StringField>
              </Tooltip>
            </Box>
          </Box>

          {
            //functional moved to next version
            /* <Box className={classes.cashoutSwitch}>
            <StringField style={{ fontSize: '14px' }}>Cashout</StringField>
            <Switch
              disabled={cashOut.disabled}
              color={'primary'}
              value={cashOut.value}
              onChange={onToggleCashout}
            />
          </Box> */
          }
        </Box>
      </Box>
      <ExpandableOrderedList
        selected={selected}
        disabled={configurationDisabled}
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        marketProviders={false}
        items={listItems.map(value => value.label) || []}
      />
    </Box>
  );
};

export default CoverageMarketOption;
