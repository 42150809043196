import React from 'react';
import LocalQueryProvider from '../../providers/LocalQueryProvider';
import ActionCards from '../../components/ActionCards';

const FiltersWithPresets = props => {
  const { path } = props;

  return (
    <LocalQueryProvider>
      <ActionCards path={path} />
    </LocalQueryProvider>
  );
};

export default FiltersWithPresets;
