import TableActionCard from './TableActionCard';
import TableActionCardExpansion from './TableActionCardExpansion';

const TableFiltersActionCard = ({
  isOpen,
  onChange,
  onChangeHeight,
  style,
  children,
  large = false,
  inline = false,
}) => {
  return (
    <TableActionCard style={style} inline={inline} large={!isOpen && large}>
      <TableActionCardExpansion
        summary={<div data-cy={'action-card-filters'}>Filters</div>}
        isOpen={isOpen}
        onChange={onChange}
        onChangeHeight={onChangeHeight}
      >
        {children}
      </TableActionCardExpansion>
    </TableActionCard>
  );
};

export default TableFiltersActionCard;
