import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import classnames from 'clsx';
import useIsOpen from '../hooks/useIsOpen';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  error: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.9)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
  },
  wrappedComponent: {
    height: '100%',
    '& > *': {
      height: '100%',
    },
  },
  reloadButton: {
    marginBottom: theme.spacing(1),
  },
  buttonIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
});

const enhance = withStyles(styles);

const withFullHeightError = BaseComponent => {
  const WithFullHeightErrorHOC = ({ classes, className, ...props }) => {
    const { error, routes, path, fetchData } = props;
    const isExpectedError = error && routes[path].responses[error.status];
    const { isOpen, open, close } = useIsOpen();

    return (
      <div className={classnames(classes.root, className)}>
        {error && (
          <div className={classes.error}>
            <Typography variant="h6">
              Error {isExpectedError ? error.response.code : error.status}
            </Typography>
            <Typography gutterBottom>
              {isExpectedError
                ? error.response.description
                : 'Server request failure'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.reloadButton}
              onClick={fetchData}
            >
              <RefreshIcon className={classes.buttonIcon} />
              Reload
            </Button>
            {isExpectedError
              ? !!error.response.message
              : !!error.stack && (
                  <Button onClick={open} color="primary" size="small">
                    Show more
                  </Button>
                )}
            <Dialog
              open={isOpen}
              onClose={close}
              aria-labelledby="error-details"
            >
              <DialogTitle id="error-details">
                {isExpectedError
                  ? `${error.response.code} - ${error.response.description}`
                  : `${error.status} - ${error.message}`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText component="pre">
                  {isExpectedError ? error.response.message : error.stack}
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        )}
        <div className={classes.wrappedComponent}>
          <BaseComponent {...props} />
        </div>
      </div>
    );
  };

  WithFullHeightErrorHOC.displayName = `withFullHeightError(${BaseComponent.displayName})`;

  return enhance(WithFullHeightErrorHOC);
};

export default withFullHeightError;
