import { gql } from '@apollo/client';

const getSourcesTemplatesDiff = gql`
  query getSourcesTemplatesDiff(
    $limit: Int!
    $offset: Int!
    $searchQuery: String
    $templateId: TemplateId!
  ) {
    data: getSourcesTemplatesDiff(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      templateId: $templateId
    ) {
      items {
        sport {
          name
          id
        }
        source
        lastUpdated
        status
        user
      }
      parent_template {
        id
        name
      }
      total
    }
  }
`;

export default getSourcesTemplatesDiff;
