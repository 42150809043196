import { Box } from '@mui/material';
import ClearInputIcon from '../../../../../icons/controls/ClearInputIcon';
import useTreeSelectStyles from '../styles/TreeSelect.styles';

const ModalTitle = ({ title = 'Title', onClose = () => {} }) => {
  const classes = useTreeSelectStyles();

  return (
    <Box className={classes.modalTittle}>
      {title}

      <ClearInputIcon className={classes.modalCloseButton} onClick={onClose} />
    </Box>
  );
};

export default ModalTitle;
