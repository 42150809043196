import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  filledTableCell: {
    width: '100%',
    height: '100%',
  },
});

const FilledTableCell = ({ color, children }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.filledTableCell}
      style={{
        backgroundColor: color,
      }}
    >
      {children}
    </div>
  );
};

export default FilledTableCell;
