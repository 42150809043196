import { gql } from '@apollo/client';
import mergerGroupFragment from '../fragments/mergerGroupFragment';

const mergeGroups = gql`
  ${mergerGroupFragment}
  mutation mergeGroups($inputGroups: GroupsToMerge!) {
    mergeGroups(inputGroups: $inputGroups) {
      ...MergerGroupFragment
    }
  }
`;

export default mergeGroups;
