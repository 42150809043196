import { gql } from '@apollo/client';

const GetEventsNames = gql`
  query getEventsNames(
    $query: String!
    $brand_id: BrandId
    $limit: Int
    $offset: Int
    $sport_ids: [ID!]
    $exclude_sport_ids: Boolean
    $categories_ids: [ID!]
    $exclude_categories_ids: Boolean
    $tournaments_ids: [ID!]
    $exclude_tournaments_ids: Boolean
    $events_ids: [ID!]
  ) @api(name: rm) {
    data: getEventsNames(
      query: $query
      brand_id: $brand_id
      limit: $limit
      offset: $offset
      sport_ids: $sport_ids
      exclude_sport_ids: $exclude_sport_ids
      categories_ids: $categories_ids
      exclude_categories_ids: $exclude_categories_ids
      tournaments_ids: $tournaments_ids
      exclude_tournaments_ids: $exclude_tournaments_ids
      events_ids: $events_ids
    ) {
      label
      value
    }
  }
`;

export default GetEventsNames;
