import {
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import useTableExpandRows from '../../hooks/useTableExpandRows';

const ExpandableTable = forwardRef(
  (
    {
      id,
      data,
      renderRow,
      renderHeaderRow,
      children,
      loading,
      renderExpandedRow,
      renderSummaryRow,
      summary,
      scrollable,
      persistToggledOnListChange,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef(null);

    const { onExpand, expandedRows, expandAll, everyToggled } =
      useTableExpandRows(data, 'groupId', persistToggledOnListChange);

    const tableRef = ref || innerRef;

    const recomputeRowHeight = useCallback(
      rowIndex =>
        tableRef &&
        tableRef.current &&
        tableRef.current.recalculateSizes(rowIndex),
      [tableRef]
    );

    const clearRowHeightCache = useCallback(
      rowIndex => {
        tableRef &&
          tableRef.current &&
          tableRef.current.clearRowHeightCache(rowIndex);
      },
      [tableRef]
    );

    const onExpandCb = useCallback(
      (rowId, rowIndex) => {
        onExpand(rowId);
        clearRowHeightCache(rowIndex);
        recomputeRowHeight(rowIndex);
      },
      [onExpand, clearRowHeightCache, recomputeRowHeight]
    );

    const onExpandAllCb = useCallback(() => {
      expandAll();
      clearRowHeightCache();
    }, [expandAll, clearRowHeightCache]);

    const onChangeRowHeightCb = useCallback(
      rowIndex => {
        clearRowHeightCache(rowIndex);
        recomputeRowHeight(rowIndex);
      },
      [clearRowHeightCache, recomputeRowHeight]
    );

    const renderExpandedRowCb = useCallback(
      (rowIndex, rowData) => {
        return renderExpandedRow(rowIndex, rowData, onChangeRowHeightCb);
      },
      [renderExpandedRow, onChangeRowHeightCb]
    );

    useEffect(() => {
      clearRowHeightCache();
    }, [data, clearRowHeightCache]);

    const renderExpandableRow = useCallback(
      ({ rowIndex, rowData, rowId, isVisible }) => {
        return cloneElement(
          renderRow({ rowIndex, rowData, rowId, isVisible, summary }),
          {
            rowIndex,
            expandable: true,
            onExpand: onExpandCb,
            expanded: expandedRows[rowId],
            onChangeRowHeight: onChangeRowHeightCb,
            expandedContent: renderExpandedRowCb,
            recomputeRowHeight,
          }
        );
      },
      [
        summary,
        recomputeRowHeight,
        onChangeRowHeightCb,
        onExpandCb,
        renderExpandedRowCb,
        renderRow,
        expandedRows,
      ]
    );

    const renderExpandableHeaderRow = useMemo(() => {
      return (
        renderHeaderRow &&
        cloneElement(renderHeaderRow, {
          expandable: true,
          onExpandAll: onExpandAllCb,
          everyExpanded: everyToggled,
          scrollable: scrollable,
        })
      );
    }, [renderHeaderRow, onExpandAllCb, everyToggled, scrollable]);

    const renderExpandableSummaryRow = useMemo(() => {
      return (
        renderSummaryRow &&
        cloneElement(renderSummaryRow, {
          expandable: true,
        })
      );
    }, [renderSummaryRow]);

    return cloneElement(children, {
      ref: ref || innerRef,
      id,
      expandable: true,
      renderRow: renderExpandableRow,
      renderHeaderRow: renderExpandableHeaderRow,
      renderSummaryRow: renderExpandableSummaryRow,
      data,
      loading,
      summary,
      scrollable,
      ...props,
    });
  }
);

export default memo(ExpandableTable);
