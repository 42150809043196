import debounce from 'lodash/debounce';
import { useMemo } from 'react';

const useDebounced = (cb, delay, options) => {
  return useMemo(() => {
    return debounce(cb, delay, options);
  }, [cb, delay, options]);
};

export default useDebounced;
