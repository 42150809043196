import React, { useState } from 'react';
import { useUiComponentSchema } from '../../../providers/UiComponentSchemaProvider';
import Button from '../../../v2/components/Button/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSchema } from '../../../providers/SchemaProvider';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '../../../icons/controls/AddIcon';
import FieldLabel from '../FieldLabel';
import RemoveIcon from '../../../icons/controls/RemoveIcon';
import getLangLabel from '../../../utils/getLangLabel';
import LanguageIcon from '../../../icons/LanguageIcon';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '../../../icons/controls/CheckBoxIcon';
import CheckBoxActiveIcon from '../../../icons/controls/CheckBoxActiveIcon';
import CheckboxIndeterminateIcon from '../../../icons/controls/CheckboxIndeterminateIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grow from '@mui/material/Grow';

const useStyles = makeStyles(theme => ({
  enumArrayFieldRoot: {
    background: '#fff',
    borderRadius: 4,
    margin: 4,
    paddingBottom: 2,
  },
  enumArrayFieldHeader: {
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  enumArrayFieldControls: {
    padding: '0 16px',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    '&>*': {
      marginRight: 40,
    },
  },
  enumToggleButton: {
    padding: 1,
    marginBottom: 22,
  },
  enumAddIcon: {
    marginRight: 8,
  },
  enumRemoveButtonLabel: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.5px',
    textTransform: 'initial',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
    '&>span': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  enumRemoveButtonText: {
    padding: 0,
  },
  enumRemoveButtonRoot: {
    minWidth: 'initial',
  },
  enumRemoveIcon: {
    marginRight: 4,
    fontSize: 16,
  },
  enumArrayFields: {
    padding: '0 16px 16px 16px',
  },
  headerTitleContainer: {
    height: 24,
    margin: '22px 0 16px',
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
    },
  },
  copyPropertiesCheckboxRoot: {
    padding: 0,
    paddingRight: 9,
  },
  copyPropertiesFormControl: {
    marginBottom: 27,
    marginTop: 6,
    height: 17,
  },
  enumArrayLabel: {
    paddingBottom: 'initial',
  },
}));

const EnumArrayFieldTemplate = ({ formData, items, onAddClick }) => {
  const classes = useStyles();

  const [enumAnchorEl, setEnumAnchorEl] = useState(null);
  const [isCopyProperties, setIsCopyProperties] = useState(true);
  const schema = useSchema();
  const uiSchema = useUiComponentSchema();

  const enumPropertyKey = uiSchema['ui:label'];
  const enumPropertyUiSchema =
    enumPropertyKey && uiSchema.items[enumPropertyKey];

  const enumProperty = schema.items.properties[enumPropertyKey];

  const handleOpenEnum = event => {
    setEnumAnchorEl(event.currentTarget);
  };

  const handleCloseEnum = () => {
    setEnumAnchorEl(null);
  };

  // TODO restore list transitions

  return (
    <div>
      {items.map((element, index) => {
        const isFirst = index === 0;
        const isSingle = items.length === 1;

        const data = formData[element.index];
        const { onDropIndexClick } = element;

        const langLabel = getLangLabel(data && data[enumPropertyKey]);

        return (
          <Grow key={element.key} timeout={300}>
            <div className={classes.enumArrayFieldRoot}>
              <div className={classes.enumArrayFieldHeader}>
                <div className={classes.headerTitleContainer}>
                  <LanguageIcon name={data && data[enumPropertyKey]} />
                  <FieldLabel className={classes.enumArrayLabel}>
                    {langLabel} text
                  </FieldLabel>
                </div>

                {!isSingle && (
                  <Button
                    disableRipple
                    variant={'text'}
                    classes={{
                      root: classes.enumRemoveButtonRoot,
                      text: classes.enumRemoveButtonText,
                      label: classes.enumRemoveButtonLabel,
                    }}
                    onClick={e => {
                      onDropIndexClick(index)(e);
                    }}
                  >
                    <RemoveIcon className={classes.enumRemoveIcon} />
                    <span>Remove locale</span>
                  </Button>
                )}
              </div>
              <div className={classes.enumArrayFields}>{element.children}</div>
              {isFirst && (
                <div className={classes.enumArrayFieldControls}>
                  <Button
                    onClick={handleOpenEnum}
                    color={'primary'}
                    disableRipple
                    variant={'text'}
                    className={classes.enumToggleButton}
                  >
                    <AddIcon className={classes.enumAddIcon} />
                    {enumPropertyUiSchema['ui:title']}
                  </Button>

                  <FormControlLabel
                    className={classes.copyPropertiesFormControl}
                    control={
                      <Checkbox
                        classes={{
                          root: classes.copyPropertiesCheckboxRoot,
                        }}
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxActiveIcon />}
                        indeterminateIcon={<CheckboxIndeterminateIcon />}
                        checked={isCopyProperties}
                        onChange={event => {
                          setIsCopyProperties(event.target.checked);
                        }}
                        value="checkbox"
                        color="primary"
                      />
                    }
                    label={'Copy properties from this locale'}
                  />

                  <Menu
                    PaperProps={{
                      style: {
                        width: 135,
                      },
                    }}
                    anchorEl={enumAnchorEl}
                    keepMounted
                    open={Boolean(enumAnchorEl)}
                    onClose={handleCloseEnum}
                  >
                    {enumProperty.enum
                      .filter(
                        enumItem =>
                          !items.find(item => {
                            const data = formData[item.index];

                            return data && data[enumPropertyKey] === enumItem;
                          })
                      )
                      .map(item => {
                        return (
                          <MenuItem
                            onClick={e => {
                              onAddClick(e, {
                                [enumPropertyKey]: item,
                              });

                              handleCloseEnum();
                            }}
                          >
                            {getLangLabel(item)}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
              )}
            </div>
          </Grow>
        );
      })}
    </div>
  );
};

export default EnumArrayFieldTemplate;
