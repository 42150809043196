import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import getSourcesPreset from '../../../../../../gql/sportbook-config/templates/queries/getSourcesPreset';
import getSourcesPresetNames from '../../../../../../gql/sportbook-config/templates/queries/getSourcesPresetNames';
import TextField from '../../../../TextField';
import {
  ApplyPreset,
  MenuCaption,
  MenuTitle,
  SelectContainer,
  VirtualizedMenu,
} from '../../../components';
import { useSourcesTemplateSelect } from '../../../hooks/sources/select/useSourcesTemplateSelect';
import SourcesCategoryOption from '../Options/SourcesCategoryOption';

const SourcesCategorySelect = memo(
  ({
    templateId,
    nodePath = {},
    setPath = () => {},
    selectKey,
    nodeIndex,
    options,
  }) => {
    const selectedId = nodePath.categoryId;

    const { getValues } = useFormContext();

    const lineType = getValues('lineType');

    const {
      displayOptions,
      onSearchFieldChange,
      isSourcesWriter,
      isPresetsAvailable,
    } = useSourcesTemplateSelect({
      options,
      getFormValues: getValues,
    });

    return (
      nodePath.sportId && (
        <SelectContainer style={{ width: '350px' }}>
          <TextField
            style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
            label={`Search Category`}
            onChange={onSearchFieldChange}
            clearButton={true}
          />

          <MenuCaption>
            <MenuTitle name={'Category'} />
            {isPresetsAvailable && (
              <ApplyPreset
                templateType={'source'}
                selectKey={'category'}
                menuOptions={options}
                sportId={nodePath.sportId}
                idKey={'categoryId'}
                getPresetNamesQuery={getSourcesPresetNames}
                getPresetQuery={getSourcesPreset}
              />
            )}
          </MenuCaption>
          <VirtualizedMenu
            rowCount={displayOptions.length}
            itemRender={({ measure, index }) => {
              const option = displayOptions[index];

              return (
                <SourcesCategoryOption
                  key={selectKey + '-' + option.categoryId}
                  templateId={templateId}
                  nodePath={nodePath}
                  selectKey={selectKey}
                  setPath={setPath}
                  nodeIndex={nodeIndex}
                  selected={option.categoryId === selectedId}
                  itemValue={option}
                  lineType={lineType}
                  onMenuOpen={measure}
                  configurationDisabled={!isSourcesWriter}
                />
              );
            }}
          />
        </SelectContainer>
      )
    );
  }
);

export default SourcesCategorySelect;
