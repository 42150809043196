import { Box } from '@mui/material';
import { identity, pickBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import {
  DragDropList,
  ExpandableOrderedList,
  FancyOptionTitle,
  SelectItem,
} from '../../../components';
import { useSourcesTemplateOption } from '../../../hooks/sources/option/useSourcesTemplateOption';
import useSourcesStyles from '../../../styles/Sources.style';
import { changePath } from '../../../utils/changePath';

const SourcesTournamentOption = ({
  templateId,
  nodePath = [],
  nodeIndex,
  selected,
  onMenuOpen = () => {},
  itemValue,
  lineType,
  configurationDisabled,
}) => {
  const classes = useSourcesStyles();

  const { getValues, setValue } = useFormContext();
  const {
    setNodePath,
    setData,
    getSourcesTemplateQuery,
    setTreeSelectLoading,
    setParentSourcesCommands,
    parentSourcesCommands: {
      [lineType]: { category: parentSourcesCommands },
    },
  } = useTreeSelectContext();

  const nodeSelector = {
    sportId: nodePath.sportId,
    categoryId: nodePath.categoryId,
    tournamentId: itemValue.tournamentId,
  };

  const { showToast } = useToasts();
  const {
    sourcesList,
    toggledSourcesList,
    isSourcesListOpen,
    setIsSourcesListOpen,
    onStatusChange,
    onReorder,
  } = useSourcesTemplateOption({
    itemSources: itemValue.prioritySources,
    parentConfig: parentSourcesCommands,
    getFormStateValues: getValues,
    setFormStateValue: setValue,
    setParentSourcesCommands,
    selectedKey: 'tournament',
    selected,
    nodeSelector,
    isSourcesConfiguredInitially: itemValue.isConfigured,
  });

  //edit path(selector) for template query with current selected item
  //query new selects, and save them to context
  const onSelect = useCallback(() => {
    const prevNodePath = nodePath;
    let path = changePath({
      nodeId: itemValue.tournamentId,
      nodeKey: 'tournamentId',
      nodeIndex,
      nodePath,
      setNodePath,
      lineType,
    });
    getSourcesTemplateQuery({
      templateId,
      path: pickBy(path, identity),
      lineType,
    })
      .then(data => {
        const newData = withoutEmpty(data.data.data);
        setTreeSelectLoading(false);

        setData(currentData => {
          return {
            ...currentData,
            [lineType]: {
              ...currentData[lineType],
              marketsToNode: newData.marketsToNode,
              events: newData.events,
              markets: newData.markets,
            },
          };
        });
      })
      .catch(() => {
        setTreeSelectLoading(false);
        setNodePath(currentPath => ({
          ...currentPath,
          [lineType]: prevNodePath,
        }));
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can not fetch data
          </NotificationAlert>
        );
      });
  }, [
    templateId,
    itemValue,
    nodeIndex,
    nodePath,
    lineType,
    showToast,
    setNodePath,
    getSourcesTemplateQuery,
    setData,
    setTreeSelectLoading,
  ]);

  useEffect(() => {
    onMenuOpen && onMenuOpen();
  }, [isSourcesListOpen, onMenuOpen, sourcesList]);

  return (
    <Box>
      <SelectItem selected={selected}>
        <FancyOptionTitle
          countryIcon={false}
          name={itemValue.name}
          total={itemValue.total}
          onClick={onSelect}
          textClassName={classes.sourcesOptionName}
          tooltip
        >
          {itemValue.name}
        </FancyOptionTitle>

        <ExpandableOrderedList
          selected={selected}
          disabled={configurationDisabled}
          isMenuOpen={isSourcesListOpen}
          toggleMenu={setIsSourcesListOpen}
          items={toggledSourcesList.map(value => value.label)}
        />
      </SelectItem>
      {!configurationDisabled && (
        <DragDropList
          onDragEnd={onReorder}
          isMenuOpen={isSourcesListOpen}
          toggledItems={toggledSourcesList.map(value => value.key)}
          items={sourcesList}
          virtualized
          onToggleItem={onStatusChange}
        />
      )}
    </Box>
  );
};

export default SourcesTournamentOption;
