import React, { createContext, useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { UiOptionsContext } from './UiOptionsProvider';
import { StaticFieldListContext } from '../../providers/StaticFieldListProvider';
import { TableCellAlignEnum } from '../../constants/appConstants';
import usePaletteColor from '../../hooks/usePaletteColor';

const useStyles = makeStyles({
  fieldContainerStatic: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },

  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 36,
    position: 'relative',
  },

  fieldContainerExtended: {
    cursor: 'default',
    padding: '0px 4px',
    boxShadow: '6px 0px 6px -6px rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 4,
    background: '#fff',
    zIndex: 1,
  },
  fieldContainerAlignRight: {
    textAlign: 'right',
    flexDirection: 'row-reverse',
  },

  fieldContainerAlignCenter: {
    textAlign: 'center',
    justifyContent: 'center',
  },

  fieldCellBackground: {
    paddingLeft: 12,
    paddingRight: 12,
  },
});

export const FieldContainerExtendedContext = createContext(false);

const FieldContainer = ({ align, children, className, onClick }) => {
  const [containerExtended, setContainerExtended] = useState(false);
  const { value, decorators } = useContext(UiOptionsContext) || {};
  const [width, setWidth] = useState(value.width || 'initial');

  const classes = useStyles();

  const style = {};

  if (decorators && decorators.verticalStrip) {
    style.left = 8;
  }

  const backgroundColor = usePaletteColor(
    (decorators.cellBackground && decorators.cellBackground.color) ||
      'transparent'
  );

  if (decorators.cellBackground) {
    style.backgroundColor = backgroundColor;
  }

  const staticFieldList = useContext(StaticFieldListContext);

  if (staticFieldList) {
    return <div className={classes.fieldContainer}>{children}</div>;
  }

  return (
    <div
      onClick={onClick}
      onMouseLeave={() => {
        setWidth(value.width);
        setContainerExtended(false);
      }}
      className={clsx(
        classes.fieldContainer,
        {
          [classes.fieldContainerExtended]: value.width && containerExtended,
          [classes.fieldContainerAlignRight]:
            align === TableCellAlignEnum.RIGHT,
          [classes.fieldContainerAlignCenter]:
            align === TableCellAlignEnum.CENTER,
          [classes.fieldCellBackground]: decorators.cellBackground,
        },
        className
      )}
      style={style}
    >
      <FieldContainerExtendedContext.Provider
        value={{
          containerExtended,
          setContainerExtended,
          width,
          setWidth,
        }}
      >
        {children}
      </FieldContainerExtendedContext.Provider>
    </div>
  );
};

export default FieldContainer;
