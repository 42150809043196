import React, { useContext, useEffect, useMemo } from 'react';
import Table from './Table';
import DataProvider from '../../providers/DataProvider';
import { RoutesContext } from '../../providers/RoutesProvider';
import { usePresets } from '../../providers/PresetsProvider';
import TableProvider from '../../providers/TableProvider';
import useQueryByPath from '../../hooks/useQueryByPath';
import usePrevious from '../../hooks/usePrevious';
import isEmpty from 'lodash/isEmpty';
import TableApplyProvider from '../../providers/TableApplyProvider';

const useFiltersQuery = path => {
  const { activePreset, activePresetId, presets, loading } = usePresets();

  const offset = 0;
  const limit = (activePreset && activePreset.data.rows_per_page) || undefined;

  const filters = useMemo(() => {
    if (loading || !activePreset) {
      return null;
    }

    return {
      ...activePreset.data.filters,
      offset,
      limit,
    };
  }, [activePreset, loading, offset, limit]);

  const { query, setQuery } = useQueryByPath(path);
  const previousActivePresetId = usePrevious(activePresetId);

  useEffect(() => {
    if (
      filters &&
      (!query ||
        isEmpty(query) ||
        (typeof previousActivePresetId !== 'undefined' &&
          activePresetId !== previousActivePresetId) ||
        (activePresetId !== previousActivePresetId && activePresetId !== '0'))
    ) {
      setQuery({
        ...filters,
        limit: (filters && filters.limit) || (query && query.limit),
      });
    }
  }, [activePresetId, previousActivePresetId, query, filters, setQuery]);

  return useMemo(
    () => [filters && query, setQuery, filters && filters.limit],
    [query, setQuery, filters]
  );
};

const DataTable = ({ path, filtersPath, params, ...props }) => {
  const routes = useContext(RoutesContext);

  const [query, setQuery, limit] = useFiltersQuery(filtersPath || path);

  if (!query || isEmpty(query)) {
    return null;
  }

  return (
    <TableProvider>
      <DataProvider
        path={path}
        params={params}
        routes={routes}
        {...props}
        defaultLimit={limit}
      >
        <TableApplyProvider>
          <Table />
        </TableApplyProvider>
      </DataProvider>
    </TableProvider>
  );
};

export default DataTable;
