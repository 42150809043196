import TextField from '@mui/material/TextField';
import React, { forwardRef, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  pickerInput: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    fontWeight: 'normal',
  },
  inputRoot: {
    // maxWidth: 190,
  },
});

const PickerInput = forwardRef(
  (
    {
      className,
      id,
      onOpen,
      onFocus,
      onClick,
      onChangeInput,
      onBlurInput,
      displayValue,
      InputProps,
      inputProps,
      fullWidth,
      error,
      helperText,
      label,
      disabled,
      required,
    },
    ref
  ) => {
    const classes = useStyles();

    const [overrideInput, setOverrideInput] = useState(displayValue);

    const handleChange = e => {
      const {
        target: { value },
      } = e;

      if (onChangeInput) onChangeInput(value);
      setOverrideInput(value);
    };

    useEffect(() => {
      setOverrideInput(displayValue);
    }, [displayValue]);

    return (
      <TextField
        id={id}
        ref={ref}
        margin="dense"
        value={overrideInput}
        variant="outlined"
        onClick={onClick}
        onFocus={onFocus}
        onChange={handleChange}
        onBlur={() => {
          overrideInput !== displayValue &&
            onBlurInput &&
            onBlurInput(overrideInput);
        }}
        inputProps={inputProps}
        required={required}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
        }}
        InputProps={{
          ...InputProps,
          classes: {
            ...InputProps.classes,
            root: clsx(classes.inputRoot, InputProps?.classes?.inputRoot),
            input: clsx(classes.pickerInput, InputProps?.classes?.input),
          },
        }}
        className={className}
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        label={label}
        disabled={disabled}
      />
    );
  }
);

export default PickerInput;
