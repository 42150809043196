import { useCallback, useEffect, useMemo } from 'react';
import { useRootIdSchema } from '../containers/Form/templates/ObjectFieldTemplate';
import { useParams } from 'react-router-dom';
import { useRoutes } from './RoutesProvider';
import {
  getMethodAndUrl,
  getParameters,
  getPathByOperationId,
} from '../utils/schemaHelper';
import { memoizedDebouncedGetRouteData } from '../actions/dataActions';
import { useRawSchema } from './RawSchemaProvider';
import { getData, getDataLoading, getError } from '../reducers/data';
import { useDispatch, useSelector } from 'react-redux';
import { isSelect, optionsList } from 'react-jsonschema-form/lib/utils';

export const useEnumSelectData = schema => {
  return useMemo(() => {
    return (
      (isSelect(schema) && optionsList(schema)) ||
      (schema.items && isSelect(schema.items) && optionsList(schema.items)) ||
      []
    );
  }, [schema]);
};

export const useDynamicSelectData = ({
  id = '',
  params,
  operationId,
  requestBody = {},
}) => {
  const rootIdSchema = useRootIdSchema();
  const matchParams = useParams();
  const routes = useRoutes();
  const schemaState = useRawSchema();

  const query = getParameters({
    params,
    requestPath: matchParams,
    requestBody,
    requestQuery: requestBody,
    id,
    idSchema: rootIdSchema,
  });

  const path = getPathByOperationId(routes, operationId);

  const isEmptyQuery = Object.values(query).some(paramValue =>
    Array.isArray(paramValue) ? !paramValue.length : !paramValue
  );

  const { method, url } = path
    ? getMethodAndUrl({
        path,
        params: matchParams,
        query,
      })
    : {};

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    return dispatch(
      memoizedDebouncedGetRouteData(schemaState, {
        method,
        url,
        path,
      })
    );
  }, [method, url, path, dispatch, schemaState]);

  useEffect(() => {
    if (!isEmptyQuery) {
      fetchData();
    }
  }, [fetchData, isEmptyQuery]);

  const data = useSelector(state =>
    getData(state, routes, method + url, null, path)
  );
  const error = useSelector(state =>
    getError(state, routes, method + url, path)
  );
  const loading = useSelector(state =>
    getDataLoading(state, routes, method + url, null, path)
  );

  const items = useMemo(() => data && data.items, [data]);

  return useMemo(() => {
    return {
      items,
      loading,
      error,
      isEmptyQuery,
    };
  }, [error, isEmptyQuery, items, loading]);
};
