import SvgIcon from '@mui/material/SvgIcon';
import React, { forwardRef } from 'react';

const ExpandIconUp = forwardRef((props, ref) => {
  return (
    <SvgIcon viewBox={'0 0 16 16'} {...props} ref={ref} fontSize={'inherit'}>
      <path
        d="M7.2458 4.84708C7.64366 4.38431 8.35634 4.38431 8.7542 4.84708L11.7546 8.33703C12.3141 8.98793 11.8551 10 11.0004 10L4.99964 10C4.14485 10 3.68586 8.98793 4.24545 8.33702L7.2458 4.84708Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

export default ExpandIconUp;
