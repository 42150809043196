import { useContext } from 'react';
import { DataContext } from '../providers/DataProvider';
import { useTableApplyContext } from '../providers/TableApplyProvider';
import { useRouteCheck } from './useRouteCheck';
import { useTableApplyRequestUrl } from './useTableApplyRequestUrl';

export const useTableApplyButton = () => {
  const { fetchData } = useContext(DataContext);
  const { tableChanges, isApplyAvailable, setTableChanges } =
    useTableApplyContext();
  const { pathname, applyRequest } = useTableApplyRequestUrl();

  const { isRouteProvided } = useRouteCheck();

  const isApplyVisible = isRouteProvided && isApplyAvailable;

  const onApplyClick = async () => {
    if (pathname && isApplyVisible) {
      applyRequest({
        url: pathname,
        data: { items: Object.values(tableChanges?.payload || {}) },
      }).then(() => {
        setTableChanges({ data: {}, payload: {} });
        fetchData();
      });
    }
  };

  return {
    isApplyVisible,
    onApplyClick,
  };
};
