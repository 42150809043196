import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Fragment, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CoverageTreeSelect from '../../../../../components/Inputs/TreeSelect/TreeSelectTypes/Coverage/CoverageTreeSelect';
import MarginTreeSelect from '../../../../../components/Inputs/TreeSelect/TreeSelectTypes/Margin/MarginTreeSelect';
import SourcesTreeSelect from '../../../../../components/Inputs/TreeSelect/TreeSelectTypes/Sources/SourcesTreeSelect';
import TabLabel from '../../../../../components/Tabs/TabLabel';
import Tabs from '../../../../../components/Tabs/Tabs';
import { TEMPLATE_TYPE } from './TemplateForm';

export const TEMPLATE_CONFIG_NODES = {
  SPORT: 'sports',
  CATEGORY: 'categories',
  TOURNAMENT: 'tournaments',
  EVENT: 'events',
  MARKET: 'markets',
};

export const SELECT_NODE_TYPES = {
  SPORTS: 'sport',
  CATEGORIES: 'category',
  TOURNAMENTS: 'tournament',
  EVENTS: 'event',
  MARKETS: 'market',
};

export const LINE_TYPES = {
  live: 'live',
  prematch: 'prematch',
  all: 'all',
};

const LivePrematchTabContent = styled(Box)`
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  padding: 20px 10px 0 10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateSettingsContainer = styled(Box)`
  background-color: white;
  border-radius: 4px;
  height: 100%;
  padding: 20px 10px 0 10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateConfigurationField = ({
  templateId,
  isSameLivePrematch = false,
  templateType,
}) => {
  const { setValue, getValues } = useFormContext();
  const [currentLineType] = getValues(['lineType']);

  const TreeSelect = useMemo(() => {
    switch (templateType) {
      case TEMPLATE_TYPE.SOURCES:
        return SourcesTreeSelect;
      case TEMPLATE_TYPE.COVERAGE:
        return CoverageTreeSelect;
      case TEMPLATE_TYPE.MARGIN:
        return MarginTreeSelect;
      default:
        return SourcesTreeSelect;
    }
  }, [templateType]);

  const tabs = [
    {
      key: LINE_TYPES.prematch,
      label: <TabLabel>Prematch</TabLabel>,
      content: (
        <LivePrematchTabContent key={LINE_TYPES.prematch}>
          <TreeSelect templateId={templateId} lineType={LINE_TYPES.prematch} />
        </LivePrematchTabContent>
      ),
    },
    {
      key: LINE_TYPES.live,
      label: <TabLabel>Live</TabLabel>,
      content: (
        <LivePrematchTabContent key={LINE_TYPES.live}>
          <TreeSelect templateId={templateId} lineType={LINE_TYPES.live} />
        </LivePrematchTabContent>
      ),
    },
  ];

  const onChangeTab = useCallback(
    tabKey => {
      setValue('lineType', tabKey);
      setValue('configLineType', tabKey);
    },
    [setValue]
  );

  return (
    <Fragment>
      {isSameLivePrematch ? (
        <TemplateSettingsContainer>
          <TreeSelect templateId={templateId} lineType={currentLineType} />
        </TemplateSettingsContainer>
      ) : (
        <Tabs
          activeTabKey={LINE_TYPES.prematch}
          fullWidthTabs
          withoutSpacing
          onChangeTab={onChangeTab}
          tabs={tabs}
          style={{ height: '100%' }}
        />
      )}
    </Fragment>
  );
};

export default TemplateConfigurationField;
