import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const SelectItem = ({ selected = false, fillWidth = false, children }) => {
  const classes = useTreeSelectStyles();

  return (
    <>
      <Box
        className={clsx(classes.treeSelectMenuItem, {
          [classes.treeSelectItemSelected]: selected,
          [classes.treeSelectItemFullWidth]: fillWidth,
        })}
      >
        {children}
      </Box>
    </>
  );
};

export default SelectItem;
