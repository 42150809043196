import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const SearchIcon = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <circle
        cx="4.875"
        cy="4.875"
        r="4.125"
        stroke="#8496AB"
        strokeWidth="1.5"
        fill="transparent"
      />
      <path
        d="M11.25 11.25L8.25 8.25"
        stroke="#8496AB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
