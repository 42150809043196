import { Box } from '@mui/material';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import CopyField from '../../../../../Fields/CopyField/CopyField';
import StringField from '../../../../../Fields/StringField/StringField';
import Tooltip from '../../../../../Tooltip/Tooltip';
import ItemMoveButton from '../../../components/ItemMoveButton';
import useCoverageStyles from '../../../styles/Coverage.style';

const CoverageMainMarketsOption = ({
  nodePath = {},
  selected,
  itemValue,
  lineType,
  dragDropDisabled,
  provided,
}) => {
  const classes = useCoverageStyles();

  const { setNodePath } = useTreeSelectContext();

  const onSelect = useCallback(() => {
    if (!selected) {
      let path = structuredClone(nodePath);
      path.market.marketId = itemValue.marketId;
      setNodePath(currentPath => {
        return {
          ...currentPath,
          [lineType]: path,
        };
      });
    }
  }, [itemValue, nodePath, selected, lineType, setNodePath]);

  const dragHandleProps = !dragDropDisabled && { ...provided.dragHandleProps };

  return (
    <Box className={classes.coverageMainMarketContainer}>
      <ItemMoveButton
        dragHandleProps={dragHandleProps}
        selected={selected}
        dragDropDisabled={dragDropDisabled}
      />

      <Box className={classes.coverageMarketOptionContent}>
        <Tooltip title={itemValue.name}>
          <Box className={classes.coverageMarketName}>
            <CopyField
              value={itemValue.marketId}
              style={{ width: 'fit-content' }}
            />
            <StringField
              className={clsx(
                classes.coverageOptionCaption,
                classes.coverageMainOptionCaption
              )}
              onClick={onSelect}
            >
              {itemValue.name}
            </StringField>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CoverageMainMarketsOption;
