import React, { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

export const getSportIconNameById = id => {
  if (SportIcons[id]) return `sport-${SportIcons[id]}`;

  return `sport-default-placeholder`;
};

const useStyles = makeStyles({
  sportIconContainer: {
    height: '18px',
    width: '18px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '18px',
    backgroundPosition: 'center',
  },
  whiteIcon: {
    filter: 'brightness(0) invert(1)',
  },
  blackIcon: {
    filter: 'brightness(0)',
  },
});

const SportIcon = forwardRef(
  ({ name: id, className, white = false, black = false, ...props }, ref) => {
    const classes = useStyles();
    const name = getSportIconNameById(id);

    return (
      <div
        className={clsx(
          className,
          white && classes.whiteIcon,
          black && classes.blackIcon,
          classes.sportIconContainer
        )}
        ref={ref}
        style={{
          backgroundImage: `url("/sports/${name}.svg")`,
        }}
        {...props}
      />
    );
  }
);

export default SportIcon;

export const ROWING_SPORT_ID = '64';
export const ARCHERY_SPORT_ID = '75';
export const EQUESTRIAN_SPORT_ID = '76';
export const FENCING_SPORT_ID = '77';
export const TRIATHLON_SPORT_ID = '84';
export const WEIGHT_LIFTING_SPORT_ID = '85';
export const SPEEDWAY_SPORT_ID = '131';
export const KING_OF_GLORY_SPORT_ID = '134';

export const VIRTUAL_SPORT_ID = '299';
export const TVBET_SPORT_ID = '298';
export const FIFA18_SPORT_ID = '300';
export const ROCKET_LEAGUE_SPORT_ID = '301';
export const NBA2K19_SPORT_ID = '302';
export const AOTENNIS2_SPORT_ID = '303';
export const E_FIGHTING_SPORT_ID = '304';
export const E_CRICKET_SPORT_ID = '305';
export const KABADDI_SPORT_ID = '138';

export const SportIcons = {
  1: 'soccer',
  2: 'basketball',
  3: 'baseball',
  4: 'icehockey',
  5: 'tennis',
  6: 'handball',
  7: 'floorball',
  8: 'trotting',
  9: 'golf',
  10: 'boxing',
  11: 'rally',
  12: 'rugby',
  13: 'aussierules',
  14: 'wintersports',
  15: 'bandy',
  16: 'americanfootball',
  17: 'cycling',
  18: 'specials',
  19: 'snooker',
  20: 'tabletennis',
  21: 'cricket',
  22: 'darts',
  23: 'volleyball',
  24: 'fieldhockey',
  26: 'waterpolo',
  28: 'curling',
  29: 'futsal',
  30: 'olympics',
  31: 'badminton',
  32: 'bowls',
  33: 'chess',
  34: 'beachvolleyball',
  35: 'netball',
  36: 'athletics',
  37: 'squash',
  38: 'rinkhockey',
  39: 'lacrosse',
  40: 'formula-1',
  41: 'bikes',
  42: 'dtm',
  43: 'alpineskiing',
  44: 'biathlon',
  45: 'bobsleigh',
  46: 'skirace',
  47: 'nordiccombined',
  48: 'skijumping',
  49: 'snowboard',
  50: 'speedskating',
  51: 'luge',
  52: 'swimming',
  54: 'softball',
  55: 'horseracing',
  58: 'greyhound',
  59: 'rugbyleague',
  60: 'beachsoccer',
  61: 'pesapallo',
  70: 'nascar',
  81: 'sailing',
  90: 'surfing',
  92: 'canoeslalom',
  96: 'diving',
  98: 'beachtennis',
  102: 'figureskating',
  103: 'freestyleskiing',
  104: 'skeleton',
  105: 'shorttrack',
  106: 'mysticalsoccer',
  107: 'esport',
  109: 'counterstrike',
  110: 'leagueoflegends',
  111: 'dota2',
  112: 'starcraft',
  113: 'hearthstone',
  115: 'wot',
  117: 'mma',
  118: 'callofduty',
  121: 'overwatch',
  125: 'rainbow_six',
  127: 'street_fighter_v',
  128: 'rocket-league-br',
  129: 'indycar',
  135: 'gaelicfootball',
  136: 'gaelichurling',
  137: 'esport_fifa',
  140: 'esport_pubg',
  142: 'formula-e',
  152: 'wow',
  153: 'nba2k',
  155: '3x3_basketball',
  156: 'tekken',
  190: 'bikes',
  191: 'stockcarracing',
  [AOTENNIS2_SPORT_ID]: 'aotennis2',
  [FIFA18_SPORT_ID]: 'fifa18',
  [ROCKET_LEAGUE_SPORT_ID]: 'rocket-league',
  [NBA2K19_SPORT_ID]: 'nba2k19',
  [TVBET_SPORT_ID]: 'tvbet',
  [VIRTUAL_SPORT_ID]: 'virtualsport',
  [E_FIGHTING_SPORT_ID]: 'e-fighting',
  [E_CRICKET_SPORT_ID]: 'e-cricket',
  [ROWING_SPORT_ID]: 'rowing',
  [ARCHERY_SPORT_ID]: 'archery',
  [EQUESTRIAN_SPORT_ID]: 'equestrian',
  [FENCING_SPORT_ID]: 'fencing',
  [TRIATHLON_SPORT_ID]: 'triathlon',
  [WEIGHT_LIFTING_SPORT_ID]: 'weight-lifting',
  [SPEEDWAY_SPORT_ID]: 'speedway',
  [KING_OF_GLORY_SPORT_ID]: 'king-of-glory',
  [KABADDI_SPORT_ID]: 'kabaddi',
};
