import React, { useState } from 'react';
import { InputAdornment, Stack, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import { string, object } from 'yup';

import isEmail from 'validator/lib/isEmail';

import emailIcon from '../../../assets/email.svg';
import { LoadingButton } from '@mui/lab';

const ForgotPasswordForm = ({ onSubmit, onBack, loading }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: object({
      email: string().test({
        name: 'email',
        message: 'Enter a valid email address',
        test: (value = '') => isEmail(value),
      }),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Stack gap={'20px'} width={'100%'}>
        <TextField
          onChange={formik.handleChange}
          name={'email'}
          value={formik.values.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={emailIcon} />
              </InputAdornment>
            ),
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          placeholder={'Email'}
          sx={{
            '& .MuiInputBase-root': { paddingRight: '14px' },
            '& .MuiInputAdornment-root': {
              width: '30px',
              '&.MuiInputAdornment-positionStart': {
                color: '#1481ff',
              },
            },
          }}
        />
        <LoadingButton variant={'contained'} type={'submit'}>
          Get new password
        </LoadingButton>
        <Button onClick={onBack} variant={'text'}>
          Back to login
        </Button>
      </Stack>
    </form>
  );
};

export default ForgotPasswordForm;
