import { usePresets } from '../providers/PresetsProvider';
import { useCallback, useMemo } from 'react';

const useTablePresetColumnToggle = initialToggledColumns => {
  const { activePreset, updatePreset } = usePresets();

  const toggledColumns = useMemo(() => {
    if (!activePreset || !activePreset.data.toggled_columns.length) {
      return initialToggledColumns;
    }

    return activePreset.data.toggled_columns;
  }, [initialToggledColumns, activePreset]);

  const onToggleColumns = useCallback(
    toggledColumns => {
      updatePreset(activePreset.id, {
        ...activePreset,
        data: {
          ...activePreset.data,
          toggled_columns: toggledColumns,
        },
      });
    },
    [updatePreset, activePreset]
  );

  return useMemo(
    () => [toggledColumns, onToggleColumns],
    [toggledColumns, onToggleColumns]
  );
};

export default useTablePresetColumnToggle;
