import { makeStyles } from '@mui/styles';

export const useCoverageStyles = makeStyles({
  coverageEventContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '10px 0 10px 12px',
    cursor: 'pointer',
  },
  coverageEventSection: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '400px',
  },
  coverageOptionCaption: {
    fontSize: '14px',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  coverageMarketNameContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  coverageMarketName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '245px',
  },
  coverageMarketOptionContent: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cashoutSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  coverageMainMarketContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  coverageMainOptionCaption: {
    padding: '10px 0 10px 6px',
    maxWidth: '350px',
  },
  coverageTournamentOptionName: {
    maxWidth: '200px',
  },
});

export default useCoverageStyles;
