import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

class Page404 extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.notFoundBlock}>
          <h1 className={classes.title}>Page not found</h1>
          <div>
            Try to visit <Link to="/">Main Page</Link>
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  notFoundBlock: {
    width: '100%',
    textAlign: 'center',
    ...theme.typography.body2,
  },
  title: {
    ...theme.typography.h4,
  },
});

export default withStyles(styles)(Page404);
