import FormGroup from '../../../../components/Form/FormGroup';
import Grid from '@mui/material/Grid/Grid';
import FormGroupLabel from '../../../../components/Form/FormGroupLabel';
import FormField from '../../../../components/Form/FormField';
import TextField from '../../../../components/Inputs/TextField';
import Checkbox from '../../../../components/Inputs/Checkbox';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { PLACEHOLDERS_ENUM } from '../BannerForm/BannerValidationSchema';
import isNil from 'lodash/isNil';

const BannerLinkField = ({ name }) => {
  const { watch, trigger, setValue } = useFormContext();
  const customActionFieldName = `${name}.custom_action`;
  const savedCAValueRef = useRef(null);
  const customAction = watch(customActionFieldName);
  const url = watch(`${name}.url`);
  const bannerType = watch('banner_type');
  const placeholders = watch(`${bannerType}.placeholders`);

  const isCustomActionForced = useMemo(() => {
    const placeholdersToCheck = [
      PLACEHOLDERS_ENUM.OPERATOR_PAGE,
      PLACEHOLDERS_ENUM.OPERATOR_PAGE1,
      PLACEHOLDERS_ENUM.OPERATOR_PAGE2,
      PLACEHOLDERS_ENUM.OPERATOR_PAGE3,
      PLACEHOLDERS_ENUM.OPERATOR_PAGE4,
      PLACEHOLDERS_ENUM.OPERATOR_PAGE5,
    ];
    const isAbsoluteUrl = /^((http|https):\/\/)/.test(url);

    return (
      !isAbsoluteUrl && placeholders.some(p => placeholdersToCheck.includes(p))
    );
  }, [url, placeholders]);

  useEffect(() => {
    trigger(`${name}.url`);
  }, [customAction, name, trigger]);

  useEffect(() => {
    if (isCustomActionForced) {
      if (isNil(savedCAValueRef.current)) {
        savedCAValueRef.current = customAction;
      }
      setValue(customActionFieldName, true);
    } else if (!isNil(savedCAValueRef.current)) {
      setValue(customActionFieldName, savedCAValueRef.current);
      savedCAValueRef.current = null;
    }
  }, [customAction, customActionFieldName, isCustomActionForced, setValue]);

  return (
    <Grid item xs={12}>
      <FormGroup large>
        <Grid container spacing={2} direction={'column'}>
          <Grid item xs={12}>
            <FormGroupLabel
              helpText={
                'Insert the link where the user will be redirected after clicking the banner. If you want to use your own method for links on your side (for example: count clicks on banners), then please select the checkbox ‘Custom action’'
              }
            >
              Banner Link
            </FormGroupLabel>
          </Grid>

          <Grid item xs={12}>
            <FormField name={`${name}.url`}>
              <TextField label={'Add Banner Link'} copy />
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <FormField name={customActionFieldName}>
              <Checkbox
                label={'Custom action'}
                disabled={isCustomActionForced}
              />
            </FormField>
          </Grid>
        </Grid>
      </FormGroup>
    </Grid>
  );
};

export default BannerLinkField;
