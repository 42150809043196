import OrderListItem from './OrderListItem';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles(theme => ({
  listTableRoot: {
    position: 'relative',
    background: '#F7F7F7',
    borderRadius: 6,
    padding: 8,
    display: 'flex',
  },
  tableDroppable: {
    display: 'grid',
    width: '100%',
  },
  dropzonePlaceholder: {
    position: 'absolute',
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
    borderRadius: 4,
  },
  listNumbers: {
    display: 'grid',
  },
  listNumberRoot: {
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.2px',
    padding: '0 16px 0 6px',
    width: 29,
  },
  autocomplete: {
    maxWidth: 400,
  },
}));

const IsDraggingContext = React.createContext(false);

const DraggableList = ({
  items,
  getId,
  renderActions,
  schema,
  uiSchema = {},
}) => {
  const classes = useStyles();

  const [isDragging, setIsDragging] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState(null);

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = result => {
    setIsDragging(false);

    if (!result.destination) {
      return;
    }

    const item = items[result.destination.index];

    if (!item) {
      return;
    }

    item.onReorder(result.source.index, result.destination.index);

    setPlaceholderProps(null);
  };

  const onDragStart = update => {
    const sourceIndex = update.source.index;
    const draggableId = update.draggableId;

    setPlaceholderProps(getPlaceholderProps(sourceIndex, draggableId));
  };

  const onDragUpdate = update => {
    if (!update.destination) {
      setPlaceholderProps(null);
      return;
    }

    const destinationIndex = update.destination.index;

    const draggableId = update.draggableId;

    setPlaceholderProps(getPlaceholderProps(destinationIndex, draggableId));
  };

  const getPlaceholderProps = (index, draggableId) => {
    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery).parentElement;

    if (!draggedDOM) {
      setPlaceholderProps(null);
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, index).reduce(total => {
        return total + 38 + 4;
      }, 8);

    return {
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    };
  };

  return (
    <IsDraggingContext.Provider value={isDragging}>
      <DragDropContext
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
      >
        {items.length ? (
          <div className={classes.listTableRoot}>
            <div className={classes.listNumbers}>
              {items.map((item, index) => (
                <div className={classes.listNumberRoot} key={index}>
                  {index + 1}
                </div>
              ))}
            </div>
            <Droppable droppableId="list">
              {droppableProvided => (
                <div
                  className={classes.tableDroppable}
                  ref={ref => {
                    // this.tableRef = ref;
                    droppableProvided.innerRef(ref);
                  }}
                  {...droppableProvided.droppableProps}
                >
                  {items.map((item, index) => {
                    const data = item.children.props.formData;
                    const draggableId = getId(item);

                    return (
                      <Draggable
                        draggableId={draggableId}
                        index={index}
                        key={draggableId}
                      >
                        {(provided, snapshot) => (
                          <OrderListItem
                            schema={schema}
                            uiSchema={uiSchema}
                            data={data}
                            provided={provided}
                            snapshot={snapshot}
                            actions={renderActions(item)}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                  {placeholderProps && (
                    <div
                      className={classes.dropzonePlaceholder}
                      style={{
                        top: placeholderProps.clientY,
                        left: placeholderProps.clientX + 37,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </div>
        ) : null}
      </DragDropContext>
    </IsDraggingContext.Provider>
  );
};

export default DraggableList;
