const сhapters = {};

class Chapter {
  constructor(name) {
    this.name = name;
    this.stories = [];
  }

  addStory(name, handler) {
    this.stories.push({ name, handler });
    return this;
  }

  getStory(name) {
    return this.stories.find(s => s.name === name);
  }

  getStories() {
    return this.stories;
  }
}

const getChapters = () => {
  let chaptersArr = [];
  for (let c in сhapters) {
    chaptersArr.push(сhapters[c]);
  }

  return chaptersArr;
};

export const addChapter = name => {
  const chapter = new Chapter(name);
  сhapters[name] = chapter;

  return chapter;
};

export const getStory = (chapter, story) => {
  return сhapters[chapter].getStory(story);
};

const store = {
  getChapters,
  getStory,
  addChapter,
};

export default store;
