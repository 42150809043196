import { gql } from '@apollo/client';

const BETSTREAM_WATCH_SUBSCRIPTION = gql`
  subscription ($userId: String) {
    WatchModel(user_id: $userId) {
      watch
      bet_id
      user_id
    }
  }
`;

export default BETSTREAM_WATCH_SUBSCRIPTION;
