import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import PresetForm from '../uiComponents/Filters/PresetForm';
import TransitionScaleFade from '../components/TransitionScaleFade';
import { usePresets } from './PresetsProvider';

export const CreatePresetDialogContext = createContext({});

export const useCreatePresetDialog = () => {
  return useContext(CreatePresetDialogContext);
};

const CreatePresetDialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [presetFilters, setPresetFilters] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const { fetchPresets, createPreset, activePreset } = usePresets();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSave = useCallback(
    preset => {
      createPreset(preset)
        .then(() => {
          setIsOpen(false);
          setErrorMessage(null);
          return fetchPresets();
        })
        .catch(e => setErrorMessage(e.message));
    },
    [fetchPresets, createPreset, setIsOpen, setErrorMessage]
  );

  const open = useCallback(filters => {
    setPresetFilters(filters);
    setIsOpen(true);
  }, []);

  const providerValue = useMemo(() => {
    return {
      open: open,
      close: onClose,
    };
  }, [open, onClose]);

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage(null);
    }
  }, [isOpen, setErrorMessage]);

  return (
    <CreatePresetDialogContext.Provider value={providerValue}>
      <Dialog
        TransitionComponent={TransitionScaleFade}
        onClose={onClose}
        aria-labelledby="notification-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="notification-dialog-title">
          Saving page as a preset
        </DialogTitle>
        <PresetForm
          onSave={onSave}
          onCancel={onClose}
          presetData={{
            data: {
              ...(activePreset && activePreset.data),
              filters: presetFilters,
              initial_filters: presetFilters,
            },
          }}
          errorMessage={errorMessage}
        />
      </Dialog>

      {children}
    </CreatePresetDialogContext.Provider>
  );
};

export default CreatePresetDialogProvider;
