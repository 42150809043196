import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  lang: {
    letterSpacing: '0.5px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
  country: {
    fontWeight: 400,
  },
});

const LocaleTabLabel = ({ label, country, lang }) => {
  const classes = useStyles();

  return (
    <span className={classes.lang}>
      {label ? (
        label
      ) : (
        <>
          <span>{lang}</span> <span className={classes.country}>{country}</span>
        </>
      )}
    </span>
  );
};

export default LocaleTabLabel;
