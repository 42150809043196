import React from 'react';
import TransferListItem from './TransferListItem';
import makeStyles from '@mui/styles/makeStyles';
import TransferListSkeletonGroupItem from './TransferListSkeletonGroupItem';

const useStyles = makeStyles({
  list: {},
  placeholderList: {
    position: 'absolute',
    width: 'calc(100% - 16px)',
  },
});

const TransferListItems = ({
  loading,
  setToggled,
  valueKey,
  labelKey,
  descKey,
  searchQuery,
  filteredItems,
  items,
  isEmpty,
  emptyLabel,
}) => {
  const classes = useStyles();

  const displayItems = searchQuery ? filteredItems : items;

  return loading ? (
    <div className={classes.placeholderList}>
      {[1, 2, 3, 5, 6, 7, 8, 9, 10].map(item => {
        return <TransferListSkeletonGroupItem key={item} />;
      })}
    </div>
  ) : (
    <div className={classes.list}>
      {isEmpty
        ? emptyLabel
        : displayItems.map(item => (
            <TransferListItem
              searchQuery={searchQuery}
              key={item[valueKey]}
              description={item[descKey]}
              title={item[labelKey]}
              toggled={item.toggled}
              setToggled={setToggled}
              value={item[valueKey]}
            />
          ))}
    </div>
  );
};

export default TransferListItems;
