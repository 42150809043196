import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import TextField from '../../../../components/Inputs/TextField';
import SportIcon from '../../../../icons/SportIcon/SportIcon';
import ArrowRightSmall from '../../../../../icons/controls/ArrowRightSmall';
import Highlighted from '../../../../components/Highlighted/Highlighed';
import CATEGORIES_FILTER_AUTOCOMPLETE_QUERY from '../../../../gql/TradingTool/queries/CATEGORIES_FILTER_AUTOCOMPLETE_QUERY';
import TradingToolSidebarCategory from './TradingToolSidebarCategory';
import sortByEventsCount from '../../utils/sortByEventsCount';

const useStyles = makeStyles({
  tradingToolSidebarSport: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 6,
  },
  sportLine: {
    padding: '12px 0 12px 12px',
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowRight: {
    color: '#1581FF',
    width: 12,
    height: 12,
    transition: 'transform 0.1s linear',
  },
  arrowRightOpened: {
    transform: 'rotate(90deg)',
  },
  sportIcon: {
    marginLeft: 4,
    backgroundSize: 14,
  },
  sportName: {
    marginLeft: 4,
    marginRight: 4,
    fontWeight: 600,
  },
  selectedLine: {
    backgroundColor: '#E8F2FF',
    transition: 'backgroundColor 0.1s linear',
    borderRadius: 4,
  },
  categorySearchField: {
    marginTop: 6,
    marginBottom: 6,
  },
});

const TradingToolSidebarSport = ({
  sport,
  sportSearchValue,
  selectedSport,
  setSelectedSport,
  selectedCategory,
  setSelectedCategory,
  selectedTournament,
  setSelectedTournament,
}) => {
  const classes = useStyles();
  const [isOpened, setOpened] = useState(false);
  const [categorySearchValue, setCategorySearchValue] = useState('');

  const { data: { data: categories = [] } = {} } = useQuery(
    CATEGORIES_FILTER_AUTOCOMPLETE_QUERY,
    {
      variables: {
        sportId: sport.id,
      },
      fetchPolicy: 'cache-and-network',
      skip: !isOpened,
    }
  );

  const handleSportSelected = useCallback(() => {
    if (sport.id !== selectedSport?.id) {
      setSelectedSport({ id: sport.id, name: sport.name });

      if (!isOpened) {
        setOpened(true);
      }
    } else {
      setSelectedSport(null);
    }

    setSelectedCategory(null);
    setSelectedTournament(null);
  }, [
    sport,
    selectedSport,
    setSelectedSport,
    setSelectedCategory,
    setSelectedTournament,
    isOpened,
    setOpened,
  ]);

  const onCategorySelected = useCallback(() => {
    setSelectedSport({ id: sport.id, name: sport.name });
  }, [sport, setSelectedSport]);

  return (
    <Box className={classes.tradingToolSidebarSport}>
      <span
        className={clsx(classes.sportLine, {
          [classes.selectedLine]: sport.id === selectedSport?.id,
        })}
        onClick={handleSportSelected}
      >
        <ArrowRightSmall
          className={clsx(classes.arrowRight, {
            [classes.arrowRightOpened]: isOpened,
          })}
          onClick={e => {
            e.stopPropagation();
            setOpened(!isOpened);
          }}
        />
        <SportIcon name={sport.id} black className={classes.sportIcon} />
        <span className={classes.sportName}>
          <Highlighted highlight={sportSearchValue}>{sport.name}</Highlighted>
        </span>
        {`(${sport.active_events_count})`}
      </span>
      {isOpened && (
        <>
          <TextField
            label="Category"
            value={categorySearchValue}
            onChange={e => setCategorySearchValue(e.target.value)}
            withSearchIcon
            clearButton
            onClear={() => setCategorySearchValue('')}
            className={classes.categorySearchField}
          />
          {sortByEventsCount(categories)
            .filter(({ name }) =>
              name.toLowerCase().includes(categorySearchValue.toLowerCase())
            )
            .map(category => (
              <TradingToolSidebarCategory
                key={category.id}
                sport={sport}
                category={category}
                categorySearchValue={categorySearchValue}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedTournament={selectedTournament}
                setSelectedTournament={setSelectedTournament}
                onCategorySelected={onCategorySelected}
              />
            ))}
        </>
      )}
    </Box>
  );
};

export default TradingToolSidebarSport;
