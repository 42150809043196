import { useApolloClient, useMutation } from '@apollo/client';
import { pickBy } from 'lodash';
import { useCallback, useContext } from 'react';
import { ConfirmationPromptContext } from '../../../../providers/ConfirmationPromptProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import cancelBetslips from '../../../gql/Reports/mutations/cancelBetslips';
import getBetsStatus from '../../../gql/Reports/queries/getBetsStatus';
import { useTableBulkSelection } from '../../../hooks';
import { useToasts } from '../../../providers/ToastsProvider';
import { BET_STATUS } from '../BetHistory';
import { COLUMN } from '../BetHistoryTable';
import useCancelationNotifications from './useCancelationNotifications';

const useMultipleBetslipCancel = ({ items }) => {
  const client = useApolloClient();

  const { openConfirmation } = useContext(ConfirmationPromptContext);

  const [cancelBetslipsCb, { loading: cancelBetslipsLoading }] = useMutation(
    cancelBetslips,
    {
      refetchQueries: ['getBetHistoryList'],
    }
  );

  const bulk = useTableBulkSelection(items, COLUMN.BET_ID, false);
  const chosenIds = pickBy(bulk.chosenItems, value => value);
  const chosenIdsCount = Object.keys(chosenIds).length;

  const { cancelationNotification, errorNotification, getBetsCountTitle } =
    useCancelationNotifications();

  const cancelBetsSubmit = useCallback(
    items => {
      const betslipsToCancel = items.map(value => ({
        playerId: value.playerId,
        betId: value.betId,
      }));
      const canceledCount = items.filter(
        value =>
          value.betStatus === BET_STATUS.CANCELED ||
          value.betStatus === BET_STATUS.REJECTED
      ).length;

      const countBetsForCancelation = chosenIdsCount - canceledCount;

      cancelBetslipsCb({ variables: { betslipsToCancel } })
        .then(({ data: { data } = {} }) => {
          cancelationNotification({
            data,
            betsCount: countBetsForCancelation,
            alreadyCanceled: canceledCount,
          });
        })
        .catch(errorNotification)
        .finally(() => {
          bulk.reset();
        });
    },
    [
      cancelBetslipsCb,
      cancelationNotification,
      errorNotification,
      bulk,
      chosenIdsCount,
    ]
  );

  const onCancelBets = useCallback(() => {
    openConfirmation({
      buttonLabel: 'Confirm',
      title: 'Cancel bets',
      message: (
        <>
          Are you sure you want to perform action{' '}
          <b>{`cancel ${getBetsCountTitle(chosenIdsCount)}`}</b>?
        </>
      ),
      callback: async () => {
        client
          .query({
            query: getBetsStatus,
            fetchPolicy: 'no-cache',
            variables: {
              inputFilters: {
                filterBetId: Object.keys(chosenIds),
                limit: Object.keys(chosenIds).length,
              },
            },
          })
          .then(
            ({
              data: {
                data: { items },
              },
            }) => {
              cancelBetsSubmit(items);
            }
          );
      },
    });
  }, [
    chosenIds,
    chosenIdsCount,
    openConfirmation,
    cancelBetsSubmit,
    getBetsCountTitle,
    client,
  ]);

  return {
    bulk,
    cancelBetslipsLoading,
    chosenIdsCount,
    onCancelBets,
  };
};

export default useMultipleBetslipCancel;
