import React from 'react';
import { QRCode } from 'react-qr-svg';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  code: {
    maxHeight: 200,
  },
});

const QRCodeWidget = ({ value, label }) => {
  const classes = useStyles();

  return value ? (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <QRCode value={value} className={classes.code} />
    </div>
  ) : null;
};

export default QRCodeWidget;
