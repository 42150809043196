import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const addMarketFreeSpecifierMutation = gql`
  ${eventModelFragment}
  mutation addMarketFreeSpecifier(
    $eventId: EventId!
    $marketId: MarketId!
    $outcomeMame: String!
    $version: Version!
  ) {
    addMarketFreeSpecifier(
      event_id: $eventId
      market_id: $marketId
      outcome_name: $outcomeMame
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;

export default addMarketFreeSpecifierMutation;
