import { useMemo } from 'react';
import EmptyStateImage from '../../../img/EmptyStateImage';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  emptyStateCellRoot: {
    display: 'block',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  emptyStateRowRoot: {
    display: 'block',
    width: '100%',
  },
  emptyState: {
    height: 210,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '&>span': {
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '13px',
      letterSpacing: '0.2px',
      marginTop: 16,
    },
  },
});

const TableEmptyPlaceholder = ({
  error,
  fixed,
  showApplyRequiredPlaceholder = false,
  containerWidth,
}) => {
  const classes = useStyles();

  const placeholderText = useMemo(() => {
    if (error) {
      return 'Something went wrong';
    }

    if (showApplyRequiredPlaceholder) {
      return 'Apply filters to show results';
    }

    return 'Table is empty';
  }, [error, showApplyRequiredPlaceholder]);

  return (
    <div
      className={classes.emptyStateRowRoot}
      style={
        fixed && containerWidth
          ? {
              width: `${containerWidth}px`,
              position: 'sticky',
              left: 0,
            }
          : {}
      }
    >
      <div align={'center'} className={classes.emptyStateCellRoot}>
        <div className={classes.emptyState}>
          <EmptyStateImage />

          <span>{placeholderText}</span>
        </div>
      </div>
    </div>
  );
};

export default TableEmptyPlaceholder;
