import useAutocompleteData from '../../../hooks/useAutocompleteData';
import { forwardRef } from 'react';
import useSelectCheckValueOptions from '../../../../hooks/useSelectCheckValueOptions';
import FancySelect from '../FancySelect/FancySelect';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';
import FancyAutocompleteAllOption from '../FancyAutocomplete/FancyAutocompleteAllOption';
import useFancyAutocompleteAllToggle from '../../../../hooks/useFancyAutocompleteAllToggle';

const filterOptions = createFilterOptions({
  stringify: option => {
    return option.name;
  },
});

const FancyBrandSelect = forwardRef(
  (
    {
      operatorIds = [],
      withOperator = true,
      multiple = false,
      value: v,
      valueKey = 'id',
      onChange,
      disabled: isDisabled,
      checkValueOptions = true,
      proxyValue = false,
      ...props
    },
    ref
  ) => {
    const disabled = isDisabled || (!operatorIds.length && withOperator);
    const { data: options = [], loading } = useAutocompleteData({
      url: '/api/v1/Autocomplete/brands',
      params: withOperator
        ? {
            operator_id: operatorIds,
          }
        : {},
      disabled,
    });

    const value = multiple ? v || [] : v;

    const [toggledAll, onToggleAll] = useFancyAutocompleteAllToggle({
      onChange: onChange,
      options,
      value,
      valueKey: 'id',
    });

    useSelectCheckValueOptions({
      loading,
      value,
      options,
      valueKey,
      multiple,
      onChange,
      isValueObject: !proxyValue,
      check:
        checkValueOptions &&
        !((operatorIds.length || !withOperator) && value && value.length),
    });

    const Component = (
      <FancySelect
        ref={ref}
        label={'Brand'}
        multiple={multiple}
        options={options}
        labelKey={'name'}
        disabled={disabled}
        onChange={onChange}
        value={value}
        valueKey={valueKey}
        uniqueKey={['id', 'name']}
        filterOptions={filterOptions}
        {...props}
        getInputPlaceholder={defaultPlaceholder =>
          multiple && toggledAll ? 'All' : defaultPlaceholder
        }
        extraOptions={
          multiple && (
            <FancyAutocompleteAllOption
              label={'All Brands'}
              onToggle={onToggleAll}
              toggled={toggledAll}
            />
          )
        }
      />
    );

    if (proxyValue) {
      return (
        <FancySelectValueProxy
          multiple={multiple}
          value={value}
          options={options}
          onChange={onChange}
          valueKey={valueKey}
        >
          {Component}
        </FancySelectValueProxy>
      );
    }

    return Component;
  }
);

export default FancyBrandSelect;
