import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  optionContentDetails: {
    display: 'flex',
    gap: 8,
    color: '#8496AB',
  },
  optionDetailsCard: {
    overflow: 'hidden',
    maxWidth: 200,
  },
  optionDetailsCardLabel: {
    fontSize: 10,
    lineHeight: '12px',
    marginBottom: 1,
  },
  optionDetailsCardValue: {
    lineHeight: '14px',
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DetailSelectOption = ({ className, option, labelKey, subKey }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {option[labelKey]}
      <div className={classes.optionContentDetails}>
        {option[subKey].templates.map((value, index) => (
          <div className={classes.optionDetailsCard}>
            <div className={classes.optionDetailsCardLabel}>{value.label}</div>
            <div className={classes.optionDetailsCardValue} title={value.value}>
              {value.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailSelectOption;
