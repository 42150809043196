import React from 'react';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    position: 'absolute',
    zIndex: 3,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    boxShadow: '0px 2px 16px rgba(132, 150, 171, 0.25)',
    borderRadius: 8,
    maxHeight: 400,
    overflow: 'auto',
  },
});

const Menu = ({ innerProps, children, classes }) => (
  <Paper square className={classes.root} {...innerProps}>
    {children}
  </Paper>
);

export default withStyles(styles)(Menu);
