import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import UiComponentSchemaProvider from '../../../providers/UiComponentSchemaProvider';
import Button from '../../../v2/components/Button/Button';

const useStyles = makeStyles(theme => ({
  fieldObjectRoot: {
    display: 'flex',
  },

  fieldObjectRootProperty: {
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: 8,
    padding: '24px 16px',
  },

  fieldObjectRootPropertyDivided: {
    backgroundColor: 'initial',
    margin: 4,
    padding: '0',
  },

  fieldObjectColumn: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  fieldObjectRootPrimitive: {
    padding: '24px 16px',
  },
  formControls: {
    padding: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: 8,
  },
}));

const RootObjectFieldTemplate = ({ uiSchema = {}, properties, schema }) => {
  const classes = useStyles();

  const uiOrders = uiSchema['ui:orders'];

  const getProperty = name => {
    const element =
      properties && properties.find(property => property.name === name);

    if (!element) {
      return null;
    }

    const propertyUiSchema = uiSchema[name] || {};
    const propertySchema = schema.properties[name] || {};

    const { divided } = propertyUiSchema['ui:options'] || {};
    const isPrimitive =
      propertySchema.type !== 'array' && propertySchema.type !== 'object';

    return (
      <UiComponentSchemaProvider value={propertyUiSchema}>
        <div
          className={classNames(classes.fieldObjectRootProperty, {
            [classes.fieldObjectRootPropertyDivided]: divided,
            [classes.fieldObjectRootPrimitive]: isPrimitive,
          })}
        >
          {element.content}
        </div>
      </UiComponentSchemaProvider>
    );
  };

  return (
    <div className={classes.fieldObjectRoot}>
      {uiOrders
        ? uiOrders.map((uiOrder, index) => (
            <div className={classes.fieldObjectColumn}>
              {uiOrder.map(propertyName => getProperty(propertyName))}
              {index === uiOrders.length - 1 && (
                <div className={classes.formControls}>
                  <Button color={'primary'} variant={'text'}>
                    Cancel
                  </Button>
                  <Button
                    color={'lightBlue'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          ))
        : properties.map(element => (
            <div className={classes.fieldObjectRootProperty}>
              {element.content}
            </div>
          ))}
    </div>
  );
};

export default RootObjectFieldTemplate;
