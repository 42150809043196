import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles({
  fieldLabel: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    padding: '0 0 16px',
    letterSpacing: '0.5px',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
});

const FieldLabel = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.fieldLabel, className)}>{children}</div>
  );
};

export default FieldLabel;
