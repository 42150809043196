import { useCallback, useMemo } from 'react';
import { usePresets } from '../../providers/PresetsProvider';

const useFiltersPreset = () => {
  const { activePreset, updatePreset } = usePresets();

  const saveFilters = useCallback(
    filters => {
      if (activePreset) {
        updatePreset(activePreset.id, {
          ...activePreset,
          data: {
            ...activePreset.data,
            filters,
          },
        });
      }
    },
    [activePreset, updatePreset]
  );

  return useMemo(
    () => [saveFilters, activePreset],
    [activePreset, saveFilters]
  );
};

export default useFiltersPreset;
