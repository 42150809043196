import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ZoomTransition from '../utils/transitions/ZoomTransition';
import PresetForm from '../uiComponents/Filters/PresetForm';
import { usePresets } from './PresetsProvider';

export const EditPresetDialogContext = createContext({});

export const useEditPresetDialog = () => {
  return useContext(EditPresetDialogContext);
};

const EditPresetDialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [preset, setPreset] = useState();
  const { updatePreset } = usePresets();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSave = useCallback(
    preset => {
      updatePreset(preset.id, preset)
        .then(() => {
          setIsOpen(false);
          setErrorMessage(null);
        })
        .catch(e => setErrorMessage(e.message));
    },
    [updatePreset, setIsOpen, setErrorMessage]
  );

  const open = useCallback(preset => {
    setPreset(preset);
    setIsOpen(true);
  }, []);

  const providerValue = useMemo(() => {
    return {
      open: open,
      close: onClose,
    };
  }, [open, onClose]);

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage(null);
    }
  }, [isOpen, setErrorMessage]);

  return (
    <EditPresetDialogContext.Provider value={providerValue}>
      <Dialog
        TransitionComponent={ZoomTransition}
        onClose={onClose}
        aria-labelledby="notification-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="notification-dialog-title">Edit Preset</DialogTitle>
        <PresetForm
          onSave={onSave}
          onCancel={onClose}
          presetData={preset}
          errorMessage={errorMessage}
        />
      </Dialog>

      {children}
    </EditPresetDialogContext.Provider>
  );
};

export default EditPresetDialogProvider;
