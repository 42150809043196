import { gql } from '@apollo/client';

const EVENTS_QUERY = gql`
  query getEvents($filters: EventsFilters) {
    data: getEvents(input: $filters) {
      items {
        id
        platform_id
        status
        scheduled
        sport
        category
        tournament
        competitors
        creator
        blocked_markets
        version
      }
      total
    }
  }
`;

export default EVENTS_QUERY;
