import Page from '../../components/Page/Page';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import BannerForm from './components/BannerForm/BannerForm';
import useBannerData from './hooks/useBannerData';
import { useParams } from 'react-router-dom';
import ErrorWidget from '../../components/ErrorWidget';
import React from 'react';

const PromoBannerEditPage = () => {
  const { bannerId } = useParams();
  const { data, error } = useBannerData(bannerId);

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Edit Banner'}>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Banners',
            to: '/banners',
          },
          {
            label: 'Edit Banner',
          },
        ]}
      />
      <BannerForm defaultValues={data} bannerId={bannerId} />
    </Page>
  );
};

export default PromoBannerEditPage;
