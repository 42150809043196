import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useRef, useState } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import PickerInput from './PickerInput';
import NumberRangePicker from './NumberRangePicker/NumberRangePicker';
import toNumber from 'lodash/toNumber';
import isNil from 'lodash/isNil';
import { useFormContext } from 'react-hook-form';
import IsValidRange from './NumberRangePicker/utils/IsValidRange';
import clsx from 'clsx';

const useStyles = makeStyles({
  inputFullWidth: {
    width: '100%',
  },

  numberRangePopper: {
    zIndex: 10000,
  },

  numberRangePaper: {
    padding: 16,
    overflow: 'hidden',
  },
});

const formatNumber = (number, defaultValue) => {
  if (isNaN(number) || number === null) {
    return defaultValue;
  }

  return number;
};

const numberDisplayFormat = (from, to) => {
  if ((from === null && to === null) || (isNaN(from) && isNaN(to))) {
    return '';
  }
  return `${formatNumber(from, '-∞')} – ${formatNumber(to, '∞')}`;
};

const parseCustomValue = customValue => {
  // eslint-disable-next-line
  // noinspection RegExpRedundantEscape
  const match = customValue.match(/[\d\.\,]+/g);

  if (!match) {
    return {
      rangeFrom: null,
      rangeTo: null,
    };
  }

  const value = match && match.map(item => toNumber(item));

  return {
    rangeFrom: value[0],
    rangeTo: value[1],
  };
};

const NumberRangeInput = ({
  id,
  name,
  value,
  onChange,
  className,
  disabled,
  label,
  error,
  fullWidth,
  helperText,
  InputProps,
  minFrom,
  maxFrom,
  minTo,
  maxTo,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const anchorElRef = useRef(null);
  const focusRef = useRef(null);
  const secondFocusRef = useRef(null);
  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);
  const { setError, clearErrors } = useFormContext();

  const displayValue = value
    ? numberDisplayFormat(value.rangeFrom, value.rangeTo)
    : '';

  const checkValidationError = useCallback(
    ({ rangeFrom, rangeTo }) => {
      const isValidFrom = IsValidRange(rangeFrom, minFrom, maxFrom);
      const isValidTo = IsValidRange(rangeTo, minTo, maxTo);

      const isValuesDefined = !isNil(rangeFrom) && !isNil(rangeTo);
      const isInvalid = isValuesDefined && !(isValidFrom && isValidTo);
      const isWrongOrder = isValuesDefined && rangeFrom > rangeTo;

      return isInvalid || isWrongOrder;
    },
    [minFrom, maxFrom, minTo, maxTo]
  );

  const handleChange = useCallback(
    range => {
      onChange(range);
      if (
        !checkValidationError({
          rangeFrom: range.rangeFrom,
          rangeTo: range.rangeTo,
        })
      ) {
        clearErrors([name]);
      }

      setIsOpen(false);
    },
    [onChange, clearErrors, name, setIsOpen, checkValidationError]
  );

  const handleChangeCustomValue = useCallback(
    customValue => {
      if (customValue) {
        return onChange(parseCustomValue(customValue));
      } else {
        onChange({});
      }
    },
    [onChange]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  //
  // const focusPicker = useCallback(() => {
  //   if (focusRef.current) {
  //     focusRef.current.focus();
  //     focusRef.current.select();
  //   }
  // }, []);

  const handleValidationError = useCallback(() => {
    const message = `Correct range: from ${
      typeof minFrom === 'undefined' ? '-∞' : minFrom
    } to ${typeof maxTo === 'undefined' ? '∞' : maxTo}`;

    setError(name, {
      type: 'manual',
      message,
    });
  }, [name, setError, minFrom, maxTo]);

  const handlePickerInputChange = value => {
    const { rangeFrom, rangeTo } = parseCustomValue(value);

    if (checkValidationError({ rangeFrom, rangeTo })) {
      handleValidationError();
    } else {
      clearErrors([name]);
    }
  };

  return (
    <div>
      <PickerInput
        id={id}
        onClick={handleOpen}
        ref={anchorElRef}
        displayValue={displayValue}
        onChangeInput={handlePickerInputChange}
        onBlurInput={handleChangeCustomValue}
        InputProps={{
          ...InputProps,
        }}
        className={clsx(className, classes.inputFullWidth)}
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        label={label}
        disabled={disabled}
      />

      <Popper
        className={classes.numberRangePopper}
        open={isOpen}
        anchorEl={anchorElRef.current}
        placement={'bottom'}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.numberRangePaper} elevation={2}>
            <NumberRangePicker
              helperText={helperText}
              minFrom={minFrom}
              maxFrom={maxFrom}
              minTo={minTo}
              maxTo={maxTo}
              setValidationError={handleValidationError}
              clearErrors={clearErrors}
              value={value}
              onChange={handleChange}
              focusRef={focusRef}
              secondFocusRef={secondFocusRef}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default NumberRangeInput;
