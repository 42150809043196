import React, { useCallback, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { WebSocketContext } from '../providers/WebSocketProvider';
import classNames from 'clsx';
import { SocketStatusEnum } from '../utils/Socket';

const useStyles = makeStyles(theme => ({
  connectionStatusBar: {
    zIndex: -1,
    width: '100%',
    height: 32,
    background: '#FFE792',
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    position: 'absolute',
    bottom: -32,

    transform: 'translateY(-32px)',

    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: 1000,
    }),
  },

  offlineConnectionStatusBar: {
    transform: 'translateY(0)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: 1000,
    }),
  },

  activeConnectionStatusBar: {
    background: '#8CEBBD',
    transition: [
      theme.transitions.create(['background'], {
        easing: theme.transitions.easing.easeInOut,
        duration: 100,
      }),
      theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.easeInOut,
        duration: 1000,
        delay: 200,
      }),
    ],
  },

  reloadLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 4,
  },
}));

const ConnectionStatusBar = () => {
  const classes = useStyles();
  const { socketStatus } = useContext(WebSocketContext);

  const onReload = useCallback(() => {
    window.location.reload();
  }, []);

  if (!socketStatus || socketStatus === SocketStatusEnum.DISABLED) {
    return null;
  }

  return (
    <div
      className={classNames(classes.connectionStatusBar, {
        [classes.offlineConnectionStatusBar]:
          socketStatus !== SocketStatusEnum.ACTIVE,
        [classes.activeConnectionStatusBar]:
          socketStatus === SocketStatusEnum.ACTIVE,
      })}
    >
      {SocketStatusEnum.ACTIVE ? (
        <>
          Connection lost. We’ll keep trying to reconnect.{' '}
          <span onClick={onReload} className={classes.reloadLink}>
            Try again
          </span>
        </>
      ) : (
        <>Connection established.</>
      )}
    </div>
  );
};

export default ConnectionStatusBar;
