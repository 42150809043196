import { gql } from '@apollo/client';

const GetEventsNamesInLine = gql`
  query getEventsNamesInLine(
    $query: String!
    $limit: Int
    $offset: Int
    $sportIds: [SportId!]
    $excludeSportIds: Boolean
    $categoriesIds: [CategoryId!]
    $excludeCategoriesIds: Boolean
    $tournamentsIds: [TournamentId!]
    $excludeTournamentsIds: Boolean
    $eventsIds: [EventId!]
  ) @api(name: autocomplete) {
    data: getEventsNamesInLine(
      query: $query
      limit: $limit
      offset: $offset
      sportIds: $sportIds
      excludeSportIds: $excludeSportIds
      categoriesIds: $categoriesIds
      excludeCategoriesIds: $excludeCategoriesIds
      tournamentsIds: $tournamentsIds
      excludeTournamentsIds: $excludeTournamentsIds
      eventsIds: $eventsIds
    ) {
      label: name
      value: id
    }
  }
`;

export default GetEventsNamesInLine;
