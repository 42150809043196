import React from 'react';
import get from 'lodash/get';
import RangeDateInput from '../../../components/inputs/RangeDateInput';
import IntegerRangeInput from '../../../components/inputs/IntegerRangeInput';
import NumberRangeInput from '../../../components/inputs/NumberRangeInput';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
});

const RangeFilterByType = {
  'date-time': RangeDateInput,
  integer: IntegerRangeInput,
  number: NumberRangeInput,
};

const RangeFilter = ({ ...props }) => {
  const { schema } = props;
  const classes = useStyles();

  const type = get(
    schema,
    ['properties', 'range_from', 'format'],
    get(schema, ['properties', 'range_from', 'type'])
  );

  const Component = RangeFilterByType[type];

  const minFrom = get(schema, 'properties.range_from.minimum');
  const maxFrom = get(schema, 'properties.range_from.maximum');

  const minTo = get(schema, 'properties.range_to.minimum');
  const maxTo = get(schema, 'properties.range_to.maximum');

  return (
    <div>
      {Component ? (
        <Component
          className={classes.fullWidth}
          {...props}
          minFrom={minFrom}
          maxFrom={maxFrom}
          minTo={minTo}
          maxTo={maxTo}
          rangeFromKey={'range_from'}
          rangeToKey={'range_to'}
        />
      ) : (
        'Unknown component'
      )}
    </div>
  );
};

export default RangeFilter;
