export const getGroupItemAffiliation = groupItem => {
  const isRace = !!groupItem.desc?.raceNumber;

  const competitorsString =
    groupItem.desc.competitors?.length === 2
      ? `${groupItem.desc.competitors?.join(' - ')}, `
      : '';
  const tournamentString = `${groupItem.desc?.tournament}, `;
  const categoryString = groupItem.desc?.category;

  return `${
    isRace ? `${groupItem.desc?.raceNumber}, ` : ''
  }${competitorsString}${tournamentString}${categoryString}`;
};

export const getGroupAffiliation = group => {
  const mainItem =
    group.booked ||
    (group.groupItems && group.groupItems.find(item => item.mainItem)) ||
    group.groupItems[0];

  return mainItem && getGroupItemAffiliation(mainItem);
};
