import get from 'lodash/get';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';

import flatten from 'flat';
import dropRight from 'lodash/dropRight';
import evalCondition, { getConditionRule } from '../../utils/evalCondition';
import mapValuesDeep from '../../utils/mapValuesDeep';
import {
  getMethodAndUrl,
  getPathByOperationId,
  hasSchemaProperty,
} from '../../utils/schemaHelper';

const getSchemaWithoutHiddenProperties = (schema, keys) => {
  if (schema.type === 'array') {
    return {
      ...schema,
      items: {
        ...schema.items,
        ...getSchemaWithoutHiddenProperties(schema.items, keys.slice(1)),
      },
    };
  }

  return {
    ...schema,
    properties:
      keys.length === 1
        ? omit(schema.properties, keys)
        : {
            ...schema.properties,
            [keys[0]]: getSchemaWithoutHiddenProperties(
              schema.properties[keys[0]],
              keys.slice(1)
            ),
          },
    required:
      keys.length === 1
        ? schema.required && schema.required.filter(key => key !== keys[0])
        : schema.required || [],
  };
};

const getSelectsData = (state, uiSchema, data, routes) => {
  const fullValues = {};

  mapValuesDeep(uiSchema, (value, path) => {
    const lastPathItem = path.pop();
    const inputKey = path[path.length - 1];

    if (lastPathItem === 'ui:widget') {
      const widget = get(uiSchema, path);

      if (
        widget['ui:widget'] === 'select' &&
        widget['ui:options'] &&
        widget['ui:options']['operationId']
      ) {
        const operationId = widget['ui:options']['operationId'];
        const params = widget['ui:options']['params'] || {};

        const paramValues = Object.keys(params).reduce((result, param) => {
          return {
            ...result,
            [param]: data[param] || params[param],
          };
        }, {});

        let selectData = state.data[operationId];

        if (selectData) {
          const { url, method } = getMethodAndUrl({
            path: getPathByOperationId(routes, operationId),
            params: paramValues,
            query: paramValues,
          });

          selectData = selectData[method + url];

          if (selectData && selectData.data && selectData.data.items) {
            fullValues[inputKey] = selectData.data.items.find(valueItem => {
              const valueKey = widget['ui:options']['value_key'];

              return valueItem[valueKey] === data[inputKey];
            });
          }
        }
      }
    }
  });

  return flatten(fullValues, {
    delimiter: '.',
  });
};

const getConditionField = condition => {
  const rule = getConditionRule(condition);

  return rule.field;
};

const omitByPath = (data, path) => {
  const indexOfArray = path.indexOf('items');
  if (indexOfArray !== -1) {
    const pathToArray = path.slice(0, indexOfArray);
    const restPath = path.slice(indexOfArray + 1);
    return set(
      { ...data },
      pathToArray,
      get(data, pathToArray, []).map(item => omitByPath(item, restPath))
    );
  } else {
    return omit(data, [path]);
  }
};

const processForm = (
  state,
  originalSchema,
  originalUISchema,
  prevFormData,
  prevFullFormData,
  params,
  formParams = {},
  routes
) => {
  let schema = originalSchema;

  let formData = {
    ...formParams,
    ...prevFormData,
  };
  const fullFormData = merge({}, prevFullFormData, formData);

  const uiSchema = mapValuesDeep(originalUISchema, (value, pathArr) => {
    const key = pathArr[pathArr.length - 1];
    if (key === 'ui:disabled' && typeof value === 'string') {
      return evalCondition(value, prevFormData);
    }

    if (key === 'ui:condition') {
      const keys = pathArr.slice(0, pathArr.length - 1);

      const isTruthy = evalCondition(value, {
        ...prevFormData,
        ...getSelectsData(
          state,
          originalUISchema,
          {
            ...params,
            ...fullFormData,
          },
          routes
        ),
      });

      const nestedPath = pathArr.length >= 3 && dropRight(pathArr, 2);

      const isTruthyNested =
        nestedPath &&
        hasSchemaProperty(
          schema,
          [...nestedPath, getConditionField(value)].join('.')
        ) &&
        evalCondition(`${[...nestedPath, value].join('.')}`, {
          ...fullFormData,
          ...getSelectsData(
            state,
            originalUISchema,
            {
              ...params,
              ...fullFormData,
            },
            routes
          ),
        });

      if (
        (isTruthy || isTruthyNested) &&
        !has(formData, [keys]) &&
        has(fullFormData, [keys])
      ) {
        set(formData, [keys], get(fullFormData, [keys]));
      }

      if (!isTruthy && !isTruthyNested) {
        schema = getSchemaWithoutHiddenProperties(schema, keys);
        formData = omitByPath(formData, keys);
      }
    }

    return value;
  });

  if (originalUISchema['ui:order']) {
    // Update UI Schema UI order
    // react-jsonschema-form cannot handle extra properties found in UI order
    uiSchema['ui:order'] = intersection(
      originalUISchema['ui:order'],
      Object.keys(schema.properties)
    );
  }

  // Update Schema required fields
  if (originalSchema.required) {
    schema.required = intersection(
      originalSchema.required,
      Object.keys(schema.properties)
    );
  }
  return {
    schema,
    uiSchema,
    formData,
    fullFormData,
  };
};

export default processForm;
