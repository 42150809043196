import React, { useCallback } from 'react';
import ContextMenu from '../../components/ContextMenu';
import ContextMenuItem from '../../components/ContextMenuItem';
import MoreVertIcon from '../../icons/controls/MoreVertIcon';
import IconButton from '@mui/material/IconButton';

const PresetCardMenu = ({ onEdit, onRemove, classes = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState();
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        classes={{
          root: classes.button,
        }}
        aria-label="close"
        variant={'text'}
        disableRipple
        onClick={event => {
          if (event.currentTarget) {
            setAnchorEl(event.currentTarget);
          }
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <ContextMenu
        id="preset-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <ContextMenuItem
          onClick={async () => {
            onEdit();
            handleMenuClose();
          }}
        >
          Edit
        </ContextMenuItem>
        <ContextMenuItem
          destructive={true}
          onClick={async e => {
            e.stopPropagation();

            onRemove();
            handleMenuClose();
          }}
        >
          Remove
        </ContextMenuItem>
      </ContextMenu>
    </>
  );
};

export default PresetCardMenu;
