import { gql } from '@apollo/client';

const CATEGORIES_FILTER_AUTOCOMPLETE_QUERY = gql`
  query getCategoriesEvents($sportId: SportId) {
    data: getCategoriesEvents(sport_id: $sportId) {
      id
      name
      active_events_count
    }
  }
`;

export default CATEGORIES_FILTER_AUTOCOMPLETE_QUERY;
