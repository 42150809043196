import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(() => ({
  checkbox: {
    fontSize: '14px',
  },
}));

const CheckBoxIcon = ({ className, ...props }) => {
  const { checkbox } = useStyles();

  return (
    <SvgIcon
      viewBox={'0 0 14 14'}
      className={classNames(checkbox, className)}
      {...props}
    >
      <path
        d="M1 2C1 1.44772 1.44772 1 2 1H12C12.5523 1 13 1.44772 13 2V12C13 12.5523 12.5523 13 12 13H2C1.44772 13 1 12.5523 1 12V2Z"
        fill={'#fff'}
        stroke={'currentColor'}
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default CheckBoxIcon;
