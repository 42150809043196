import React, { Children, cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classNames from 'clsx';
import TabbedViewProvider from '../providers/TabbedViewProvider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabs: {
    flex: 1,
  },

  nestedTabs: {
    background: '#fff',
    minHeight: 32,
  },

  nestedTab: {
    minHeight: 32,
    paddingTop: 2,
    paddingBottom: 2,

    fontSize: '14px',
    fontWeight: 500,

    '&$selectedNestedTab': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },

    '&:first-child': {
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    },

    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },

  selectedNestedTab: {},
}));

const TabbedView = ({
  title,
  actions,
  filters,
  children,
  currentTab,
  onTabChange,
  nestedTabs,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.row}>
        {title && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}
        {actions}
      </div>
      <div className={classes.row}>
        <Tabs
          className={classNames(classes.tabs, {
            [classes.nestedTabs]: nestedTabs,
          })}
          value={currentTab}
          onChange={(e, currTab) => onTabChange(currTab)}
          variant="scrollable"
          scrollButtons={false}
        >
          {Children.map(children, child => (
            <Tab
              label={child.props.label}
              classes={
                nestedTabs
                  ? {
                      root: classes.nestedTab,
                      selected: classes.selectedNestedTab,
                    }
                  : null
              }
            />
          ))}
        </Tabs>

        {filters}
      </div>
      {Children.map(children, (child, index) =>
        index === currentTab
          ? cloneElement(
              <TabbedViewProvider isActiveTab={index === currentTab}>
                {child}
              </TabbedViewProvider>,
              {
                isInActiveTab: index === currentTab,
              }
            )
          : null
      )}
    </Fragment>
  );
};

TabbedView.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  filters: PropTypes.node,
  currentTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default TabbedView;
