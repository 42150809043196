import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import UserIcon from '../../../icons/controls/UserIcon';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { getActiveSchemaUrl } from '../../../reducers/schemaUrls';

const useStyles = makeStyles({
  userIcon: {
    fontSize: '15px',
  },
});

function EmailWidget(props) {
  const classes = useStyles();

  const { BaseInput } = props.registry.widgets;
  const activeSchemaUrl = useSelector(getActiveSchemaUrl);

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <UserIcon className={classes.userIcon} />
      </InputAdornment>
    ),
  };

  return (
    <BaseInput
      type="email"
      {...props}
      InputProps={inputProps}
      id={`email-${activeSchemaUrl}`}
    />
  );
}

EmailWidget.propTypes = {
  value: PropTypes.string,
};

export default EmailWidget;
