import { gql } from '@apollo/client';
import { COVERAGE_TEMPLATE_NODE_TYPE } from './getCoverageTemplate';

const getDuplicateCoverageTemplate = gql`
  ${COVERAGE_TEMPLATE_NODE_TYPE}
  query getDuplicateCoverageTemplate(
    $path: NodeSelectorInput
    $templateId: TemplateId
    $lineType: LineType!
  ) {
    data: getDuplicateCoverageTemplate(
      path: $path
      templateId: $templateId
      lineType: $lineType
    ) {
      ...CoverageTemplateNodesType
    }
  }
`;

export default getDuplicateCoverageTemplate;
