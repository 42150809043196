import FormField from '../../../../components/Form/FormField';
import TextField from '../../../../components/Inputs/TextField';
import Grid from '@mui/material/Grid/Grid';

const BannerTextField = ({ name, ...props }) => {
  return (
    <Grid item xs={4}>
      <FormField name={name}>
        <TextField label={'Banner Text'} {...props} />
      </FormField>
    </Grid>
  );
};

export default BannerTextField;
