import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  dynamicClearData,
  memoizedDebouncedGetRouteData,
} from '../actions/dataActions';
import { branch, compose, withProps } from 'recompose';
import { getData, getDataLoading, getError } from '../reducers/data';
import {
  getMethodAndUrl,
  getOperationIdByPath,
  getParameterValue,
  getStoreStrategy,
  getXStreamByPath,
} from '../utils/schemaHelper';
import getQuery from '../utils/getQuery';
import mapValues from 'lodash/mapValues';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WebSocketContext } from '../providers/WebSocketProvider';
import { getUserToken } from '../reducers/users';
import LocalQueryProvider from '../providers/LocalQueryProvider';
import usePrevious from '../hooks/usePrevious';
import { useRoutes } from '../providers/RoutesProvider';
import { useApiHost } from '../providers/ApiHostProvider';
import { useIsNestedTable } from '../providers/NestedTableProvider';
import useQueryByPath from '../hooks/useQueryByPath';
import { useIsTableContext } from '../providers/TableProvider';
import withRouter from '../utils/withRouter';
import { useUiComponentSchema } from '../providers/UiComponentSchemaProvider';

const withQuery = BaseComponent => props => {
  const {
    path,
    responseBody,
    query,
    location: { search },
    defaultLimit,
    schema,
  } = props;

  const isNestedTable = useIsNestedTable();
  const isTable = useIsTableContext();

  let [localQuery, setLocalQuery] = useState({});
  let { query: queryByPath, setQuery } = useQueryByPath(path);

  if (isTable && !isNestedTable) {
    localQuery = queryByPath;
    setLocalQuery = setQuery;
  }

  const fullQuery = useMemo(() => getQuery(search), [search]);
  const routes = useRoutes();

  const queryHash = fullQuery.hash;

  const combinedQuery = useMemo(() => {
    return {
      ...mapValues(routes[path].paramMaps, (params, paramName) => {
        const paramValue = params
          .map(param => {
            const requestQuery = fullQuery[param.path];

            return getParameterValue({
              param: param.value,
              requestQuery: requestQuery || {},
              responseBody,
            });
          })
          .reduce((prev, param) => {
            if (typeof prev === 'boolean') {
              return prev;
            }

            return prev || param;
          });

        if (paramName === 'limit') {
          return paramValue || defaultLimit;
        }

        if (paramName === 'offset') {
          return paramValue || 0;
        }

        return paramValue;
      }),
      ...query,
      ...localQuery,
    };
  }, [defaultLimit, fullQuery, localQuery, path, query, responseBody, routes]);

  return (
    <LocalQueryProvider setLocalQuery={setLocalQuery} localQuery={localQuery}>
      <BaseComponent
        {...props}
        schema={schema}
        query={combinedQuery}
        queryHash={queryHash}
        localQuery={localQuery}
        setLocalQuery={setLocalQuery}
        routes={routes}
      />
    </LocalQueryProvider>
  );
};

const withMethodAndUrl = withProps(getMethodAndUrl);

const mapStateToProps = (state, { method, url, source, path, routes }) => {
  return {
    data: getData(state, routes, method + url, source, path),
    error: getError(state, routes, method + url, path),
    loading: getDataLoading(state, routes, method + url, source, path),
  };
};

export const withConnectedRoutes = BaseComponent => props => {
  const routes = useRoutes('with connected routes');

  return <BaseComponent routes={routes} {...props} />;
};

const withDataFetcher = BaseComponent => props => {
  const {
    method,
    url,
    memoizedDebouncedGetRouteData,
    operationId,
    xStream,
    token,
    dynamicClearData,
    path,
    clearOnUnmount = true,
    query: queryByPath,
    queryHash,
    data,
  } = props;

  const { sendSocketMessage, socketStatus } = useContext(WebSocketContext);
  const prevToken = usePrevious(token);
  const prevUrl = usePrevious(url);
  const routes = useRoutes('data fetcher');
  const { apiHost } = useApiHost('data fetcher');
  const schemaState = useMemo(
    () => ({
      routes,
      apiHost,
    }),
    [routes, apiHost]
  );
  const uiSchema = useUiComponentSchema();
  const skipInitialRequest = get(uiSchema, 'ui:options.skip_initial_request');
  const [isUrlChanged, setUrlChanged] = useState(false);

  const fetchData = useCallback(() => {
    memoizedDebouncedGetRouteData(schemaState, {
      method,
      url,
      path,
      limit: queryByPath.limit,
    });
    // eslint-disable-next-line
  }, [
    url,
    queryHash,
    queryByPath.limit,
    memoizedDebouncedGetRouteData,
    schemaState,
    method,
    path,
  ]);

  useEffect(() => {
    const storePath = (method === 'put' ? 'get' : method) + url;
    if (xStream) {
      console.info('Socket subscribe:', storePath);
    }

    return () => {
      if (clearOnUnmount) {
        dynamicClearData({
          meta: {
            operationId,
            path: storePath,
          },
        });
      }

      let isUnsubscribe = !!xStream;

      if (
        xStream &&
        xStream['x-options'] &&
        xStream['x-options'].unsubscribe !== undefined
      ) {
        isUnsubscribe = xStream['x-options'].unsubscribe;
      }

      if (isUnsubscribe) {
        const storePath = (method === 'put' ? 'get' : method) + url;
        console.info('Socket unsubscribe:', storePath);
        sendSocketMessage({
          command: 'UNSUBSCRIBE',
          payload: {
            channel: operationId,
          },
        });
      }
    };
  }, [
    dynamicClearData,
    operationId,
    sendSocketMessage,
    xStream,
    path,
    clearOnUnmount,
    method,
    url,
  ]);

  useEffect(() => {
    if (!prevToken && !socketStatus && xStream) {
      return;
    }

    if (prevToken && prevToken !== token && !xStream) {
      return;
    }

    const skip = skipInitialRequest && !isUrlChanged;

    if (!skip) {
      fetchData();
    }
  }, [
    fetchData,
    xStream,
    sendSocketMessage,
    dynamicClearData,
    operationId,
    prevToken,
    prevUrl,
    url,
    socketStatus,
    token,
    skipInitialRequest,
    isUrlChanged,
  ]);

  useEffect(() => {
    if (!isUrlChanged && prevUrl && url !== prevUrl) {
      setUrlChanged(true);
    }
  }, [url, prevUrl, isUrlChanged, setUrlChanged]);

  return (
    <BaseComponent
      fetchData={fetchData}
      firstSkipped={skipInitialRequest && !isUrlChanged && !data}
      {...props}
    />
  );
};

const withData = branch(
  ({ path }) => path && path.endsWith('get'),
  compose(
    withRouter,
    withConnectedRoutes,
    connect(state => ({
      token: getUserToken(state),
    })),
    withQuery,
    withMethodAndUrl,
    connect(mapStateToProps, {
      memoizedDebouncedGetRouteData,
    }),
    connect(
      (state, { routes, path }) => ({
        operationId: getOperationIdByPath(routes, path),
        xStream: getXStreamByPath(routes, path),
        storeStrategy: getStoreStrategy(routes, path),
      }),
      {
        dynamicClearData,
      }
    ),
    withDataFetcher
  )
);

export default withData;
