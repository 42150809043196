import { RSAA } from 'redux-api-middleware';
import { showNotification } from './uiActions';
import memoizeDebounce from '../utils/memoizeDebounce';
import get from 'lodash/get';
import {
  DYNAMIC_CLEAR_SUCCESS,
  DYNAMIC_EDIT_FAILURE,
  DYNAMIC_EDIT_REQUEST,
  DYNAMIC_EDIT_SUCCESS,
  DYNAMIC_FAILURE,
  DYNAMIC_REQUEST,
  DYNAMIC_SUCCESS,
  DYNAMIC_UPDATE_SUCCESS,
} from '../constants/dataConstants';
import debounce from 'lodash/debounce';
import removeEmpty from '../utils/removeEmpty';
import { getModal } from '../reducers/modal';
import { hideModal } from './modalActions';
import {
  getLinksAndSchemas,
  getOperationIdByPath,
  getPathByOperationId,
  getResponseSchemas,
  getXStreamByPath,
} from '../utils/schemaHelper';
import getFilenameFromDisposition from '../utils/getFilenameFromDisposition';
import { saveAs } from 'file-saver';
import convertToSchemaTypes from '../utils/convertToSchemaTypes';
import getResponseTypeByCode from '../utils/getResponseTypeByCode';
import { createAction } from 'redux-actions';
import { batch } from 'react-redux';
import { compileSchema } from '../uiComponents/Field/FieldUtils';
import { v4 as uuid } from 'uuid';

const dynamicUpdate = createAction(
  DYNAMIC_UPDATE_SUCCESS,
  data => data.payload,
  data => data.meta
);

const dynamicClear = createAction(
  DYNAMIC_CLEAR_SUCCESS,
  data => {
    return data.payload;
  },
  data => data.meta
);

export const dynamicClearData =
  ({ meta }) =>
  (dispatch, getState) => {
    const state = getState();
    const operationStore = state.data[meta.operationId];

    if (!operationStore) {
      return;
    }

    const paths = Object.keys(operationStore);

    if (meta.path) {
      dispatch(
        dynamicClear({
          meta: {
            ...meta,
          },
        })
      );
    } else {
      for (let path of paths) {
        dispatch(
          dynamicClear({
            meta: {
              ...meta,
              path,
            },
          })
        );
      }
    }
  };

export const dynamicUpdateData =
  (messages, routes, query) => (dispatch, getState) => {
    const state = getState();

    batch(() => {
      messages.forEach(({ channel, payload, action }) => {
        const path = getPathByOperationId(routes, channel);
        const queryByPath = query[path];
        let limit;

        if (queryByPath && queryByPath.limit) {
          limit = queryByPath.limit;
        }
        const data = {
          meta: {
            channel,
            operationId: channel,
            limit,
            action,
          },
          payload: {
            data: payload,
            loading: false,
            error: null,
          },
        };

        const xStream = getXStreamByPath(routes, path);
        if (xStream && xStream['x-options'] && xStream['x-options'].store) {
          data.meta.storeStrategy = xStream['x-options'].store;
          data.meta.contentSchema = get(
            xStream,
            'content.application/json.schema'
          );
        }

        state.data &&
          state.data[data.meta.operationId] &&
          Object.keys(state.data[data.meta.operationId]).forEach(path => {
            dispatch(
              dynamicUpdate({
                ...data,
                meta: {
                  ...data.meta,
                  path,
                },
              })
            );
          });
      });
    });
  };

export const processJson = (data, state, path, routes) => {
  const withoutEmpty = removeEmpty(data);
  return path
    ? convertToSchemaTypes(
        withoutEmpty,
        getLinksAndSchemas(routes, path).schema
      )
    : withoutEmpty;
};

const handleResponse = (res, path, state, body, routes) => {
  if (res.headers.get('Content-Type') === 'application/json') {
    return res
      .json()
      .then(data => {
        return {
          data: processJson(data, state, path, routes),
          code: res.status,
        };
      })
      .catch(e => {
        console.error(e);
      });
  }
  const disposition = res.headers.get('Content-Disposition');

  if (disposition && disposition.includes('attachment')) {
    res
      .blob()
      .then(blob => saveAs(blob, getFilenameFromDisposition(disposition)));
  }

  return {
    data: body,
    code: res.status,
  };
};

export const fetchDynamic =
  (schemaState, { method, url, body, path, limit }) =>
  dispatch => {
    const { routes, apiHost } = schemaState;
    let operationId = getOperationIdByPath(routes, path);

    if (!operationId) {
      operationId = method + url;
    }

    const xStream = getXStreamByPath(routes, path);

    let xStreamMeta = {};

    if (xStream && xStream['x-options'] && xStream['x-options'].store) {
      xStreamMeta.storeStrategy = xStream['x-options'].store;
      xStreamMeta.contentSchema = get(
        xStream,
        'content.application/json.schema'
      );
    }

    const storePath = (method === 'put' ? 'get' : method) + url;

    const controller = new window.AbortController();
    const { signal } = controller;

    return [
      dispatch({
        [RSAA]: {
          endpoint: apiHost + url,
          method,
          body: JSON.stringify(body),
          options: { signal },
          types: [
            {
              type: DYNAMIC_REQUEST,
              meta: {
                path: storePath,
                operationId,
              },
            },
            {
              type: DYNAMIC_SUCCESS,
              payload: (action, state, res) => {
                return handleResponse(res, path, state, body, routes);
              },
              meta: {
                path: storePath,
                operationId,
                limit,
                ...xStreamMeta,
              },
            },
            {
              type: DYNAMIC_FAILURE,
              meta: {
                path: storePath,
                operationId,
              },
            },
          ],
        },
      }),
      controller,
    ];
  };

const memoizedDebouncedFetchDynamic = memoizeDebounce(
  (dispatch, schemaState, ...args) =>
    dispatch(fetchDynamic(schemaState, ...args)),
  100,
  {
    resolver: (dispatch, schemaState, ...args) => JSON.stringify(args),
    leading: true,
    trailing: false,
  }
);

export const memoizedDebouncedGetRouteData =
  (schemaState, ...args) =>
  dispatch =>
    memoizedDebouncedFetchDynamic(dispatch, schemaState, ...args);

const debouncedFetchDynamic = debounce(
  (dispatch, schemaState, ...args) =>
    dispatch(fetchDynamic(schemaState, ...args)),
  500
);

export const debouncedGetRouteData =
  (schemaState, ...args) =>
  dispatch =>
    debouncedFetchDynamic(dispatch, schemaState, ...args);

export const processUpdate =
  ({ data, path, notificationPopup, navigate }, schemaState) =>
  (dispatch, getState) => {
    const state = getState();
    const { routes } = schemaState;

    if (data.error && data.payload) {
      const response = data.payload && data.payload.response;

      const responseType = getResponseTypeByCode(response.code);

      dispatch(showNotification(responseType, response.message));

      return response;
    } else if (data.payload && data.payload.error) {
      const responseType = getResponseTypeByCode(data.payload.error.code);
      dispatch(
        showNotification(
          responseType,
          data.payload.error.args && data.payload.error.args.join(',')
        )
      );
    }

    if (data.payload) {
      const { uiSchema: responseUiSchema } = getResponseSchemas(
        get(routes, [path, 'responses', data.payload.code || 'default'])
      );

      if (
        responseUiSchema &&
        responseUiSchema['ui:component'] === 'NotificationPopup'
      ) {
        const responseBody = data.payload.data || {};
        const compiled = compileSchema(
          responseUiSchema['ui:options'],
          responseBody,
          {}
        );

        notificationPopup.open({
          key: uuid(),
          title: get(compiled, 'title.text', compiled.title || 'Notification'),
          message: get(compiled, 'description.text', compiled.description),
        });
      }
    }

    const { uiSchema } = getLinksAndSchemas(routes, path);

    dispatch(showNotification('success', 'Successfully sent'));

    if (getModal(state).modalType) {
      return dispatch(hideModal());
    }

    const redirectUrl = get(uiSchema, 'ui:options.submitRedirectUrl');

    if (typeof redirectUrl !== 'undefined') {
      return;
    }

    return navigate && navigate(-1);
  };

export const editData = meta => dispatch => {
  return dispatch({
    [RSAA]: {
      endpoint: meta.apiHost + meta.editPath,
      method: meta.method || 'PATCH',
      body: JSON.stringify(meta.body),
      types: [
        {
          type: DYNAMIC_EDIT_REQUEST,
          meta,
        },
        {
          type: DYNAMIC_EDIT_SUCCESS,
          payload: (action, state, res) =>
            res
              .json()
              .then(data => ({
                data,
                code: res.status,
              }))
              .catch(() => ({
                code: res.status,
                data: {},
              })),
          meta,
        },
        {
          type: DYNAMIC_EDIT_FAILURE,
          meta,
        },
      ],
    },
  });
};
