import { useQuery } from '@apollo/client';
import getGroups from '../../gql/EventsGroups/queries/getGroups';

const useGroups = (options = {}, query) => {
  const { data, refetch, loading, error } = useQuery(
    query || getGroups,
    options
  );

  let groups;
  let pageInfo;

  if (data) {
    groups = data.data.items;
    pageInfo = { total: data.data.total };
  }

  return { groups, pageInfo, refetchGroups: refetch, loading, error };
};

export default useGroups;
