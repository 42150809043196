import ArrayField from './ArrayField';
import BooleanField from './BooleanField';
import DescriptionField from './DescriptionField';
import NumberField from './NumberField';
import ObjectField from './ObjectField';
import SchemaField from './SchemaField';
import StringField from './StringField';
import TitleField from './TitleField';
import UnsupportedField from './UnsupportedField';

const fields = {
  ArrayField,
  BooleanField,
  DescriptionField,
  NumberField,
  ObjectField,
  SchemaField,
  StringField,
  TitleField,
  UnsupportedField,
};

export default fields;
