import React, { lazy, Suspense } from 'react';
import GlobalLoading from './GlobalLoading';
import ErrorBoundary from './ErrorBoundary';

const InnerChart = lazy(() => import('./InnerChart'));

const Chart = props => (
  <ErrorBoundary>
    <Suspense fallback={<GlobalLoading />}>
      <InnerChart {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default Chart;
