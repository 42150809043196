import React from 'react';
import { isMultiSelect } from '../../../../v2/components/Inputs/Select/Select';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { getHelperText } from '../../FilterField';
import SearchSelectInput from '../../../../components/inputs/SearchSelectInput';
import DynamicSelect from '../../../../components/inputs/Select/DynamicSelect';
import EnumSelect from '../../../../components/inputs/Select/EnumSelect';

/**
 * Select component is used in Filters. Data can be provided using `schema.enum` or dynamically with `ui:options.operationId`. Dynamic Select can be disabled in case when any required param is empty.
 */
const SelectFilter = ({
  id,
  uiSchema,
  schema,
  name,
  value,
  onChange,
  onResetFilterValue,
  className,
  InputProps,
}) => {
  const formContext = useFormContext();
  const values = formContext && formContext.watch();
  const errors = formContext && formContext.formState.errors;
  const { params, operationId } = get(uiSchema, 'ui:options', {});

  const multiple = isMultiSelect(schema, uiSchema);
  const title = get(schema, 'title');
  const labelKey = get(uiSchema, 'ui:options.label_key', 'label');
  const valueKey = get(uiSchema, 'ui:options.value_key', 'value');
  const fullWidth = get(uiSchema, 'ui:options.fullwidth', true);
  const error = get(errors, name);
  const helperText = error && getHelperText(error);
  const uiWidget = get(uiSchema, 'ui:widget');

  if (uiWidget && uiWidget === 'search_select') {
    return (
      <SearchSelectInput
        id={id}
        margin="dense"
        fullWidth={fullWidth}
        helperText={helperText}
        className={className}
        onResetFilterValue={onResetFilterValue}
        value={value}
        isClearable={false}
        operationId={operationId}
        onChange={onChange}
        multiple={multiple}
        label={title}
        labelKey={labelKey}
        valueKey={valueKey}
        InputProps={InputProps}
      />
    );
  }

  const selectProps = {
    id,
    margin: 'dense',
    fullWidth,
    helperText,
    className,
    error,
    schema,
    uiSchema,
    multiple,
    labelKey,
    valueKey,
    onResetValue: onResetFilterValue,
    name,
    onChange,
    InputProps,
    value,
    label: title,
  };

  return operationId ? (
    <DynamicSelect
      params={params}
      requestBody={values}
      operationId={operationId}
      SelectProps={selectProps}
    />
  ) : (
    <EnumSelect schema={schema} SelectProps={selectProps} />
  );
};

SelectFilter.propTypes = {
  /**
   * Schema
   */
  schema: PropTypes.shape({
    /**
     * Type
     */
    type: PropTypes.oneOf(['array', 'string']),
    title: PropTypes.string,
    enum: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  /**
   * UI Schema
   */
  uiSchema: PropTypes.shape({
    'ui:options': PropTypes.shape({
      label_key: PropTypes.string,
      value_key: PropTypes.string,
      fullwidth: PropTypes.string,
    }),
  }),
};

SelectFilter.defaultProps = {
  uiSchema: {
    'ui:options': {
      label_key: 'label',
      value_key: 'value',
      fullwidth: true,
    },
  },
  error: false,
};

export default SelectFilter;
