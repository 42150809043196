import makeStyles from '@mui/styles/makeStyles';
import Page from '../../../../../components/Page/Page';

const useStyles = makeStyles({
  templateFormPage: {
    height: '100%',
  },
});

const TemplateFormPage = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Page title={title} extraClass={classes.templateFormPage}>
      {children}
    </Page>
  );
};

export default TemplateFormPage;
