import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef } from 'react';

const useStyles = makeStyles(theme => ({
  badgeField: {
    height: 14,
    textAlign: 'center',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    padding: '0 4px',
    borderRadius: 2,
    fontSize: '9px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey.main,
    color: '#fff',

    maxWidth: '100%',
    lineHeight: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  badgeRed: {
    backgroundColor: theme.palette.error.main,
  },

  badgeGreen: {
    backgroundColor: theme.palette.success.main,
  },

  badgeBlue: {
    backgroundColor: '#32aff5',
  },
}));

const BadgeField = forwardRef(
  ({ red = false, green = false, blue = false, children, ...props }, ref) => {
    const classes = useStyles();

    return (
      <div
        {...props}
        ref={ref}
        className={clsx(classes.badgeField, {
          [classes.badgeRed]: red,
          [classes.badgeGreen]: green,
          [classes.badgeBlue]: blue,
        })}
      >
        {children}
      </div>
    );
  }
);

export default BadgeField;
