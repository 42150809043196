import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import TableInlinePopup from '../../../components/Table/TableInlinePopup';

const useStyles = makeStyles({
  mergingPopupButton: {
    marginLeft: 4,
    whiteSpace: 'nowrap',
    padding: '12px 24px',
  },
  mergingPopupCancelButton: {
    marginLeft: 24,
  },
});

const MergingPopup = ({
  count,
  onButtonClick,
  onInvertedButtonClick,
  onCancelButtonClick,
}) => {
  const classes = useStyles();

  return (
    <TableInlinePopup count={count}>
      <Button
        className={clsx(
          classes.mergingPopupButton,
          classes.mergingPopupCancelButton
        )}
        onClick={onCancelButtonClick}
        color="secondary"
        disableRipple
        variant={'contained'}
      >
        Cancel
      </Button>
      <Button
        className={classes.mergingPopupButton}
        onClick={onButtonClick}
        color="secondary"
        disableRipple
        variant={'contained'}
      >
        Merge events
      </Button>
      <Button
        className={classes.mergingPopupButton}
        onClick={onInvertedButtonClick}
        color="primary"
        disableRipple
        variant={'contained'}
      >
        Merge & invert events
      </Button>
    </TableInlinePopup>
  );
};

MergingPopup.propTypes = {
  count: PropTypes.number,
  onButtonClick: PropTypes.func,
};

export default MergingPopup;
