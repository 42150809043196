import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

const useAutocompleteQuery = (
  gqlQuery,
  params,
  prepareOptions,
  skip,
  gqlQueryOptions,
  ignoreSearchQuery = false
) => {
  const [query, setQuery] = useState('');

  const queryVariables = useMemo(() => {
    return {
      query: ignoreSearchQuery ? null : query,
      ...params,
    };
  }, [query, params, ignoreSearchQuery]);

  const [
    fetch,
    {
      loading,
      data: { data } = {},
      previousData: { data: previousData } = {},
    } = {},
  ] = useLazyQuery(gqlQuery, {
    fetchPolicy: 'no-cache',
    variables: queryVariables,
    ...gqlQueryOptions,
  });

  useEffect(() => {
    if (!skip) fetch();
  }, [skip, fetch]);

  return useMemo(
    () => ({
      loading,
      data: prepareOptions ? prepareOptions(data) : data,
      previousData: prepareOptions
        ? prepareOptions(previousData)
        : previousData,
      setQuery,
    }),
    [loading, data, setQuery, prepareOptions, previousData]
  );
};

export default useAutocompleteQuery;
