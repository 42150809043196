import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TreeSelectProvider from '../../../../../../providers/TreeSelectProvider';
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../components/Notifications/NotificationAlert';
import createCoverageTemplate from '../../../../../gql/sportbook-config/templates/mutations/create/createCoverageTemplate';
import createMarginTemplate from '../../../../../gql/sportbook-config/templates/mutations/create/createMarginTemplate';
import createSourcesTemplate from '../../../../../gql/sportbook-config/templates/mutations/create/createSourcesTemplate';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../providers/ToastsProvider';
import TemplateForm from '../components/TemplateForm';
import TemplateFormPage from '../components/TemplateFormPage';
import { getSubmitMutation } from '../utils/getSubmitMutation';
import { getTemplateInputData } from '../utils/getTemplateInputData';
import { LINE_TYPES } from '../components/TemplateConfigurationField';

const TemplateCreatePage = () => {
  const [createSourcesTemplateCallback, { error: createSourcesError }] =
    useMutation(createSourcesTemplate);
  const [createCoverageTemplateCallback, { error: createCoverageError }] =
    useMutation(createCoverageTemplate);
  const [createMarginTemplateCallback, { error: createMarginError }] =
    useMutation(createMarginTemplate);

  const navigate = useNavigate();
  const { showToast } = useToasts();

  const onTemplateCreate = useCallback(
    formData => {
      const input = getTemplateInputData({ formData });
      const templateType = formData.type;

      const submitMutation = getSubmitMutation(
        templateType,
        createSourcesTemplateCallback,
        createCoverageTemplateCallback,
        createMarginTemplateCallback
      );

      submitMutation({
        variables: {
          input: withoutEmpty(input),
        },
      })
        .then(data => {
          data &&
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={2000}>
                Created template with name: <b>{formData.name}</b>
              </NotificationAlert>
            );
          navigate('/line-settings/template');
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something want wrong
            </NotificationAlert>
          );
        });
    },
    [
      createSourcesTemplateCallback,
      createCoverageTemplateCallback,
      createMarginTemplateCallback,
      showToast,
      navigate,
    ]
  );

  const defaultValues = async () => {
    return {
      name: '',
      type: null,
      lineType: LINE_TYPES.prematch,
      configLineType: LINE_TYPES.prematch,
      version: 1,
      template: {},
    };
  };

  return (
    <TreeSelectProvider>
      <TemplateFormPage title={'Create Template'}>
        <BreadCrumbs
          crumbs={[
            {
              label: 'Line Settings',
              to: '/line-settings/template',
            },
            {
              label: 'New Template',
            },
          ]}
        />
        <TemplateForm
          defaultValues={defaultValues}
          onSubmit={onTemplateCreate}
        />
      </TemplateFormPage>
    </TreeSelectProvider>
  );
};

export default TemplateCreatePage;
