import { gql } from '@apollo/client';
import { CREATE_TEMPLATE_RESULT_TYPE } from './createSourcesTemplate';

const createMarginTemplate = gql`
  ${CREATE_TEMPLATE_RESULT_TYPE}
  mutation createMarginTemplate(
    $parentId: TemplateId
    $input: MarginTemplateInput!
  ) {
    data: createMarginTemplate(templateId: $templateId, input: $input) {
      ...CreateTemplateResultType
    }
  }
`;

export default createMarginTemplate;
