export const passRequirements = {
  charLength: 'At least 8 characters long',
  uppercase: 'Uppercase letter (A-Z)',
  lowercase: 'Lowercase letter (a-z)',
  number: 'Number',
  specialChar: 'Special character',
  required: 'Password is required',
  bothMatch: 'Both passwords match',
  repeat: 'Repeat password',
};
