import React from 'react';
import DateTimeInput from '../../inputs/DateTimeInput';
import {
  getValueForTimePicker,
  getValueFromTimePicker,
} from '../../../utils/datePickerUtils';
import useCorrectDateEffect from '../../../hooks/useCorrectDateEffect';

const DateTimeWidget = ({
  errorSchema,
  options: { help, formatDate },
  onChange,
  value,
  ...otherProps
}) => {
  useCorrectDateEffect(value, onChange);

  return (
    <DateTimeInput
      fullWidth
      variant="outlined"
      margin="dense"
      error={
        !!(errorSchema && errorSchema.__errors && errorSchema.__errors.length)
      }
      helperText={
        (errorSchema && errorSchema.__errors && errorSchema.__errors[0]) ||
        help ||
        ' '
      }
      formatDate={formatDate}
      onChange={(v, e) => onChange(getValueFromTimePicker(v, formatDate), e)}
      value={getValueForTimePicker(value)}
      {...otherProps}
    />
  );
};

export default DateTimeWidget;
