import { useApiGet } from '../../hooks/useApi';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import usePrevious from '../../hooks/usePrevious';
// import 'effector-logger/inspector';
import { createApi, createStore } from 'effector';
import { useStoreMap } from 'effector-react';

const $store = createStore({});

const { setData, setLoading } = createApi($store, {
  setData: (state, { key, data, idList, idKey }) => {
    if (idList && idKey) {
      return {
        ...state,
        [key]: {
          ...state[key],
          items: [
            ...data,
            ...(state[key] && state[key].items
              ? state[key].items.filter(item => {
                  return (
                    !data.find(option => option[idKey] === item[idKey]) &&
                    idList.indexOf(item[idKey]) !== -1
                  );
                })
              : []),
          ],
          loading: false,
          cached: true,
        },
      };
    }

    return {
      ...state,
      [key]: {
        ...state[key],
        items: data,
        loading: false,
        cached: true,
      },
    };
  },
  setLoading: (state, { key, loading }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading,
      // cached: false,
    },
  }),
});

const useAutocompleteData = ({
  dataKey,
  url,
  params,
  idList,
  idKey,
  disabled,
  withoutParams,
  prepareOptions,
  skip,
}) => {
  dataKey = typeof dataKey === 'undefined' ? url : dataKey;

  const {
    items: data = [],
    loading = true,
    cached,
  } = useStoreMap({
    store: $store,
    keys: [dataKey],
    fn: (items, [key]) => items[key] || {},
  });

  const [error, setError] = useState(null);
  const abortController = useRef(null);

  const prevParams = usePrevious(params);

  const getData = useApiGet(url, params);

  const labelsParams = useMemo(() => {
    return {
      id_list: idList,
    };
  }, [idList]);

  const getLabels = useApiGet(url, labelsParams);

  const fetchLabels = useCallback(() => {
    getLabels().then(data => {
      setData({
        key: dataKey,
        data: data.items,
      });
    });
  }, [getLabels, dataKey]);

  useEffect(() => {
    if (
      labelsParams.id_list &&
      labelsParams.id_list.length &&
      !data.length &&
      !loading
    ) {
      fetchLabels();
    }
  }, [fetchLabels, labelsParams, data, loading]);

  const fetchItems = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    setLoading({ key: dataKey, loading: true });

    getData({}, abortController.current)
      .then(data => {
        if (data) {
          if (idList && idKey) {
            setData({
              key: dataKey,
              data: prepareOptions ? prepareOptions(data) : data.items,
              idList,
              idKey,
            });
          } else {
            setData({
              key: dataKey,
              data: prepareOptions ? prepareOptions(data) : data.items,
            });
          }
        }
      })
      .catch(e => {
        setError(e);
      })
      .finally(() => {
        setLoading({ key: dataKey, loading: false });
        abortController.current = null;
      });
  }, [getData, prepareOptions, idKey, idList, dataKey]);

  useEffect(() => {
    if (
      !skip &&
      !disabled &&
      (withoutParams ||
        (!params && cached) ||
        (params &&
          params.query !== '' &&
          JSON.stringify(params) !== JSON.stringify(prevParams)))
    ) {
      fetchItems();
    }
  }, [
    dataKey,
    cached,
    params,
    fetchItems,
    prevParams,
    disabled,
    withoutParams,
    skip,
  ]);

  return useMemo(
    () => ({ data, fetchItems, loading, error }),
    [data, fetchItems, loading, error]
  );
};

export default useAutocompleteData;
