import React, { useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import InlineDrugDropMenuContainer from '../../../Table/InlineDrugDropMenuContainer';
import SelectMenu from './SelectMenu';

export const reorderDragItemsData = ({ startIndex, endIndex, options }) => {
  if (startIndex === endIndex) {
    return options;
  }
  const result = structuredClone(options);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((value, index) => ({
    ...value,
    priority: index + 1,
  }));
};

const DraggableSelectMenu = ({
  options,
  setDisplayOptions = () => {},
  children,
  withContainer = false,
  afterDrop = () => {},
  onDragStart = () => {},
}) => {
  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      const reorderedOptions = reorderDragItemsData({
        startIndex,
        endIndex,
        options,
      });

      setDisplayOptions(reorderedOptions);
      afterDrop({ startIndex, endIndex, reorderedOptions });
    },
    [setDisplayOptions, afterDrop, options]
  );

  const Container = withContainer ? SelectMenu : React.Fragment;

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onDragStart}>
        <Droppable droppableId="droppable" direction="vertical" mode="virtual">
          {provided => {
            return (
              <InlineDrugDropMenuContainer
                keepMounted={false}
                ref={provided.innerRef}
                style={{ height: '100%' }}
                {...provided.droppableProps}
              >
                {children}
              </InlineDrugDropMenuContainer>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default DraggableSelectMenu;
