import React, { useCallback, useMemo, useRef, useState } from 'react';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DatePicker from '../DatePicker/DatePicker';
import { format, isValid, parse } from 'date-fns';
import DatePickerAdornment from '../DatePicker/DatePickerAdornment';
import PickerInput from './PickerInput';

const useStyles = makeStyles({
  calendarPopper: {
    zIndex: 10000,
  },

  calendarPaper: {
    padding: '16px 16px 24px',
    overflow: 'hidden',
  },
});

const DATETIME_DISPLAY_FORMAT = 'dd.MM.yyyy, HH:mm';
const DATE_DISPLAY_FORMAT = 'dd.MM.yyyy';

const DateTimeInputInner = ({
  id,
  value,
  onChange,
  className,
  disabled,
  label,
  error,
  fullWidth,
  helperText,
  InputProps,
  dateOnly,
  withStartAdornment = false,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const anchorElRef = useRef(null);
  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  const displayFormat = dateOnly
    ? DATE_DISPLAY_FORMAT
    : DATETIME_DISPLAY_FORMAT;

  const displayValue = isValid(value) ? format(value, displayFormat) : '';

  const handleChangeCustomValue = useCallback(
    customValue => {
      if (customValue) {
        return onChange(parse(customValue.trim(), displayFormat, new Date()));
      } else {
        onChange(null);
      }
    },
    [displayFormat, onChange]
  );

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, [setFocused]);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  const datePickerAdornment = (
    <DatePickerAdornment onClick={() => setIsOpen(true)} disabled={disabled} />
  );

  return (
    <>
      <PickerInput
        id={id}
        ref={anchorElRef}
        onOpen={setIsOpen}
        displayValue={displayValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onBlurInput={handleChangeCustomValue}
        InputProps={{
          ...InputProps,
          [withStartAdornment ? 'startAdornment' : 'endAdornment']:
            InputProps && InputProps.endAdornment ? (
              <>
                {datePickerAdornment}
                {InputProps.endAdornment}
              </>
            ) : (
              datePickerAdornment
            ),
        }}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
            marginLeft:
              withStartAdornment && !isFocused && !displayValue ? 30 : 0,
          },
          shrink: isFocused || displayValue,
        }}
        className={className}
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        label={label}
        disabled={disabled}
      />

      <Popper
        className={classes.calendarPopper}
        open={isOpen}
        anchorEl={anchorElRef.current}
        placement={'bottom'}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
              },
            },
          ],
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.calendarPaper} elevation={2}>
            <DatePicker
              dateOnly={dateOnly}
              value={value}
              onChange={date => {
                onChange(date);
                setIsOpen(false);
              }}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const DateTimeInput = ({ formatDate, value, onChange, ...dateTimeProps }) => {
  const dateObj = useMemo(() => {
    const date = new Date(value);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, [value]);

  return (
    <DateTimeInputInner
      {...dateTimeProps}
      dateOnly={
        !formatDate ||
        formatDate.endsWith('%Y') ||
        formatDate.endsWith('%m') ||
        formatDate.endsWith('%d')
      }
      onChange={(v, e) => {
        if (v) {
          v.setSeconds(0);
          v.setMilliseconds(0);
        }
        onChange(v, e);
      }}
      value={value ? dateObj : value}
    />
  );
};

export default DateTimeInput;
