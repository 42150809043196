import { useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';
import { useCallback, useContext, useRef } from 'react';
import { ConfirmationPromptContext } from '../../../../../providers/ConfirmationPromptProvider';
import { useToasts } from '../../../../providers/ToastsProvider';
import Button from '../../../Button/Button';
import NotificationAlert, {
  AlertVariant,
} from '../../../Notifications/NotificationAlert';
import useTreeSelectStyles from '../styles/TreeSelect.styles';
import SaveModalContent from './SaveModalContent';

const SavePresetButton = ({
  templateType,
  onSave = () => {},
  getPresetNamesQuery,
  sportId,
}) => {
  const classes = useTreeSelectStyles();
  const client = useApolloClient();
  const { showToast } = useToasts();

  const presetName = useRef('');

  const { openConfirmation, setSubmitDisabled } = useContext(
    ConfirmationPromptContext
  );

  const onNameChange = useCallback(
    (e, names) => {
      presetName.current = e.target.value;
      setSubmitDisabled(
        !presetName.current || names.includes(presetName.current)
      );
    },
    [presetName, setSubmitDisabled]
  );

  const getPresetNames = useCallback(async () => {
    return client.query({
      query: getPresetNamesQuery,
      fetchPolicy: 'no-cache',
      variables: {
        sportId,
      },
    });
  }, [client, getPresetNamesQuery, sportId]);

  const onSavePresetClick = useCallback(() => {
    setSubmitDisabled(true);
    getPresetNames()
      .then(data => {
        const nameId = data.data.data;
        const names = nameId.map(value => value.name);

        openConfirmation({
          buttonLabel: 'Save',
          title: `Save ${templateType} preset`,
          message: (
            <SaveModalContent
              onNameChange={e => {
                onNameChange(e, names);
              }}
              names={names}
            />
          ),
          callback: async () => {
            onSave(presetName.current);
          },
        });
      })
      .catch(() => {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can't fetch preset names
          </NotificationAlert>
        );
      });
  }, [
    openConfirmation,
    templateType,
    onSave,
    showToast,
    presetName,
    getPresetNames,
    onNameChange,
    setSubmitDisabled,
  ]);

  return (
    <Box className={classes.savePresetField}>
      <Button className={classes.savePresetButton} onClick={onSavePresetClick}>
        Save preset
      </Button>
    </Box>
  );
};

export default SavePresetButton;
