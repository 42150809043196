import styles from './index.styles';
import { Stack } from '@mui/material';
import Select from '../../../../components/Inputs/Select/Select';
import TextField from '../../../../components/Inputs/TextField';
import RemoveIcon from '../../../../icons/RemoveIcon';
import Button from '../../../../components/Button/Button';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const TranslationsList = ({
  languages,
  isExpanded,
  setExpanded,
  langs,
  name,
}) => {
  const { watch, setValue } = useFormContext();

  const data = watch(name);

  const classes = styles();

  const renderActions = langs => {
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'24px'}
      >
        {isExpanded && langs.length > 1 ? (
          <Button
            className={classes.actionButton}
            startIcon={<KeyboardArrowUp />}
            variant={'text'}
            disabled={langs.length < 1}
            onClick={() => {
              setExpanded(false);
            }}
          >
            Hide all languages ({langs.length})
          </Button>
        ) : (
          <Button
            className={classes.actionButton}
            startIcon={<KeyboardArrowDown />}
            variant={'text'}
            disabled={langs.length <= 1}
            onClick={() => {
              setExpanded(true);
            }}
          >
            Show all languages ({langs.length})
          </Button>
        )}
        <Button
          onClick={() => {
            setValue(`${name}.langs`, [
              ...data.langs,
              { language: '', translation: '' },
            ]);
            setExpanded(true);
          }}
          className={classes.actionButton}
          startIcon={<Add />}
          variant={'text'}
        >
          Add language
        </Button>
      </Stack>
    );
  };

  const renderRow = (field, index) => {
    const { language, translation } = field;
    const removable = true;

    return (
      <Stack
        gap={'4px'}
        direction={'row'}
        className={classes.languageRow}
        alignItems={'center'}
        key={field.language + index}
      >
        <Stack
          gap={'4px'}
          direction={'row'}
          className={classes.languageFieldsRow}
        >
          <Select
            disabledOptions={langs.map(l => l.language)}
            label={'Language'}
            options={languages?.map(l => ({
              label: l.name,
              value: l.name,
            }))}
            disabled={languages?.length === 1 || index === 0 || !removable}
            onChange={value => {
              setValue(
                `${name}.langs`,
                data.langs.map((l, i) => {
                  if (i === index) {
                    return { ...l, language: value };
                  } else {
                    return l;
                  }
                })
              );
            }}
            value={language}
          />
          <TextField
            label={'Translation'}
            value={translation}
            onChange={e => {
              const value = e.target.value;
              setValue(
                `${name}.langs`,
                data.langs.map((l, i) => {
                  if (i === index) {
                    return { ...l, translation: value };
                  } else {
                    return l;
                  }
                })
              );
            }}
          />
        </Stack>
        {removable && index !== 0 && (
          <RemoveIcon
            className={classes.removeButton}
            onClick={() => {
              setValue(
                `${name}.langs`,
                data.langs.filter((l, i) => i !== index)
              );
            }}
          />
        )}
      </Stack>
    );
  };

  return (
    <Stack gap={'15px'}>
      {data.langs.filter((el, index) => isExpanded || index < 1).map(renderRow)}
      {renderActions(langs)}
    </Stack>
  );
};

export default TranslationsList;
