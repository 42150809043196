import { useData } from '../providers/DataProvider';

const useTableSummary = () => {
  const dataContext = useData();
  const { data } = dataContext;

  return data && data.results;
};

export default useTableSummary;
