import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const RefreshIcon = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49951 9.0293L6.4997 9.02927L6.4997 6.02927"
        stroke="#1581FF"
        strokeLinecap="round"
      />
      <path
        d="M6.84184 8.93476C7.52817 7.77986 8.58224 6.88871 9.83521 6.40405C11.0882 5.91939 12.4676 5.86926 13.7524 6.26169C15.0373 6.65412 16.1532 7.4664 16.9216 8.56843C17.69 9.67047 18.0662 10.9985 17.9901 12.3398C17.9141 13.6811 17.39 14.958 16.502 15.9661C15.614 16.9742 14.4133 17.6551 13.0923 17.8997C11.7713 18.1443 10.4065 17.9385 9.21634 17.3153C8.0262 16.6921 7.07966 15.6874 6.52832 14.4623"
        stroke="#1581FF"
        fill="none"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default RefreshIcon;
