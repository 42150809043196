import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import getQuery from '../utils/getQuery';
import pickWithoutUndefined from '../utils/pickWithoutUndefined';
import { LocalQueryContext } from '../providers/LocalQueryProvider';
import useSearch from './useSearch';
import omit from 'lodash/omit';
import useQuery from '../v2/hooks/useQuery';

export const HistoryMethodEnum = {
  PUSH: 'push',
  REPLACE: 'replace',
};

const useQueryByPath = (path, fallbackValue) => {
  const query = useQuery();
  const search = useSearch();

  const navigate = useNavigate();

  const queryByPath = useMemo(() => {
    return (
      JSON.parse(query.get('jsonQuery') || '{}')[path] || fallbackValue || {}
    );
  }, [query, fallbackValue, path]);

  const { localQuery, setLocalQuery } = useContext(LocalQueryContext);

  const setQuery = useCallback(
    (newQuery, method = HistoryMethodEnum.REPLACE, hash) => {
      const fullQuery = JSON.parse(query.get('jsonQuery') || '{}');

      query.set(
        'jsonQuery',
        JSON.stringify({
          ...fullQuery,
          [path]: pickWithoutUndefined({
            ...newQuery,
          }),
          hash,
        })
      );

      navigate(
        {
          search: query.toString(),
        },
        {
          replace: method === HistoryMethodEnum.REPLACE,
        }
      );
    },
    [query, navigate, path]
  );

  const clearQuery = useCallback(() => {
    navigate({
      search: omit(getQuery(search), path),
    });
  }, [navigate, search, path]);

  let resultQuery = queryByPath;
  let resultSetQuery = setQuery;

  if (!path) {
    resultQuery = localQuery;
    resultSetQuery = setLocalQuery;
  }

  return useMemo(() => {
    return {
      query: resultQuery,
      setQuery: resultSetQuery,
      clearQuery,
    };
  }, [resultQuery, resultSetQuery, clearQuery]);
};

export default useQueryByPath;
