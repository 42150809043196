import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { UiOptionsContext } from './UiOptionsProvider';
import ExtendableField from './ExtendableField';
import usePaletteColor, { ColorEnum } from '../../hooks/usePaletteColor';
import FormattedValueText from './FormattedValueText';

const BadgeTextColorMap = {
  [ColorEnum.RED]: '#fff',
  [ColorEnum.GREEN]: '#fff',
  [ColorEnum.BLUE]: '#fff',
  [ColorEnum.ORANGE]: '#fff',
  [ColorEnum.CYAN]: '#fff',
  [ColorEnum.GREY]: '#fff',
};

const useStyles = makeStyles({
  badgeField: {
    height: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0 4px',
    borderRadius: 2,
    fontSize: '9px',
    fontWeight: 'bold',
    lineHeight: '12px',
  },
});

const BadgeField = () => {
  const { value } = useContext(UiOptionsContext);
  const color = value.color || ColorEnum.GREY;
  const classes = useStyles();

  const textColor = BadgeTextColorMap[color] || '#fff';
  const backgroundColor = usePaletteColor(color);

  return (
    <ExtendableField>
      <div
        className={classes.badgeField}
        style={{
          color: textColor,
          backgroundColor: backgroundColor,
        }}
      >
        <FormattedValueText color={textColor} />
      </div>
    </ExtendableField>
  );
};

export default BadgeField;
