import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  createTabButton: {
    backgroundColor: '#fff',
    borderRadius: 6,
    marginRight: 2,
    border: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
    width: 36,
    height: 36,
    '&:hover': {
      backgroundColor: theme.palette.blue[200],
    },
  },
}));

const CreateTabButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <button type="button" onClick={onClick} className={classes.createTabButton}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7V17"
          stroke="#1581FF"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17 12L7 12"
          stroke="#1581FF"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export default CreateTabButton;
