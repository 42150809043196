import { isEmpty, isEqual, omitBy, pick, remove } from 'lodash';
import { useTreeSelectContext } from '../../../../../../../../providers/TreeSelectProvider';

export const useMarketsReorder = marketType => {
  const { data } = useTreeSelectContext();
  const pathKeysForInherit = {
    sport: ['sportId'],
    category: ['sportId', 'categoryId'],
    tournament: ['sportId', 'tournamentId'],
    event: ['sportId', 'tournamentId', 'eventId'],
  };

  const clearSelector = selector =>
    omitBy(selector, (value, key) => !value || key === 'market');

  const getSelectedTournamentCategoryId = (path, lineType) => {
    const selectedTournament =
      data[lineType].tournaments.sport.find(
        dataValue => dataValue.tournamentId === path.tournamentId
      ) || {};
    return selectedTournament.categoryId;
  };

  const pickMarketConfigPredicate = (value, pathForCheck, lineType) => {
    const configPath = clearSelector(value.nodeSelector);

    return value.lineType === lineType && isEqual(configPath, pathForCheck);
  };

  const getInheritMarketTypeForTournament = ({
    path,
    marketsConfigValues,
    lineType,
  }) => {
    const categoryId = getSelectedTournamentCategoryId(path, lineType);

    const categoryChangesCheckPredicate = value =>
      value.lineType == lineType &&
      !value.nodeSelector.tournamentId &&
      !value.nodeSelector.eventId &&
      value.nodeSelector.categoryId === categoryId;
    const isParentCategoryMarketReordered = marketsConfigValues.some(
      categoryChangesCheckPredicate
    );
    return isParentCategoryMarketReordered ? 'category' : 'sport';
  };

  const getInheritMarketTypeForEvent = ({
    path,
    marketsConfigValues,
    lineType,
  }) => {
    const tournamentChangesCheckPredicate = value =>
      value.lineType === lineType &&
      value.nodeSelector.tournamentId === path.tournamentId;
    const isParentTournamentReordered = marketsConfigValues.some(
      tournamentChangesCheckPredicate
    );

    const marketTypeForInherit = isParentTournamentReordered
      ? 'tournament'
      : getInheritMarketTypeForTournament({
          path,
          marketsConfigValues,
          lineType,
        });

    return marketTypeForInherit;
  };

  // we need to inherit from closest marketType markets that have changes. With the following sequence ['event', 'tournament', 'category', 'sport']
  const getMarketTypeForInherit = ({ marketsConfigValues, path, lineType }) => {
    const pathForNodeSelector = pick(path, pathKeysForInherit[marketType]);

    const isCurrentMarketsConfigured = marketsConfigValues.some(value =>
      pickMarketConfigPredicate(value, pathForNodeSelector, lineType)
    );

    if (isCurrentMarketsConfigured) {
      return null;
    }

    switch (marketType) {
      case 'category': {
        return 'sport';
      }
      case 'tournament': {
        return getInheritMarketTypeForTournament({
          path,
          marketsConfigValues,
          lineType,
        });
      }
      case 'event': {
        return getInheritMarketTypeForEvent({
          path,
          marketsConfigValues,
          lineType,
        });
      }
      default: {
        return null;
      }
    }
  };

  const moveItem = (newValues, option, newIndex, searchedMarketId) => {
    remove(newValues, value => value.marketId === searchedMarketId);
    newValues.splice(newIndex, 0, option);
  };

  const inheritOrder = ({ options, template, lineType, path }) => {
    const reorderedValues = structuredClone(options);

    const configValues = Object.values(template);
    const marketsConfigValues = configValues.filter(
      value => value.nodeSelector.market
    );

    const marketTypeForInherit = getMarketTypeForInherit({
      marketsConfigValues,
      path,
      lineType,
    });

    if (marketTypeForInherit === null) {
      return;
    }

    const pathForInheritConfigs = pick(
      path,
      pathKeysForInherit[marketTypeForInherit]
    );

    const configsForInherit = marketsConfigValues.filter(value =>
      pickMarketConfigPredicate(value, pathForInheritConfigs, lineType)
    );

    if (isEmpty(configsForInherit)) {
      return;
    }

    options.forEach(option => {
      const config = configsForInherit.find(
        configValue =>
          configValue.nodeSelector.market.marketId === option.marketId
      );

      if (config && config.priority) {
        moveItem(reorderedValues, option, config.priority - 1, option.marketId);
      }
    });

    options.length = 0;
    options.push(...reorderedValues);
  };

  return {
    moveItem,
    inheritOrder,
  };
};
