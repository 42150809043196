import { makeStyles } from '@mui/styles';

export const useSourcesStyles = makeStyles({
  sourcesEventTitleContainer: {
    marginLeft: '20px',
  },
  sourcesOptionName: {
    maxWidth: '275px',
  },
  sourcesMarketOptionName: {
    maxWidth: '300px',
  },
  sourcesOptionCaption: {
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: '275px',
  },
});

export default useSourcesStyles;
