import { format, fromUnixTime, addMinutes } from 'date-fns';

export const convertUnixTimeToUtc = timestamp => {
  const date = fromUnixTime(timestamp);
  return format(addMinutes(date, date.getTimezoneOffset()), 'dd.MM.yyyy, H:mm');
};

export const getUtcDate = () => {
  const localDate = new Date();
  localDate.setMilliseconds(0);
  return new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
};
