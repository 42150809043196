import { gql } from '@apollo/client';

const revertChanges = gql`
  mutation revertChanges(
    $changeLogId: ChangeLogId!
    $LineSettingsId: LineSettingsId!
  ) {
    data: revertChanges(
      changeLogId: $changeLogId
      LineSettingsId: $LineSettingsId
    )
  }
`;

export default revertChanges;
