import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const LoadingTextPlaceholder = ({ width = 100 }) => {
  const randomWidth = Math.random() * width + width / 2;

  return <Skeleton style={{ width: randomWidth }}>&zwnj;</Skeleton>;
};

export default LoadingTextPlaceholder;
