import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import ExpandButton from '../../../Button/ExpandButton';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const ExpandableOrderedList = ({
  selected,
  disabled = false,
  isMenuOpen,
  toggleMenu = () => {},
  items,
}) => {
  const classes = useTreeSelectStyles();

  return (
    <Box
      className={clsx(classes.orderedList, {
        [classes.expandListDisabled]: disabled,
      })}
      onClick={() => toggleMenu(currState => !currState)}
    >
      <ExpandButton
        white={selected}
        expanded={isMenuOpen}
        extraClasses={[
          classes.sourcesListExpandButton,
          { [classes.expandedButtonDisabled]: disabled },
        ]}
      />
      {items.join(', ')}
    </Box>
  );
};

export default ExpandableOrderedList;
