import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

const useImperativeQuery = (query, options) => {
  const { refetch, loading, error } = useQuery(query, {
    skip: true,
    ...options,
  });

  const imperativelyCallQuery = useCallback(
    variables => {
      return refetch(variables);
    },
    [refetch]
  );

  return useMemo(
    () => ({
      fetch: imperativelyCallQuery,
      loading,
      error,
    }),
    [imperativelyCallQuery, loading, error]
  );
};

export default useImperativeQuery;
