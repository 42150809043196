import Box from '@mui/material/Box';

const LineBannerButtonPreview = ({
  fields: [banner_title, buttonText, buttonColor],
}) => {
  const buttonBackground = buttonColor || '#1581FF';

  return (
    <Box
      sx={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '12px',
        fontFamily: 'IBM Plex Sans',
        width: '360px',
        height: '152px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
        color: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          maxWidth: 200,
          overflowWrap: 'anywhere',
        }}
      >
        {banner_title}
      </Box>
      <Box
        sx={{
          backgroundColor: buttonBackground,
          color: '#fff',
          fontSize: '12px',
          lineHeight: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          letterSpacing: '0.03em',
          textTransform: 'uppercase',
          minWidth: 124,
          height: 32,
          borderRadius: '40px',
          cursor: 'pointer',
          marginTop: '20px',
        }}
      >
        {buttonText}
      </Box>
    </Box>
  );
};

export default LineBannerButtonPreview;
