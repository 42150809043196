import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '../../components/Table/Table';
import StringField from '../../components/Fields/StringField/StringField';
import Checkbox from '../../components/Inputs/Checkbox';
import SportIcon from '../../icons/SportIcon/SportIcon';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';

const useStyles = makeStyles({
  createButton: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  emptyId: {
    background: '#031224',
    height: 1,
    width: 124,
  },
  sourcesTootlip: {
    display: 'inline',
  },
  highlightedSource: {
    textDecoration: 'underline',
  },
  sport: {
    display: 'flex',
    alignItems: 'center',
  },
  sportIcon: {
    marginRight: 4,
    backgroundSize: 16,
    minWidth: 18,
  },
  invertedIcon: {
    backgroundColor: '#8496AB',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
  },
  invertedActiveIcon: {
    backgroundColor: '#08CA6D',
  },
});

const COLUMNS = {
  SPORT: 'Sport',
  AFFILIATION: 'Affiliation',
  SOURCE: 'Source',
  AUTOCREATE: 'Auto Create',
};

const initialToggledColumns = [
  COLUMNS.SPORT,
  COLUMNS.AFFILIATION,
  COLUMNS.SOURCE,
  COLUMNS.AUTOCREATE,
];

const columnsOrderConfig = [
  COLUMNS.SPORT,
  COLUMNS.AFFILIATION,
  COLUMNS.SOURCE,
  COLUMNS.AUTOCREATE,
];

const columnsWidthConfig = {
  [COLUMNS.SPORT]: 0.2,
  [COLUMNS.AFFILIATION]: 0.4,
  [COLUMNS.SOURCE]: 0.2,
  [COLUMNS.AUTOCREATE]: 0.2,
};

const AutoCreatedTournamentsTable = ({
  loading,
  items,
  onSelectTournament,
}) => {
  const classes = useStyles();
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const handleCheckboxChange = (checked, data) => {
    onSelectTournament(prevState =>
      prevState.map(t => {
        if (t.tournament.id === data.tournament.id) {
          return { ...t, autocreatable: checked };
        } else {
          return t;
        }
      })
    );
  };

  const columns = [
    {
      label: COLUMNS.SPORT,
      key: COLUMNS.SPORT,
      render: data => {
        return (
          <StringField className={classes.sport}>
            <SportIcon
              name={data.sport.id}
              className={classes.sportIcon}
              black
            />
            {data.sport.name}
          </StringField>
        );
      },
    },
    {
      label: COLUMNS.AFFILIATION,
      key: COLUMNS.AFFILIATION,
      render: data => {
        return (
          <StringField>
            {data.category.langs.en}, {data.tournament.langs.en}
          </StringField>
        );
      },
    },
    {
      label: COLUMNS.SOURCE,
      key: COLUMNS.SOURCE,
      render: data => {
        return <StringField>{data.provider}</StringField>;
      },
    },
    {
      label: COLUMNS.AUTOCREATE,
      key: COLUMNS.AUTOCREATE,
      justifyContent: 'flex-end',
      labelStyle: {
        justifyContent: 'flex-end',
      },
      render: data => {
        return (
          <Checkbox
            value={data.autocreatable}
            onChange={checked => {
              handleCheckboxChange(checked, data);
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      hideConfigMenu={true}
      initialToggledColumns={initialToggledColumns}
      columnsWidth={columnsWidthConfig}
      loading={loading}
      data={items}
      columns={columns}
      scrollable={false}
      order={order}
      configMenu={false}
      onReorder={onReorder}
      rowActions={false}
    />
  );
};

AutoCreatedTournamentsTable.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  onSelectTournament: PropTypes.func,
};

export default AutoCreatedTournamentsTable;
