import clsx from 'clsx';
import { memo } from 'react';

const TableHeader = ({ style, children }) => {
  return (
    <div
      className={clsx('tableHeaderRow')}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default memo(TableHeader);
