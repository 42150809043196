import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

class ToolsPanel extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h6">Tools Panel</Typography>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    padding: 30,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
  },
});

export default withStyles(styles)(ToolsPanel);
