import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CheckBoxActiveIcon from '../../../icons/controls/CheckBoxActiveIcon';
import CheckBoxIcon from '../../../icons/controls/CheckBoxIcon';
import CheckboxIndeterminateIcon from '../../../icons/controls/CheckboxIndeterminateIcon';

function selectValue(value, selected, all) {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value, selected) {
  return selected.filter(v => v !== value);
}

function CheckboxesWidget(props) {
  const {
    classes,
    disabled,
    options,
    value,
    autofocus,
    readonly,
    onChange,
    schema,
  } = props;
  const { enumOptions } = options;

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{schema.items.title}</FormLabel>
      <FormGroup>
        {enumOptions.map((option, index) => {
          const checked = value.indexOf(option.value) !== -1;
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  icon={<CheckBoxIcon />}
                  checkedIcon={<CheckBoxActiveIcon />}
                  indeterminateIcon={<CheckboxIndeterminateIcon />}
                  checked={checked}
                  disabled={disabled || readonly}
                  autoFocus={autofocus && index === 0}
                  onChange={event => {
                    const all = enumOptions.map(({ value }) => value);
                    if (event.target.checked) {
                      onChange(selectValue(option.value, value, all));
                    } else {
                      onChange(deselectValue(option.value, value));
                    }
                  }}
                />
              }
              label={option.label}
            />
          );
        })}
      </FormGroup>
      <FormHelperText>{options.help}</FormHelperText>
    </FormControl>
  );
}

CheckboxesWidget.defaultProps = {
  autofocus: false,
  options: {
    inline: false,
  },
};

CheckboxesWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
};

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(CheckboxesWidget);
