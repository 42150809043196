import { gql } from '@apollo/client';

const getTemplatesTable = gql`
  query getTemplatesTable($filters: TemplateTableFilter!) {
    data: getTemplatesTable(filters: $filters) {
      items {
        id
        name
        childTemplates
        type
        brandsCount
        # brandIds
        updatedBy
        createdBy
        created
        updated
        version
        parent_id
      }
      total
    }
  }
`;

export default getTemplatesTable;
