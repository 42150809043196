import { RSAA } from 'redux-api-middleware';
import { SCHEMA_REQUEST } from '../constants/appConstants';
import { getUsers } from '../reducers/users';
import { getActiveSchemaUrl } from '../reducers/schemaUrls';

const authApiInjector =
  ({ getState }) =>
  next =>
  action => {
    const callApi = action[RSAA];

    if (callApi) {
      const state = getState();
      const { type, meta } = callApi.types[0];
      const schemaUrl =
        type === SCHEMA_REQUEST ? meta.schemaUrl : getActiveSchemaUrl(state);
      const user = getUsers(state)[schemaUrl];
      const token = user && (user.refreshedToken || user.token);

      if (token) {
        callApi.headers = {
          ...callApi.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }

    return next(action);
  };

export default authApiInjector;
