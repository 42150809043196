import * as React from 'react';
import { useContext } from 'react';

export const NestedIndexesContext = React.createContext({});

const NestedIndexesProvider = ({ children, indexes = {} }) => {
  const parentIndexes = useContext(NestedIndexesContext);

  return (
    <NestedIndexesContext.Provider
      value={{
        ...parentIndexes,
        ...indexes,
      }}
    >
      {children}
    </NestedIndexesContext.Provider>
  );
};

export default NestedIndexesProvider;
