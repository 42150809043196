import { TableCell } from '@mui/material';
import React from 'react';

const InlineTableDropdownHead = ({ columns = [] }) => {
  return columns.map((column, index) => {
    return (
      <TableCell
        align={column.align}
        key={index}
        style={{
          ...column.css,
        }}
      >
        {column.title}
      </TableCell>
    );
  });
};

export default InlineTableDropdownHead;
