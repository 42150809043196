import { isEmpty, omit } from 'lodash';

const clearCommands = ({ commands }) => {
  return commands.reduce((accumulator, currentValue) => {
    if (!('isFormData' in currentValue) || currentValue.isFormData) {
      if ('groups' in currentValue && currentValue.groups) {
        // const groupsConfig = clearCommands({ commands: command.groups });

        currentValue.groups = currentValue.groups
          .filter(value => value.status)
          .map(value => value.group);
      }
      if ('ignoreStatus' in currentValue && currentValue.ignoreStatus) {
        currentValue = omit(currentValue, 'status');
      }
      return [
        ...accumulator,
        omit(currentValue, ['isFormData', 'ignoreStatus']),
      ];
    }
    return accumulator;
  }, []);
};

export const getTemplateInputData = ({ formData }) => {
  const template = Object.values(formData.template).flat();
  const formDataCommands = clearCommands({ commands: template });

  return isEmpty(formDataCommands)
    ? {
        parentId: formData.parentId,
        name: formData.name,
        type: formData.type,
        version: formData.version,
        prematchSameAsLiveFrom: formData.switchedFrom,
      }
    : {
        parentId: formData.parentId,
        name: formData.name,
        type: formData.type,
        template: formDataCommands,
        version: formData.version,
        prematchSameAsLiveFrom: formData.switchedFrom,
      };
};
