import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TableHead from './TableHead';
import Portal from '@mui/material/Portal';
import { useStickyHeaderContainer } from '../../providers/StickyHeaderProvider';
import { isEqual } from 'lodash';

const useHeaderIntersectionObserver = onEntry => {
  return new IntersectionObserver(
    entries => {
      const [entry] = entries;

      onEntry(entry);
    },
    {
      rootMargin: '-94px 0px 0px 0px',
    }
  );
};

const useScrollableIntersectionObserver = onEntry => {
  return new IntersectionObserver(
    entries => {
      const [entry] = entries;

      onEntry(entry);
    },
    {
      root: null,
      rootMargin: '-100px 0px 0px 0px',
      threshold: 0,
    }
  );
};

const useStickyHeaderRef = (
  stickyHeader,
  stickyHeaderContainer,
  scrollable,
  containerBackground,
  inactive
) => {
  const ref = useRef(null);

  const isStuckRef = useRef(false);

  const resizeObserver = new ResizeObserver(() => {
    setTimeout(() => {
      const { left } = scrollable.getBoundingClientRect();

      if (stickyHeaderContainer) {
        stickyHeaderContainer.style.left = `${left}px`;
      }
    }, 300);
  });

  const hideHeader = useCallback(() => {
    if (stickyHeader) {
      stickyHeader.style.opacity = 0;
      stickyHeader.style.visibility = 'hidden';
      stickyHeader.style.boxShadow = 'none';
      containerBackground.style.visibility = 'hidden';

      stickyHeaderContainer.style.display = 'none';

      isStuckRef.current = false;
    }
  }, [stickyHeader, containerBackground, stickyHeaderContainer]);

  const showHeader = () => {
    if (stickyHeader) {
      containerBackground.style.visibility = 'visible';
      stickyHeader.style.opacity = 1;
      stickyHeader.style.visibility = 'visible';
      stickyHeader.style.boxShadow = '0px 2px 4px rgba(132, 150, 171, 0.4)';

      stickyHeaderContainer.style.display = 'block';

      isStuckRef.current = true;
    }
  };
  const observer = useHeaderIntersectionObserver(entry => {
    if (stickyHeader && !inactive) {
      if (entry.isIntersecting || entry.boundingClientRect.top > 60) {
        hideHeader();
      } else {
        showHeader();
      }
    }
  });

  useEffect(() => {
    if (inactive) {
      hideHeader();
    }
  }, [inactive, hideHeader]);

  useEffect(() => {
    return () => {
      if (stickyHeader) hideHeader();
    };
  }, [stickyHeader, hideHeader]);

  const scrollableObserver = useScrollableIntersectionObserver(entry => {
    if (stickyHeader && !inactive) {
      if (entry.isIntersecting) {
        if (ref.current && ref.current.getBoundingClientRect().top <= 48) {
          showHeader();
        }

        return;
      }
      if (entry.boundingClientRect.top <= 0) {
        hideHeader();
      }
    }
  });

  let isScrolling;

  const handleScroll = () => {
    if (inactive) {
      return;
    }

    if (!isStuckRef.current) {
      ref.current.style.opacity = 1;
      translateHead(ref.current, 0);
      window.clearTimeout(isScrolling);

      return;
    }

    ref.current.style.opacity = 0;

    if (!isScrolling) {
      freezeHead();
    } else {
      window.clearTimeout(isScrolling);
    }

    isScrolling = setTimeout(function () {
      isScrolling = null;
      ref.current.style.opacity = 1;
      freezeHead();

      translateHead(
        ref.current,
        parseInt(-ref.current.parentNode.getBoundingClientRect().top + 44)
      );
    }, 100);
  };

  const freezeHead = () => {
    const ths = ref.current.getElementsByTagName('th');
    const frozenThs = stickyHeader.getElementsByTagName('th');

    for (let i = 0; i < ths.length; i++) {
      frozenThs[i].style.minWidth = `${ths[i].getBoundingClientRect().width}px`;
    }

    if (scrollable && stickyHeader.parentNode) {
      stickyHeader.parentNode.scrollLeft = scrollable.scrollLeft;
      stickyHeader.parentNode.style.width = `${
        scrollable.getBoundingClientRect().width
      }px`;
    }
  };

  const translateHead = (node, y) => {
    const ths = node.getElementsByTagName('th');

    for (let element of ths) {
      element.style.transform = `translateY(${y}px)`;
    }
  };

  if (inactive && ref.current) {
    translateHead(ref.current, 0);
  }

  const setRef = useCallback(
    node => {
      if (ref.current) {
        observer.unobserve(ref.current);
        if (scrollable) {
          scrollableObserver.unobserve(scrollable);
        }
        if (scrollable) resizeObserver.unobserve(scrollable);
        window.removeEventListener('scroll', handleScroll);
      }

      if (node) {
        observer.observe(node);
        if (scrollable) {
          scrollableObserver.observe(scrollable);
        }
        if (scrollable) resizeObserver.observe(scrollable);
        window.addEventListener('scroll', handleScroll);
      }

      ref.current = node;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [observer, stickyHeaderContainer]
  );

  return [setRef];
};

const StickyTableHead = ({ scrollableRef, disabled, ...props }) => {
  const [frozenHeadNode, setFrozenHeadNode] = useState(null);

  const { container, containerBackground } = useStickyHeaderContainer();

  const [headerSetRef] = useStickyHeaderRef(
    frozenHeadNode,
    container.current,
    scrollableRef.current,
    containerBackground.current,
    disabled
  );

  return (
    <>
      <Portal container={container.current} style>
        <TableHead
          style={{
            visibility: 'hidden',
          }}
          {...props}
          ref={node => {
            if (node && frozenHeadNode !== node) {
              setFrozenHeadNode(node);
            }
          }}
        />
      </Portal>

      <TableHead {...props} ref={headerSetRef} />
    </>
  );
};

export default StickyTableHead;
