import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BookmarkIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 10 11'} {...props}>
      <path
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H8.5C9.32843 0 10 0.671573 10 1.5V10.0171C10 10.4259 9.53601 10.6619 9.20557 10.4212L5 7.35714L0.794429 10.4212C0.463991 10.6619 0 10.4259 0 10.0171V1.5Z"
        fill="#1581FF"
      />
    </SvgIcon>
  );
};

export default BookmarkIcon;
