import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import TableActionCards from '../../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import getMarginTemplatesDiff from '../../../../gql/sportbook-config/margin/queries/getMarginTemplatesDiff';
import useSearchInput from '../../../../hooks/useSearchInput';
import useTablePagination from '../../../../hooks/useTablePagination';
import TemplateParentLink from '../../components/TemplateParentLink';
import MarginTable from './MarginTable';
import { tableNames } from '../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../components/ErrorWidget';

const MarginTab = ({ templateId, showActions = true }) => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.MarginTab,
    onChangeLimit: () => {},
  });

  const [parentTemplate, setParentTemplate] = useState();

  const renderParentLink = useCallback(() => {
    return (
      <TemplateParentLink name={parentTemplate.name} id={parentTemplate.id} />
    );
  }, [parentTemplate]);

  const { searchValue, searchInputCard } = useSearchInput({
    label: 'Event/category/tournament name or ID',
    metaData: parentTemplate && renderParentLink,
  });

  const variables = useMemo(() => {
    return {
      templateId,
      limit,
      offset,
      searchQuery: searchValue,
    };
  }, [limit, offset, searchValue, templateId]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(getMarginTemplatesDiff, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const items = useMemo(() => {
    if (!isEmpty(data)) {
      setParentTemplate(data.parent_template);
      return data.items;
    }
    return [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  const actions = useMemo(() => {
    return [searchInputCard];
  }, [searchInputCard]);

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <>
      {showActions && <TableActionCards actions={actions} inline={true} />}
      <MarginTable data={items} loading={loading} />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default MarginTab;
