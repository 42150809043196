import React from 'react';
import { animated, config, useSpring } from 'react-spring';

const TransitionScaleFade = React.forwardRef(function Transition(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: {
      height: '100%',
      opacity: 0,
      transform: 'scale(0.5)',
    },
    to: {
      opacity: open ? 1 : 0,
      transform: open ? 'scale(1)' : 'scale(0.5)',
    },
    config: config.stiff,
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default TransitionScaleFade;
