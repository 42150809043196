import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import React, { Fragment, forwardRef, useContext, useState } from 'react';
import { isSelect } from 'react-jsonschema-form/lib/utils';
import CheckBoxActiveIcon from '../../../../icons/controls/CheckBoxActiveIcon';
import CheckBoxIcon from '../../../../icons/controls/CheckBoxIcon';
import CheckboxIndeterminateIcon from '../../../../icons/controls/CheckboxIndeterminateIcon';
import ExpandIcon from '../../../../icons/controls/ExpandIcon';
import { DebugModeContext } from '../../../../providers/DebugModeProvider';
import Tooltip from '../../Tooltip/Tooltip';
import DetailSelectOption from './DetailSelectOption';

export const isMultiSelect = (schema, uiSchema) =>
  !!(
    schema.items &&
    (isSelect(schema.items) || get(uiSchema, 'ui:options.operationId'))
  );

const useStyles = makeStyles(theme => ({
  autocompleteRoot: {
    position: 'relative',
  },
  menuButtons: {
    pointerEvents: 'none',
    justifyContent: 'flex-end',
    marginBottom: -theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  applyButton: {
    pointerEvents: 'initial',
  },

  selectInputRoot: {
    maxWidth: 400,
    width: '100%',
  },

  selectInputFullWidthRoot: {
    maxWidth: 'initial',
    width: '100%',
  },
  imagePreview: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: -12,
    padding: 4,
    paddingRight: 12,
    width: 84,

    '& img': {
      maxWidth: 72,
      maxHeight: 72,
      borderRadius: 4,
    },
  },
  selectInput: {
    display: 'flex',
  },
  tags: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#031224',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    paddingLeft: 8,
  },
  autocompleteExpandIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },

  optionWithoutFormat: {
    textTransform: 'none',
  },

  optionContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: 'auto',
  },

  optionTooltip: {
    width: '100%',
  },

  clearIndicator: {
    display: 'none',
  },
}));

const isValue = value => !isUndefined(value) && !isNull(value);

export const enumToOptions = options =>
  options.map(option => ({
    label: option,
    value: option,
  }));

export const Select = forwardRef(
  (
    {
      id,
      name,
      onChange,
      onBlur,
      onInputChange = () => {},
      value,
      initialValue,
      className,
      formatLabelCase,
      imageKey,
      label = 'Select',
      disabled,
      disableClearable = false,
      disabledOptions = [],
      hintText,
      required,
      autoFocus,
      InputProps = {},
      error,
      fullWidth = true,
      helperText,
      multiple = false,
      labelKey = 'label',
      valueKey = 'value',
      options = [],
      withDetails = false,
      withDivider = false,
      withExpandIcon = true,
      withTooltip = false,
      subKey = 'sub',
      size,
      debug,
      freeSolo = false,
      valueToString = true,
      preventClearOnBackspace = false,
    },
    ref
  ) => {
    const [innerValue, setInnerValue] = useState(null);
    const classes = useStyles();

    const Container = withTooltip ? Tooltip : Fragment;

    if (typeof value === 'undefined') {
      value = innerValue;
    }

    const renderTags = value => {
      if (!multiple) {
        return null;
      }

      const text = value.map(item => get(item, labelKey, item)).join(', ');

      const containerProps = withTooltip
        ? { overflowOnly: true, title: text }
        : {};

      return (
        <Container {...containerProps}>
          <div className={classes.tags}>{text}</div>
        </Container>
      );
    };

    let fieldValue = multiple
      ? value
        ? value.map(value => options.find(item => item[valueKey] === value))
        : []
      : options.find(item => item[valueKey] === value) || initialValue || null;

    const { endAdornment } = InputProps;

    const debugEnabled = useContext(DebugModeContext);

    const actions =
      multiple && options.length >= 10
        ? [
            { [labelKey]: 'Select all', [valueKey]: 'select-all' },
            { [labelKey]: 'Clear all', [valueKey]: 'clear-all' },
          ]
        : [];

    return (
      <Autocomplete
        size={size}
        ref={ref}
        id={id || `autocomplete-${name}`}
        popupIcon={
          withExpandIcon && (
            <ExpandIcon className={classes.autocompleteExpandIcon} />
          )
        }
        freeSolo={freeSolo}
        open={debugEnabled ? false : undefined}
        options={
          options &&
          [...actions, ...options].map(item => ({
            ...item,
            [valueKey]:
              item[valueKey] && valueToString
                ? item[valueKey].toString()
                : item[valueKey],
            [labelKey]: item[labelKey]
              ? item[labelKey].toString()
              : item[labelKey],
          }))
        }
        getOptionLabel={option =>
          option[labelKey] ? option[labelKey].toString() : ''
        }
        isOptionEqualToValue={(option, value) => {
          return value && option[valueKey] === value[valueKey];
        }}
        fullWidth={fullWidth}
        value={fieldValue}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        renderTags={renderTags}
        openOnFocus
        autoComplete={true}
        disabled={disabled}
        disableClearable={disableClearable}
        onChange={(event, value) => {
          //sometimes data for select variant can contain sub data about this variant (value[subKey])
          if (multiple && value) {
            //to provide this sub info you need to add to option object field with key like "sub", or pass subKey prop
            const subValues = {};
            value &&
              value.map(
                value => (subValues[`${value[valueKey]}`] = value[subKey])
              );

            const isSelectedAll = value.find(
              el => el[valueKey] === 'select-all'
            );
            const isClearedAll = value.find(el => el[valueKey] === 'clear-all');

            if (isSelectedAll) {
              setInnerValue(options.map(v => v[valueKey]));
              onChange && onChange(options.map(v => v[valueKey]));
            } else if (isClearedAll) {
              setInnerValue([]);
              onChange && onChange([]);
            } else {
              const isSet = isValue(value);

              value =
                isSet &&
                value
                  .filter(v => typeof v !== 'undefined')
                  .map(v => (isValue(v[valueKey]) ? v[valueKey] : v));

              if (!isSet && !required && !multiple) {
                value = null;
              }

              if (!value) {
                value = null;
              }

              setInnerValue(value ? value : null);
              onChange && onChange(value, subValues);
            }
          } else {
            const isSet = isValue(value);

            value = isSet && isValue(value[valueKey]) ? value[valueKey] : value;

            if (!isSet && !required && !multiple) {
              value = null;
            }

            if (!value) {
              value = null;
            }

            setInnerValue(value ? value : null);
            onChange && onChange(value, value?.[subKey]);
          }
        }}
        renderOption={(props, option, { selected }) => {
          const isSelectAllOption = option[valueKey] === 'select-all';
          const isClearAllOption = option[valueKey] === 'clear-all';

          const containerProps = withTooltip
            ? {
                overflowOnly: true,
                title: option[labelKey],
                tooltipClassName: classes.optionContent,
              }
            : {};

          return (
            <li
              {...props}
              key={option[valueKey]}
              data-cy-value={option[valueKey]}
              aria-disabled={disabledOptions.includes(option[valueKey])}
              style={{
                ...(withDivider && {
                  borderBottom: '1px rgba(132, 150, 171, .2) solid',
                }),
                ...(isSelectAllOption && {
                  color: '#1581FF',
                  fontWeight: '600',
                }),
                ...(isClearAllOption && {
                  color: '#FF000F',
                  fontWeight: '600',
                }),
              }}
            >
              {imageKey ? (
                <>
                  <ListItemAvatar
                    classes={{
                      root: classes.imagePreview,
                    }}
                  >
                    <img alt={'preview'} src={option[imageKey]} />
                  </ListItemAvatar>
                  <ListItemText primary={option[labelKey]} />
                </>
              ) : withDetails && !isEmpty(option[subKey]) ? (
                <DetailSelectOption
                  className={classes.optionContent}
                  option={option}
                  labelKey={labelKey}
                  subKey={subKey}
                />
              ) : (
                <Container {...containerProps}>
                  <div className={classes.optionContent}>
                    {option[labelKey]}
                  </div>
                </Container>
              )}
              {multiple && !isSelectAllOption && !isClearAllOption && (
                <Checkbox
                  value={option[valueKey]}
                  icon={<CheckBoxIcon />}
                  checkedIcon={<CheckBoxActiveIcon />}
                  indeterminateIcon={<CheckboxIndeterminateIcon />}
                  checked={selected}
                />
              )}
            </li>
          );
        }}
        classes={{
          root: classes.autocompleteRoot,
          option: classNames({
            [classes.optionWithoutFormat]: formatLabelCase === false,
          }),
          clearIndicator: classes.clearIndicator,
        }}
        renderInput={params => (
          <>
            <TextField
              name={name}
              {...params}
              onKeyDown={
                preventClearOnBackspace &&
                (event => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                })
              }
              InputLabelProps={{
                ...params.InputLabelProps,
                style: { overflow: 'hidden', textOverflow: 'ellepsis' },
              }}
              inputProps={{
                ...params.inputProps,
                title: typeof hintText === 'string' ? hintText : undefined,
              }}
              onChange={onInputChange}
              helperText={helperText}
              error={error}
              margin="dense"
              className={className}
              fullWidth={fullWidth}
              required={required}
              autoFocus={autoFocus}
              label={label}
              variant="outlined"
            />

            {endAdornment}
          </>
        )}
      />
    );
  }
);

export default Select;
