import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button, { ButtonColorEnum } from '../../v2/components/Button/Button';
import ExpandIcon from '../../icons/controls/ExpandIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Table, TableBody } from '@mui/material';
import TableRow from './TableRow';
import classNames from 'clsx';
import { UiSchemaContext } from '../../providers/UiSchemaProvider';
import get from 'lodash/get';
import Grow from '@mui/material/Grow';
import TableRowActionsProvider from '../../providers/TableRowActionsProvider';
import RowDecoratorsProvider from '../../providers/RowDecoratorsProvider';
import NestedIndexesProvider from '../../providers/NestedIndexProvider';

const useStyles = makeStyles(() => ({
  dropdownButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px 0px 16px',
    height: 28,
    boxSizing: 'border-box',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    fontWeight: '500',
    textTransform: 'lowercase',
  },

  dropdownButtonActive: {
    zIndex: 2,
    backgroundColor: '#fff',

    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  inlineDropdownContent: {
    position: 'absolute',
    paddingTop: 37,
    paddingLeft: 3,
    paddingRight: 3,
    top: 0,
    left: -3,
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow:
      '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
    borderRadius: 8,
  },

  inlineTableDropdownRoot: {
    backgroundColor: 'initial',
  },

  expandIcon: {
    fontSize: '14px',
  },
}));

const InlineTableDropdown = ({
  columnName,
  data,
  columns,
  isOpen,
  setIsOpen,
}) => {
  const classes = useStyles();
  const uiSchema = useContext(UiSchemaContext);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const options = get(uiSchema, 'ui:options', {});
  const { label } = options;

  return (
    <>
      <Button
        className={classNames({
          [classes.dropdownButtonActive]: isOpen,
        })}
        onClick={toggle}
        color={ButtonColorEnum.LIGHT_BLUE}
        classes={{
          root: classes.dropdownButtonRoot,
          label: classes.dropdownButtonLabel,
        }}
      >
        {data.length} {label}{' '}
        <ExpandIcon className={classes.expandIcon} expanded={isOpen} />
      </Button>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Grow in={isOpen}>
            <div className={classes.inlineDropdownContent}>
              <div className={classes.inlineDropdownDivider} />
              <TableRowActionsProvider value={null}>
                <RowDecoratorsProvider value={() => ({})}>
                  <Table
                    classes={{
                      root: classes.inlineTableDropdownRoot,
                    }}
                  >
                    <TableBody
                      classes={{
                        root: classes.inlineTableDropdownBodyRoot,
                      }}
                    >
                      {data.map((row, index) => {
                        return (
                          <NestedIndexesProvider
                            key={index}
                            indexes={{
                              [columnName]: index,
                            }}
                          >
                            <TableRow data={row} columns={columns} />
                          </NestedIndexesProvider>
                        );
                      })}
                    </TableBody>
                  </Table>
                </RowDecoratorsProvider>
              </TableRowActionsProvider>
            </div>
          </Grow>
        </ClickAwayListener>
      )}
    </>
  );
};

export default InlineTableDropdown;
