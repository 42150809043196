import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import ChangePasswordForm from './components/ChangePasswordForm';
import useAuth from '../../../../v2/hooks/auth';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes';

const ChangePasswordPage = () => {
  const [success, setSuccess] = useState(false);

  const TITLE = success ? 'Password set successfully' : 'Change password';
  const CAPTION = success
    ? 'You can now log in to your account.'
    : 'Please fill out this short form carefully to create a new password.';

  const { changePassword } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (data, onError) => {
    changePassword({
      email: data.email,
      oldPassword: data.oldPassword,
      newPassword: data.password,
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        onError(error.graphQLErrors);
      });
  };

  const handleGoToLogin = () => {
    navigate(`../${paths.login}`);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Stack gap={'24px'} justifyContent={'center'} alignItems={'center'}>
      <Stack gap={'12px'}>
        <Typography textAlign={'center'} fontWeight={600} fontSize={'24px'}>
          {TITLE}
        </Typography>
        <Typography
          textAlign={'center'}
          maxWidth={'320px'}
          color={'#93a3b5'}
          fontSize={'14px'}
          lineHeight={'20px'}
        >
          {CAPTION}
        </Typography>
      </Stack>
      {success ? (
        <Button onClick={handleGoToLogin} variant={'contained'}>
          Log in
        </Button>
      ) : (
        <ChangePasswordForm onSubmit={handleSubmit} onCancel={handleCancel} />
      )}
    </Stack>
  );
};

export default ChangePasswordPage;
