import { gql } from '@apollo/client';

const getLanguages = gql`
  query getLanguages @api(contextKey: "apiName") {
    getLanguages {
      items
    }
  }
`;

export default getLanguages;
