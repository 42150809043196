import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  timePickerControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
  },

  timePickerControlLabel: {
    marginLeft: 6,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },
});
const TimePickerControl = ({ isOpen, onChange, disabled }) => {
  const classes = useStyles();

  const handleOpenTime = useCallback(
    e => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <div className={classes.timePickerControl}>
      <FormControlLabel
        label={
          <div className={classes.timePickerControlLabel}>
            Hours and minutes
          </div>
        }
        control={
          <Switch
            checked={isOpen}
            disabled={disabled}
            onChange={handleOpenTime}
            value="toggleValue"
          />
        }
      />
    </div>
  );
};

export default TimePickerControl;
