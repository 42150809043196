import React from 'react';
import withStyles from '@mui/styles/withStyles';
import StoryBook from '../../storybook';

class StoryBookPage extends React.Component {
  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.root}>
        <StoryBook />
      </div>
    );
  }
}

const styles = () => ({
  root: {
    background: '#fff',
  },
});

export default withStyles(styles)(StoryBookPage);
