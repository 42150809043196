import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import getCoveragePreset from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePreset';
import getCoveragePresetNames from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePresetNames';
import {
  ApplyPreset,
  DisabledItemArea,
  DraggableSelectItem,
  DraggableSelectMenu,
  MenuCaption,
  MenuTitle,
  SelectContainer,
  SelectSearch,
  VirtualizedMenu,
} from '../../../components/';
import { useCoverageTemplateSelect } from '../../../hooks/coverage/select/useCoverageTemplateSelect';
import CoverageSportOption from '../Options/CoverageSportOption';

const CoverageSportSelect = ({ templateId, selectKey, nodeIndex, options }) => {
  const { getValues, setValue } = useFormContext();

  const [lineType, template] = getValues(['lineType', 'template']);

  const {
    nodePath: { [lineType]: nodePath },
  } = useTreeSelectContext();

  const selectedId = nodePath.sportId;

  const {
    displayOptions,
    dragDropDisabled,
    optionsFullListRef,
    isCoverageWriter,
    isPresetsAvailable,
    onSearchFieldChange,
    saveReorderChanges,
    applyChanges,
    setDisplayOptions,
  } = useCoverageTemplateSelect({
    options,
    getFormValues: getValues,
    setFormValue: setValue,
  });

  //apply changes for each option (to save order)
  useEffect(() => {
    setDisplayOptions(applyChanges());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineType, nodePath, template]);

  return (
    <SelectContainer style={{ width: '375px' }}>
      <SelectSearch
        label={`Search Sport`}
        onSearchFieldChange={onSearchFieldChange}
      />
      <MenuCaption>
        <MenuTitle name={'Sport'} />
        {isPresetsAvailable && (
          <ApplyPreset
            templateType={'coverage'}
            selectKey={'sport'}
            menuOptions={options}
            sportId={selectedId}
            idKey={'sportId'}
            getPresetNamesQuery={getCoveragePresetNames}
            getPresetQuery={getCoveragePreset}
          />
        )}
      </MenuCaption>
      <DraggableSelectMenu
        afterDrop={saveReorderChanges}
        options={displayOptions}
        setDisplayOptions={setDisplayOptions}
      >
        <VirtualizedMenu
          rowCount={displayOptions.length}
          itemRender={({ index }) => {
            const option = displayOptions[index];

            return (
              <Box position={'relative'}>
                {option.readOnly && <DisabledItemArea />}
                <DraggableSelectItem
                  selected={option.sportId === selectedId}
                  index={index}
                  itemValue={option}
                  idKey={'sportId'}
                  virtualized
                >
                  <CoverageSportOption
                    key={selectKey + '-' + option.sportId}
                    templateId={templateId}
                    nodePath={nodePath}
                    nodeIndex={nodeIndex}
                    selected={option.sportId === selectedId}
                    itemValue={option}
                    itemIndex={index}
                    lineType={lineType}
                    options={options}
                    optionsFullListRef={optionsFullListRef}
                    setDisplayOptions={setDisplayOptions}
                    dragDropDisabled={dragDropDisabled}
                    configurationDisabled={!isCoverageWriter}
                  />
                </DraggableSelectItem>
              </Box>
            );
          }}
        />
      </DraggableSelectMenu>
    </SelectContainer>
  );
};

export default CoverageSportSelect;
