import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ConfirmationPromptContext } from '../../../../../../providers/ConfirmationPromptProvider';
import {
  defaultNodePath,
  initialData,
  initialSelectedCoverageItems,
  initialSelectedCoverageItemsConfig,
  useTreeSelectContext,
} from '../../../../../../providers/TreeSelectProvider';
import Button from '../../../../../components/Button/Button';
import FormActions from '../../../../../components/Form/FormActions';
import FormField from '../../../../../components/Form/FormField';
import FormGroup from '../../../../../components/Form/FormGroup';
import Select from '../../../../../components/Inputs/Select/Select';
import Switch from '../../../../../components/Inputs/Switch/Switch';
import TextField from '../../../../../components/Inputs/TextField';
import ModalTitle from '../../../../../components/Inputs/TreeSelect/components/ModalTitle';
import getTemplateNames from '../../../../../gql/sportbook-config/line-settings/getTemplateNames';
import {
  ACCESS_ROLE,
  useSportsBookUserAccessRoles,
} from '../../../../../providers/SportsBookUserAccessRolesProvider';
import { SETTINGS_FORM_MODE } from '../../LineSettings/LineSettingsCreatePage/LineSettingsCreatePage';
import { combineTemplateConfigs } from '../utils/combineTemplateConfigs';
import { splitTemplateConfigs } from '../utils/splitTemplateConfigs';
import TemplateConfigurationField, {
  LINE_TYPES,
} from './TemplateConfigurationField';

const useStyles = makeStyles({
  fullHeightForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 43px)',
  },
});

export const TEMPLATE_TYPE = {
  MARGIN: 'margin',
  COVERAGE: 'coverage',
  SOURCES: 'sources',
};

const TemplateForm = ({
  defaultValues,
  templateId,
  defaultTemplateType,
  formMode = SETTINGS_FORM_MODE.CREATE,
  onSubmit = () => {},
}) => {
  const classes = useStyles();

  const { openConfirmation, closeConfirmation } = useContext(
    ConfirmationPromptContext
  );
  const {
    treeSelectCrashed,
    setTreeSelectCrashed,
    setNodePath,
    setData,
    setChangedMarginItems,
    setSelectedCoverageItemsConfig,
  } = useTreeSelectContext();

  const { data: { data: nameIds } = {}, error } = useQuery(getTemplateNames, {
    fetchPolicy: 'no-cache',
    variables: { templateTypes: [] },
  });

  const navigate = useNavigate();

  const { roles } = useSportsBookUserAccessRoles();

  const isCoverageWriter = roles?.coverage === ACCESS_ROLE.WRITE;
  const isSourcesWriter = roles?.sources === ACCESS_ROLE.WRITE;
  const defaultNameRef = useRef('');

  const nameValidation = useCallback(
    value => {
      if (!nameIds || isEmpty(nameIds)) {
        return true;
      }
      const names = nameIds.map(value => value.name);
      const trimmedValue = value.trim();

      if (
        formMode === SETTINGS_FORM_MODE.EDIT &&
        value === defaultNameRef.current
      ) {
        return true;
      }

      return names.every(item => item !== trimmedValue);
    },
    [nameIds, formMode]
  );

  const nameSchema = yup
    .string()
    .test({
      name: 'unique-template-name',
      message: 'This name already exists!',
      test: nameValidation,
    })
    .trim()
    .required('Name is a required field');

  const TemplateValidationSchema = yup.object({
    name: nameSchema,
    type: yup.string().required(),
    lineType: yup.string(),
    template: yup.object(),
    switchedFrom: yup.string(),
    path: yup.object(),
    version: yup.number(),
  });

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(TemplateValidationSchema),
    defaultValues,
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const currentTemplateType = getValues('type');

  //TODO make as an object
  const [showSameSettingsSwitch, setShowSameSettingsSwitch] = useState(
    formMode !== SETTINGS_FORM_MODE.CREATE &&
      currentTemplateType !== TEMPLATE_TYPE.MARGIN
  );
  const [isTemplateSelected, setIsTemplateSelected] = useState(
    formMode !== SETTINGS_FORM_MODE.CREATE
  );
  const [formTemplateType, setFormTemplateType] = useState(defaultTemplateType);
  const [sameLivePrematch, setSameLivePrematch] = useState(
    currentTemplateType === TEMPLATE_TYPE.MARGIN
  );

  const templateTypeOptions = useMemo(() => {
    const initialTypes = [];

    if (formMode === SETTINGS_FORM_MODE.EDIT || isSourcesWriter) {
      initialTypes.push({
        label: 'Sources',
        value: TEMPLATE_TYPE.SOURCES,
      });
    }
    if (formMode === SETTINGS_FORM_MODE.EDIT || isCoverageWriter) {
      initialTypes.push({
        label: 'Coverage',
        value: TEMPLATE_TYPE.COVERAGE,
      });
    }

    return initialTypes;
  }, [isSourcesWriter, isCoverageWriter, formMode]);

  const disabledByRights =
    (formTemplateType === TEMPLATE_TYPE.SOURCES && !isSourcesWriter) ||
    (formTemplateType === TEMPLATE_TYPE.COVERAGE && !isCoverageWriter);

  useEffect(() => {
    defaultValues().then(values => {
      defaultNameRef.current = values.name;
      setValue('name', values.name);
      setValue('version', values.version);
      setValue('type', values.type);
      setSameLivePrematch(values.type === TEMPLATE_TYPE.MARGIN);
    });
  }, [defaultValues, setValue]);

  //to hide tree select field and remove selected template type
  //if template first level fetch drop error
  useEffect(() => {
    if (treeSelectCrashed) {
      setIsTemplateSelected(false);
      setFormTemplateType(defaultTemplateType || null);
      setValue('type', defaultTemplateType || null);
      setTreeSelectCrashed(false);
    }
  }, [
    treeSelectCrashed,
    defaultTemplateType,
    setIsTemplateSelected,
    setValue,
    setTreeSelectCrashed,
  ]);

  const onFormReset = useCallback(() => {
    const templateType = getValues('type');
    setNodePath(defaultNodePath);
    setSameLivePrematch(templateType === TEMPLATE_TYPE.MARGIN);
    setData(initialData);
    setValue('template', {});
    setChangedMarginItems({});
    setSelectedCoverageItemsConfig(initialSelectedCoverageItems);
  }, [
    setData,
    setNodePath,
    setChangedMarginItems,
    setValue,
    getValues,
    setSelectedCoverageItemsConfig,
  ]);

  const onChangeTemplateType = useCallback(
    value => {
      setFormTemplateType(value);
      setShowSameSettingsSwitch(value !== TEMPLATE_TYPE.MARGIN);
      setIsTemplateSelected(Object.values(TEMPLATE_TYPE).includes(value));
      setValue('lineType', LINE_TYPES.prematch);
      setValue('configLineType', LINE_TYPES.prematch);
      onFormReset();
    },
    [setValue, onFormReset]
  );

  const setConfigAfterLineTypeChange = useCallback(
    ({ value, currentLineType, currentConfigLineType, templateConfigs }) => {
      setValue('switchedFrom', currentConfigLineType);
      if (!isEmpty(templateConfigs)) {
        const newConfig = value
          ? combineTemplateConfigs({
              templateConfigs,
              lineType: currentConfigLineType,
            })
          : splitTemplateConfigs({
              templateConfigs,
              lineType: currentConfigLineType,
            });
        setValue('template', newConfig);
      }
      if (!value) {
        setNodePath(currentData => {
          return {
            live: currentData[currentLineType],
            prematch: currentData[currentLineType],
          };
        });
        setData(currentData => {
          return {
            live: currentData[currentLineType],
            prematch: currentData[currentLineType],
          };
        });
      }

      setValue('lineType', value ? currentLineType : LINE_TYPES.prematch);
      setValue('configLineType', value ? LINE_TYPES.all : LINE_TYPES.prematch);
      setSameLivePrematch(value);
    },
    [setData, setNodePath, setValue]
  );

  const changeLineTypeProcessing = useMemo(() => {
    return value => {
      const currentLineType = getValues('lineType');
      const currentConfigLineType = getValues('configLineType');
      const templateConfigs = getValues('template');
      setConfigAfterLineTypeChange({
        value,
        currentLineType,
        currentConfigLineType,
        templateConfigs,
      });
    };
  }, [getValues, setConfigAfterLineTypeChange]);

  const onLiveLikePrematchSwitchToggle = useCallback(
    value => {
      if (value) {
        openConfirmation({
          buttonLabel: 'Confirm',
          title: (
            <ModalTitle title="Are you sure?" onClose={closeConfirmation} />
          ),
          message: (
            <Fragment>
              Are you sure you want to apply <b>identical</b> settings for{' '}
              <b>Prematch</b> and <b>Live</b> template?
            </Fragment>
          ),
          callback: async () => {
            changeLineTypeProcessing(value);
          },
        });
      } else {
        changeLineTypeProcessing(value);
      }
    },
    [changeLineTypeProcessing, closeConfirmation, openConfirmation]
  );

  const renderSameSettingsSwitch = ({ template }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            disabled={disabledByRights || isEmpty(template)}
            onChange={onLiveLikePrematchSwitchToggle}
            value={sameLivePrematch}
          />
        }
        label={'The same changes for Prematch and Live'}
      />
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className={isTemplateSelected ? classes.fullHeightForm : undefined}
    >
      <FormProvider {...methods}>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormField name={'name'}>
                <TextField
                  required
                  disabled={disabledByRights}
                  label={'Name of the template'}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField name={'type'} onChange={onChangeTemplateType}>
                <Select
                  required
                  disabled={
                    isEmpty(templateTypeOptions) ||
                    formMode !== SETTINGS_FORM_MODE.CREATE
                  }
                  label={'Type of the template'}
                  options={templateTypeOptions}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              {showSameSettingsSwitch && (
                <FormField name={'liveLikePrematch'} deps={['template']}>
                  {renderSameSettingsSwitch}
                </FormField>
              )}
            </Grid>
          </Grid>
        </FormGroup>
        {isTemplateSelected && (
          <Box mt={2} height={'100%'}>
            <TemplateConfigurationField
              templateId={templateId}
              isSameLivePrematch={sameLivePrematch}
              templateType={formTemplateType}
            />
          </Box>
        )}

        <FormActions mt={2}>
          <Button
            variant={'text'}
            color={'lightBlue'}
            onClick={() => navigate('/line-settings/template')}
          >
            Cancel
          </Button>
          {formMode === SETTINGS_FORM_MODE.EDIT && (
            <Button
              disabled={disabledByRights}
              variant={'text'}
              color={'lightBlue'}
              onClick={onFormReset}
            >
              Reset
            </Button>
          )}
          <Button
            disabled={disabledByRights || !isValid}
            type={'submit'}
            color={'primary'}
          >
            {formMode === SETTINGS_FORM_MODE.CREATE
              ? 'Create template'
              : 'Save template'}
          </Button>
        </FormActions>
      </FormProvider>
    </form>
  );
};

export default TemplateForm;
