import BaseInput from './BaseInput';
import DynamicTabs from './DynamicTabs';
import TextareaWidget from '../../../components/DynamicForm/widgets/TextareaWidget';
import SelectWidget from './SelectWidget';
import RadioWidget from '../../../components/DynamicForm/widgets/RadioWidget';

const widgets = {
  BaseInput,
  DynamicTabs,
  TextareaWidget,
  SelectWidget,
  RadioWidget,
};

export default widgets;
