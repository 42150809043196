import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import ArrowRightSmall from '../../../icons/controls/ArrowRightSmall';
import Link from '../Link/Link';

const useStyles = makeStyles({
  breadCrumbs: {
    padding: '0 0 11px 0px',
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: '0.2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  breadcrumbsArrow: {
    width: 20,
    color: '#8496AB',
    opacity: 0.5,
  },
  crumb: {
    textDecoration: 'none',
    color: '#031224',
    transition: 'color 0.2s ease-in-out',

    '&:not($lastCrumb)': {
      '&:hover': {
        cursor: 'pointer',
        color: '#1581FF',
      },
    },
  },

  mobileViewLabel: {
    cursor: 'pointer',
    color: '#031224',
  },

  lastCrumb: {
    color: '#8496AB',
  },
});

const BreadCrumbs = ({ crumbs = [] }) => {
  const classes = useStyles();

  return (
    <div className={classes.breadCrumbs}>
      {crumbs.map(({ to, label }, index) => {
        const isLastCrumb = index === crumbs.length - 1;

        return (
          <React.Fragment key={label}>
            <Link
              to={to}
              disabled={isLastCrumb}
              className={clsx(classes.crumb, {
                [classes.lastCrumb]: isLastCrumb,
              })}
            >
              {label}
            </Link>

            {!isLastCrumb && (
              <ArrowRightSmall className={classes.breadcrumbsArrow} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
