import React from 'react';
import useActionCards from '../hooks/useActionCards';
import TableActionCards from '../v2/components/TableActionCards/TableActionCards';

export const ActionCardTypeEnum = {
  CREATE: 'create',
  FILTERS: 'filters',
  PRESETS: 'presets',
  EXPORT: 'export',
};

const ActionCards = ({ path, actions: providedActions }) => {
  const actions = useActionCards(path, providedActions);

  return <TableActionCards actions={actions} />;
};

export default ActionCards;
