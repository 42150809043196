import {
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';
import { ActivatePeriod } from '../../../pages/PromoBanner/components/BannerForm/BannerValidationSchema';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { forwardRef } from 'react';

const RadioGroup = forwardRef(
  ({ value, onChange, error, helperText, ...props }, ref) => {
    return (
      <FormControl error={error} variant="standard" ref={ref}>
        <MuiRadioGroup value={value} onChange={onChange} {...props}>
          <FormControlLabel
            value={ActivatePeriod.PERMANENTLY}
            control={<Radio color={'primary'} />}
            label="Permanently"
          />

          <FormControlLabel
            value={ActivatePeriod.PERIOD}
            control={<Radio color={'primary'} />}
            label="Period"
          />
        </MuiRadioGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default RadioGroup;
