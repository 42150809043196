import dayjs from 'dayjs';
import { memo } from 'react';
import EmptyField from '../EmptyField/EmptyField';

export const FORMAT_DATE = 'DD.MM.YYYY';
export const FORMAT_DATE_TIME = 'DD.MM.YYYY, HH:mm';

const DateField = memo(({ date: dateString, format = FORMAT_DATE_TIME }) => {
  const date = dayjs(
    typeof dateString === 'number' ? dateString * 1000 : dateString
  );

  if (!date.isValid()) {
    return <EmptyField />;
  }

  return date.utc().format(format);
});

export default DateField;
