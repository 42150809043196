import React, { useContext } from 'react';
import { UiOptionsContext } from './UiOptionsProvider';
import { FieldContainerExtendedContext } from './FieldContainer';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles({
  extendableField: {
    overflow: 'hidden',
  },

  extendableFieldExtended: {
    transition: 'width 0.2s ease-in-out',
  },
});

const ExtendableField = ({ children }) => {
  const { value } = useContext(UiOptionsContext);
  const classes = useStyles();

  const { setContainerExtended, containerExtended, width, setWidth } =
    useContext(FieldContainerExtendedContext);

  return (
    <div
      onMouseEnter={e => {
        const fieldElement = e.currentTarget;

        if (
          fieldElement &&
          fieldElement.clientWidth < fieldElement.scrollWidth
        ) {
          setWidth(fieldElement.scrollWidth);
          setContainerExtended(true);
        }
      }}
      className={classNames(classes.extendableField, {
        [classes.extendableFieldExtended]: containerExtended,
      })}
      style={{
        width: width,
      }}
    >
      {children || value.data}
    </div>
  );
};

export default ExtendableField;
