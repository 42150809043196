import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const GlobalError = ({ error, classes }) => (
  <div className={classes.root}>
    <Typography variant="h4" color="error" align="center" gutterBottom>
      Something went wrong...
    </Typography>
    <Typography variant="h6">{JSON.stringify(error)}</Typography>
  </div>
);

export default withStyles(styles)(GlobalError);
