import React, { useState } from 'react';
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Link } from '@mui/icons-material';
import isURL from 'validator/lib/isURL';

const CustomUrlModal = ({ onSubmit, onClose }) => {
  const [url, setURL] = useState('');
  const [error, setError] = useState(null);

  return (
    <Stack gap={'25px'} width={'100%'}>
      <Typography fontSize={'20px'}>Add custom schema</Typography>
      <TextField
        value={url}
        onChange={e => setURL(e.target.value)}
        name={'url'}
        error={error}
        helperText={error && 'Must be URL: http(s)://<url>/schema.json'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link />
            </InputAdornment>
          ),
        }}
        placeholder={'URL to schema.json'}
        sx={{
          '& .MuiInputBase-root': { paddingRight: '14px' },
          '& .MuiInputAdornment-root': {
            width: '30px',
            '&.MuiInputAdornment-positionStart': {
              color: '#1481ff',
            },
          },
        }}
      />
      <Stack
        gap={'10px'}
        direction={'row'}
        sx={{
          alignSelf: 'flex-end',
        }}
      >
        <Button value={'text'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          onClick={() => {
            setError(null);
            if (
              isURL(url, {
                require_protocol: true,
                require_host: false,
                require_tld: false,
              })
            ) {
              onSubmit(url);
            } else {
              setError(true);
            }
          }}
        >
          Add
        </Button>
      </Stack>
    </Stack>
  );
};

export default CustomUrlModal;
