import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrayChipFilter from '../../../../../components/Filters/ArrayChipFilter';
import BrandSelect from '../../../../../components/Inputs/BrandSelect/BrandSelect';
import Checkbox from '../../../../../components/Inputs/Checkbox';
import MarketProviderSelect from '../../../../../components/Inputs/MarketProviderSelect/MarketProviderSelect';
import NumberRangeInput from '../../../../../components/Inputs/NumberRangeInput';
import OperatorSelect from '../../../../../components/Inputs/OperatorSelect/OperatorSelect';
import RangeDateInput from '../../../../../components/Inputs/RangeDateInput';
import Select from '../../../../../components/Inputs/Select/Select';
import SportsSelect from '../../../../../components/Inputs/SportsSelect/SportsSelect';
import { OrderDirection } from '../../../../../components/Table/TableHeaderColumn';
import TableActionCards from '../../../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../../../components/TablePagination/TablePagination';
import getMarketLiabilityList from '../../../../../gql/Reports/queries/getMarketLiabilityList';
import getOperatorMarketLiabilityList from '../../../../../gql/Reports/queries/getOperatorMarketLiabilityList';
import useFilters from '../../../../../hooks/useFilters';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import useTableColumnToggle from '../../../../../hooks/useTableColumnToggle';
import useTableOrderByChange from '../../../../../hooks/useTableOrderByChange';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { useIsOperator } from '../../../../../providers/OperatorProvider';
import MarketLiabilityTable, {
  initialToggledColumns,
} from './MarketLiabilityTable';
import { tableNames } from '../../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../../components/ErrorWidget';

export const TEST_PLAYER_CHOICE = {
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
  LOOKUP: 'lookup',
};

const MarketLiabilityTab = () => {
  const navigate = useNavigate();
  const isOperator = useIsOperator();
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.MarketLiabilityTab,
    onChangeLimit: () => {},
  });
  const [toggledColumns, onToggleColumns] = useTableColumnToggle(
    initialToggledColumns
  );
  const { eventId } = useParams();
  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange({
    initialOrderBy: ['liability_single'],
    initialOrderByRotation: OrderDirection.ASC,
  });

  const refetchDataRef = useRef(() => {});

  const refetchData = useCallback(() => {
    return refetchDataRef.current();
  }, []);

  const defaultFiltersValues = useMemo(() => {
    return {
      filterOperatorId: [],
      filterBrandId: [],
      rangeDate: {
        rangeFrom: null,
        rangeTo: null,
      },
      filterSportId: [],
      filterEventName: [],
      filterCategoryName: [],
      filterTournamentName: [],
      filterCategoryId: [],
      filterTournamentId: [],
      filterEventId: [eventId],
      filterMarketName: [],
      filterOutcomeName: [],
      filterMarketProvider: [],
      rangePlayers: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeBets: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeTurnover: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeLiabilitySingle: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeLiabilityCombo: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangePlayerLifetimeBets: {
        rangeFrom: null,
        rangeTo: null,
      },
    };
  }, [eventId]);

  const { filtersCard, filters } = useFilters({
    tableName: tableNames.MarketLiabilityTab,
    resetOffset: setOffset,
    onResubmit: refetchData,
    defaultValues: defaultFiltersValues,
    filters: [
      {
        name: 'testPlayers',
        label: 'Test players',
        // fullWidth: false,
        component: (
          <Select
            options={[
              {
                label: 'Exclude',
                value: TEST_PLAYER_CHOICE.EXCLUDE,
              },
              {
                label: 'Include',
                value: TEST_PLAYER_CHOICE.INCLUDE,
              },
              {
                label: 'Lookup',
                value: TEST_PLAYER_CHOICE.LOOKUP,
              },
            ]}
          />
        ),
        toggled: false,
      },
      {
        name: 'filterOperatorId',
        label: 'Choose Operator',
        component: <OperatorSelect multiple />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'filterBrandId',
        label: 'Choose Brand',
        component: ({ filterOperatorId, ...props }) => {
          return (
            <BrandSelect operatorIds={filterOperatorId} multiple {...props} />
          );
        },
        toggled: true,
        deps: ['filterOperatorId'],
        hidden: isOperator,
      },
      {
        name: 'rangeDate',
        label: 'Date',
        fullWidth: false,
        component: <RangeDateInput />,
        toggled: true,
      },
      {
        name: 'filterSportId',
        label: 'Sport',
        fullWidth: false,
        component: <SportsSelect multiple />,
        hidden: isOperator,
      },
      {
        name: 'filterCategoryId',
        label: 'Category ID',
        fullWidth: false,
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterTournamentId',
        label: 'Tournament ID',
        fullWidth: false,
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterEventId',
        label: 'Event ID',
        fullWidth: false,
        component: <ArrayChipFilter />,
        toggled: false,
      },
      {
        name: 'filterMarketId',
        label: 'Market ID',
        fullWidth: false,
        component: <ArrayChipFilter />,
        toggled: false,
      },
      {
        name: 'filterOutcomeId',
        label: 'Outcome ID',
        fullWidth: false,
        component: <ArrayChipFilter />,
        toggled: false,
        hidden: isOperator,
      },
      {
        name: 'filterEventName',
        label: 'Event',
        fullWidth: false,
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterCategoryName',
        label: 'Category',
        fullWidth: false,
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterTournamentName',
        label: 'Tournament',
        fullWidth: false,
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterMarketName',
        label: 'Market',
        fullWidth: false,
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterOutcomeName',
        label: 'Outcome',
        fullWidth: false,
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterMarketProvider',
        label: 'Provider',
        fullWidth: false,
        component: <MarketProviderSelect multiple type="reports" />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'rangePlayers',
        label: 'Players',
        fullWidth: false,
        component: <NumberRangeInput minFrom={-1000} maxTo={1000} />,
        toggled: true,
      },
      {
        name: 'rangeBets',
        label: 'Bets',
        fullWidth: false,
        component: <NumberRangeInput minFrom={-1000} maxTo={1000} />,
        toggled: true,
      },
      {
        name: 'rangeTurnover',
        label: 'Turnover',
        fullWidth: false,
        component: <NumberRangeInput minFrom={-1000} maxTo={1000} />,
        toggled: true,
      },
      {
        name: 'rangeLiabilitySingle',
        label: 'Liability (single)',
        fullWidth: false,
        component: <NumberRangeInput minFrom={-1000} maxTo={1000} />,
        toggled: true,
      },
      {
        name: 'rangeLiabilityCombo',
        label: 'Liability (combo)',
        fullWidth: false,
        component: <NumberRangeInput minFrom={-1000} maxTo={1000} />,
        toggled: true,
      },
      {
        name: 'rangePlayerLifetimeBets',
        label: 'Player Lifetime Bets',
        fullWidth: false,
        component: <NumberRangeInput minFrom={-1000} maxTo={1000} />,
        toggled: true,
      },
      {
        name: 'excludeFake',
        label: 'Exclude Fake',
        component: <Checkbox />,
      },
    ],
  });

  const inputFilters = useMemo(() => {
    return {
      filterEventId: eventId,
      limit,
      offset,
      orderBy,
      orderByAscDesc,
      ...withoutEmpty(filters),
    };
  }, [filters, eventId, orderBy, orderByAscDesc, limit, offset]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(
    isOperator ? getOperatorMarketLiabilityList : getMarketLiabilityList,
    {
      variables: {
        inputFilters,
      },
    }
  );

  refetchDataRef.current = refetch;

  const actions = useMemo(() => {
    return [filtersCard];
  }, [filtersCard]);

  const items = useMemo(() => {
    return (data && data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  const rowActions = useMemo(
    () => [
      {
        key: 'outcomeDetails',
        label: 'Outcome Details',
        onClick: item => {
          navigate(
            `/api/v1/ReporterAPI/report/outcome/detail/${item.eventId}/${item.marketId}/${item.outcomeId}/get`
          );
        },
      },
    ],
    [navigate]
  );

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TableActionCards animated actions={actions} />
      <MarketLiabilityTable
        toggledColumns={toggledColumns}
        onToggleColumns={onToggleColumns}
        loading={loading}
        data={items}
        rowActions={rowActions}
        orderBy={orderBy}
        onChangeOrderBy={onChangeOrderBy}
      />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default MarketLiabilityTab;
