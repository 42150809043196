import { gql } from '@apollo/client';

const getBetsStatus = gql`
  query getBetHistoryList($inputFilters: BetHistoryTableFilters) {
    data: getBetHistoryList(inputFilters: $inputFilters) {
      items {
        playerId
        betId
        betStatus
      }
    }
  }
`;

export default getBetsStatus;
