import StringField from '../StringField/StringField';
import { forwardRef, memo, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

export const formatCurrency = (
  amount,
  currency,
  options = {},
  locale = 'en-us'
) => {
  if (isEmpty(options)) {
    options = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    };
  }

  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      ...options,
    }).format(amount);
  } catch (e) {
    const { style, ...optionsWithoutStyle } = options;

    return `${currency ? currency + ' ' : ''}${new Intl.NumberFormat(
      locale,
      optionsWithoutStyle
    ).format(amount)}`;
  }
};

/**
  Kkkekekek
 */
const CurrencyField = memo(
  forwardRef(({ amount, currency, bold, formatOptions, ...props }, ref) => {
    const formatted = useMemo(
      () => formatCurrency(amount, currency, formatOptions),
      [amount, currency, formatOptions]
    );

    return (
      <StringField ref={ref} bold={bold} {...props}>
        {formatted}
      </StringField>
    );
  })
);

CurrencyField.propTypes = {
  /**
    Use the loading state to indicate that the data Avatar needs is still loading.
  */
  amount: PropTypes.number,
};

export default CurrencyField;
