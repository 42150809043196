import { ClickAwayListener, useButton } from '@mui/material';
import React, {
  Fragment,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import ExpandIcon from '../../../../icons/controls/ExpandIcon';
import { animated, config, useTransition } from 'react-spring';
import TableCell from '../../Table/TableCell';
import StringField from '../StringField/StringField';
import Tooltip from '../../Tooltip/Tooltip';

const useStyles = makeStyles(theme => ({
  selectionButtonText: {
    color: '#1581FF',
    fontSize: '11px',
    letterSpacing: '0.2px',
    cursor: 'pointer',
  },

  selectionsButton: {
    position: 'absolute',
    backgroundColor: '#E8F2FF',
    height: 36,
    border: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px 0px 16px',
    // color: '#1581FF',
    // fontSize: '11px',
    // letterSpacing: '0.2px',
    // cursor: 'pointer',
    minWidth: 48,
    '&:hover': {
      backgroundColor: theme.palette.blue[200],
    },
  },

  expandedSelectionsButton: {
    backgroundColor: '#fff',
    position: 'relative',

    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  buttonLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  expandIcon: {
    marginLeft: 4,
    minWidth: 'auto',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 11px',
    height: 30,
  },

  selectionsContent: {
    position: 'absolute',
    marginLeft: -11,
    marginTop: 6,
    top: 0,
    zIndex: 2,
    backgroundColor: '#fff',
    boxShadow:
      '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
    borderRadius: 8,
  },
  selections: {
    marginTop: 4,
  },
  divider: {
    height: 2,
    width: 'calc(100% - 16px)',
    margin: '0 auto',
    borderRadius: '1px',
    backgroundColor: '#F7F7F7',
  },
  selectionRow: {
    height: '42px!important',
    flexDirection: 'row!important',
    padding: '2px 7px!important',
    alignItems: 'center',
  },
}));

const BetSelections = memo(
  forwardRef(
    (
      { selections, columns, oddsTotal, expanded, onExpand, columnWidth = {} },
      ref
    ) => {
      const classes = useStyles();
      const { getRootProps } = useButton({
        ref,
        component: 'button',
      });

      const transitions = useTransition(expanded, {
        from: { opacity: 0, height: 0 },
        enter: { opacity: 1, height: 36 + 42 * selections.length },
        leave: { opacity: 0, height: 0 },
        config: config.stiff,
      });

      const columnsWidth = useMemo(
        () =>
          columns
            .map(column => column.key)
            .reduce((acc, columnKey) => acc + columnWidth[columnKey], -4),
        [columnWidth, columns]
      );

      return (
        <>
          <button
            title={`${selections.length} selections`}
            className={clsx(
              classes.selectionsButton,
              classes.selectionButtonText
            )}
            style={{
              width: `${columnsWidth}px`,
            }}
            {...getRootProps()}
            onClick={onExpand}
          />
          <div
            onClick={onExpand}
            className={classes.selectionButtonText}
            style={{ zIndex: 1, marginLeft: '16px' }}
          >
            {selections.length}
          </div>
          &nbsp;
          <div
            onClick={onExpand}
            style={{ zIndex: 1 }}
            className={clsx(classes.buttonLabel, classes.selectionButtonText)}
          >
            selections
          </div>
          <ExpandIcon
            className={classes.expandIcon}
            expanded={expanded}
            onClick={onExpand}
          />
          {transitions((style, item) => {
            return (
              item && (
                <ClickAwayListener onClickAway={() => onExpand(false)}>
                  <animated.div
                    className={classes.selectionsContent}
                    style={style}
                  >
                    <div className={classes.header}>
                      <button
                        className={clsx(
                          classes.selectionsButton,
                          classes.selectionButtonText,
                          classes.expandedSelectionsButton
                        )}
                        {...getRootProps()}
                        onClick={onExpand}
                      >
                        <div>{selections.length}</div>&nbsp;
                        <div className={classes.buttonLabel}>selections</div>
                        <ExpandIcon
                          className={classes.expandIcon}
                          expanded={expanded}
                        />
                      </button>

                      <StringField bold number>
                        {oddsTotal}
                      </StringField>
                    </div>

                    <div className={classes.divider} />

                    <div className={classes.selections}>
                      {selections.map((selection, index) => (
                        <div
                          className={clsx('tableRow', classes.selectionRow)}
                          key={index}
                        >
                          {columns.map(column => (
                            <TableCell
                              key={column.key}
                              deps={column.deps}
                              rowData={selection}
                              cellClassName={column.cellClassName}
                              render={column.render}
                              style={column.style}
                              width={columnWidth[column.key]}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  </animated.div>
                </ClickAwayListener>
              )
            );
          })}
        </>
      );
    }
  )
);

const BetSelectionsField = forwardRef(
  ({ selections, columns, oddsTotal, columnWidth = {} }, ref) => {
    const [expanded, setExpanded] = useState(false);

    const onExpand = useCallback(() => {
      setExpanded(e => !e);
    }, []);

    return (
      <BetSelections
        ref={ref}
        selections={selections}
        columns={columns}
        oddsTotal={oddsTotal}
        columnWidth={columnWidth}
        onExpand={onExpand}
        expanded={expanded}
      />
    );
  }
);

export default memo(BetSelectionsField);
