import { useContext } from 'react';
import { DataContext } from '../providers/DataProvider';

const useDataContext = () => {
  const {
    path,
    data,
    loading,
    fetchData,
    error,
    operationId,
    method,
    url,
    query,
    params,
    xStream,
  } = useContext(DataContext);
  return {
    path,
    data,
    loading,
    fetchData,
    error,
    operationId,
    method,
    url,
    query,
    params,
    xStream,
  };
};

export default useDataContext;
