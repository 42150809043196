import React, { useContext } from 'react';
import { useRawSchema } from './RawSchemaProvider';

export const RoutesContext = React.createContext({});

const RoutesProvider = ({ children }) => {
  const { routes } = useRawSchema('routes');

  return (
    <RoutesContext.Provider value={routes}>{children}</RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  return useContext(RoutesContext);
};

export default RoutesProvider;
