import React, { Children, useContext } from 'react';
import TabItem from '../../components/TabItem';
import ControlledTabbedView from '../../components/ControlledTabbedView';
import { RoutesContext } from '../../providers/RoutesProvider';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  tabItemRoot: {
    background: '#fff',
    padding: 2,
    paddingTop: 24,
    height: 'calc(100% - 40px)',
    border: '4px solid #fff',
    marginTop: -8,
    borderRadius: 8,
    borderTopWidth: 8,
  },
  tabItemSingleTabRoot: {
    paddingTop: 2,
  },
  tabItemContent: {
    borderRadius: 6,
    background: '#F7F7F7',
    padding: 6,
    overflow: 'hidden',
  },
});

const TabContainer = ({ schema: { title }, children }) => {
  const routes = useContext(RoutesContext);
  const classes = useStyles();

  const totalTabs = Children.count(children);
  const isOneTab = totalTabs === 1;

  if (isOneTab) {
    return children;
  }

  return (
    <div>
      <ControlledTabbedView title={title}>
        {Children.map(children, child => (
          <TabItem
            label={routes[child.props.path].summary}
            classes={{
              root: clsx(classes.tabItemRoot, {
                [classes.tabItemSingleTabRoot]: totalTabs === 1,
              }),
            }}
          >
            <div className={classes.tabItemContent}>{child}</div>
          </TabItem>
        ))}
      </ControlledTabbedView>
    </div>
  );
};

export default TabContainer;
