import { useMemo } from 'react';
import TableSearchInputActionCard from '../components/TableActionCards/TableSearchInputActionCard';

const useSearchInputActionCard = ({
  searchValue,
  setSearchValue = () => {},
  metaData,
  label = 'Input',
  options,
  multiple,
  span,
}) => {
  return useMemo(
    () => ({
      span,
      key: 'search',
      render: (style, onChangeHeight, inline, large) => (
        <TableSearchInputActionCard
          style={style}
          inline={inline}
          label={label}
          setSearchValue={setSearchValue}
          options={options}
          multiple={multiple}
          metaData={metaData}
        />
      ),
    }),
    [span, label, options, setSearchValue, multiple, metaData]
  );
};

export default useSearchInputActionCard;
