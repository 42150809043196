import * as React from 'react';
import SchemaProvider, { useSchema } from '../../providers/SchemaProvider';
import UiComponentSchemaProvider, {
  useUiComponentSchema,
} from '../../providers/UiComponentSchemaProvider';
import StaticComponent from '../../components/StaticComponent';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';

const CardContainer = () => {
  const schema = useSchema();
  const uiSchema = useUiComponentSchema();

  const childrenSchema = schema.properties;

  return (
    <Grid container spacing={2}>
      {childrenSchema &&
        Object.keys(childrenSchema).map(childKey => {
          const uiComponentSchema = uiSchema[childKey];
          const uiSettings = get(uiComponentSchema, 'ui:settings', {});
          const sizes = uiSettings.size || {};

          return (
            <SchemaProvider value={childrenSchema[childKey]} key={childKey}>
              <UiComponentSchemaProvider value={uiSchema[childKey]}>
                <Grid item {...sizes}>
                  <StaticComponent />
                </Grid>
              </UiComponentSchemaProvider>
            </SchemaProvider>
          );
        })}
    </Grid>
  );
};

export default CardContainer;
