import { useMutation } from '@apollo/client';
import editEventMutation from '../../gql/EventsGroups/mutations/editEvent';
import mergeGroupsMutation from '../../gql/EventsGroups/mutations/mergeGroups';
import unmergeGroupItemMutation from '../../gql/EventsGroups/mutations/unmergeGroupItem';
import disableAutocreatableTournaments from '../../gql/EventsGroups/mutations/disableAutocreatableTournaments';
import createEventMutation from '../../gql/EventsGroups/mutations/createEvent';

const useMergerAPI = () => {
  const [create] = useMutation(createEventMutation);
  const [edit] = useMutation(editEventMutation);
  const [merge] = useMutation(mergeGroupsMutation);
  const [unmerge] = useMutation(unmergeGroupItemMutation);
  const [disable] = useMutation(disableAutocreatableTournaments);

  const createEvent = data => {
    return create({ variables: data }).then(
      response => response?.data?.createMatch
    );
  };
  const editEvent = data => {
    return edit({ variables: data }).then(
      response => response?.data?.editEvent
    );
  };

  const mergeGroups = data => {
    return merge({ variables: { inputGroups: data } }).then(
      response => response?.data?.mergeGroups
    );
  };

  const unmergeGroupItem = data => {
    return unmerge({ variables: { inputGroupItem: data } }).then(
      response => response?.data?.mergeGroups
    );
  };

  const disableAutoCreatability = data => {
    return disable({ variables: { autocreatableTournaments: data } }).then(
      response => response?.data?.disableAutocreatableTournaments
    );
  };

  return {
    createEvent,
    editEvent,
    mergeGroups,
    unmergeGroupItem,
    disableAutoCreatability,
  };
};

export default useMergerAPI;
