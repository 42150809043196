import { useTheme } from '@mui/material';

export const ColorEnum = {
  RED: 'red',
  GREEN: 'green',
  BLUE: 'blue',
  ORANGE: 'orange',
  WARNING: 'warning',
  CYAN: 'cyan',
  GREY: 'grey',
};

export const ColorPaletteMap = {
  [ColorEnum.RED]: 'error',
  [ColorEnum.GREEN]: 'success',
  [ColorEnum.BLUE]: 'primary',
  [ColorEnum.ORANGE]: 'warning',
  [ColorEnum.WARNING]: 'warning',
  [ColorEnum.CYAN]: 'info',
  [ColorEnum.GREY]: 'grey',
};

export const useColor = color => {
  const theme = useTheme();

  const paletteColorKey = ColorPaletteMap[color];

  if (paletteColorKey) {
    const paletteColor = theme.palette[paletteColorKey];

    if (paletteColor) {
      return paletteColor.main || paletteColor[500] || color;
    }
  }

  return color;
};

export const usePaletteColor = color => {
  const theme = useTheme();

  const searchByColor = Object.keys(theme.palette).find(
    key => theme.palette[key].main === color
  );
  const paletteColorKey =
    ColorPaletteMap[color] || ColorPaletteMap[searchByColor];

  if (paletteColorKey) {
    const paletteColor = theme.palette[paletteColorKey];

    if (paletteColor) {
      return paletteColor;
    }
  }
};

export default useColor;
