import { gql } from '@apollo/client';

const getBannersList = gql`
  query getBannersList($input: BannerTableFilters) {
    data: getBannersList(input: $input) {
      total
      items {
        id
        name
        banner_type
        view_type
        activate {
          type
          period {
            range_from
            range_to
          }
        }
        brand {
          id
          name
        }
        state
      }
    }
  }
`;

export default getBannersList;
