import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useApiDelete,
  useApiGet,
  useApiPatch,
  useApiPost,
  useApiPut,
} from '../hooks/useApi';
import EditPresetDialogProvider from '../../providers/EditPresetDialogProvider';
import CreatePresetDialogProvider from '../../providers/CreatePresetDialogProvider';
import omit from 'lodash/omit';
import { useSessionStorage } from '../../hooks/useStorage';
import { PresetsProviderContext } from '../../providers/PresetsProvider';

export const usePresets = () => {
  return useContext(PresetsProviderContext);
};

const usePresetsByOperationId = (operationId, gql = false) => {
  const [presets, setPresets] = useState();
  const [loading, setLoading] = useState(true);

  const PATH = `/api/v1/Presets/presets${gql ? '/graphql' : ''}`;

  const fetchPresetsApi = useApiGet(`${PATH}/${operationId}`);

  const createPresetApi = useApiPost();
  const reorderPresetsApi = useApiPost();
  const savePresetApi = useApiPut();
  const saveDefaultPresetApi = useApiPatch();
  const deletePresetApi = useApiDelete();

  const fetchPresets = useCallback(async () => {
    setLoading(true);
    fetchPresetsApi()
      .then(data => {
        if (data && data.items) {
          setPresets(data.items);
        } else {
          throw new Error('Error loading presets');
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchPresetsApi, setPresets]);

  const savePreset = useCallback(
    (presetId, data) => {
      return savePresetApi({
        url: `${PATH}/${presetId}`,
        data: omit(data, [
          'id',
          'user_id',
          'operation_id',
          'order',
          'is_deleted',
          'is_default',
          'data.initial_filters',
        ]),
        throwError: true,
      });
    },
    [savePresetApi, PATH]
  );

  const saveDefaultPreset = useCallback(
    data => {
      return saveDefaultPresetApi(`${PATH}/default/${operationId}`, {
        data: {
          rows_per_page: data.data.rows_per_page,
          columns_width: data.data.columns_width,
          columns_order: data.data.columns_order,
          toggled_columns: data.data.toggled_columns,
        },
      });
    },
    [saveDefaultPresetApi, operationId, PATH]
  );

  const deletePreset = useCallback(
    presetId => {
      return deletePresetApi(`${PATH}/delete/${presetId}`);
    },
    [deletePresetApi, PATH]
  );

  const createPreset = useCallback(
    data => {
      return createPresetApi({
        url: PATH,
        data,
        throwError: true,
      });
    },
    [createPresetApi, PATH]
  );

  const reorderPresets = useCallback(
    ids => {
      return reorderPresetsApi({
        url: `${PATH}/reorder`,
        data: {
          items: ids,
          operation_id: operationId,
        },
      });
    },
    [reorderPresetsApi, operationId, PATH]
  );

  return useMemo(
    () => ({
      createPreset,
      reorderPresets,
      fetchPresets,
      presets,
      savePreset,
      deletePreset,
      saveDefaultPreset,
      loading,
    }),
    [
      savePreset,
      reorderPresets,
      fetchPresets,
      presets,
      createPreset,
      saveDefaultPreset,
      deletePreset,
      loading,
    ]
  );
};

const PresetsProvider = ({ operationId, children, gql = false }) => {
  const {
    fetchPresets,
    createPreset,
    presets: persistedPresets,
    savePreset: savePresetApi,
    deletePreset,
    reorderPresets: reorderPresetsApi,
    saveDefaultPreset: saveDefaultPresetApi,
    loading,
  } = usePresetsByOperationId(operationId, gql);

  const [persistedActivePresetId, setPersistedActivePresetId] =
    useSessionStorage('persistedActivePresetId', null);

  const [state, setState] = useState({
    activePresetId: persistedActivePresetId || '0',
    presets: persistedPresets,
  });

  const activePresetId = state.activePresetId;

  useEffect(() => {
    if (activePresetId) {
      setPersistedActivePresetId(activePresetId);
    }
  }, [activePresetId, setPersistedActivePresetId]);

  useEffect(() => {
    fetchPresets();
  }, [fetchPresets]);

  useEffect(() => {
    if (persistedPresets) {
      const persistedPreset =
        persistedPresets &&
        persistedPresets.find(p => p.id === persistedActivePresetId);

      if (!persistedPreset) {
        setPersistedActivePresetId('0');
      }

      setState({
        activePresetId: persistedPreset ? persistedActivePresetId : '0',
        presets: persistedPresets,
      });
    }
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedPresets]);

  const setActivePresetId = useCallback(activePresetId => {
    setState(currentState => {
      return {
        ...currentState,
        activePresetId,
      };
    });
  }, []);

  const updatePresetsState = useCallback(
    (id, data) => {
      setState(state => {
        return {
          ...state,
          presets: state.presets.map(preset => {
            if (preset.id === id) {
              return {
                ...preset,
                ...data,
              };
            }
            return preset;
          }),
        };
      });
    },
    [setState]
  );

  const savePreset = useCallback(
    (id, data) => {
      if (!data.is_default) {
        return savePresetApi(id, data);
      } else {
        return saveDefaultPresetApi(data);
      }
    },
    [savePresetApi, saveDefaultPresetApi]
  );

  const updatePreset = useCallback(
    (id, data) => {
      updatePresetsState(id, data);
      savePreset(id, data);
    },
    [updatePresetsState, savePreset]
  );

  const reorderPresets = useCallback(
    presets => {
      setState(state => ({
        ...state,
        presets,
      }));

      return reorderPresetsApi(presets.map(preset => preset.id));
    },
    [reorderPresetsApi, setState]
  );

  const removePreset = useCallback(
    id => {
      setState(state => ({
        activePresetId:
          state.activePresetId === id ? '0' : state.activePresetId,
        presets: state.presets.filter(preset => preset.id !== id),
      }));

      return deletePreset(id);
    },
    [deletePreset]
  );

  const providerValue = useMemo(() => {
    return {
      reorderPresets,
      updatePreset,
      fetchPresets,
      activePresetId,
      activePreset: state.presets
        ? state.presets.find(item => item.id === state.activePresetId)
        : null,
      presets: state.presets,
      savePreset,
      updatePresetsState,
      setActivePresetId,
      removePreset,
      createPreset,
      loading,
    };
  }, [
    loading,
    activePresetId,
    reorderPresets,
    updatePreset,
    savePreset,
    updatePresetsState,
    fetchPresets,
    state,
    setActivePresetId,
    createPreset,
    removePreset,
  ]);

  return (
    <PresetsProviderContext.Provider value={providerValue}>
      <EditPresetDialogProvider>
        <CreatePresetDialogProvider operationId={operationId}>
          {children}
        </CreatePresetDialogProvider>
      </EditPresetDialogProvider>
    </PresetsProviderContext.Provider>
  );
};

export default PresetsProvider;
