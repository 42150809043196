import React, { useState } from 'react';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes';
import useAuth from '../../../../v2/hooks/auth';
import successIcon from '../../assets/success.svg';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const { forgotPassword, loading } = useAuth();
  const [success, setSuccess] = useState(false);

  const CAPTION = success
    ? 'A link to change your password has been sent to your account email.'
    : 'Please enter your email address. You will receive an email message with instructions on how to reset your password.';

  const handleSubmit = credentials => {
    forgotPassword({
      ...credentials,
    }).then(response => {
      setSuccess(true);
    });
  };

  return (
    <Stack gap={'15px'} justifyContent={'center'} alignItems={'center'}>
      {success && (
        <img src={successIcon} style={{ marginBottom: '20px' }} alt="" />
      )}
      <Typography
        fontWeight={600}
        fontSize={'29px'}
        lineHeight={'33px'}
        textAlign={'center'}
      >
        Request to change forgotten password
      </Typography>
      <Typography
        textAlign={'center'}
        maxWidth={'320px'}
        color={'#93a3b5'}
        fontSize={'14px'}
        lineHeight={'20px'}
      >
        {CAPTION}
      </Typography>
      {!success && (
        <ForgotPasswordForm
          loading={loading}
          onSubmit={handleSubmit}
          onBack={() => navigate(`../${paths.login}`)}
        />
      )}
    </Stack>
  );
};

export default ForgotPasswordPage;
