import { useQuery } from '@apollo/client';
import getAutocreatableTournaments from '../../gql/EventsGroups/queries/getAutocreatableTournaments';

const useAutocreatableTournaments = (options = {}) => {
  const { data, refetch, loading, error } = useQuery(
    getAutocreatableTournaments,
    options
  );

  let tournaments;
  let pageInfo;

  if (data) {
    tournaments = data.getAutocreatableTournaments.items;
    pageInfo = { total: data.getAutocreatableTournaments.total };
  }

  return { tournaments, pageInfo, refetchTournaments: refetch, loading, error };
};

export default useAutocreatableTournaments;
