import { gql } from '@apollo/client';
import * as builder from 'gql-query-builder';
import { useEffect, useState } from 'react';
import { isObject, groupBy } from 'lodash';
import { mergeObjectsArray } from '../utils/merge';

const mapFieldsToQueryFields = (fields, mapper) => {
  const mapped = fields.reduce((acc, el) => {
    if (mapper[el]) {
      acc.push(...mapper[el]);
    } else {
      acc.push(el);
    }
    return acc;
  }, []);

  // divide all fields into two groups; returns object { true?: <objects>, false?: <primitives> }
  const groups = groupBy(mapped, field => isObject(field));

  return [...(groups[false] || []), mergeObjectsArray(groups[true] || [])];
};

const useDynamicQuery = (
  name,
  { fields, variables, mapper = {}, template = fields => fields }
) => {
  const [query, setQuery] = useState(
    (() => {
      const result = builder.query({
        variables,
        operation: name,
        fields: template
          ? template(mapFieldsToQueryFields(fields, mapper))
          : mapFieldsToQueryFields(fields, mapper),
      });

      // LOG INITIAL QUERY HERE

      return gql`
        ${result.query}
      `;
    })()
  );

  useEffect(() => {
    const result = builder.query({
      variables,
      operation: name,
      fields: template
        ? template(mapFieldsToQueryFields(fields, mapper))
        : mapFieldsToQueryFields(fields, mapper),
    });

    // LOG ALL PRODUCED QUERIES HERE

    setQuery(
      gql`
        ${result.query}
      `
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return {
    query,
  };
};

export default useDynamicQuery;
