import React, { Fragment } from 'react';
import BannerLinkField from '../../BannerFields/BannerLinkField';
import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import BannerTextField from '../../BannerFields/BannerTextField';
import BannerImageUploader from '../../BannerImageUploader/BannerImageUploader';
import Stack from '@mui/material/Stack/Stack';
import PopularNowTextPreview from '../../BannerImagePreview/PopularNowTextPreview';
import FormField from '../../../../../components/Form/FormField';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import { useFormContext } from 'react-hook-form';

const TextView = ({ prefix }) => {
  const { watch } = useFormContext();
  const brand = watch('brand');

  return (
    <ImagePreviewProvider>
      <LocaleTabs
        name={`${prefix}.locales`}
        content={(localeField, localeIndex) => (
          <Fragment key={localeField.locale.country + localeField.locale.lang}>
            <Stack direction={'column'} spacing={2}>
              <BannerLinkField name={`${prefix}.locales.${localeIndex}.link`} />

              <Grid item xs={12}>
                <FormGroup large>
                  <Grid container spacing={2} direction={'column'}>
                    <Grid item container xs={12} spacing={2}>
                      <BannerTextField
                        maxLength={33}
                        name={`${prefix}.locales.${localeIndex}.title`}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>

              {brand && (
                <Grid item xs={12}>
                  <FormGroup large>
                    <FormField
                      name={`${prefix}.locales.${localeIndex}.images.desktop`}
                    >
                      <BannerImageUploader
                        preview={PopularNowTextPreview}
                        cropSize={{
                          width: 128,
                          height: 88,
                        }}
                        useGradient
                        previewFields={[
                          `${prefix}.locales.${localeIndex}.title`,
                        ]}
                      />
                    </FormField>
                  </FormGroup>
                </Grid>
              )}
            </Stack>
          </Fragment>
        )}
      />
    </ImagePreviewProvider>
  );
};

export default TextView;
