import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '../../../../../components/Inputs/TextField';
import Button from '../../../../../components/Button/Button';

const useStyles = makeStyles({
  changeMarketNameForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },
  changeMarketNameFormButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    justifyContent: 'flex-end',
  },
  formButton: {
    minWidth: 'unset',
    marginLeft: 18,
  },
});

const ChangeMarketNameForm = ({ onCancel, onSubmit }) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const isChangeButtonDisabled =
    !value || (typeof value === 'string' && !value.trim());

  return (
    <div className={classes.changeMarketNameForm}>
      <TextField
        label={`Market name`}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <div className={classes.changeMarketNameFormButtons}>
        <Button
          color={'lightBlue'}
          onClick={onCancel}
          className={classes.formButton}
        >
          Cancel
        </Button>
        <Button
          color={'primary'}
          onClick={() => onSubmit(value)}
          className={classes.formButton}
          disabled={isChangeButtonDisabled}
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default ChangeMarketNameForm;
