import React from 'react';
import { getObjectFilters } from '../useAvailableAndVisibleFilters';
import FilterField from '../FilterField';
import makeStyles from '@mui/styles/makeStyles';
import HideIcon from '../../../icons/controls/HideIcon';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import get from 'lodash/get';

const useStyles = makeStyles(theme => ({
  objectFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #DADFE6',
    borderRadius: 16,
    boxSizing: 'border-box',
    padding: 8,
    position: 'relative',
  },

  objectFilterRemovable: {
    borderTopRightRadius: 0,
  },

  objectCell: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
  },

  fullWidthObjectCell: {
    width: `calc(100% - ${theme.spacing(2)})!important`,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),

    '&+$fullWidthCell': {
      marginTop: 24,
    },
  },

  [theme.breakpoints.up('sm')]: {
    objectCell: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },
  },
  [theme.breakpoints.up('md')]: {
    objectCell: {
      width: `calc(100% / 3 - ${theme.spacing(3)})`,
    },
  },
  [theme.breakpoints.up('xl')]: {
    objectCell: {
      width: `calc(100% / 6 - ${theme.spacing(2)})`,
    },
  },

  hideFilterControl: {
    position: 'absolute',
    top: -12,
    right: -12,
  },
}));

const ObjectFilter = ({
  onHideFilterClick,
  onResetFilterValue,
  name,
  filter,
  persistFilters,
  fullWidth,
}) => {
  const classes = useStyles();
  const objectFilters = getObjectFilters(name, filter.schema, filter.uiSchema);

  return (
    <div
      className={clsx(classes.objectFilter, {
        [classes.objectFilterRemovable]: !filter.alwaysVisible,
      })}
    >
      {objectFilters.map(objectFilter => {
        return (
          <div
            className={clsx(classes.objectCell, {
              [classes.fullWidthObjectCell]: get(objectFilter, [
                'uiSchema',
                'ui:options',
                'fullwidth',
              ]),
            })}
          >
            <FilterField
              name={`${name}.${objectFilter.name}`}
              label={objectFilter.title}
              onHideFilterClick={() => onHideFilterClick(objectFilter)}
              onResetFilterValue={value =>
                onResetFilterValue(objectFilter, value)
              }
              fullWidth={fullWidth}
              persistFilters={persistFilters}
              {...objectFilter}
            />

            {!filter.alwaysVisible && (
              <div className={classes.hideFilterControl}>
                <IconButton
                  onClick={onHideFilterClick}
                  variant={'contained'}
                  color={'primary'}
                  size={'small'}
                >
                  <HideIcon className={classes.hideFilterButton} />
                </IconButton>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ObjectFilter;
