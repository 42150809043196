import useAutocompleteData from '../../../hooks/useAutocompleteData';
import Select from '../Select/Select';
import { forwardRef, useEffect } from 'react';
import useSelectCheckValueOptions from '../../../../hooks/useSelectCheckValueOptions';

const BrandSelect = forwardRef(
  (
    {
      operatorIds = [],
      withOperator = true,
      multiple,
      value,
      valueKey = 'id',
      onChange,
      disabled: isDisabled,
      ...props
    },
    ref
  ) => {
    const disabled = isDisabled || (!operatorIds.length && withOperator);
    const { data: options = [] } = useAutocompleteData({
      url: '/api/v1/Autocomplete/brands',
      params: withOperator
        ? {
            operator_id: operatorIds,
          }
        : {},
      disabled,
    });

    useSelectCheckValueOptions({
      value,
      options,
      valueKey,
      multiple,
      onChange,
      check: !((operatorIds.length || !withOperator) && value && value.length),
    });

    useEffect(() => {
      if (
        withOperator &&
        multiple &&
        value &&
        value.length &&
        !operatorIds.length
      ) {
        onChange([]);
      } else if (withOperator && value && !operatorIds.length && !multiple) {
        onChange('');
      }
    }, [onChange, operatorIds, value, multiple, withOperator]);

    return (
      <Select
        hintText={disabled && 'Operator must be specified'}
        disabled={disabled}
        ref={ref}
        label={'Brand'}
        multiple={multiple}
        options={options}
        labelKey={'name'}
        onChange={onChange}
        value={value}
        valueKey={valueKey}
        withTooltip
        {...props}
      />
    );
  }
);

export default BrandSelect;
