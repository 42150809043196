import React, { Fragment } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Control from './Control';
import SingleValue from './SingleValue';
import Menu from './Menu';
import ValueContainer from './ValueContainer';
import LoadingIndicator from './LoadingIndicator';
import GroupHeading from './GroupHeading';
import LoadingMessage from './LoadingMessage';
import Placeholder from './Placeholder';
import NoOptionsMessage from './NoOptionsMessage';
import MultiValue from './MultiValue';
import Option from './Option';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';

export const SelectComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage,
  GroupHeading,
  DropdownIndicator: null,
  ClearIndicator: null,
  LoadingIndicator,
};

const Autocomplete = ({
  label = '',
  helperText = '',
  isCreatable,
  ...otherProps
}) => {
  const theme = useTheme();
  const SelectComponent = isCreatable ? Creatable : Select;

  return (
    <Fragment>
      <SelectComponent
        styles={{
          input: base => ({
            ...base,
            margin: 0,
            padding: 0,
            color: theme.palette.text.primary,
            '& input': {
              font: 'inherit',
            },
          }),
          menuList: base => ({
            ...base,
            paddingTop: 0,
          }),
          indicatorsContainer: base => ({
            ...base,
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'flex-end',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
        textFieldProps={{
          label,
          variant: 'outlined',
        }}
        components={SelectComponents}
        placeholder="Enter at least 3 characters..."
        {...otherProps}
        isClearable={true}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </Fragment>
  );
};

export default Autocomplete;
