import React, { useCallback } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import classnames from 'clsx';
import CheckBoxActiveIcon from '../../../icons/controls/CheckBoxActiveIcon';
import CheckBoxIcon from '../../../icons/controls/CheckBoxIcon';
import CheckboxIndeterminateIcon from '../../../icons/controls/CheckboxIndeterminateIcon';
import { useFormContext } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
  },
  label: {
    width: '100%',
    marginLeft: -9,
  },
});

const BooleanFilter = ({
  label,
  fullWidth,
  InputProps: { endAdornment },
  className,
  onChange,
  uiSchema = {},
  ...props
}) => {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();
  const value = watch(props.name);
  const indeterminate = typeof value === 'undefined' || value === null;
  const { 'ui:options': uiOptions = {} } = uiSchema;

  const { indeterminateMode = true } = uiOptions;

  const onChangeCb = useCallback(
    value => {
      setValue(props.name, value);
      onChange(value);
    },
    [onChange, props.name, setValue]
  );

  return (
    <div className={classnames(classes.root, className)}>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxActiveIcon />}
            indeterminateIcon={<CheckboxIndeterminateIcon />}
            indeterminate={indeterminateMode && indeterminate}
            checked={value === true}
            color="primary"
            {...props}
            onChange={event => {
              if (!indeterminateMode) {
                return onChangeCb(event.target.checked);
              }

              if (indeterminate) {
                return onChangeCb(true);
              }

              if (value) {
                return onChangeCb(false);
              }

              return onChangeCb(null);
            }}
          />
        }
        label={label}
      />
      {endAdornment}
    </div>
  );
};

export default BooleanFilter;
