import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const ImagePreviewContext = createContext();

export const useImagePreviewByName = name => {
  const { previews, setPreview } = useContext(ImagePreviewContext);

  const previewValue = previews[name];
  const setPreviewValue = useCallback(
    value => {
      setPreview(name, value);
    },
    [name, setPreview]
  );

  return useMemo(
    () => ({
      previewValue,
      setPreviewValue,
    }),
    [previewValue, setPreviewValue]
  );
};

export const useImagePreviews = () => {
  const { previews, setPreview, setPreviews } = useContext(ImagePreviewContext);

  const getPreviewValue = useCallback(name => previews[name], [previews]);
  const setPreviewValue = useCallback(
    (name, value) => {
      setPreview(name, value);
    },
    [setPreview]
  );

  return useMemo(
    () => ({
      setPreviews,
      getPreviewValue,
      setPreviewValue,
    }),
    [getPreviewValue, setPreviewValue, setPreviews]
  );
};

const ImagePreviewProvider = memo(({ children }) => {
  const [previews, setPreviews] = useState({});

  const setPreview = useCallback((name, value) => {
    setPreviews(curr => ({
      ...curr,
      [name]: value,
    }));
  }, []);

  return (
    <ImagePreviewContext.Provider
      value={{
        setPreviews,
        setPreview,
        previews,
      }}
    >
      {children}
    </ImagePreviewContext.Provider>
  );
});

export default ImagePreviewProvider;
