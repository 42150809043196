import every from 'lodash/every';
import some from 'lodash/some';
import pickBy from 'lodash/pickBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useToggleList = (items, idKey, persistToggledOnListChange) => {
  const [toggledItems, setToggledItems] = useState({});

  const everyToggled = useMemo(
    () => every(items, item => toggledItems[item[idKey]]),
    [items, toggledItems, idKey]
  );

  const someToggled = useMemo(
    () => some(Object.values(toggledItems)),
    [toggledItems]
  );

  const reset = useCallback(() => {
    setToggledItems({});
  }, [setToggledItems]);

  const resetHidden = useCallback(() => {
    setToggledItems(currentItems =>
      pickBy(currentItems, (value, key) => {
        return items && items.find(item => item[idKey] === key);
      })
    );
  }, [setToggledItems, items, idKey]);

  useEffect(() => {
    if (!persistToggledOnListChange) {
      resetHidden();
    }
  }, [resetHidden, persistToggledOnListChange]);

  const toggle = useCallback(
    (id, toggled) => {
      setToggledItems(currentItems => {
        return {
          ...currentItems,
          [id]: typeof toggled === 'undefined' ? !currentItems[id] : toggled,
        };
      });
    },
    [setToggledItems]
  );

  const toggleAll = useCallback(
    toggled => {
      setToggledItems(currentItems => {
        const everyToggled = every(items, item => currentItems[item[idKey]]);

        toggled = typeof toggled === 'boolean' ? toggled : !everyToggled;

        if (everyToggled && !toggled) {
          return {};
        }

        return items.reduce((acc, item) => {
          return {
            ...acc,
            [item[idKey]]: toggled,
          };
        }, {});
      });
    },
    [setToggledItems, items, idKey]
  );

  return useMemo(() => {
    return {
      toggle,
      toggledItems,
      setToggledItems,
      resetHidden,
      reset,
      toggleAll,
      someToggled,
      everyToggled,
    };
  }, [
    toggledItems,
    setToggledItems,
    resetHidden,
    reset,
    toggle,
    toggleAll,
    someToggled,
    everyToggled,
  ]);
};

export default useToggleList;
