import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Page from '../../../components/Page/Page';
import MarginLadderEditForm from './MarginLadderEditForm';
import getMarginLadderDetailsQuery from '../../../gql/TradingTool/queries/getMarginLadderDetailsQuery';
import MarginLadderValidationSchema from './MarginLadderValidationSchema';

const MarginLadderEditPage = () => {
  const { ladderId } = useParams();

  const {
    data: { data: { items = [], name, sport_id, sport_name } = {} } = {},
    loading,
  } = useQuery(getMarginLadderDetailsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      ladderId,
    },
    skip: !ladderId,
  });

  const getInitialValues = useMemo(() => {
    const defaultValues = MarginLadderValidationSchema.default();

    return async () => {
      return new Promise(resolve => {
        if (!ladderId) {
          resolve({ ...defaultValues });
        } else {
          resolve({
            name,
            sport:
              sport_id && sport_name
                ? {
                    id: sport_id,
                    name: sport_name,
                  }
                : null,
            low: null,
            high: null,
            pairs: items,
          });
        }
      });
    };
  }, [ladderId, name, sport_id, sport_name, items]);

  return (
    <Page title={`${ladderId ? 'Edit' : 'Create'} margin ladder`}>
      {!loading && (
        <MarginLadderEditForm
          ladderId={ladderId}
          defaultValues={getInitialValues}
        />
      )}
    </Page>
  );
};

export default MarginLadderEditPage;
