import Autocomplete from './Autocomplete';
import useAutocompleteData from '../../hooks/useAutocompleteData';

// TODO replace with OperatorSelect
const OperatorAutocomplete = ({
  value: operatorId,
  onChange,
  helperText,
  error,
  disabled,
  required,
}) => {
  const {
    data: { items: options = [] },
  } = useAutocompleteData({ url: '/api/v1/Autocomplete/operators' });

  const getOptionSelected = (option, value) => {
    return option.id === value;
  };

  const value =
    options.find(option => getOptionSelected(option, operatorId)) || null;

  return (
    <Autocomplete
      disabled={disabled}
      required={required}
      error={error}
      options={options}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={option => {
        return option.name;
      }}
      InputProps={{
        label: 'Operator',
        helperText,
        error,
      }}
      value={value}
      onChange={(e, value) => {
        onChange(value ? value.id : null);
      }}
    />
  );
};

export default OperatorAutocomplete;
