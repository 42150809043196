import { useCallback, useEffect, useRef } from 'react';
import useInterval from './useInterval';

const useBatched = (callback, delay) => {
  const latestCallback = useRef(() => {});
  const messagesRef = useRef([]);
  const argsRef = useRef([]);

  useEffect(() => {
    latestCallback.current = callback;
  });

  useInterval(() => {
    latestCallback.current(messagesRef.current, ...argsRef.current);
    messagesRef.current = [];
  }, delay);

  return useCallback((message, ...args) => {
    messagesRef.current = [...messagesRef.current, message];
    argsRef.current = args;
  }, []);
};

export default useBatched;
