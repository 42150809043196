import makeStyles from '@mui/styles/makeStyles';
import EventLiveIcon from '../../v2/icons/EventLiveIcon.svg';

const useStyles = makeStyles({
  liveIcon: {
    backgroundImage: `url(${EventLiveIcon})`,
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },
});

const LiveIcon = ({ ...props }) => {
  const classes = useStyles();
  return <div {...props} className={classes.liveIcon} />;
};

export default LiveIcon;
