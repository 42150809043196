import React from 'react';
import RootContainer from '../containers/RootContainer';
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import cloneDeep from 'lodash/cloneDeep';
import lightTheme from '../themes/lightTheme';
import RawSchemaProvider from '../providers/RawSchemaProvider';
import DebugModeProvider from '../providers/DebugModeProvider';
import ConfirmationPromptProvider from '../providers/ConfirmationPromptProvider';
import NotificationPopupProvider from '../providers/NotificationPopupProvider';
import RoutesProvider from '../providers/RoutesProvider';
import ApiHostProvider from '../providers/ApiHostProvider';
import AppPageTitleProvider from '../providers/AppPageTitleProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import AuthProvider from '../v2/providers/AuthProvider';
import ToastsProvider from '../v2/providers/ToastsProvider';
import DynEnvsProvider from '../v2/providers/DynEnvsProvider';
import { ApplyRequiredGlobalProvider } from '../v2/providers/ApplyRequiredProvider';
import { AppStyles } from './AppStyles';

const generateClassName = createGenerateClassName({});

const appTheme = createTheme(adaptV4Theme(cloneDeep(lightTheme)));

const App = () => {
  return (
    <RawSchemaProvider>
      <RoutesProvider>
        <ApiHostProvider>
          <StylesProvider generateClassName={generateClassName}>
            <DebugModeProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={appTheme}>
                  <AppStyles>
                    <ConfirmationPromptProvider>
                      <NotificationPopupProvider>
                        <AuthProvider>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <AppPageTitleProvider>
                              <ToastsProvider>
                                <DynEnvsProvider>
                                  <ApplyRequiredGlobalProvider>
                                    <RootContainer />
                                  </ApplyRequiredGlobalProvider>
                                </DynEnvsProvider>
                              </ToastsProvider>
                            </AppPageTitleProvider>
                          </LocalizationProvider>
                        </AuthProvider>
                      </NotificationPopupProvider>
                    </ConfirmationPromptProvider>
                  </AppStyles>
                </ThemeProvider>
              </StyledEngineProvider>
            </DebugModeProvider>
          </StylesProvider>
        </ApiHostProvider>
      </RoutesProvider>
    </RawSchemaProvider>
  );
};

export default App;
