import React, { memo, useMemo } from 'react';
import shallowEqual from '../../utils/shallowEqual';
import clsx from 'clsx';
import get from 'lodash/get';

const areEqual = (
  {
    rowData: prevRowData,
    deps: prevDeps,
    columnWidthDeps: prevColumnWidthDeps,
    ...prevProps
  },
  {
    rowData: nextRowData,
    deps: nextDeps,
    columnWidthDeps: nextColumnWidthDeps,
    ...nextProps
  }
) => {
  const isEqualProps = shallowEqual(prevProps, nextProps);

  let isColumnsWidthEqual = true;

  if (nextColumnWidthDeps) {
    isColumnsWidthEqual = shallowEqual(
      prevColumnWidthDeps,
      nextColumnWidthDeps
    );
  }

  if (nextDeps) {
    const changed = nextDeps.find(dep => {
      return get(prevRowData, dep) !== get(nextRowData, dep);
    });

    return isEqualProps && isColumnsWidthEqual && !changed;
  }

  return isEqualProps && isColumnsWidthEqual;
};

const TableCell = ({
  name = '',
  style,
  width,
  render,
  cellClassName = () => {},
  rowData = {},
  summary,
  columnWidthDeps,
  children,
  className,
  rowClassName,
  isLast,
  divider = false,
  direction = 'row',
  alignItems,
  textAlign,
  justifyContent,
  minWidth,
}) => {
  const styles = useMemo(() => {
    if (style) {
      return {
        width,
        minWidth: minWidth && minWidth,
        ...style,
        alignItems,
        justifyContent,
        textAlign,
        flexDirection: direction,
      };
    }
    return {
      width,
    };
  }, [
    style,
    width,
    alignItems,
    justifyContent,
    direction,
    textAlign,
    minWidth,
  ]);

  return (
    <div
      id={name}
      className={clsx(
        'tableRowColumn',
        className,
        cellClassName(rowData),
        rowClassName,
        {
          tableRowColumnLast: isLast,
          tableRowColumnDivided: divider && !isLast,
        }
      )}
      style={styles}
    >
      {render ? render(rowData, summary, columnWidthDeps) : children}
    </div>
  );
};

export default memo(TableCell, areEqual);
