import * as React from 'react';

export const GridContext = React.createContext({});

const GridProvider = ({ children, totalItems }) => {
  return (
    <GridContext.Provider value={{ totalItems }}>
      {children}
    </GridContext.Provider>
  );
};

export default GridProvider;
