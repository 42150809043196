import Box from '@mui/material/Box';

const LineBannerTextPreview = ({ fields: [banner_title] }) => {
  return (
    <Box
      sx={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '12px',
        fontFamily: 'IBM Plex Sans',
        width: '360px',
        height: '152px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
        color: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          maxWidth: 200,
          overflowWrap: 'anywhere',
        }}
      >
        {banner_title}
      </Box>
    </Box>
  );
};

export default LineBannerTextPreview;
