import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

const mapValuesDeep = (obj, fn, pathArr = []) =>
  Array.isArray(obj)
    ? obj.map((val, i) => mapValuesDeep(val, fn, [...pathArr, i]))
    : isObject(obj)
    ? mapValues(obj, (val, key) => mapValuesDeep(val, fn, [...pathArr, key]))
    : fn(obj, pathArr);

window.mapValuesDeep = mapValuesDeep;

export default mapValuesDeep;
