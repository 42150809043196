import { gql } from '@apollo/client';

const getUserAccessRoles = gql`
  query getUserAccessRoles {
    data: getUserAccessRoles {
      coverageTemplate
      sourcesTemplate
    }
  }
`;

export default getUserAccessRoles;
