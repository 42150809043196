import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';

const PresetsSkeleton = ({ animation = false }) => {
  return (
    <Box display={'flex'}>
      <Box>
        <Skeleton
          style={{ width: 200, height: 60, transform: 'none' }}
          animation={animation}
        />
      </Box>
      <Box ml={1}>
        <Skeleton
          style={{ width: 200, height: 60, transform: 'none' }}
          animation={animation}
        />
      </Box>
      <Box ml={1}>
        <Skeleton
          style={{ width: 200, height: 60, transform: 'none' }}
          animation={animation}
        />
      </Box>
    </Box>
  );
};

export default PresetsSkeleton;
