import { useEffect, useRef } from 'react';

function useDocumentTitle(title, retainOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;

    const currentDefaultTitle = defaultTitle.current;

    return () => {
      if (!retainOnUnmount) document.title = currentDefaultTitle;
    };
  }, [title, retainOnUnmount]);
}

export default useDocumentTitle;
