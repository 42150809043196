import Select from '../../../../components/Inputs/Select/Select';
import {
  BannerType,
  DisplayPlatform,
} from '../BannerForm/BannerValidationSchema';
import { useFormContext } from 'react-hook-form';
import { forwardRef, useEffect } from 'react';

const BannerPlatformSelect = forwardRef(
  ({ onChange, value, name, ...props }, ref) => {
    const { watch, setValue } = useFormContext();
    const bannerType = watch('banner_type');

    useEffect(() => {
      if (bannerType === BannerType.CODE_BANNER) {
        setValue(name, [DisplayPlatform.DESKTOP]);
      }
    }, [bannerType, name, setValue]);

    return (
      <Select
        ref={ref}
        {...props}
        name={name}
        onChange={onChange}
        value={value}
        disabled={bannerType === BannerType.CODE_BANNER}
        label={'Show'}
        multiple
        options={[
          {
            label: 'Desktop',
            value: DisplayPlatform.DESKTOP,
          },
          {
            label: 'Mobile',
            value: DisplayPlatform.MOBILE,
          },
        ]}
      />
    );
  }
);

export default BannerPlatformSelect;
