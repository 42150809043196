import { cloneElement, forwardRef } from 'react';
import { useWatch } from 'react-hook-form';

const FormFieldDepsProvider = forwardRef(
  ({ deps, children, ...props }, ref) => {
    const named = !Array.isArray(deps);
    const values = useWatch({ name: named ? Object.values(deps) : deps });

    if (typeof children === 'function') {
      const component = children(
        (named ? Object.keys(deps) : deps).reduce((acc, dep, index) => {
          return {
            ...acc,
            [dep]: values[index],
          };
        }, props),
        ref
      );

      if (!component) {
        return null;
      }

      return cloneElement(component, {
        ref,
        ...props,
      });
    }

    return cloneElement(children, {
      ref,
    });
  }
);

export default FormFieldDepsProvider;
