/* eslint-disable no-undef */

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

class List extends React.Component {
  state = {
    openedChapter: null,
  };

  handleChapterClick = name => {
    this.setState({
      openedChapter: this.state.openedChapter === name ? null : name,
    });
  };

  render() {
    const { chapters, classes } = this.props;

    return (
      <div className={classes.container}>
        {chapters.map(chapter => (
          <div className={classes.chapter} key={`chapter-${chapter.name}`}>
            <Typography
              variant="subtitle1"
              onClick={() => this.handleChapterClick(chapter.name)}
            >
              {chapter.name}
            </Typography>
            {this.state.openedChapter === chapter.name ? (
              <div className={classes.storyList}>
                {this.renderStoryList(chapter)}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }

  renderStoryList = chapter => (
    <div>
      {chapter.getStories().map(story => (
        <div
          className={this.props.classes.story}
          key={`story-${story.name}`}
          onClick={() => this.props.onStorySelect(chapter.name, story.name)}
        >
          <Typography>{story.name}</Typography>
        </div>
      ))}
    </div>
  );
}

const styles = theme => {
  return {
    container: {
      padding: 20,
      backgroundColor: theme.palette.background.default,
    },
    chapter: {
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.grey[300],
      borderBottomStyle: 'solid',
      cursor: 'pointer',
    },
    storyList: {
      padding: 10,
    },
    story: {
      padding: 5,
      cursor: 'pointer',
    },
  };
};

export default withStyles(styles)(List);
