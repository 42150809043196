import Select from '../Select/Select';
import { forwardRef } from 'react';

const BetStatusSelect = forwardRef(({ ...props }, ref) => {
  return (
    <Select
      ref={ref}
      options={[
        { value: 'accepted', label: 'Accepted' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'cashed_out', label: 'Cached Out' },
        { value: 'canceled', label: 'Canceled' },
        { value: 'vip_stake_requested', label: 'Vip Stake Requested' },
      ]}
      {...props}
    />
  );
});

export default BetStatusSelect;
