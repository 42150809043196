import { Box } from '@mui/material';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import Checkbox from '../../../../Checkbox';
import FancyOptionTitle from '../../../components/FancyOptionTitle';
import ItemMoveButton from '../../../components/ItemMoveButton';
import PrioritySelect from '../../../components/PrioritySelect';
import { useCoverageTemplateOption } from '../../../hooks/coverage/options/useCoverageTemplateOption';
import useCoverageStyles from '../../../styles/Coverage.style';
import { changePath } from '../../../utils/changePath';

const CoverageTournamentOption = ({
  templateId,
  nodePath = {},
  nodeIndex,
  tabKey,
  selected,
  itemValue,
  itemIndex,
  lineType,
  options,
  optionsFullListRef,
  displayOptions,
  setDisplayOptions,
  onManualPriorityChange,
  dragDropDisabled,
  configurationDisabled,
  provided,
}) => {
  const classes = useCoverageStyles();
  const { showToast } = useToasts();

  const [searchParams] = useSearchParams();
  const duplicate = !!searchParams.get('duplicate');

  const { getValues, setValue } = useFormContext();
  const {
    setNodePath,
    setData,
    setTreeSelectLoading,
    getCoverageTemplateQuery,
    selectedCoverageItemsConfig: { [lineType]: selectedCoverageItemsConfig },
    setSelectedCoverageItemsConfig,
  } = useTreeSelectContext();
  const selectedSportStatus = selectedCoverageItemsConfig.sport.status;
  const tournamentCategoryId =
    tabKey === 'category' ? nodePath.categoryId : itemValue.categoryId;
  const categoryStatus =
    selectedCoverageItemsConfig.category[tournamentCategoryId];
  const tournamentInheritStatus =
    selectedSportStatus === false ? false : categoryStatus;

  const nodeSelector = withoutEmpty({
    sportId: nodePath.sportId,
    categoryId: tabKey === 'category' ? nodePath.categoryId : undefined,
    tournamentId: itemValue.tournamentId,
  });

  const { checked, onChangeStatus } = useCoverageTemplateOption({
    itemValue,
    options,
    optionsFullListRef,
    nodeSelector,
    getFormValues: getValues,
    setFormValue: setValue,
    idKey: 'tournamentId',
    tabKey,
    setDisplayOptions,
    inheritStatus: tournamentInheritStatus,
  });
  // need strict compare because it is possible to be null
  const status = tournamentInheritStatus === false ? false : checked;

  const refreshSelectedItemStatus = (status, categoryId) => {
    setSelectedCoverageItemsConfig(curr => ({
      ...curr,
      [lineType]: {
        ...curr[lineType],
        tournament: {
          ...curr[lineType].tournament,
          status,
          categoryId: categoryId || curr[lineType].tournament.categoryId,
        },
      },
    }));
  };
  const handleStatusCheckbox = value => {
    onChangeStatus(value);
    if (selected) {
      refreshSelectedItemStatus(value);
    }
  };

  //on option click we need to highlight this option, after this request new lists of options
  //for get new option list we need to pass ids of previous selected items
  const onSelect = () => {
    const prevNodePath = nodePath;
    const path = changePath({
      nodeId: itemValue.tournamentId,
      nodeKey: 'tournamentId',
      nodeIndex,
      nodePath,
      setNodePath,
      lineType,
    });
    getCoverageTemplateQuery(
      {
        templateId,
        path: {
          sportId: path.sportId,
          categoryId:
            tabKey === 'category' ? path.categoryId : itemValue.categoryId,
          tournamentId: path.tournamentId,
        },
        lineType,
      },
      duplicate
    )
      .then(data => {
        const newData = withoutEmpty(data.data.data);
        setTreeSelectLoading(false);
        refreshSelectedItemStatus(
          status,
          tabKey === 'sport' && itemValue.categoryId
        );

        setData(currentData => {
          return {
            ...currentData,
            [lineType]: {
              ...currentData[lineType],
              marketsToNode: newData.marketsToNode,
              events: newData.events,
              markets: newData.markets,
            },
          };
        });
      })
      .catch(() => {
        setTreeSelectLoading(false);
        setNodePath(currentPath => ({
          ...currentPath,
          [lineType]: prevNodePath,
        }));
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can not fetch data
          </NotificationAlert>
        );
      });
  };

  const dragHandleProps = !configurationDisabled &&
    !dragDropDisabled && { ...provided.dragHandleProps };

  return (
    <Box className={classes.coverageMarketOptionContent}>
      <Box display={'flex'} alignItems={'center'} width={'100%'}>
        <ItemMoveButton
          dragHandleProps={dragHandleProps}
          selected={selected}
          dragDropDisabled={configurationDisabled || dragDropDisabled}
        />

        <Checkbox
          value={status}
          onChange={handleStatusCheckbox}
          disabled={
            configurationDisabled ||
            itemValue.readOnly ||
            tournamentInheritStatus === false
          }
        />
        <Box width={'100%'}>
          <FancyOptionTitle
            tooltip={true}
            code={itemValue.countryCode}
            total={itemValue.total}
            name={itemValue.name}
            textClassName={classes.coverageTournamentOptionName}
            onClick={onSelect}
          >
            {itemValue.name}
          </FancyOptionTitle>
        </Box>
      </Box>

      <PrioritySelect
        disabled={configurationDisabled}
        option={itemValue}
        options={optionsFullListRef.current}
        itemIndex={itemValue.priority - 1}
        countryCode={itemValue.countryCode}
        priority={itemValue.priority}
        onSubmit={onManualPriorityChange}
      />
    </Box>
  );
};

export default memo(CoverageTournamentOption);
