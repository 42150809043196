import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import { UiOptionsContext } from '../UiOptionsProvider';
import WatchIcon from '../../../icons/controls/WatchIcon';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../../providers/DataProvider';
import { NestedIndexesContext } from '../../../providers/NestedIndexProvider';
import { getByIndexes } from '../../Table/TableUtils';
import { LinksContext } from '../../../providers/LinksProvider';
import useInlineEdit from '../../../hooks/UseInlineEdit';

const useStyles = makeStyles({
  watchField: {
    width: 32,
    height: 32,
    color: '#C1CBD5',
    cursor: 'pointer',
  },

  watchFieldActive: {
    color: '#FF000F',
  },

  watchFieldProcessing: {
    cursor: 'wait',
  },
});

/**
 * Used to attach row to the top of the table.
 */
const WatchField = () => {
  const classes = useStyles();

  const { value } = useContext(UiOptionsContext) || {};
  const [width] = useState(value.width || 'initial');

  const { editLink, key } = value;

  const requestPath = useParams();

  const {
    method,
    url,
    operationId,
    query: requestQuery,
    data,
    storeStrategy,
  } = useContext(DataContext);

  const indexes = useContext(NestedIndexesContext);
  const entity = getByIndexes(data, indexes);

  const links = useContext(LinksContext);

  const { isLoading: submitting, edit } = useInlineEdit({
    link: links[editLink],
    entity,
    requestQuery,
    requestPath,
    operationId,
    method,
    url,
    storeStrategy,
  });

  return (
    <div
      style={{
        width,
      }}
    >
      <WatchIcon
        onClick={
          !submitting &&
          (async () => {
            await edit({
              [key]: !value.data,
            });
          })
        }
        className={classNames(classes.watchField, {
          [classes.watchFieldActive]: value.data,
          [classes.watchFieldProcessing]: submitting,
        })}
      />
    </div>
  );
};

export default WatchField;
