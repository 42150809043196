import useMeasure from 'react-use-measure';
import { useEffect, useMemo } from 'react';
import throttle from 'lodash/throttle';

const WithMeasure = ({ onMeasure, throttleWait, children }) => {
  const [contentRef, { width }] = useMeasure();

  const throttledOnMeasure = useMemo(
    () => (throttleWait ? throttle(onMeasure, throttleWait) : onMeasure),
    [onMeasure, throttleWait]
  );

  useEffect(() => {
    throttledOnMeasure({ width });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledOnMeasure, width]);

  return children(contentRef);
};

export default WithMeasure;
