import { identity, isEmpty, isEqual, pickBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  initialPreset,
  useTreeSelectContext,
} from '../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import {
  LINE_TYPES,
  SELECT_NODE_TYPES,
  TEMPLATE_CONFIG_NODES,
} from '../../../../../pages/LineSettingsPage/FormPages/Template/components/TemplateConfigurationField';
import { useSportsBookUserAccessRoles } from '../../../../../providers/SportsBookUserAccessRolesProvider';
import { useToasts } from '../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../Notifications/NotificationAlert';
import Loader from '../../components/Loader';
import TreeSelectContainer from '../../components/TreeSelectContainer';
import { getKeySelector } from '../../utils/getKeySelector';
import CoverageCategorySelect from './Selects/CoverageCategorySelect';
import CoverageEventSelect from './Selects/CoverageEventSelect';
import CoverageMarketSelect from './Selects/CoverageMarketSelect';
import CoverageSportSelect from './Selects/CoverageSportSelect';
import CoverageTournamentSelect from './Selects/CoverageTournamentSelect';

const pathPredicate = (value, key) => {
  return key === 'market' ? !!value.marketId : !!value;
};

export const applyCoveragePresets = ({
  preset,
  template,
  lineType,
  switchedFrom,
  setValue,
  pathToSelect = {},
}) => {
  if (!isEqual(preset, initialPreset)) {
    const commands = preset.commands;
    const config = commands.reduce((acc, value) => {
      if (preset.idKey !== 'sportId') {
        const groupNodeSelector = {
          sportId: pathToSelect.sportId,
          market: {
            marketId:
              value.nodeSelector.market && value.nodeSelector.market.marketId,
          },
        };

        const groupKey = getKeySelector({
          nodeSelector: groupNodeSelector,
          lineType,
        });
        if (!acc[groupKey]) {
          acc[groupKey] = {
            groups: value.groups,
            lineType,
            nodeSelector: groupNodeSelector,
            prematchSameAsLiveFrom: switchedFrom,
            mainMarketPriority: value.mainMarketPriority,
          };
        }
      }

      preset.itemIds.forEach(id => {
        const nodeSelector = pickBy(
          {
            ...pathToSelect,
            [preset.idKey]:
              (value.nodeSelector.market && id) ||
              value.nodeSelector[preset.idKey],
            market: {
              marketId:
                value.nodeSelector.market && value.nodeSelector.market.marketId,
            },
          },
          pathPredicate
        );

        const key = getKeySelector({ nodeSelector, lineType });
        if (!template[key]) {
          const currentConfig = acc[key] || {};
          const groups = isEmpty(value.groups)
            ? currentConfig.groups || []
            : value.groups;

          const command = {
            status: value.status,
            groups: preset.idKey === 'sportId' ? groups : undefined,
            lineType,
            nodeSelector,
            prematchSameAsLiveFrom: switchedFrom,
            priority: value.priority,
            mainMarketPriority:
              preset.idKey === 'sportId' ? value.mainMarketPriority : undefined,
          };

          acc[key] = command;
        }
      });

      return acc;
    }, {});

    setValue('template', { ...template, ...config });
  }
};

const CoverageTreeSelect = ({ templateId, lineType }) => {
  const selectNames = [
    TEMPLATE_CONFIG_NODES.SPORT,
    TEMPLATE_CONFIG_NODES.CATEGORY,
    TEMPLATE_CONFIG_NODES.TOURNAMENT,
    TEMPLATE_CONFIG_NODES.EVENT,
    TEMPLATE_CONFIG_NODES.MARKET,
  ];

  const selectComponents = {
    [TEMPLATE_CONFIG_NODES.SPORT]: CoverageSportSelect,
    [TEMPLATE_CONFIG_NODES.CATEGORY]: CoverageCategorySelect,
    [TEMPLATE_CONFIG_NODES.TOURNAMENT]: CoverageTournamentSelect,
    [TEMPLATE_CONFIG_NODES.EVENT]: CoverageEventSelect,
    [TEMPLATE_CONFIG_NODES.MARKET]: CoverageMarketSelect,
  };

  const { getValues } = useFormContext();
  const { showToast } = useToasts();

  const [searchParams] = useSearchParams();
  const duplicate = !!searchParams.get('duplicate');

  const switchedFrom = getValues('switchedFrom');

  const {
    nodePath: { [lineType]: nodePath },
    data: { [lineType]: data },
    setData,
    treeSelectLoading,
    setTreeSelectLoading,
    getCoverageTemplateQuery,
    setTreeSelectCrashed,
  } = useTreeSelectContext();

  const { roles } = useSportsBookUserAccessRoles();

  const variables = useMemo(() => {
    return nodePath && nodePath.sportId
      ? {
          templateId,
          lineType,
          path: pickBy(
            {
              sportId: nodePath.sportId,
              categoryId: nodePath.categoryId,
              tournamentId: nodePath.tournamentId,
              eventId: nodePath.eventId,
            },
            identity
          ),
        }
      : { templateId, lineType };
  }, [nodePath, lineType, templateId]);

  useEffect(() => {
    if (isEmpty(data) && !isEmpty(roles)) {
      getCoverageTemplateQuery(variables, duplicate)
        .then(data => {
          const newData = withoutEmpty(data.data.data);
          setTreeSelectLoading(false);

          setData(currentData => ({
            ...currentData,
            [lineType]: { ...newData },
          }));
        })
        .catch(() => {
          //if we get error while initially load select we need to undo template type select
          setTreeSelectLoading(false);
          setTreeSelectCrashed(true);
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Can not fetch data
            </NotificationAlert>
          );
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, roles]);

  return (
    <TreeSelectContainer>
      {treeSelectLoading && <Loader onTopOfContent />}
      {selectNames.map((value, index) => {
        if (data[value] && !isEmpty(nodePath)) {
          const selectOptions = data[value];
          const TreeSelectElement = selectComponents[value];

          //this used to change key only after changes data for this menu
          const selectOptionsJson = JSON.stringify(selectOptions);

          //markets have to change key also every time when changed nodePath
          const uniqKey =
            value === TEMPLATE_CONFIG_NODES.MARKET
              ? selectOptionsJson + JSON.stringify(nodePath)
              : selectOptionsJson;

          return (
            <TreeSelectElement
              key={uniqKey}
              templateId={templateId}
              options={selectOptions}
              selectKey={value}
              selectNodeType={SELECT_NODE_TYPES[value]}
              nodeIndex={index}
              marketType={data.marketsToNode}
            />
          );
        }
      })}
    </TreeSelectContainer>
  );
};

export default CoverageTreeSelect;
