import BannerPreview from './BannerPreview';
import { forwardRef } from 'react';

const CodeBannerPreview = forwardRef(({ active, onClick, ...props }, ref) => {
  return (
    <BannerPreview
      title={'Code'}
      active={active}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      <svg
        width="56"
        height="31"
        viewBox="0 0 56 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          marginTop: 5,
        }}
      >
        <path
          opacity="0.2"
          d="M17.1894 12.472L7.49338 15.8L17.1894 19.128V24.568L0.869375 18.648V12.952L17.1894 7.032V12.472ZM30.2331 0.0559978H36.6331L26.0731 30.2H19.6731L30.2331 0.0559978ZM55.1269 12.952V18.648L38.8069 24.568V19.128L48.5029 15.8L38.8069 12.472V7.032L55.1269 12.952Z"
          fill="currentColor"
        />
      </svg>
    </BannerPreview>
  );
});

export default CodeBannerPreview;
