import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../reducers/users';
import { useCallback } from 'react';
import { useApiHost } from '../providers/ApiHostContext';
import pickBy from 'lodash/pickBy';
import { refreshToken } from '../actions/authActions';
export const getSearchParams = params => {
  const searchParams = new URLSearchParams();

  for (let paramKey in params) {
    if (Array.isArray(params[paramKey])) {
      for (let paramValue of params[paramKey]) {
        searchParams.append(paramKey, paramValue);
      }
    } else {
      searchParams.append(paramKey, params[paramKey]);
    }
  }

  return searchParams;
};

export const getApiUrl = (url, host = window.location.origin, params) => {
  const urlObj = new URL(url, host);

  if (params) {
    const nonEmptyParams = pickBy(params, param => {
      return param || (typeof param !== 'undefined' && param !== null);
    });

    urlObj.search = getSearchParams(nonEmptyParams).toString();
  }

  return urlObj;
};

export const useApiImage = ({ url, params }) => {
  const token = useSelector(getUserToken);
  const { apiHost } = useApiHost();

  return useCallback(
    ({ url: urlOverride }) => {
      const urlObj = getApiUrl(urlOverride || url, apiHost, params);

      return fetch(urlObj, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }

          if (response.status === 204) {
            return;
          }

          return response.blob();
        })
        .then(imageBlob => {
          const reader = new FileReader();
          reader.readAsDataURL(imageBlob);
          return new Promise(resolve => {
            reader.onloadend = () => {
              resolve(reader.result);
            };
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    [apiHost, token, params, url]
  );
};

export const useApi = ({ url, params, method, data }) => {
  const token = useSelector(getUserToken);
  const { apiHost } = useApiHost();
  const dispatch = useDispatch();

  return useCallback(
    (
      { url: urlOverride, data: dataOverride, throwError = false } = {},
      abortController
    ) => {
      const signal = abortController && abortController.signal;

      const urlObj = getApiUrl(urlOverride || url, apiHost, params);

      return fetch(urlObj, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataOverride || data),
        signal,
      })
        .then(async function (response) {
          if (!response.ok) {
            const { statusText, message } = await response.json();
            const error = new Error(statusText || message);
            error.status = response.status;
            throw error;
          }

          if (response.status === 204) {
            return;
          }

          return response.json();
        })
        .catch(e => {
          if (throwError) {
            throw e;
          } else if (e.status === 401) {
            dispatch(refreshToken());
          } else {
            console.log(e);
          }
        });
    },
    [apiHost, url, token, method, data, params, dispatch]
  );
};

export const useApiGet = (url, params) => {
  return useApi({ url, params, method: 'GET' });
};

export const useApiPost = () => {
  const api = useApi({ method: 'POST' });

  return useCallback(
    ({ url, data, throwError }) => {
      return api({
        url,
        data,
        throwError,
      });
    },
    [api]
  );
};

export const useApiPut = () => {
  const api = useApi({ method: 'PUT' });

  return useCallback(
    ({ url, data, throwError }) => {
      return api({
        url,
        data,
        throwError,
      });
    },
    [api]
  );
};

export const useApiPatch = () => {
  const api = useApi({ method: 'PATCH' });

  return useCallback(
    (url, data) => {
      return api({
        url,
        data,
      });
    },
    [api]
  );
};

export const useApiDelete = () => {
  const api = useApi({ method: 'DELETE' });

  return useCallback(
    (url, data) => {
      return api({
        url,
        data,
      });
    },
    [api]
  );
};
