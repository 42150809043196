import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select/Select';
import makeStyles from '@mui/styles/makeStyles';
import ExpandIcon from '../../../icons/controls/ExpandIcon';
import { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
  rowsPerPageSelect: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    lineHeight: '25px',
    paddingRight: 46,
    paddingLeft: 10,
    paddingTop: 7,
    paddingBottom: 8,
  },
  rowsPerPageSelectIcon: {
    top: 'initial',
    right: 10,
  },
  perPageLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: 8,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.2px',
  },
  [theme.breakpoints.down('xs')]: {
    perPageLabel: {
      maxWidth: 93,
    },
  },
  tablePaginationLimit: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TablePaginationLimit = ({ value = 10, onChange = () => {} }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className={classes.tablePaginationLimit}>
      <div className={classes.perPageLabel}>Rows per page</div>
      <Select
        IconComponent={ExpandIcon}
        value={value}
        onChange={handleChange}
        classes={{
          select: classes.rowsPerPageSelect,
          icon: classes.rowsPerPageSelectIcon,
        }}
      >
        {[
          {
            value: 10,
            label: '10',
          },
          {
            value: 25,
            label: '25',
          },
          {
            value: 50,
            label: '50',
          },
          {
            value: 100,
            label: '100',
          },
          {
            value: 150,
            label: '150',
          },
        ].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TablePaginationLimit;
