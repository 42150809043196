import BannerPreview from './BannerPreview';
import BannerPreviewPlaceholder from './BannerPreviewPlaceholder';
import { forwardRef } from 'react';

const BoostedOddsBannerPreview = forwardRef(
  ({ active, onClick, ...props }, ref) => {
    return (
      <BannerPreview
        title={'Boosted Odds'}
        active={active}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <BannerPreviewPlaceholder
          style={{
            height: 21,
            width: 146,
            marginTop: 18,
            borderRadius: 1,
          }}
        />
      </BannerPreview>
    );
  }
);

export default BoostedOddsBannerPreview;
