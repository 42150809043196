import * as React from 'react';
import UiComponentSchemaProvider from './UiComponentSchemaProvider';
import usePresetUiSchema from '../hooks/usePresetUiSchema';

const PresetUiComponentSchemaProvider = ({ children }) => {
  const { uiSchema } = usePresetUiSchema();

  return (
    <UiComponentSchemaProvider value={uiSchema}>
      {children}
    </UiComponentSchemaProvider>
  );
};

export default PresetUiComponentSchemaProvider;
