import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HighlightedLabel from './HighlightedLabel';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  primaryText: {
    fontWeight: 500,
    color: '#031224',
    fontSize: '14px',
    letterSpacing: '0.2px',
  },
  secondaryText: {
    fontSize: '11px',
  },
});

const Option = ({
  innerRef,
  isFocused,
  isSelected,
  innerProps,
  children,
  data,
  selectProps: { inputValue, secondaryKey },
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      buttonRef={innerRef}
      selected={isFocused}
      style={{
        minHeight: 40,
        color: isSelected ? '#1581FF' : '#031224',
      }}
      {...innerProps}
    >
      <ListItemText
        primary={
          <span className={classes.primaryText}>
            <HighlightedLabel text={children} query={inputValue} />
          </span>
        }
        secondary={
          data[secondaryKey] && (
            <span className={classes.secondaryText}>
              <HighlightedLabel text={data[secondaryKey]} query={inputValue} />
            </span>
          )
        }
      />
    </ListItem>
  );
};

export default Option;
