import {
  CONFIRMATION_MODAL,
  DYNAMIC_MODAL,
  IMAGE_CROP_MODAL,
  THEME_MODAL,
} from '../constants/modalConstants';
import { createAction } from 'redux-actions';
import { getParams } from '../utils/schemaHelper';

export const showModal = createAction('SHOW_MODAL');
export const hideModal = createAction('HIDE_MODAL');

export const showDynamicModal = modalProps => {
  return showModal({
    modalType: DYNAMIC_MODAL,
    modalProps,
  });
};

export const showImageCropModal = modalProps =>
  showModal({
    modalType: IMAGE_CROP_MODAL,
    modalProps,
  });

export const showThemeModal = () =>
  showModal({
    modalType: THEME_MODAL,
  });

export const showConfirmationModal = modalProps =>
  showModal({
    modalType: CONFIRMATION_MODAL,
    modalProps,
  });

export const showLinkModal = ({
  link,
  requestQuery,
  responseBody,
  requestPath,
}) => {
  const { params, formParams } = getParams({
    link,
    requestQuery,
    responseBody,
    requestPath,
  });

  return showDynamicModal({
    path: link.path,
    params: {
      ...params,
    },
    formParams,
  });
};
