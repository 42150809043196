import React, { memo, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import WatchIconSvg from '../../../icons/WatchIcon.svg';
import WatchIconActiveSvg from '../../../icons/WatchIconActive.svg';

const useStyles = makeStyles({
  watchIcon: {
    cursor: 'pointer',
    backgroundImage: `url(${WatchIconSvg})`,
    backgroundRepeat: 'no-repeat',
    height: 32,
    width: 32,
  },
  watchIconActive: {
    backgroundImage: `url(${WatchIconActiveSvg})`,
  },
});

/**
 * Used to attach row to the top of the table.
 */
const WatchField = memo(({ toggled, onToggle }) => {
  const classes = useStyles();

  const onClick = useCallback(() => {
    onToggle(!toggled);
  }, [toggled, onToggle]);

  return (
    <div
      onClick={onClick}
      className={clsx(classes.watchIcon, {
        [classes.watchIconActive]: toggled,
      })}
    />
  );
});

export default WatchField;
