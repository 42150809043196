import { useState, useEffect, useMemo, useCallback } from 'react';

let evtTarget;

try {
  evtTarget = new EventTarget();
} catch {
  evtTarget = document.createElement('phony');
}

export const useSessionStorage = (key, defaultValue) => {
  const storage = sessionStorage;
  const raw = storage.getItem(key);

  const [value, setValue] = useState(raw ? JSON.parse(raw) : defaultValue);

  const updater = useCallback(
    (updatedValue, remove = false) => {
      setValue(updatedValue);

      storage[remove ? 'removeItem' : 'setItem'](
        key,
        JSON.stringify(updatedValue)
      );
      evtTarget.dispatchEvent(
        new CustomEvent('storage_change', {
          detail: { key },
        })
      );
    },
    [key, storage]
  );

  defaultValue != null && !raw && updater(defaultValue);

  useEffect(() => {
    const listener = ({ detail }) => {
      if (detail.key === key) {
        const lraw = storage.getItem(key);

        lraw !== raw && setValue(JSON.parse(lraw));
      }
    };

    evtTarget.addEventListener('storage_change', listener);
    return () => evtTarget.removeEventListener('storage_change', listener);
  });

  return useMemo(() => {
    return [
      value,
      updatedValue => updater(updatedValue),
      () => updater(null, true),
    ];
  }, [value, updater]);
};
