import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import ColorPickerTooltip from '../ColorPickerTooltip';
import useIsOpen from '../../hooks/useIsOpen';

const styles = {
  popper: {
    zIndex: 1400,
  },
  colorPreview: {
    height: 16,
    width: 40,
    marginBottom: -20,
    borderRadius: 4,
  },
};

const ColorInput = ({ value, onChange, id, classes, ...otherProps }) => {
  const { isOpen, open, close } = useIsOpen();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Fragment>
      <TextField
        aria-describedby={id}
        value={value || ''}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <div
                className={classes.colorPreview}
                style={{
                  backgroundColor: value,
                }}
              />
            </InputAdornment>
          ),
          onClick: e => {
            setAnchorEl(e.currentTarget);
            open();
          },
        }}
        {...otherProps}
      />
      <Popper
        id={id}
        open={isOpen}
        anchorEl={isOpen ? anchorEl : null}
        className={classes.popper}
      >
        <ColorPickerTooltip
          // placement={placement}
          initialColor={value}
          onSubmit={color => {
            onChange(color);
            close();
          }}
        />
      </Popper>
    </Fragment>
  );
};

ColorInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ColorInput);
