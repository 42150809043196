import { useEffect, useState } from 'react';
import TextField from '../../TextField';

const SelectSearch = ({ label, onSearchFieldChange = () => {}, tabKey }) => {
  const [value, setValue] = useState();

  const onChange = e => {
    setValue(e.target ? e.target.value : e);
    onSearchFieldChange(e);
  };

  //we need to clear search field if tab (optional exist) changed
  useEffect(() => {
    if (tabKey) {
      setValue(currentValue => (currentValue ? '' : currentValue));
    }
  }, [tabKey]);

  return (
    <TextField
      style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
      label={label}
      value={value}
      onChange={onChange}
      clearButton
    />
  );
};

export default SelectSearch;
