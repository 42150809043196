import { createContext, useContext } from 'react';

export const TableAnimatedRowContext = createContext({});

export const useTableAnimatedRowContext = () => {
  return useContext(TableAnimatedRowContext);
};

const TableAnimatedRowProvider = ({ value, children }) => {
  return (
    <TableAnimatedRowContext.Provider value={value}>
      {children}
    </TableAnimatedRowContext.Provider>
  );
};

export default TableAnimatedRowProvider;
