import React, { forwardRef } from 'react';
import { getLoginRoute } from '../utils/schemaHelper';
import { useRoutes } from '../providers/RoutesProvider';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Logo from '../img/Logo';
import { Typography } from '@mui/material';
import EnvInput from './EnvInput';
import classNames from 'clsx';
import LoginForm from './LoginForm';
import FeatureBranchInput from '../v2/components/Login/FeatureBranchInput';
import {
  IS_SHOW_FEATURE_BRANCHES,
  SCHEMA_LABELS,
} from '../constants/schemaUrls';

const useStyles = makeStyles({
  loginFormRoot: {
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 368,
    padding: 24,
    flexDirection: 'column',
  },
  loginFormLogo: {
    padding: '8px 0',
  },
  loginFormTitle: {
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '4px',
    color: '#8496AB',
    padding: '8px 0',
    paddingBottom: 32,
  },
  errorLabel: {
    fontSize: 14,
    color: '#8496AB',
  },
});

const LoginPage = forwardRef(({ className, style }, ref) => {
  const classes = useStyles();
  const routes = useRoutes();
  const loginRoute = routes && getLoginRoute(routes);

  return (
    <Paper
      ref={ref}
      className={classNames(className, classes.loginFormRoot)}
      style={style}
    >
      {IS_SHOW_FEATURE_BRANCHES && <FeatureBranchInput />}
      <Logo className={classes.loginFormLogo} />
      <Typography className={classes.loginFormTitle}>BACKOFFICE</Typography>
      {SCHEMA_LABELS && <EnvInput />}
      {loginRoute ? (
        <LoginForm route={loginRoute} routes={routes} />
      ) : (
        <div className={classes.errorLabel}>Could not retrieve schema...</div>
      )}
    </Paper>
  );
});

export default LoginPage;
