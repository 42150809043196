import React, { createContext } from 'react';

export const TabbedViewContext = createContext(null);

const TabbedViewProvider = ({ isActiveTab, ...props }) => {
  return (
    <TabbedViewContext.Provider
      value={{
        isActiveTab,
      }}
      {...props}
    />
  );
};

export default TabbedViewProvider;
