import { useContext } from 'react';
import { useToasts } from '../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';

const useCancelationNotifications = () => {
  const { showToast } = useToasts();

  const getBetsCountTitle = count => {
    return `${count} ${count === 1 ? 'bet' : 'bets'}`;
  };

  const cancelationNotification = ({
    data,
    betsCount = 0,
    alreadyCanceled = 0,
  }) => {
    if (data) {
      if (betsCount)
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS} timeout={7000}>
            {`${getBetsCountTitle(
              betsCount
            )} cancelation in progress... Please wait and refresh the page`}
          </NotificationAlert>
        );
      if (alreadyCanceled) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={7000}>
            {`${getBetsCountTitle(alreadyCanceled)} canceled already`}
          </NotificationAlert>
        );
      }
    } else {
      showToast(
        <NotificationAlert variant={AlertVariant.WARNING} timeout={7000}>
          Error while bets cancel
        </NotificationAlert>
      );
    }
  };

  const errorNotification = () => {
    showToast(
      <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
        Something went wrong ...
      </NotificationAlert>
    );
  };

  return {
    cancelationNotification,
    errorNotification,
    getBetsCountTitle,
  };
};

export default useCancelationNotifications;
