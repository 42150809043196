import { makeStyles } from '@mui/styles';

export default makeStyles({
  actionButton: {
    padding: 0,
    height: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: 0,
      '& .MuiSvgIcon-root': {
        fontSize: '12px',
      },
    },
    fontSize: '12px',
  },
});
