import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import pickBy from 'lodash/pickBy';
import { useAuth } from '../providers/AuthProvider';
import useApiHost from './useApiHost';
import { refreshToken } from '../../actions/authActions';

export const useApi = ({ url, params, method, data }) => {
  const { apiHost } = useApiHost();
  const { token } = useAuth();
  const dispatch = useDispatch();

  return useCallback(
    (
      { url: urlOverride, data: dataOverride, throwError = false } = {},
      abortController
    ) => {
      const urlObj = new URL(
        `${urlOverride || url}`,
        apiHost || window.location.origin
      );

      const signal = abortController && abortController.signal;

      if (params) {
        const nonEmptyParams = pickBy(params, param => {
          return param || (typeof param !== 'undefined' && param !== null);
        });

        const searchParams = new URLSearchParams();

        for (let paramKey in nonEmptyParams) {
          if (Array.isArray(nonEmptyParams[paramKey])) {
            for (let paramValue of nonEmptyParams[paramKey]) {
              searchParams.append(paramKey, paramValue);
            }
          } else {
            searchParams.append(paramKey, nonEmptyParams[paramKey]);
          }
        }

        urlObj.search = searchParams.toString();
      }

      return fetch(urlObj, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataOverride || data),
        signal,
      })
        .then(async function (response) {
          if (!response.ok) {
            const { statusText, message } = await response.json();
            const error = new Error(statusText || message);
            error.status = response.status;
            throw error;
          }

          if (response.status === 204) {
            return;
          }

          return response.json();
        })
        .catch(e => {
          if (throwError) {
            throw e;
          } else if (e.status === 401) {
            dispatch(refreshToken());
          } else {
            console.log(e);
          }
        });
    },
    [apiHost, url, token, method, data, params, dispatch]
  );
};

export const useApiGet = (url, params) => {
  return useApi({ url, params, method: 'GET' });
};

export const useApiPost = () => {
  const api = useApi({ method: 'POST' });

  return useCallback(
    ({ url, data, throwError }) => {
      return api({
        url,
        data,
        throwError,
      });
    },
    [api]
  );
};

export const useApiPut = () => {
  const api = useApi({ method: 'PUT' });

  return useCallback(
    ({ url, data, throwError }) => {
      return api({
        url,
        data,
        throwError,
      });
    },
    [api]
  );
};

export const useApiPatch = () => {
  const api = useApi({ method: 'PATCH' });

  return useCallback(
    (url, data) => {
      return api({
        url,
        data,
      });
    },
    [api]
  );
};

export const useApiDelete = () => {
  const api = useApi({ method: 'DELETE' });

  return useCallback(
    (url, data) => {
      return api({
        url,
        data,
      });
    },
    [api]
  );
};
