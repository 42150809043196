import React, { useEffect } from 'react';
import DraggableList from './DraggableList';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '../../../../icons/controls/CheckBoxIcon';
import CheckBoxActiveIcon from '../../../../icons/controls/CheckBoxActiveIcon';

const OrderListWidget = ({
  items,
  formDataArray,
  onAdd,
  formContext: {},
  uiSchema = {},
}) => {
  const { 'ui:options': uiOptions = {} } = uiSchema;

  const { options, selected_key, value_key } = uiOptions;

  useEffect(() => {
    const newOptions = options
      .filter(
        option =>
          !formDataArray.find(item => item[value_key] === option[value_key])
      )
      .map(option => ({
        ...option,
        [selected_key]: false,
      }));

    if (newOptions.length) {
      onAdd(newOptions);
    }
  }, [formDataArray, options, onAdd, selected_key, value_key]);

  return (
    <div>
      <DraggableList
        getId={item => {
          return value_key
            ? item.children.props.formData[value_key].toString()
            : item.key;
        }}
        renderActions={item => {
          return (
            <Checkbox
              icon={<CheckBoxIcon />}
              checkedIcon={<CheckBoxActiveIcon />}
              checked={item.children.props.formData[selected_key]}
              onChange={event => {
                item.children.props.onChange({
                  ...item.children.props.formData,
                  [selected_key]: event.target.checked,
                });
              }}
            />
          );
        }}
        formDataArray={formDataArray}
        onAdd={onAdd}
        items={items}
        uiSchema={uiSchema}
      />
    </div>
  );
};

export default OrderListWidget;
