import { useParams } from 'react-router-dom';
import BetHistory from '../../../../BetHistory/BetHistory';
import { useApplyRequired } from '../../../../../providers/ApplyRequiredProvider';
import { tableNames } from '../../../../../hooks/useLocalStorageTableConfig';

const EventBetHistoryTab = () => {
  const { eventId } = useParams();
  const { isApplyRequired } = useApplyRequired(tableNames.BetHistory);

  return <BetHistory eventId={eventId} isApplyRequired={isApplyRequired} />;
};

export default EventBetHistoryTab;
