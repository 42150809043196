import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { format, isValid, parse } from 'date-fns';
import RangeDatePicker from '../../v2/components/DatePicker/RangeDatePicker';
import {
  getValueForTimePicker,
  getValueFromTimePicker,
} from '../../utils/datePickerUtils';
import PickerInput from './PickerInput';
import DatePickerAdornment from '../../v2/components/DatePicker/DatePickerAdornment';

const useStyles = makeStyles({
  calendarPopper: {
    zIndex: 10000,
  },

  calendarPaper: {
    padding: '16px 16px 24px',
    overflow: 'hidden',
  },
});

const DATETIME_FORMAT = 'dd.MM.yyyy, HH:mm';
const DATE_RANGE_DELIMITER = '—';

const formatDate = (date, defaultValue) => {
  if (!isValid(date) || date === null) {
    return defaultValue;
  }

  return format(date, DATETIME_FORMAT);
};

const numberDisplayFormat = (from, to) => {
  if ((from === null && to === null) || (!isValid(from) && !isValid(to))) {
    return '';
  }

  return `${formatDate(from, '-∞')} ${DATE_RANGE_DELIMITER} ${formatDate(
    to,
    '∞'
  )}`;
};

const RangeDateInput = forwardRef(
  (
    {
      id,
      value,
      onChange,
      className,
      disabled,
      label,
      error,
      fullWidth,
      helperText,
      errorText,
      InputProps = {},
      required,
      rangeFromKey = 'rangeFrom',
      rangeToKey = 'rangeTo',
    },
    ref
  ) => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const anchorElRef = useRef(null);
    const handleClickAway = useCallback(() => {
      setIsOpen(false);
    }, []);

    const valueRangeFrom =
      value && value[rangeFromKey]
        ? getValueForTimePicker(value[rangeFromKey])
        : null;
    const valueRangeTo =
      value && value[rangeToKey]
        ? getValueForTimePicker(value[rangeToKey])
        : null;

    const dateFromValue = useMemo(
      () => (valueRangeFrom ? new Date(valueRangeFrom) : null),
      [valueRangeFrom]
    );
    const dateToValue = useMemo(
      () => (valueRangeTo ? new Date(valueRangeTo) : null),
      [valueRangeTo]
    );

    const rangeValue = useMemo(
      () => [dateFromValue, dateToValue],
      [dateFromValue, dateToValue]
    );

    const handleChange = useCallback(
      range => {
        onChange({
          [rangeFromKey]: getValueFromTimePicker(range[0]),
          [rangeToKey]: getValueFromTimePicker(range[1]),
        });

        setIsOpen(false);
      },
      [onChange, setIsOpen, rangeFromKey, rangeToKey]
    );

    const displayValue = numberDisplayFormat(dateFromValue, dateToValue);

    const handleChangeCustomValue = useCallback(
      customValue => {
        if (customValue) {
          const rangeParts = customValue.split(DATE_RANGE_DELIMITER);

          if (rangeParts && rangeParts.length === 2) {
            return handleChange([
              parse(rangeParts[0].trim(), DATETIME_FORMAT, new Date()),
              parse(rangeParts[1].trim(), DATETIME_FORMAT, new Date()),
            ]);
          }
          handleChange(rangeValue);
        } else {
          handleChange([null, null]);
        }
      },
      [handleChange, rangeValue]
    );

    const datePickerAdornment = (
      <DatePickerAdornment
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      />
    );

    return (
      <>
        <PickerInput
          id={id}
          ref={anchorElRef}
          onOpen={setIsOpen}
          displayValue={displayValue}
          onBlurInput={handleChangeCustomValue}
          inputProps={{
            ref,
          }}
          InputProps={{
            ...InputProps,

            endAdornment:
              InputProps && InputProps.endAdornment ? (
                <>
                  {datePickerAdornment}
                  {InputProps.endAdornment}
                </>
              ) : (
                datePickerAdornment
              ),
          }}
          className={className}
          fullWidth={fullWidth}
          error={error}
          helperText={errorText || helperText}
          label={label}
          disabled={disabled}
          required={required}
        />

        <Popper
          className={classes.calendarPopper}
          open={isOpen}
          anchorEl={anchorElRef.current}
          placement={'bottom'}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper className={classes.calendarPaper} elevation={2}>
              <RangeDatePicker value={rangeValue} onChange={handleChange} />
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
);

export default RangeDateInput;
