const langIconNames = {
  en: 'gb',
  ru: 'ru',
  uk: 'ua',
  zh: 'cn',
  ar: 'ae',
  bg: 'bg',
  de: 'de',
  es: 'es',
  fi: 'fi',
  it: 'it',
  ja: 'jp',
  no: 'no',
  pl: 'pl',
  ro: 'ro',
  sv: 'se',
  tr: 'tr',
  pt: 'pt',
  fr: 'fr',
  vi: 'vn',
  sk: 'sk',
  ko: 'kr',
  ka: 'ge',
  sl: 'si',
  kk: 'kz',
  ms: 'my',
  id: 'id',
  hi: 'in',
  fil: 'ph',
  international: 'international',
};

const getLangIconName = key => {
  return langIconNames[key] || langIconNames['international'];
};

export default getLangIconName;
