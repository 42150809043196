import { LINE_TYPES } from '../components/TemplateConfigurationField';
import { getConfigByLineType } from './getConfigByLineType';

export const combineTemplateConfigs = ({ templateConfigs, lineType }) => {
  return Object.keys(templateConfigs).reduce((acc, key) => {
    if (key.includes(lineType)) {
      acc[key.replace(lineType, LINE_TYPES.all)] = getConfigByLineType({
        templateConfigs,
        key,
        lineType: LINE_TYPES.all,
      });
    }
    return acc;
  }, {});
};
