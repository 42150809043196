import { gql } from '@apollo/client';

const getOperatorEventDetailsQuery = gql`
  query getBaseEventDetails($eventId: EventId!) {
    data: getBaseEventDetails(eventId: $eventId) {
      id
      event
      category {
        id
        name
      }
      tournament {
        id
        name
      }
      sport {
        id
        name
      }
    }
  }
`;

export default getOperatorEventDetailsQuery;
