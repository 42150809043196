import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useSpring, animated, config } from 'react-spring';

const useStyles = makeStyles({
  formGroup: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 4,
    overflow: 'hidden',
  },

  formGroupLarge: {
    padding: '24px 16px',
  },

  formGroupLargeTop: {
    paddingTop: '24px',
  },
});

const FormGroup = ({
  loading,
  children,
  className,
  large = false,
  largeTop = false,
}) => {
  const classes = useStyles();

  const styles = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
    config: config.gentle,
  });

  return (
    <animated.div
      style={styles}
      className={clsx(
        classes.formGroup,
        {
          [classes.formGroupLarge]: large,
          [classes.formGroupLargeTop]: largeTop,
        },
        className
      )}
    >
      {children}
    </animated.div>
  );
};

export default FormGroup;
