import makeStyles from '@mui/styles/makeStyles';
import { animated } from 'react-spring';
import clsx from 'clsx';
import { forwardRef, memo } from 'react';

const useStyles = makeStyles({
  activeItemIndicator: {
    width: 4,
    height: 32,
    backgroundColor: '#1581FF',
    position: 'absolute',
    right: 0,
  },
  activeItemIndicatorHidden: {
    visibility: 'hidden',
  },
});

const MenuActiveItemIndicator = memo(
  forwardRef(({ style, hidden = false, className }, ref) => {
    const classes = useStyles();

    return (
      <animated.div
        ref={ref}
        style={style}
        className={clsx(classes.activeItemIndicator, className, {
          [classes.activeItemIndicatorHidden]: hidden,
        })}
      />
    );
  })
);

export default MenuActiveItemIndicator;
