import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import RangeDateInput from '../../../../components/inputs/RangeDateInput';
import TextField from '../../../components/Inputs/TextField';
import TradingToolSidebarSport from './components/TradingToolSidebarSport';
import SPORTS_FILTER_AUTOCOMPLETE_QUERY from '../../../gql/TradingTool/queries/SPORTS_FILTER_AUTOCOMPLETE_QUERY';
import usePrevious from '../../../../hooks/usePrevious';
import sortByEventsCount from '../utils/sortByEventsCount';

const useStyles = makeStyles({
  tradingToolSidebar: {
    width: 288,
    position: 'sticky',
    top: 64,
    height: 'calc(100vh - 80px)',
    maxHeight: '100%',
    overflow: 'scroll',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 12px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      background: '#fff',
      padding: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(132, 150, 171, 0.4)',
    },
  },
  rangeDateInput: {
    marginTop: 12,
  },
  sportSearchField: {
    marginTop: 6,
    marginBottom: 6,
  },
});

const TradingToolSidebar = ({ filters, onChange, onDateChange }) => {
  const classes = useStyles();
  const [sportSearchValue, setSportSearchValue] = useState('');
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const previousSport = usePrevious(selectedSport);
  const previousCategory = usePrevious(selectedCategory);
  const previousTournament = usePrevious(selectedTournament);

  useEffect(() => {
    if (
      selectedSport?.id !== previousSport?.id ||
      selectedCategory?.id !== previousCategory?.id ||
      selectedTournament?.id !== previousTournament?.id
    ) {
      onChange({
        sport: selectedSport,
        category: selectedCategory,
        tournament: selectedTournament,
      });
    }
  }, [
    selectedSport,
    previousSport,
    selectedCategory,
    previousCategory,
    selectedTournament,
    previousTournament,
    onChange,
  ]);

  const { data: { data: sports = [] } = {} } = useQuery(
    SPORTS_FILTER_AUTOCOMPLETE_QUERY
  );

  return (
    <Box className={classes.tradingToolSidebar}>
      <RangeDateInput
        fullWidth
        label={'Date'}
        rangeFromKey="date_from"
        rangeToKey="date_to"
        value={{
          date_from: filters.date_from,
          date_to: filters.date_to,
        }}
        onChange={({ date_from, date_to }) =>
          onDateChange({ date_from, date_to })
        }
        className={classes.rangeDateInput}
      />
      <TextField
        label="Sport"
        value={sportSearchValue}
        onChange={e => setSportSearchValue(e.target.value)}
        withSearchIcon
        clearButton
        onClear={() => setSportSearchValue('')}
        className={classes.sportSearchField}
      />
      {sortByEventsCount(sports)
        .filter(({ name }) =>
          name.toLowerCase().includes(sportSearchValue.toLowerCase())
        )
        .map(sport => (
          <TradingToolSidebarSport
            key={sport.id}
            sport={sport}
            sportSearchValue={sportSearchValue}
            selectedSport={selectedSport}
            setSelectedSport={setSelectedSport}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedTournament={selectedTournament}
            setSelectedTournament={setSelectedTournament}
          />
        ))}
    </Box>
  );
};

export default TradingToolSidebar;
