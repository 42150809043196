import escapeRegExp from '../../../utils/escapeRegExp';

const Highlighted = ({ highlight = '', children = '', style, markStyle }) => {
  if (!highlight.trim()) {
    return <span style={style}>{children}</span>;
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = children.split(regex);

  return (
    <span style={style}>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <mark key={i} style={markStyle}>
            {part}
          </mark>
        ) : (
          part
        );
      })}
    </span>
  );
};

export default Highlighted;
