import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import Button from '../v2/components/Button/Button';

const styles = {
  submitButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

const ButtonWithLoader = ({ classes, loading, ...otherProps }) => (
  <div className={classes.submitButtonWrapper}>
    <Button {...otherProps} />
    {loading && (
      <CircularProgress size={24} className={classes.buttonProgress} />
    )}
  </div>
);

ButtonWithLoader.propTypes = {
  loading: PropTypes.bool,
};

export default withStyles(styles)(ButtonWithLoader);
