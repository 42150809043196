import React, { useState } from 'react';

export const LocalQueryContext = React.createContext({});

const LocalQueryProvider = ({ localQuery, setLocalQuery, children }) => {
  const [defaultLocalQuery, setDefaultLocalQuery] = useState({});

  return (
    <LocalQueryContext.Provider
      value={{
        setLocalQuery: setLocalQuery || setDefaultLocalQuery,
        localQuery: localQuery || defaultLocalQuery,
      }}
    >
      {children}
    </LocalQueryContext.Provider>
  );
};

export default LocalQueryProvider;
