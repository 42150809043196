import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import ECharts from '../../components/ECharts';
import Typography from '@mui/material/Typography';
import ActionCards from '../../components/ActionCards';
import { DataContext } from '../../providers/DataProvider';

const styles = theme => ({
  root: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
});

const Charts = ({ schema: { title, description }, classes }) => {
  const { path, data } = useContext(DataContext);

  return (
    <Paper className={classes.root}>
      <ActionCards path={path} />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography>
      <ECharts data={data} />
    </Paper>
  );
};

export default withStyles(styles)(Charts);
