import { gql } from '@apollo/client';
import { UPDATE_VERSIONED_RESULT_TYPE } from '../../templates/mutations/update/updateSourcesTemplate';

const updateLineSettings = gql`
  ${UPDATE_VERSIONED_RESULT_TYPE}
  mutation updateLineSettings(
    $LineSettingsId: LineSettingsId!
    $input: LineSettingsInput!
  ) {
    data: updateLineSettings(LineSettingsId: $LineSettingsId, input: $input) {
      ...UpdateVersionedResultType
    }
  }
`;

export default updateLineSettings;
