import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableRow from '@mui/material/TableRow';
import { TableCell } from '@mui/material';
import NestedTableTabs from './NestedTableTabs';
import NestedTableComponent from './NestedTableComponent';
import { ContainerBoundsContext } from '../../providers/ContainerWidthProvider';
import Collapse from '@mui/material/Collapse';
import classNames from 'clsx';

const useStyles = makeStyles({
  nestedContainer: {
    backgroundColor: '#fff',
    padding: 2,
    marginTop: -4,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    position: 'sticky',
    left: 0,
    opacity: 0,
  },
  nestedContent: {
    borderRadius: 6,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    backgroundColor: '#F7F7F7',
    padding: 6,
  },
  nestedContainerActive: {
    opacity: 1,
  },
  nestedRowRoot: {
    borderTopWidth: 0,
    display: 'none',
  },
  nestedRowRootActive: {
    display: 'table-row',
  },
  nestedCellRoot: {
    padding: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    '&:first-child': {
      borderTopRightRadius: 'initial',
      borderTopLeftRadius: 'initial',
    },
    '&:last-child': {
      borderTopRightRadius: 'initial',
      borderTopLeftRadius: 'initial',
    },
  },
});

const NestedTableRow = ({ nested, active }) => {
  const classes = useStyles();

  const { width: containerBounds } = useContext(ContainerBoundsContext);

  return (
    <TableRow
      classes={{
        root: classNames(classes.nestedRowRoot, {
          [classes.nestedRowRootActive]: active,
        }),
      }}
    >
      <TableCell
        colSpan={'100%'}
        classes={{
          root: classes.nestedCellRoot,
        }}
      >
        <div
          className={classNames(classes.nestedContainer, {
            [classes.nestedContainerActive]: active,
          })}
          style={{
            width: containerBounds || '100%',
          }}
        >
          <Collapse in={nested && active} unmountOnExit={true}>
            <div className={classes.nestedContent}>
              {nested.length > 1 ? (
                <NestedTableTabs items={nested} />
              ) : (
                <NestedTableComponent component={nested[0]} />
              )}
            </div>
          </Collapse>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default NestedTableRow;
