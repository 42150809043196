import styles from './index.styles';

import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../../../components/Button/Button';
import ErrorWidget from '../../../../components/ErrorWidget';
import StringField from '../../../../components/Fields/StringField/StringField';
import Checkbox from '../../../../components/Inputs/Checkbox';
import Select from '../../../../components/Inputs/Select/Select';
import getLanguageInfo from '../../../../gql/EventsGroups/queries/getLanguageInfo';
import {
  useEventEditModel,
  useMatchCreateModel,
} from '../../../../hooks/event-management';
import ApolloClientProvider from '../../../../providers/ApolloClientProvider';
import {
  eventSourcesMapping,
  mapLangsToTranslations,
  mapTranslationsToLangs,
  sortLanguages,
  sortLanguagesList,
} from '../../utils';
import CategoryAutocomplete from '../CategoryAutocomplete';
import { CompetitorsList } from '../Competitors';
import TournamentAutocomplete from '../TournamentAutocomplete';
import schema from './index.schema';

const defaultValues = async () => ({
  source: null,
  model: null,
  category: {
    id: null,
    countryCode: null,
    langs: [],
  },
  tournament: {
    id: null,
    langs: [],
  },
  homeTeam: {
    id: null,
    countryCode: null,
    langs: [],
  },
  awayTeam: {
    id: null,
    countryCode: null,
    langs: [],
  },
  autocreate: false,
});

const EventFormComponent = ({ data, onSubmit, onCancel, isEditing, close }) => {
  const classes = styles();

  const [isSubmitting, setSubmitting] = useState(false);

  const sources = data.groupItems
    ? [
        ...new Set(
          data.groupItems
            .filter(item => (isEditing ? !!item : item.creatable))
            .map(item => ({
              value: item.provider,
              label: eventSourcesMapping[item.provider] || item.provider,
            }))
        ),
      ]
    : [];

  const {
    getCreateModel,
    model: createModel,
    error: createModelError,
  } = useMatchCreateModel({
    skip: isEditing,
    fetchPolicy: 'network-only',
  });

  const {
    getEditModel,
    model: editModel,
    error: editModelError,
  } = useEventEditModel({
    skip: !isEditing,
    fetchPolicy: 'network-only',
  });

  const { data: { getLanguageInfo: languagesData } = {} } =
    useQuery(getLanguageInfo);

  const languagesList = sortLanguagesList(languagesData || []);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      source: sources.length === 1 ? sources[0].value : null,
    },
  });

  const [selectedSource, category, tournament, competitors, autocreate] =
    form.watch([
      'source',
      'category',
      'tournament',
      'competitors',
      'autocreate',
    ]);

  const isSubmitButtonDisabled =
    !category ||
    !tournament ||
    isSubmitting ||
    competitors?.some(value => !value);

  const mapCompetitors = competitors => {
    return competitors.map(value => ({
      ...value,
      langs: JSON.stringify(mapTranslationsToLangs(value.langs, languagesList)),
    }));
  };

  const hasLessThanOneCompetitor = group => {
    const mainItem =
      group.booked ||
      (group.groupItems && group.groupItems.find(item => item.mainItem)) ||
      group.groupItems[0];

    return mainItem.desc.competitors.length === 0;
  };

  useEffect(() => {
    if (isEditing) {
      getEditModel({
        sportId: data.sport.id,
        groupId: data.groupId,
        eventId: data.booked.desc.id,
        descType: data.descType,
      });
    } else if (selectedSource) {
      getCreateModel({
        sportId: data.sport.id,
        groupId: data.groupId,
        providerId: data.groupItems.find(i => i.provider === selectedSource)
          .desc.id,
        provider: selectedSource,
        descType: data.descType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSource, data, isEditing]);

  useEffect(() => {
    const model = isEditing ? editModel : createModel;
    if (model) {
      const { category, tournament, autocreate, competitors } = model;

      form.reset({
        source: isEditing
          ? data.groupItems.find(i => i.mainItem)?.provider || 'Unknown'
          : selectedSource,
        category: {
          ...category,
          langs: mapLangsToTranslations(
            sortLanguages(category.langs),
            languagesList
          ),
        },
        tournament: {
          ...tournament,
          langs: mapLangsToTranslations(
            sortLanguages(tournament.langs),
            languagesList
          ),
        },
        competitors: competitors.map(value => ({
          ...value,
          langs: mapLangsToTranslations(
            sortLanguages(value.langs),
            languagesList
          ),
        })),
        autocreate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createModel, editModel]);

  const { setValue } = form;

  const renderLoader = () => {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  };

  const renderAffiliation = () => {
    const mainItem =
      data.booked ||
      (data.groupItems && data.groupItems.find(item => item.mainItem)) ||
      data.groupItems[0];

    if (mainItem) {
      const isRace = !!mainItem.desc.raceNumber;

      const competitors = mainItem.desc.competitors.join(' - ');
      const tournament = mainItem.desc.tournament;
      const category = mainItem.desc.category;

      return (
        <Stack sx={{ marginBottom: '10px', maxWidth: '472px' }}>
          {isRace && (
            <StringField>
              <b>Race number:</b> {mainItem.desc.raceNumber}
            </StringField>
          )}
          <StringField>
            <b>Category:</b> {category}
          </StringField>
          <StringField>
            <b>Tournament</b>: {tournament}
          </StringField>
          <StringField wrap>
            <b>Competitors</b>: {competitors}
          </StringField>
        </Stack>
      );
    }
  };

  const renderContent = () => {
    const isError = createModelError || editModelError;

    if (selectedSource) {
      if (isError) {
        return (
          <Stack gap={'5px'} alignItems={'center'} sx={{ margin: '25px 0' }}>
            <Typography sx={{ fontWeight: '700' }}>
              Something went wrong:
            </Typography>
            <Typography>
              {isEditing ? editModelError.message : createModelError.message}
            </Typography>
          </Stack>
        );
      } else {
        if (isEditing ? editModel : createModel) {
          return (
            <Box>
              <Stack gap={'15px'}>
                <CategoryAutocomplete
                  sportId={data.sport.id}
                  languages={languagesList}
                />
                <TournamentAutocomplete
                  sportId={data.sport.id}
                  categoryId={category?.id}
                  languages={languagesList}
                />
                {!hasLessThanOneCompetitor(data) && (
                  <CompetitorsList
                    sportId={data.sport.id}
                    languages={languagesList}
                  />
                )}
                {competitors?.length === 2 && (
                  <Checkbox
                    label={'Same settings for auto-create'}
                    value={!!autocreate}
                    onChange={v => setValue('autocreate', v)}
                  />
                )}
              </Stack>
            </Box>
          );
        } else {
          return renderLoader();
        }
      }
    } else {
      return null;
    }
  };

  if (createModelError || editModelError) {
    return <ErrorWidget onClose={() => close && close()} />;
  }

  return (
    <FormProvider {...form}>
      <Stack gap={'20px'} className={classes.container}>
        <Typography>{renderAffiliation()}</Typography>
        <Select
          label={'Source'}
          options={sources}
          disabled={isEditing || sources.length === 1}
          onChange={value => {
            setValue('source', value);
          }}
          value={selectedSource}
        />
        {renderContent()}
        <Stack direction={'row'} justifyContent={'flex-end'} gap={'8px'}>
          <Button color={'lightBlue'} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitButtonDisabled}
            onClick={() => {
              const formData = form.getValues();
              const dataToSend = {
                category: {
                  ...formData.category,
                  langs: JSON.stringify(
                    mapTranslationsToLangs(
                      formData.category.langs,
                      languagesList
                    )
                  ),
                },
                tournament: {
                  ...formData.tournament,
                  langs: JSON.stringify(
                    mapTranslationsToLangs(
                      formData.tournament.langs,
                      languagesList
                    )
                  ),
                },
                ...(isEditing
                  ? {
                      tournament_autocreate: formData.autocreate,
                      competitors: hasLessThanOneCompetitor(data)
                        ? []
                        : mapCompetitors(formData.competitors),
                    }
                  : {
                      autocreate: formData.autocreate,
                      competitors: hasLessThanOneCompetitor(data)
                        ? []
                        : mapCompetitors(formData.competitors),
                    }),
              };

              const payload = isEditing
                ? {
                    sportId: data.sport.id,
                    groupId: data.groupId,
                    eventId: data.booked.desc.id,
                    eventEditModel: dataToSend,
                    descType: data.descType,
                  }
                : {
                    sportId: data.sport.id,
                    groupId: data.groupId,
                    providerId: data.groupItems.find(
                      i => i.provider === selectedSource
                    ).desc.id,
                    provider: selectedSource,
                    eventCreateModel: dataToSend,
                    descType: data.descType,
                  };

              setSubmitting(true);
              onSubmit(payload).finally(() => {
                setSubmitting(false);
              });
            }}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

const EventForm = props => (
  <ApolloClientProvider
    defaultEndpoint={'events-groups'}
    endpoint={{
      'events-groups': '/api/v2/events-groups',
      'trading-tool': '/api/v2/trading-tool',
      'risk-management': '/api/v2/risk-management',
      promo: '/api/v2/promo',
      autocomplete: '/api/v2/autocomplete',
    }}
    key={'events-groups'}
    allowSubscription={false}
  >
    <EventFormComponent {...props} />
  </ApolloClientProvider>
);

export default EventForm;
