import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import TabsScrollButton from './TabsScrollButton';

const useStyles = makeStyles({
  scrollContainer: {
    display: 'flex',
  },
  shadow: {
    boxShadow: '-24px 0px 30px #E6EAEE, 0px 0px 20px #E6EAEE',
  },
});

const TabsScroll = ({
  canScrollLeft,
  canScrollRight,
  onClickLeft,
  onClickRight,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.scrollContainer, classes.shadow)}>
      <TabsScrollButton
        isDisabled={!canScrollLeft}
        onClick={onClickLeft}
        scrollLeft
      />
      <TabsScrollButton isDisabled={!canScrollRight} onClick={onClickRight} />
    </div>
  );
};

export default TabsScroll;
