import { IconButton, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearInputIcon from '../../icons/controls/ClearInputIcon';

const useStyles = makeStyles({
  hideButton: {
    fontSize: '22px',
  },
  inputAdornmentEndRoot: {
    right: 3,
    position: 'absolute',
    zIndex: 1,
  },
});

const InputClearAdornment = ({ onClick }) => {
  const classes = useStyles();

  return (
    <InputAdornment
      position="end"
      classes={{
        root: classes.inputAdornmentEndRoot,
      }}
    >
      <IconButton
        onClick={onClick}
        color={'primary'}
        size={'small'}
        disableRipple
      >
        <ClearInputIcon className={classes.hideButton} />
      </IconButton>
    </InputAdornment>
  );
};

export default InputClearAdornment;
