import RelatedContingencyItemType from '../constants/RelatedContingencyItemType';

const getPartLabel = (
  { type, value },
  allLabel,
  detailed = false,
  sameLabel
) => {
  if (type === RelatedContingencyItemType.ALL) {
    return allLabel;
  }

  if (type === RelatedContingencyItemType.SAME) {
    return sameLabel;
  }

  if (detailed) {
    return value.map(item => item.label).join(', ');
  }

  if (value && value.length) {
    if (value.length === 1) {
      return value[0].label;
    }

    if (value.length > 1) {
      return `${value[0].label} +${value.length - 1}`;
    }
  }
};

const getRelatedContingencyLabel = (contingency, detailed = false) => {
  const parts = [
    getPartLabel(contingency.sports, 'All Sports', detailed),
    getPartLabel(contingency.categories, 'All Categories', detailed),
    getPartLabel(
      contingency.tournaments,
      'All Tournaments',
      detailed,
      'Same Tournament'
    ),
    getPartLabel(contingency.events, 'All Events', detailed),
    getPartLabel(contingency.markets, 'All Markets', detailed),
    getPartLabel(
      contingency.competitors,
      'All Competitors',
      detailed,
      'Same Competitor'
    ),
  ];

  return parts.join(', ');
};

export default getRelatedContingencyLabel;
