import { gql } from '@apollo/client';

const getGroupsFilters = gql`
  query getGroupsFilters {
    getGroupsFilters {
      sports {
        sport {
          id
          name
        }
        count
      }
      providers
    }
  }
`;

export default getGroupsFilters;
