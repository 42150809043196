import { gql } from '@apollo/client';

export const CREATE_LINE_SETTINGS_RESULT_TYPE = gql`
  fragment CreateLineSettingsResultType on CreateLineSettingsResult {
    versionedLineSettingsId {
      lineSettingsId
      version
    }
    status
    error
  }
`;

const createLineSettings = gql`
  ${CREATE_LINE_SETTINGS_RESULT_TYPE}
  mutation createLineSettings($input: LineSettingsInput!) {
    data: createLineSettings(input: $input) {
      ...CreateLineSettingsResultType
    }
  }
`;

export default createLineSettings;
