import { useEffect } from 'react';
import { getValueFromTimePicker } from '../utils/datePickerUtils';

const useCorrectDateEffect = (value, onChange) =>
  useEffect(() => {
    if (
      value &&
      !/^\d\d\d\d-[0-1]\d-[0-3]\d[t\s](?:[0-2]\d:[0-5]\d:[0-5]\d|23:59:60)(?:\.\d+)?(?:z|[+-]\d\d:\d\d)$/i.test(
        value
      )
    ) {
      onChange(getValueFromTimePicker(new Date(value)));
    }
  }, [value, onChange]);

export default useCorrectDateEffect;
