import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useUiComponentSchema } from '../../providers/UiComponentSchemaProvider';
import { useSchema } from '../../providers/SchemaProvider';
import { useData } from '../../providers/DataProvider';
import { compileSchema } from '../Field/FieldUtils';
import Field from '../Field/Field/Field';
import usePaletteColor from '../../hooks/usePaletteColor';
import clsx from 'clsx';
import { Fade, Skeleton } from '@mui/material';

const useStyles = makeStyles({
  cardRoot: {
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 124,
    padding: 16,
  },

  cardWithFooterRoot: {
    '& $cardTitle': {
      marginTop: 0,
    },
    '& $cardValue': {
      margin: '8px 0',
    },
  },

  cardTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    height: 24,
    marginTop: 8,
  },

  cardSkeleton: {
    margin: 0,
  },

  cardTitleIndicator: {
    height: 24,
    width: 24,
    borderRadius: '50%',
    marginRight: 12,
  },

  cardValue: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.5px',
    margin: '8px 0',
    height: 32,
    overflow: 'hidden',
    '& div > div': {
      whiteSpace: 'nowrap',
    },
  },

  cardFooter: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
});

const Card = () => {
  const classes = useStyles();

  const uiSchema = useUiComponentSchema();
  const schema = useSchema();
  const { data, loading } = useData();

  const isLoaded = !loading && data;

  const uiOptions = uiSchema['ui:options'];

  const compiled = compileSchema(uiOptions, data, {});

  const indicatorColor = usePaletteColor(compiled.color || '#fff');

  return (
    <div
      className={clsx(classes.cardRoot, {
        [classes.cardWithFooterRoot]: compiled.footer,
      })}
    >
      <div className={classes.cardTitle}>
        {compiled.color && (
          <div
            className={classes.cardTitleIndicator}
            style={{
              backgroundColor: indicatorColor,
            }}
          />
        )}

        {!isLoaded && !compiled.title.text && (
          <Skeleton width={'40%'} className={classes.cardSkeleton} />
        )}

        <Fade in={isLoaded} unmountOnExit={true}>
          <span>{compiled.title.text}</span>
        </Fade>
      </div>
      <div className={classes.cardValue}>
        {!isLoaded && (
          <Skeleton width={'30%'} className={classes.cardSkeleton} />
        )}
        <Field
          fieldKey={'key'}
          fieldValue={compiled.value.text}
          schema={schema}
          uiSchema={uiSchema}
        />
      </div>
      {compiled.footer && (
        <>
          <Fade in={isLoaded} unmountOnExit={true}>
            <div className={classes.cardFooter}>{compiled.footer.text}</div>
          </Fade>
          {!isLoaded && (
            <Skeleton width={'60%'} className={classes.cardSkeleton} />
          )}
        </>
      )}
    </div>
  );
};

export default Card;
