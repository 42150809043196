import { createContext, useContext } from 'react';

export const NestedTableContext = createContext(null);

export const useIsNestedTable = () => {
  return !!useContext(NestedTableContext);
};

const NestedTableProvider = ({ children = {} }) => {
  return (
    <NestedTableContext.Provider value={{}}>
      {children}
    </NestedTableContext.Provider>
  );
};

export default NestedTableProvider;
