import React, { useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import TransferListGroups from './TransferListGroups';
import TransferListItems from './TransferListItems';

const useStyles = makeStyles({
  transferListColumn: {
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    flex: 1,
    padding: 8,
    overflow: 'scroll',
    position: 'relative',
  },

  content: {
    padding: 8,
  },

  headline: {
    padding: '14px 8px',
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: '#031224',
    letterSpacing: '0.2px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'bold',
  },

  totalLabel: {
    backgroundColor: '#E0EBF8',
    color: '#1581FF',
    borderRadius: '16px',
    padding: '2px 4px',
    marginLeft: 8,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    minWidth: 20,
    textAlign: 'center',
  },
});

const getFiltered = (items, searchQuery, searchKeys = []) => {
  return items.filter(item => {
    for (let currentKey of searchKeys) {
      const index = item[currentKey]
        ? item[currentKey].toLowerCase().indexOf(searchQuery.toLowerCase())
        : -1;

      if (index !== -1) {
        return true;
      }
    }

    return false;
  });
};

const TransferListColumn = ({
  title,
  items = [],
  groupKey,
  valueKey,
  labelKey,
  descKey,
  infoKey,
  setToggled,
  counterLabel,
  setGroupToggled,
  emptyLabel = null,
  loading = false,
  isEmpty = false,
}) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredItems = useMemo(
    () => getFiltered(items, searchQuery, [labelKey, descKey]),
    [searchQuery, labelKey, descKey, items]
  );

  const changeSearchQuery = debounce(setSearchQuery, 300, {});

  const totalFound = filteredItems.length;

  const totalToggled = items.filter(item => {
    return item.toggled;
  }).length;

  const isEmptyList = isEmpty || !items.length;

  return (
    <div className={classes.transferListColumn}>
      <TextField
        classes={{
          root: classes.searchFieldRoot,
        }}
        margin={'dense'}
        variant="outlined"
        placeholder={'Search'}
        fullWidth
        onChange={e => {
          changeSearchQuery(e.target.value);
        }}
      />

      <div className={classes.headline}>
        <div className={classes.title}>{title}</div>
        <div className={classes.totalLabel}>
          {searchQuery
            ? `${totalFound} ${'found'}`
            : totalToggled
            ? `${totalToggled} selected`
            : counterLabel}
        </div>
      </div>

      {groupKey ? (
        <TransferListGroups
          loading={loading}
          setGroupToggled={setGroupToggled}
          setToggled={setToggled}
          valueKey={valueKey}
          labelKey={labelKey}
          descKey={descKey}
          infoKey={infoKey}
          searchQuery={searchQuery}
          filteredItems={filteredItems}
          items={items}
          groupKey={groupKey}
          isEmpty={isEmptyList}
          emptyLabel={emptyLabel}
        />
      ) : (
        <TransferListItems
          loading={loading}
          setToggled={setToggled}
          valueKey={valueKey}
          labelKey={labelKey}
          descKey={descKey}
          infoKey={infoKey}
          searchQuery={searchQuery}
          filteredItems={filteredItems}
          items={items}
          isEmpty={isEmptyList}
          emptyLabel={emptyLabel}
        />
      )}
    </div>
  );
};

export default TransferListColumn;
