import Select from '../Select/Select';
import { forwardRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import useSelectCheckValueOptions from '../../../../hooks/useSelectCheckValueOptions';
import getPlaceholders from '../../../gql/Promo/queries/getPlaceholders';

const BannerPlaceholderSelect = forwardRef(
  (
    {
      name,
      disabled,
      bannerType,
      brandId,
      label = 'Placeholder',
      onChange,
      value,
      multiple = false,
      ...props
    },
    ref
  ) => {
    const params = useMemo(
      () => ({
        input: {
          banner_type: bannerType,
          brand_id: brandId,
        },
      }),
      [bannerType, brandId]
    );

    const { data, loading } = useQuery(getPlaceholders, {
      variables: params,
      skip: !bannerType || !brandId,
    });

    const items = data && data.data && data.data.items;

    const options = useMemo(() => {
      return (items || []).map(item => ({ value: item, label: item }));
    }, [items]);

    useSelectCheckValueOptions({
      value,
      options,
      multiple,
      onChange,
      loading,
    });

    return (
      <Select
        name={name}
        disabled={disabled}
        loading={loading}
        ref={ref}
        multiple={multiple}
        onChange={onChange}
        value={value}
        label={label}
        options={options}
        {...props}
      />
    );
  }
);

export default BannerPlaceholderSelect;
