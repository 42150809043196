import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

export const HistoryMethodEnum = {
  PUSH: 'push',
  REPLACE: 'replace',
};

const useFiltersQuery = (initialValue, path = 'filters') => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const filtersQuery = useMemo(() => {
    const currentQueryFilters = JSON.parse(query.get(path));

    return currentQueryFilters || initialValue || {};
  }, [query, initialValue, path]);

  const setFiltersQuery = useCallback(
    (value, method = HistoryMethodEnum.REPLACE) => {
      query.set(path, JSON.stringify(value));
      navigate(
        { search: query.toString() },
        {
          replace: method === HistoryMethodEnum.REPLACE,
          state: location.state,
        }
      );
    },
    [query, navigate, path, location]
  );

  useEffect(() => {
    if (location.search === '' && initialValue) {
      setFiltersQuery(initialValue);
    }
  }, [location.search, initialValue, setFiltersQuery]);

  return useMemo(
    () => [filtersQuery, setFiltersQuery],
    [filtersQuery, setFiltersQuery]
  );
};

export default useFiltersQuery;
