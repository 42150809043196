import { makeStyles } from '@mui/styles';

export const usePrioritySelectStyles = makeStyles({
  container: {
    color: '#1581FF',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    display: 'flex',
    marginRight: '8px',
  },

  label: {
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    background: '#E0EBF8',
    '&:hover': {
      background: '#AACBF0',
      cursor: 'pointer',
    },
  },

  leftButton: {
    padding: '11px 12px',
    borderRadius: '20px 0px 0px 20px',
  },

  rightButton: {
    padding: '4px 4px',
    borderRadius: '0px 20px 20px 0px',
    borderLeft: '1px solid #66ACFC',
  },

  popper: {
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow:
      '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
  },

  popperItemsContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  popperItem: {
    borderRadius: 8,
  },
  autocomplete: {
    position: 'relative',
    height: 200,
  },
  popperAutocompleteInput: {
    padding: '8px 12px',
  },
  modalPopper: {
    zIndex: 1301,
    width: '436px',
  },
  optionName: {
    maxWidth: '310px',
  },
  shortedOptionName: {
    maxWidth: '125px',
  },
  itemContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  optionContainer: {
    padding: '0!important',
  },

  disabled: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
});

export default usePrioritySelectStyles;
