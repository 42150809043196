import { gql } from '@apollo/client';

const getBetHistoryList = gql`
  query getBetHistoryList($inputFilters: BetHistoryTableFilters) {
    data: getBetHistoryList(inputFilters: $inputFilters) {
      items {
        playerId
        bonusType
        bonusName
        betId
        betStatus
        username
        accepted
        betType
        betTypeSpecifier
        event
        eventId
        betTimestamp
        settleDate
        eventScheduled
        odd
        oddResult
        stake
        won
        refund
        hasRollback
        forced
        useMts
        betCurrency
        stakeCurrency
        wonCurrency
        operatorBonus
        operatorBonusCurrency
        currencyCents
        ip
        isVirtual
        operatorName
        brandName
        extPlayerId
        operatorId
        operatorBrandId
        bonusId
        riskManagement
        ccf
        selections {
          selectionId
          status
          eventId
          tournamentId
          marketId
          outcomeId
          live
          rollback
          forced
          sport
          category
          tournament
          season
          event
          market
          outcome
          k
          betradarId
          score
          voidReason
          marketProviders
          settledAt
          eventScheduled
        }
        country
        diffBetSettle
        diffBetStart
        rmRejectCode
      }
      total
    }
  }
`;

export default getBetHistoryList;
