import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { usePresets } from '../providers/PresetsProvider';
import useFiltersQuery from './useFiltersQuery';

const useFiltersPreset = () => {
  const { loading, activePreset, updatePresetsState, savePreset } =
    usePresets();
  const [filtersQuery, setFiltersQuery] = useFiltersQuery();
  const [isInitialised, setInitialised] = useState(false);
  const pendingPresetUpdate = useRef(null);

  const filters = useMemo(() => {
    return !loading && activePreset && activePreset.data.filters;
  }, [activePreset, loading]);

  const onChange = useCallback(
    data => {
      const presetId = activePreset.id;
      const presetData = {
        ...activePreset,
        data: {
          ...activePreset.data,
          filters: data,
        },
      };

      savePreset(presetId, presetData);
      pendingPresetUpdate.current = {
        presetId,
        presetData,
      };
    },
    [activePreset, savePreset]
  );

  // preset update will trigger table refetch and re-render, so we call it only when new presets is selected
  useEffect(() => {
    if (pendingPresetUpdate.current) {
      updatePresetsState(
        pendingPresetUpdate.current.presetId,
        pendingPresetUpdate.current.presetData
      );
    }
  }, [activePreset?.id, updatePresetsState]);

  // when redirected by direct url with filters in query params, add preset filters to url filters
  useEffect(() => {
    if (
      !isInitialised &&
      filtersQuery &&
      Object.keys(filtersQuery).length &&
      activePreset
    ) {
      setInitialised(true);
      setFiltersQuery({ ...activePreset.data.filters, ...filtersQuery });
    }
  }, [isInitialised, filtersQuery, setFiltersQuery, activePreset]);

  return useMemo(
    () => [
      filters,
      onChange,
      activePreset && activePreset.data.initial_filters,
      activePreset ? activePreset.id === '0' : true,
    ],
    [filters, onChange, activePreset]
  );
};

export default useFiltersPreset;
