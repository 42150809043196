import { gql } from '@apollo/client';

const getEventsBets = gql`
  query getEventsBets($eventsIds: [EventId!]!) {
    getEventsBets(eventsIds: $eventsIds) {
      eventId
      betsCount {
        open
        all
      }
    }
  }
`;

export default getEventsBets;
