import { gql } from '@apollo/client';

const getAutocreatableTournaments = gql`
  query getAutocreatableTournaments(
    $query: String
    $sports: [SportId!]
    $providers: [Provider!]
    $limit: Int!
    $offset: Int!
  ) {
    getAutocreatableTournaments(
      query: $query
      sports: $sports
      providers: $providers
      limit: $limit
      offset: $offset
    ) {
      items {
        sport {
          id
          name
        }
        provider
        providerId
        tournament {
          langs
          id
        }
        category {
          langs
          id
        }
      }
      total
    }
  }
`;

export default getAutocreatableTournaments;
