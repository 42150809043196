import * as yup from 'yup';

const MarginLadderValidationSchema = yup.object({
  name: yup.string().required().default(null),
  sport: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .default(null),
  low: yup.string().nullable().default(null),
  high: yup.string().nullable().default(null),
  pairs: yup
    .array()
    .of(
      yup.object().shape({
        low: yup.string(),
        high: yup.string(),
      })
    )
    .default([]),
});

export default MarginLadderValidationSchema;
