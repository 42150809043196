import { Box, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  loaderField: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  topLevel: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'white',
    opacity: 0.5,
  },
});

const Loader = ({ onTopOfContent = false, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.loaderField, {
        [classes.topLevel]: onTopOfContent,
      })}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
