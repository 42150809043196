import SchemaWorker from '../../workers/SchemaWorker';

const getHttpServer = servers => {
  const server = servers.find(server => {
    let url;

    try {
      url = new URL(server.url);
    } catch (e) {
      console.error(e.message);

      return true;
    }

    return url.protocol === 'https:' || url.protocol === 'http:';
  });

  return {
    ...server,
    hostname: new URL(server.url).hostname,
  };
};

const getWebSocketServer = servers =>
  servers.find(server => {
    let url;
    try {
      url = new URL(server.url);
    } catch (e) {
      console.error(e.message);

      return true;
    }
    return url.protocol === 'wss:' || url.protocol === 'ws:';
  });

const getCachedSchema = async (url, token) => {
  const schemaHash = await SchemaWorker.fetchSchema(url, token);
  const cacheKey = `schema-cache`;
  const hashKey = `schema-cache-hash`;

  const currentHash = localStorage.getItem(hashKey);

  if (currentHash === schemaHash) {
    return await SchemaWorker.getCached(localStorage.getItem(cacheKey));
  }

  const { schema: data, routes } = await SchemaWorker.dereference();

  try {
    localStorage.setItem(cacheKey, JSON.stringify(data));
    localStorage.setItem(hashKey, schemaHash);
    console.log('Cached', schemaHash);
  } catch (e) {
    console.log('Schema caching skipped');
  }

  return {
    schema: data,
    routes,
  };
};

const prepareSchema = async (schemaUrl, token) => {
  const start = Date.now();

  const { schema, routes } = await getCachedSchema(schemaUrl, token);

  const webSocketServer = getWebSocketServer(schema.servers);
  const httpServer = getHttpServer(schema.servers);

  return {
    schema,
    routes,
    rawSchema: schema,
    apiHost: httpServer ? httpServer.url : null,
    apiBaseUrl: httpServer ? httpServer.hostname : null,
    webSocketHost: webSocketServer ? webSocketServer.url : null,
  };
};

export default prepareSchema;
