import React from 'react';
import { SvgIcon } from '@mui/material';

const size = 26;
const strokeWidth = 2;
const center = size / 2;

const radius = size / 2 - strokeWidth / 2;
const circumference = 2 * Math.PI * radius;

const AlertCloseIcon = ({ onClick, className, progress, timeout }) => {
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <SvgIcon className={className} onClick={onClick} viewBox={'0 0 26 26'}>
      <g opacity="0.5">
        <path
          d="M9.21067 7.79647C8.82212 7.40398 8.18897 7.40078 7.79647 7.78933C7.40398 8.17788 7.40078 8.81103 7.78933 9.20353L9.21067 7.79647ZM16.6989 18.2035C17.0874 18.596 17.7206 18.5992 18.1131 18.2107C18.5056 17.8221 18.5088 17.189 18.1202 16.7965L16.6989 18.2035ZM18.2107 9.20353C18.5992 8.81103 18.596 8.17788 18.2035 7.78933C17.811 7.40078 17.1779 7.40398 16.7893 7.79647L18.2107 9.20353ZM7.87979 16.7965C7.49124 17.189 7.49444 17.8221 7.88693 18.2107C8.27942 18.5992 8.91258 18.596 9.30112 18.2035L7.87979 16.7965ZM7.78933 9.20353L16.6989 18.2035L18.1202 16.7965L9.21067 7.79647L7.78933 9.20353ZM16.7893 7.79647L7.87979 16.7965L9.30112 18.2035L18.2107 9.20353L16.7893 7.79647Z"
          fill="#8496AB"
        />
      </g>
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        stroke="#8496AB"
        opacity="0.3"
        strokeWidth="2"
        r={radius}
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
        style={{
          transition: `stroke-dashoffset ${timeout}ms ease-in-out`,
        }}
      />
    </SvgIcon>
  );
};

export default AlertCloseIcon;
