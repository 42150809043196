import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TableActionCard from './TableActionCard';
import AddIconCircle from '../../../icons/controls/AddIconCircle';

const useStyles = makeStyles({
  actionCreateRoot: {
    padding: 0,
    minWidth: 'initial',
    width: '100%',
    justifyContent: 'flex-start',
    color: '#1581FF',
    height: 22,
  },

  createActionButtonLabel: {
    color: '#1581FF',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.5px',
    marginLeft: '8px',
  },
});

const CreateActionCard = ({ style, link, label }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <TableActionCard style={style}>
      <Button
        disableRipple={true}
        classes={{
          root: classes.actionCreateRoot,
        }}
        onClick={() => {
          navigate(link);
        }}
      >
        <AddIconCircle />
        <span className={classes.createActionButtonLabel}>
          {label || 'Create'}
        </span>
      </Button>
    </TableActionCard>
  );
};

export default CreateActionCard;
