import TableCell from './TableCell';
import ExpandButton from '../Button/ExpandButton';
import { memo } from 'react';
import clsx from 'clsx';

const TableRowExpandCell = memo(
  ({ className, expanded, withExpandButton, onExpandCb }) => {
    return (
      <TableCell className={clsx('tableRowColumnExpand', className)}>
        {withExpandButton && (
          <ExpandButton expanded={expanded} onClick={onExpandCb} />
        )}
      </TableCell>
    );
  }
);

export default TableRowExpandCell;
