import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { UiOptionsContext } from './UiOptionsProvider';
import useTableSummary from '../../hooks/useTableSummary';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';

const useStyles = makeStyles({
  percentageDecorator: {
    color: '#8496AB',
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    lineHeight: '15px',
  },
});

const PercentageValue = () => {
  const classes = useStyles();
  const { value } = useContext(UiOptionsContext);
  const summary = useTableSummary();

  const summaryValue = summary && get(summary, ['0', value.key]);
  const percentageValue =
    summaryValue && (toNumber(value.data) * 100) / summaryValue;

  if (!percentageValue) {
    return null;
  }

  return (
    <div className={classes.percentageDecorator}>
      {percentageValue.toFixed(2)}%
    </div>
  );
};

export default PercentageValue;
