import { gql } from '@apollo/client';

const getEventDetailsQuery = gql`
  query getBaseEventDetails($eventId: EventId!) {
    data: getBaseEventDetails(eventId: $eventId) {
      id
      event
      eventScheduled
      providers {
        id
        name
      }
      category {
        id
        name
      }
      tournament {
        id
        name
      }
      sport {
        id
        name
      }
      offered
    }
  }
`;

export default getEventDetailsQuery;
