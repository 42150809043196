import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
  tabScrollButton: {
    backgroundColor: '#fff',
    borderRadius: 6,
    marginRight: 2,
    border: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: 36,
    height: 36,
    '&:hover': {
      backgroundColor: theme.palette.blue[200],
    },
  },
  disabledCursor: {
    cursor: 'default',
  },
}));

const TabsScrollButton = ({ onClick, scrollLeft, isDisabled }) => {
  const classes = useStyles();

  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={onClick}
      className={classNames(
        classes.tabScrollButton,
        isDisabled && classes.disabledCursor
      )}
    >
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={scrollLeft && 'rotate(180)'}
      >
        <path
          d="M1 1L7 7L1 13"
          stroke={isDisabled ? '#8496AB' : '#1581FF'}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
  );
};

export default TabsScrollButton;
