import get from 'lodash/get';
import {
  DYNAMIC_CLEAR_SUCCESS,
  DYNAMIC_DATA_STORE_STRATEGY,
  DYNAMIC_EDIT_FAILURE,
  DYNAMIC_EDIT_REQUEST,
  DYNAMIC_EDIT_SUCCESS,
  DYNAMIC_FAILURE,
  DYNAMIC_REQUEST,
  DYNAMIC_SUCCESS,
  DYNAMIC_UPDATE_SUCCESS,
} from '../constants/dataConstants';
import mapValues from 'lodash/mapValues';
import { combineActions, handleAction, handleActions } from 'redux-actions';
import { getOperationIdByPath } from '../utils/schemaHelper';
import removeEmpty from '../utils/removeEmpty';
import orderBy from 'lodash/orderBy';
import mergeWith from 'lodash/mergeWith';
import some from 'lodash/some';

const data = handleAction(
  combineActions(
    DYNAMIC_REQUEST,
    DYNAMIC_SUCCESS,
    DYNAMIC_FAILURE,
    DYNAMIC_EDIT_REQUEST,
    DYNAMIC_EDIT_SUCCESS,
    DYNAMIC_EDIT_FAILURE,
    DYNAMIC_UPDATE_SUCCESS,
    DYNAMIC_CLEAR_SUCCESS
  ),
  (state, action) => {
    if (!action.meta) {
      return state;
    }
    return {
      ...state,
      [action.meta.operationId]: {
        ...state[action.meta.operationId],
        [action.meta.path]: pathDataReducer(
          state[action.meta.operationId],
          action
        ),
      },
    };
  },
  {}
);

export default data;

const initialPathState = {
  data: undefined,
  loading: true,
  error: null,
};

function limitData(data, limit) {
  if (data.length <= limit) {
    return data;
  }

  return data.splice(0, limit);
}

const appendData = (data = {}, changes, limit) => {
  return {
    ...data,
    ...changes,
    items: limitData([...(data.items || []), ...changes.items], limit),
  };
};

const insertData = (data = {}, changes, limit) => {
  return {
    ...data,
    ...changes,
    items: limitData([...changes.items, ...(data.items || [])], limit),
  };
};

const removeByKeyData = (data = {}, changes, key = 'id') => {
  let items = data.items || [];
  let changedItems = changes.items || [];

  items = items.filter(item => {
    return (
      changedItems.findIndex(changedItem => item[key] === changedItem[key]) ===
      -1
    );
  });

  return {
    ...data,
    ...changes,
    items,
  };
};

const insertByKeyData = (data = {}, changes, limit, key = 'id') => {
  let items = data.items || [];

  for (let changedItem of changes.items || []) {
    const itemIndex = items.findIndex(item => item[key] === changedItem[key]);

    if (itemIndex >= 0) {
      items[itemIndex] = { ...items[itemIndex], ...changedItem };
    } else {
      items = [changedItem, ...items];
    }
  }

  return {
    ...data,
    ...changes,
    items: limitData(items, limit),
  };
};

const getStoreStrategyKey = storeStrategy => {
  if (!storeStrategy.key) {
    return null;
  }

  const matchKey = storeStrategy.key.match(/\/items\/(.+)/);

  if (!matchKey) {
    return null;
  }

  return matchKey[1];
};

const getSortKey = field => {
  const matched = field.match(/\/items\/(.+)/);

  if (!matched) {
    return null;
  }

  return matched[1];
};

const getStoreStrategySortKeys = storeStrategy => {
  if (typeof storeStrategy.sortBy === 'string') {
    return [
      {
        field: getSortKey(storeStrategy.sortBy),
        order: 'ASC',
      },
    ];
  }

  if (Array.isArray(storeStrategy.sortBy)) {
    return storeStrategy.sortBy.map(item => ({
      order: 'ASC',
      ...item,
      field: getSortKey(item.field),
    }));
  }

  return null;
};

const sortDataItems = (data, sortByKeys) => {
  if (!sortByKeys || !sortByKeys.length) {
    return data;
  }

  return {
    ...data,
    items: orderBy(
      data.items,
      sortByKeys.map(item => item.field),
      sortByKeys.map(item => item.order.toLowerCase())
    ),
  };
};

const reduceDynamicData = (
  contentSchema,
  storeStrategy,
  data,
  currentData,
  limit
) => {
  data = removeEmpty(data);

  if (!storeStrategy) {
    return data;
  }

  if (!limit) {
    limit = storeStrategy.limit || 500;
  }

  const sortByKeys = getStoreStrategySortKeys(storeStrategy);

  if (get(contentSchema, 'properties.items.type') === 'array') {
    switch (storeStrategy.storeAction) {
      case DYNAMIC_DATA_STORE_STRATEGY.INSERT:
        const key = getStoreStrategyKey(storeStrategy);

        if (key) {
          return sortDataItems(
            insertByKeyData(currentData, data, limit, key),
            sortByKeys
          );
        }

        return sortDataItems(insertData(currentData, data, limit), sortByKeys);
      case DYNAMIC_DATA_STORE_STRATEGY.APPEND:
        return sortDataItems(appendData(currentData, data, limit), sortByKeys);
      case DYNAMIC_DATA_STORE_STRATEGY.MERGE:
        return sortDataItems(
          {
            ...currentData,
            ...data,
          },
          sortByKeys
        );
      case DYNAMIC_DATA_STORE_STRATEGY.REMOVE:
        return removeByKeyData(
          currentData,
          data,
          getStoreStrategyKey(storeStrategy)
        );
      case DYNAMIC_DATA_STORE_STRATEGY.REPLACE:
        return data;
      default:
        return data;
    }
  }

  switch (storeStrategy.default) {
    case DYNAMIC_DATA_STORE_STRATEGY.MERGE:
      return mergeWith(data, currentData);
    case DYNAMIC_DATA_STORE_STRATEGY.REPLACE:
      return data;
    default:
      return data;
  }
};

const pathDataReducer = handleActions(
  {
    [DYNAMIC_REQUEST]: () => initialPathState,
    [DYNAMIC_SUCCESS]: (
      state,
      {
        payload: { data },
        meta: { path, limit, storeStrategy, contentSchema, action },
      }
    ) => {
      if (storeStrategy && state[path]) {
        return {
          ...state[path],
          data: reduceDynamicData(
            contentSchema,
            {
              ...storeStrategy,
              storeAction: action || storeStrategy.default,
            },
            data,
            state[path].data,
            limit
          ),
          loading: false,
        };
      }

      return {
        ...state[path],
        data,
        loading: false,
      };
    },
    [DYNAMIC_UPDATE_SUCCESS]: (
      state,
      {
        payload: { data },
        meta: { path, limit, storeStrategy, contentSchema, action },
      }
    ) => {
      return {
        ...state[path],
        data: reduceDynamicData(
          contentSchema,
          {
            ...storeStrategy,
            storeAction: action || storeStrategy.default,
          },
          data,
          state[path]?.data,
          limit
        ),
        loading: false,
      };
    },
    [DYNAMIC_CLEAR_SUCCESS]: () => {
      return null;
    },
    [DYNAMIC_FAILURE]: (state, { meta: { path }, payload }) => {
      return {
        ...state[path],
        data: (state[path] && state[path].data) || {},
        error: processError(payload),
        loading: false,
      };
    },
    [DYNAMIC_EDIT_REQUEST]: (state, { meta: { editPath, body, path } }) => ({
      ...state[path],
      [editPath]: mapValues(body, () => true),
      loading: true,
    }),
    [DYNAMIC_EDIT_FAILURE]: (state, { meta: { editPath, body, path } }) => ({
      ...state[path],
      [editPath]: mapValues(body, () => false),
      loading: false,
    }),
    [DYNAMIC_EDIT_SUCCESS]: (
      state,
      {
        payload: { data },
        meta: { responseBody, editPath, body, path, storeStrategy },
      }
    ) => {
      const items =
        state[path].data && state[path].data.items
          ? state[path].data.items.map(item =>
              responseBody === item
                ? {
                    ...item,
                    ...data,
                  }
                : item
            )
          : [];

      let resultData = {
        ...state[path].data,
        items,
      };

      if (storeStrategy) {
        console.log('storeStrategy', storeStrategy);
        const sortByKeys = getStoreStrategySortKeys(storeStrategy);
        if (sortByKeys && sortByKeys.length) {
          resultData = sortDataItems(resultData, sortByKeys);
        }
      }

      return {
        ...state[path],
        data: resultData,
        [editPath]: mapValues(body, () => false),
        loading: false,
      };
    },
  },
  initialPathState
);

const processError = error => {
  let form_errors;

  if (error.response && error.response.form_errors) {
    try {
      form_errors = JSON.parse(error.response.form_errors);
    } catch (e) {}

    error.response.form_errors = form_errors || error.response.form_errors;
  }

  return error;
};

export const getDataOperationId = (state, routes, path) => {
  return getOperationIdByPath(routes, path);
};

export const getDataKey = (state, url) => {
  return url;
};

const selectData = (state, routes, url, path) => {
  const dataKey = getDataKey(state, url, path);
  const dataOperationId = getDataOperationId(state, routes, path);

  return state.data[dataOperationId] && state.data[dataOperationId][dataKey];
};

export const getData = (state, routes, url, source, path) => {
  const byPath = selectData(state, routes, url, path);

  if (!byPath) {
    return;
  }

  return source ? get(byPath.data, source) : byPath.data;
};

export const getError = (state, routes, url, path) => {
  const data = selectData(state, routes, url, path);

  return (data && data.error) || null;
};

export const getDataLoading = (state, routes, url, source, path) => {
  const data = selectData(state, routes, url, path);

  return data && data.loading;
};

export const getLoading = (state, routes, url, path) => {
  const data = selectData(state, routes, url, path);

  return data && data.loading;
};

export const getFieldsLoading = (state, path, editPath) =>
  (state.data[path] && state.data[path][editPath]) || {};

export const getGlobalLoading = (state, routes) =>
  Object.keys(state.data).some(path => getLoading(state, routes, path, path));
