import React, { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import getDescriptionEventMarkets from '../../../../gql/TradingTool/queries/getDescriptionEventMarkets';

const useEventMarketsDetails = ({ eventId, competitors }) => {
  const {
    loading,
    error,
    data: { getDescriptionEventMarkets: markets } = {},
    refetch: refetchDescriptionEventMarkets,
  } = useQuery(getDescriptionEventMarkets, {
    variables: {
      id: eventId,
    },
    fetchPolicy: 'network-only',
    skip: !eventId,
  });

  const hasNoData = useMemo(() => {
    return loading || error || !markets;
  }, [loading, error, markets]);

  const formatOutcomeName = useCallback(
    ({ name, specifierValues }) => {
      return name
        .replaceAll('{$competitor1}', competitors?.[0]?.name || 'Competitor 1')
        .replaceAll('{$competitor2}', competitors?.[1]?.name || 'Competitor 2')
        .replaceAll('{total}', specifierValues.total)
        .replaceAll(
          '({+hcp})',
          `(${specifierValues.hcp && specifierValues.hcp < 0 ? '-' : '+'}${(
            specifierValues.hcp || ''
          ).replaceAll('-', '')})`
        )
        .replaceAll(
          '({-hcp})',
          `(${specifierValues.hcp && specifierValues.hcp < 0 ? '+' : '-'}${(
            specifierValues.hcp || ''
          ).replaceAll('-', '')})`
        );
    },
    [competitors]
  );

  const formatMarketName = useCallback(
    ({ name, specifierValue, isShowSpecifier }) => {
      const marketNameString = name
        .replaceAll('{$competitor1}', competitors?.[0]?.name || 'Competitor 1')
        .replaceAll('{$competitor2}', competitors?.[1]?.name || 'Competitor 2');

      const specifierValueString = specifierValue
        ? `(${specifierValue})`
        : '(unset)';

      return `${marketNameString} ${
        isShowSpecifier ? specifierValueString : ''
      }`;
    },
    [competitors]
  );

  const getSpecifiersList = useCallback(
    ({ marketId }) => {
      if (hasNoData) {
        return [];
      }

      const market = markets.find(({ id }) => id === marketId);

      return market.specifiers;
    },
    [hasNoData, markets]
  );

  const isSpecifierEditable = useCallback(
    ({ marketId }) => {
      if (hasNoData) {
        return false;
      }

      const market = markets.find(({ id }) => id === marketId);

      return !!market?.dynamic_outcomes;
    },
    [hasNoData, markets]
  );

  const isFreeTextMarket = useCallback(
    ({ marketId }) => {
      if (hasNoData) {
        return false;
      }

      const market = markets.find(({ id }) => id === marketId);

      return market?.outcome_type === 'text';
    },
    [hasNoData, markets]
  );

  const getOutcomeName = useCallback(
    ({ marketId, specifier, outcomeId }) => {
      if (hasNoData) {
        return '';
      }

      const specifierValues = specifier.split('|').reduce((acc, specifier) => {
        const [key, value] = specifier.split('=');

        acc[key] = value;
        return acc;
      }, {});

      const market = markets.find(({ id }) => id === marketId);

      if (!market) return '';

      const variant =
        market.variants[specifier] || Object.values(market.variants)[0];
      const outcome = variant.outcomes.find(({ id }) => id === outcomeId);

      return outcome
        ? formatOutcomeName({ name: outcome.name, specifierValues })
        : '';
    },
    [hasNoData, markets, formatOutcomeName]
  );

  const getMarketName = useCallback(
    ({ marketId, specifierValue, isShowSpecifier = false }) => {
      if (hasNoData) {
        return '';
      }

      const market = markets.find(({ id }) => id === marketId);

      return formatMarketName({
        name: market.name,
        specifierValue,
        isShowSpecifier,
      });
    },
    [hasNoData, markets, formatMarketName]
  );

  const isCalculateEnabled = useCallback(() => {
    if (hasNoData) {
      return false;
    }

    return markets.some(({ support_math_model }) => support_math_model);
  }, [hasNoData, markets]);

  const isSupportMarketModel = useCallback(
    ({ marketId }) => {
      if (hasNoData) {
        return false;
      }

      const market = markets.find(({ id }) => id === marketId);

      return !!market.support_math_model;
    },
    [hasNoData, markets]
  );

  return {
    getSpecifiersList,
    getOutcomeName,
    getMarketName,
    isSpecifierEditable,
    isFreeTextMarket,
    isCalculateEnabled,
    isSupportMarketModel,
    refetchDescriptionEventMarkets,
  };
};

export default useEventMarketsDetails;
