import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { UiOptionsContext } from './UiOptionsProvider';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../icons/controls/CloseIcon';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&>div[tabindex="-1"]': {
      maxWidth: '75%',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  modalContent: {
    background: '#fff',
    padding: 16,
    paddingTop: 48,
    borderRadius: 8,
    zIndex: 1,
  },
  imageField: {
    display: 'flex',
    alignItems: 'center',

    '&>img': {
      height: 30,
      cursor: 'zoom-in',
      borderRadius: 2,
    },
  },
  imagePreview: {
    maxWidth: '100%',
    verticalAlign: 'middle',
  },
  modalCloseRoot: {
    transition: 'color 0.3s ease-in-out',
    color: '#8496AB',
    padding: 0,
    position: 'absolute',
    top: 8,
    right: 8,
    '& svg': {
      fontSize: '32px',
    },
    '&:hover': {
      color: '#031224',
      backgroundColor: 'initial',
    },
  },
});

const ImageField = () => {
  const classes = useStyles();
  const { value } = useContext(UiOptionsContext);

  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={classes.imageField}>
      <img
        src={value.data}
        alt={'Preview'}
        onClick={() => {
          setIsOpened(true);
        }}
      />

      <Modal
        className={classes.modal}
        open={isOpened}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={() => {
          setIsOpened(false);
        }}
      >
        <div className={classes.modalContent}>
          <IconButton
            classes={{
              root: classes.modalCloseRoot,
            }}
            aria-label="close"
            variant={'text'}
            disableRipple
            onClick={() => {
              setIsOpened(false);
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>

          <img
            src={value.data}
            alt={'Preview'}
            className={classes.imagePreview}
          />
        </div>
      </Modal>

      {/*<Dialog*/}
      {/*  TransitionComponent={Transition}*/}
      {/*  onClose={() => {*/}
      {/*    setIsOpened(false);*/}
      {/*  }}*/}
      {/*  maxWidth="sm"*/}
      {/*  aria-labelledby="image-preview-dialog-title"*/}
      {/*  open={isOpened}*/}
      {/*>*/}
      {/*  <DialogTitle disableTypography id="image-preview-dialog-title">*/}

      {/*  </DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*   */}
      {/*  </DialogContent>*/}
      {/*  <DialogActions />*/}
      {/*</Dialog>*/}
    </div>
  );
};

export default ImageField;
