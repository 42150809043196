import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

const getValue = (value, valueKey, isValueObject) => {
  if (isValueObject) {
    return value === null ? null : value[valueKey];
  }

  return value;
};

const checkValueOptions = (
  value,
  options,
  valueKey,
  multiple,
  isValueObject
) => {
  if (multiple && value) {
    const filtered = value.filter(item =>
      options.find(
        option => option[valueKey] === getValue(item, valueKey, isValueObject)
      )
    );

    if (!isEqual(value, filtered)) {
      return filtered;
    }
  } else {
    if (
      !options.find(
        option => option[valueKey] === getValue(value, valueKey, isValueObject)
      )
    ) {
      return isValueObject ? null : '';
    }
  }
};

const useSelectCheckValueOptions = ({
  value,
  options,
  valueKey = 'value',
  multiple,
  onChange,
  isValueObject = false,
  loading,
  check = true,
}) => {
  useEffect(() => {
    if (!check || typeof options === 'undefined') {
      return;
    }

    if (options.length) {
      if (value) {
        const checkedValue = checkValueOptions(
          value,
          options,
          valueKey,
          multiple,
          isValueObject
        );
        if (typeof checkedValue !== 'undefined') {
          onChange && onChange(checkedValue);
        }
      }
    } else {
      if (value && (!multiple || value.length) && !loading) {
        onChange(multiple ? [] : isValueObject ? null : '');
      }
    }
    // eslint-disable-next-line
  }, [check, value, options, valueKey, multiple, loading]);
};

export default useSelectCheckValueOptions;
