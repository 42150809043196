import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import Checkbox from '../../../../Checkbox';
import { ItemMoveButton, SportOption } from '../../../components/';
import { useCoverageTemplateOption } from '../../../hooks/coverage/options/useCoverageTemplateOption';
import { changePath } from '../../../utils/changePath';
import { clearPath } from '../../../utils/getKeySelector';

const useStyles = makeStyles(theme => ({
  menuItemCheckbox: {
    padding: '0 3px 0 3px',
  },
}));

const CoverageSportOption = ({
  templateId,
  nodePath = {},
  nodeIndex,
  selected,
  itemValue,
  itemIndex,
  options,
  optionsFullListRef,
  lineType,
  setDisplayOptions,
  dragDropDisabled,
  configurationDisabled,
  provided,
}) => {
  const classes = useStyles();
  const { showToast } = useToasts();

  const [searchParams] = useSearchParams();
  const duplicate = !!searchParams.get('duplicate');

  const { getValues, setValue } = useFormContext();
  const {
    setNodePath,
    setData,
    setTreeSelectLoading,
    setCoverageMarketGroups,
    getCoverageTemplateQuery,
    setSelectedCoverageItemsConfig,
  } = useTreeSelectContext();

  const nodeSelector = {
    sportId: itemValue.sportId,
  };
  const { checked, onChangeStatus } = useCoverageTemplateOption({
    itemValue,
    options,
    optionsFullListRef,
    nodeSelector,
    getFormValues: getValues,
    setFormValue: setValue,
    idKey: 'sportId',
    setDisplayOptions,
  });

  const addMarketGroups = () => {
    setCoverageMarketGroups(currentValue => {
      if (!currentValue[itemValue.sportId]) {
        return {
          ...currentValue,
          [itemValue.sportId]: itemValue.marketGroupPriorities || [],
        };
      }
      return currentValue;
    });
  };

  const refreshSelectedItemStatus = status => {
    setSelectedCoverageItemsConfig(curr => ({
      ...curr,
      [lineType]: {
        ...curr[lineType],
        sport: { ...curr[lineType].sport, status },
      },
    }));
  };

  const handleStatusCheckbox = value => {
    onChangeStatus(value);
    if (selected) {
      refreshSelectedItemStatus(value);
    }
  };

  //on option click we need to highlight this option, after this request new lists of options
  //for get new option list we need to pass ids of previous selected items
  const onSelect = () => {
    const prevNodePath = nodePath;
    let path = changePath({
      nodeId: itemValue.sportId,
      nodeKey: 'sportId',
      nodeIndex,
      nodePath,
      setNodePath,
      lineType,
    });
    addMarketGroups();

    getCoverageTemplateQuery(
      {
        templateId,
        path: clearPath(path),
        lineType,
      },
      duplicate
    )
      .then(data => {
        const newData = withoutEmpty(data.data.data);
        refreshSelectedItemStatus(checked);
        setTreeSelectLoading(false);
        setData(currentData => {
          return {
            ...currentData,
            [lineType]: {
              marketsToNode: newData.marketsToNode,
              sports: currentData[lineType].sports,
              categories: newData.categories,
              tournaments: newData.tournaments
                ? {
                    sport: newData.tournaments ? newData.tournaments.sport : {},
                  }
                : null,
              markets: newData.markets,
            },
          };
        });
      })
      .catch(() => {
        setTreeSelectLoading(false);
        setNodePath(currentPath => ({
          ...currentPath,
          [lineType]: prevNodePath,
        }));
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can not fetch data
          </NotificationAlert>
        );
      });
  };
  const dragHandleProps = !configurationDisabled &&
    !dragDropDisabled && { ...provided.dragHandleProps };

  return (
    <Box display={'flex'} alignItems={'center'}>
      <ItemMoveButton
        dragHandleProps={dragHandleProps}
        selected={selected}
        dragDropDisabled={configurationDisabled || dragDropDisabled}
      />
      <Checkbox
        extraClasses={{ checkboxRoot: classes.menuItemCheckbox }}
        value={checked}
        onChange={handleStatusCheckbox}
        disabled={configurationDisabled || itemValue.readOnly}
      />
      <SportOption
        selected={selected}
        withTooltip
        id={itemValue.sportId}
        name={itemValue.name}
        onClick={onSelect}
      />
    </Box>
  );
};

export default memo(CoverageSportOption);
