import React, { useContext, useState } from 'react';
import { UiOptionsContext } from '../UiOptionsProvider';
import CountryIcon from '../../../icons/CountryIcon';
import DeviceIcon from '../../../icons/DeviceIcon';
import SportIcon from '../../../icons/SportIcon';
import MaterialIcon from '../../../icons/MaterialIcon';
import makeStyles from '@mui/styles/makeStyles';

const IconNamespaceEnum = {
  SPORT: 'spt77_sport',
  COUNTRY: 'country',
  DEVICE: 'device',
  MATERIAL: 'material',
};

const IconByNamespace = {
  [IconNamespaceEnum.COUNTRY]: CountryIcon,
  [IconNamespaceEnum.DEVICE]: DeviceIcon,
  [IconNamespaceEnum.SPORT]: SportIcon,
  [IconNamespaceEnum.MATERIAL]: MaterialIcon,
};

const useStyles = makeStyles({
  [IconNamespaceEnum.COUNTRY]: {
    height: 16,
    width: 16,
  },

  [IconNamespaceEnum.DEVICE]: {
    height: 18,
    width: 18,
    color: '#8496AB',
  },

  [IconNamespaceEnum.SPORT]: {
    fontSize: 18,
    color: '#8496AB',
  },
});

const IconField = () => {
  const classes = useStyles();
  const { value } = useContext(UiOptionsContext) || {};
  const { icons, data } = value;

  const iconNamespace =
    icons && IconByNamespace[icons] ? icons : IconNamespaceEnum.MATERIAL;

  const IconComponent = IconByNamespace[iconNamespace];

  const [width] = useState(value.width || 'initial');

  return (
    <div
      style={{
        width,
      }}
    >
      <IconComponent name={data} className={classes[iconNamespace]} />
    </div>
  );
};

export default IconField;
