import { gql } from '@apollo/client';

export const UPDATE_VERSIONED_RESULT_TYPE = gql`
  fragment UpdateVersionedResultType on UpdateVersionedResult {
    version
    status
    error
  }
`;

const updateSourcesTemplate = gql`
  ${UPDATE_VERSIONED_RESULT_TYPE}
  mutation updateSourcesTemplate(
    $templateId: TemplateId!
    $input: SourcesTemplateInput!
  ) {
    data: updateSourcesTemplate(templateId: $templateId, input: $input) {
      ...UpdateVersionedResultType
    }
  }
`;

export default updateSourcesTemplate;
