import { gql } from '@apollo/client';

const OPERATOR_BETSTREAM_FIELDS = gql`
  fragment OperatorBetstreamFields on BetStreamModel {
    created_at
    watch
    date_time
    expire_timestamp
    country
    source
    player_id
    username
    ext_player_id
    operator
    operator_id
    bookmaker_id
    limit_id
    brand
    brand_id
    bet_type
    bet_spec
    sport {
      sport_id
      event
      event_id
      sport
      tournament
      category
      market
      outcome
      type
      odds
      score
      event_scheduled
    }
    odds
    stake
    currency
    stake_EUR
    bonus_id
    bonus_type
    bonus
    net_win
    net_win_EUR
    bet_status
    bet_id
    paid_for
    statistics {
      ggr
      margin
      turnover
      bets
      payout
    }
    reject_code
    reject_reason
    status
    is_vip_request
    rm_comment
    vip_request_result {
      result_type
      resolved_by
      comment
    }
    ccf
    risk_management_id
    mts
    segment_id
    segment_reviewed
    operator_bonus
    operator_bonus_EUR
  }
`;

export default OPERATOR_BETSTREAM_FIELDS;
