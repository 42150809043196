import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ObjectInspector } from 'react-inspector';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 9999,
    padding: 16,
    maxWidth: 400,
    background: '#fff',
    boxShadow: '0px 2px 16px rgba(132, 150, 171, 0.25)',
    wordBreak: 'break-all',
  },
});

const DebuggerPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const FieldDebugger = ({ schema, uiSchema, compiledSchema, children }) => {
  const classes = useStyles();
  const [debuggerOpened, setDebuggerOpened] = useState(false);

  return (
    <>
      <DebuggerPortal>
        {debuggerOpened && (
          <div className={classes.root}>
            <ObjectInspector
              name={'Result schema'}
              data={compiledSchema}
              expandLevel={10}
            />
            <ObjectInspector
              name={'Original schema'}
              data={schema}
              expandLevel={10}
            />
            <ObjectInspector
              name={'Original UI schema'}
              data={uiSchema}
              expandLevel={10}
            />
          </div>
        )}
      </DebuggerPortal>

      <div
        onMouseEnter={() => setDebuggerOpened(true)}
        onMouseLeave={() => setDebuggerOpened(false)}
      >
        {children}
      </div>
    </>
  );
};

export default FieldDebugger;
