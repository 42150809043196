import React from 'react';
import SearchSelectInput from '../../inputs/SearchSelectInput';

const SearchSelectWidget = ({
  options: {
    operationId,
    value_key = 'id',
    label_key = 'name',
    secondary_key = 'secondary_text',
    params,
  },
  ...props
}) => (
  <SearchSelectInput
    margin={'dense'}
    variant="outlined"
    operationId={operationId}
    valueKey={value_key}
    labelKey={label_key}
    secondaryKey={secondary_key}
    params={params}
    {...props}
  />
);

export default SearchSelectWidget;
