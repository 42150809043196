import { pickBy } from 'lodash';
import { withoutEmpty } from '../../../../hooks/usePresetFilters';

//to removes market in path if marketId null
export const pathPredicate = (value, key) => {
  return key === 'market' ? !!value.marketId : !!value;
};

//remove empty values from path
export const clearPath = nodePath => {
  return pickBy(nodePath, pathPredicate);
};

export const getKeySelector = ({ nodeSelector, lineType, tabKey }) => {
  return JSON.stringify(
    withoutEmpty({ ...nodeSelector, lineType, tabKey })
  ).replaceAll('"', '');
};
