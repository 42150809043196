import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ArrayChipField from '../Inputs/ArrayChipField/ArrayChipField';

const validationByType = {
  number: {
    test: value => /^(-?\d+\.\d+)$|^(-?\d+)$/.test(value),
    message: 'Value must be valid number',
  },
  integer: {
    test: value => /^-?\d+$/.test(value),
    message: 'Value must be valid integer',
  },
};

const getValidationError = ({ type, minimum, maximum, value }) => {
  const validation = validationByType[type];
  if (validation && !validation.test(value)) {
    return {
      type: 'manual',
      message: validation.message,
    };
  }

  if (typeof minimum !== 'undefined' && value < minimum) {
    return {
      type: 'manual',
      message: `Value must not be less than ${minimum}`,
    };
  }

  if (typeof maximum !== 'undefined' && value < maximum) {
    return {
      type: 'manual',
      message: `Value must not be greater than ${maximum}`,
    };
  }
};

const ArrayChipFilter = ({
  label,
  fullWidth,
  error,
  helperText,
  onChange,
  onBlur,
  value = [],
  itemType,
  minimum,
  maximum,
  itemFormat,
  maxItems,
  minItems,
  name,
  InputProps,
}) => {
  const { setError, clearErrors } = useFormContext();

  const [inputValue, setInputValue] = useState('');

  const onUpdateInput = useCallback(
    value => {
      const validationError = getValidationError({
        minimum,
        maximum,
        type: itemType,
        value,
      });

      if (error && (!value || !validationError)) {
        clearErrors([name]);
      } else if (value && validationError) {
        setError(name, validationError);
      }

      setInputValue(value);
    },
    [itemType, error, setError, name, clearErrors, minimum, maximum]
  );

  return (
    <ArrayChipField
      name={name}
      maxItems={maxItems}
      minItems={minItems}
      inputValue={inputValue}
      setInputValue={onUpdateInput}
      value={value}
      onChange={onChange}
      type={itemType}
      format={itemFormat}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      label={label}
      onBlur={onBlur}
      InputProps={InputProps}
    />
  );
};

export default ArrayChipFilter;
