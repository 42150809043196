import React, { forwardRef } from 'react';
import { SvgIcon } from '@mui/material';

const MobileDeviceIcon = forwardRef((props, ref) => {
  return (
    <SvgIcon viewBox={'0 0 10 16'} {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.89543 16 2 16H8C9.10457 16 10 15.1046 10 14V2C10 0.895431 9.10457 0 8 0H2ZM1 2C1 1.44772 1.44772 1 2 1H3C3 1.55228 3.44772 2 4 2H6C6.55228 2 7 1.55228 7 1H8C8.55228 1 9 1.44772 9 2V14C9 14.5523 8.55228 15 8 15H2C1.44772 15 1 14.5523 1 14V2Z"
      />
    </SvgIcon>
  );
});

export default MobileDeviceIcon;
