import React from 'react';
import PropTypes from 'prop-types';
import {
  getDefaultRegistry,
  getUiOptions,
  getWidget,
  isSelect,
  optionsList,
  hasWidget,
} from 'react-jsonschema-form/lib/utils';

function StringField(props) {
  const {
    schema,
    uiSchema,
    idSchema,
    errorSchema,
    formData,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    registry = getDefaultRegistry(),
    type,
  } = props;
  const { title, format } = schema;
  const { widgets, formContext } = registry;
  const enumOptions = isSelect(schema) && optionsList(schema);
  let defaultWidget = enumOptions ? 'select' : 'text';
  if (format && hasWidget(schema, format, widgets)) {
    defaultWidget = format;
  }
  const {
    widget = defaultWidget,
    placeholder = title,
    ...options
  } = getUiOptions(uiSchema);

  const formatDate = uiSchema.format_date;

  const Widget = getWidget(
    schema,
    widget.match(/int32|int64/) ? 'text' : widget,
    widgets
  );

  const label = uiSchema['ui:title'];

  return (
    <Widget
      type={type}
      options={{
        ...options,
        enumOptions,
        formatDate,
        inputType: type || options.inputType,
      }}
      schema={schema}
      errorSchema={errorSchema}
      id={idSchema && idSchema.$id}
      label={label}
      value={formData}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      required={required}
      disabled={disabled}
      readonly={readonly}
      formContext={formContext}
      autofocus={autofocus}
      registry={registry}
      placeholder={placeholder}
    />
  );
}

StringField.propTypes = {
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  idSchema: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  formData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registry: PropTypes.shape({
    widgets: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    ).isRequired,
    fields: PropTypes.objectOf(PropTypes.func).isRequired,
    definitions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
  }),
  formContext: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
};

StringField.defaultProps = {
  uiSchema: {},
  disabled: false,
  readonly: false,
  autofocus: false,
};

export default StringField;
