import * as React from 'react';
import clsx from 'clsx';
import TablePaginationActions from './TablePaginationActions';
import Toolbar from '@mui/material/Toolbar';
import { MenuItem, TableCell } from '@mui/material';
import useId from '../../utils/unstable_useId';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: 'auto',
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
    },
  },
  /* Styles applied to the Toolbar component. */
  toolbar: {
    minHeight: 52,
    paddingRight: 2,
  },
  /* Styles applied to the spacer element. */
  spacer: {
    flex: '1 1 100%',
  },
  /* Styles applied to the caption Typography components if `variant="caption"`. */
  caption: {
    flexShrink: 0,
  },
  // TODO v5: `.selectRoot` should be merged with `.input`
  /* Styles applied to the Select component root element. */
  formControl: {
    marginRight: 32,
    marginLeft: 8,
  },
  /* Styles applied to the Select component `select` class. */
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: 'right',
    textAlignLast: 'right', // Align <select> on Chrome.
  },
  // TODO v5: remove
  /* Styles applied to the Select component `icon` class. */
  selectIcon: {},
  /* Styles applied to the `InputBase` component. */
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
  },
  /* Styles applied to the MenuItem component. */
  menuItem: {},
  /* Styles applied to the internal `TablePaginationActions` component. */
  actions: {
    flexShrink: 0,
    marginLeft: 20,
  },
});

const defaultLabelDisplayedRows = ({ from, to, count }) =>
  `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
const defaultRowsPerPageOptions = [10, 25, 50, 100];

/**
 * A `TableCell` based component for placing inside `TableFooter` for pagination.
 */
const TablePagination = React.forwardRef(function TablePagination(props, ref) {
  const {
    ActionsComponent = TablePaginationActions,
    backIconButtonProps,
    backIconButtonText = 'Previous page',
    classes,
    className,
    colSpan: colSpanProp,
    component: Component = TableCell,
    count,
    labelDisplayedRows = defaultLabelDisplayedRows,
    labelRowsPerPage = 'Rows per page:',
    nextIconButtonProps,
    nextIconButtonText = 'Next page',
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    SelectProps = {},
    ...other
  } = props;
  let colSpan;

  if (Component === TableCell || Component === 'td') {
    colSpan = colSpanProp || 1000; // col-span over everything
  }

  const selectId = useId();
  const labelId = useId();
  const MenuItemComponent = SelectProps.native ? 'option' : MenuItem;

  return (
    <Component
      className={clsx(classes.root, className)}
      colSpan={colSpan}
      ref={ref}
      {...other}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.spacer} />
        {rowsPerPageOptions.length > 1 && (
          <div className={classes.caption} id={labelId}>
            {labelRowsPerPage}
          </div>
        )}
        {rowsPerPageOptions.length > 1 && (
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              classes={{
                select: classes.select,
                icon: classes.selectIcon,
              }}
              value={rowsPerPage}
              onChange={onChangeRowsPerPage}
              id={selectId}
              labelId={labelId}
              {...SelectProps}
            >
              {rowsPerPageOptions.map(rowsPerPageOption => (
                <MenuItemComponent
                  className={classes.menuItem}
                  key={
                    rowsPerPageOption.value
                      ? rowsPerPageOption.value
                      : rowsPerPageOption
                  }
                  value={
                    rowsPerPageOption.value
                      ? rowsPerPageOption.value
                      : rowsPerPageOption
                  }
                >
                  {rowsPerPageOption.label
                    ? rowsPerPageOption.label
                    : rowsPerPageOption}
                </MenuItemComponent>
              ))}
            </Select>
          </FormControl>
        )}
        <div className={classes.caption}>
          {labelDisplayedRows({
            from: count === 0 ? 0 : page * rowsPerPage + 1,
            to:
              count !== -1
                ? Math.min(count, (page + 1) * rowsPerPage)
                : (page + 1) * rowsPerPage,
            count: count === -1 ? -1 : count,
            page,
          })}
        </div>
        <ActionsComponent
          className={classes.actions}
          backIconButtonProps={{
            title: backIconButtonText,
            'aria-label': backIconButtonText,
            ...backIconButtonProps,
          }}
          count={count}
          nextIconButtonProps={{
            title: nextIconButtonText,
            'aria-label': nextIconButtonText,
            ...nextIconButtonProps,
          }}
          onChangePage={onChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Toolbar>
    </Component>
  );
});

export default withStyles(styles, { name: 'MuiTablePagination' })(
  TablePagination
);
