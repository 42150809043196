import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import PersonIcon from '@mui/icons-material/Person';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CardIcon from './CardIcon';
import LoadingTextPlaceholder from '../../components/LoadingTextPlaceholder';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  card: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    overflow: 'visible',
    height: '100%',
  },
  top: {
    display: 'flex',
  },
  cardIcon: {
    position: 'relative',
    top: -theme.spacing(1) * 3,
    marginBottom: -theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  bottom: {
    marginTop: theme.spacing(1),
  },
});

const CardWithIcon = ({ classes, data, loading }) => (
  <div className={classes.root}>
    <Card className={classes.card}>
      <div className={classes.top}>
        <CardIcon className={classes.cardIcon} Icon={PersonIcon} />
        <div>
          <Typography color="textSecondary">
            {loading || !data ? <LoadingTextPlaceholder /> : data.title}
          </Typography>
          <Typography variant="h5">
            {loading || !data ? <LoadingTextPlaceholder /> : data.caption}
          </Typography>
        </div>
      </div>
      <Divider />
      <Typography className={classes.bottom} variant="caption">
        {loading || !data ? <LoadingTextPlaceholder /> : data.footer}
      </Typography>
    </Card>
  </div>
);

CardWithIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    footer: PropTypes.arrayOf(PropTypes.string),
  }),
  loading: PropTypes.bool,
};

export default withStyles(styles)(CardWithIcon);
