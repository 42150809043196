import React from 'react';
import PropTypes from 'prop-types';
import { asNumber } from 'react-jsonschema-form/lib/utils';
import useCorrectNumberEffect from './useCorrectNumberEffect';

function NumberField(props) {
  const { StringField } = props.registry.fields;
  useCorrectNumberEffect(props.formData, props.onChange);

  return (
    <StringField
      {...props}
      onChange={value => {
        const numberValue = asNumber(value);

        props.onChange(typeof numberValue === 'undefined' ? null : numberValue);
      }}
    />
  );
}

NumberField.propTypes = {
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object,
  idSchema: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  formContext: PropTypes.object.isRequired,
};

NumberField.defaultProps = {
  uiSchema: {},
};

export default NumberField;
