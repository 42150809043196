import React from 'react';

import Guacamole from './bundle';

class RDPClientComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { client: null, display: null };
  }

  initSession(endpointUrl, token) {
    let client = new Guacamole.Client(
      new Guacamole.WebSocketTunnel(endpointUrl)
    );
    let display = client.getDisplay().getElement();
    client.onerror = function (error) {
      console.error('💀', error);
    };

    client.connect(token);

    // Mouse
    let mouse = new Guacamole.Mouse(display);
    mouse.onmousedown =
      mouse.onmouseup =
      mouse.onmousemove =
        function (mouseState) {
          client.sendMouseState(mouseState);
        };

    // Keyboard
    let keyboard = new Guacamole.Keyboard(document);
    keyboard.onkeydown = function (keysym) {
      client.sendKeyEvent(1, keysym);
    };
    keyboard.onkeyup = function (keysym) {
      client.sendKeyEvent(0, keysym);
    };

    this.setState({
      client: client,
      display: display,
      keyboard: keyboard,
    });
  }

  componentDidMount() {
    this.initSession(this.props.endpointUrl, this.props.token);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!(!this.state.display && nextState.display);
  }

  componentWillUnmount() {
    if (this.state.client) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.keyboard.onkeydown = this.state.keyboard.onkeyup = null;
      this.state.client.disconnect();
    }
  }

  render() {
    if (this.state.display)
      return (
        <div
          ref={ref => (ref ? ref.appendChild(this.state.display) : null)}
          className={this.props.className}
        />
      );
    return null;
  }
}

export default RDPClientComponent;
