import React, { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import TabbedView from '../../../components/TabbedView';
import TabItem from '../../../components/TabItem';
import AddIcon from '@mui/icons-material/Add';
import PlusIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionField from '../../../components/DynamicForm/fields/DescriptionField';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import { emphasize } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import OrderListWidget from '../../PavoForm/widgets/OrderListWidget/OrderListWidget';
import TransferList from '../../PavoForm/widgets/TransferList/TransferList';
import OrderCheckListWidget from '../../PavoForm/widgets/OrderListWidget/OrderCheckListWidget';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: ({ $id }) =>
      `1px solid ${emphasize(
        theme.palette.background.paper,
        0.1 * (getLevel($id) + 1)
      )}`,
    backgroundColor: ({ $id }) =>
      emphasize(theme.palette.background.paper, 0.04 * (getLevel($id) + 1)),
  },
  inputField: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
  },
  inputButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  closeTabIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: 0,
    color: theme.palette.text.secondary,
    padding: 0,
  },
  tabContent: {
    padding: `${theme.spacing(2)} 0`,
    overflow: 'hidden',
  },
  leftSpacing: {
    marginLeft: theme.spacing(2),
  },
  errorText: {
    letterSpacing: '0.5px',
    fontSize: '11px',
    lineHeight: '14px',
    color: '#FF000F',
    fontWeight: 500,
  },
}));

const getLevel = $id => $id.split(/_\d+_/).length - 1;

const isTopLevel = $id => getLevel($id) === 0;

const ArrayOfFieldsTemplate = props => {
  const {
    items,
    canAdd,
    onAddClick,
    uiSchema: {
      'ui:widget': uiWidget,
      'ui:label': uiLabel,
      'ui:title': uiTitle,
    },
    formDataArray,
    title,
    description,
    idSchema: { $id },
    error,
    helperText,
  } = props;

  const [currentTab, setCurrentTab] = useState(0);
  const classes = useStyles({ $id });

  const getComponent = uiWidget => {
    switch (uiWidget) {
      case 'transferList':
        return <TransferList {...props} />;
      case 'order_list': {
        return <OrderListWidget {...props} />;
      }
      case 'order_checklist': {
        return <OrderCheckListWidget {...props} />;
      }
      case 'DynamicTabs':
        return (
          <TabbedView
            currentTab={currentTab}
            onTabChange={setCurrentTab}
            filters={
              canAdd && (
                <Button
                  mini
                  color="primary"
                  onClick={e => {
                    onAddClick(e);
                    setCurrentTab(items.length);
                  }}
                >
                  <AddIcon />
                  Add
                </Button>
              )
            }
          >
            {items &&
              items.map(({ key, ...element }, i) => (
                <TabItem
                  key={key}
                  label={
                    <span>
                      {formDataArray[i][uiLabel]}
                      <IconButton
                        component="div"
                        size="small"
                        className={classes.closeTabIcon}
                        onClick={e => {
                          e.stopPropagation();
                          if (
                            items.length > 1 &&
                            ((i < currentTab && currentTab) ||
                              currentTab === items.length - 1)
                          ) {
                            setCurrentTab(currentTab - 1);
                          }

                          element.onDropIndexClick(i);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </span>
                  }
                >
                  <div className={classes.tabContent}>{element.children}</div>
                </TabItem>
              ))}
          </TabbedView>
        );
      default:
        return (
          <Fragment>
            {items &&
              items.map(element => (
                <div key={element.key} className={classes.input}>
                  <div className={classes.inputField}>{element.children}</div>
                  <Divider />
                  <Button
                    fullWidth
                    color="primary"
                    aria-label="Delete"
                    onClick={() => element.onDropIndexClick(element.index)}
                  >
                    Delete {title}
                  </Button>
                </div>
              ))}
            {canAdd && (
              <Fragment>
                {isTopLevel($id) ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={onAddClick}
                  >
                    Add
                  </Button>
                ) : (
                  <Fab size="small" color="primary" onClick={onAddClick}>
                    <PlusIcon />
                  </Fab>
                )}
                <Typography
                  variant="caption"
                  display={'inline'}
                  className={classes.leftSpacing}
                >
                  {title}
                </Typography>
              </Fragment>
            )}
          </Fragment>
        );
    }
  };

  const Component = getComponent(uiWidget);

  return (
    <div className={classes.root} id={$id}>
      <div className={classes.header}>
        {(uiTitle || title) && (
          <Typography variant={isTopLevel($id) ? 'h6' : 'subtitle1'}>
            {uiTitle || title}
          </Typography>
        )}
        {description && <DescriptionField description={description} />}
        {(uiTitle || title || description) && <Divider />}
        <span className={classes.errorText}>{error && helperText}</span>
      </div>
      {Component}
    </div>
  );
};

export default ArrayOfFieldsTemplate;
