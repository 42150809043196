import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const CalendarIcon = ({ props }) => {
  return (
    <SvgIcon
      viewBox={'0 0 19 17'}
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1.5C4 0.671573 4.67157 0 5.5 0C6.32843 0 7 0.671573 7 1.5V2H12V1.5C12 0.671573 12.6716 0 13.5 0C14.3284 0 15 0.671573 15 1.5V2H17C18.1046 2 19 2.89543 19 4V15C19 16.1046 18.1046 17 17 17H2C0.895431 17 0 16.1046 0 15V4C0 2.89543 0.895431 2 2 2H4V1.5ZM12.5 6C12.2239 6 12 6.22386 12 6.5V8.5C12 8.77614 12.2239 9 12.5 9H14.5C14.7761 9 15 8.77614 15 8.5V6.5C15 6.22386 14.7761 6 14.5 6H12.5ZM8 6.5C8 6.22386 8.22386 6 8.5 6H10.5C10.7761 6 11 6.22386 11 6.5V8.5C11 8.77614 10.7761 9 10.5 9H8.5C8.22386 9 8 8.77614 8 8.5V6.5ZM8.5 10C8.22386 10 8 10.2239 8 10.5V12.5C8 12.7761 8.22386 13 8.5 13H10.5C10.7761 13 11 12.7761 11 12.5V10.5C11 10.2239 10.7761 10 10.5 10H8.5ZM4 10.5C4 10.2239 4.22386 10 4.5 10H6.5C6.77614 10 7 10.2239 7 10.5V12.5C7 12.7761 6.77614 13 6.5 13H4.5C4.22386 13 4 12.7761 4 12.5V10.5ZM16.5 4C16.7761 4 17 4.22386 17 4.5V14.5C17 14.7761 16.7761 15 16.5 15H2.5C2.22386 15 2 14.7761 2 14.5V4.5C2 4.22386 2.22386 4 2.5 4H16.5Z"
        fill="#1581FF"
      />
    </SvgIcon>
  );
};

export default CalendarIcon;
