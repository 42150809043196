import React from 'react';
import PropTypes from 'prop-types';
import DescriptionField from '../fields/DescriptionField.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';
import CheckBoxActiveIcon from '../../../icons/controls/CheckBoxActiveIcon';
import CheckBoxIcon from '../../../icons/controls/CheckBoxIcon';
import CheckboxIndeterminateIcon from '../../../icons/controls/CheckboxIndeterminateIcon';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: -theme.spacing(1),
  },
}));

function CheckboxWidget(props) {
  const {
    schema,
    id,
    value,
    required,
    disabled,
    readonly,
    label,
    autofocus,
    onChange,
    options: { help = ' ', title },
  } = props;

  const classes = useStyles();

  return (
    <div>
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}

      <FormControlLabel
        disabled={disabled || readonly}
        className={classes.formControl}
        control={
          <Checkbox
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxActiveIcon />}
            indeterminateIcon={<CheckboxIndeterminateIcon />}
            id={id}
            required={required}
            autoFocus={autofocus}
            checked={typeof value === 'undefined' ? false : value}
            onChange={event => onChange(event.target.checked)}
            value="checkbox"
            color="primary"
          />
        }
        label={title || label}
      />
      <FormHelperText>{help}</FormHelperText>
    </div>
  );
}

CheckboxWidget.defaultProps = {
  autofocus: false,
};

CheckboxWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxWidget;
