import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CompetitorField from './CompetitorField';
import styles from './index.styles';
import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const CompetitorsList = ({ sportId, languages }) => {
  const { watch } = useFormContext();
  const classes = styles();

  const competitorTeams = watch('competitors') || [];

  const [isExpanded, setIsExpanded] = useState(false);

  const renderExpandAction = () => {
    return isExpanded ? (
      <Button
        className={classes.actionButton}
        startIcon={<KeyboardArrowUp />}
        variant={'text'}
        disabled={competitorTeams.length < 2}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        Hide all competitors ({competitorTeams.length})
      </Button>
    ) : (
      <Button
        className={classes.actionButton}
        startIcon={<KeyboardArrowDown />}
        variant={'text'}
        disabled={competitorTeams.length < 2}
        onClick={() => {
          setIsExpanded(true);
        }}
      >
        Show all competitors ({competitorTeams.length})
      </Button>
    );
  };

  return (
    <Stack gap={'15px'}>
      {competitorTeams
        .filter((_, index) => isExpanded || index < 2)
        .map((value, index) => {
          return (
            <CompetitorField
              sportId={sportId}
              competitor={value}
              languages={languages}
              index={index}
            />
          );
        })}
      {competitorTeams.length > 2 && renderExpandAction()}
    </Stack>
  );
};

export default CompetitorsList;
