import { useCallback, useEffect, useMemo, useState } from 'react';

const withoutDisabled = (columns = [], disabledColumns = []) => {
  if (disabledColumns) {
    return columns.filter(column => disabledColumns.indexOf(column) === -1);
  }
  return columns;
};

const useTableColumnToggle = (
  initial,
  onToggleColumns = () => {},
  disabledColumns
) => {
  const [toggledColumns, setToggledColumns] = useState(initial);

  useEffect(() => {
    setToggledColumns(withoutDisabled(initial, disabledColumns));
  }, [initial, disabledColumns]);

  const toggleColumn = useCallback(
    newToggledColumns => {
      onToggleColumns(withoutDisabled(newToggledColumns, disabledColumns));
      setToggledColumns(withoutDisabled(newToggledColumns, disabledColumns));
    },
    [onToggleColumns, disabledColumns]
  );

  return useMemo(() => {
    return [toggledColumns, toggleColumn];
  }, [toggledColumns, toggleColumn]);
};

export default useTableColumnToggle;
