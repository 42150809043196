import useAutocompleteData from '../../../hooks/useAutocompleteData';
import Select from '../Select/Select';
import { forwardRef, useMemo } from 'react';

const CategorySelect = forwardRef(
  ({ multiple, disabled, sportId, ...props }, ref) => {
    const params = useMemo(
      () => ({
        sport_id: sportId,
      }),
      [sportId]
    );

    const { data: options = [] } = useAutocompleteData({
      url: '/api/v1/RiskManagementAdmin/suggestions/categories',
      params,
    });

    return (
      <Select
        ref={ref}
        label={'Category'}
        options={options}
        labelKey={'label'}
        valueKey={'value'}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default CategorySelect;
