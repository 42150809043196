import { Box } from '@mui/material';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const MenuCaption = ({ children }) => {
  const classes = useTreeSelectStyles();

  return <Box className={classes.menuCaptionContainer}>{children}</Box>;
};

export default MenuCaption;
