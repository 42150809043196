import React, { useState } from 'react';
import TabbedView from './TabbedView';

const ControlledTabbedView = props => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <TabbedView
      {...props}
      currentTab={currentTab}
      onTabChange={setCurrentTab}
    />
  );
};

export default ControlledTabbedView;
