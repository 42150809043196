import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import ArrowRightSmall from '../../../../icons/controls/ArrowRightSmall';
import Tabs from '../../../components/Tabs/Tabs';
import TabLabel from '../../../components/Tabs/TabLabel';
import TradingEventEditContainer from './TradingEventEditContainer';
import EVENT_QUERY from '../../../gql/TradingTool/queries/EVENT_QUERY';
import { EVENT_TABS } from '../constants';

const useStyles = makeStyles({
  topPanel: {
    background: '#fff',
    borderRadius: 4,
    padding: 12,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: '12px 18px',
    display: 'flex',
    alignItems: 'center',
    color: '#1581FF',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
  },
  backButtonArrow: {
    width: 12,
    height: 12,
    transform: 'rotate(180deg)',
    marginRight: 8,
  },
  affiliation: {
    color: '#1581FF',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'end',
  },
});

const TradingEventDetails = ({
  isCreating = false,
  eventId,
  creationFlowInitialData,
  onBackButtonClick,
  refetchEvents,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(
    isCreating ? EVENT_TABS.settings : EVENT_TABS.trading
  );

  const { data } = useQuery(EVENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: eventId,
    },
    skip: !eventId,
  });

  const affiliation = useMemo(() => {
    if (data?.data) {
      const {
        fixture: { sport, category, tournament, competitors },
      } = data.data;

      return `${sport.name}, ${category.name}, ${tournament.name}, ${competitors
        .map(({ name }) => name)
        .join(' vs ')}`;
    }
    return '';
  }, [data]);

  return (
    <Box className={classes.tradingEventTradePage}>
      <Box className={classes.topPanel}>
        <Box className={classes.backButton} onClick={onBackButtonClick}>
          <ArrowRightSmall className={classes.backButtonArrow} />
          Back
        </Box>
        <Box className={classes.affiliation}>{affiliation}</Box>
      </Box>
      <Tabs
        activeTab={activeTab}
        activeTabKey={activeTab}
        fullWidthTabs
        withoutSpacing
        tabs={[
          {
            key: EVENT_TABS.settings,
            label: <TabLabel>Settings</TabLabel>,
          },
          {
            key: EVENT_TABS.trading,
            label: <TabLabel>Trading</TabLabel>,
            disabled: !eventId,
          },
        ]}
        onChangeTab={tab => setActiveTab(tab)}
      />
      <TradingEventEditContainer
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        eventId={eventId}
        eventData={data?.data}
        creationFlowInitialData={creationFlowInitialData}
        onBackButtonClick={onBackButtonClick}
        refetchEvents={refetchEvents}
      />
    </Box>
  );
};

export default TradingEventDetails;
