import DragDropMenu from '../../../Table/DragDropMenu';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const DragDropList = ({
  onDragEnd,
  onDragStart,
  isMenuOpen,
  toggledItems,
  disabledColumns = [],
  items,
  onToggleItem,
  checkboxIndeterminateMode = false,
  virtualized = false,
}) => {
  const classes = useTreeSelectStyles();

  return (
    <DragDropMenu
      droppableId="droppable1"
      checkboxIndeterminateMode={checkboxIndeterminateMode}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      isMenuOpen={isMenuOpen}
      toggledColumns={toggledItems}
      disabledColumns={disabledColumns}
      columns={items}
      onToggleColumn={onToggleItem}
      virtualized={virtualized}
      inlineMenu={true}
      customContainerClass={classes.dragListContainer}
      enableUncheckLast
    />
  );
};

export default DragDropList;
