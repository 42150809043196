import Box from '@mui/material/Box';

const BannerTabNotification = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        background: '#FF000F',
        width: '8px',
        height: '8px',
        right: '-12px',
        borderRadius: '4px',
        top: '0',
      }}
    />
  );
};

export default BannerTabNotification;
