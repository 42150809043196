import { gql } from '@apollo/client';
import { COVERAGE_MARKET_NODE_TYPE } from './getCoverageTemplate';

export const COVERAGE_TEMPLATE_NODE_TYPE = gql`
  ${COVERAGE_MARKET_NODE_TYPE}
  fragment CoverageTemplateNodesType on CoverageTemplateNodes {
    marketsToNode
    markets {
      ...CoverageMarketNodeType
    }
  }
`;

const getCoverageTemplateMarkets = gql`
  ${COVERAGE_TEMPLATE_NODE_TYPE}
  query getCoverageTemplate(
    $path: NodeSelectorInput
    $templateId: TemplateId
    $lineType: LineType!
    $markets_limit: Int
    $markets_offset: Int
    $search_query: String
  ) {
    data: getCoverageTemplate(
      path: $path
      templateId: $templateId
      lineType: $lineType
      markets_limit: $markets_limit
      markets_offset: $markets_offset
      search_query: $search_query
    ) {
      ...CoverageTemplateNodesType
    }
  }
`;

export default getCoverageTemplateMarkets;
