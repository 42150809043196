import { createContext, useCallback, useContext, useState } from 'react';
import useFiltersQuery from '../hooks/useFiltersQuery';
import { useApiHost } from '../../providers/ApiHostProvider';
import { getUserToken } from '../../reducers/users';
import { connect } from 'react-redux';
import { saveCsvFile } from '../../utils/saveFile';
import { withoutEmpty } from '../hooks/usePresetFilters';
import { isEmpty } from 'lodash';

export const ExportCSVProviderContext = createContext({
  exportCSV: () => {},
  isExporting: false,
  isFiltersApplied: false,
});

export const useExportCSV = () => {
  return useContext(ExportCSVProviderContext);
};

const ExportCSVProvider = ({
  sectionKey,
  fileName = 'items',
  token,
  children,
}) => {
  const PATH = `/api/v2/${sectionKey}/export_csv`;

  const [filtersQuery] = useFiltersQuery();
  const [isExporting, setIsExporting] = useState(false);
  const { apiHost } = useApiHost();

  const exportCsv = useCallback(() => {
    setIsExporting(true);

    fetch(`${apiHost}${PATH}`, {
      method: 'POST',
      body: JSON.stringify(withoutEmpty(filtersQuery)),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const data = await response.text();

        const filename = `${fileName}.csv`;

        if (response.status !== 200) {
          throw Error(response.body);
        }
        saveCsvFile(data, filename);

        setIsExporting(false);
      })
      .catch(error => {
        setIsExporting(false);
        console.error(error);
      });
  }, [PATH, apiHost, fileName, filtersQuery, token]);

  console.log();

  return (
    <ExportCSVProviderContext.Provider
      value={{
        exportCSV: exportCsv,
        isExporting: isExporting,
        isFiltersEmpty: isEmpty(filtersQuery),
      }}
    >
      {children}
    </ExportCSVProviderContext.Provider>
  );
};

export default connect(state => ({
  token: getUserToken(state),
}))(ExportCSVProvider);
