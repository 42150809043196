import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RemoveIcon = ({ className }) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2 3H14"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M3 3V13C3 13.5523 3.44772 14 4 14H12C12.5523 14 13 13.5523 13 13V3"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M6 3V2C6 1.44772 6.44772 1 7 1L9 1C9.55228 1 10 1.44772 10 2V3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M6 6V11"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M10 6V11"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default RemoveIcon;
