import { useMemo, useState } from 'react';
import useSearchInputActionCard from './useSearchInputActionCard';

const useSearchInput = ({
  span = 1,
  options,
  multiple = false,
  label = 'Input',
  metaData,
}) => {
  const [searchValue, setSearchValue] = useState();

  const searchInputCard = useSearchInputActionCard({
    span,
    label,
    searchValue,
    setSearchValue,
    options,
    multiple,
    metaData,
  });

  return useMemo(
    () => ({
      searchValue,
      setSearchValue,
      searchInputCard,
    }),
    [searchValue, setSearchValue, searchInputCard]
  );
};

export default useSearchInput;
