const langLabels = {
  en: 'english',
  ru: 'russian',
  uk: 'ukrainian',
  zh: 'chinese',
  ar: 'arabic',
  bg: 'bulgarian',
  de: 'german',
  es: 'spanish',
  fi: 'finnish',
  it: 'italian',
  ja: 'japanese',
  no: 'norwegian',
  pl: 'polish',
  ro: 'romanian',
  sv: 'swedish',
  tr: 'turkish',
  pt: 'portuguese',
  fr: 'french',
  vi: 'vietnamese',
  sk: 'slovak',
  ko: 'korean',
  ka: 'georgian',
  sl: 'slovenian',
  kk: 'kazakh',
  ms: 'malay',
  id: 'indonesian',
  hi: 'hindi',
  fil: 'filipino',
};

const getLangLabel = code => {
  return langLabels[code] || code;
};

export default getLangLabel;
