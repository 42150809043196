import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useRef } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack/Stack';
import ImageLibraryField from '../../pages/PromoBanner/components/BannerFields/ImageLibraryField';

const ImageCropModal = ({
  src,
  open,
  onClose,
  onChange,
  width,
  height,
  onChangeImage,
}) => {
  const cropperRef = useRef(null);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xl'}>
      <DialogTitle
        sx={{
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: 700,
          padding: 3,
          paddingBottom: 2,
        }}
      >
        Image upload
      </DialogTitle>
      <DialogContent
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
        }}
      >
        <Cropper
          style={{ width: 682, borderRadius: 4, overflow: 'hidden' }}
          aspectRatio={width / height}
          zoomable={false}
          // preview=".img-preview"
          src={src}
          viewMode={1}
          minCropBoxHeight={height}
          minCropBoxWidth={width}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={instance => {
            cropperRef.current = instance;
          }}
          guides={true}
          crop={props => {}}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: 3,
          paddingTop: 1,
        }}
      >
        <Stack
          direction={'column'}
          sx={{
            width: '100%',
          }}
        >
          <ImageLibraryField onChange={onChangeImage} />
          <Stack
            direction={'row'}
            sx={{
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Button
              disableRipple
              onClick={() => {
                const { x, y, width, height } =
                  cropperRef.current.getData(true);

                onChange({
                  start_x: x,
                  start_y: y,
                  width,
                  height,
                });

                onClose();
              }}
              variant={'contained'}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropModal;
