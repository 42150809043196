import getPromoBanner from '../../../gql/Promo/queries/getPromoBanner';
import BannerValidationSchema from '../components/BannerForm/BannerValidationSchema';
import mergeWith from 'lodash/mergeWith';
import isObject from 'lodash/isObject';
import { useMemo, useState } from 'react';
import useImperativeQuery from '../../../hooks/useImperativeQuery';

const useBannerData = id => {
  const { fetch, error } = useImperativeQuery(getPromoBanner, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const [networkError, setNetworkError] = useState(false);

  return useMemo(() => {
    return {
      data: async () => {
        const defaultValues = await BannerValidationSchema.default();
        const queryData = await fetch({
          id,
        }).catch(e => setNetworkError(e));

        return new Promise(resolve => {
          resolve(
            mergeWith({ ...defaultValues }, queryData.data.data, (a, b) =>
              b === null
                ? a
                : Array.isArray(a) &&
                  !a.some(el => isObject(el)) &&
                  Array.isArray(b) &&
                  !b.some(el => isObject(el))
                ? b // if a and b are both arrays of primitives, this will ensure that only b is taken, otherwise a and b will concat by lodash, resulting in bug
                : undefined
            )
          );
        });
      },
      error: error || networkError,
    };
  }, [id, fetch, error, networkError]);
};

export default useBannerData;
