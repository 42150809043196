import { styled } from '@mui/system';

const TabLabel = styled('span')`
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export default TabLabel;
