import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import SetPasswordForm from './components/SetPasswordForm';
import useAuth from '../../../../v2/hooks/auth';
import { useSearchParams } from 'react-router-dom';
import { decodeToken, ENDPOINT } from '../../utils';
import { isAfter, toDate } from 'date-fns';
import failureIcon from '../../assets/failure.svg';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import setPasswordMutation from '../../../../v2/gql/auth/setPassword';

const SetPasswordPage = () => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const [searchParams] = useSearchParams();

  const TITLE = success
    ? 'Password set successfully'
    : failure
    ? 'The link is expired or invalid'
    : 'Setup new password';

  const CAPTION = success
    ? 'You can now log in to your account.'
    : failure
    ? 'To get new link, contact your account manager'
    : 'Please fill out this short form carefully to create a new password.';

  const { validate } = useAuth();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      const encoded = decodeToken(token);

      if (isAfter(Date.now(), toDate(+encoded.expiredAt * 1000))) {
        setFailure(true);
      }
    } else {
      setFailure(true);
    }
  }, [searchParams]);

  const handleSubmit = (data, onSuccess, onError) => {
    const token = searchParams.get('token');
    const encoded = decodeToken(token);

    const client = new ApolloClient({
      uri: `https://${encoded.url}${ENDPOINT}/`, // TODO: remove last slash after backend nginx fix
      cache: new InMemoryCache(),
    });

    client
      .mutate({
        mutation: setPasswordMutation,
        variables: {
          email: data.email,
          token: token,
          newPassword: data.password,
        },
      })
      .then(response => {
        if (response.data) {
          setSuccess(true);
          onSuccess();
        }
      })
      .catch(error => {
        onError(error.graphQLErrors);
      });
  };

  // YWRtaW4tYXBpLWR5bi1idC0xNjU1NC11cGQuZGVudi5kZXZzcHQuY29tfDE3Mjg0ODg4MTV8MTgxODFlNjAtMWJhMC00YTQyLWJmZjItODViNDE2ZTNkNDIy

  const handleValidateToken = (data, onSuccess) => {
    validate({
      email: data.email,
      token: searchParams.get('token'),
    }).then(response => {
      if (response.data.validateInviteToken) {
        onSuccess();
      } else {
        setFailure(true);
      }
    });
  };

  return (
    <Stack gap={'24px'} justifyContent={'center'} alignItems={'center'}>
      <Stack gap={'12px'}>
        {failure && (
          <img
            src={failureIcon}
            style={{ marginBottom: '20px', height: '72px' }}
            alt=""
          />
        )}
        <Typography textAlign={'center'} fontWeight={600} fontSize={'24px'}>
          {TITLE}
        </Typography>
        <Typography
          textAlign={'center'}
          maxWidth={'320px'}
          color={'#93a3b5'}
          fontSize={'14px'}
          lineHeight={'20px'}
        >
          {CAPTION}
        </Typography>
      </Stack>
      {!failure && (
        <SetPasswordForm
          onSubmit={handleSubmit}
          onFirstStepSubmit={handleValidateToken}
        />
      )}
    </Stack>
  );
};

export default SetPasswordPage;
