import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const getConditionRules = (condition = '') => {
  let rules = [];
  let allHaveToMatch = false;
  let visible = false;

  // foo=bar || bar=foo
  if (condition.indexOf('||') !== -1) {
    rules = condition.split('||').map(rule => rule.trim());
    allHaveToMatch = false;
    visible = false;
  }
  // foo=bar && bar=foo
  else if (condition.indexOf('&&') !== -1) {
    rules = condition.split('&&').map(rule => rule.trim());
    allHaveToMatch = true;
    visible = true;
  }
  // foo=bar
  else {
    rules = [condition];
    allHaveToMatch = true;
    visible = true;
  }

  return {
    rules,
    allHaveToMatch,
    visible,
  };
};

const OperationsEnum = {
  NOT_EQUAL: '!=',
  MORE_OR_EQUAL_THAN: '>=',
  LESS_OR_EQUAL_THAN: '<=',
  EQUAL: '=',
  MORE_THAN: '>',
  LESS_THAN: '<',
  EXIST: 'EXIST',
};

const OperationFunctionMap = {
  [OperationsEnum.EQUAL]: (value = '', other = '') => {
    if (value === null) {
      value = '';
    }

    return value.toString() === other.toString();
  },
  [OperationsEnum.NOT_EQUAL]: (value = '', other = '') => {
    if (value === null) {
      value = '';
    }
    return value.toString() !== other.toString();
  },
  [OperationsEnum.MORE_THAN]: (value, other) => value > other,
  [OperationsEnum.LESS_THAN]: (value, other) => value < other,
  [OperationsEnum.MORE_OR_EQUAL_THAN]: (value, other) => value >= other,
  [OperationsEnum.LESS_OR_EQUAL_THAN]: (value, other) => value <= other,
  [OperationsEnum.EXIST]: value => !isEmpty(value),
};

export const getConditionRule = conditionRule => {
  for (let operationKey in OperationsEnum) {
    const operation = OperationsEnum[operationKey];

    if (conditionRule.indexOf(operation) !== -1) {
      let [field, value] = conditionRule.split(operation);

      if (!field || !value) continue;

      return {
        field,
        operation,
        value,
      };
    }
  }

  return {
    field: conditionRule,
    operation: OperationsEnum.EXIST,
  };
};

const evalCondition = (condition, params) => {
  const { rules, allHaveToMatch } = getConditionRules(condition);
  let matches = [];

  rules.forEach(rule => {
    const { field, value, operation } = getConditionRule(rule);
    let hasMatch = false;

    if (field) {
      const currValue = get(params, field);

      const currentValues = Array.isArray(currValue) ? currValue : [currValue];

      for (let currentValue of currentValues) {
        hasMatch =
          hasMatch || OperationFunctionMap[operation](currentValue, value);
      }
    }

    matches.push(hasMatch);
  });

  return !!matches.length && allHaveToMatch
    ? matches.every(Boolean) // foo=bar && bar=foo
    : matches.some(Boolean); // foo=bar || bar=foo
};

export default evalCondition;
