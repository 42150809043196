import { sortBy } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import DragDropList from '../../../components/DragDropList';
import DraggableSelectItem from '../../../components/DraggableSelectItem';
import { reorderDragItemsData } from '../../../components/DraggableSelectMenu';
import { getItemsForDragList } from '../../../utils/getItemsForDragList';
import { getKeySelector } from '../../../utils/getKeySelector';
import CoverageMainMarketsOption from './CoverageMainMarketsOption';
import CoverageMarketOption from './CoverageMarketOption';

export const MARKET_GROUPS = {
  _1st_half: '1st Half',
  _2nd_half: '2nd Half',
  all: 'All',
  bet_builder: 'Bet Builder',
  combo_markets: 'Combo Markets',
  correct_score: 'Correct Score',
  deliveries: 'Deliveries',
  extras: 'Extras',
  fast: 'Fast',
  games: 'Games',
  halves: 'Halves',
  handicaps: 'Handicaps',
  inning: 'Inning',
  innings: 'Innings',
  main: 'Main',
  maps: 'Maps',
  overs: 'Overs',
  period: 'Period',
  player_props: 'Player Props',
  quarters: 'Quarters',
  rounds: 'Rounds',
};

const CoverageMarketOptionComponent = ({
  option,
  selectedId,
  index,
  selectKey,
  nodePath,
  nodeIndex,
  lineType,
  isMenuAvailable = true,
  dragDropDisabled,
  configurationDisabled,
  tabKey,
  marketType,
  mainMarkets = [],
  setMainMarkets,
  measure,
}) => {
  const { getValues, setValue } = useFormContext();

  const sportId = nodePath.sportId;

  const [template, configLineType] = getValues(['template', 'configLineType']);
  const {
    preset,
    setCoverageMarketGroups,
    coverageMarketGroups: { [sportId]: coverageMarketGroups },
  } = useTreeSelectContext();

  const rootMarketsNodeSelector = withoutEmpty({
    sportId,
    market: {
      marketId: option.marketId,
    },
  });

  const rootMarketsKeySelector = getKeySelector({
    nodeSelector: rootMarketsNodeSelector,
    lineType: configLineType,
  });

  const config = template[rootMarketsKeySelector] || {};

  const groups = useMemo(() => {
    const configGroups = config.groups || [];
    const optionGroups = option.marketGroups || [];

    return coverageMarketGroups.map((value, index) => {
      const configGroup = configGroups.find(
        configValue => value === configValue.group
      );

      return {
        marketGroup: {
          marketId: option.marketId,
          name: value,
        },
        status: configGroup ? configGroup.status : optionGroups.includes(value),
        priority: index + 1,
      };
    });
  }, [
    coverageMarketGroups,
    config.groups,
    option.marketGroups,
    option.marketId,
  ]);

  const initialListItems = useMemo(
    () =>
      getItemsForDragList(
        groups,
        'marketGroup.name',
        'status',
        'marketGroup.marketId'
      ),
    [groups]
  );

  const [listItems, setListItems] = useState(initialListItems);
  const toggledMarketGroups = listItems.filter(value => value.toggled);

  const [isMenuOpen, toggleMenu] = useState(false);

  useEffect(() => {
    toggleMenu(false);
  }, [isMenuAvailable]);

  useEffect(() => {
    setListItems(initialListItems);
    measure();
    // eslint-disable-next-line
  }, [option, preset, coverageMarketGroups]);

  useEffect(() => {
    measure();
  }, [listItems, measure]);

  const isMainMarketToggled = sortedListItems => {
    return sortedListItems.some(value => value.key === 'main' && value.toggled);
  };

  const createConfigsForMainMarketList = useCallback(
    mainMarketList => {
      mainMarketList.forEach((value, index) => {
        const rootNodeSelector = {
          sportId,
          market: {
            marketId: value.marketId,
          },
        };

        const rootKeySelector = getKeySelector({
          nodeSelector: rootNodeSelector,
          lineType: configLineType,
        });

        const currentConfig = template[rootKeySelector] || {};

        template[rootKeySelector] = {
          ...currentConfig,
          lineType: configLineType,
          nodeSelector: rootNodeSelector,
          mainMarketPriority: index + 1,
        };
      });
      setMainMarkets(mainMarketList);
    },
    [template, sportId, configLineType, setMainMarkets]
  );

  const saveDragListChanges = useCallback(
    sortedListItems => {
      const currentConfig = template[rootMarketsKeySelector] || {};
      const mainMarketsTempList = structuredClone(mainMarkets);

      const mainInList = isMainMarketToggled(sortedListItems);
      const groups = sortedListItems.map(value => ({
        group: value.key,
        status: value.toggled,
      }));

      template[rootMarketsKeySelector] = {
        lineType: configLineType,
        nodeSelector: rootMarketsNodeSelector,
        priority: currentConfig.priority || option.priority,
        cashOut: currentConfig.cashOut && option.cashOut,
        status: currentConfig.status && option.status,
        groups,
      };

      //main market added
      if (
        mainInList &&
        !mainMarketsTempList.some(value => value.marketId === option.marketId)
      ) {
        mainMarketsTempList.push(option);
        createConfigsForMainMarketList(mainMarketsTempList);
      }
      //main market removed
      else if (
        !mainInList &&
        mainMarketsTempList.some(value => value.marketId === option.marketId)
      ) {
        const currentMarketIndex = mainMarketsTempList.findIndex(
          value => value.marketId === option.marketId
        );

        mainMarketsTempList.splice(currentMarketIndex, 1);
        createConfigsForMainMarketList(mainMarketsTempList);
      }
      setValue('template', template);
      measure();
    },
    [
      configLineType,
      mainMarkets,
      option,
      rootMarketsKeySelector,
      rootMarketsNodeSelector,
      setValue,
      template,
      createConfigsForMainMarketList,
      measure,
    ]
  );

  const onDragListItemToggle = (key, isToggled) => {
    const newListItems = listItems.map(value => {
      if (value.key === key) {
        value.toggled = isToggled;
      }
      return value;
    });
    const sortedListItems = sortBy(newListItems, ['priority']);
    saveDragListChanges(sortedListItems);

    setListItems(sortedListItems);
  };

  const onDragListReorder = result => {
    if (!result.destination) {
      return;
    }
    const sortedListItems = reorderDragItemsData({
      options: listItems,
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });
    saveDragListChanges(sortedListItems);

    const sortedGroupNames = sortedListItems.map(value => value.key);

    const groupsOrderKeySelector = getKeySelector({
      nodeSelector: {
        sportId,
        groups: true,
      },
      lineType: configLineType,
    });

    template[groupsOrderKeySelector] = {
      lineType: configLineType,
      nodeSelector: {
        sportId,
      },
      marketGroupPriorities: sortedGroupNames,
    };

    setValue('template', template);

    setCoverageMarketGroups(currentValue => ({
      ...currentValue,
      [sportId]: sortedGroupNames,
    }));
  };

  return (
    <Fragment>
      <DraggableSelectItem
        selected={option.marketId === selectedId}
        index={index}
        itemValue={option}
        idKey={'marketId'}
        virtualized
      >
        {tabKey === 'allMarkets' ? (
          <CoverageMarketOption
            key={selectKey + '-' + option.marketId}
            nodePath={nodePath}
            nodeIndex={nodeIndex}
            selected={option.marketId === selectedId}
            itemValue={option}
            itemIndex={index}
            lineType={lineType}
            onMenuOpen={measure}
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            listItems={toggledMarketGroups}
            tabKey={tabKey}
            marketType={marketType}
            dragDropDisabled={dragDropDisabled}
            configurationDisabled={configurationDisabled}
            marketGroupPriority={option.marketGroupPriority}
          />
        ) : (
          <CoverageMainMarketsOption
            key={selectKey + '-' + option.marketId}
            nodePath={nodePath}
            nodeIndex={nodeIndex}
            selected={option.marketId === selectedId}
            itemValue={option}
            itemIndex={index}
            lineType={lineType}
            tabKey={tabKey}
            marketType={marketType}
            dragDropDisabled={configurationDisabled || dragDropDisabled}
          />
        )}
      </DraggableSelectItem>
      {isMenuAvailable && tabKey === 'allMarkets' && !configurationDisabled && (
        <DragDropList
          onDragEnd={onDragListReorder}
          isMenuOpen={isMenuOpen}
          toggledItems={toggledMarketGroups.map(value => value.key)}
          items={listItems}
          virtualized
          onToggleItem={onDragListItemToggle}
        />
      )}
    </Fragment>
  );
};

export default CoverageMarketOptionComponent;
