import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'clsx';
import React from 'react';
import LoadingTextPlaceholder from '../../components/LoadingTextPlaceholder';
import Field from '../Field/Field/Field';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    whiteSpace: 'pre-wrap',
  },
  fullWidth: {
    width: '100%',
  },
  caption: {
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '11px',
    display: 'flex',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    color: '#8496AB',
  },
  valueContainer: {
    marginTop: 4,
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.2px',
    overflowWrap: 'anywhere',
    width: '-moz-available',
  },
});

const LabeledField = ({ field, value, loading }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root)}>
      <Typography variant="caption" className={classes.caption}>
        {field.title}
      </Typography>
      <div
        className={classes.valueContainer}
        style={{
          width: '-webkit-fill-available',
        }}
      >
        {loading ? (
          <LoadingTextPlaceholder />
        ) : (
          <Field
            labeled
            schema={field.schema}
            uiSchema={field.uiSchema}
            fieldValue={value}
            fieldKey={field.name}
          />
        )}
      </div>
    </div>
  );
};

export default LabeledField;
