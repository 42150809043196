import FormGroup from '../Form/FormGroup';
import TextField from './TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from './Autocomplete';
import { useCallback, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AddButton from '../Button/AddButton';

const initialValue = {
  country: null,
  lang: null,
};

const getKey = (country, lang) => {
  return `${country}-${lang}`;
};

const useStyles = makeStyles({
  langItem: {
    '&+$langItem': {
      marginTop: 4,
    },
  },
});

const LocaleField = ({ value = [], onChange = () => {} }) => {
  const classes = useStyles();

  const [localization, setLocalization] = useState(initialValue);

  const addLocalization = useCallback(() => {
    onChange([
      ...value,
      {
        lang: localization.lang,
        lang_subtag: localization.country,
        desc: '',
      },
    ]);

    setLocalization(initialValue);
  }, [localization, onChange, value]);

  const removeLocalization = useCallback(
    key => {
      onChange(
        value.filter(item => {
          return getKey(item.lang_subtag, item.lang) !== key;
        })
      );
    },
    [onChange, value]
  );

  const currentKey = useMemo(
    () => getKey(localization.country, localization.lang),
    [localization]
  );

  // const currentLanguage = localization.lang;

  // const countryOptions = [];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormGroup>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={4}>
              <Autocomplete
                options={[]}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value;
                }}
                getOptionLabel={option => option.name}
                InputProps={{
                  label: 'Language',
                }}
                value={localization.lang}
                onChange={(e, value) => {
                  setLocalization(current => ({
                    ...current,
                    lang: value ? value.id : null,
                  }));
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                disabled={!localization.lang}
                options={[]}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value;
                }}
                getOptionLabel={option => option.name}
                InputProps={{
                  label: 'Country',
                }}
                value={localization.country}
                onChange={(e, value) => {
                  setLocalization(current => ({
                    ...current,
                    country: value ? value.id : null,
                  }));
                }}
              />
            </Grid>

            <Grid item>
              <AddButton
                disabled={
                  !(localization.country && localization.lang) ||
                  value.find(item => {
                    const key = getKey(item.lang_subtag, item.lang);

                    console.log(key, currentKey);
                    return key === currentKey;
                  })
                }
                onClick={addLocalization}
              >
                Add Localization
              </AddButton>
            </Grid>
          </Grid>
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        {value.map(item => {
          const key = getKey(item.lang_subtag, item.lang);

          const country = item.lang_subtag;
          const language = item.lang;

          return (
            <FormGroup key={key} className={classes.langItem}>
              <TextField
                onRemove={() => {
                  removeLocalization(key);
                }}
                minRows={3}
                maxRows={3}
                maxLength={100}
                multiline
                label={
                  country && language && `${country.name} ${language.name} text`
                }
                type={'text'}
                margin={'dense'}
                variant="outlined"
                fullWidth
                value={item.desc}
                onChange={e => {
                  onChange(
                    value.map(item => {
                      if (key === getKey(item.lang_subtag, item.lang)) {
                        return {
                          ...item,
                          desc: e.target.value,
                        };
                      }
                      return item;
                    })
                  );
                }}
              />
            </FormGroup>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LocaleField;
