import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import BannerPriorityDraggable from './BannerPriorityDraggable';
import Tabs from '../../../../components/Tabs/Tabs';
import TabContent from '../../../../components/Tabs/TabContent';
import FormGroupLabel from '../../../../components/Form/FormGroupLabel';
import Stack from '@mui/material/Stack/Stack';
import FormGroup from '../../../../components/Form/FormGroup';
import getPriorities from '../../../../gql/Promo/queries/getPriorities';
import { useWatch } from 'react-hook-form';

export const TEMP_BANNER_ID = '-1';

const PlaceholderPriority = ({
  brandId,
  bannerId,
  bannerType,
  placeholder,
  banners,
  banner,
  onChangePriorities,
}) => {
  return (
    <Stack direction={'column'} spacing={2}>
      <FormGroupLabel
        helpText={
          'You can set the priority within the same banner type by using drag and drop in the section below'
        }
      >
        Select Banner Priority
      </FormGroupLabel>

      <BannerPriorityDraggable
        banner={banner}
        bannerId={bannerId}
        placeholder={placeholder}
        banners={banners}
        onChangePriority={priority => {
          onChangePriorities({
            brand_id: brandId,
            placeholder,
            items: priority.map(item => item.id),
            banner_type: bannerType,
          });
        }}
      />
    </Stack>
  );
};

const BannerPriorityList = ({ bannerId, onChangePriorities }) => {
  const values = useWatch({
    name: ['name', 'banner_type', 'brand', 'activate'],
  });

  const [name, bannerType, brand, activate] = values;

  const brandId = brand && brand.id;

  const placeholders = useWatch({ name: `${bannerType}.placeholders` });
  const state = useWatch({ name: `state` });
  const banner_view = useWatch({ name: `${bannerType}.view_type` });
  const locales = useWatch({
    name: `${bannerType}.${banner_view}_view.locales`,
  });

  const params = useMemo(
    () => ({
      input: {
        brand_id: brandId,
        banner_type: bannerType,
        placeholders,
      },
    }),
    [brandId, placeholders, bannerType]
  );

  const { data } = useQuery(getPriorities, {
    fetchPolicy: 'no-cache',
    variables: params,
    skip: !brandId || !placeholders || !placeholders.length || !bannerType,
  });

  const bannerData = useMemo(
    () => ({
      id: bannerId || TEMP_BANNER_ID,
      state,
      name,
      banner_view,
      activate,
      locales: locales && locales.map(item => item.locale),
    }),
    [activate, name, state, banner_view, bannerId, locales]
  );

  const byPlaceholder = (data && data.data && data.data.items) || [];

  if (byPlaceholder.length === 1) {
    return (
      <FormGroup large>
        <PlaceholderPriority
          brandId={brandId}
          bannerId={bannerId}
          bannerType={bannerType}
          placeholder={byPlaceholder[0].placeholder}
          banners={byPlaceholder[0].banners}
          banner={bannerData}
          onChangePriorities={onChangePriorities}
        />
      </FormGroup>
    );
  }

  return (
    <Tabs
      fullWidthTabs
      tabs={byPlaceholder.map(item => ({
        label: item.placeholder,
        content: (
          <TabContent key={item.placeholder}>
            <PlaceholderPriority
              brandId={brandId}
              bannerId={bannerId}
              bannerType={bannerType}
              placeholder={item.placeholder}
              banners={item.banners}
              banner={bannerData}
              onChangePriorities={onChangePriorities}
            />
          </TabContent>
        ),
      }))}
    />
  );
};

export default BannerPriorityList;
