import { Box } from '@mui/material';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const MenuTitle = ({ name, ...props }) => {
  const classes = useTreeSelectStyles();

  return (
    <Box className={classes.menuCaption} {...props}>
      {name}
    </Box>
  );
};

export default MenuTitle;
