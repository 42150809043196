import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithFrom from '../../../hooks/useNavigateWithFrom';
import Page from '../../components/Page/Page';
import TableActionCards from '../../components/TableActionCards/TableActionCards';
import TabLabel from '../../components/Tabs/TabLabel';
import Tabs from '../../components/Tabs/Tabs';
import useCreateActionCard from '../../hooks/useCreateActionCard';
import {
  ACCESS_ROLE,
  useSportsBookUserAccessRoles,
} from '../../providers/SportsBookUserAccessRolesProvider';
import LineSettingsTab from './LineSettings/LineSettingsTab';
import TemplatesTab from './Templates/TemplatesTab';

const LineSettingsTabContent = styled(Box)`
  background-color: #f7f7f7;
  border-radius: 4px;
`;

const LineSettingsPage = () => {
  const navigate = useNavigateWithFrom();
  const { tabKey } = useParams();
  const { roles } = useSportsBookUserAccessRoles();

  const createLineSettingsActionCard = useCreateActionCard({
    key: 'createLineSettings',
    link: '/line-settings/line/create',
    label: 'Create Line Settings',
  });
  const createTemplateActionCard = useCreateActionCard({
    key: 'createTemplate',
    link: '/line-settings/template/create',
    label: 'Create Template',
  });

  const actions = useMemo(() => {
    const initialActions = [];
    if (
      roles?.coverage === ACCESS_ROLE.WRITE &&
      roles?.sources === ACCESS_ROLE.WRITE
    ) {
      initialActions.push(createLineSettingsActionCard);
    }
    if (
      roles?.coverage === ACCESS_ROLE.WRITE ||
      roles?.sources === ACCESS_ROLE.WRITE
    ) {
      initialActions.push(createTemplateActionCard);
    }
    return initialActions;
  }, [createLineSettingsActionCard, createTemplateActionCard, roles]);

  return (
    <Page title={'Line Settings'}>
      {!isEmpty(actions) && <TableActionCards animated actions={actions} />}
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Tabs
          activeTabKey={tabKey || 'line'}
          fullWidthTabs
          withoutSpacing
          onChangeTab={tab => {
            navigate(`/line-settings/${tab}`);
          }}
          tabs={[
            {
              key: 'line',
              label: <TabLabel>Line Settings</TabLabel>,
              content: (
                <LineSettingsTabContent key={'line'}>
                  <LineSettingsTab roles={roles} />
                </LineSettingsTabContent>
              ),
            },
            {
              key: 'template',
              label: <TabLabel>Templates</TabLabel>,
              content: (
                <LineSettingsTabContent key={'template'}>
                  <TemplatesTab roles={roles} />
                </LineSettingsTabContent>
              ),
            },
          ]}
        />
      </Box>
    </Page>
  );
};

export default LineSettingsPage;
