import React, { useMemo } from 'react';
import TransferListSkeletonGroup from './TransferListSkeletonGroup';
import TransferListGroup from './TransferListGroup';
import groupBy from 'lodash/groupBy';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  list: {},
  placeholderList: {
    position: 'absolute',
  },
});

const TransferListGroups = ({
  isEmpty,
  items,
  filteredItems,
  groupKey,
  emptyLabel,
  searchQuery,
  loading,
  setToggled,
  setGroupToggled,
  valueKey,
  labelKey,
  descKey,
  infoKey,
}) => {
  const classes = useStyles();
  const groups = useMemo(() => groupBy(items, groupKey), [items, groupKey]);

  const filteredGroups = useMemo(
    () => groupBy(filteredItems, groupKey),
    [filteredItems, groupKey]
  );

  const displayGroups = searchQuery ? filteredGroups : groups;

  return loading ? (
    <div className={classes.placeholderList}>
      {[1, 2, 3, 5, 6, 7, 8, 9, 10].map(item => {
        return <TransferListSkeletonGroup key={item} />;
      })}
    </div>
  ) : (
    <div className={classes.list}>
      {isEmpty
        ? emptyLabel
        : Object.keys(displayGroups).map(group => {
            return (
              <TransferListGroup
                searchQuery={searchQuery}
                setToggled={setToggled}
                setGroupToggled={setGroupToggled}
                valueKey={valueKey}
                labelKey={labelKey}
                descKey={descKey}
                infoKey={infoKey}
                key={group}
                group={group}
                groupItemsFiltered={displayGroups[group]}
                groupItems={groups[group]}
              />
            );
          })}
    </div>
  );
};

export default TransferListGroups;
