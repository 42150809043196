import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MenuActiveItemIndicator from './MenuActiveItemIndicator';
import { isUrlValid } from '../../../utils/schemaHelper';

const useStyles = makeStyles(theme => ({
  navigationMenuItem: {
    all: 'unset',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.2px',
    color: theme.palette.text.main,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 8,
    cursor: 'pointer',
    padding: '0 8px',
    boxSizing: 'border-box',
    fontWeight: 500,

    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#E8F2FF',
    },
  },
  navigationMenuItemActive: {
    color: theme.palette.primary.main,
  },

  badge: {
    borderRadius: 10,
    height: 12,
    fontWeight: 'bold',
    fontSize: '8px',
    lineHeight: '10px',
    letterSpacing: '0.5px',
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginLeft: 8,
  },
  badgeGreen: {
    backgroundColor: '#08CA6D',
    color: '#fff',
  },
}));

const NavigationMenuItem = memo(
  forwardRef(
    (
      {
        href,
        initialQuery,
        label,
        isActive,
        children,
        onClick,
        groupIndex,
        itemIndex,
        badge,
      },
      ref
    ) => {
      const classes = useStyles();

      const handleClick = useCallback(
        e => {
          onClick(e, groupIndex, itemIndex);
        },
        [onClick, groupIndex, itemIndex]
      );

      const isAbsolutePath = isUrlValid(href);
      const LinkComponent = isAbsolutePath ? 'a' : Link;
      const linkPathProps = isAbsolutePath
        ? {
            href,
            target: '_blank',
          }
        : {
            to: {
              pathname: href,
              search: initialQuery,
            },

            state: { state: { formMenu: true } },
          };

      return (
        <LinkComponent
          {...linkPathProps}
          onClick={handleClick}
          ref={ref}
          className={clsx(classes.navigationMenuItem, {
            [classes.navigationMenuItemActive]: isActive,
          })}
        >
          {label}

          {badge && (
            <div
              className={clsx(classes.badge, {
                [classes.badgeGreen]: badge.color === 'green',
              })}
            >
              {badge.label}
            </div>
          )}

          <MenuActiveItemIndicator hidden={!isActive} />
        </LinkComponent>
      );
    }
  )
);

export default NavigationMenuItem;
