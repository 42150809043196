import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ArrowRight = props => {
  return (
    <SvgIcon viewBox={'0 0 6 14'} {...props}>
      <path
        fill={'none'}
        d="M1 13L7 7L1 0.999999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ArrowRight;
