import { makeStyles } from '@mui/styles';

export default makeStyles({
  container: {
    width: '100%',
    justifyContent: 'flex-end',
    padding: 0,
    marginTop: '24px',
  },
});
