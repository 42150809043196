import React, { lazy, Suspense } from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import GlobalLoading from '../../components/GlobalLoading';

const InnerMarkdown = lazy(() => import('./InnerMarkdown'));

const Markdown = props => (
  <ErrorBoundary>
    <Suspense fallback={<GlobalLoading />}>
      <InnerMarkdown {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default Markdown;
