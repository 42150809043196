import mapValues from 'lodash/mapValues';
import Form from '../containers/Form';
import CardWithIcon from './CardWithIcon';
import Markdown from './Markdown';
import Charts from './Charts';
import IFrame from './IFrame';
import withDataAndError from './withDataAndError';
import DataTable from './Table/DataTable';
import DataFieldList from './FieldList/DataFieldList';
import Card from './Card/Card';
import DataCardContainer from '../uiContainers/CardContainer/DataCardContainer';
import PavoForm from '../containers/PavoForm/PavoForm';
import FiltersWithPresets from './Filters/FiltersWithPresets';
import RDPClient from './RDPClient';
import TwitchPlayer from './TwitchPlayer/TwitchPlayer';
import DataECharts from './ECharts/DataECharts';

const uiComponentsWithData = {
  ...mapValues(
    {
      CardWithIcon,
      Markdown,
      Charts,
      IFrame,
      RDPClient,
      TwitchPlayer,
    },
    withDataAndError
  ),
  CardContainer: DataCardContainer,
  Form,
  // FormV2,
  PavoForm,
  StaticFieldList: DataFieldList,
  ECharts: DataECharts,
  Table: DataTable,
  Filters: FiltersWithPresets,
};

export const staticComponents = {
  Card,
};

export default uiComponentsWithData;
