import FormGroup from '../../components/Form/FormGroup';
import Grid from '@mui/material/Grid';
import FormGroupLabel from '../../components/Form/FormGroupLabel';
import OperatorSelect from '../../components/Inputs/OperatorSelect/OperatorSelect';
import BrandSelect from '../../components/Inputs/BrandSelect/BrandSelect';
import TemplateSelect from '../../components/Inputs/TemplateSelect/TemplateSelect';
import TextField from '../../components/Inputs/TextField';
import RMSettingsSelect from '../../components/Inputs/RMSettingsSelect/RMSettingsSelect';
import Button from '../../components/Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormField from '../../components/Form/FormField';
import Box from '@mui/material/Box';

const schema = yup.object().shape({
  risk_management_id: yup.array().of(yup.string().required()),
  operator_id: yup.array().of(yup.string()),
  brand_id: yup.array().of(yup.string()),
  template_id: yup.string().required(),
  live_template_id: yup.string().required(),
  live_delay_k: yup.number().required(),
});

const defaultValues = async () => ({
  risk_management_id: null,
  operator_id: [],
  brand_id: [],
  template_id: null,
  live_template_id: null,
  live_delay_k: '',
});

const TournamentRiskRulesForm = ({
  formMessage,
  loading,
  disabled,
  onSubmit,
  selected,
}) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
    watch,
  } = methods;

  const operatorIds = watch('operator_id');

  return (
    <>
      <FormProvider {...methods}>
        <Box mt={1} px={0.25}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormGroup largeTop>
              <Grid container spacing={2} direction={'column'}>
                <Grid item xs={12}>
                  <FormGroupLabel
                    helpText={
                      'You can select multiple tournaments from the table below and click Add Rule to create rules for multiple tournaments. To edit the existing tournament, please expand the row.'
                    }
                  >
                    Add Risk Management Rules
                  </FormGroupLabel>
                </Grid>
                <Grid item xs={12} width={'100%'}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormField name={'risk_management_id'}>
                        <RMSettingsSelect
                          multiple
                          label={'Global RM Settings'}
                          disabled={loading}
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormField name={'operator_id'}>
                        <OperatorSelect disabled={loading} multiple />
                      </FormField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormField name={'brand_id'}>
                        <BrandSelect
                          multiple
                          withOperator
                          operatorIds={operatorIds}
                          disabled={loading}
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormField name={'template_id'}>
                        <TemplateSelect label={'Template'} disabled={loading} />
                      </FormField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormField name={'live_template_id'}>
                        <TemplateSelect
                          label={'Live Template'}
                          disabled={loading}
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormField name={'live_delay_k'}>
                        <TextField
                          label={'Live Delay S'}
                          type={'number'}
                          disabled={loading}
                        />
                      </FormField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      {formMessage
                        ? formMessage
                        : selected
                        ? `${selected} Tournaments Selected`
                        : 'Select Tournaments to apply Risk Management Rules'}
                    </Grid>

                    <Grid item>
                      <Button
                        disabled={loading || disabled || !isValid}
                        color={'primary'}
                        variant={'contained'}
                        type={'submit'}
                      >
                        Add Rule
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </form>
        </Box>
      </FormProvider>
    </>
  );
};

export default TournamentRiskRulesForm;
