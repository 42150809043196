import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { animated } from 'react-spring';

const useStyles = makeStyles({
  actionCard: {
    background: '#fff',
    borderRadius: 4,
  },
  actionCardContent: {
    padding: 16,
    overflow: 'hidden',
    minHeight: 56,
    boxSizing: 'border-box',
  },
  inlineCard: {
    marginTop: 0,
    borderRadius: '0 0 4px 4px',
  },

  largeCard: {
    padding: '25px 25px 25px 16px',
  },
});

const TableActionCard = forwardRef(
  ({ style, children, inline = false, large = false }, ref) => {
    const classes = useStyles();

    return (
      <animated.div
        className={clsx(classes.actionCard, inline && classes.inlineCard)}
        ref={ref}
        style={style}
      >
        <div
          className={clsx(
            classes.actionCardContent,
            large && classes.largeCard
          )}
        >
          {children}
        </div>
      </animated.div>
    );
  }
);

export default TableActionCard;
