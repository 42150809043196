import useAutocomplete from '@mui/material/useAutocomplete';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const useFancyAutocomplete = ({
  id,
  value,
  onChange,
  label,
  options,
  valueKey = 'value',
  labelKey = 'label',
  multiple,
  groupBy,
  isOptionSelected,
  onChangeSearch = () => {},
  getInputPlaceholder,
  filterOptions,
  inModal = false,
}) => {
  const anchorEl = useRef(null);

  const [searchInputValue, setSearchInputValue] = useState('');

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    popupOpen,
  } = useAutocomplete({
    value,
    inputValue: searchInputValue,
    label,
    disableCloseOnSelect: multiple,
    onChange: (e, value) => {
      if (!multiple && value) {
        setSearchInputValue(value[labelKey]);
      }
      onChange(value);
    },
    id,
    options,
    multiple,
    openOnFocus: true,
    getOptionLabel: option => option[labelKey],
    includeInputInList: true,
    clearOnBlur: true,
    blurOnSelect: !multiple,
    clearIcon: null,
    isOptionEqualToValue: (option, value) => {
      return !!option && !!value && option[valueKey] === value[valueKey];
    },
    groupBy,
    filterOptions,
  });

  useEffect(() => {
    if (!multiple && value === null) {
      setSearchInputValue('');
    }
  }, [multiple, value]);

  const inputProps = useMemo(() => {
    return getInputProps();
  }, [getInputProps]);

  const onSearchInputChange = useCallback(
    e => {
      const value = e ? e.target.value : '';
      onChangeSearch(value);
      setSearchInputValue(value);
      if (!value && !multiple) {
        onChange(null);
      }
    },
    [onChange, onChangeSearch, multiple]
  );

  const innerGetInputPlaceholder = useCallback(() => {
    const defaultPlaceholder = multiple
      ? value.map(item => item[labelKey]).join(', ')
      : value
      ? value[labelKey]
      : '';

    return getInputPlaceholder
      ? getInputPlaceholder(defaultPlaceholder)
      : defaultPlaceholder;
  }, [getInputPlaceholder, value, labelKey, multiple]);

  const inputPlaceholder = useMemo(
    () => (focused ? inputProps.value : innerGetInputPlaceholder()),
    [innerGetInputPlaceholder, inputProps.value, focused]
  );

  const isOptionSelectedCb = useCallback(
    option => {
      return isOptionSelected
        ? isOptionSelected(option)
        : multiple
        ? !!value.find(item => item[valueKey] === option[valueKey])
        : value[valueKey] === option[valueKey];
    },
    [value, valueKey, isOptionSelected, multiple]
  );

  const popperProps = useMemo(
    () => ({
      id,
      open: popupOpen,
      anchorEl: anchorEl.current,
      style: {
        width:
          anchorEl.current && anchorEl.current.getBoundingClientRect().width,
        zIndex: inModal ? 1300 : 2,
      },
    }),
    [id, popupOpen, inModal]
  );

  return {
    anchorEl,
    getOptionProps,
    searchInputValue,
    onSearchInputChange,
    inputPlaceholder,
    inputProps,
    popupOpen,
    groupedOptions,
    focused,
    isOptionSelectedCb,
    getRootProps,
    getListboxProps,
    popperProps,
  };
};

export default useFancyAutocomplete;
