import { gql } from '@apollo/client';

const TOURNAMENTS_UPDATE_CONFIG = gql`
  mutation UpdateConfig($ids: [ID!]!, $input: ConfigInput!) {
    data: updateConfig(ids: $ids, input: $input) {
      success
      errors {
        reason
      }
    }
  }
`;

export default TOURNAMENTS_UPDATE_CONFIG;
