import React, { useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Button from '../../../../components/Button/Button';
import ExpandIcon from '../../../../../icons/controls/ExpandIcon';
import Checkbox from '../../../../components/Inputs/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { EVENT_STATUSES_OPTIONS } from '../../constants';

const useStyles = makeStyles({
  statusFilterButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
  },
  statusCircle: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  activeCircle: {
    marginLeft: 8,
  },
  expandIcon: {
    marginLeft: 8,
  },
  statusFilterPopper: {
    boxShadow: '0px 2px 16px 0px rgba(132, 150, 171, 0.25)',
    zIndex: 1000,
  },
  statusesList: {
    background: '#fff',
    borderRadius: 4,
    padding: '8px 0',
  },
  statusOption: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
  },
  statusOptionLabel: {
    flex: 1,
    marginLeft: 16,
    marginRight: 12,
  },
});

const TradingToolStatusFilter = ({ selectedStatuses = [], onSelectStatus }) => {
  const classes = useStyles();
  const [isOpened, setOpened] = useState(false);
  const anchorElRef = useRef(null);

  const renderStatusCircle = (color, backgroundImage) => (
    <div
      className={classes.statusCircle}
      style={
        backgroundImage
          ? {
              backgroundImage,
              backgroundSize: 'contain',
            }
          : {
              background: color,
            }
      }
    />
  );

  const renderActiveCircles = () =>
    selectedStatuses.map(status => {
      const option = EVENT_STATUSES_OPTIONS[status];

      return option ? (
        <div className={classes.activeCircle} key={option.value}>
          {renderStatusCircle(option.color, option.backgroundImage)}
        </div>
      ) : null;
    });

  const renderOption = option => (
    <Box className={classes.statusOption} key={option.value}>
      {renderStatusCircle(option.color, option.backgroundImage)}
      <span className={classes.statusOptionLabel}>{option.label}</span>
      <Checkbox
        value={selectedStatuses.includes(option.value)}
        onChange={v => onSelectStatus(option.value, v)}
      />
    </Box>
  );

  return (
    <Box ref={anchorElRef}>
      <Button
        color={'lightBlue'}
        className={classes.statusFilterButton}
        onClick={() => setOpened(!isOpened)}
      >
        {`Filter status${selectedStatuses.filter(s => !!s).length ? ':' : ''}`}
        {renderActiveCircles()}
        <ExpandIcon className={classes.expandIcon} expanded={isOpened} />
      </Button>
      <Popper
        anchorEl={anchorElRef.current}
        open={isOpened}
        placement={'bottom-start'}
        className={classes.statusFilterPopper}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <ClickAwayListener onClickAway={() => setOpened(false)}>
          <Box className={classes.statusesList}>
            {Object.values(EVENT_STATUSES_OPTIONS).map(option =>
              renderOption(option)
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
export default TradingToolStatusFilter;
