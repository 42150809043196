import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import React from 'react';
import CardContent from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export function ObjectFieldTemplate(props) {
  return (
    <CardContent style={{ paddingBottom: 0 }}>
      <div style={{ margin: 20 }}>
        <Typography variant="h5">
          {upperFirst(lowerCase(props.title))}
        </Typography>

        <Typography>{props.description}</Typography>

        {props.properties.map(el => {
          return [el.content];
        })}
      </div>
    </CardContent>
  );
}
