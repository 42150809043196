const routes = [
  {
    path: '/bonuses/create',
    // element: <BonusCreatePage />,
  },
  {
    path: '/tournaments',
    // element: (
    //   <ApolloClientProvider
    //     defaultEndpoint={'rm'}
    //     endpoint={{
    //       rm: '/api/v2/risk-management',
    //     }}
    //     allowSubscription={false}
    //     key={'tournaments'}
    //   >
    //     <PresetsProvider
    //       operationId={'RiskManagementAdmin.tournaments_graphql_placeholder'}
    //     >
    //       <TournamentsPage />
    //     </PresetsProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/related-contingencies/:id',
    // element: (
    //   <ApolloClientProvider
    //     endpoint={{
    //       rm: '/api/v2/risk-management',
    //     }}
    //     key={'related-contingency'}
    //     defaultEndpoint={'rm'}
    //   >
    //     <RelatedContingencyPage />
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/related-contingencies',
    // element: (
    //   <ApolloClientProvider
    //     endpoint={{
    //       rm: '/api/v2/risk-management',
    //     }}
    //     defaultEndpoint={'rm'}
    //     key={'related-contingencies'}
    //   >
    //     <PresetsProvider
    //       operationId={'RiskManagementAdmin.related_contingency_placeholder'}
    //     >
    //       <RelatedContingenciesPage />
    //     </PresetsProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/betstream',
    // element: (
    //   <ApolloClientProvider
    //     defaultEndpoint={'betstream'}
    //     endpoint={{
    //       betstream: '/api/v1/betstream-graphql',
    //       rm: '/api/v2/risk-management',
    //     }}
    //     key={'betstream'}
    //   >
    //     <PresetsProvider operationId={'BetSlipsAdmin.bet_stream_list'}>
    //       <OperatorProvider>
    //         <BetstreamPage />
    //       </OperatorProvider>
    //     </PresetsProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/banners/create',
    // element: (
    //   <ApolloClientProvider
    //     defaultEndpoint={'promo'}
    //     endpoint={{
    //       promo: '/api/v2/promo',
    //       rm: '/api/v2/risk-management',
    //     }}
    //     allowSubscription={false}
    //   >
    //     <OperatorProvider>
    //       <PromoBannerCreatePage />
    //     </OperatorProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/banners/duplicate/:bannerId',
    // element: (
    //   <ApolloClientProvider
    //     defaultEndpoint={'promo'}
    //     endpoint={{
    //       promo: '/api/v2/promo',
    //       rm: '/api/v2/risk-management',
    //     }}
    //     allowSubscription={false}
    //   >
    //     <OperatorProvider>
    //       <PromoBannerDuplicatePage />
    //     </OperatorProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/banners',
    // element: (
    //   <ApolloClientProvider
    //     defaultEndpoint={'promo'}
    //     endpoint={{
    //       promo: '/api/v2/promo',
    //       rm: '/api/v2/risk-management',
    //     }}
    //     allowSubscription={false}
    //   >
    //     <OperatorProvider>
    //       <PresetsProvider operationId={'PromoAdmin.banners_placeholder'}>
    //         <PromoBannersListPage />
    //       </PresetsProvider>
    //     </OperatorProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/banners/:bannerId',
    // element: (
    //   <ApolloClientProvider
    //     defaultEndpoint={'promo'}
    //     endpoint={{
    //       promo: '/api/v2/promo',
    //       rm: '/api/v2/risk-management',
    //     }}
    //     allowSubscription={false}
    //   >
    //     <OperatorProvider>
    //       <PromoBannerEditPage />
    //     </OperatorProvider>
    //   </ApolloClientProvider>
    // ),
  },
  {
    path: '/outcomes/:eventId',
    // element: (
    //   <ApolloClientProvider
    //     endpoint={'/api/v2/settlements-admin'}
    //     key={'outcomes'}
    //   >
    //     <PresetsProvider operationId={'SettlementsAdmin.get_event_outcomes'}>
    //       <OutcomesPage />
    //     </PresetsProvider>
    //   </ApolloClientProvider>
    // ),
  },
];

export default routes;
