import React from 'react';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import Chart from '../../components/Chart';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';
import ActionCards from '../../components/ActionCards';

const styles = theme => ({
  root: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
});

const Charts = ({
  schema: { title, description },
  uiSchema,
  data,
  path,
  classes,
}) => {
  return (
    <Paper className={classes.root}>
      <ActionCards path={path} />

      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography>
      {get(uiSchema, 'ui:options.charts').map(({ type, ...config }, i) => (
        <Chart key={i} type={type} config={config} data={data && data.items} />
      ))}
    </Paper>
  );
};

export default withStyles(styles)(Charts);
