import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  market: {
    fontSize: 12,
    fontWeight: 600,
  },
  odds: {
    fontSize: 14,
    fontWeight: 600,
  },
  competitor: {
    fontSize: 15,
    fontWeight: 600,
  },
  event: {
    fontSize: 12,
    fontWeight: 600,
  },
  time: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const LineBannerAutoEventsPreview = () => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '12px',
        overflow: 'hidden',
        fontFamily: 'IBM Plex Sans',
        width: '360px',
        height: '152px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <svg
        width={360}
        height={152}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path fill="url(#a)" d="M0 0h360v152H0z" />
        <path fill="url(#b)" d="M0 0h360v152H0z" />
        <rect
          x={8}
          y={112}
          width={109.333}
          height={32}
          rx={6}
          fill="#fff"
          fillOpacity={0.3}
        />
        <text
          x="16"
          y="129"
          fill="#fff"
          textAnchor="start"
          dominantBaseline="middle"
          className={classes.market}
        >
          1
        </text>
        <text
          x="108"
          y="129"
          fill="#fff"
          textAnchor="end"
          dominantBaseline="middle"
          className={classes.odds}
        >
          odds
        </text>
        <rect
          x={125.333}
          y={112}
          width={109.333}
          height={32}
          rx={6}
          fill="#fff"
          fillOpacity={0.3}
        />
        <text
          x="132"
          y="129"
          fill="#fff"
          textAnchor="start"
          dominantBaseline="middle"
          className={classes.market}
        >
          X
        </text>
        <text
          x="226"
          y="129"
          fill="#fff"
          textAnchor="end"
          dominantBaseline="middle"
          className={classes.odds}
        >
          odds
        </text>
        <rect
          x={242.667}
          y={112}
          width={109.333}
          height={32}
          rx={6}
          fill="#fff"
          fillOpacity={0.3}
        />
        <text
          x="252"
          y="129"
          fill="#fff"
          textAnchor="start"
          dominantBaseline="middle"
          className={classes.market}
        >
          2
        </text>
        <text
          x="344"
          y="129"
          fill="#fff"
          textAnchor="end"
          dominantBaseline="middle"
          className={classes.odds}
        >
          odds
        </text>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.52 18.69a5.118 5.118 0 0 0 2.151.523l1.266-1.45a5.154 5.154 0 0 0-.575-2.456l-2.502-.583a5.145 5.145 0 0 0-1.185 1.848l.845 2.118Zm-.132.412-.846.705a5.124 5.124 0 0 0 4.21 1.281l-1.084-1.446a5.545 5.545 0 0 1-2.28-.54Zm-2.52-2.779h1.444a5.577 5.577 0 0 1 1.296-1.953v-1.559a5.128 5.128 0 0 0-1.535-.133 5.122 5.122 0 0 0-1.206 3.645Zm8.588 1.351a5.152 5.152 0 0 0 1.057-1.573l-.683-2.33a5.109 5.109 0 0 0-1.866-.536l-1.162 1.993c.348.713.54 1.494.562 2.289l2.092.157Zm.248.358v1.535a5.125 5.125 0 0 0 1.427-3.21l-.244-.042a5.581 5.581 0 0 1-1.183 1.717ZM20 12.766a5.14 5.14 0 0 0-3.062-1.824c-.173.203-.33.418-.469.644l1.405 1.212c.694.046 1.364.22 1.984.51l.142-.542Zm-3.957-1.303c.12-.204.252-.4.397-.587a5.125 5.125 0 0 0-3.948 1.364 5.55 5.55 0 0 1 1.198.15.217.217 0 0 1 .056-.032l2.297-.895ZM16 22a6 6 0 1 1 0-12 6 6 0 0 1 0 12Z"
          fill="#fff"
          fillOpacity={0.5}
        />
        <text
          x="28"
          y="17"
          opacity={0.7}
          fill="#fff"
          textAnchor="start"
          dominantBaseline="middle"
          className={classes.event}
        >
          Event
        </text>
        <text
          x="352"
          y="17"
          opacity={0.7}
          fill="#fff"
          textAnchor="end"
          dominantBaseline="middle"
          className={classes.time}
        >
          Starting time
        </text>
        <path fill="url(#c)" d="M8 32h32v32H8z" />
        <text
          x="8"
          y="90"
          fill="#fff"
          textAnchor="start"
          dominantBaseline="middle"
          className={classes.competitor}
        >
          First competitor
        </text>
        <path fill="url(#d)" d="M320 32h32v32h-32z" />
        <text
          x="350"
          y="90"
          fill="#fff"
          textAnchor="end"
          dominantBaseline="middle"
          className={classes.competitor}
        >
          Second competitor
        </text>
        <defs>
          <pattern
            id="a"
            patternContentUnits="objectBoundingBox"
            width={1}
            height={1}
          >
            <use
              xlinkHref="#image0_12293_11035"
              transform="matrix(.00091 0 0 .00216 -.157 0)"
            />
          </pattern>
          <pattern
            id="c"
            patternContentUnits="objectBoundingBox"
            width={1}
            height={1}
          >
            <use
              xlinkHref="#image1_12293_11035"
              transform="matrix(.00142 0 0 .00142 .074 0)"
            />
          </pattern>
          <pattern
            id="d"
            patternContentUnits="objectBoundingBox"
            width={1}
            height={1}
          >
            <use xlinkHref="#image2_12293_11035" transform="scale(.00167)" />
          </pattern>
          <linearGradient
            id="b"
            x1={180}
            y1={0}
            x2={180}
            y2={152}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity={0.4} />
            <stop offset={1} stopOpacity={0.8} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default LineBannerAutoEventsPreview;
