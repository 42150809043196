import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormGroup from '../../../../../components/Form/FormGroup';
import BannerImageUploader from '../../BannerImageUploader/BannerImageUploader';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import Stack from '@mui/material/Stack/Stack';
import FormGroupLabel from '../../../../../components/Form/FormGroupLabel';
import Grid from '@mui/material/Grid';
import FormField from '../../../../../components/Form/FormField';
import PopularNowTournamentPreview from '../../BannerImagePreview/PopularNowTournamentPreview';
import FancyTournamentAutocomplete from '../../../../../components/Inputs/TournamentAutocomplete/FancyTournamentAutocomplete';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  previewClassName: {
    borderRadius: 12,
  },
}));

const TournamentView = ({ prefix }) => {
  const classes = useStyles();
  const { watch } = useFormContext();
  const brand = watch('brand');

  return (
    <Stack direction={'column'} spacing={2}>
      <ImagePreviewProvider>
        <LocaleTabs
          name={`${prefix}.locales`}
          content={(localeField, localeIndex) => (
            <Fragment
              key={localeField.locale.country + localeField.locale.lang}
            >
              <FormGroup large>
                <Stack direction={'column'} spacing={2}>
                  <FormGroupLabel>Add Tournament</FormGroupLabel>
                  <Grid container>
                    <Grid item xs={4}>
                      <FormField
                        name={`${prefix}.locales.${localeIndex}.tournament`}
                      >
                        <FancyTournamentAutocomplete
                          checkValueOptions={false}
                        />
                      </FormField>
                    </Grid>
                  </Grid>
                </Stack>
              </FormGroup>
              {brand && (
                <FormGroup large>
                  <FormField
                    name={`${prefix}.locales.${localeIndex}.images.desktop`}
                  >
                    <BannerImageUploader
                      preview={PopularNowTournamentPreview}
                      cropSize={{
                        width: 128,
                        height: 88,
                      }}
                      useGradient
                      previewClassName={classes.previewClassName}
                    />
                  </FormField>
                </FormGroup>
              )}
            </Fragment>
          )}
        />
      </ImagePreviewProvider>
    </Stack>
  );
};

export default TournamentView;
