import { makeStyles } from '@mui/styles';

export default makeStyles({
  sidebar: {
    flex: '0 0 300px',
    background: '#fff',
    height: 'calc(100vh - 48px)',
    overflowY: 'hidden',
    position: 'sticky',
    top: 48,
  },
  textField: {
    width: '100%',
  },
  body: {
    height: '100%',
    padding: '16px',
    overflowY: 'scroll',
    margin: 0,
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  filter: {},
  filterLabel: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#000',
    letterSpacing: '0.2px',
  },
  filterItems: {},
  filterItem: {
    alignItems: 'center',
    flexBasis: '126px',
    justifyContent: 'flex-start',
  },
  checkboxRoot: {
    '&.MuiCheckbox-root': {
      padding: '0',
      paddingRight: '4px',
    },
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      color: '#031224',
      fontWeight: 500,
    },
  },
  filterItemTooltip: {
    flex: 1,
  },
  filterItemLabel: {
    fontSize: '12px',
    color: '#031224',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: 6,
  },
  filterItemCaption: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#8496AB',
    letterSpacing: '0.2px',
  },
  footer: {
    borderTop: '1px solid rgba(132, 150, 171, 0.20)',
    padding: '16px',
  },
  datepicker: {
    '& .MuiInputBase-input': {
      fontSize: '12.5px',
      letterSpacing: '-1px',
    },
  },
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-root': {
      padding: '3px',
    },
  },
});
