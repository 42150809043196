import { isEqual, pickBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { initialPreset } from '../../../../../../../providers/TreeSelectProvider';
import {
  ACCESS_ROLE,
  useSportsBookUserAccessRoles,
} from '../../../../../../providers/SportsBookUserAccessRolesProvider';
import { getKeySelector, pathPredicate } from '../../../utils/getKeySelector';

export const useSourcesTemplateSelect = ({
  options = [],
  setFormValue = () => {},
  getFormValues = () => {},
}) => {
  const [displayOptions, setDisplayOptions] = useState(options);

  const { roles } = useSportsBookUserAccessRoles();
  const isSourcesWriter = roles?.sources === ACCESS_ROLE.WRITE;
  const isPresetsAvailable =
    roles?.coverage === ACCESS_ROLE.WRITE &&
    roles?.sources === ACCESS_ROLE.WRITE;

  //resolve received presets to array of config (in template form change format)
  const applySourcesPresets = useCallback(
    ({ preset, setPresetApplied, setPreset, pathToSelect = {} }) => {
      if (!isEqual(preset, initialPreset)) {
        const [lineType, switchedFrom, template] = getFormValues([
          'configLineType',
          'switchedFrom',
          'template',
        ]);
        const commands = preset.commands;
        const config = commands.reduce((acc, value) => {
          preset.itemIds.forEach(id => {
            //in case preset mast to be applied for each selected item in apply modal
            //then we creates selector to markets for all chosen items
            const nodeSelector = pickBy(
              {
                ...pathToSelect,
                [preset.idKey]:
                  (value.nodeSelector.market && id) ||
                  value.nodeSelector[preset.idKey],
                market: {
                  marketId:
                    value.nodeSelector.market &&
                    value.nodeSelector.market.marketId,
                },
              },
              pathPredicate
            );
            const key = getKeySelector({ nodeSelector, lineType });
            const currentProviderConfig = acc[key];
            if (!template[key]) {
              const command = {
                enabled: value.enabled,
                lineType,
                nodeSelector,
                prematchSameAsLiveFrom: switchedFrom,
                priority: value.priority,
                provider: value.provider,
              };
              acc[key] = currentProviderConfig
                ? [...currentProviderConfig, command]
                : [command];
            }
          });
          return acc;
        }, {});

        setFormValue('template', { ...template, ...config });
        setPresetApplied(true);
        setPreset(initialPreset);
      }
    },
    [getFormValues, setFormValue]
  );

  const onSearchFieldChange = useCallback(
    e => {
      const inputValue = e.target.value.toLowerCase();

      setDisplayOptions(
        options.filter(
          option => option && option.name.toLowerCase().includes(inputValue)
        )
      );
    },
    [setDisplayOptions, options]
  );

  return useMemo(
    () => ({
      displayOptions,
      isSourcesWriter,
      isPresetsAvailable,
      setDisplayOptions,
      onSearchFieldChange,
      applySourcesPresets,
    }),
    [
      displayOptions,
      isSourcesWriter,
      isPresetsAvailable,
      setDisplayOptions,
      onSearchFieldChange,
      applySourcesPresets,
    ]
  );
};
