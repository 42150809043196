import { gql } from '@apollo/client';
import marginLadderFragment from '../fragments/marginLadderFragment';

const addMarginLadderPairMutation = gql`
  ${marginLadderFragment}
  mutation addMarginLadderPair($ladderId: LadderId!, $pair: MarginPairInput!) {
    addMarginLadderPair(ladder_id: $ladderId, pair: $pair) {
      ...MarginLadderFragment
    }
  }
`;

export default addMarginLadderPairMutation;
