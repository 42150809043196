import { createContext, useContext, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import DescriptionField from '../../../components/DynamicForm/fields/DescriptionField';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

export const IdSchemaContext = createContext({});

export const useRootIdSchema = () => {
  return useContext(IdSchemaContext);
};

const ObjectFieldTemplate = ({ children, title, description, idSchema }) => {
  const classes = useStyles();
  let rootIdSchema = idSchema.$id === 'root' ? idSchema : null;

  return (
    <Fragment>
      {(title || description) && (
        <div className={classes.header}>
          {title && <Typography variant="h6">{title}</Typography>}
          {description && <DescriptionField description={description} />}
          {(title || description) && <Divider />}
        </div>
      )}
      {rootIdSchema ? (
        <IdSchemaContext.Provider value={rootIdSchema}>
          {children}
        </IdSchemaContext.Provider>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default ObjectFieldTemplate;
