import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const useNavigateWithFrom = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (to, options = {}) =>
      navigate(to, {
        ...options,
        state: {
          from: location.state && location.state.from,
        },
      }),
    [location, navigate]
  );
};

export default useNavigateWithFrom;
