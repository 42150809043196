import { useLazyQuery, useMutation } from '@apollo/client';
import loginQuery from '../../gql/auth/login';
import refreshTokenQuery from '../../gql/auth/refreshToken';
import validateInviteTokenQuery from '../../gql/auth/validateInviteToken';
import setPasswordMutation from '../../gql/auth/setPassword';
import forgotPasswordMutation from '../../gql/auth/forgotPassword';
import changePasswordMutation from '../../gql/auth/changePassword';

const useAuth = () => {
  const [fetchLogin, { loading: logging }] = useMutation(loginQuery);

  const [fetchRefresh, { loading: refreshing }] =
    useMutation(refreshTokenQuery);
  const [fetchValidate, { loading: validating }] = useLazyQuery(
    validateInviteTokenQuery
  );

  const [passwordSet, { loading: settingNewPassword }] =
    useMutation(setPasswordMutation);

  const [passwordChange, { loading: changingPassword }] = useMutation(
    changePasswordMutation
  );

  const [passwordForgot, { loading: recoveringPassword }] = useMutation(
    forgotPasswordMutation
  );

  const login = variables => {
    return fetchLogin({ variables });
  };

  const refresh = variables => {
    return fetchRefresh({ variables });
  };

  const validate = variables => {
    return fetchValidate({ variables });
  };

  const setPassword = variables => {
    return passwordSet({ variables });
  };

  const changePassword = variables => {
    return passwordChange({ variables });
  };

  const forgotPassword = variables => {
    return passwordForgot({ variables });
  };

  return {
    login,
    refresh,
    validate,
    setPassword,
    changePassword,
    forgotPassword,
    loading:
      logging ||
      refreshing ||
      validating ||
      settingNewPassword ||
      changingPassword ||
      recoveringPassword,
  };
};

export default useAuth;
