import ApolloClientProvider from '../providers/ApolloClientProvider';
import { useSubscription, gql } from '@apollo/client';
import { useState } from 'react';

const Subscriptions = ({ subscription, variables = {} }) => {
  useSubscription(gql(subscription), {
    variables,
  });
  return null;
};

const GraphqlDebugger = () => {
  const [url, setUrl] = useState('');
  const [subscription, setSubscription] = useState(`
    subscription MySubscription {
      BetStreamModel(input: {}) {
        bet_id
      }
    }
  `);

  const [sent, setSent] = useState(false);

  if (sent) {
    return (
      <div>
        <ApolloClientProvider endpointOverride={url}>
          <Subscriptions subscription={subscription} />
        </ApolloClientProvider>
      </div>
    );
  }

  return (
    <div>
      <input
        placeholder={'Endpoint'}
        style={{
          width: 500,
        }}
        value={url}
        onChange={e => {
          setUrl(e.target.value);
        }}
      />
      <br />
      <br />
      <textarea
        rows={10}
        style={{
          width: 500,
        }}
        value={subscription}
        onChange={e => {
          setSubscription(e.target.value);
        }}
      />
      <br />
      <br />
      <button
        onClick={() => {
          setSent(true);
        }}
      >
        Send
      </button>
    </div>
  );
};

export default GraphqlDebugger;
