import { createAction } from 'redux-actions';

export const toggleDrawer = createAction('DRAWER_TOGGLED');

export const resetTheme = createAction('RESET_THEME');

export const updateTheme = createAction('UPDATE_THEME');

export const addNotification = createAction('ADD_NOTIFICATION');

export const removeNotification = createAction('REMOVE_NOTIFICATION');

export const showNotification = (type, text) => dispatch => {
  dispatch(addNotification({ type, text }));
  setTimeout(() => dispatch(removeNotification()), 3000);
};
