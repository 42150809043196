import CoverageTab from '../../LineSettings/Coverage/CoverageTab';
import MarginTab from '../../LineSettings/Margin/MarginTab';
import SourcesTab from '../../LineSettings/Sources/SourcesTab';

const BrandTemplate = ({ templateId, type }) => {
  if (type === 'coverage') {
    return <CoverageTab templateId={templateId} showActions={false} />;
  } else if (type === 'sources') {
    return <SourcesTab templateId={templateId} showActions={false} />;
  } else {
    return <MarginTab templateId={templateId} showActions={false} />;
  }
};

export default BrandTemplate;
