import qs from 'qs';

const getQuery = search => {
  return (
    JSON.parse(
      (qs.parse(search, { ignoreQueryPrefix: true }) || {}).jsonQuery || '{}'
    ) || {}
  );
};

export default getQuery;
