import React, { useState, useRef, useCallback } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Checkbox from '../../../../../components/Inputs/Checkbox';
import FormField from '../../../../../components/Form/FormField';
import NumberField from '../../../../../components/Inputs/NumberField';
import Button from '../../../../../components/Button/Button';
import MoreVertIcon from '../../../../../../icons/controls/MoreVertIcon';
import toNumber from 'lodash/toNumber';
import CheckMarkIcon from '../../../../../icons/CheckMarkIcon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const useStyles = makeStyles({
  tradingEvenSpecifierCard: {
    border: '1px #BEC6D1 solid',
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
  },
  specifierCardEnabled: {
    border: '1px #1581FF solid',
  },
  specifierCardBorderless: {
    border: 'none',
    padding: 0,
  },
  specifierCardCheckbox: {
    width: 14,
    height: 14,
  },
  specifierOutcomes: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    rowGap: 8,
  },
  specifierOutcome: {
    width: 120,
    marginLeft: 8,
    position: 'relative',
  },
  specifierOutcomeButton: {
    borderRadius: 2,
    padding: 0,
    position: 'absolute',
    minWidth: 'unset',
    width: 24,
    height: 24,
    right: 24,
    top: 8,
  },
  specifierActionsButton: {
    marginLeft: 8,
    fontSize: '10px',
    cursor: 'pointer',
  },
  checkMarkIcon: {
    backgroundImage: `url(${CheckMarkIcon})`,
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },
  optionsPopper: {
    zIndex: 1000,
  },
  optionsList: {
    borderRadius: 8,
  },
  removeButton: {
    color: '#FF000F',
    padding: 12,
    cursor: 'pointer',
  },
  outcomeInputLabel: {
    pointerEvents: 'all',
    '&:hover': {
      background: 'white',
      whiteSpace: 'normal',
    },
  },
});

const TradingEventSpecifierCard = ({
  form,
  marketId,
  specifierName,
  isEventUntradable,
  outcomes,
  markets,
  getOutcomeName,
  specifier,
  isOutcomeDirty,
  onToggleSpecifier,
  onUpdateOutcome,
  onRemoveSpecifier,
  isShowCheckbox,
  isShowDeleteButton,
  marginLadderValues,
  className,
}) => {
  const classes = useStyles();
  const { handleSubmit, getValues } = form;

  const anchorElRef = useRef(null);
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const handleClickAway = useCallback(() => {
    setOptionsOpen(false);
  }, []);

  const specifiersObject = getValues(
    `markets.${marketId}.outcomes.${specifierName}`
  );
  const isSpecifierEnabled =
    typeof specifiersObject === 'object' &&
    Object.values(specifiersObject).some(({ b }) => b === 0);

  return (
    <div
      key={specifier}
      className={clsx(classes.tradingEvenSpecifierCard, className, {
        [classes.specifierCardEnabled]: isSpecifierEnabled,
        [classes.specifierCardBorderless]: !isShowCheckbox,
      })}
    >
      {isShowCheckbox && (
        <Checkbox
          value={isSpecifierEnabled}
          onChange={e => {
            handleSubmit(data =>
              onToggleSpecifier(
                data,
                marketId,
                specifierName,
                !isSpecifierEnabled
              )
            )(e);
          }}
          disabled={isEventUntradable}
          className={classes.specifierCardCheckbox}
        />
      )}
      <div className={classes.specifierOutcomes}>
        {Object.keys(outcomes).map(outcomeId => {
          const marketFieldName = `markets.${marketId}`;
          const outcomeFieldName = `${marketFieldName}.outcomes.${specifierName}.${outcomeId}`;

          if (!markets?.[marketId]?.outcomes) {
            return;
          }

          const outcomeValue =
            markets[marketId].outcomes[specifierName]?.[outcomeId];

          if (!outcomeValue) return;

          const outcomeName = getOutcomeName({
            marketId,
            specifier,
            outcomeId,
          });

          const isShowConfirmButton =
            isOutcomeDirty(outcomeFieldName) && toNumber(outcomeValue.k) >= 1;

          const currentK = getValues(`${outcomeFieldName}.k`);
          const allLadderValues = marginLadderValues.reduce((acc, item) => {
            return [...acc, item.low, item.high];
          }, []);
          const allSteps = [...new Set(allLadderValues)].sort();

          const nextStepValue = allSteps.find(step => {
            return Number(step) > Number(currentK);
          });
          const prevStepValue = allSteps.reverse().find(step => {
            return Number(step) < Number(currentK);
          });

          return (
            <div key={outcomeId} className={classes.specifierOutcome}>
              <FormField name={`${outcomeFieldName}.k`}>
                <NumberField
                  label={outcomeName}
                  step={0.1}
                  nextStepValue={nextStepValue}
                  prevStepValue={prevStepValue}
                  min={1}
                  useStringValue
                  disabled={isEventUntradable}
                  InputLabelProps={{
                    style: {
                      overflow: 'hidden',
                    },
                    classes: {
                      root: classes.outcomeInputLabel,
                    },
                  }}
                />
              </FormField>
              {isShowConfirmButton && (
                <Button
                  type={'submit'}
                  color={'primary'}
                  onClick={e => {
                    handleSubmit(data =>
                      onUpdateOutcome(
                        data,
                        marketFieldName,
                        outcomeFieldName,
                        marketId,
                        outcomeId,
                        specifierName
                      )
                    )(e);
                  }}
                  className={classes.specifierOutcomeButton}
                >
                  <div className={classes.checkMarkIcon} />
                </Button>
              )}
            </div>
          );
        })}
      </div>
      {isShowDeleteButton && (
        <>
          <div ref={anchorElRef}>
            <MoreVertIcon
              className={classes.specifierActionsButton}
              onClick={() => setOptionsOpen(!isOptionsOpen)}
            />
          </div>
          <Popper
            className={classes.optionsPopper}
            open={isOptionsOpen}
            anchorEl={anchorElRef.current}
            placement={'right-end'}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            }}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className={classes.optionsList} elevation={2}>
                <div
                  onClick={() => {
                    onRemoveSpecifier(marketId, specifier);
                    setOptionsOpen(false);
                  }}
                  className={classes.removeButton}
                >
                  Remove
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      )}
    </div>
  );
};

export default TradingEventSpecifierCard;
