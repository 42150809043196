import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';
import SelectMenu from './SelectMenu';

const VirtualizedMenu = ({
  rowCount,
  itemRender,
  containerStyle,
  scrollToIndex,
}) => {
  const classes = useTreeSelectStyles();
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 86,
  });

  return (
    <SelectMenu style={containerStyle} autoSize>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cache}
            scrollToIndex={scrollToIndex}
            rowHeight={cache.rowHeight}
            rowRenderer={({ index, key, style, parent }) => {
              return (
                <CellMeasurer
                  key={index}
                  cache={cache}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                  style={style}
                >
                  {({ registerChild, measure }) => {
                    return (
                      <div
                        ref={registerChild}
                        style={{ ...style, width: '100% ' }}
                      >
                        {itemRender({ measure, index })}
                      </div>
                    );
                  }}
                </CellMeasurer>
              );
            }}
            rowCount={rowCount}
            className={classes.virtualizedMenu}
          />
        )}
      </AutoSizer>
    </SelectMenu>
  );
};

export default VirtualizedMenu;
