import React, { useCallback, useContext } from 'react';
import UiComponentSchemaProvider, {
  useUiComponentSchema,
} from '../../providers/UiComponentSchemaProvider';
import { SchemaContext } from '../../providers/SchemaProvider';
import Form from 'react-jsonschema-form';
import ObjectFieldTemplate from './templates/ObjectFieldTemplate';
import extendedWidgets from './widgets/widgets';
import fields from './fields/fields';
import FieldTemplate from './templates/FieldTemplate';
import ArrayFieldTemplateContainer from './templates/ArrayFieldTemplateContainer';
import widgets from '../../components/DynamicForm/widgets/index';
import applyRules from 'react-jsonschema-form-conditionals';
import Engine from 'json-rules-engine-simplified';
import { useUpdate } from '../../uiComponents/withUpdate';
import { useParams } from 'react-router-dom';

const PavoForm = ({ path, ...props }) => {
  const uiSchema = useUiComponentSchema();
  const schema = useContext(SchemaContext);

  const rules = [
    // {
    //   conditions: {
    //     'bonus_type.type': {
    //       is: 'Freebet',
    //     },
    //   },
    //   event: {
    //     type: 'remove',
    //     params: {
    //       field: ['multipliers', 'bonus_type.comboboost_type'],
    //     },
    //   },
    // },
    // {
    //   conditions: {
    //     'bonus_type.type': {
    //       is: 'Comboboost',
    //     },
    //   },
    //   event: {
    //     type: 'remove',
    //     params: {
    //       field: [
    //         'fb_base',
    //         'fb_wager',
    //         'fb_period',
    //         'fb_event_scheduled',
    //         'bonus_type.freebet_type',
    //       ],
    //     },
    //   },
    // },
  ];

  const params = useParams();
  const { updateData } = useUpdate(path, params);

  const ConditionalForm = applyRules(schema, uiSchema, rules, Engine)(Form);

  const onChange = useCallback(() => {}, []);

  const onSubmit = useCallback(
    async ({ formData }) => {
      await updateData(formData);
    },
    [updateData]
  );

  return (
    <UiComponentSchemaProvider value={uiSchema}>
      <ConditionalForm
        widgets={{
          ...widgets,
          ...extendedWidgets,
        }}
        fields={fields}
        schema={schema}
        uiSchema={uiSchema}
        ArrayFieldTemplate={ArrayFieldTemplateContainer}
        FieldTemplate={FieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        onChange={onChange}
        onSubmit={onSubmit}
      >
        {null}
      </ConditionalForm>
    </UiComponentSchemaProvider>
  );
};

export default PavoForm;
