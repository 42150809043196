import StringField from '../../components/Fields/StringField/StringField';
import React, { useCallback, useEffect, useMemo } from 'react';
import RMSettingsSelect from '../../components/Inputs/RMSettingsSelect/RMSettingsSelect';
import TemplateSelect from '../../components/Inputs/TemplateSelect/TemplateSelect';
import TextField from '../../components/Inputs/TextField';
import InlineFormField from '../../components/Form/InlineFormField';
import * as yup from 'yup';
import TOURNAMENT_RULES_QUERY from '../../gql/queries/TOURNAMENT_RULES_QUERY';
import updateTournamentRule from '../../gql/mutations/updateTournamentRule';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumns from '../../hooks/useTableColumns';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import FancyTable from '../../components/Table/FancyTable';
import ErrorWidget from '../../components/ErrorWidget';

const commonCellStyle = {
  padding: '0px 16px',
};

export const COLUMN = {
  ID: 'id',
  OPERATOR_NAME: 'operator_name',
  BRAND_NAME: 'brand_name',
  RISK_MANAGEMENT_ID: 'risk_management_id',
  TEMPLATE_ID: 'template_id',
  LIVE_TEMPLATE_ID: 'live_template_id',
  LIVE_DELAY_K: 'live_delay_k',
};

export const columnsWidthConfig = {
  [COLUMN.ID]: 0.7,
  [COLUMN.OPERATOR_NAME]: 0.6,
  [COLUMN.BRAND_NAME]: 0.6,
  [COLUMN.RISK_MANAGEMENT_ID]: 1,
  [COLUMN.TEMPLATE_ID]: 0.5,
  [COLUMN.LIVE_TEMPLATE_ID]: 0.5,
  [COLUMN.LIVE_DELAY_K]: 0.5,
};

export const columnsOrderConfig = [
  COLUMN.ID,
  COLUMN.OPERATOR_NAME,
  COLUMN.BRAND_NAME,
  COLUMN.RISK_MANAGEMENT_ID,
  COLUMN.TEMPLATE_ID,
  COLUMN.LIVE_TEMPLATE_ID,
  COLUMN.LIVE_DELAY_K,
];

export const toggledColumns = [
  COLUMN.ID,
  COLUMN.OPERATOR_NAME,
  COLUMN.BRAND_NAME,
  COLUMN.RISK_MANAGEMENT_ID,
  COLUMN.TEMPLATE_ID,
  COLUMN.LIVE_TEMPLATE_ID,
  COLUMN.LIVE_DELAY_K,
];

const validationSchema = yup.object().shape({
  [COLUMN.RISK_MANAGEMENT_ID]: yup.string().required(),
  [COLUMN.TEMPLATE_ID]: yup.string().required(),
  [COLUMN.LIVE_TEMPLATE_ID]: yup.string().required(),
  [COLUMN.LIVE_DELAY_K]: yup.number().required(),
});

const TournamentsConfigTable = ({
  tournamentId,
  onHeightChanged,
  onExpand,
  isInitialExpanded = false,
}) => {
  const queryVariables = useMemo(() => {
    return {
      id: tournamentId,
    };
  }, [tournamentId]);

  const client = useApolloClient();

  const cached = useMemo(
    () =>
      client.readQuery({
        query: TOURNAMENT_RULES_QUERY,
        variables: {
          input: queryVariables,
        },
      }),
    [client, queryVariables]
  );

  const {
    loading,
    error,
    data = {},
  } = useQuery(TOURNAMENT_RULES_QUERY, {
    variables: {
      input: queryVariables,
    },
  });

  const { data: { items = (cached && cached.data.items) || [] } = {} } = data;

  useEffect(() => {
    if (!loading && !cached) {
      onHeightChanged && onHeightChanged();
    }
    // eslint-disable-next-line
  }, [loading, cached]);

  const [update, { error: updateError }] = useMutation(updateTournamentRule, {
    update(cache, { data: { updateTournamentRule } }) {
      cache.writeQuery({
        query: TOURNAMENT_RULES_QUERY,
        data: {
          data: updateTournamentRule,
        },
        variables: {
          input: queryVariables,
        },
      });
    },
  });

  const onUpdate = useCallback(
    (id, name, value) => {
      return update({
        variables: {
          id: id,
          input: {
            [name]: value,
          },
        },
      });
    },
    [update]
  );

  const onItemCollapse = useCallback(() => {
    onHeightChanged && onHeightChanged();
    onExpand && onExpand(tournamentId);
  }, [onHeightChanged, onExpand, tournamentId]);

  const { template } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrderConfig,
    toggledColumns,
    rowActions: false,
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'ID',
        key: COLUMN.ID,
        style: commonCellStyle,
        render: ({ id }) => {
          return <StringField>{id}</StringField>;
        },
      },
      {
        label: 'Operator',
        key: COLUMN.OPERATOR_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.OPERATOR_NAME]}</StringField>;
        },
      },
      {
        label: 'Brand',
        key: COLUMN.BRAND_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.BRAND_NAME]}</StringField>;
        },
      },
      {
        label: 'Global RM Settings',
        key: COLUMN.RISK_MANAGEMENT_ID,
        style: commonCellStyle,
        render: () => {
          return (
            <InlineFormField name={COLUMN.RISK_MANAGEMENT_ID}>
              <RMSettingsSelect size={'small'} label={null} />
            </InlineFormField>
          );
        },
      },
      {
        label: 'Template K',
        key: COLUMN.TEMPLATE_ID,
        style: commonCellStyle,
        render: () => {
          return (
            <InlineFormField name={COLUMN.TEMPLATE_ID}>
              <TemplateSelect size={'small'} label={null} />
            </InlineFormField>
          );
        },
      },
      {
        label: 'Live Template K',
        key: COLUMN.LIVE_TEMPLATE_ID,
        style: commonCellStyle,
        render: () => {
          return (
            <InlineFormField name={COLUMN.LIVE_TEMPLATE_ID}>
              <TemplateSelect size={'small'} label={null} />
            </InlineFormField>
          );
        },
      },
      {
        label: 'Live Delay S',
        key: COLUMN.LIVE_DELAY_K,
        style: commonCellStyle,
        render: () => {
          return (
            <InlineFormField name={COLUMN.LIVE_DELAY_K}>
              <TextField label={null} type={'number'} size={'small'} />
            </InlineFormField>
          );
        },
      },
    ],
    order: columnsOrderConfig,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    inlineForm: true,
    inlineFormProps: {
      schema: validationSchema,
      onChange: onUpdate,
    },
  });

  if (error || updateError) {
    return <ErrorWidget />;
  }

  return (
    <FancyTable
      id={COLUMN.ID}
      loading={loading && !cached}
      renderHeaderRow={
        <TableHeaderRow
          sticky={false}
          configMenu={false}
          toggledColumns={toggledColumns}
          template={template}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
        />
      }
      renderRow={renderRow}
      data={items}
      isCollapsible={true}
      collapseLimit={2}
      onCollapse={onItemCollapse}
      isInitialExpanded={isInitialExpanded}
    />
  );
};

export default TournamentsConfigTable;
