import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LinkField from '../../../components/Fields/LinkField/LinkField';

const useStyles = makeStyles({
  parentLinkContainer: {
    display: 'flex',
    gap: '6px',
    fontSize: '12px',
    alignItems: 'center',
  },
});

const TemplateParentLink = ({ name, id }) => {
  const classes = useStyles();

  return (
    <Box className={classes.parentLinkContainer}>
      Parent template:
      <LinkField
        href={`/line-settings/template/edit/${id}`}
        bold={false}
        extraLink
      >
        {name}
      </LinkField>
    </Box>
  );
};

export default TemplateParentLink;
