import { forwardRef } from 'react';
import useAutocompleteData from '../../../hooks/useAutocompleteData';
import Select from '../Select/Select';

const CurrencySelect = forwardRef(({ multiple, ...props }, ref) => {
  const { data: options = [] } = useAutocompleteData({
    url: '/api/v1/ReporterAPI/autocomplete/clickhouse?method=currency',
    withoutParams: true,
  });

  return (
    <Select
      ref={ref}
      label={'Currency'}
      multiple={multiple}
      options={options}
      labelKey={'string_value'}
      valueKey={'string_id'}
      {...props}
    />
  );
});

export default CurrencySelect;
