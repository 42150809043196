import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const addMarketSpecifierMutation = gql`
  ${eventModelFragment}
  mutation addMarketSpecifier(
    $eventId: EventId!
    $marketId: MarketId!
    $specifier: Specifiers!
    $version: Version!
  ) {
    addMarketSpecifier(
      event_id: $eventId
      market_id: $marketId
      specifier: $specifier
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;

export default addMarketSpecifierMutation;
