import { Box } from '@mui/material';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const SelectContainer = ({ children, ...props }) => {
  const classes = useTreeSelectStyles();

  return (
    <Box className={classes.treeSelectElement} sx={props.style} {...props}>
      {children}
    </Box>
  );
};

export default SelectContainer;
