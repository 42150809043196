import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { QrCode2 } from '@mui/icons-material';
import errorIcon from '../../../assets/error.svg';

const TwoFactorForm = ({ onSubmit, hash }) => {
  const [code, setCode] = useState(Array(6).fill(''));
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Automatically focus the next input if a number is entered
      if (value && index < 5) {
        document.getElementById(`code-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const newCode = [...code];
      newCode[index] = ''; // Clear the current input
      setCode(newCode);

      // Move focus to the previous input if Backspace is pressed and the current input is empty
      if (index > 0 && e.key === 'Backspace' && !code[index]) {
        document.getElementById(`code-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = e => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    if (/^\d{1,6}$/.test(pasteData)) {
      const newCode = pasteData
        .split('')
        .concat(Array(6 - pasteData.length).fill(''));
      setCode(newCode);
    }
  };

  useEffect(() => {
    if (code.every(digit => digit !== '')) {
      const enteredCode = code.join('');
      const correctCode = '123456';

      if (enteredCode?.length === correctCode.length) {
        if (enteredCode !== correctCode) {
          setError(true);
        } else {
          setLoading(true);
          onSubmit(enteredCode);
        }
      } else {
        setError(false);
      }
    }
  }, [code, onSubmit]);

  return (
    <Stack gap={'24px'}>
      <Stack alignItems={'center'}>
        <QrCode2
          sx={{
            fontSize: '190px',
          }}
        />
        <Typography
          fontSize={'14px'}
          color={'#8496AB'}
          lineHeight={'20px'}
          letterSpacing={'0.2px'}
        >
          Scan the QR code
        </Typography>
      </Stack>
      <Stack gap={'12px'} alignItems={'center'}>
        <Stack gap={'6px'} direction={'row'} onPaste={handlePaste}>
          {code.map((num, index) => (
            <TextField
              disabled={isLoading}
              key={index}
              id={`code-${index}`}
              value={num}
              onChange={e => handleChange(e, index)}
              onKeyDown={e => handleKeyDown(e, index)}
              inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              sx={{ width: 40, fontSize: '24px' }}
            />
          ))}
        </Stack>
        {error ? (
          <Stack
            direction={'row'}
            gap={'8px'}
            padding={'12px'}
            sx={{
              backgroundColor: '#FFE6E8',
              borderRadius: '8px',
            }}
          >
            <img src={errorIcon} />
            <Typography
              fontSize={'14px'}
              color={'#820008'}
              lineHeight={'20px'}
              letterSpacing={'0.2px'}
            >
              The code insert is incorrect. Try again!
            </Typography>
          </Stack>
        ) : (
          <Typography
            fontSize={'14px'}
            color={'#8496AB'}
            lineHeight={'20px'}
            letterSpacing={'0.2px'}
          >
            Enter authorizing code{' '}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default TwoFactorForm;
