import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  sortIcon: {
    cursor: 'pointer',
    width: 14,
    height: 14,
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("/icons/SortIcon.svg")',
    backgroundPosition: 'center',
    backgroundColor: '#f8f4f4',
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed',
  },
  collapsed: {
    position: 'absolute',
    right: 8,
    boxShadow: '-10px 0px 30px 15px #f8f4f4, 0px 0px 20px #f8f4f4',
  },
});

const SortIcon = memo(
  forwardRef(({ up, disabled, className, style, ...props }, ref) => {
    const classes = useStyles();
    const divRef = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { rotate } = useSpring({
      rotate: up ? 180 : 0,
      config: config.gentle,
    });

    useEffect(() => {
      const observer = new ResizeObserver(entries => {
        const { width } = entries[0].contentRect;
        if (width < 14) {
          setIsCollapsed(true);
        }
      });

      observer.observe(divRef.current);
    }, []);

    return (
      <animated.div
        ref={divRef}
        className={clsx(
          classes.sortIcon,
          isCollapsed && classes.collapsed,
          className,
          {
            [classes.disabled]: disabled,
          }
        )}
        style={{
          ...style,
          transform: rotate.to(deg => `rotateZ(${deg}deg)`),
        }}
        {...props}
      />
    );
  })
);

export default SortIcon;
