import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid/Grid';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormGroup from '../../../components/Form/FormGroup';
import FormField from '../../../components/Form/FormField';
import ArrowRightSmall from '../../../../icons/controls/ArrowRightSmall';
import DeleteLadderButton from './DeleteLadderButton';
import TextField from '../../../components/Inputs/TextField';
import NumberField from '../../../components/Inputs/NumberField';
import FancyAutocomplete from '../../../components/Inputs/FancyAutocomplete/FancyAutocomplete';
import MarginLadderValidationSchema from './MarginLadderValidationSchema';
import SPORTS_AUTOCOMPLETE_QUERY from '../../../gql/TradingTool/queries/SPORTS_AUTOCOMPLETE_QUERY';
import { useMutation } from '@apollo/client';
import createMarginLadderMutation from '../../../gql/TradingTool/mutations/createMarginLadderMutation';
import updateMarginLadderMutation from '../../../gql/TradingTool/mutations/updateMarginLadderMutation';
import addMarginLadderPairMutation from '../../../gql/TradingTool/mutations/addMarginLadderPairMutation';
import deleteMarginLadderPairMutation from '../../../gql/TradingTool/mutations/deleteMarginLadderPairMutation';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import { useToasts } from '../../../providers/ToastsProvider';

const useStyles = makeStyles({
  topPanel: {
    background: '#fff',
    borderRadius: 4,
    padding: 12,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: '12px 18px',
    display: 'flex',
    alignItems: 'center',
    color: '#1581FF',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
  },
  backButtonArrow: {
    width: 12,
    height: 12,
    transform: 'rotate(180deg)',
    marginRight: 8,
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    marginLeft: 4,
  },
  formGroupLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  coefInputLabel: {
    pointerEvents: 'all',
    '&:hover': {
      background: 'white',
      whiteSpace: 'normal',
    },
  },
  separator: {
    backgroundColor: '#8496AB',
    height: 1,
    width: '100%',
  },
  pairsRow: {
    display: 'flex',
  },
  firstColSpace: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  pairsColumnLabel: {
    fontWeight: 600,
    fontSize: 14,
    textAlign: 'center',
  },
  deletePairButton: {
    marginRight: 16,
  },
});

const MarginLadderEditForm = ({ ladderId, defaultValues }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const form = useForm({
    resolver: yupResolver(MarginLadderValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    resetOptions: {
      keepDirtyValues: false,
    },
  });

  const { handleSubmit, watch, setValue } = form;

  const sport = watch('sport');
  const low = watch('low');
  const high = watch('high');
  const pairs = watch('pairs');

  const [createMarginLadder] = useMutation(createMarginLadderMutation);
  const [updateMarginLadder] = useMutation(updateMarginLadderMutation);
  const [addMarginLadderPair, { loading: addMarginLadderPairLoading }] =
    useMutation(addMarginLadderPairMutation);
  const [deleteMarginLadderPair, { loading: deleteMarginLadderLoading }] =
    useMutation(deleteMarginLadderPairMutation);

  const isPairsButtonDisabled =
    addMarginLadderPairLoading || deleteMarginLadderLoading;

  const onBackButtonClick = useCallback(() => {
    navigate(`/trading-margin-ladders`);
  }, [navigate]);

  const onCreate = useCallback(
    async ({ data }) => {
      try {
        const res = await createMarginLadder({
          variables: {
            ladder: {
              name: data.name,
              sport_id: data.sport?.id,
            },
          },
        });

        if (res.data?.createMarginLadder?.id) {
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS}>
              {`Ladder was successfully created`}
            </NotificationAlert>
          );
          navigate(
            `/trading-margin-ladders/edit/${res.data.createMarginLadder.id}`
          );
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on ladder saving: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [createMarginLadder, navigate, showToast]
  );

  const onEdit = useCallback(
    async ({ data }) => {
      try {
        const res = await updateMarginLadder({
          variables: {
            ladderId,
            name: data.name,
            sportId: data.sport?.id,
          },
        });

        if (res.data?.updateMarginLadder?.id) {
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS}>
              {`Ladder was successfully updated`}
            </NotificationAlert>
          );
          navigate(`/trading-margin-ladders`);
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on ladder updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [updateMarginLadder, ladderId, navigate, showToast]
  );

  const onAddPair = useCallback(
    async ({ data }) => {
      try {
        const res = await addMarginLadderPair({
          variables: {
            ladderId,
            pair: {
              low: data.low,
              high: data.high,
            },
          },
        });

        if (res.data?.addMarginLadderPair?.id) {
          setValue('pairs', res.data.addMarginLadderPair.items, {
            shouldDirty: false,
            shouldTouch: false,
          });
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS}>
              {`Pair was successfully added`}
            </NotificationAlert>
          );
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on pair adding: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [addMarginLadderPair, ladderId, showToast, setValue]
  );

  const onDeletePair = useCallback(
    async ({ data, index }) => {
      try {
        const res = await deleteMarginLadderPair({
          variables: {
            ladderId,
            pair: {
              low: data.pairs[index].low,
              high: data.pairs[index].high,
            },
          },
        });

        if (res.data?.deleteMarginLadderPair?.id) {
          setValue('pairs', res.data.deleteMarginLadderPair.items, {
            shouldDirty: false,
            shouldTouch: false,
          });
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS}>
              {`Pair was successfully deleted`}
            </NotificationAlert>
          );
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on pair deleting: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [deleteMarginLadderPair, ladderId, showToast, setValue]
  );

  return (
    <form noValidate>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.topPanel}>
              <Box className={classes.backButton} onClick={onBackButtonClick}>
                <ArrowRightSmall className={classes.backButtonArrow} />
                Back
              </Box>
              <Box className={classes.actionButtons}>
                <Button
                  onClick={onBackButtonClick}
                  color="secondary"
                  disableRipple
                  variant={'contained'}
                >
                  Cancel
                </Button>
                <Button
                  type={'submit'}
                  onClick={e => {
                    handleSubmit(
                      ladderId
                        ? data => onEdit({ data })
                        : data => onCreate({ data })
                    )(e);
                  }}
                  color="primary"
                  disableRipple
                  variant={'contained'}
                  className={classes.saveButton}
                >
                  {ladderId ? 'Save' : 'Create'}
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormGroup large>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.formGroupLabel}>
                    Ladder settings
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <FormField name={'name'}>
                    <TextField label={'Name'} />
                  </FormField>
                </Grid>

                <Grid item xs={6}>
                  <FormField name={'sport'}>
                    <FancyAutocomplete
                      gqlQuery={SPORTS_AUTOCOMPLETE_QUERY}
                      label={sport?.id ? 'Sport' : 'All sports'}
                      valueKey="id"
                      labelKey="name"
                      multiple={false}
                      checkValueOptions={false}
                      alwaysShowClearButton
                    />
                  </FormField>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>

          {!!ladderId && (
            <Grid item xs={12}>
              <FormGroup large>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.formGroupLabel}>
                      Coefficients
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <FormField name={'low'}>
                      <NumberField
                        label={'Low'}
                        step={0.1}
                        min={1}
                        useStringValue
                        disabled={!ladderId}
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={6}>
                    <FormField name={'high'}>
                      <NumberField
                        label={'High'}
                        step={0.1}
                        min={1}
                        useStringValue
                        disabled={!ladderId}
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type={'submit'}
                      onClick={e => {
                        handleSubmit(data => onAddPair({ data }))(e);
                      }}
                      color="primary"
                      disableRipple
                      disabled={
                        !ladderId || isPairsButtonDisabled || !low || !high
                      }
                      variant={'contained'}
                      className={classes.saveButton}
                    >
                      Add
                    </Button>
                  </Grid>

                  {pairs && !!pairs.length && (
                    <>
                      <Grid item xs={12}>
                        <div className={classes.separator} />
                      </Grid>

                      <Grid item xs={12} className={classes.pairsRow}>
                        <div className={classes.firstColSpace} />

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={classes.pairsColumnLabel}>
                              Low
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.pairsColumnLabel}>
                              High
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {pairs &&
                    pairs.map((pair, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          className={classes.pairsRow}
                          key={`pair-${index}`}
                        >
                          <DeleteLadderButton
                            type={'submit'}
                            onClick={e => {
                              handleSubmit(data =>
                                onDeletePair({ data, index })
                              )(e);
                            }}
                            disabled={isPairsButtonDisabled}
                            className={classes.deletePairButton}
                          />

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormField name={`pairs.${index}.low`}>
                                <NumberField
                                  label={'Low'}
                                  step={0.1}
                                  min={1}
                                  useStringValue
                                  disabled={true}
                                />
                              </FormField>
                            </Grid>
                            <Grid item xs={6}>
                              <FormField name={`pairs.${index}.high`}>
                                <NumberField
                                  label={'High'}
                                  step={0.1}
                                  min={1}
                                  useStringValue
                                  disabled={true}
                                />
                              </FormField>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </FormGroup>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </form>
  );
};

export default MarginLadderEditForm;
