import { gql } from '@apollo/client';

const getDescriptionEventMarkets = gql`
  query getDescriptionEventMarkets($id: EventId!) {
    getDescriptionEventMarkets(id: $id) {
      id
      name
      specifiers
      variants
      support_math_model
      dynamic_outcomes
      outcome_type
    }
  }
`;

export default getDescriptionEventMarkets;
