import clsx from 'clsx';
import { memo, useRef } from 'react';
import { useIntersection } from 'react-use';

const TableStickyHeader = ({ style, children }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '-49px 0px 0px 0px',
    threshold: 1,
  });

  return (
    <div
      ref={intersectionRef}
      className={clsx('tableHeaderRow', {
        tableStickyHeader: intersection && intersection.intersectionRatio < 1,
      })}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default memo(TableStickyHeader);
