import { gql } from '@apollo/client';

const getBoostedOddsMarkets = gql`
  query getBoostedOddsMarkets($brandId: BrandId!, $eventId: EventId!) {
    data: getBoostedOddsMarkets(brand_id: $brandId, event_id: $eventId) {
      id
      name
      specifiers
    }
  }
`;

export default getBoostedOddsMarkets;
