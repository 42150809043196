import { gql } from '@apollo/client';

const getFailedRequestsList = gql`
  query getFailedRequestsList($filters: FailRequestFilters!) {
    data: getFailedRequestsList(filters: $filters) {
      items {
        id
        bet_id
        player_id
        brand
        operator_brand_id
        transaction_id
        method
        url
        verify_ssl
        use_rsa
        retry
        timestamp
        executed
        marker
        last_attempt
        attempts
        from_bet_service
        params
        payload
        headers
      }
      total
    }
  }
`;

export default getFailedRequestsList;
