import { gql } from '@apollo/client';
import marginLadderFragment from '../fragments/marginLadderFragment';

const updateMarginLadderMutation = gql`
  ${marginLadderFragment}
  mutation updateMarginLadder(
    $ladderId: LadderId!
    $name: String
    $sportId: SportId
  ) {
    updateMarginLadder(ladder_id: $ladderId, name: $name, sport_id: $sportId) {
      ...MarginLadderFragment
    }
  }
`;

export default updateMarginLadderMutation;
