import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import TableContainer from '@mui/material/TableContainer';

const useStyles = makeStyles(theme => ({
  liabilityMatrixTable: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
  },
  liabilityMatrixTableCell: {
    borderRight: '1px solid #BCC5D1',
    borderBottom: '1px solid #BCC5D1',
    padding: 8,
    fontSize: 16,
  },
  liabilityMatrixTableIndexCell: {
    fontWeight: 400,
    minWidth: 64,
    color: '#BCC5D1',
    backgroundColor: '#F7F7F7',
    position: 'sticky',
    top: 0,
    left: 0,
  },
  liabilityMatrixTableValueCell: {
    backgroundColor: '#BCF2D8',
  },
  liabilityMatrixTableValueCellNegative: {
    backgroundColor: '#FFCCCF',
  },
}));

const LiabilityMatrixTable = ({ matrix }) => {
  const classes = useStyles();

  const columnKeys = [...Array(matrix[0].length + 1).keys()];
  const rowKeys = [...Array(matrix.length).keys()];

  return (
    <>
      <TableContainer sx={{ maxHeight: '600px' }}>
        <table className={classes.liabilityMatrixTable}>
          <thead>
            <tr>
              {columnKeys.map(columnKey => {
                if (columnKey === 0) {
                  return (
                    <th
                      key={`th-${columnKey}`}
                      scope="col"
                      className={clsx(
                        classes.liabilityMatrixTableCell,
                        classes.liabilityMatrixTableIndexCell
                      )}
                      style={{
                        borderTop: '1px solid #BCC5D1',
                        borderLeft: '1px solid #BCC5D1',
                        zIndex: 1,
                      }}
                    />
                  );
                } else {
                  return (
                    <th
                      key={`th-${columnKey}`}
                      scope="col"
                      align="left"
                      className={clsx(
                        classes.liabilityMatrixTableCell,
                        classes.liabilityMatrixTableIndexCell
                      )}
                      style={{
                        borderTop: '1px solid #BCC5D1',
                      }}
                    >
                      {columnKey - 1}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {rowKeys.map(rowKey => (
              <tr key={`tr-${rowKey}`}>
                {columnKeys.map(columnKey => {
                  if (columnKey === 0) {
                    return (
                      <th
                        key={`th-${columnKey}-${rowKey}`}
                        scope="row"
                        align="left"
                        className={clsx(
                          classes.liabilityMatrixTableCell,
                          classes.liabilityMatrixTableIndexCell
                        )}
                        style={{
                          borderLeft: '1px solid #BCC5D1',
                        }}
                      >
                        {rowKey}
                      </th>
                    );
                  } else {
                    const cellValue = Math.round(
                      +matrix[rowKey][columnKey - 1]
                    );

                    return (
                      <td
                        key={`th-${columnKey}-${rowKey}`}
                        align="left"
                        className={clsx(
                          classes.liabilityMatrixTableCell,
                          classes.liabilityMatrixTableValueCell,
                          {
                            [classes.liabilityMatrixTableValueCellNegative]:
                              cellValue < 0,
                          }
                        )}
                      >
                        {cellValue}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </>
  );
};

LiabilityMatrixTable.propTypes = {
  matrix: PropTypes.array.isRequired,
};

export default LiabilityMatrixTable;
