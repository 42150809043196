import Select from '../../../../../../../components/Inputs/Select/Select';
import { useMemo } from 'react';
import { useIsOperator } from '../../../../../../../providers/OperatorProvider';

export const GA_GROUP_BY = {
  day: 'Day',
  month: 'Month',
  week: 'Week',
  year: 'Year',
  operatorId: 'operatorId',
  brandId: 'brandId',
  playerId: 'playerId',
  provider: 'provider',
  sportId: 'sportId',
  categoryId: 'categoryId',
  tournamentId: 'tournamentId',
  eventId: 'eventId',
  marketId: 'marketId',
  marketName: 'marketName',
  outcomeId: 'outcomeId',
  outcomeName: 'outcomeName',
  device: 'device',
  betStatus: 'betStatus',
  bonusType: 'bonusType',
  country: 'country',
  language: 'language',
  playerCcf: 'playerCcf',
  betType: 'betType',
  currency: 'currency',
  layout: 'layout',
  page: 'page',
  section: 'section',
  isLive: 'isLive',
};

const GROUP_BY_LABELS_MAP = {
  [GA_GROUP_BY.day]: 'Day',
  [GA_GROUP_BY.month]: 'Month',
  [GA_GROUP_BY.week]: 'Week',
  [GA_GROUP_BY.year]: 'Year',
  [GA_GROUP_BY.operatorId]: 'Operator ID',
  [GA_GROUP_BY.brandId]: 'Brand ID',
  [GA_GROUP_BY.playerId]: 'Player ID',
  [GA_GROUP_BY.provider]: 'Provider',
  [GA_GROUP_BY.sportId]: 'Sport ID',
  [GA_GROUP_BY.categoryId]: 'Category ID',
  [GA_GROUP_BY.tournamentId]: 'Tournament ID',
  [GA_GROUP_BY.eventId]: 'Event ID',
  [GA_GROUP_BY.marketId]: 'Market ID',
  [GA_GROUP_BY.marketName]: 'Market Name',
  [GA_GROUP_BY.outcomeId]: 'Outcome ID',
  [GA_GROUP_BY.outcomeName]: 'Outcome Name',
  [GA_GROUP_BY.device]: 'Device',
  [GA_GROUP_BY.betStatus]: 'Bet Status',
  [GA_GROUP_BY.bonusType]: 'Bonus Type',
  [GA_GROUP_BY.country]: 'Country',
  [GA_GROUP_BY.language]: 'Language',
  [GA_GROUP_BY.playerCcf]: 'Player CCF',
  [GA_GROUP_BY.betType]: 'Bet Type',
  [GA_GROUP_BY.currency]: 'Currency',
  [GA_GROUP_BY.layout]: 'Layout',
  [GA_GROUP_BY.page]: 'Page',
  [GA_GROUP_BY.section]: 'Section',
  [GA_GROUP_BY.isLive]: 'Is Live',
};

const OPERATORS_GROUP_BY_LABELS_MAP = {
  [GA_GROUP_BY.day]: 'Day',
  [GA_GROUP_BY.month]: 'Month',
  [GA_GROUP_BY.week]: 'Week',
  [GA_GROUP_BY.year]: 'Year',
  [GA_GROUP_BY.brandId]: 'Brand ID',
  [GA_GROUP_BY.playerId]: 'Player ID',
  [GA_GROUP_BY.marketId]: 'Market ID',
  [GA_GROUP_BY.marketName]: 'Market Name',
  [GA_GROUP_BY.outcomeId]: 'Outcome ID',
  [GA_GROUP_BY.outcomeName]: 'Outcome Name',
  [GA_GROUP_BY.device]: 'Device',
  [GA_GROUP_BY.betStatus]: 'Bet Status',
  [GA_GROUP_BY.bonusType]: 'Bonus Type',
  [GA_GROUP_BY.country]: 'Country',
  [GA_GROUP_BY.language]: 'Language',
  [GA_GROUP_BY.playerCcf]: 'Player CCF',
  [GA_GROUP_BY.betType]: 'Bet Type',
  [GA_GROUP_BY.currency]: 'Currency',
  [GA_GROUP_BY.page]: 'Page',
  [GA_GROUP_BY.section]: 'Section',
  [GA_GROUP_BY.isLive]: 'Is Live',
};

const GAGroupBySelect = ({ exclude, ...props }) => {
  const isOperator = useIsOperator();
  const options = Object.entries(
    isOperator ? OPERATORS_GROUP_BY_LABELS_MAP : GROUP_BY_LABELS_MAP
  ).map(([value, label]) => ({
    value,
    label,
  }));

  const filteredOptions = useMemo(() => {
    return exclude
      ? options.filter(option => !exclude.find(item => item === option.value))
      : options;
  }, [exclude, options]);

  return <Select options={filteredOptions} {...props} />;
};

export default GAGroupBySelect;
