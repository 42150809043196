import { useLocation } from 'react-router-dom';

const useBreadcrumbs = (current = []) => {
  const location = useLocation();

  if (location.state && location.state.from) {
    return [
      {
        label: location.state.from.title,
        to: location.state.from.url,
      },
      ...current,
    ];
  }

  return current;
};

export default useBreadcrumbs;
