import * as React from 'react';
import { useContext } from 'react';
import { compose } from 'recompose';
import withData from '../uiComponents/withData';
import withRouter from '../utils/withRouter';

export const DataContext = React.createContext({});

const DataProvider = ({
  children,
  data,
  loading,
  path,
  error,
  fetchData,
  operationId,
  query,
  method,
  url,
  params,
  xStream,
  storeStrategy,
  firstSkipped,
}) => {
  return (
    <DataContext.Provider
      value={{
        path,
        data,
        loading,
        fetchData,
        error,
        operationId,
        method,
        url,
        query,
        params,
        xStream,
        storeStrategy,
        firstSkipped,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};

export default compose(withRouter, withData)(DataProvider);
