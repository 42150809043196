import React from 'react';
import Field from '../../../uiComponents/Field/Field/Field';

const FieldEditor = () => {
  return (
    <div>
      <Field />
    </div>
  );
};

export default FieldEditor;
