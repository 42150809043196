import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AvatarIcon from '@mui/icons-material/AccountCircle';
import { getLinksAndSchemas } from '../utils/schemaHelper';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import { useRoutes } from '../providers/RoutesProvider';
import { useDialog } from '../v2/hooks';
import DialogProvider from '../v2/providers/DialogProvider';
import ChangePasswordForm from './Authorization/pages/ChangePassword/components/ChangePasswordForm';
import { Stack, Typography } from '@mui/material';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ENDPOINT } from './Authorization/utils';
import changePassword from '../v2/gql/auth/changePassword';
import { useSessionStorage } from '../hooks/useStorage';
import { useSelector } from 'react-redux';
import { getUser } from '../reducers/users';

const useStyles = makeStyles({
  email: {
    '&:focus': {
      outline: 'none',
    },
  },
});

const ChangePasswordModal = ({ onClose, afterSuccess }) => {
  const [success, setSuccess] = useState(false);

  const TITLE = success ? 'Password changed successfully' : 'Change password';

  const CAPTION = success
    ? 'You will be redirected to login in a 5 seconds'
    : 'Please fill out this short form carefully to change a password.';

  const [schemaURL] = useSessionStorage('sessionSchemaUrl', null);
  const user = useSelector(getUser);

  const handleSubmit = (data, onError) => {
    const client = new ApolloClient({
      uri: schemaURL.replace('/api/v1/schema.json', `${ENDPOINT}/`), // TODO: remove last slash after backend nginx fix
      cache: new InMemoryCache(),
    });

    client
      .mutate({
        mutation: changePassword,
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.password,
        },
        context: {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      })
      .then(response => {
        if (response.data) {
          afterSuccess();
          // setSuccess(true);
        }
      })
      .catch(error => {
        onError(error.graphQLErrors);
      });
  };
  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      gap={'25px'}
      width={'100%'}
    >
      <Stack gap={'12px'}>
        <Typography textAlign={'center'} fontWeight={600} fontSize={'24px'}>
          {TITLE}
        </Typography>
        <Typography
          textAlign={'center'}
          maxWidth={'320px'}
          color={'#93a3b5'}
          fontSize={'14px'}
          lineHeight={'20px'}
        >
          {CAPTION}
        </Typography>
      </Stack>
      {!success && (
        <ChangePasswordForm onSubmit={handleSubmit} onCancel={onClose} />
      )}
    </Stack>
  );
};

const UserMenu = ({ logout, user }) => {
  const classes = useStyles();
  const routes = useRoutes();
  const [anchorEl, setAnchorEl] = useState(null);
  const { open, close } = useDialog({ closeOnEsc: true });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    setAnchorEl(null);

    open({
      component: ChangePasswordModal,
      props: {
        onClose: close,
        afterSuccess: () => {
          close();
          logout();
        },
      },
      options: {
        onClose: close,
      },
    });
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-owns={anchorEl ? 'user-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        size="large"
      >
        <AvatarIcon />
      </IconButton>
      <DialogProvider />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user && user.email && (
          <MenuItem divider button={false} className={classes.email}>
            {user.email}
          </MenuItem>
        )}
        {user ? (
          <>
            <MenuItem onClick={handleChangePassword}>Change password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </>
        ) : (
          Object.keys(routes)
            .filter(routeName =>
              get(getLinksAndSchemas(routes, routeName).uiSchema, 'ui:usermenu')
            )
            .map(routeName => (
              <MenuItem
                key={routeName}
                component={Link}
                to={{
                  pathname: routeName,
                }}
              >
                {routes[routeName].summary}
              </MenuItem>
            ))
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
