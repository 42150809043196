import { gql } from '@apollo/client';

const enableEventMarketsMutation = gql`
  mutation enableEventMarkets(
    $eventId: EventId!
    $status: Boolean!
    $version: Version!
  ) {
    enableEventMarkets(event_id: $eventId, status: $status, version: $version) {
      id
      blocked_markets
      version
    }
  }
`;

export default enableEventMarketsMutation;
