import React from 'react';
import TextField from '@mui/material/TextField';

const IntegerFilter = ({
  fullWidth,
  label,
  InputProps,
  className,
  error,
  helperText,
  value,
  ...props
}) => (
  <TextField
    fullWidth={fullWidth}
    type="number"
    margin="dense"
    label={label}
    className={className}
    InputProps={InputProps}
    error={error}
    helperText={helperText}
    variant={'outlined'}
    value={value == null ? '' : value}
    {...props}
  />
);

export default IntegerFilter;
