import { gql } from '@apollo/client';

const getEventLiabilityMatrixQuery = gql`
  query getEventLiabilityMatrix(
    $eventId: EventId!
    $size: Int!
    $betTypes: [BetTypes!]
    $playerId: PlayerId
  ) {
    getEventLiabilityMatrix(
      eventId: $eventId
      size: $size
      betTypes: $betTypes
      playerId: $playerId
    ) {
      id
      coverage {
        liabilityCoverage
        marketsCoverage
      }
      liabilityTotal
      liabilityCalculated
      marketsTotal
      marketsCovered
      marketGroupsMatrices {
        id
        name
        matrix
        marketsMatrices {
          id
          name
          matrix
          liabilityCalculated
          coverage
          outcomesTotal
          outcomesCovered
          minBetSpecifiersValue
          maxBetSpecifiersValue
        }
        liabilityTotal
        liabilityCalculated
        coverage
        marketsTotal
        marketsCovered
      }
    }
  }
`;

export default getEventLiabilityMatrixQuery;
