import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const setOutcomeCoefMutation = gql`
  ${eventModelFragment}
  mutation setOutcomeCoef(
    $eventId: EventId!
    $marketId: MarketId!
    $specifier: Specifiers!
    $outcomeId: OutcomeId!
    $coef: Coef!
    $version: Version!
  ) {
    setOutcomeCoef(
      event_id: $eventId
      market_id: $marketId
      specifier: $specifier
      outcome_id: $outcomeId
      coef: $coef
      version: $version
    ) {
      ...EventModelFragment
    }
  }
`;
// TODO: add event model response
export default setOutcomeCoefMutation;
