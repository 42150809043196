/* eslint-disable no-undef */

import JSONSchemaForm from 'react-jsonschema-form';
import { toErrorList } from 'react-jsonschema-form/lib/validate';
import { removeOptionalEmptyValues } from './helpers';

class PatchedForm extends JSONSchemaForm {
  validate(formData, schema) {
    formData = removeOptionalEmptyValues(formData, this.props.schema);

    return super.validate(formData, schema);
  }

  onChange = (formData, newErrorSchema) => {
    const mustValidate = !this.props.noValidate && this.props.liveValidate;
    let state = { formData };
    if (mustValidate) {
      const { errors, errorSchema } = this.validate(formData);
      state = { ...state, errors, errorSchema };
    } else if (!this.props.noValidate && newErrorSchema) {
      state = {
        ...state,
        errorSchema: newErrorSchema,
        errors: toErrorList(newErrorSchema),
      };
    }

    this.setState(state, () => {
      if (this.props.onChange) {
        this.props.onChange(state);
      }
    });
  };
}

export default PatchedForm;
