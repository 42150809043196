import React, { forwardRef, useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// noinspection JSFileReferences
const importSvgIcon = ({ name }) =>
  import(`@svgr/webpack?-svgo,+ref!./flags/${name}.svg`);

const loadSvgIcon = ({ name }) =>
  importSvgIcon({ name }).catch(() => ({ ReactComponent: null }));

const useIcon = name => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const loadParams = { name };

    const iconPromise = loadSvgIcon(loadParams);

    iconPromise.then(data => {
      if (didCancel) {
        return;
      }
      if (data.ReactComponent) {
        setComponent(data.ReactComponent);
      }
    });

    return () => {
      didCancel = true;
    };
  }, [name]);

  return Component;
};

const useStyles = makeStyles({
  countryIconContainer: {
    overflow: 'hidden',
    height: 16,
    width: 16,
    borderRadius: 2,
    boxSizing: 'border-box',
    border: '0.5px solid #8496AB',
  },
});

const CountryIcon = forwardRef(({ name, ...props }, ref) => {
  const classes = useStyles();

  if (!name) {
    // TODO design icon fallback
    return null;
  }

  const Icon = useIcon(name.toLowerCase());

  return (
    <div className={classes.countryIconContainer}>
      <SvgIcon viewBox={'0 0 16 16'} {...props} ref={ref}>
        {Icon && <Icon />}
      </SvgIcon>
    </div>
  );
});

export default CountryIcon;
