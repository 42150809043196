import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

const RadioWidget = ({
  options: { enumOptions, inline },
  value,
  label,
  required,
  disabled,
  readonly,
  onChange,
  id,
}) => (
  <FormControl
    component="fieldset"
    required={required}
    disabled={disabled || readonly}
  >
    <RadioGroup
      aria-label={label}
      name={Math.random().toString()}
      row={inline}
      value={`${value}`}
      onChange={e => onChange(e.target.value)}
    >
      {enumOptions.map((option, i) => (
        <FormControlLabel
          key={i}
          value={`${option.value}`}
          control={<Radio color="primary" id={id} />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

RadioWidget.defaultProps = {
  autofocus: false,
};

RadioWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioWidget;
