import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFiltersActionCard from './useFiltersActionCard';
import useFiltersPreset from './useFiltersPreset';
import useFiltersQuery from './useFiltersQuery';
import useInitialFiltersList from './useInitialFiltersList';

export const withoutEmpty = (values, options = {}) => {
  return Object.keys(values).reduce((acc, key) => {
    const value = values[key];

    const isValidValue =
      value !== '' &&
      value !== null &&
      !(options.removeEmptyArrays && Array.isArray(value) && !value.length);

    if (isValidValue) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});
};

const usePresetFilters = ({
  tableName,
  hidePresets = false,
  filters: filtersList,
  onSubmit: onSubmitCb = () => {},
  onResubmit: onResubmitCb = () => {},
  resetOffset,
  spanMin,
  spanMax,
  extraFilters,
}) => {
  const initialFiltersList = useInitialFiltersList({ filtersList });

  const [filters, setFilters] = useState(initialFiltersList);
  const [
    presetFilters,
    onChangePresetFilters,
    initialFilters,
    isDefaultPreset,
  ] = useFiltersPreset();

  const filteredPresetFilters = useMemo(() => {
    return (
      presetFilters &&
      filtersList.reduce((acc, item) => {
        return {
          ...acc,
          [item.name]: presetFilters[item.name],
        };
      }, {})
    );
    // eslint-disable-next-line
  }, [presetFilters]);

  const [filtersQuery, setFiltersQuery] = useFiltersQuery(
    filteredPresetFilters,
    'filters'
  );

  //for reset pagination after preset chosen
  useEffect(() => {
    resetOffset && resetOffset(0);
  }, [initialFilters, resetOffset]);

  const onSubmit = useCallback(
    (...args) => {
      resetOffset && resetOffset(0);

      setFiltersQuery(...args);
      onSubmitCb(...args);
    },
    [onSubmitCb, resetOffset, setFiltersQuery]
  );

  const onResubmit = useCallback(
    (...args) => {
      resetOffset && resetOffset(0);

      if (isEmpty(filtersQuery)) {
        setFiltersQuery(...args);
      }

      onResubmitCb(...args);
    },
    [onResubmitCb, resetOffset, setFiltersQuery, filtersQuery]
  );

  const filtersCard = useFiltersActionCard({
    filters,
    setFilters,
    defaultValues: initialFilters,
    filtersQuery,
    onSubmit,
    onResubmit,
    spanMin,
    spanMax,
    hidePresets,
    extraFilters,
    isDefaultPreset,
    tableName,
    onChangePresetFilters,
  });

  return useMemo(
    () => ({ filtersCard, filters: filtersQuery }),
    [filtersCard, filtersQuery]
  );
};

export default usePresetFilters;
