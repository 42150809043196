import { useQuery } from '@apollo/client';
import getCategoriesNames from '../../gql/EventsGroups/queries/getCategoriesNames';

const useCategories = options => {
  const { data, loading, error, refetch } = useQuery(getCategoriesNames, {
    context: {
      apiName: 'autocomplete',
    },
    ...options,
  });

  let categories = [];

  if (data) {
    categories = data.getCategoriesNames;
  }

  return { categories, loading, error, refetch };
};

export default useCategories;
