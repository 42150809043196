import FormGroup from '../../components/Form/FormGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import FormGroupLabel from '../../components/Form/FormGroupLabel';
import Box from '@mui/material/Box';
import Button from '../../components/Button/Button';
import Stack from '@mui/material/Stack/Stack';
import RelatedContingencyFields, {
  relatedContingencyItemSchema,
} from './RelatedContingencyFields';
import RelatedContingencyItemType from './constants/RelatedContingencyItemType';
import { createApi, createStore } from 'effector';
import { useStore } from 'effector-react';
import Chip from '../../components/Chip/Chip';
import getRelatedContingencyLabel from './utils/getRelatedContingencyLabel';
import { useCallback, useMemo } from 'react';
import RelatedContingencyRuleType from './constants/RelatedContingencyRuleType';
import Tooltip from '../../components/Tooltip/Tooltip';

const $form = createStore({
  right: null,
  left: null,
});

const {
  addContingency,
  removeLeftContingency,
  removeRightContingency,
  onReset,
} = createApi($form, {
  addContingency: (form, values) => {
    if (form.left) {
      return {
        ...form,
        right: values,
      };
    }

    return {
      ...form,
      left: values,
    };
  },

  removeLeftContingency: form => {
    return {
      ...form,
      left: form.right,
      right: null,
    };
  },

  removeRightContingency: form => {
    return {
      ...form,
      right: null,
    };
  },

  onReset: () => {
    return {
      right: null,
      left: null,
    };
  },
});

const defaultValues = async () => ({
  sports: {
    type: RelatedContingencyItemType.LIST,
    value: [],
  },
  categories: {
    type: RelatedContingencyItemType.LIST,
    value: [],
  },
  tournaments: {
    type: RelatedContingencyItemType.LIST,
    value: [],
  },
  events: {
    type: RelatedContingencyItemType.LIST,
    value: [],
  },
  markets: {
    type: RelatedContingencyItemType.LIST,
    value: [],
  },
  competitors: {
    type: RelatedContingencyItemType.LIST,
    value: [],
  },
});

const RelatedContingenciesForm = ({ loading, onSubmit }) => {
  const { left, right } = useStore($form);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(relatedContingencyItemSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = methods;

  const onAddContingency = useCallback(
    values => {
      addContingency(values);
      reset();
    },
    [reset]
  );

  const onCancel = useCallback(() => {
    reset();
    onReset();
  }, [reset]);

  const createRule = useCallback(
    type => {
      onSubmit({
        left,
        right,
        rule_type: type,
      }).then(() => {
        onReset();
      });
    },
    [onSubmit, left, right]
  );

  const onInclude = useCallback(() => {
    createRule(RelatedContingencyRuleType.INCLUDE);
  }, [createRule]);

  const onExclude = useCallback(() => {
    createRule(RelatedContingencyRuleType.EXCLUDE);
  }, [createRule]);

  return (
    <Box mt={1} px={0.25}>
      <Stack spacing={0.5} direction={'column'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onAddContingency)} noValidate>
            <FormGroup largeTop>
              <Grid container spacing={2} direction={'column'}>
                <Grid item xs={12}>
                  <FormGroupLabel helpText={'Create Related Contingencies'}>
                    Create Related Contingencies
                  </FormGroupLabel>
                </Grid>

                <Grid item xs={12}>
                  <RelatedContingencyFields withSameCompetitorOption={!left} />
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'end'}
                  >
                    <Stack
                      spacing={0.5}
                      direction={'column'}
                      alignItems={'start'}
                      sx={{
                        maxWidth: '100%',
                      }}
                    >
                      {left && (
                        <Chip onDelete={removeLeftContingency}>
                          <Tooltip
                            title={getRelatedContingencyLabel(left, true)}
                          >
                            <span>{getRelatedContingencyLabel(left)}</span>
                          </Tooltip>
                        </Chip>
                      )}
                      {right && (
                        <Chip onDelete={removeRightContingency}>
                          <Tooltip
                            title={getRelatedContingencyLabel(right, true)}
                          >
                            <span>{getRelatedContingencyLabel(right)}</span>
                          </Tooltip>
                        </Chip>
                      )}
                    </Stack>

                    <Button
                      color={'primary'}
                      type={'submit'}
                      disabled={!isValid || (right && left)}
                    >
                      Add Contingency
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </form>
        </FormProvider>

        <FormGroup largeTop>
          <Grid container direction={'row-reverse'}>
            <Stack spacing={1} direction={'row'}>
              <Button
                color={'primary'}
                variant={'text'}
                onClick={onCancel}
                disabled={!isDirty && !left && !right}
              >
                Cancel
              </Button>
              <Button
                color={'lightBlue'}
                disabled={loading || !left || !right}
                onClick={onInclude}
              >
                Include
              </Button>
              <Button
                color={'lightRed'}
                disabled={loading || !left || !right}
                onClick={onExclude}
              >
                Exclude
              </Button>
            </Stack>
          </Grid>
        </FormGroup>
      </Stack>
    </Box>
  );
};

export default RelatedContingenciesForm;
