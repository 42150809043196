import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import MoveIcon from '../../../icons/controls/MoveIcon';

const useStyles = makeStyles(theme => ({
  moveButton: {
    padding: '11px 0px 11px 8px',
    height: 16,
    color: '#8496AB',
    '&>svg': {
      fontSize: '16px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  removeButton: {
    padding: '11px 16px',
    height: 16,
    '&>svg': {
      fontSize: '16px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  orderListRow: {
    display: 'grid',
    gridGap: 8,
    height: 38,
    backgroundColor: '#fff',
    marginBottom: 4,
    borderRadius: 4,
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    borderLeft: '4px solid #fff',
  },
  orderListRowDragging: {
    boxShadow: '0px 4px 16px rgba(132, 150, 171, 0.5)',
  },
  orderListRowActive: {
    borderLeft: '4px solid #8496AB',
  },
  orderListCell: {
    letterSpacing: '0.2px',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '13px',
    color: '#031224',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const DraggableListItem = ({
  isActive,
  provided,
  snapshot,
  data,
  actions,
  columns,
  columnsTemplate,
}) => {
  const classes = useStyles();
  const { isDragging } = snapshot;

  return (
    <div
      className={classNames(classes.orderListRow, {
        [classes.orderListRowDragging]: isDragging,
        [classes.orderListRowActive]: isActive,
      })}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        gridTemplateColumns:
          columnsTemplate ||
          `32px 2fr ${' 1fr '.repeat(columns.length - 1)} 48px`,
      }}
    >
      <div className={classes.moveButton} {...provided.dragHandleProps}>
        <MoveIcon />
      </div>
      {columns.map((field, index) => {
        return (
          <div key={index} className={classes.orderListCell}>
            {field(data)}
          </div>
        );
      })}
      {actions}
    </div>
  );
};

export default DraggableListItem;
