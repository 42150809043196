import GetEventsNames from '../../../gql/RiskManagement/queries/getEventsNames';
import FancyAutocomplete from '../FancyAutocomplete/FancyAutocomplete';
import { forwardRef } from 'react';

const FancyEventAutocomplete = forwardRef(({ ...props }, ref) => {
  return (
    <FancyAutocomplete
      ref={ref}
      gqlQuery={GetEventsNames}
      label={'Event'}
      valueKey={'value'}
      labelKey={'label'}
      multiple={false}
      checkValueOptions={false}
      {...props}
    />
  );
});

export default FancyEventAutocomplete;
