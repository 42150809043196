import * as React from 'react';
import { useEffect, useState } from 'react';

export const DebugModeContext = React.createContext({});

const DebugModeProvider = ({ children }) => {
  const [debuggerEnabled, setDebuggerEnabled] = useState(false);

  useEffect(() => {
    document.onkeyup = function (e) {
      if (e.ctrlKey && e.code === 'KeyD') {
        setDebuggerEnabled(value => !value);
      }
    };
  }, []);

  return (
    <DebugModeContext.Provider value={debuggerEnabled}>
      {children}
    </DebugModeContext.Provider>
  );
};

export default DebugModeProvider;
