import { cloneElement, forwardRef, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormFieldDepsProvider from './FormFieldDepsProvider';
import get from 'lodash/get';
import FormFieldPlaceholder from './FormFieldPlaceholder';

const FieldRender = forwardRef(
  ({ component, onChange, field, helperText, error }, ref) => {
    const onChangeField = field.onChange;

    const onChangeCb = useCallback(
      (...args) => {
        onChangeField(...args);
        if (onChange) {
          onChange(...args);
        }
      },
      [onChangeField, onChange]
    );

    return cloneElement(component, {
      ref,
      ...field,
      error: error?.toString(),
      helperText,
      onChange: onChangeCb,
    });
  }
);

const FormField = ({ deps, name, value, onChange, children, ...props }) => {
  const {
    control,
    formState: { errors, isLoading },
  } = useFormContext();

  const component = useMemo(() => {
    return deps ? (
      <FormFieldDepsProvider deps={deps}>{children}</FormFieldDepsProvider>
    ) : (
      children
    );
  }, [deps, children]);

  const render = useCallback(
    ({ field }) => {
      if (isLoading) {
        return <FormFieldPlaceholder />;
      }
      const error = get(errors, name);

      const helperText = error && error.message;

      return (
        <FieldRender
          error={error}
          component={component}
          field={field}
          onChange={onChange}
          helperText={helperText}
        />
      );
    },
    [component, errors, name, onChange, isLoading]
  );

  return (
    <Controller {...props} name={name} control={control} render={render} />
  );
};

export default FormField;
