import React, { createContext } from 'react';

export const TableRowsDataContext = createContext([]);

const TableRowsDataProvider = ({ rows, children }) => (
  <TableRowsDataContext.Provider value={rows}>
    {children}
  </TableRowsDataContext.Provider>
);

export default TableRowsDataProvider;
