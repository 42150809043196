import FormField from '../../../../components/Form/FormField';
import Grid from '@mui/material/Grid/Grid';
import ColorInput from '../../../../components/Inputs/ColorInput/ColorInput';

const BannerButtonColor = ({ name }) => {
  return (
    <Grid item xs={4}>
      <FormField name={name}>
        <ColorInput
          label={'Button Color'}
          hint={'Brand color will be used if empty'}
        />
      </FormField>
    </Grid>
  );
};

export default BannerButtonColor;
