import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const DescriptionField = ({ id, description }) => {
  if (!description) {
    return null;
  }
  return <Typography id={id}>{description}</Typography>;
};

DescriptionField.propTypes = {
  id: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default DescriptionField;
