import React, { Children } from 'react';
import Grid from '@mui/material/Grid';
import { compose } from 'recompose';
import GridProvider from '../../providers/GridProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const withXs = () => WrappedComponent => props =>
  <WrappedComponent {...props} width="xs" />;

const DEFAULT_SIZES = {
  CardContainer: {
    xs: 12,
  },
  CardWithIcon: {
    xs: 12,
    sm: 6,
    lg: 3,
  },
  TabContainer: {
    xs: 12,
  },
  StaticFieldList: {
    xs: 12,
    sm: 6,
  },
  Filters: {
    xs: 12,
  },
  Charts: {
    xs: 12,
  },
  Table: {
    xs: 12,
  },
};

const BaseLayout = ({ children }) => {
  const theme = useTheme();
  const isWidthUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <GridProvider totalItems={Children.count(children)}>
      <Grid container spacing={isWidthUp ? 3 : 1} alignItems="stretch">
        {Children.map(children, child => {
          const {
            'ui:component': uiComponent,
            'ui:container': uiContainer,
            'ui:settings': uiSettings = {},
          } = child.props.uiSchema;
          const sizes =
            uiSettings.size || DEFAULT_SIZES[uiComponent || uiContainer] || {};

          return (
            <Grid item {...sizes}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    </GridProvider>
  );
};

export default compose(withXs())(BaseLayout);
