import CountryIcon from '../../../../icons/CountryIcon';
import DeviceIcon from '../../../../icons/DeviceIcon';
import SportIcon from '../../../../icons/SportIcon';
import MaterialIcon from '../../../../icons/MaterialIcon';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import clsx from 'clsx';

export const IconSetEnum = {
  SPORT: 'spt77_sport',
  COUNTRY: 'country',
  DEVICE: 'device',
  MATERIAL: 'material',
};

const IconComponentMap = {
  [IconSetEnum.COUNTRY]: CountryIcon,
  [IconSetEnum.DEVICE]: DeviceIcon,
  [IconSetEnum.SPORT]: SportIcon,
  [IconSetEnum.MATERIAL]: MaterialIcon,
};

const useStyles = makeStyles({
  [IconSetEnum.COUNTRY]: {
    height: 16,
    width: 16,
  },

  [IconSetEnum.DEVICE]: {
    height: 18,
    width: 18,
    color: '#8496AB',
  },

  [IconSetEnum.SPORT]: {
    fontSize: 18,
    color: '#8496AB',
  },
});

const IconField = memo(
  React.forwardRef(
    ({ name, iconSet = IconSetEnum.MATERIAL, ...props }, ref) => {
      const classes = useStyles();

      const IconComponent = IconComponentMap[iconSet];

      return (
        <IconComponent
          ref={ref}
          name={name}
          {...props}
          className={clsx(classes[iconSet], props && props.className)}
        />
      );
    }
  )
);

export default IconField;
