import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ContextMenu from '../../../components/ContextMenu';
import ContextMenuItem from '../../../components/ContextMenuItem';
import MoveIcon from '../../../icons/controls/MoveIcon';
import DraggableRef from '../../../uiComponents/Table/DraggableRef';
import Checkbox from '../Inputs/Checkbox';
import InlineDrugDropMenuContainer from './InlineDrugDropMenuContainer';

const useStyles = makeStyles(theme => ({
  menuItemRoot: {
    paddingLeft: 6,
    backgroundColor: '#fff',

    '&>$menuItemLabel': {
      marginRight: 'auto',
      marginLeft: 6,
    },

    '&:hover': {
      '& $menuItemCheckbox': {
        color: theme.palette.primary.main,
      },
    },
  },
  menuItemRootDragging: {
    borderRadius: 8,
    boxShadow: '0px 4px 16px rgba(132, 150, 171, 0.5)',
  },
  virtualizedDragging: {
    left: '0 !important',
    top: 'auto !important',
  },
  menuItemLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuItemCheckbox: {
    paddingRight: 0,
  },

  moveButton: {
    display: 'flex',
    color: '#8496AB',
    '&>svg': {
      fontSize: '16px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const DragDropMenu = ({
  droppableId = 'droppable',
  onDragEnd = () => {},
  onDragStart = () => {},
  isMenuOpen = false,
  handleMenuClose = () => {},
  columns = [],
  disabledColumns = [],
  onToggleColumn = () => {},
  toggledColumns = [],
  inlineMenu = false,
  checkboxIndeterminateMode = false,
  customContainerClass,
  anchorEl,
  enableUncheckLast = false,
}) => {
  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onDragStart}>
      <Droppable
        droppableId={droppableId}
        direction="vertical"
        renderClone={(provided, snapshot, rubric) => {
          const column = columns[rubric.source.index];

          return (
            <ContextMenuItem
              disabled={
                disabledColumns && disabledColumns.indexOf(column.key) !== -1
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              classes={{
                root: clsx(classes.menuItemRoot, {
                  [classes.menuItemRootDragging]: snapshot.isDragging,
                }),
              }}
            >
              <div className={classes.moveButton}>
                <MoveIcon />
              </div>
              <span className={classes.menuItemLabel}>{column.label}</span>

              <Checkbox
                name={column.key}
                extraClasses={{
                  root: classes.menuItemCheckbox,
                }}
                value={column.toggled}
              />
            </ContextMenuItem>
          );
        }}
      >
        {provided => {
          const DragMenuComponent = inlineMenu
            ? InlineDrugDropMenuContainer
            : ContextMenu;

          const props = {
            keepMounted: false,
            anchorEl: anchorEl,
            open: isMenuOpen,
            onClose: handleMenuClose,
            ref: provided.innerRef,
            style: {
              width: inlineMenu ? '100%' : 200,
              borderRadius: 8,
              margin: '4px',
              boxShadow:
                '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
            },
            className: customContainerClass,
            ...provided.droppableProps,
          };

          const isLastVisible =
            !enableUncheckLast && toggledColumns.length === 1;

          return (
            isMenuOpen && (
              <DragMenuComponent {...props}>
                {columns.map((column, index) => {
                  return (
                    <DraggableRef
                      key={column.key}
                      draggableId={`id-${column.key}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ContextMenuItem
                          disabled={
                            (disabledColumns &&
                              disabledColumns.includes(column.key)) ||
                            (isLastVisible &&
                              toggledColumns.includes(column.key))
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          classes={{
                            root: clsx(classes.menuItemRoot, {
                              [classes.menuItemRootDragging]:
                                snapshot.isDragging,
                            }),
                          }}
                          onClick={() =>
                            onToggleColumn(
                              column.key,
                              !toggledColumns.includes(column.key)
                            )
                          }
                        >
                          <div
                            className={classes.moveButton}
                            {...provided.dragHandleProps}
                          >
                            <MoveIcon />
                          </div>
                          <span className={classes.menuItemLabel}>
                            {column.label}
                          </span>

                          <Checkbox
                            name={column.key}
                            extraClasses={{
                              root: classes.menuItemCheckbox,
                            }}
                            indeterminateMode={checkboxIndeterminateMode}
                            value={toggledColumns.includes(column.key)}
                          />
                        </ContextMenuItem>
                      )}
                    </DraggableRef>
                  );
                })}
                {provided.placeholder}
              </DragMenuComponent>
            )
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
export default DragDropMenu;
