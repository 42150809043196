import TextField from '../TextField';
import ColorPickerAdornment from './ColorPickerAdornment';
import { forwardRef } from 'react';

const ColorInput = forwardRef(
  (
    { name, onChange, value, label = 'Button color', helperText, hint },
    ref
  ) => {
    return (
      <TextField
        ref={ref}
        name={name}
        onChange={onChange}
        value={value}
        helperText={helperText || hint}
        label={label}
        InputProps={{
          endAdornment: (
            <ColorPickerAdornment value={value} onChange={onChange} />
          ),
        }}
      />
    );
  }
);

export default ColorInput;
