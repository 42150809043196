import React, { forwardRef, memo } from 'react';
import { animated, config, useSpring } from 'react-spring';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  expandIcon: {
    cursor: 'pointer',
    width: 16,
    height: 16,
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("/icons/ExpandIcon16.svg")',
    backgroundPosition: 'center',
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed',
  },
  whiteIcon: {
    filter: 'brightness(0) invert(1)',
  },
});

const ExpandIcon = memo(
  forwardRef(
    ({ expanded, disabled, className, style, white, ...props }, ref) => {
      const classes = useStyles();

      const { rotate } = useSpring({
        rotate: expanded ? 180 : 0,
        config: config.gentle,
      });

      return (
        <animated.div
          ref={ref}
          className={clsx(
            classes.expandIcon,
            className,
            white && classes.whiteIcon,
            {
              [classes.disabled]: disabled,
            }
          )}
          style={{
            transform: rotate.to(deg => `rotateZ(${deg}deg)`),

            ...style,
          }}
          {...props}
        />
      );
    }
  )
);

export default ExpandIcon;
