import BannerPreview from './BannerPreview';
import Stack from '@mui/material/Stack/Stack';
import BannerPreviewPlaceholder from './BannerPreviewPlaceholder';
import { forwardRef } from 'react';

const TournamentBannerPreview = forwardRef(
  ({ reversed, active, onClick, ...props }, ref) => {
    return (
      <BannerPreview
        ref={ref}
        title={'Tournament'}
        active={active}
        onClick={onClick}
        reversed={reversed}
        {...props}
      >
        <Stack direction={'row'} spacing={1} mt={'18px'}>
          <BannerPreviewPlaceholder
            style={{
              height: 21,
              width: 21,
              borderRadius: 1,
            }}
          />
        </Stack>
      </BannerPreview>
    );
  }
);

export default TournamentBannerPreview;
