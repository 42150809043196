import { gql } from '@apollo/client';

const getBrandNames = gql`
  query getBrandNames {
    data: getBrandsWithAssignedTemplates {
      brand {
        id
        name
      }
    }
  }
`;

export default getBrandNames;
