import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import Stack from '@mui/material/Stack/Stack';
import FormGroupLabel from '../../../../../components/Form/FormGroupLabel';
import FormField from '../../../../../components/Form/FormField';
import NumberField from '../../../../../components/Inputs/NumberField';
import BannerImageUploader from '../../BannerImageUploader/BannerImageUploader';
import LineBannerAutoEventsPreview from '../../BannerImagePreview/LineBannerAutoEventsPreview';
import AutoEventsAutocomplete from '../../../../../components/Inputs/AutoEventsAutocomplete/AutoEventsAutocomplete';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

const AutoEventsView = ({ prefix }) => {
  const { watch } = useFormContext();

  const selectedBrand = watch('brand');

  return (
    <Stack direction={'column'} spacing={2}>
      <ImagePreviewProvider>
        <LocaleTabs
          name={`${prefix}.locales`}
          content={(localeField, localeIndex) => (
            <Fragment
              key={localeField.locale.country + localeField.locale.lang}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  }}
                >
                  <FormGroup large>
                    <Stack spacing={2} direction={'column'}>
                      <Box>
                        <FormGroupLabel
                          helpText={
                            'Select tournaments or events you want to promote'
                          }
                        >
                          Add events
                        </FormGroupLabel>
                      </Box>
                      <Box>
                        <FormField
                          name={`${prefix}.locales.${localeIndex}.events`}
                        >
                          <AutoEventsAutocomplete
                            label={'Enter at last 3 characters...'}
                            params={{ brand_id: selectedBrand?.id }}
                          />
                        </FormField>
                      </Box>
                    </Stack>
                  </FormGroup>

                  <FormGroup large>
                    <Grid container spacing={2} direction={'column'}>
                      <Grid item xs={12}>
                        <FormGroupLabel>Events Limit</FormGroupLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FormField
                              name={`${prefix}.locales.${localeIndex}.events_limit`}
                            >
                              <NumberField
                                required
                                label={'Limit'}
                                step={1}
                                min={1}
                                max={100}
                              />
                            </FormField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Box>
              </Grid>
              {selectedBrand && (
                <Grid item xs={12}>
                  <FormGroup large>
                    <FormField
                      name={`${prefix}.locales.${localeIndex}.images.desktop`}
                    >
                      <BannerImageUploader
                        useGradient
                        preview={LineBannerAutoEventsPreview}
                        previewFields={[]}
                        cropSize={{
                          width: 360,
                          height: 152,
                        }}
                      />
                    </FormField>
                  </FormGroup>
                </Grid>
              )}
            </Fragment>
          )}
        />
      </ImagePreviewProvider>
    </Stack>
  );
};

export default AutoEventsView;
