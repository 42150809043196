import * as React from 'react';
import { useContext } from 'react';

export const SchemaContext = React.createContext({});

const SchemaProvider = ({ children, value }) => {
  return (
    <SchemaContext.Provider value={value}>{children}</SchemaContext.Provider>
  );
};

export const useSchema = () => {
  return useContext(SchemaContext);
};

export default SchemaProvider;
