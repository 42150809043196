import { gql } from '@apollo/client';

const getTemplateNames = gql`
  query getTemplateNames($templateTypes: [TemplateType]!) {
    data: getTemplateNames(templateTypes: $templateTypes) {
      id
      name
    }
  }
`;

export default getTemplateNames;
