import Select from '../../../components/Inputs/Select/Select';

export const TEMPLATE_TYPE = {
  COVERAGE: 'coverage',
  SOURCES: 'sources',
  MARGIN: 'margin',
};

const TemplateTypeSelect = ({ label, value }) => {
  return (
    <Select
      label={label}
      options={[
        {
          label: 'Coverage',
          value: TEMPLATE_TYPE.COVERAGE,
        },
        {
          label: 'Margin',
          value: TEMPLATE_TYPE.MARGIN,
        },
        {
          label: 'Sources',
          value: TEMPLATE_TYPE.SOURCES,
        },
      ]}
      value={value}
    />
  );
};

export default TemplateTypeSelect;
