import React from 'react';
import { asNumber } from 'react-jsonschema-form/lib/utils';
import DynamicSelect from '../../../components/inputs/Select/DynamicSelect';
import EnumSelect from '../../../components/inputs/Select/EnumSelect';

const processValue = ({ type, items }, value) => {
  if (value === '') {
    return null;
  }

  if (type === 'array' && items && ['number', 'integer'].includes(items.type)) {
    return value.map(asNumber);
  }

  if (type === 'boolean') {
    return value === 'true';
  }

  if (type === 'number') {
    return asNumber(value);
  }

  return value;
};

const getValue = event => {
  return event.target.value;
};

const SelectWidget = ({
  id,
  schema,
  errorSchema = {},
  formContext: { formData },
  options: {
    formatLabelCase,
    value_key: valueKey = 'value',
    label_key: labelKey = 'label',
    image_key: imageKey,
    help: helperText,
    operationId,
    params,
  },
  value,
  required,
  disabled,
  readonly,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  label,
}) => {
  const selectProps = {
    fullWidth: true,
    formatLabelCase,
    multiple,
    required,
    label,
    placeholder,
    margin: 'dense',
    variant: 'outlined',
    autoFocus: autofocus,
    disabled: disabled || readonly,
    error: errorSchema.__errors &&
      errorSchema.__errors.length && {
        message: errorSchema.__errors[0],
      },
    helperText,
    id,
    value: typeof value === 'undefined' ? (multiple ? [] : '') : value,
    onBlur:
      onBlur &&
      (event => {
        onBlur(id, processValue(schema, getValue(event, multiple)));
      }),
    onFocus:
      onFocus &&
      (event => {
        onFocus(id, processValue(schema, getValue(event, multiple)));
      }),
    onChange: value => {
      onChange(processValue(schema, value));
    },
    labelKey,
    valueKey,
    imageKey,
  };

  return operationId ? (
    <DynamicSelect
      params={params}
      requestBody={formData}
      operationId={operationId}
      SelectProps={selectProps}
    />
  ) : (
    <EnumSelect schema={schema} SelectProps={selectProps} />
  );
};

export default SelectWidget;
