import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Popper,
  TextField,
  autocompleteClasses,
} from '@mui/material';
import { styled } from '@mui/system';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import ExpandButton from '../../../Button/ExpandButton';
import usePrioritySelectStyles from '../styles/PrioritySelect.styles';
import ModalContentContainer from './ModalContentContainer';
import PrioritySelectionOption from './PrioritySelectionOption';
import TournamentItemWithPriority from './TournamentItemWithPriority';

const SelectionButton = styled(Box)`
    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(148, 162, 180, 0.30);
    background: #F7F7F7;
}
`;

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  width: '100%',
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const PrioritySelectionModalContent = ({
  currentTournament,
  selectedTournament,
  options,
}) => {
  const classes = usePrioritySelectStyles();

  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onChange = useCallback(
    (e, value) => {
      selectedTournament.current = value;
      setExpanded(false);
    },
    [selectedTournament]
  );

  const onSelectOpen = useCallback(
    e => {
      setExpanded(currState => !currState);
      setAnchorEl(e.currentTarget);
    },
    [setExpanded, setAnchorEl]
  );

  const onPopperClose = useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  return (
    <ModalContentContainer>
      <SelectionButton onClick={onSelectOpen}>
        <Box display={'flex'}>
          <TournamentItemWithPriority
            option={currentTournament}
            newOption={selectedTournament.current}
          />
        </Box>
        <ExpandButton expanded={expanded} />
      </SelectionButton>
      {expanded && (
        <Popper
          id="change-priorities-popper"
          open={expanded}
          anchorEl={anchorEl}
          className={clsx(classes.popper, classes.modalPopper)}
        >
          <ClickAwayListener onClickAway={onPopperClose}>
            <Box bgcolor={'white'} borderRadius={'8px'}>
              <Autocomplete
                id={'change-priorities-autocomplete'}
                open
                style={{ position: 'relative' }}
                options={options}
                onChange={onChange}
                popupIcon={null}
                renderInput={params => (
                  <Box padding={'8px 12px'}>
                    <TextField
                      label={'Search Tournament'}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        title:
                          typeof hintText === 'string' ? hintText : undefined,
                      }}
                      variant="outlined"
                    />
                  </Box>
                )}
                getOptionLabel={option => option.name + option.tournamentId}
                renderOption={(props, option, { selected }) => {
                  return (
                    <PrioritySelectionOption
                      selected={
                        option.tournamentId === currentTournament.tournamentId
                      }
                      option={option}
                      selectedOption={currentTournament}
                      {...props}
                    />
                  );
                }}
                PopperComponent={props => (
                  <StyledAutocompletePopper
                    {...props}
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      )}
    </ModalContentContainer>
  );
};

export default PrioritySelectionModalContent;
