import React, { useState, useEffect, useCallback } from 'react';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Button,
  MenuItem,
  Typography,
} from '@mui/material';

import eye from '../../../assets/eye.svg';
import eyeOff from '../../../assets/eye-off.svg';
import emailIcon from '../../../assets/email.svg';
import passwordIcon from '../../../assets/password.svg';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import isEmail from 'validator/lib/isEmail';
import CustomUrlModal from './CustomURLModal';
import { useDialog } from '../../../../../v2/hooks';
import errorIcon from '../../../assets/error.svg';
import infoIcon from '../../../assets/info.svg';
import { ERROR_CODES } from '../../../utils';
import { LoadingButton } from '@mui/lab';
import { SCHEMA_LABELS } from '../../../../../constants/schemaUrls';

const NewLoginForm = ({
  loading = false,
  onSubmit,
  onForgotPassword,
  branches,
  currentBranch,
  currentEnv,
  withFeatureBranches,
  envs,
  onLimitExceeded,
  onChangeEnv,
  onChangeFeatureBranch,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { open, close } = useDialog({ closeOnEsc: true });
  const [error, setError] = useState(null);
  const [isShowForgotPassBlock, setShowForgotPassBlock] = useState(false);
  const [isAutofilled, setAutofilled] = useState(false);

  const handleToggleVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const onForgotPasswordClick = () => {
    // TODO: after backend implementation, remove state and jsx block, then use prop function below,
    // onForgotPassword();

    setShowForgotPassBlock(true);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      // password: 'oO_8&!$Tttt',
      password: '',
      // keepSignIn: false,
    },
    validationSchema: object({
      email: string().test({
        name: 'email',
        message: 'Enter a valid email address',
        test: (value = '') => isEmail(value),
      }),
      password: string().required('Enter your password'),
    }),
    onSubmit: values => {
      setError(null);

      onSubmit(values, errors => {
        const codes = errors ? errors.map(e => e.code) : [];
        if (
          codes.includes(ERROR_CODES.USER_NOT_FOUND) ||
          codes.includes(ERROR_CODES.INVALID_CREDENTIALS)
        ) {
          setError(
            'Incorrect email or password entered. The account will be blocked after 5 unsuccessful attempts.'
          );
        } else if (codes.includes(ERROR_CODES.ATTEMPTS_EXCEEDED)) {
          onLimitExceeded();
        }
      });
    },
  });

  const handleOpenCustomEnvModal = () => {
    open({
      component: CustomUrlModal,
      props: {
        onClose: close,
        onSubmit: url => {
          onChangeEnv(url);
          close();
        },
      },
      options: {
        onClose: close,
      },
    });
  };

  // chrome do not set input values properly when autofills/autocomplete
  // also it is impossible to get autofill values by JS before user interaction (click)
  // therefore just try to detect autofill and enable submit button
  const emailRefCallback = useCallback(
    element => {
      if (element) {
        const intervalId = setInterval(() => {
          const autofillEl = element.querySelector(
            'input[name="email"]:-webkit-autofill'
          );

          if (autofillEl) {
            clearInterval(intervalId);
            setAutofilled(true);
          }
        }, 100);
      }
    },
    [setAutofilled]
  );

  const isLoginButtonDisabled =
    !isAutofilled &&
    (!isEmail(formik.values.email) ||
      (formik.touched.email && Boolean(formik.errors.email)) ||
      !formik.values.password.length ||
      (formik.touched.password && Boolean(formik.errors.password)));

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Stack gap={'20px'} width={'100%'}>
        {withFeatureBranches && (
          <TextField
            value={currentBranch}
            onChange={e => {
              onChangeFeatureBranch(e.target.value);
            }}
            name={'featureBranch'}
            sx={{
              '& .MuiSelect-icon': {
                fontSize: '25px',
                top: 'calc(50% - .5em)',
              },
            }}
            label={'Select feature branch'}
            select
          >
            {['develop', ...branches].map(b => (
              <MenuItem
                key={b}
                sx={{ textTransform: 'lowercase' }}
                value={b.toLowerCase()}
              >
                {b}
              </MenuItem>
            ))}
          </TextField>
        )}
        {SCHEMA_LABELS && (
          <TextField
            value={currentEnv}
            onChange={e => {
              if (!e.target.value) {
                handleOpenCustomEnvModal();
              } else {
                onChangeEnv(e.target.value);
              }
            }}
            name={'env'}
            sx={{
              '& .MuiSelect-icon': {
                fontSize: '25px',
                top: 'calc(50% - .5em)',
              },
            }}
            label={'Environment'}
            select
          >
            <MenuItem
              value={0}
              sx={{
                justifyContent: 'space-between',
                color: '#8496AB',
                borderBottom: '1px solid #e3e6ea',
              }}
            >
              Add custom URL...
            </MenuItem>
            {!envs.find(e => e.value === currentEnv) && (
              <MenuItem sx={{ textTransform: 'lowercase' }} value={currentEnv}>
                {currentEnv}
              </MenuItem>
            )}
            {envs.map(e => (
              <MenuItem
                key={e.value}
                sx={{ textTransform: 'lowercase' }}
                value={e.value}
              >
                {e.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          ref={emailRefCallback}
          value={formik.values.email}
          onChange={v => {
            formik.handleChange(v);
            formik.setFieldTouched('email', false, false);
            setAutofilled(false);
          }}
          name={'email'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={emailIcon} />
              </InputAdornment>
            ),
          }}
          placeholder={'Email'}
          sx={{
            '& .MuiInputBase-root': { paddingRight: '14px' },
            '& .MuiInputAdornment-root': {
              width: '30px',
              '&.MuiInputAdornment-positionStart': {
                color: '#1481ff',
              },
            },
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          value={formik.values.password}
          onChange={v => {
            formik.handleChange(v);
            formik.setFieldTouched('password', false, false);
            setAutofilled(false);
          }}
          name={'password'}
          type={showPassword ? 'text' : 'password'}
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: '14px',
            },
            '& .MuiInputAdornment-root': {
              width: '30px',
              '&.MuiInputAdornment-positionStart': {
                color: '#1481ff',
              },
              '&.MuiInputAdornment-positionEnd': {
                '& .MuiSvgIcon-root': {
                  fontSize: '18px',
                },
              },
            },
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            type: showPassword ? 'text' : 'password',
            startAdornment: (
              <InputAdornment position="start">
                <img src={passwordIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleVisibility}
                  onMouseDown={e => e.preventDefault()}
                >
                  {showPassword ? <img src={eyeOff} /> : <img src={eye} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder={'Password'}
        />
        {error && (
          <Stack
            direction={'row'}
            gap={'8px'}
            padding={'12px'}
            sx={{
              backgroundColor: '#FFE6E8',
              borderRadius: '8px',
            }}
            alignItems={'flex-start'}
          >
            <img src={errorIcon} />
            <Typography
              fontSize={'14px'}
              color={'#820008'}
              lineHeight={'20px'}
              letterSpacing={'0.2px'}
            >
              {error}
            </Typography>
          </Stack>
        )}
        {/*<FormControlLabel*/}
        {/*    control={*/}
        {/*        <Checkbox*/}
        {/*            name={'keepSignIn'}*/}
        {/*            sx={{*/}
        {/*                padding: 0,*/}
        {/*                marginRight: '7px',*/}
        {/*            }}*/}
        {/*            checked={formik.values.keepSignIn}*/}
        {/*            onChange={formik.handleChange}*/}
        {/*        />*/}
        {/*    }*/}
        {/*    label={'Keep me signed in'}*/}
        {/*    sx={{*/}
        {/*        '& .MuiTypography-root': {*/}
        {/*            color: '#899baf !important',*/}
        {/*        },*/}
        {/*    }}*/}
        {/*/>*/}
        <LoadingButton
          loading={loading}
          loadingIndicator="Logging in…"
          variant={'contained'}
          type={'submit'}
          disabled={isLoginButtonDisabled}
        >
          Log in
        </LoadingButton>
        {isShowForgotPassBlock ? (
          <Stack
            direction={'row'}
            gap={'8px'}
            padding={'12px'}
            sx={{
              backgroundColor: '#E8F3FF',
              borderRadius: '8px',
            }}
            alignItems={'flex-start'}
          >
            <img src={infoIcon} />
            <Typography
              fontSize={'12px'}
              color={'#0E57AB'}
              lineHeight={'16px'}
              letterSpacing={'0.2px'}
            >
              If you do not remember your password, please contact your Account
              Manager or Administrator
            </Typography>
          </Stack>
        ) : (
          <Button onClick={onForgotPasswordClick} variant={'text'}>
            Forgot password?
          </Button>
        )}
      </Stack>
    </form>
  );
};

export default NewLoginForm;
