import React from 'react';
import NumberRangeInput from './NumberRangeInput';

const IntegerRangeInput = ({ onChange, ...props }) => {
  return (
    <NumberRangeInput
      {...props}
      onChange={({ range_from, range_to }) => {
        onChange({
          range_from: parseInt(range_from),
          range_to: parseInt(range_to),
        });
      }}
    />
  );
};

export default IntegerRangeInput;
