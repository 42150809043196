import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import CheckBoxActiveIcon from '../../../icons/controls/CheckBoxActiveIcon';
import CheckBoxIcon from '../../../icons/controls/CheckBoxIcon';
import CheckboxIndeterminateIcon from '../../../icons/controls/CheckboxIndeterminateIcon';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: -theme.spacing(1),
  },

  withoutLabel: {
    marginRight: 0,
  },

  checkboxRoot: {
    padding: '0 8px',
  },

  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
}));

const Checkbox = forwardRef(
  (
    {
      name,
      label = '',
      extraClasses = {},
      onChange = () => {},
      value,
      indeterminateMode = false,
      helperText,
      error,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    const indeterminate = typeof value === 'undefined' || value === null;

    return (
      <>
        <FormGroup>
          <FormControlLabel
            className={clsx(classes.formControl, extraClasses.formControl, {
              [classes.withoutLabel]: !label,
            })}
            control={
              <MuiCheckbox
                disableRipple
                name={name}
                ref={ref}
                indeterminate={indeterminate}
                checked={!!value}
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckBoxActiveIcon />}
                indeterminateIcon={<CheckboxIndeterminateIcon />}
                color="primary"
                classes={{
                  root: clsx(classes.checkboxRoot, extraClasses.checkboxRoot, {
                    [classes.disabled]: props?.disabled,
                  }),
                }}
                onChange={(e, v) => {
                  if (!indeterminateMode) {
                    return onChange(v);
                  }

                  if (indeterminate) {
                    return onChange(true);
                  }

                  if (value) {
                    return onChange(false);
                  }

                  return onChange(null);
                }}
                {...props}
              />
            }
            label={label}
          />

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormGroup>
      </>
    );
  }
);

export default Checkbox;
