import React from 'react';
import PropTypes from 'prop-types';
import TabItem from '../../components/TabItem';
import { isEditLink } from '../../utils/schemaHelper';
import pickBy from 'lodash/pickBy';
import ControlledTabbedView from '../../components/ControlledTabbedView';
import DataProvider from '../../providers/DataProvider';
import UiComponentSchemaProvider from '../../providers/UiComponentSchemaProvider';
import SchemaProvider from '../../providers/SchemaProvider';
import FieldList from './FieldList';
import Table from '../Table/Table';
import LinksProvider from '../../providers/LinksProvider';

const getNestedLinks = (links, nestedField) =>
  pickBy(
    links,
    link =>
      isEditLink(link) ||
      Object.keys(link.parameters).find(a =>
        link.parameters[a].startsWith(`$response.body#/items/${nestedField}`)
      )
  );

const NestedFields = ({
  entity,
  nested,
  uiSchema,
  links,
  routes,
  parentPath = [],
  parentEntity,
}) => {
  if (!entity) {
    return null;
  }

  return (
    <ControlledTabbedView nestedTabs>
      {Object.keys(nested)
        .filter(prop => entity[prop])
        .map(prop => {
          return (
            <TabItem key={prop} label={nested[prop].title}>
              {nested[prop].type === 'array' ? (
                <LinksProvider links={getNestedLinks(links, prop)}>
                  <SchemaProvider value={nested[prop]}>
                    <UiComponentSchemaProvider
                      value={uiSchema && uiSchema[prop]}
                    >
                      <DataProvider data={entity[prop]}>
                        <Table nestedKey={prop} />
                      </DataProvider>
                    </UiComponentSchemaProvider>
                  </SchemaProvider>
                </LinksProvider>
              ) : (
                <SchemaProvider value={nested[prop]}>
                  <UiComponentSchemaProvider value={uiSchema && uiSchema[prop]}>
                    <DataProvider data={entity[prop]}>
                      <FieldList
                        routes={routes}
                        links={getNestedLinks(links, prop)}
                        parentPath={[...parentPath, prop]}
                        parentEntity={parentEntity}
                      />
                    </DataProvider>
                  </UiComponentSchemaProvider>
                </SchemaProvider>
              )}
            </TabItem>
          );
        })}
    </ControlledTabbedView>
  );
};

NestedFields.propTypes = {
  nested: PropTypes.object,
  links: PropTypes.object,
  uiSchema: PropTypes.object,
  routes: PropTypes.object,
  entity: PropTypes.object.isRequired,
};

export default NestedFields;
