import React from 'react';
import { SvgIcon } from '@mui/material';

const MoreVertIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 2 10'} {...props}>
      <circle cx="1" cy="1" r="1" fill="currentColor" />
      <circle cx="1" cy="5" r="1" fill="currentColor" />
      <circle cx="1" cy="9" r="1" fill="currentColor" />
    </SvgIcon>
  );
};

export default MoreVertIcon;
