import LinkField from '../../../components/Fields/LinkField/LinkField';

const RetryRequestsNotificationMessage = ({
  itemsSelected,
  taskId,
  requestIds,
}) => {
  if (itemsSelected === 0) {
    throw '';
  }

  return (
    <>
      <div style={{ display: 'flex', gap: 4 }}>
        {itemsSelected > 1 ? (
          <>
            Retry task created for <b>{itemsSelected}</b> requests.
          </>
        ) : (
          <>
            Retry task created for request <b>{requestIds[0]}</b>.
          </>
        )}
      </div>
      <div style={{ display: 'flex', gap: 4 }}>
        Task:
        <LinkField href={`/api/v1/TasksManagerAdmin/tasks/${taskId}/get`}>
          {taskId}
        </LinkField>
      </div>
    </>
  );
};

export default RetryRequestsNotificationMessage;
