import React from 'react';
import { render } from 'react-dom';
import store from './store';
import AppWrapper from './components/AppWrapper';
import smoothscroll from 'smoothscroll-polyfill';
import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';

smoothscroll.polyfill();
dayjs.extend(dayjsUtcPlugin);

if (process.env.REACT_APP_SENTRY_URL) {
  import('@sentry/browser').then(({ init }) => {
    init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  });
}

const renderToNode = Component => {
  render(<Component store={store} />, document.getElementById('app-root'));
};

renderToNode(AppWrapper);

if (module.hot) {
  module.hot.accept('./components/AppWrapper', () => {
    const NextApp = require('./components/AppWrapper').default;

    renderToNode(NextApp);
  });
}
