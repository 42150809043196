import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { identity, pickBy } from 'lodash';
import { useCallback } from 'react';
import {
  initialNodePath,
  useTreeSelectContext,
} from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import UndoChangeButton from '../../../../../Button/UndoChangeButton';
import { SelectItem, SportOption } from '../../../components/';
import { getKeySelector } from '../../../utils/getKeySelector';

const useStyles = makeStyles({
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
  },
});

const MarginSportOption = ({
  templateId,
  nodePath = [],
  selectKey,
  setPath = () => {},
  nodeIndex,
  selected,
  itemValue,
  lineType,
  onUndoButtonClick = () => {},
}) => {
  const classes = useStyles();

  const nodeSelector = {
    sportId: itemValue.sportId,
  };

  const keySelector = getKeySelector({ nodeSelector });

  const {
    setNodePath,
    setData,
    setTreeSelectLoading,
    getMarginTemplateQuery,
    changedMarginItems: { [keySelector]: isMarketsChanged },
  } = useTreeSelectContext();

  const onSelect = useCallback(() => {
    let path = structuredClone(nodePath);
    path.sportId = itemValue.sportId;
    Object.keys(path).forEach((key, index) => {
      if (index > nodeIndex) {
        path[key] = initialNodePath[key];
      }
    });
    setNodePath(currentPath => {
      return {
        ...currentPath,
        [lineType]: path,
      };
    });
    getMarginTemplateQuery({
      templateId,
      path: pickBy(path, identity),
    }).then(data => {
      const newData = withoutEmpty(data.data.data);
      setTreeSelectLoading(false);

      setData(currentData => {
        return {
          ...currentData,
          [lineType]: {
            marketsToNode: newData.marketsToNode,
            sports: currentData[lineType].sports,
            categories: newData.categories,
            markets: newData.markets,
          },
        };
      });
    });
  }, [
    itemValue,
    nodeIndex,
    nodePath,
    lineType,
    setNodePath,
    getMarginTemplateQuery,
    setData,
    templateId,
    setTreeSelectLoading,
  ]);

  return (
    <SelectItem selected={selected}>
      <Box className={classes.optionContainer}>
        <SportOption
          id={itemValue.sportId}
          name={itemValue.name}
          selected={selected}
          onClick={onSelect}
        />
        {isMarketsChanged && (
          <UndoChangeButton
            onClick={() => onUndoButtonClick({ nodeSelector, keySelector })}
          />
        )}
      </Box>
    </SelectItem>
  );
};

export default MarginSportOption;
