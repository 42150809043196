import { useProxyValue } from '../AutocompleteChips/AutocompleteChips';
import { cloneElement } from 'react';

const FancySelectValueProxy = ({
  value,
  onChange,
  options,
  valueKey,
  children,
  multiple = true,
  ...props
}) => {
  const [proxyValue, proxyOnChange] = useProxyValue(
    value,
    onChange,
    options || [],
    valueKey,
    multiple
  );

  return cloneElement(children, {
    value: proxyValue,
    onChange: proxyOnChange,
    multiple,
    ...props,
  });
};

export default FancySelectValueProxy;
