import { gql } from '@apollo/client';

const TOURNAMENT_RULES_QUERY = gql`
  query getTournamentRules($input: TournamentRulesInput!) @api(name: rm) {
    data: getTournamentRules(input: $input) {
      total
      items {
        id
        operator_name
        brand_name
        risk_management_id
        template_id
        live_template_id
        live_delay_k
      }
    }
  }
`;

export default TOURNAMENT_RULES_QUERY;
