import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const IndexPage = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h2" align="center">
      Welcome to Betby Backoffice
    </Typography>
  </div>
);

export default withStyles(styles)(IndexPage);
