import makeStyles from '@mui/styles/makeStyles';
import { memo } from 'react';
import ExpandAllIcon from '../../../icons/controls/ExpandAllIcon';

const useStyles = makeStyles({
  expandButton: {
    cursor: 'pointer',
    padding: '0px 9px',
    color: '#1581FF',
  },
});

const ExpandAllButton = ({ expanded, onClick }) => {
  const classes = useStyles();

  return (
    <div onClick={onClick} className={classes.expandButton}>
      <ExpandAllIcon expanded={expanded} />
    </div>
  );
};

export default memo(ExpandAllButton);
