import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { memo } from 'react';
import ExpandIcon from '../../../icons/controls/ExpandIcon';

const useStyles = makeStyles({
  expandButton: {
    cursor: 'pointer',
    padding: 5,
    color: '#1581FF',
  },
});

const ExpandButton = ({
  expanded,
  white,
  onClick,
  extraClasses = [],
  ...props
}) => {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={clsx(classes.expandButton, ...extraClasses)}
      {...props}
    >
      <ExpandIcon expanded={expanded} white={white} />
    </div>
  );
};

export default memo(ExpandButton);
