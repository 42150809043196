import Typography from '@mui/material/Typography';
import React from 'react';

const SingleValue = ({ innerProps, children }) => (
  <Typography noWrap {...innerProps}>
    {children}
  </Typography>
);

export default SingleValue;
