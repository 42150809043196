import BannerPreviewPlaceholder from './BannerPreviewPlaceholder';
import BannerPreview from './BannerPreview';
import Stack from '@mui/material/Stack/Stack';
import { forwardRef } from 'react';

const AutoEventBannerPreview = forwardRef(
  ({ active, onClick, ...props }, ref) => {
    return (
      <BannerPreview
        title={'AUTO-Events'}
        active={active}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <Stack direction={'row'} spacing={1} mt={'18px'}>
          <BannerPreviewPlaceholder
            style={{
              height: 21,
              width: 44,
              borderRadius: 1,
            }}
          />

          <BannerPreviewPlaceholder
            style={{
              height: 21,
              width: 44,
              borderRadius: 1,
            }}
          />

          <BannerPreviewPlaceholder
            style={{
              height: 21,
              width: 44,
              borderRadius: 1,
            }}
          />
        </Stack>
      </BannerPreview>
    );
  }
);

export default AutoEventBannerPreview;
