import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef } from 'react';

const useStyles = makeStyles({
  bannerPreview: {
    backgroundColor: '#F3F4F7',
    color: '#8496AB',
    borderRadius: 4,
    width: 168,
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    border: '2px solid transparent',
    boxSizing: 'border-box',
  },

  bannerPreviewActive: {
    color: '#1581FF',
    backgroundColor: '#E8F2FF',
    border: '2px solid #1581FF',
    cursor: 'initial',
  },

  bannerTitle: {
    marginTop: 14,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '2px',
  },
  reversed: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    padding: 16,
  },
});

const BannerPreview = forwardRef(
  (
    { title, view, reversed, active = false, className, children, onClick },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div
        data-cy={`banner-view-select-${view}`}
        ref={ref}
        className={clsx(classes.bannerPreview, className, {
          [classes.bannerPreviewActive]: active,
          [classes.reversed]: reversed,
        })}
        onClick={onClick}
      >
        <div className={classes.bannerTitle}>{title}</div>
        {children}
      </div>
    );
  }
);

export default BannerPreview;
