import React, { useCallback, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TimeNumberField from './TimeNumberField';
import { format, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import clsx from 'clsx';

const useStyles = makeStyles({
  timePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  timeDelimiter: {
    margin: '0 4px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    alignItems: 'center',
    letterSpacing: '0.2px',
  },
});

const TimePicker = ({ value, onChange, className, hoursRef, onComplete }) => {
  const classes = useStyles();

  const minutesRef = useRef(null);

  const handleCompleteHours = useCallback(() => {
    if (minutesRef.current) {
      minutesRef.current.focus();
      minutesRef.current.select();
    }
  }, []);

  const handleCompleteMinutes = useCallback(() => {
    onComplete && onComplete();
  }, [onComplete]);

  return (
    <div className={clsx(classes.timePicker, className)}>
      <TimeNumberField
        ref={hoursRef}
        onComplete={handleCompleteHours}
        value={getHours(value)}
        min={0}
        max={23}
        formatedValue={value ? format(value, 'HH') : ''}
        onChange={hours => {
          onChange(setHours(value, hours));
        }}
      />
      <div className={classes.timeDelimiter}>:</div>
      <TimeNumberField
        ref={minutesRef}
        onComplete={handleCompleteMinutes}
        value={getMinutes(value)}
        min={0}
        max={59}
        formatedValue={value ? format(value, 'mm') : ''}
        onChange={minutes => {
          onChange(setMinutes(value, minutes));
        }}
      />
    </div>
  );
};

export default TimePicker;
