import TablePaginationLimit from './TablePaginationLimit';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { useCallback, useMemo, useState, memo } from 'react';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowLeft from '../../../icons/controls/ArrowLeft';
import ArrowRight from '../../../icons/controls/ArrowRight';
import clsx from 'clsx';

const useStyles = makeStyles({
  tablePagination: {
    fontWeight: 500,
  },

  numberLabel: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
  },

  pageNumberField: {
    width: 40,
    marginLeft: 8,
    marginRight: 24,
  },
  pageNumberFieldInput: {
    padding: '12px 2px',
    height: 'initial',
    textAlign: 'center',
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
  },
  paginationLabelDisabled: {
    opacity: 0.5,
  },
});

const useActionButtonStyles = makeStyles(theme => ({
  root: {
    marginLeft: 8,
    padding: 14,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: '14px',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },

    '&$disabled': {
      backgroundColor: alpha(theme.palette.grey.main, 0.1),
      color: theme.palette.grey.main,
    },
  },
  disabled: {},
}));

const TablePagination = ({ limit, setLimit, totalRows, offset, setOffset }) => {
  const classes = useStyles();
  const [goToPageValue, setGoToPageValue] = useState(1);
  const actionButtonClasses = useActionButtonStyles();

  const totalPages = useMemo(
    () => (totalRows ? Math.ceil(totalRows / limit) : 0),
    [totalRows, limit]
  );

  const currentPage = useMemo(
    () => Math.floor(offset / limit),
    [offset, limit]
  );

  const onChangePage = useCallback(
    page => {
      setOffset(page * limit);
    },
    [limit, setOffset]
  );

  const handleBackButtonClick = useCallback(() => {
    onChangePage(currentPage - 1);
  }, [currentPage, onChangePage]);

  const handleNextButtonClick = useCallback(() => {
    onChangePage(currentPage + 1);
  }, [currentPage, onChangePage]);

  return (
    <Stack
      direction={'row'}
      mt={2}
      justifyContent={'space-between'}
      className={classes.tablePagination}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={4}>
        {!!totalRows && (
          <Box className={classes.totalRowsLabel}>
            Total rows: <span className={classes.numberLabel}>{totalRows}</span>
          </Box>
        )}
        <Box>
          <TablePaginationLimit value={limit} onChange={setLimit} />
        </Box>
      </Stack>

      {setOffset && (
        <Stack alignItems={'center'} direction={'row'}>
          <Stack direction={'row'} alignItems={'center'}>
            <span
              className={clsx({
                [classes.paginationLabelDisabled]: !totalRows,
              })}
            >
              Go to page
            </span>
            <TextField
              disabled={!totalRows}
              classes={{
                root: classes.pageNumberField,
              }}
              InputProps={{
                classes: {
                  input: classes.pageNumberFieldInput,
                },
                onKeyDown: e => {
                  if (e.key === 'Enter') {
                    setGoToPageValue(1);
                    const page = (parseInt(e.target.value) || 1) - 1;

                    if (page >= totalPages) {
                      return onChangePage(totalPages - 1);
                    }

                    if (page < 0) {
                      return onChangePage(0);
                    }

                    onChangePage(page);
                  }
                },
              }}
              onChange={(e, value) => {
                setGoToPageValue(value);
              }}
              value={goToPageValue}
              variant={'outlined'}
            />

            <span
              className={clsx({
                [classes.paginationLabelDisabled]: !totalRows,
              })}
            >
              <span className={classes.numberLabel}>
                {totalRows ? currentPage + 1 : 0}
              </span>{' '}
              of <span className={classes.numberLabel}>{totalPages}</span>
            </span>
            <IconButton
              classes={actionButtonClasses}
              onClick={handleBackButtonClick}
              disabled={currentPage <= 0}
              color="inherit"
              size="large"
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              classes={actionButtonClasses}
              onClick={handleNextButtonClick}
              disabled={currentPage >= totalPages - 1}
              color="inherit"
              size="large"
            >
              <ArrowRight />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(TablePagination);
