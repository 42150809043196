import useCorrectDateEffect from '../../hooks/useCorrectDateEffect';
//
// const getViews = formatDate => {
//   if (!formatDate || formatDate.endsWith('%d')) {
//     return ['year', 'date'];
//   }
//
//   if (formatDate.endsWith('%Y')) {
//     return ['year'];
//   }
//
//   return ['year', 'month'];
// };

const DateInput = ({
  id,
  value,
  onChange,
  formatDate,
  className,
  disabled,
  label,
  error,
  helperText,
  fullWidth,
  InputProps,
  variant,
  margin,
}) => {
  useCorrectDateEffect(value, onChange);

  return null;
  //
  // return (
  //   <DatePicker
  //     autoOk
  //     clearable
  //     views={getViews(formatDate)}
  //     margin={margin}
  //     value={getValueForTimePicker(value)}
  //     onChange={date => onChange(getValueFromTimePicker(date, formatDate))}
  //     className={className}
  //     disabled={disabled}
  //     label={label}
  //     error={error}
  //     helperText={helperText}
  //     fullWidth={fullWidth}
  //     InputProps={InputProps}
  //     variant={variant}
  //     inputVariant={'outlined'}
  //     id={id}
  //   />
  // );
};

export default DateInput;
