import makeStyles from '@mui/styles/makeStyles';
import { animated, useSpring, useTransition } from 'react-spring';
import { useCallback, useMemo, useState, memo, forwardRef } from 'react';
import { isEqual } from 'lodash';
import usePrevious from '../../../hooks/usePrevious';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';

const useStyles = makeStyles({
  tableActionCards: {
    position: 'relative',
    willChange: 'height',
    overflow: 'visible',
  },

  animatedCard: {
    position: 'absolute',
    padding: 2,
    boxSizing: 'border-box',
    willChange: 'transform, left, box-shadow',
  },

  inlineCard: {
    padding: 0,
  },
});

const SpanMapXs = [
  {
    test: [2, 1, 1],
    map: [4, 2, 2],
  },
];

const SpanMap = [
  {
    test: [2],
    map: [4],
  },
  {
    test: [1],
    map: [4],
  },
  {
    test: [1, 4],
    map: [4, 4],
  },
  {
    test: [2, 4],
    map: [4, 4],
  },
  {
    test: [4, 1],
    map: [4, 4],
  },
  {
    test: [4, 2],
    map: [4, 4],
  },
  {
    test: [2, 1],
    map: [2, 2],
  },
  {
    test: [1, 2],
    map: [2, 2],
  },
  {
    test: [1, 1],
    map: [2, 2],
  },
  {
    test: [1, 1, 4],
    map: [2, 2, 4],
  },
  {
    test: [2, 1, 4],
    map: [2, 2, 4],
  },
  {
    test: [1, 4, 4],
    map: [4, 4, 4],
  },
  {
    test: [2, 4, 1],
    map: [4, 4, 4],
  },
  {
    test: [2, 4, 4],
    map: [4, 4, 4],
  },
];

const cssMap = [
  {
    test: [4],
    mapX: ['0%'],
    row: [0],
  },
  {
    test: [2, 2],
    mapX: ['0%', '50%'],
    row: [0, 0],
  },
  {
    test: [4, 4],
    mapX: ['0%', '0%'],
    row: [0, 1],
  },
  {
    test: [2, 1, 1],
    mapX: ['0%', '50%', '75%'],
    row: [0, 0, 0],
  },
  {
    test: [4, 2, 2],
    mapX: ['0%', '50%', '75%'],
    row: [0, 0, 0],
  },
  {
    test: [2, 2, 4],
    mapX: ['0%', '50%', '0%'],
    row: [0, 0, 1],
  },
  {
    test: [4, 4, 4],
    mapX: ['0%', '0%', '0%'],
    row: [0, 1, 2],
  },
  {
    test: [2, 2, 1, 1],
    mapX: ['0%', '50%', '0%', '25%'],
    row: [0, 0, 1, 1],
  },
  {
    test: [2, 2, 1, 4],
    mapX: ['0%', '50%', '0%', '0%'],
    row: [0, 0, 1, 2],
  },
  {
    test: [2, 2, 4, 1],
    mapX: ['0%', '50%', '0%', '0%'],
    row: [0, 0, 1, 2],
  },
  {
    test: [2, 2, 4, 4],
    mapX: ['0%', '50%', '0%', '0%'],
    row: [0, 0, 1, 2],
  },
];

const spanToWidth = {
  1: '25%',
  2: '50%',
  3: '75%',
  4: '100%',
};

const mapActionsSpan = (actions, isMobile = false) => {
  const spans = actions.map(action => action.span);
  const spanMap = isMobile ? [...SpanMapXs, ...SpanMap] : SpanMap;

  const mapped = spanMap.find(span => isEqual(span.test, spans));

  const mappedCss =
    cssMap.find(css => isEqual(css.test, mapped ? mapped.map : spans)) ||
    cssMap[0];

  return actions.map((action, index) => {
    return {
      ...action,
      span: (mapped ? mapped.map : spans)[index],
      x: mappedCss.mapX[index],
      row: mappedCss.row[index],
    };
  });
};

const getActionRowY = (actions, row) => {
  const heightByRow = {};

  for (let action of actions) {
    if (action.row < row) {
      if (!heightByRow[action.row] || action.height > heightByRow[action.row]) {
        heightByRow[action.row] = action.height;
      }
    } else {
      return Object.values(heightByRow).reduce((acc, h) => acc + h, row * 4);
    }
  }
};

const TableActionCards = forwardRef(
  (
    {
      animated: withAnimation = false,
      actions = [],
      inline = false,
      large = false,
    },
    ref
  ) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [itemsHeight, setItemsHeight] = useState({});
    const classes = useStyles();

    actions = actions.filter(item => !!item);

    const items = useMemo(() => {
      const mappedActions = mapActionsSpan(actions, isMobile).map(action => {
        return {
          height: itemsHeight[action.key] || 74,
          ...action,
        };
      });

      return mappedActions.map(action => {
        return {
          ...action,
          width: spanToWidth[action.span],
          y: getActionRowY(mappedActions, action.row),
        };
      });
    }, [actions, itemsHeight, isMobile]);

    const prevItems = usePrevious(items);

    const containerHeight = useMemo(() => {
      const latestRowItem = items[items.length - 1];

      if (latestRowItem) {
        const itemHeight =
          latestRowItem.height || itemsHeight[latestRowItem.key];

        if (itemHeight) {
          return latestRowItem.y + itemHeight;
        }
      }

      return 0;
    }, [items, itemsHeight]);

    const containerStyle = useSpring({
      padding: inline && 0,
      height: containerHeight,
      immediate: true,
      config: withAnimation
        ? { mass: 1, tension: 120, friction: 16 }
        : { duration: 0 },
    });

    const transitions = useTransition(items, {
      key: item => item.key,
      from: ({ x, y, width }) => ({
        left: x,
        y,
        width,
        opacity: 0,
      }),
      enter: ({ x, y, width }) => ({
        left: x,
        y,
        width,
        opacity: 1,
      }),
      update:
        ({ x, y, width, span, key }) =>
        async next => {
          const previousItem = prevItems.find(item => item.key === key);
          const moving = x !== previousItem.x || y !== previousItem.y;

          if (moving) {
            if (withAnimation) {
              next({
                boxShadow: '0px 0px 16px 4px rgba(0,0,0,0.05)',
              }).catch(() => {});
            }
          }

          if (span < previousItem.span) {
            next({ width }).catch(() => {});
            await next({ left: x, y });
          } else {
            next({ left: x, y }).catch(() => {});
            await next({ width });
          }

          if (withAnimation) {
            next({
              boxShadow: '0px 0px 16px 4px rgba(0,0,0,0)',
            }).catch(() => {});
          }
        },
      leave: { height: 0, opacity: 0 },
      config: withAnimation
        ? { mass: 1, tension: 170, friction: 22 }
        : { duration: 0 },
      trail: 75,
      immediate: true,
    });

    const onChangeItemHeight = useCallback(
      (key, height) => {
        if (itemsHeight[key] !== height) {
          setItemsHeight(state => ({ ...state, [key]: height }));
        }
      },
      [itemsHeight]
    );

    return (
      <animated.div
        className={classes.tableActionCards}
        style={containerStyle}
        ref={ref}
      >
        {transitions(({ boxShadow, ...style }, item, t, zIndex) => {
          return (
            <animated.div
              style={{ ...style, zIndex }}
              key={item.key}
              className={clsx(
                classes.animatedCard,
                inline && classes.inlineCard
              )}
            >
              {item.render(
                {
                  boxShadow,
                },
                value => onChangeItemHeight(item.key, value),
                inline,
                large
              )}
            </animated.div>
          );
        })}
      </animated.div>
    );
  }
);

export default memo(TableActionCards);
