import React, { forwardRef, useCallback, useContext } from 'react';
import { TableCell, TableHead as MuiTableHead, TableRow } from '@mui/material';
import InlineTableDropdownHead from './InlineTableDropdownHead';
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import { DataContext } from '../../providers/DataProvider';
import useQueryByPath from '../../hooks/useQueryByPath';
import TableSortIcon from '../../icons/controls/TableSortIcon';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import TableConfigMenu from './TableConfigMenu';
import { useSchema } from '../../providers/SchemaProvider';
import { getColumnsSchema } from './TableUtils';
import { usePresets } from '../../providers/PresetsProvider';

const useStyles = makeStyles(theme => ({
  emptyTableHeadRoot: {
    display: 'block',

    '& $tableHeadRowRoot': {
      position: 'relative',
      display: 'block',

      '&:after': {
        content: '""',
        right: 0,
        position: 'absolute',
        height: 38,
        width: 30,
        top: 4,
        background: 'linear-gradient(90deg, transparent, #F7F7F7)',
      },
    },
  },
  stickyTableHeadRoot: {
    display: 'block',
    background: '#F7F7F7',
    visibility: 'hidden',
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rowActionsCell: {
    minWidth: 30,
    maxWidth: 30,
    right: 0,
    position: 'sticky',
    background: 'linear-gradient(90deg, rgba(247, 247, 247, 0.90), #F7F7F7)',
    boxShadow: '-6px 0px 6px 0px rgba(247, 247, 247, 0.90)',
  },
  tableHeadRowRoot: {},

  tableSortLabelRoot: {
    display: 'block',
  },

  tableHeadCell: {
    zIndex: 4,
  },
}));

const TableHeadTooltip = ({ tooltip, children }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip} enterDelay={300}>
        {children}
      </Tooltip>
    );
  }

  return children;
};

const revertDirection = direction => {
  if (direction === 'DESC') {
    return 'ASC';
  }

  return 'DESC';
};

const TableHead = forwardRef(
  (
    {
      style = {},
      columns,
      hasNested,
      isSortable,
      isEmpty,
      entity,
      sticky,
      isSticked,
      cellStyle = {},
    },
    ref
  ) => {
    const classes = useStyles();
    const { path, loading } = useContext(DataContext);
    const { query, setQuery } = useQueryByPath(path);
    const { activePreset } = usePresets();
    const schema = useSchema();
    const { columnsSchema } = getColumnsSchema(schema);

    const sortBy = query.order_by;
    const sortDirection = query.order_by_asc_desc;

    const setOrder = useCallback(
      order_by => {
        const orderBy = order_by;
        const orderByDirection =
          sortBy === order_by ? revertDirection(sortDirection) : 'DESC';

        setQuery({
          ...query,
          order_by: orderBy,
          order_by_asc_desc: orderByDirection,
        });
      },
      [query, setQuery, sortDirection, sortBy]
    );

    return (
      <MuiTableHead
        style={style}
        ref={ref}
        classes={{
          root: clsx({
            [classes.emptyTableHeadRoot]: isEmpty,
            [classes.stickyTableHeadRoot]: sticky,
          }),
        }}
      >
        <TableRow
          classes={{
            root: classes.tableHeadRowRoot,
          }}
        >
          {hasNested && (
            <TableCell
              className={classes.tableHeadCell}
              style={{
                ...cellStyle,
              }}
            />
          )}
          {columns.map((column, index) => {
            const { uiSchema = {} } = column;

            if (column.inlineTableDropdown) {
              return (
                <InlineTableDropdownHead
                  key={index}
                  columns={column.inlineTableDropdown}
                />
              );
            }
            return (
              <TableCell
                className={classes.tableHeadCell}
                align={column.align}
                key={column.name}
                style={{
                  ...column.css,
                  ...cellStyle,
                }}
              >
                <TableHeadTooltip tooltip={uiSchema.tooltip}>
                  {isSortable ? (
                    <TableSortLabel
                      classes={{
                        root: classes.tableSortLabelRoot,
                      }}
                      active={sortBy === column.name}
                      direction={sortDirection && sortDirection.toLowerCase()}
                      IconComponent={TableSortIcon}
                      onClick={() => {
                        if (!loading) {
                          setOrder(column.name);
                        }
                      }}
                    >
                      {column.title}
                    </TableSortLabel>
                  ) : (
                    <div>{column.title}</div>
                  )}
                </TableHeadTooltip>
              </TableCell>
            );
          })}

          <TableCell
            align={'right'}
            className={clsx(classes.tableHeadCell, classes.rowActionsCell)}
            style={{
              ...cellStyle,
            }}
          >
            {!_isEmpty(columnsSchema) && activePreset && (
              <TableConfigMenu entity={entity} />
            )}
          </TableCell>
        </TableRow>
      </MuiTableHead>
    );
  }
);

export default TableHead;
