import { useContext } from 'react';
import { useApiHost } from '../providers/ApiHostProvider';
import { DataContext } from '../providers/DataProvider';
import { useApiPut } from '../v2/hooks/useApi';

export const useTableApplyRequestUrl = () => {
  const { url } = useContext(DataContext);
  const { apiHost } = useApiHost();

  const queryUrl = new URL(url, apiHost);

  const applyRequest = useApiPut();

  return { pathname: queryUrl.pathname, applyRequest };
};
