import React, { useContext, useEffect, useState } from 'react';
import { UiOptionsContext } from './UiOptionsProvider';
import FormattedValueText from './FormattedValueText';
import * as DateFns from 'date-fns';

const ts = () => Math.round(new Date().getTime() / 1000);

const formatDuration = seconds => {
  const helperDate = DateFns.addSeconds(new Date(0), seconds);
  return DateFns.format(helperDate, 'mm:ss');
};

const CountdownField = () => {
  const { value } = useContext(UiOptionsContext);

  const { label } = value;

  const { highlightLimit } = label;

  const [currentValue, setCurrentValue] = useState(value.data - ts());

  useEffect(() => {
    const tick = setInterval(() => setCurrentValue(value.data - ts()), 1000);
    return () => clearInterval(tick);
  }, [value.data]);

  const limitExceeded = highlightLimit && currentValue < highlightLimit;

  const formattedValue = formatDuration(currentValue);

  return (
    <FormattedValueText color={limitExceeded ? 'red' : 'black'}>
      {formattedValue}
    </FormattedValueText>
  );
};

export default CountdownField;
