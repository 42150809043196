import Select from '../Select/Select';

export const getBrandIds = brands => {
  return brands.map(value => value.brand.id);
};

const getOptions = items => {
  return items.map(item => ({
    label: item.brand.name,
    value: item.brand.id,
    sub: {
      templates: [
        // {
        //   label: 'Margin',
        //   value: item.margin ? item.margin.name : 'Default',
        // },
        {
          label: 'Coverage',
          value: item.coverage ? item.coverage.name : 'Default',
        },
        {
          label: 'Sources',
          value: item.coverage ? item.sources.name : 'Default',
        },
      ],
      settings: item.settings,
    },
  }));
};

const getExistedValue = (value, items) => {
  return value.filter(element => getBrandIds(items).includes(element));
};

const BrandSelectWithDetails = ({
  onChange,
  value,
  initialValue,
  multiple = false,
  label = 'Select',
  disabled,
  disabledOptions = [],
  required = true,
  items,
}) => {
  return (
    <Select
      onChange={onChange}
      value={value && getExistedValue(value, items)}
      initialValue={initialValue}
      multiple={multiple}
      label={label}
      disabled={disabled}
      disabledOptions={disabledOptions}
      required={required}
      withDetails
      withDivider
      preventClearOnBackspace
      options={items && getOptions(items)}
    />
  );
};

export default BrandSelectWithDetails;
