import { gql } from '@apollo/client';

const getAutocreatableTournamentsFilters = gql`
  query getAutocreatableTournamentsFilters {
    getAutocreatableTournamentsFilters {
      sports {
        sport {
          id
          name
        }
        count
      }
      providers
    }
  }
`;

export default getAutocreatableTournamentsFilters;
