import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AutocompleteWidget from '../../../../components/DynamicForm/widgets/AutocompleteWidget';
import DraggableList from './DraggableList';
import RemoveIcon from '../../../../icons/controls/RemoveIcon';

const useStyles = makeStyles(theme => ({
  listTableRoot: {
    position: 'relative',
    background: '#F7F7F7',
    borderRadius: 6,
    padding: 8,
    display: 'flex',
  },
  tableDroppable: {
    display: 'grid',
    width: '100%',
  },
  dropzonePlaceholder: {
    position: 'absolute',
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
    borderRadius: 4,
  },
  listNumbers: {
    display: 'grid',
  },
  listNumberRoot: {
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.2px',
    padding: '0 16px 0 6px',
    width: 29,
  },
  autocomplete: {
    maxWidth: 400,
  },
  removeButton: {
    padding: '11px 16px',
    height: 16,
    '&>svg': {
      fontSize: '16px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

// const IsDraggingContext = React.createContext(false);

const OrderCheckListWidget = ({
  items,
  formDataArray,
  onAdd,
  uiSchema = {},
}) => {
  const classes = useStyles();

  const { 'ui:options': uiOptions = {} } = uiSchema;
  const { value_key } = uiOptions;

  return (
    <div>
      <AutocompleteWidget
        onChange={newItem => {
          if (
            newItem &&
            !formDataArray.find(item => item.value === newItem.value)
          ) {
            onAdd(newItem);
          }
        }}
        value={null}
        resetOnChange={true}
        className={classes.autocomplete}
        options={uiOptions}
      />

      <DraggableList
        getId={item => {
          return value_key
            ? item.children.props.formData[value_key].toString()
            : item.key;
        }}
        renderActions={item => {
          return (
            <div
              className={classes.removeButton}
              onClick={() => {
                item.onDropIndexClick(item.index);
              }}
            >
              <RemoveIcon />
            </div>
          );
        }}
        formDataArray={formDataArray}
        onAdd={onAdd}
        items={items}
        uiSchema={uiSchema}
      />
    </div>
  );
};

export default OrderCheckListWidget;
