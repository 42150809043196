import { styled } from '@mui/system';

const FieldsGroupContainer = styled('div')`
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 4px;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`;

const FieldsGroup = ({ children }) => {
  return <FieldsGroupContainer>{children}</FieldsGroupContainer>;
};

export default FieldsGroup;
