import React, { useCallback, useState } from 'react';
import useWebSocket from '../hooks/useWebSocket';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { dynamicUpdateData } from '../actions/dataActions';
import { getActiveSchemaUrl } from '../reducers/schemaUrls';
import { getUserToken } from '../reducers/users';
import useBatched from '../hooks/useBatched';
import { useNotificationPopup } from './NotificationPopupProvider';
import { compileSchema } from '../uiComponents/Field/FieldUtils';
import get from 'lodash/get';
import { v4 as uuid } from 'uuid';
import { useRawSchema } from './RawSchemaProvider';
import { refreshTokenCmd } from '../actions/cmdActions';
import withRouter from '../utils/withRouter';

export const WebSocketContext = React.createContext({});

const WebSocketProvider = ({ children, dynamicUpdateData, token }) => {
  const { loading: schemaLoading } = useRawSchema();
  const { webSocketHost } = useRawSchema();
  const [socketToken, setSocketToken] = useState(token);
  const [prevWebSocketHost, setPrevWebSocketHost] = useState(webSocketHost);
  const onChannelMessage = useBatched(dynamicUpdateData, 1000);
  const dispatch = useDispatch();

  const notificationPopup = useNotificationPopup();

  if (prevWebSocketHost !== webSocketHost) {
    setSocketToken(token);
    setPrevWebSocketHost(webSocketHost);
  }

  if ((socketToken && !token) || (!socketToken && token)) {
    setSocketToken(token);
  }

  const notificationPopupOpen = notificationPopup.open;

  const onUiComponent = useCallback(
    (data, schema, uiSchema, path) => {
      if (uiSchema['ui:component'] === 'NotificationPopup') {
        const responseBody = data.payload || {};
        const compiled = compileSchema(
          uiSchema['ui:options'],
          responseBody,
          {}
        );

        notificationPopupOpen({
          key: uuid(),
          title: get(compiled, 'title.text', compiled.title || 'Notification'),
          message: get(compiled, 'description.text', compiled.description),
        });
      }
    },
    [notificationPopupOpen]
  );

  const onCmdMessage = useCallback(
    (cmd, payload) => {
      if (cmd === 'REFRESH_TOKEN') {
        dispatch(refreshTokenCmd(payload.token));
      }
    },
    [dispatch]
  );

  let socketUrl =
    webSocketHost && socketToken && `${webSocketHost}?token=${socketToken}`;

  if (!socketUrl && !schemaLoading) {
    socketUrl = undefined;
  }

  const socket = useWebSocket(
    socketUrl,
    onChannelMessage,
    onCmdMessage,
    onUiComponent
  );

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default compose(
  withRouter,
  connect(state => ({
    token: getUserToken(state),
    schemaUrl: getActiveSchemaUrl(state),
  })),
  connect(null, {
    dynamicUpdateData,
  })
)(WebSocketProvider);
