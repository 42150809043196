import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApiGet } from '../../hooks/useApi';

export const OperatorContext = createContext(null);

export const useIsOperator = () => {
  const rights = useContext(OperatorContext);

  return rights.isOperator;
};

export const useIsAdmin = () => {
  const rights = useContext(OperatorContext);

  return rights.isAdmin;
};

export const useIsRMAllowed = () => {
  const rights = useContext(OperatorContext);

  return rights.isRMAllowed;
};

const OperatorProvider = ({ children }) => {
  const [isOperator, setIsOperator] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [isRMAllowed, setIsRMAllowed] = useState(null);
  const params = useMemo(() => ({}), []);
  const getData = useApiGet('/api/v1/get_user_info', params, false);

  useEffect(() => {
    getData()
      .then(data => {
        setIsOperator(data && data.user_scope === 'operator');
        setIsAdmin(data && data.user_scope === 'admin');
        setIsRMAllowed(data && data.is_rm_allowed);
      })
      .catch(() => {
        setIsOperator(false);
        setIsAdmin(false);
        setIsRMAllowed(false);
      });
  }, [getData]);

  if (isOperator === null) {
    return null;
  }

  return (
    <OperatorContext.Provider value={{ isOperator, isAdmin, isRMAllowed }}>
      {children}
    </OperatorContext.Provider>
  );
};

export default OperatorProvider;
