import { useCallback } from 'react';
import DateField from '../../../components/Fields/DateField/DateField';
import EmptyField from '../../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../../components/Fields/LinkField/LinkField';
import StringField from '../../../components/Fields/StringField/StringField';
import Table from '../../../components/Table/Table';
import useTableColumnsReorder from '../../../hooks/useTableColumnsReorder';
import LineSettingsExpanded from './LineSettingsExpanded';

export const COLUMN = {
  name: 'name',
  brands: 'brands',
  updated_by: 'updated_by',
  created: 'created',
  updated: 'updated',
  //margin value temporary mocked
  // margin: 'margin',
  sources: 'sources',
  coverage: 'coverage',
};

export const columnsWidthConfig = {
  [COLUMN.name]: 0.75,
  [COLUMN.brands]: 0.5,
  [COLUMN.updated_by]: 1,
  [COLUMN.created]: 0.5,
  [COLUMN.updated]: 1,
  // [COLUMN.margin]: 1.5,
  [COLUMN.sources]: 1.25,
  [COLUMN.coverage]: 1.25,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.name,
  COLUMN.brands,
  COLUMN.updated_by,
  COLUMN.created,
  COLUMN.updated,
  // COLUMN.margin,
  COLUMN.sources,
  COLUMN.coverage,
];

export const initialToggledColumns = [
  COLUMN.name,
  COLUMN.brands,
  COLUMN.updated_by,
  COLUMN.created,
  COLUMN.updated,
  // COLUMN.margin,
  COLUMN.sources,
  COLUMN.coverage,
];

const LineSettingsTable = ({ data, loading, error, rowActions }) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Name',
      key: COLUMN.name,
      render: rowData => {
        return rowData.name ? (
          <StringField title={rowData.name}>
            <LinkField
              href={`/line-settings/line/edit/${rowData.id}`}
              state={rowData}
            >
              {rowData.name}
            </LinkField>
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Brands',
      key: COLUMN.brands,
      render: ({ brandsCount }) => {
        return brandsCount ? (
          <StringField title={brandsCount}>{brandsCount}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Updated by',
      key: COLUMN.updated_by,
      render: ({ updatedBy }) => {
        return updatedBy ? (
          <StringField title={updatedBy}>{updatedBy}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Created',
      key: COLUMN.created,
      render: ({ created }) => {
        return created ? (
          <StringField>
            <DateField date={created} format={'YYYY.MM.DD'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Updated',
      key: COLUMN.updated,
      render: ({ updated }) => {
        return updated ? (
          <StringField>
            <DateField date={updated} format={'YYYY.MM.DD, HH:mm:ss'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    //margin value temporary mocked
    // {
    //   label: 'Margin',
    //   key: COLUMN.margin,
    //   render: ({ margin }) => {
    //     return margin ? (
    //       <StringField>
    //         <LinkField href={`/line-settings/template/edit/${margin.id}`}>
    //           {margin.name}
    //         </LinkField>
    //       </StringField>
    //     ) : (
    //       <EmptyField />
    //     );
    //   },
    // },
    {
      label: 'Sources',
      key: COLUMN.sources,
      render: ({ sources }) => {
        return sources ? (
          <StringField>
            <LinkField href={`/line-settings/template/edit/${sources.id}`}>
              {sources.name}
            </LinkField>
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Coverage',
      key: COLUMN.coverage,
      render: ({ coverage }) => {
        return coverage ? (
          <StringField>
            <LinkField href={`/line-settings/template/edit/${coverage.id}`}>
              {coverage.name}
            </LinkField>
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      return (
        <LineSettingsExpanded
          key={rowData.id}
          lineSettingsId={rowData.id}
          rowData={rowData}
        />
      );
    },
    []
  );

  return (
    <Table
      expandable
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      renderExpandedRow={renderExpandedRow}
      rowActions={rowActions}
      error={error}
    />
  );
};

export default LineSettingsTable;
