import { useMemo } from 'react';
import { ACCEPTED_BETS, TEST_PLAYER } from '../BetHistory';

const useDefaultBetHistoryFiltersValues = ({ eventId }) => {
  const defaultFiltersValues = useMemo(() => {
    return {
      testPlayers: TEST_PLAYER.EXCLUDE,
      filterBetId: [],
      filterPlayerId: [],
      filterExtPlayerId: [],
      filterOperatorId: [],
      filterOperatorBrandId: [],
      filterUsername: null,
      filterOutcomeName: [],
      filterRiskManagement: [],
      filterCurrency: [],
      filterBetStatus: [],
      filterBonus: [],
      filterLivePrematch: [],
      acceptedBets: [ACCEPTED_BETS.YES],
      filterBetType: [],
      filterEventSource: [],
      rBetDate: {
        rangeFrom: null,
        rangeTo: null,
      },
      rSettleDate: {
        rangeFrom: null,
        rangeTo: null,
      },
      rStakeEur: {
        rangeFrom: null,
        rangeTo: null,
      },
      rStakeCur: {
        rangeFrom: null,
        rangeTo: null,
      },
      rWonEur: {
        rangeFrom: null,
        rangeTo: null,
      },
      rOdd: {
        rangeFrom: null,
        rangeTo: null,
      },
      rCcf: {
        rangeFrom: null,
        rangeTo: null,
      },
      rDiffBetSettle: {
        rangeFrom: null,
        rangeTo: null,
      },
      rDiffBetStart: {
        rangeFrom: null,
        rangeTo: null,
      },
      filterOutcomeId: [],
      filterMarketId: [],
      filterBonusId: null,
      filterForced: null,
      filterHasRollback: null,
      filterUseMts: null,
      filterEventId: [eventId],
      filterSportId: [],
      filterCategoryName: [],
      filterTournamentName: [],
      filterEventName: [],
    };
  }, [eventId]);

  return { defaultFiltersValues };
};

export default useDefaultBetHistoryFiltersValues;
