import { useContext } from 'react';
import { UiOptionsContext } from '../UiOptionsProvider';
import StringField from '../StringField/StringField';
import EmptyFieldPlaceholder from '../EmptyFieldPlaceholder';

const MarginField = () => {
  const { value } = useContext(UiOptionsContext);

  return value.data ? <StringField /> : <EmptyFieldPlaceholder />;
};

export default MarginField;
