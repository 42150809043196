import * as React from 'react';

export const LinksContext = React.createContext({});

const LinksProvider = ({ children, links }) => {
  return (
    <LinksContext.Provider value={links || {}}>
      {children}
    </LinksContext.Provider>
  );
};

export default LinksProvider;
