import { useApolloClient, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React, { useCallback, useMemo } from 'react';
import RangeDateInput from '../../../../components/inputs/RangeDateInput';
import BrandSelect from '../../../components/Inputs/BrandSelect/BrandSelect';
import NumberField from '../../../components/Inputs/NumberField';
import OperatorSelect from '../../../components/Inputs/OperatorSelect/OperatorSelect';
import Select from '../../../components/Inputs/Select/Select';
import TextField from '../../../components/Inputs/TextField';
import Page from '../../../components/Page/Page';
import TableActionCards from '../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../components/TablePagination/TablePagination';
import getBannersList from '../../../gql/Promo/queries/getBannersList';
import useCreateActionCard from '../../../hooks/useCreateActionCard';
import useDebounced from '../../../hooks/useDebounced';
import usePresetFilters from '../../../hooks/usePresetFilters';
import usePresetsActionCard from '../../../hooks/usePresetsActionCard';
import useTablePresetPagination from '../../../hooks/useTablePresetPagination';
import useTableQueryVariables from '../../../hooks/useTableQueryVariables';
import { useIsOperator } from '../../../providers/OperatorProvider';
import { usePresets } from '../../../providers/PresetsProvider';
import {
  ActivatePeriod,
  BannerState,
  BannerStateLabel,
  BannerType,
  BannerView,
  BannerViewLabelMap,
} from '../components/BannerForm/BannerValidationSchema';
import BannerTypeSelect from '../components/BannerTypeSelect/BannerTypeSelect';
import PromoBannersTable from './components/PromoBannersTable';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../components/ErrorWidget';

const PromoBannersListPage = ({}) => {
  const isOperator = useIsOperator();
  const presetsActionCard = usePresetsActionCard({
    tableName: tableNames.PromoBanners,
    spanMin: 1,
  });
  const {
    loading: presetsLoading,
    activePreset,
    updatePreset,
    error,
  } = usePresets();
  const createActionCard = useCreateActionCard({ link: '/banners/create' });
  const { limit, setLimit, offset, setOffset } = useTablePresetPagination({
    tableName: tableNames.PromoBanners,
  });
  const client = useApolloClient();

  const onResubmit = useCallback(() => {
    client.refetchQueries({
      include: [getBannersList],
    });
  }, [client]);

  const onChangeColumnsWidth = useCallback(
    columnsWidth => {
      if (activePreset) {
        updatePreset(activePreset.id, {
          ...activePreset,
          data: {
            ...activePreset.data,
            columns_width: columnsWidth,
          },
        });
      }
    },
    [activePreset, updatePreset]
  );

  const debouncedOnResizeColumns = useDebounced(onChangeColumnsWidth, 200);

  const { filtersCard, filters } = usePresetFilters({
    tableName: tableNames.PromoBanners,
    resetOffset: setOffset,
    onResubmit,
    filters: [
      {
        name: 'id',
        label: 'Banner Id',
        fullWidth: false,
        component: <NumberField useStringValue />,
        toggled: true,
      },
      {
        name: 'name',
        label: 'Name',
        fullWidth: false,
        component: <TextField />,
        toggled: true,
      },
      {
        name: 'banner_type',
        label: 'Banner Type',
        fullWidth: false,
        component: <BannerTypeSelect multiple />,
        toggled: true,
      },
      {
        name: 'view_type',
        label: 'Banner View',
        fullWidth: false,
        component: ({ banner_type }) => {
          const options =
            banner_type === BannerType.CODE_BANNER
              ? []
              : Object.values(BannerView);

          return (
            <Select
              multiple
              disabled={banner_type === BannerType.CODE_BANNER}
              options={options.map(item => ({
                label: BannerViewLabelMap[item],
                value: item,
              }))}
            />
          );
        },
        toggled: true,
        deps: ['banner_type'],
      },
      {
        name: 'activate_type',
        label: 'Shown',
        fullWidth: false,
        component: (
          <Select
            options={[
              {
                label: 'Permanently',
                value: ActivatePeriod.PERMANENTLY,
              },
              {
                label: 'Period',
                value: ActivatePeriod.PERIOD,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'activate_period',
        label: 'Activation Period',
        fullWidth: false,
        component: ({ activate_type }) => (
          <RangeDateInput
            disabled={activate_type !== ActivatePeriod.PERIOD}
            fullWidth
            label={'Duration'}
            rangeFromKey={'range_from'}
            rangeToKey={'range_to'}
          />
        ),
        toggled: true,
        deps: ['activate_type'],
      },
      {
        name: 'state',
        label: 'State',
        fullWidth: false,
        component: (
          <Select
            multiple
            options={[
              {
                label: BannerStateLabel[BannerState.DRAFT],
                value: BannerState.DRAFT,
              },
              {
                label: BannerStateLabel[BannerState.ACTIVE],
                value: BannerState.ACTIVE,
              },
              {
                label: BannerStateLabel[BannerState.SCHEDULED],
                value: BannerState.SCHEDULED,
              },
              {
                label: BannerStateLabel[BannerState.ENDED],
                value: BannerState.ENDED,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'operator',
        label: 'Operator',
        component: <OperatorSelect multiple />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'brands',
        label: 'Brand',
        component: ({ operator, ...props }) => {
          return <BrandSelect operatorIds={operator} multiple {...props} />;
        },
        toggled: true,
        deps: ['operator'],
        hidden: isOperator,
      },
      {
        name: 'brands',
        label: 'Brand',
        component: <BrandSelect multiple withOperator={false} />,
        toggled: true,
        hidden: !isOperator,
      },
    ].filter(item => !item.hidden),
    spanMin: 1,
    spanMax: 4,
  });

  const actions = useMemo(() => {
    return [createActionCard, presetsActionCard, filtersCard];
  }, [createActionCard, presetsActionCard, filtersCard]);

  const { activate_type, activate_period } = filters;
  const queryVariables = useTableQueryVariables({
    filters: {
      ...filters,
      activate_period:
        activate_type === ActivatePeriod.PERIOD ? activate_period : '',
    },
    limit,
    offset,
  });

  const variables = useMemo(() => {
    return {
      input: queryVariables,
    };
  }, [queryVariables]);

  const {
    data: { data: { items = [], total = 0 } = {} } = {},
    loading,
    error: bannerListError,
  } = useQuery(getBannersList, {
    variables,
    skip: presetsLoading || !queryVariables,
    fetchPolicy: 'no-cache',
  });

  if (error || bannerListError) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Banners'}>
      <TableActionCards animated actions={actions} />

      <Box mt={2}>
        <PromoBannersTable
          data={items}
          loading={loading || presetsLoading}
          onChangeColumnsWidth={debouncedOnResizeColumns}
        />
      </Box>

      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </Page>
  );
};

export default PromoBannersListPage;
