import React, { useCallback, useState } from 'react';
import ContextMenu from '../../components/ContextMenu';
import ContextMenuItem from '../../components/ContextMenuItem';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '../../icons/controls/CheckBoxIcon';
import CheckBoxActiveIcon from '../../icons/controls/CheckBoxActiveIcon';
import CheckboxIndeterminateIcon from '../../icons/controls/CheckboxIndeterminateIcon';
import MoveIcon from '../../icons/controls/MoveIcon';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'clsx';
import {
  getColumnsOrder,
  getColumnsSchema,
  getColumnsUiSchema,
  getColumnTitle,
  isHideEmptyColumn,
} from './TableUtils';
import { useSchema } from '../../providers/SchemaProvider';
import set from 'lodash/set';
import get from 'lodash/get';
import without from 'lodash/without';
import { getOrderedKeys } from '../../utils/schemaHelper';
import DraggableRef from './DraggableRef';
import usePresetUiSchema from '../../hooks/usePresetUiSchema';
import MoreVertIcon from '../../icons/controls/MoreVertIcon';

const useStyles = makeStyles(theme => ({
  tableConfigIcon: {
    fontSize: '10px',
  },
  rowActionsButtonRoot: {
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
    color: '#031224',
    '&:hover': {
      color: '#8496AB',
    },
  },
  moveButton: {
    display: 'flex',
    color: '#8496AB',
    '&>svg': {
      fontSize: '16px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  menuItemRoot: {
    paddingLeft: 6,
    backgroundColor: '#fff',

    '&>$menuItemLabel': {
      marginRight: 'auto',
      marginLeft: 6,
    },

    '&:hover': {
      '& $menuItemCheckbox': {
        color: theme.palette.primary.main,
      },
    },
  },
  menuItemRootDragging: {
    borderRadius: 8,
    boxShadow: '0px 4px 16px rgba(132, 150, 171, 0.5)',
  },
  menuItemLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuItemCheckbox: {
    paddingRight: 0,
  },
}));

const TableConfigMenu = ({ entity }) => {
  const [anchorEl, setAnchorEl] = useState();
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const classes = useStyles();

  const { uiSchema, updatePresetUiSchema } = usePresetUiSchema();

  const schema = useSchema();
  const { columnsSchema } = getColumnsSchema(schema);
  const { columnsOrder, path: columnsOrderPath } = getColumnsOrder(uiSchema);
  const { columnsUiSchema, path: columnsUiSchemaPath } =
    getColumnsUiSchema(uiSchema);

  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onToggleColumn = useCallback(
    (columnName, checked) => {
      const columnToggledPath = `${columnsUiSchemaPath}.${columnName}.toggled`;
      const updatedOverrides = { ...uiSchema };

      if (!checked) {
        set(updatedOverrides, columnToggledPath, false);
      } else {
        set(updatedOverrides, columnToggledPath, true);
      }

      updatePresetUiSchema(updatedOverrides);
    },
    [updatePresetUiSchema, columnsUiSchemaPath, uiSchema]
  );

  const filterHidden = columnKey => {
    const columnWidgetPath = `${columnsUiSchemaPath}.${columnKey}.ui:widget`;
    const hideEmpty = isHideEmptyColumn(uiSchema, columnsUiSchema, columnKey);

    const hiddenEmpty =
      hideEmpty && typeof get(entity, columnKey) === 'undefined';

    return get(uiSchema, columnWidgetPath) !== 'hidden' && !hiddenEmpty;
  };

  const withoutHidden = getOrderedKeys(
    Object.keys(columnsSchema),
    columnsOrder
  ).filter(filterHidden);

  const onDragEnd = useCallback(
    result => {
      const restFields = without(columnsOrder, ...withoutHidden);

      if (!result.destination) {
        return;
      }

      const updatedOverrides = set({ ...uiSchema }, columnsOrderPath, [
        ...reorder(
          withoutHidden,
          result.source.index,
          result.destination.index
        ),
        ...restFields,
      ]);

      updatePresetUiSchema(updatedOverrides);
    },
    [
      uiSchema,
      updatePresetUiSchema,
      reorder,
      columnsOrderPath,
      columnsOrder,
      withoutHidden,
    ]
  );

  return (
    <>
      <div
        className={classes.rowActionsButtonRoot}
        onClick={e => {
          if (e.currentTarget) {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <MoreVertIcon className={classes.tableConfigIcon} />
      </div>
      {withoutHidden.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {provided => (
              <ContextMenu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  ref: provided.innerRef,
                }}
                {...provided.droppableProps}
              >
                {withoutHidden.map((columnKey, index) => {
                  const toggled =
                    get(
                      uiSchema,
                      `${columnsUiSchemaPath}.${columnKey}.toggled`
                    ) === false;

                  return (
                    <DraggableRef
                      key={columnKey}
                      draggableId={`id-${columnKey}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ContextMenuItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          classes={{
                            root: classNames(classes.menuItemRoot, {
                              [classes.menuItemRootDragging]:
                                snapshot.isDragging,
                            }),
                          }}
                          onClick={() => onToggleColumn(columnKey, toggled)}
                        >
                          <div
                            className={classes.moveButton}
                            {...provided.dragHandleProps}
                          >
                            <MoveIcon />
                          </div>
                          <span className={classes.menuItemLabel}>
                            {getColumnTitle(
                              columnsSchema[columnKey],
                              columnsUiSchema[columnKey]
                            ) || columnKey}
                          </span>

                          <Checkbox
                            name={columnKey}
                            disableRipple={true}
                            classes={{
                              root: classes.menuItemCheckbox,
                            }}
                            icon={<CheckBoxIcon />}
                            checkedIcon={<CheckBoxActiveIcon />}
                            indeterminateIcon={<CheckboxIndeterminateIcon />}
                            checked={!toggled}
                          />
                        </ContextMenuItem>
                      )}
                    </DraggableRef>
                  );
                })}
                {provided.placeholder}
              </ContextMenu>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

export default TableConfigMenu;
