import Autocomplete from '../Autocomplete';
import useAutocompleteData from '../../../hooks/useAutocompleteData';
import React, { useMemo, useState } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import ChipList from '../../ChipList/ChipList';
import useKeyedArray from '../../../hooks/useKeyedArray';
import { Tooltip } from '@mui/material';

const filterOptions = createFilterOptions({
  stringify: option => {
    return option.name + option.secondary_text;
  },
});

const processGroups = groups => {
  return groups.reduce((acc, group) => {
    return [...acc, ...group.options];
  }, []);
};

const BonusRestrictionAutocomplete = ({
  value,
  onChange,
  helperText,
  error,
  eventType = [],
  disabled = false,
  required,
}) => {
  const [innerInputValue, setInnerInputValue] = useState(null);
  const [queryValue, setQueryValue] = useState();

  const params = useMemo(() => {
    return {
      query: queryValue,
      event_type: eventType,
    };
  }, [queryValue, eventType]);

  const { data: groups = [], loading } = useAutocompleteData({
    url: `/api/v1/BonusAdmin/bonus/autocomplete_restriction`,
    params,
  });

  const options = groups && processGroups(groups);

  const { items, append, removeByKey } = useKeyedArray(value, onChange);

  return (
    <>
      <Autocomplete
        disabled={disabled}
        popupIcon={null}
        required={required}
        clearOnBlur
        options={options}
        filterSelectedOptions
        filterOptions={filterOptions}
        blurOnSelect
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        loading={loading}
        renderTags={() => null}
        getOptionLabel={option => {
          return option.name;
        }}
        groupBy={option => option.type}
        InputProps={{
          label: 'Add sports, events, tournaments, categories',
          helperText,
          error,
        }}
        onInputChange={(e, v) => {
          setQueryValue(v);
        }}
        value={innerInputValue}
        onChange={(e, value) => {
          append(value);
          setInnerInputValue(null);
        }}
      />

      <ChipList
        items={items}
        onChange={onChange}
        removeByKey={removeByKey}
        label={item => item.name}
        renderChip={(chip, data) => {
          return (
            <Tooltip
              key={data.value}
              title={
                <div>
                  <div>{data.name}</div>
                  <div>{data.secondary_text}</div>
                </div>
              }
            >
              {chip}
            </Tooltip>
          );
        }}
      />
    </>
  );
};

export default BonusRestrictionAutocomplete;
