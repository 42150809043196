import TableActionCards from '../../components/TableActionCards/TableActionCards';
import TablePagination from '../../components/TablePagination/TablePagination';
import {
  useIsOperator,
  useIsRMAllowed,
} from '../../providers/OperatorProvider';
import TableBulkProvider from '../../providers/TableBulkProvider';
import BetHistoryTable from './BetHistoryTable';
import BetslipCancelPopup from './BetslipCancelPopup';
// import OperatorBetHistoryTable from './OperatorBetHistoryTable'; // TODO: delete component after QA check
import useBetHistory from './hooks/useBetHistory';
import useMultipleBetslipCancel from './hooks/useMultipleBetslipCancel';
import ErrorWidget from '../../components/ErrorWidget';

export const ACCEPTED_BETS = {
  YES: 'Yes',
  NO: 'No',
};

export const BET_STATUS = {
  OPEN: 'open',
  NEW: 'new',
  WON: 'won',
  LOST: 'lost',
  HALF_WON: 'halfWon',
  HALF_LOST: 'halfLost',
  REFUND: 'refund',
  REJECTED: 'rejected',
  CASHED_OUT: 'cashedOut',
  CANCELED: 'canceled',
};

export const FILTER_BONUS = {
  COMBOBOOST: 'comboboost',
  BET_REFUND: 'bet_refund',
  SNR: 'snr',
  FREE_MONEY: 'free_money',
};

export const BET_TYPE = {
  SINGLE: 'single',
  COMBO: 'combo',
  SYSTEM: 'system',
};

export const LIFE_PREMATCH = {
  LIVE: 'live',
  PREMATCH: 'prematch',
  VIRTUAL: 'virtual',
};

export const TEST_PLAYER = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  LOOKUP: 'lookup',
};

const BetHistory = ({
  eventId,
  withPresets = false,
  withExportCSV = false,
  isApplyRequired = false,
}) => {
  const isOperator = useIsOperator();
  const isRMAllowed = useIsRMAllowed();

  const isCancelBetsAllowed = isRMAllowed && !isOperator;

  const {
    items,
    loading,
    error,
    rowActions,
    orderBy,
    onChangeOrderBy,
    limit,
    offset,
    setLimit,
    setOffset,
    total,
    actions,
    cancelBetslipsError,
  } = useBetHistory({
    eventId,
    withPresets,
    withExportCSV,
    isOperator,
    isCancelBetsAllowed,
  });

  const { bulk, cancelBetslipsLoading, chosenIdsCount, onCancelBets } =
    useMultipleBetslipCancel({ items });

  const tabledProps = {
    loading: loading,
    data: items,
    rowActions: rowActions,
    orderBy: orderBy,
    onChangeOrderBy: onChangeOrderBy,
    error: error,
  };

  if (error || cancelBetslipsError) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TableActionCards animated actions={actions} />
      {isCancelBetsAllowed ? (
        <TableBulkProvider bulk={bulk}>
          <BetHistoryTable
            {...tabledProps}
            isCancelBetsAllowed
            isApplyRequired={isApplyRequired}
          />
        </TableBulkProvider>
      ) : (
        <BetHistoryTable
          {...tabledProps}
          isCancelBetsAllowed={false}
          isApplyRequired={isApplyRequired}
        />
      )}

      {!isApplyRequired && (
        <TablePagination
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          totalRows={total}
        />
      )}

      {chosenIdsCount > 0 && !cancelBetslipsLoading && (
        <BetslipCancelPopup
          count={chosenIdsCount}
          onCancelBets={onCancelBets}
        />
      )}
    </>
  );
};

export default BetHistory;
