import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Select from '../../../../../components/Inputs/Select/Select';
import Button from '../../../../../components/Button/Button';
import { unprefixSpecifierName } from '../../TradingEventEditContainer';

const useStyles = makeStyles({
  addRobotMarketForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },
  addRobotMarketFields: {
    display: 'flex',
    alignItems: 'center',
  },
  addRobotMarketField: {
    flex: 1,
  },
  robotMarketSpecifierSelect: {
    marginLeft: 16,
  },
  addRobotMarketButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    justifyContent: 'flex-end',
  },
  formButton: {
    minWidth: 'unset',
    marginLeft: 18,
  },
});

const EMPTY_SPECIFIER_PLACEHOLDER = 'empty';

const AddRobotMarketForm = ({ markets, onCancel, onSubmit }) => {
  const classes = useStyles();
  const [marketValue, setMarketValue] = useState(null);
  const [specifierValue, setSpecifierValue] = useState(null);

  const marketOptions = markets.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const specifierOptions = marketValue
    ? Object.keys(markets.find(({ id }) => id === marketValue).outcomes).map(
        specifier => {
          const value = unprefixSpecifierName(specifier);

          return {
            label: value || 'Default',
            value: value || EMPTY_SPECIFIER_PLACEHOLDER,
          };
        }
      )
    : [];

  useEffect(() => {
    setSpecifierValue(null);
  }, [marketValue, setSpecifierValue]);

  return (
    <div className={classes.addRobotMarketForm}>
      <div className={classes.addRobotMarketFields}>
        <div className={classes.addRobotMarketField}>
          <Select
            label={'Market'}
            options={marketOptions}
            value={marketValue}
            onChange={v => setMarketValue(v)}
          />
        </div>
        <div
          className={clsx(
            classes.addRobotMarketField,
            classes.robotMarketSpecifierSelect
          )}
        >
          <Select
            label={'Specifier'}
            options={specifierOptions}
            value={specifierValue}
            onChange={v => setSpecifierValue(v)}
            disabled={!marketValue}
          />
        </div>
      </div>
      <div className={classes.addRobotMarketButtons}>
        <Button
          color={'lightBlue'}
          onClick={onCancel}
          className={classes.formButton}
        >
          Cancel
        </Button>
        <Button
          color={'primary'}
          onClick={() =>
            onSubmit(
              marketValue,
              specifierValue === EMPTY_SPECIFIER_PLACEHOLDER
                ? ''
                : specifierValue
            )
          }
          className={classes.formButton}
          disabled={!marketValue || !specifierValue}
        >
          Set
        </Button>
      </div>
    </div>
  );
};

export default AddRobotMarketForm;
