import React from 'react';
import BaseInput from '../../../containers/PavoForm/widgets/BaseInput';

// import PropTypes from "prop-types";

function TextareaWidget(props) {
  const textAreaProps = {
    type: 'text',
    multiline: true,
    rows: props.options.rows,
    rowsMin: props.options.rowsMin,
    rowsMax: props.options.rowsMax,
    maxLength: props.options.maxLength,
  };

  return <BaseInput {...props} {...textAreaProps} />;
}

TextareaWidget.defaultProps = {
  autofocus: false,
  options: {
    rows: 3,
    rowsMin: 3,
    rowsMax: 10,
  },
};

export default TextareaWidget;
