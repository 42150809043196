import { gql } from '@apollo/client';

const getSourcesNames = gql`
  query getSourcesNames {
    data: getSourcesNames {
      id
      name
    }
  }
`;

export default getSourcesNames;
