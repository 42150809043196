import { gql } from '@apollo/client';
import { UPDATE_VERSIONED_RESULT_TYPE } from './updateSourcesTemplate';

const updateCoverageTemplate = gql`
  ${UPDATE_VERSIONED_RESULT_TYPE}
  mutation updateCoverageTemplate(
    $templateId: TemplateId!
    $input: CoverageTemplateInput!
  ) {
    data: updateCoverageTemplate(templateId: $templateId, input: $input) {
      ...UpdateVersionedResultType
    }
  }
`;

export default updateCoverageTemplate;
