import { gql } from '@apollo/client';

const getAutoEvents = gql`
  query getAutoEvents($query: String!, $brand_id: BrandId!) {
    data: getAutoEvents(query: $query, brand_id: $brand_id) {
      item_type
      id
      label
    }
  }
`;

export default getAutoEvents;
