import React from 'react';
import DateInput from '../../inputs/DateInput';

const DateWidget = ({
  errorSchema,
  options: { help, formatDate },
  ...otherProps
}) => (
  <DateInput
    fullWidth
    margin={'dense'}
    variant="outlined"
    error={!!(errorSchema.__errors && errorSchema.__errors.length)}
    helperText={
      (errorSchema.__errors && errorSchema.__errors[0]) || help || ' '
    }
    formatDate={formatDate}
    {...otherProps}
  />
);

export default DateWidget;
