import React, { useCallback, useEffect, useState } from 'react';
import FiltersForm from './FiltersForm';

const FiltersActionCard = ({
  error: externalError,
  path,
  onRefresh,
  dataLoading = false,
}) => {
  const [error, setError] = useState(externalError);

  useEffect(() => {
    setError(externalError);
  }, [externalError]);

  const clearError = useCallback(() => {
    setError(null);
  }, [setError]);

  return (
    <div>
      <FiltersForm
        dataLoading={dataLoading}
        externalErrors={error}
        clearExternalErrors={clearError}
        path={path}
        onRefresh={onRefresh}
      />
    </div>
  );
};

export default FiltersActionCard;
