import { createContext, useContext } from 'react';

export const TableBulkContext = createContext(null);

export const useTableBulkContext = () => {
  return useContext(TableBulkContext);
};

const TableBulkProvider = ({ bulk, children }) => {
  return (
    <TableBulkContext.Provider value={bulk}>
      {children}
    </TableBulkContext.Provider>
  );
};

export default TableBulkProvider;
