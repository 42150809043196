import React from 'react';
import DateTimeInput from '../../../components/inputs/DateTimeInput';
import {
  getValueForTimePicker,
  getValueFromTimePicker,
} from '../../../utils/datePickerUtils';

const DateTimeFilter = ({
  form,
  value,
  uiSchema: { format_date },
  onChange,
  ...props
}) => {
  return (
    <DateTimeInput
      {...props}
      margin="dense"
      formatDate={format_date}
      value={getValueForTimePicker(value)}
      onChange={v => {
        onChange(getValueFromTimePicker(v, format_date));
      }}
    />
  );
};

export default DateTimeFilter;
