import * as types from '../constants/form';

export const initialState = {
  form: {},
};

const _selectSchemaByStatusCode = (schema, code) => {
  if (schema instanceof Array) {
    return schema.filter(s => s.code === code)[0].schema;
  } else {
    return schema;
  }
};

export function formReducer(state = initialState, action) {
  switch (action.type) {
    case `${types.FORM_INIT}_LOADING`: {
      return {
        ...state,
      };
    }
    case `${types.FORM_INIT}_SUCCESS`: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.meta.formPath]: singleFormReducer(
            state.form[action.meta.formPath],
            action
          ),
        },
      };
    }
    case `${types.FORM_INIT}_ERROR`: {
      return {
        ...state,
      };
    }
    case types.FORM_SET_STATE: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.form]: singleFormReducer(state[action.form], action),
        },
      };
    }
    default:
      return state;
  }
}

const singleFormReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FORM_SET_STATE:
      return {
        ...action.payload,
      };
    case `${types.FORM_INIT}_SUCCESS`:
      return {
        ...action.payload,
        schema: _selectSchemaByStatusCode(state.schema, action.payload.code),
      };
    default:
      return state;
  }
};

export default formReducer;
