import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { identity, isEmpty, isEqual, omit, pickBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import StringField from '../../../../../Fields/StringField/StringField';
import {
  NumberInputWithResetToDefault,
  SelectItem,
} from '../../../components/';
import { getKeySelector } from '../../../utils/getKeySelector';
import { getMarketChangesForItem } from '../MarginTreeSelect';

const useStyles = makeStyles({
  loaderField: {
    fontSize: '14px',
    lineHeight: '20px',
    padding: '10px 0 10px 6px',
  },

  optionCaption: {
    fontSize: '14px',
    lineHeight: '20px',

    padding: '8px 0 8px 6px',
    width: '100%',
  },
});

const MarginMarketOption = ({
  templateId,
  nodePath = {},
  selected,
  itemValue,
  presetValues,
  lineType,
}) => {
  const classes = useStyles();

  const { getValues, setValue } = useFormContext();
  const { setNodePath, setChangedMarginItems } = useTreeSelectContext();

  const template = getValues('template');

  const nodeSelector = pickBy(
    {
      ...nodePath,
      market: {
        marketId: itemValue.marketId,
      },
    },
    identity
  );
  const parentNodeSelector = omit(nodeSelector, ['market']);

  const keySelector = getKeySelector({ nodeSelector });
  const parentKeySelector = getKeySelector({
    nodeSelector: parentNodeSelector,
  });
  const initialPrematch = useMemo(
    () =>
      template[keySelector]
        ? template[keySelector].prematchMargin.value
        : itemValue.prematch,
    [itemValue, template, keySelector]
  );
  const initialLive = useMemo(
    () =>
      template[keySelector]
        ? template[keySelector].liveMargin.value
        : itemValue.live,
    [itemValue, template, keySelector]
  );

  const [prematch, setPrematch] = useState(initialPrematch);
  const [live, setLive] = useState(initialLive);

  const onSelect = useCallback(() => {
    if (!selected) {
      let path = structuredClone(nodePath);
      path.market.marketId = itemValue.marketId;
      setNodePath(currentPath => {
        return {
          ...currentPath,
          [lineType]: path,
        };
      });
    }
  }, [itemValue, nodePath, selected, lineType, setNodePath]);

  useEffect(() => {
    if (
      prematch == itemValue.prematch &&
      live == itemValue.live &&
      template[keySelector]
    ) {
      const newConfig = omit(template, [keySelector]);
      setValue(`template`, newConfig);
      setChangedMarginItems(currentItems =>
        isEmpty(
          getMarketChangesForItem({
            selector: parentNodeSelector,
            template: newConfig,
          })
        )
          ? { ...currentItems, [parentKeySelector]: false }
          : currentItems
      );
    }
    if (prematch !== itemValue.prematch || live !== itemValue.live) {
      const newMarginConfig = {
        nodeSelector,
        liveMargin: {
          value: +live,
        },
        prematchMargin: {
          value: +prematch,
        },
      };
      setChangedMarginItems(currentItems =>
        currentItems[parentKeySelector]
          ? currentItems
          : { ...currentItems, [parentKeySelector]: true }
      );
      !isEqual(template[keySelector], newMarginConfig) &&
        setValue(`template.${keySelector}`, newMarginConfig);
    }
    // eslint-disable-next-line
  }, [prematch, live]);

  useEffect(() => {
    setPrematch(currentPrematch =>
      currentPrematch === initialPrematch ? currentPrematch : initialPrematch
    );
    setLive(currentLive =>
      currentLive === initialLive ? currentLive : initialLive
    );
  }, [template, initialLive, initialPrematch]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '4px',
      }}
    >
      <SelectItem selected={selected} fillWidth>
        <StringField className={classes.optionCaption} onClick={onSelect}>
          {itemValue.name}
        </StringField>
      </SelectItem>
      <Box sx={{ display: 'flex', marginTop: '4px', gap: '4px' }}>
        <NumberInputWithResetToDefault
          inputStyle={{ paddingLeft: '7px' }}
          color={'#1581FF'}
          value={prematch}
          defaultValue={itemValue.prematch}
          setValue={setPrematch}
          style={{ width: '40px' }}
        />
        <NumberInputWithResetToDefault
          inputStyle={{ paddingLeft: '7px' }}
          color={'#1581FF'}
          value={live}
          defaultValue={itemValue.live}
          setValue={setLive}
          style={{ width: '40px' }}
        />
      </Box>
    </Box>
  );
};

export default MarginMarketOption;
