import React, { useRef } from 'react';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const getCroppedImg = ({ image, pixelCrop, width, height }) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    Math.ceil(pixelCrop.width * scaleX),
    Math.ceil(pixelCrop.height * scaleY),
    0,
    0,
    canvas.width,
    canvas.height
  );

  return canvas.toDataURL('image/png', 1);
};

const ImageCrop = ({ src, width, height, onCrop, resultSize, onComplete }) => {
  const cropperRef = useRef(null);

  return (
    <>
      <Cropper
        style={{ width: '100%' }}
        aspectRatio={width / height}
        zoomable={false}
        // preview=".img-preview"
        src={src}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={instance => {
          cropperRef.current = instance;
        }}
        guides={true}
        crop={() => {
          onComplete && onComplete(cropperRef.current.getData(true));
          onCrop(
            cropperRef.current
              .getCroppedCanvas(
                resultSize && {
                  width: resultSize.width,
                  height: resultSize.height,
                }
              )
              .toDataURL()
          );
        }}
      />
    </>
  );
};

export default ImageCrop;
