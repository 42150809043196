import { useQuery } from '@apollo/client';
import getEventsBets from '../../gql/EventsGroups/queries/getEventsBets';

const useEventsBets = options => {
  const { data, loading, error, refetch } = useQuery(getEventsBets, {
    fetchPolicy: 'network-only',
    ...options,
  });

  let bets = [];

  if (data) {
    bets = data.getEventsBets;
  }

  return { bets, loading, error, refetch };
};

export default useEventsBets;
