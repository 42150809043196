import * as React from 'react';
import { useContext } from 'react';
import get from 'lodash/get';

export const UiComponentSchemaContext = React.createContext({});

const UiComponentSchemaProvider = ({ children, value, path = '' }) => {
  return (
    <UiComponentSchemaContext.Provider value={get(value, path, value)}>
      {children}
    </UiComponentSchemaContext.Provider>
  );
};

export const useUiComponentSchema = () => {
  return useContext(UiComponentSchemaContext);
};

export default UiComponentSchemaProvider;
