import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../../../v2/components/Button/Button';
import AddIcon from '../../../icons/controls/AddIcon';
import RemoveIcon from '../../../icons/controls/RemoveIcon';
import Grow from '@mui/material/Grow';

const useStyles = makeStyles(theme => ({
  arrayFieldRoot: {},
  arrayFieldItem: {
    '&+$arrayAddItemButton': {
      marginTop: 24,
    },
  },
  arrayAddItemButton: {
    padding: 1,
    marginTop: 16,
  },
  arrayAddItemIcon: {
    marginRight: 8,
  },
  arrayFieldItemControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 24,
    padding: '24px 0',
  },
  arrayRemoveButtonLabel: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.5px',
    textTransform: 'initial',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  arrayRemoveButtonText: {
    padding: 0,
  },
  arrayRemoveButtonRoot: {
    minWidth: 'initial',
  },
  arrayRemoveIcon: {
    marginRight: 4,
    fontSize: 16,
  },
}));

const ArrayFieldTemplate = ({ items, onAddClick }) => {
  const classes = useStyles();

  // TODO restore list transitions

  return (
    <div className={classes.arrayFieldRoot}>
      {items.map((element, index) => {
        const { onDropIndexClick } = element;

        return (
          <Grow key={element.key} timeout={300}>
            <div className={classes.arrayFieldItem}>
              <div className={classes.arrayFieldItemControls}>
                <Button
                  disableRipple
                  variant={'text'}
                  classes={{
                    root: classes.arrayRemoveButtonRoot,
                    text: classes.arrayRemoveButtonText,
                    label: classes.arrayRemoveButtonLabel,
                  }}
                  onClick={e => {
                    onDropIndexClick(index)(e);
                  }}
                >
                  <RemoveIcon className={classes.arrayRemoveIcon} />
                  <span>Remove</span>
                </Button>
              </div>
              {element.children}
            </div>
          </Grow>
        );
      })}

      <Button
        onClick={onAddClick}
        color={'primary'}
        disableRipple
        variant={'text'}
        className={classes.arrayAddItemButton}
      >
        <AddIcon className={classes.arrayAddItemIcon} />
        Add item
      </Button>
    </div>
  );
};

export default ArrayFieldTemplate;
