import { memo } from 'react';
import ExpandButton from '../Button/ExpandButton';
import UndoChangeButton from '../Button/UndoChangeButton';
import TableCell from './TableCell';

export const expandCellStyles = {
  padding: '0 4px',
  minWidth: 34,
};

const UndoChangeCell = ({ onUndoButtonClick = () => {} }) => {
  return (
    <TableCell>
      <UndoChangeButton onClick={onUndoButtonClick} />
    </TableCell>
  );
};

export default memo(UndoChangeCell);
