import Page from '../../components/Page/Page';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import BannerForm from './components/BannerForm/BannerForm';
import BannerValidationSchema from './components/BannerForm/BannerValidationSchema';

const defaultValues = async () => {
  return BannerValidationSchema.default();
};

const PromoBannerCreatePage = () => {
  return (
    <Page title={'Create Banner'}>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Banners',
            to: '/banners',
          },
          {
            label: 'Create Banner',
          },
        ]}
      />

      <BannerForm defaultValues={defaultValues} />
    </Page>
  );
};

export default PromoBannerCreatePage;
