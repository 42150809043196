import { gql } from '@apollo/client';
import OPERATOR_BETSTREAM_FIELDS from '../fragments/OPERATOR_BETSTREAM_FIELDS';

const OPERATOR_BETSTREAM_SUBSCRIPTION = gql`
  ${OPERATOR_BETSTREAM_FIELDS}
  subscription ($input: BetStreamModelFilters!) {
    BetStreamModel(input: $input) {
      ...OperatorBetstreamFields
    }
  }
`;

export default OPERATOR_BETSTREAM_SUBSCRIPTION;
