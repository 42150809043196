import { omit } from 'lodash';
import every from 'lodash/every';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useTableBulkSelection = (
  items = [],
  idKey = 'id',
  resetOnPageChange = true,
  payloadDataKeys,
  checkboxesDisabled = false
) => {
  const [chosenItems, setChosenItems] = useState({});
  const idsOnPage = items.map(value => value[idKey]);

  const everyToggled = useMemo(
    () => every(items, item => chosenItems[item[idKey]]),
    [items, chosenItems, idKey]
  );

  const someToggled = useMemo(
    () => some(Object.values(chosenItems)),
    [chosenItems]
  );

  const reset = useCallback(() => {
    setChosenItems({});
  }, [setChosenItems]);

  const resetHidden = useCallback(() => {
    setChosenItems(currentItems =>
      pickBy(currentItems, (value, key) => {
        return items.find(item => item[idKey] === key);
      })
    );
  }, [setChosenItems, items, idKey]);

  useEffect(() => {
    resetOnPageChange && resetHidden();
  }, [resetOnPageChange, resetHidden]);

  const getPayloadDataForItem = useCallback(
    rowData => {
      return payloadDataKeys?.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: rowData[cur],
        };
      }, {});
    },
    [payloadDataKeys]
  );

  const toggle = useCallback(
    (rowData, toggled) => {
      const rowId = rowData[idKey];

      setChosenItems(currentItems => {
        return payloadDataKeys
          ? {
              ...currentItems,
              [rowId]: toggled && getPayloadDataForItem(rowData),
            }
          : {
              ...currentItems,
              [rowId]:
                typeof toggled === 'undefined' ? !currentItems[idKey] : toggled,
            };
      });
    },
    [setChosenItems, idKey, payloadDataKeys, getPayloadDataForItem]
  );

  const toggleAll = useCallback(
    toggled => {
      setChosenItems(currentItems => {
        const everyToggled = every(items, item => currentItems[item[idKey]]);

        if (everyToggled && !toggled) {
          return omit(currentItems, idsOnPage);
        }

        // if(excludable){
        //   return {

        //   }
        // }

        return items.reduce(
          (acc, item) => {
            return payloadDataKeys
              ? {
                  ...acc,
                  [item[idKey]]: toggled && getPayloadDataForItem(item),
                }
              : {
                  ...acc,
                  [item[idKey]]: toggled,
                };
          },
          resetOnPageChange ? {} : currentItems
        );
      });
    },
    [
      setChosenItems,
      resetOnPageChange,
      items,
      idKey,
      idsOnPage,
      getPayloadDataForItem,
      payloadDataKeys,
    ]
  );

  return useMemo(() => {
    return {
      toggle,
      chosenItems,
      setChosenItems,
      resetHidden,
      reset,
      toggleAll,
      someToggled,
      everyToggled,
      checkboxesDisabled,
    };
  }, [
    chosenItems,
    setChosenItems,
    resetHidden,
    reset,
    toggle,
    toggleAll,
    someToggled,
    everyToggled,
    checkboxesDisabled,
  ]);
};

export default useTableBulkSelection;
