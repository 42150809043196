import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ArrowDownWardIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 8 10'} {...props}>
      <path
        d="M4 1L4 9"
        stroke="currentColor"
        strokeLinecap="round"
        fill={'none'}
      />
      <path
        d="M1 6L4 9L7 6"
        stroke="currentColor"
        strokeLinecap="round"
        fill={'none'}
      />
    </SvgIcon>
  );
};

export default ArrowDownWardIcon;
