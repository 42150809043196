import React, { forwardRef, memo } from 'react';
import { animated, config, useSpring } from 'react-spring';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  undoIcon: {
    cursor: 'pointer',
    width: 28,
    height: 28,
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("/icons/UndoIcon.svg")',
    backgroundPosition: 'center',
  },
});

const UndoIcon = memo(
  forwardRef(({ ...props }, ref) => {
    const classes = useStyles();

    return (
      <animated.div ref={ref} className={clsx(classes.undoIcon)} {...props} />
    );
  })
);

export default UndoIcon;
