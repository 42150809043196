import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import NotificationToastList from '../components/Notifications/NotificationToastList';
import { v4 as uuid } from 'uuid';

const toastsRoot = document.getElementById('toasts-root');

const ToastsContext = createContext({
  toasts: [],
});

export const useToasts = () => {
  return useContext(ToastsContext);
};

const ToastsProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const removeToast = useCallback(key => {
    setToasts(toasts => toasts.filter(item => item.key !== key));
  }, []);

  const showToast = useCallback(render => {
    const key = uuid();

    setToasts(toasts => [
      ...toasts,
      {
        key,
        render,
      },
    ]);
  }, []);

  const providerValue = useMemo(() => {
    return {
      showToast,
      removeToast,
      toasts,
    };
  }, [showToast, removeToast, toasts]);

  return (
    <ToastsContext.Provider value={providerValue}>
      {children}
      {createPortal(<NotificationToastList toasts={toasts} />, toastsRoot)}
    </ToastsContext.Provider>
  );
};

export default ToastsProvider;
