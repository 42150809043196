import { gql } from '@apollo/client';

const createEvent = gql`
  mutation createEvent(
    $sportId: SportId!
    $groupId: GroupId!
    $providerId: ProviderId!
    $provider: Provider!
    $eventCreateModel: EventCreateModel!
    $descType: DescType!
  ) {
    createEvent(
      sportId: $sportId
      groupId: $groupId
      providerId: $providerId
      provider: $provider
      eventCreateModel: $eventCreateModel
      descType: $descType
    ) {
      booked {
        desc {
          id
        }
      }
    }
  }
`;

export default createEvent;
