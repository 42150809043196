import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, memo } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles({
  countryIconContainer: {
    overflow: 'hidden',
    height: 16,
    width: 16,
    borderRadius: 2,
    boxSizing: 'border-box',
    border: '0.5px solid #8496AB',
  },
});

const CountryIcon = memo(
  forwardRef(({ name, ...props }, ref) => {
    const classes = useStyles();

    if (!name) {
      // TODO design icon fallback
      return null;
    }

    return (
      <div
        ref={ref}
        style={{
          backgroundImage: `url("/flags/${name.toLowerCase()}.svg")`,
        }}
        {...props}
        className={clsx(classes.countryIconContainer, props.className)}
      />
    );
  })
);

export default CountryIcon;
