import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  disabledLink: {
    cursor: 'default',
  },
});

const Link = ({ to, disabled, className, children }) => {
  const classes = useStyles();

  if (disabled) {
    return (
      <span className={clsx(className, classes.disabledLink)}>{children}</span>
    );
  }

  return (
    <RouterLink to={to} className={className}>
      {children}
    </RouterLink>
  );
};

export default Link;
