import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
});

const NoOptionsMessage = ({ innerProps, children, classes }) => (
  <Typography color="textSecondary" className={classes.root} {...innerProps}>
    {children}
  </Typography>
);

export default withStyles(styles)(NoOptionsMessage);
