import { hideModal, showModal } from '../actions/modalActions';
import { handleActions } from 'redux-actions';

const initialState = {
  modalType: null,
  modalProps: {},
};

const modal = handleActions(
  {
    [showModal]: (state, { payload }) => ({
      modalType: payload.modalType,
      modalProps: payload.modalProps,
    }),
    [hideModal]: () => initialState,
  },
  initialState
);

export default modal;

export const getModal = state => state.modal;
