import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../../TextField';
import {
  MenuCaption,
  MenuTitle,
  SelectContainer,
  VirtualizedMenu,
} from '../../../components/';
import { useSourcesTemplateSelect } from '../../../hooks/sources/select/useSourcesTemplateSelect';
import SourcesEventOption from '../Options/SourcesEventOption';

const SourcesEventSelect = memo(
  ({
    templateId,
    nodePath = [],
    setPath = () => {},
    selectKey,
    nodeIndex,
    options,
  }) => {
    const selectedId = nodePath.eventId;

    const { getValues } = useFormContext();

    const lineType = getValues('lineType');

    const { displayOptions, onSearchFieldChange, isSourcesWriter } =
      useSourcesTemplateSelect({
        options,
        getFormValues: getValues,
      });

    return (
      <SelectContainer style={{ width: '350px' }}>
        <TextField
          style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
          label={`Search Event`}
          onChange={onSearchFieldChange}
          clearButton={true}
        />

        <MenuCaption>
          <MenuTitle name={'Event'} />
        </MenuCaption>
        <VirtualizedMenu
          rowCount={displayOptions.length}
          itemRender={({ measure, index }) => {
            const option = displayOptions[index];

            return (
              <SourcesEventOption
                key={selectKey + '-' + option.eventId}
                templateId={templateId}
                nodePath={nodePath}
                selectKey={selectKey}
                setPath={setPath}
                nodeIndex={nodeIndex}
                selected={option.eventId === selectedId}
                itemValue={option}
                onMenuOpen={measure}
                lineType={lineType}
                configurationDisabled={!isSourcesWriter}
              />
            );
          }}
        />
      </SelectContainer>
    );
  }
);

export default SourcesEventSelect;
