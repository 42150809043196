import { TextField } from '@mui/material';
import { useState } from 'react';
import ModalContentContainer from './ModalContentContainer';

const SaveModalContent = ({ onNameChange = () => {}, names = [] }) => {
  const initialErrorState = {
    isError: false,
    helperText: '',
  };

  const [error, setError] = useState(initialErrorState);

  const setErrorText = e => {
    const text = e.target.value;
    if (!text) {
      setError({ isError: true, helperText: 'Enter preset name' });
    } else if (names.includes(text)) {
      setError({ isError: true, helperText: 'Name already exist' });
    } else {
      setError(initialErrorState);
    }
  };

  return (
    <ModalContentContainer>
      <TextField
        label={'Preset name'}
        onChange={e => {
          onNameChange(e);
          setErrorText(e);
        }}
        error={error.isError}
        helperText={error.helperText}
      />
    </ModalContentContainer>
  );
};

export default SaveModalContent;
