import React from 'react';
import RootObjectFieldTemplate from './RootObjectFieldTemplate';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles({
  propertyRoot: {
    backgroundColor: '#fff',
    borderRadius: 4,
    '&+$propertyRoot': {
      marginTop: 32,
    },
  },

  propertyRootHidden: {
    padding: 0,
    margin: 0,
    '&+$propertyRoot': {
      marginTop: 0,
    },
  },
  propertyRootDivided: {
    margin: 4,
    padding: '24px 16px',
    '&+$propertyRoot': {
      marginTop: 4,
    },
  },

  objectRoot: {},

  rangePropertyRoot: {
    padding: '6px 16px',
  },
});

const ObjectFieldTemplate = ({ uiSchema = {}, ...props }) => {
  const classes = useStyles();

  const { divided } = uiSchema['ui:options'] || {};

  if (uiSchema && uiSchema['ui:component']) {
    if (uiSchema['ui:component'] === 'PavoForm') {
      return <RootObjectFieldTemplate {...props} uiSchema={uiSchema} />;
    }
  }

  return (
    <div className={classes.objectRoot}>
      {props.properties.map(element => {
        const elementUiSchema = uiSchema[element.name] || {};

        return (
          <div
            className={classNames(classes.propertyRoot, {
              [classes.propertyRootHidden]:
                elementUiSchema['ui:widget'] === 'hidden',
              [classes.propertyRootDivided]: divided,
              [classes.rangePropertyRoot]:
                elementUiSchema['ui:field'] === 'range',
            })}
          >
            {element.content}
          </div>
        );
      })}
    </div>
  );
};

export default ObjectFieldTemplate;
