import { memo } from 'react';

const TableRowExpandContent = ({ children }) => {
  return (
    <div className={'tableRowExpandContainer'}>
      <div className={'tableRowExpandContent'}>{children}</div>
    </div>
  );
};

export default memo(TableRowExpandContent);
