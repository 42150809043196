import { Box } from '@mui/material';
import { identity, pickBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import {
  DragDropList,
  ExpandableOrderedList,
  FancyOptionTitle,
  SelectItem,
} from '../../../components';
import { useSourcesTemplateOption } from '../../../hooks/sources/option/useSourcesTemplateOption';
import useSourcesStyles from '../../../styles/Sources.style';
import { getLastParentCommands } from '../../../utils/getSourcesLastParentCommands';

const SourcesMarketOption = ({
  nodePath,
  selected,
  lineType,
  onMenuOpen = () => {},
  itemValue,
  configurationDisabled,
}) => {
  const classes = useSourcesStyles();

  const { getValues, setValue } = useFormContext();
  const {
    setNodePath,
    presetApplied,
    setPresetApplied,
    parentSourcesCommands: { [lineType]: parentSourcesCommands },
  } = useTreeSelectContext();

  const parentSourcesCommand = useMemo(() => {
    return getLastParentCommands({
      allParentCommands: parentSourcesCommands,
    });
  }, [parentSourcesCommands]);

  const nodeSelector = pickBy(
    {
      sportId: nodePath.sportId,
      categoryId: nodePath.categoryId,
      tournamentId: nodePath.tournamentId,
      eventId: nodePath.eventId,
      market: { marketId: itemValue.marketId },
    },
    identity
  );

  const {
    sourcesList,
    toggledSourcesList,
    isSourcesListOpen,
    setIsSourcesListOpen,
    onStatusChange,
    onReorder,
  } = useSourcesTemplateOption({
    itemSources: itemValue.prioritySources,
    parentConfig: parentSourcesCommand,
    getFormStateValues: getValues,
    setFormStateValue: setValue,
    setPresetApplied,
    presetApplied,
    nodeSelector,
    isSourcesConfiguredInitially: itemValue.isConfigured,
  });

  //set to path for highlight item
  const onSelect = useCallback(() => {
    if (!selected) {
      let path = structuredClone(nodePath);
      path.market.marketId = itemValue.marketId;
      setNodePath(currentPath => ({
        ...currentPath,
        [lineType]: path,
      }));
    }
  }, [itemValue, nodePath, setNodePath, selected, lineType]);

  useEffect(() => {
    onMenuOpen && onMenuOpen();
  }, [isSourcesListOpen, onMenuOpen, sourcesList]);

  return (
    <Box>
      <SelectItem selected={selected}>
        <FancyOptionTitle
          countryIcon={false}
          name={itemValue.name}
          onClick={onSelect}
          textClassName={classes.sourcesMarketOptionName}
          tooltip
        >
          {itemValue.name}
        </FancyOptionTitle>
        <ExpandableOrderedList
          selected={selected}
          disabled={configurationDisabled}
          isMenuOpen={isSourcesListOpen}
          toggleMenu={setIsSourcesListOpen}
          items={toggledSourcesList.map(value => value.label)}
        />
      </SelectItem>
      {!configurationDisabled && (
        <DragDropList
          onDragEnd={onReorder}
          isMenuOpen={isSourcesListOpen}
          toggledItems={toggledSourcesList.map(value => value.key)}
          items={sourcesList}
          virtualized
          onToggleItem={onStatusChange}
        />
      )}
    </Box>
  );
};

export default SourcesMarketOption;
