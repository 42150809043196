import Page from '../../components/Page/Page';
import BetHistory from '../BetHistory/BetHistory';
import { useApplyRequired } from '../../providers/ApplyRequiredProvider';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';

const BetHistoryPage = () => {
  const { isApplyRequired } = useApplyRequired(tableNames.BetHistory);

  return (
    <Page title={'Bet History'}>
      <BetHistory withPresets withExportCSV isApplyRequired={isApplyRequired} />
    </Page>
  );
};

export default BetHistoryPage;
