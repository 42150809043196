import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

const ConfirmationModal = ({
  title,
  description,
  onSubmit,
  onCancel,
  onClose,
}) => (
  <Dialog open onClose={onClose}>
    {title && <DialogTitle>{title}</DialogTitle>}
    {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        Disagree
      </Button>
      <Button onClick={onSubmit} color="primary" autoFocus>
        Agree
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationModal;
