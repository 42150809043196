import * as React from 'react';
import { useUiComponentSchema } from '../providers/UiComponentSchemaProvider';
import { staticComponents } from '../uiComponents';
import get from 'lodash/get';

const StaticComponent = () => {
  const uiSchema = useUiComponentSchema();

  const componentName = uiSchema['ui:component'];

  const UiComponent = get(staticComponents, componentName);

  if (!UiComponent) {
    return null;
  }

  return <UiComponent />;
};

export default StaticComponent;
