import useAutocompleteData from '../../../hooks/useAutocompleteData';
import { forwardRef } from 'react';
import FancySelect from '../FancySelect/FancySelect';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';
import { useFormContext } from 'react-hook-form';

const filterOptions = createFilterOptions({
  stringify: option => {
    return option.id + option.name;
  },
});

const FancyOperatorSelect = forwardRef(
  (
    { multiple = false, proxyValue = false, value, onChange, ...props },
    ref
  ) => {
    const { data: options = [] } = useAutocompleteData({
      url: '/api/v1/Autocomplete/operators',
      withoutParams: true,
    });

    const { getValues, resetField } = useFormContext();

    const Component = (
      <FancySelect
        ref={ref}
        label={'Operator'}
        multiple={multiple}
        options={options}
        labelKey={'name'}
        filterOptions={filterOptions}
        value={multiple ? value || [] : value}
        onChange={(...args) => {
          if (!multiple) {
            const prev = getValues('operator');
            if (prev?.id !== args?.[0]?.id) {
              resetField('brand');
            }
          }
          onChange(...args);
        }}
        {...props}
      />
    );

    if (proxyValue) {
      return (
        <FancySelectValueProxy
          multiple={multiple}
          value={multiple ? value || [] : value}
          options={options}
          onChange={props.onChange}
          valueKey={'id'}
        >
          {Component}
        </FancySelectValueProxy>
      );
    }

    return Component;
  }
);

export default FancyOperatorSelect;
