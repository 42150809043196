import { FormProvider, useForm } from 'react-hook-form';
import React, { memo, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { yupResolver } from '@hookform/resolvers/yup';

const InlineTableForm = ({
  mode = 'onBlur',
  rowId,
  schema,
  defaultValues,
  onChange,
  onSuccessChange,
  onSubmit,
  children,
  debounceTime = 500,
}) => {
  const methods = useForm({
    mode,
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { watch, setError, clearErrors, handleSubmit } = methods;

  const debounced = useMemo(
    () =>
      debounce(function (callback) {
        return callback();
      }, debounceTime),
    [debounceTime]
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      let v;

      try {
        v = schema.cast(value)[name];

        debounced(function () {
          onChange(rowId, name, v)
            .then(function ({ data, errors }) {
              clearErrors(name);

              onSuccessChange &&
                onSuccessChange({
                  id: rowId,
                  name,
                  value: v,
                });
            })
            .catch(e => {
              setError(name, {
                type: 'manual',
                message: 'A record with same settings already exists',
              });
            });
        });
      } catch (e) {}
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    debounced,
    onChange,
    rowId,
    schema,
    setError,
    clearErrors,
    onSuccessChange,
  ]);

  return (
    <FormProvider
      {...methods}
      defaultValues={defaultValues}
      inlineCallback={onChange}
    >
      <form onSubmit={onSubmit && handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default memo(InlineTableForm);
