import React, { createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserToken } from '../../reducers/users';

export const DynEnvsContext = createContext({});

const DynEnvsProvider = ({ children }) => {
  const token = useSelector(getUserToken);
  const apiUrl = process.env.REACT_APP_DYNAMIC_ENVS_URL;

  const [data, setData] = useState(null);

  useEffect(() => {
    if (apiUrl) {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async response => {
          const data = await response.json();
          setData(data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [apiUrl, token, setData]);

  return (
    <DynEnvsContext.Provider value={data}>{children}</DynEnvsContext.Provider>
  );
};

export default DynEnvsProvider;
