import React, { forwardRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import setRef from '@mui/material/utils/setRef';

const DraggableRef = forwardRef(({ children, ...props }, ref) => {
  return (
    <Draggable {...props} ref={ref}>
      {(provided, snapshot) =>
        children(
          {
            ...provided,
            innerRef: node => {
              // setRef(ref, node);
              setRef(provided.innerRef, node);
            },
          },
          snapshot
        )
      }
    </Draggable>
  );
});

export default DraggableRef;
