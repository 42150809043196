import StringField from './StringField';
import RangeField from './RangeField';
import SchemaField from './SchemaField';
import ArrayField from './ArrayField';
import NumberField from './NumberField';

const fields = {
  StringField,
  range: RangeField,
  SchemaField,
  ArrayField,
  NumberField,
};

export default fields;
