import makeStyles from '@mui/styles/makeStyles';
import BannerTabNotification from './BannerTabNotification';

const useStyles = makeStyles({
  bannerImageTabLabel: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.5px',

    '&>*:first-child': {
      marginRight: 8,
    },
  },
});

const BannerImageTabLabel = ({ notification, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.bannerImageTabLabel}>
      {children}
      {notification && <BannerTabNotification />}
    </div>
  );
};

export default BannerImageTabLabel;
