import React, { Fragment } from 'react';
import BannerLinkField from '../../BannerFields/BannerLinkField';
import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import BannerButtonText from '../../BannerFields/BannerButtonText';
import BannerButtonColor from '../../BannerFields/BannerButtonColor';
import Stack from '@mui/material/Stack/Stack';
import BannerTextField from '../../BannerFields/BannerTextField';
import BannerImageUploader from '../../BannerImageUploader/BannerImageUploader';
import LineBannerButtonPreview from '../../BannerImagePreview/LineBannerButtonPreview';
import FormField from '../../../../../components/Form/FormField';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';
import { useFormContext } from 'react-hook-form';

const ButtonView = ({ prefix }) => {
  const { watch } = useFormContext();
  const brand = watch('brand');

  return (
    <ImagePreviewProvider>
      <LocaleTabs
        name={`${prefix}.locales`}
        content={(localeField, localeIndex) => (
          <Fragment key={localeField.locale.country + localeField.locale.lang}>
            <BannerLinkField name={`${prefix}.locales.${localeIndex}.link`} />

            <Stack direction={'column'} spacing={0.5}>
              <Grid item xs={12}>
                <FormGroup large>
                  <Grid container spacing={2}>
                    <BannerTextField
                      maxLength={50}
                      name={`${prefix}.locales.${localeIndex}.title`}
                    />
                    <BannerButtonText
                      maxLength={12}
                      name={`${prefix}.locales.${localeIndex}.button.text`}
                    />
                    <BannerButtonColor
                      name={`${prefix}.locales.${localeIndex}.button.color`}
                    />
                  </Grid>
                </FormGroup>
              </Grid>
            </Stack>

            {brand && (
              <Grid item xs={12}>
                <FormGroup large>
                  <FormField
                    name={`${prefix}.locales.${localeIndex}.images.desktop`}
                  >
                    <BannerImageUploader
                      useGradient
                      preview={LineBannerButtonPreview}
                      previewFields={[
                        `${prefix}.locales.${localeIndex}.title`,
                        `${prefix}.locales.${localeIndex}.button.text`,
                        `${prefix}.locales.${localeIndex}.button.color`,
                      ]}
                      cropSize={{
                        width: 360,
                        height: 152,
                      }}
                    />
                  </FormField>
                </FormGroup>
              </Grid>
            )}
          </Fragment>
        )}
      />
    </ImagePreviewProvider>
  );
};

export default ButtonView;
