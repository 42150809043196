import { gql } from '@apollo/client';

const getBrandsByTemplateId = gql`
  query getBrandsByTemplateId($input: BrandsTemplateInput!) {
    data: getBrandsByTemplateId(input: $input) {
      items {
        name
        createdBy
        created
        updated
      }
      total
    }
  }
`;

export default getBrandsByTemplateId;
