import Table from '../../../../../components/Table/Table';
import StringField from '../../../../../components/Fields/StringField/StringField';
import DateField from '../../../../../components/Fields/DateField/DateField';
import React, { useMemo } from 'react';
import PercentField from '../../../../../components/Fields/PercentField/PercentField';
import LinkField from '../../../../../components/Fields/LinkField/LinkField';
import CurrencyField from '../../../../../components/Fields/CurrencyField/CurrencyField';
import CopyField from '../../../../../components/Fields/CopyField/CopyField';
import { GA_GROUP_BY } from './components/Inputs/GAGroupBySelect';
import BooleanField from '../../../../../components/Fields/BooleanField/BooleanField';
import useTableColumnsReorder from '../../../../../hooks/useTableColumnsReorder';
import EmptyField from '../../../../../components/Fields/EmptyField/EmptyField';
import { useIsOperator } from '../../../../../providers/OperatorProvider';

export const COLUMN = {
  playerId: 'playerId',
  username: 'username',
  extPlayerId: 'extPlayerId',

  operator: 'operator',
  brand: 'brand',

  month: 'month',
  week: 'week',
  year: 'year',

  sport: 'sport',
  category: 'category',
  tournament: 'tournament',
  event: 'event',

  marketId: 'marketId',
  marketName: 'marketName',
  marketExample: 'marketExample',
  outcomeId: 'outcomeId',
  outcomeName: 'outcomeName',
  outcomeExample: 'outcomeExample',
  provider: 'provider',
  currency: 'currency',
  betType: 'betType',
  bonusType: 'bonusType',
  deviceType: 'deviceType',
  country: 'country',
  lang: 'lang',
  playerCcf: 'playerCcf',
  betStatus: 'betStatus',
  isLive: 'isLive',
  sourceLayout: 'sourceLayout',
  sourcePage: 'sourcePage',
  sourceSection: 'sourceSection',

  betDate: 'betDate',
  players: 'players',

  playersChange: 'playersChange',
  avgStakeChange: 'avgStakeChange',
  betsChange: 'betsChange',
  turnoverChange: 'turnoverChange',
  ggrChange: 'ggrChange',
  playersChangeAbs: 'playersChangeAbs',
  betsChangeAbs: 'betsChangeAbs',
  turnoverChangeAbs: 'turnoverChangeAbs',
  ggrChangeAbs: 'ggrChangeAbs',
  marginChange: 'marginChange',

  bets: 'bets',
  turnover: 'turnover',
  turnoverOpen: 'turnoverOpen',
  avgStake: 'avgStake',
  ggr: 'ggr',
  margin: 'margin',
  turnoverOdd: 'turnoverOdd',
  ggrOdd: 'ggrOdd',
  marginOdd: 'marginOdd',
  maxStakeEur: 'maxStakeEur',
  maxGgrEur: 'maxGgrEur',
  minGgrEur: 'minGgrEur',
  maxStakeBet: 'maxStakeBet',
  maxGgrBet: 'maxGgrBet',
  minGgrBet: 'minGgrBet',
  freebetBets: 'freebetBets',
  freebetPlayers: 'freebetPlayers',
  freebetTurnover: 'freebetTurnover',
  freebetGgr: 'freebetGgr',
};

export const columnsWidthConfig = {
  [COLUMN.playerId]: 1,
  [COLUMN.username]: 1.25,
  [COLUMN.extPlayerId]: 1.25,
  [COLUMN.betDate]: 1,
  [COLUMN.month]: 1,
  [COLUMN.week]: 1,
  [COLUMN.year]: 1,
  [COLUMN.marketExample]: 2,
  [COLUMN.outcomeExample]: 1,
  [COLUMN.players]: 0.5,
  [COLUMN.bets]: 0.5,
  [COLUMN.turnover]: 0.6,
  [COLUMN.turnoverOpen]: 1,
  [COLUMN.avgStake]: 0.75,
  [COLUMN.ggr]: 0.75,
  [COLUMN.margin]: 0.75,
  [COLUMN.turnoverOdd]: 2,
  [COLUMN.ggrOdd]: 1,
  [COLUMN.marginOdd]: 1,
  [COLUMN.maxStakeEur]: 1,
  [COLUMN.maxGgrEur]: 1,
  [COLUMN.minGgrEur]: 1,
  [COLUMN.maxStakeBet]: 2,
  [COLUMN.maxGgrBet]: 2,
  [COLUMN.minGgrBet]: 2,
  [COLUMN.freebetBets]: 1,
  [COLUMN.freebetPlayers]: 1,
  [COLUMN.freebetTurnover]: 1,
  [COLUMN.freebetGgr]: 1,
  [COLUMN.operator]: 2,
  [COLUMN.brand]: 2,
  [COLUMN.sport]: 2,
  [COLUMN.category]: 2,
  [COLUMN.tournament]: 2,
  [COLUMN.event]: 2,
  [COLUMN.marketId]: 2,
  [COLUMN.marketName]: 2,
  [COLUMN.outcomeId]: 2,
  [COLUMN.outcomeName]: 2,
  [COLUMN.provider]: 2,
  [COLUMN.currency]: 1,
  [COLUMN.betType]: 2,
  [COLUMN.bonusType]: 2,
  [COLUMN.deviceType]: 2,
  [COLUMN.country]: 2,
  [COLUMN.lang]: 1.25,
  [COLUMN.playerCcf]: 2,
  [COLUMN.betStatus]: 2,
  [COLUMN.isLive]: 1.25,
  [COLUMN.sourceLayout]: 2,
  [COLUMN.sourcePage]: 2,
  [COLUMN.sourceSection]: 2,

  [COLUMN.playersChange]: 1,
  [COLUMN.avgStakeChange]: 1.25,
  [COLUMN.betsChange]: 1,
  [COLUMN.turnoverChange]: 1.25,
  [COLUMN.ggrChange]: 1.25,
  [COLUMN.playersChangeAbs]: 1.25,
  [COLUMN.betsChangeAbs]: 1,
  [COLUMN.turnoverChangeAbs]: 1.25,
  [COLUMN.ggrChangeAbs]: 1.25,
  [COLUMN.marginChange]: 1.25,
};

export const columnsOrderConfig = [
  COLUMN.playerId,
  COLUMN.username,
  COLUMN.extPlayerId,
  COLUMN.operator,
  COLUMN.brand,
  COLUMN.month,
  COLUMN.week,
  COLUMN.year,
  COLUMN.sport,
  COLUMN.category,
  COLUMN.tournament,
  COLUMN.event,
  COLUMN.marketId,
  COLUMN.marketName,
  COLUMN.marketExample,
  COLUMN.outcomeId,
  COLUMN.outcomeName,
  COLUMN.outcomeExample,
  COLUMN.provider,
  COLUMN.currency,
  COLUMN.betType,
  COLUMN.bonusType,
  COLUMN.deviceType,
  COLUMN.country,
  COLUMN.lang,
  COLUMN.playerCcf,
  COLUMN.betStatus,
  COLUMN.isLive,
  COLUMN.sourceLayout,
  COLUMN.sourcePage,
  COLUMN.sourceSection,
  COLUMN.betDate,
  COLUMN.players,
  COLUMN.playersChange,
  COLUMN.playersChangeAbs,
  COLUMN.bets,
  COLUMN.betsChange,
  COLUMN.betsChangeAbs,
  COLUMN.turnover,
  COLUMN.turnoverChange,
  COLUMN.turnoverChangeAbs,
  COLUMN.turnoverOpen,
  COLUMN.avgStake,
  COLUMN.avgStakeChange,
  COLUMN.ggr,
  COLUMN.ggrChange,
  COLUMN.ggrChangeAbs,
  COLUMN.margin,
  COLUMN.marginChange,
  COLUMN.turnoverOdd,
  COLUMN.ggrOdd,
  COLUMN.marginOdd,
  COLUMN.maxStakeEur,
  COLUMN.maxGgrEur,
  COLUMN.minGgrEur,
  COLUMN.maxStakeBet,
  COLUMN.maxGgrBet,
  COLUMN.minGgrBet,
  COLUMN.freebetBets,
  COLUMN.freebetPlayers,
  COLUMN.freebetTurnover,
  COLUMN.freebetGgr,
];

export const initialToggledColumns = [
  COLUMN.playerId,
  COLUMN.username,
  COLUMN.extPlayerId,
  COLUMN.betDate,
  COLUMN.week,
  COLUMN.month,
  COLUMN.year,
  COLUMN.players,
  COLUMN.bets,
  COLUMN.turnover,
  COLUMN.turnoverOpen,
  COLUMN.avgStake,
  COLUMN.ggr,
  COLUMN.margin,

  // COLUMN.turnoverOdd,
  // COLUMN.ggrOdd,
  // COLUMN.marginOdd,
  COLUMN.maxStakeEur,
  COLUMN.maxGgrEur,
  COLUMN.minGgrEur,
  // COLUMN.maxStakeBet,
  // COLUMN.maxGgrBet,
  // COLUMN.minGgrBet,
  COLUMN.freebetBets,
  COLUMN.freebetPlayers,
  COLUMN.freebetTurnover,
  COLUMN.freebetGgr,

  COLUMN.operator,
  COLUMN.brand,
  COLUMN.sport,
  COLUMN.category,
  COLUMN.tournament,
  COLUMN.event,
  COLUMN.marketId,
  COLUMN.marketName,
  COLUMN.marketExample,
  COLUMN.outcomeId,
  COLUMN.outcomeName,
  COLUMN.outcomeExample,
  COLUMN.provider,
  COLUMN.currency,
  COLUMN.betType,
  COLUMN.bonusType,
  COLUMN.deviceType,
  COLUMN.country,
  COLUMN.lang,
  COLUMN.playerCcf,
  COLUMN.betStatus,
  COLUMN.isLive,
  COLUMN.sourceLayout,
  COLUMN.sourcePage,
  COLUMN.sourceSection,

  COLUMN.playersChange,
  COLUMN.playersChangeAbs,
  COLUMN.betsChange,
  COLUMN.betsChangeAbs,
  COLUMN.turnoverChange,
  COLUMN.turnoverChangeAbs,
  COLUMN.avgStakeChange,
  COLUMN.ggrChange,
  COLUMN.ggrChangeAbs,
  COLUMN.marginChange,
];

const disabledColumnsConfig = {
  [COLUMN.betDate]: filters => filters.groupBy !== GA_GROUP_BY.day,
  [COLUMN.playerId]: filters => filters.groupBy !== GA_GROUP_BY.playerId,
  [COLUMN.extPlayerId]: filters => filters.groupBy !== GA_GROUP_BY.playerId,
  [COLUMN.username]: filters => filters.groupBy !== GA_GROUP_BY.playerId,
  [COLUMN.operator]: filters =>
    filters.groupBy !== GA_GROUP_BY.operatorId &&
    filters.groupBy !== GA_GROUP_BY.brandId,
  [COLUMN.brand]: filters => filters.groupBy !== GA_GROUP_BY.brandId,
  [COLUMN.month]: filters => filters.groupBy !== GA_GROUP_BY.month,
  [COLUMN.week]: filters => filters.groupBy !== GA_GROUP_BY.week,
  [COLUMN.year]: filters => filters.groupBy !== GA_GROUP_BY.year,
  [COLUMN.sport]: filters =>
    filters.groupBy !== GA_GROUP_BY.sportId &&
    filters.groupBy !== GA_GROUP_BY.categoryId &&
    filters.groupBy !== GA_GROUP_BY.tournamentId &&
    filters.groupBy !== GA_GROUP_BY.eventId,
  [COLUMN.category]: filters =>
    filters.groupBy !== GA_GROUP_BY.categoryId &&
    filters.groupBy !== GA_GROUP_BY.tournamentId &&
    filters.groupBy !== GA_GROUP_BY.eventId,
  [COLUMN.tournament]: filters =>
    filters.groupBy !== GA_GROUP_BY.tournamentId &&
    filters.groupBy !== GA_GROUP_BY.eventId,
  [COLUMN.event]: filters => filters.groupBy !== GA_GROUP_BY.eventId,
  [COLUMN.marketId]: filters => filters.groupBy !== GA_GROUP_BY.marketId,
  [COLUMN.marketName]: filters => filters.groupBy !== GA_GROUP_BY.marketName,
  [COLUMN.marketExample]: filters => filters.groupBy !== GA_GROUP_BY.marketId,
  [COLUMN.outcomeId]: filters => filters.groupBy !== 'Outcome ID',
  [COLUMN.outcomeName]: filters => filters.groupBy !== 'Outcome',
  [COLUMN.outcomeExample]: filters => filters.groupBy !== 'Outcome ID',
  [COLUMN.provider]: filters => filters.groupBy !== GA_GROUP_BY.provider,
  [COLUMN.currency]: filters => filters.groupBy !== GA_GROUP_BY.currency,
  [COLUMN.betType]: filters => filters.groupBy !== GA_GROUP_BY.betType,
  [COLUMN.bonusType]: filters => filters.groupBy !== GA_GROUP_BY.bonusType,
  [COLUMN.deviceType]: filters => filters.groupBy !== GA_GROUP_BY.device,
  [COLUMN.country]: filters => filters.groupBy !== GA_GROUP_BY.country,
  [COLUMN.lang]: filters => filters.groupBy !== GA_GROUP_BY.language,
  [COLUMN.playerCcf]: filters => filters.groupBy !== GA_GROUP_BY.playerCcf,
  [COLUMN.betStatus]: filters => filters.groupBy !== GA_GROUP_BY.betStatus,
  [COLUMN.isLive]: filters => filters.groupBy !== GA_GROUP_BY.isLive,
  [COLUMN.sourceLayout]: filters => filters.groupBy !== GA_GROUP_BY.layout,
  [COLUMN.sourcePage]: filters => filters.groupBy !== GA_GROUP_BY.page,
  [COLUMN.sourceSection]: filters => filters.groupBy !== GA_GROUP_BY.section,

  [COLUMN.playersChange]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.avgStakeChange]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.betsChange]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.turnoverChange]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.ggrChange]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.playersChangeAbs]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.betsChangeAbs]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.turnoverChangeAbs]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.ggrChangeAbs]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
  [COLUMN.marginChange]: filters =>
    !filters.rangeCompareDate ||
    (!filters.rangeCompareDate.rangeFrom && !filters.rangeCompareDate.rangeTo),
};

const columns = [
  {
    label: 'Operator',
    key: COLUMN.operator,
    render: ({ operator }) => {
      return operator ? <StringField>{operator}</StringField> : <EmptyField />;
    },
    sortkey: 'operator',
  },
  {
    label: 'Brand',
    key: COLUMN.brand,
    render: ({ brand, brandId }) => {
      return brand ? <StringField>{brand}</StringField> : <EmptyField />;
    },
    sortkey: 'brand',
  },
  {
    label: 'Category',
    key: COLUMN.category,
    render: ({ category }) => {
      return category ? <StringField>{category}</StringField> : <EmptyField />;
    },
    sortkey: 'category',
  },
  {
    label: 'Tournament',
    key: COLUMN.tournament,
    render: ({ tournamentId, tournament }) => {
      return tournament ? (
        <CopyField value={tournamentId}>
          <LinkField
            target={'_blank'}
            href={`/api/v1/ReporterAPI/report/tournaments/detail/${tournamentId}/get?jsonQuery={"/api/v1/ReporterAPI/report/tournaments/detail/{tournament_id}/get":{"tournament_id":"${tournamentId}"}}`}
          >
            {tournament}
          </LinkField>
        </CopyField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'tournament_id',
  },
  {
    label: 'Event',
    key: COLUMN.event,
    render: ({ event, eventId }) => {
      return eventId ? (
        <StringField title={event}>
          <CopyField value={eventId}>
            <LinkField href={`/events/${eventId}`}>{event}</LinkField>
          </CopyField>
        </StringField>
      ) : (
        <StringField color={'primary'} bold>
          {event}
        </StringField>
      );
    },
    sortkey: 'event_id',
  },
  {
    label: 'Market ID',
    key: COLUMN.marketId,
    render: ({ marketId }, summary) => {
      if (!summary) {
        return null;
      }
      return marketId ? <StringField>{marketId}</StringField> : <EmptyField />;
    },
    sortkey: 'market_id',
  },
  {
    label: 'Market Name',
    key: COLUMN.marketName,
    render: ({ marketName }, summary) => {
      if (!summary) {
        return null;
      }
      return marketName ? (
        <StringField>{marketName}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'market_name',
  },
  {
    label: 'Market Example',
    key: COLUMN.marketExample,
    render: ({ marketExample }, summary) => {
      if (!summary) {
        return null;
      }
      return marketExample ? (
        <StringField>{marketExample}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'market_example',
  },
  {
    label: 'Outcome ID',
    key: COLUMN.outcomeId,
    render: ({ outcomeId }) => {
      return outcomeId ? (
        <StringField>{outcomeId}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'outcome_id',
  },
  {
    label: 'Outcome Name',
    key: COLUMN.outcomeName,
    render: ({ outcomeName }) => {
      return outcomeName ? (
        <StringField>{outcomeName}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'outcome_name',
  },
  {
    label: 'Outcome Example',
    key: COLUMN.outcomeExample,
    render: ({ outcomeExample }) => {
      return outcomeExample ? (
        <StringField>{outcomeExample}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'outcome_example',
  },
  {
    label: 'Provider',
    key: COLUMN.provider,
    render: ({ provider }) => {
      return provider ? <StringField>{provider}</StringField> : <EmptyField />;
    },
    sortkey: 'provider',
  },
  {
    label: 'Currency',
    key: COLUMN.currency,
    render: ({ currency }) => {
      return currency ? <StringField>{currency}</StringField> : <EmptyField />;
    },
    sortkey: 'currency',
  },
  {
    label: 'Bet Type',
    key: COLUMN.betType,
    render: ({ betType }) => {
      return betType ? <StringField>{betType}</StringField> : <EmptyField />;
    },
    sortkey: 'bet_type',
  },
  {
    label: 'Bonus Type',
    key: COLUMN.bonusType,
    render: ({ bonusType }) => {
      return bonusType ? (
        <StringField>{bonusType}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'bonus_type',
  },
  {
    label: 'Device Type',
    key: COLUMN.deviceType,
    render: ({ deviceType }) => {
      return deviceType ? (
        <StringField>{deviceType}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'device_type',
  },
  {
    label: 'Country',
    key: COLUMN.country,
    render: ({ country }) => {
      return country ? <StringField>{country}</StringField> : <EmptyField />;
    },
    sortkey: 'country',
  },
  {
    label: 'Language',
    key: COLUMN.lang,
    render: ({ lang }) => {
      return lang ? <StringField>{lang}</StringField> : <EmptyField />;
    },
    sortkey: 'lang',
  },
  {
    label: 'Player CCF',
    key: COLUMN.playerCcf,
    render: ({ playerCcf }) => {
      return playerCcf ? (
        <StringField>{playerCcf}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'player_ccf',
  },
  {
    label: 'Bet Status',
    key: COLUMN.betStatus,
    render: ({ betStatus }) => {
      return betStatus ? (
        <StringField>{betStatus}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'bet_status',
  },
  {
    label: 'Is Live',
    key: COLUMN.isLive,
    render: ({ isLive }) => {
      return <BooleanField active={isLive} />;
    },
    sortkey: 'is_live',
  },
  {
    label: 'Source Layout',
    key: COLUMN.sourceLayout,
    render: ({ sourceLayout }) => {
      return sourceLayout ? (
        <StringField>{sourceLayout}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'source_layout',
  },
  {
    label: 'Source Page',
    key: COLUMN.sourcePage,
    render: ({ sourcePage }) => {
      return sourcePage ? (
        <StringField>{sourcePage}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'source_page',
  },
  {
    label: 'Source Section',
    key: COLUMN.sourceSection,
    render: ({ sourceSection }) => {
      return sourceSection ? (
        <StringField>{sourceSection}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'source_section',
  },
  {
    label: 'Day',
    key: COLUMN.betDate,
    render: ({ created_at__day }, summary) => {
      if (!summary) {
        return null;
      }

      return created_at__day ? (
        <StringField>
          <DateField date={created_at__day} format={'YYYY/MM/DD'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'created_at__day',
  },
  {
    label: 'Month',
    key: COLUMN.month,
    render: ({ created_at__month }, summary) => {
      if (!summary) {
        return null;
      }
      return created_at__month ? (
        <StringField>
          <DateField date={created_at__month} format={'YYYY/MM'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'created_at__month',
  },
  {
    label: 'Week',
    key: COLUMN.week,
    render: ({ created_at__week }) => {
      return created_at__week ? (
        <StringField>
          <DateField date={created_at__week} format={'YYYY/MM/DD'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'created_at__week',
  },
  {
    label: 'Year',
    key: COLUMN.year,
    render: ({ created_at__year }) => {
      return created_at__year ? (
        <StringField>
          <DateField date={created_at__year} format={'YYYY'} />
        </StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'created_at__year',
  },
  {
    label: 'Sport',
    key: COLUMN.sport,
    render: ({ sport }, summary) => {
      if (!summary) {
        return null;
      }
      return sport ? <StringField>{sport}</StringField> : <EmptyField />;
    },
    sortkey: 'sport',
  },
  {
    label: 'Player ID',
    key: COLUMN.playerId,
    render: ({ playerId }, summary) => {
      if (!summary) {
        return null;
      }

      return playerId ? (
        <CopyField value={playerId}>
          <LinkField
            href={`/api/v1/OperatorAPI/players/v3/${playerId}/get?jsonQuery={"/api/v1/OperatorAPI/players/v2/{player_id}/get":{"player_id":"${playerId}"}}`}
          >
            {playerId}
          </LinkField>
        </CopyField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'player_id',
  },
  {
    label: 'Username',
    key: COLUMN.username,
    render: ({ username }, summary) => {
      if (!summary) {
        return null;
      }
      return username ? <StringField>{username}</StringField> : <EmptyField />;
    },
    sortkey: 'username',
  },
  {
    label: 'Ext. Player ID',
    key: COLUMN.extPlayerId,
    render: ({ extPlayerId }, summary) => {
      if (!summary) {
        return null;
      }
      return extPlayerId ? (
        <StringField>{extPlayerId}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'ext_player_id',
  },
  {
    label: 'Players',
    key: COLUMN.players,
    justifyContent: 'center',
    direction: 'column',
    alignItems: 'flex-end',

    render: ({ players }, summary) => {
      return players ? (
        <>
          <StringField>{players}</StringField>
          {summary && (
            <StringField color={'disabled'}>
              <PercentField total={summary.players} value={players} />
            </StringField>
          )}
        </>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'players',
  },

  {
    label: 'Bets',
    key: COLUMN.bets,
    justifyContent: 'center',
    direction: 'column',
    alignItems: 'flex-end',
    render: ({ bets }, summary) => {
      return bets ? (
        <>
          <StringField>{bets}</StringField>
          {summary && (
            <StringField color={'disabled'}>
              <PercentField total={summary.bets} value={bets} />
            </StringField>
          )}
        </>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'bets',
  },
  {
    label: 'Turnover',
    key: COLUMN.turnover,
    justifyContent: 'center',
    direction: 'column',
    alignItems: 'flex-end',
    render: ({ turnover }, summary) => {
      return turnover ? (
        <>
          <CurrencyField bold amount={turnover} />
          {summary && (
            <StringField color={'disabled'}>
              <PercentField total={summary.turnover} value={turnover} />
            </StringField>
          )}
        </>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'turnover',
  },
  {
    label: 'Turnover Open',
    key: COLUMN.turnoverOpen,
    justifyContent: 'flex-end',
    render: ({ turnoverOpen }) => {
      return turnoverOpen ? (
        <CurrencyField amount={turnoverOpen} />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'turnover_open',
  },
  {
    label: 'Avg Stake',
    key: COLUMN.avgStake,
    justifyContent: 'flex-end',
    render: ({ avgStake }) => {
      return avgStake ? <CurrencyField amount={avgStake} /> : <EmptyField />;
    },
    sortkey: 'avg_stake',
  },
  {
    label: 'GGR',
    key: COLUMN.ggr,
    render: ({ ggr }) => {
      return ggr ? (
        <CurrencyField
          bold
          amount={ggr.toFixed(2)}
          color={ggr === 0 ? 'disabled' : ggr > 0 ? 'success' : 'red'}
        />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'ggr',
  },
  {
    label: 'Margin',
    key: COLUMN.margin,
    render: ({ margin }) => {
      return margin ? (
        <CurrencyField
          bold
          amount={margin.toFixed(2)}
          color={margin === 0 ? 'disabled' : margin > 0 ? 'success' : 'red'}
        />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'margin',
  },
  {
    label: 'Turnover (Odd)',
    key: COLUMN.turnoverOdd,
    render: ({ turnover_odd }) => {
      return turnover_odd ? (
        <StringField>{turnover_odd}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'turnover_odd',
  },
  {
    label: 'GGR (Odd)',
    key: COLUMN.ggrOdd,
    render: ({ ggrOdd }) => {
      return ggrOdd ? <StringField>{ggrOdd}</StringField> : <EmptyField />;
    },
    sortkey: 'ggr_odd',
  },
  {
    label: 'Margin (Odd)',
    key: COLUMN.marginOdd,
    render: ({ marginOdd }) => {
      return marginOdd ? (
        <CurrencyField
          bold
          amount={marginOdd.toFixed(2)}
          color={marginOdd === 0 ? 'disabled' : ggr > 0 ? 'success' : 'red'}
        />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'margin_odd',
  },
  {
    label: 'Max Stake (Eur)',
    key: COLUMN.maxStakeEur,
    render: ({ maxStakeEur, maxStakeBet }) => {
      return maxStakeEur ? (
        <LinkField
          target={'_blank'}
          href={`/api/v1/BetSlipsAdmin/ch/bet/${maxStakeBet}/get?jsonQuery={"/api/v1/BetSlipsAdmin/ch/bet/{betslip_id}/get":{"betslip_id":"${maxStakeBet}"}}`}
        >
          <CurrencyField bold amount={maxStakeEur} />
        </LinkField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'max_stake_eur',
  },
  {
    label: 'Max GGR (Eur)',
    key: COLUMN.maxGgrEur,
    render: ({ maxGgrEur, maxGgrBet }) => {
      return maxGgrEur ? (
        <LinkField
          target={'_blank'}
          href={`/api/v1/BetSlipsAdmin/ch/bet/${maxGgrBet}/get?jsonQuery={"/api/v1/BetSlipsAdmin/ch/bet/{betslip_id}/get":{"betslip_id":"${maxGgrBet}"}}`}
        >
          <CurrencyField bold amount={maxGgrEur} />
        </LinkField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'max_ggr_eur',
  },
  {
    label: 'Min GGR (Eur)',
    key: COLUMN.minGgrEur,
    render: ({ minGgrEur, minGgrBet }) => {
      return minGgrEur ? (
        <LinkField
          target={'_blank'}
          href={`/api/v1/BetSlipsAdmin/ch/bet/${minGgrBet}/get?jsonQuery={"/api/v1/BetSlipsAdmin/ch/bet/{betslip_id}/get":{"betslip_id":"${minGgrBet}"}}`}
        >
          <CurrencyField bold amount={minGgrEur} />
        </LinkField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'min_ggr_eur',
  },
  {
    label: 'Max Stake Bet',
    key: COLUMN.maxStakeBet,
    render: ({ maxStakeBet }) => {
      return maxStakeBet ? (
        <CurrencyField amount={maxStakeBet} />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'max_stake_bet',
  },
  {
    label: 'Max GGR Bet',
    key: COLUMN.maxGgrBet,
    render: ({ maxGgrBet }) => {
      return maxGgrBet ? <CurrencyField amount={maxGgrBet} /> : <EmptyField />;
    },
    sortkey: 'max_ggr_bet',
  },
  {
    label: 'Min GGR Bet',
    key: COLUMN.minGgrBet,
    render: ({ minGgrBet }) => {
      return minGgrBet ? (
        <CurrencyField bold amount={minGgrBet} />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'min_ggr_bet',
  },
  {
    label: 'Bets (Freebet)',
    key: COLUMN.freebetBets,
    render: ({ freebetBets }) => {
      return freebetBets ? (
        <StringField>{freebetBets}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'freebet_bets',
  },
  {
    label: 'Players (Freebet)',
    key: COLUMN.freebetPlayers,
    render: ({ freebetPlayers }) => {
      return freebetPlayers ? (
        <StringField>{freebetPlayers}</StringField>
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'freebet_players',
  },
  {
    label: 'Turnover (Freebet)',
    key: COLUMN.freebetTurnover,
    render: ({ freebetTurnover }) => {
      return freebetTurnover ? (
        <CurrencyField amount={freebetTurnover} />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'freebet_turnover',
  },
  {
    label: 'GGR (Freebet)',
    key: COLUMN.freebetGgr,
    render: ({ freebetGgr }) => {
      return freebetGgr ? (
        <CurrencyField
          amount={freebetGgr}
          color={
            freebetGgr === 0 ? 'disabled' : freebetGgr > 0 ? 'success' : 'red'
          }
        />
      ) : (
        <EmptyField />
      );
    },
    sortkey: 'freebet_ggr',
  },
  // Compare columns
  {
    label: 'Players Change',
    key: COLUMN.playersChange,
    render: ({ playersChange }) => {
      return playersChange ? (
        <StringField>{playersChange}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Avg. Stake Change',
    key: COLUMN.avgStakeChange,
    render: ({ avgStakeChange }) => {
      return avgStakeChange ? (
        <StringField>{avgStakeChange}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Bets Change',
    key: COLUMN.betsChange,
    render: ({ betsChange }) => {
      return betsChange ? (
        <StringField>{betsChange}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Turnover Change',
    key: COLUMN.turnoverChange,
    render: ({ turnoverChange }) => {
      return turnoverChange ? (
        <StringField>{turnoverChange}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'GGR Change',
    key: COLUMN.ggrChange,
    render: ({ ggrChange }) => {
      return ggrChange ? (
        <StringField>{ggrChange}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Players Change Abs',
    key: COLUMN.playersChangeAbs,
    render: ({ playersChangeAbs }) => {
      return playersChangeAbs ? (
        <StringField>{playersChangeAbs}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Bets Change Abs',
    key: COLUMN.betsChangeAbs,
    render: ({ betsChangeAbs }) => {
      return betsChangeAbs ? (
        <StringField>{betsChangeAbs}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Turnover Change Abs',
    key: COLUMN.turnoverChangeAbs,
    render: ({ turnoverChangeAbs }) => {
      return turnoverChangeAbs ? (
        <StringField>{turnoverChangeAbs}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'GGR Change Abs',
    key: COLUMN.ggrChangeAbs,
    render: ({ ggrChangeAbs }) => {
      return ggrChangeAbs ? (
        <StringField>{ggrChangeAbs}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
  {
    label: 'Margin Change',
    key: COLUMN.marginChange,
    render: ({ marginChange }) => {
      return marginChange ? (
        <StringField>{marginChange}</StringField>
      ) : (
        <EmptyField />
      );
    },
  },
].map(c => ({ ...c, resizable: true }));

const GamingActivityTable = ({
  expandable,
  renderExpandedRow,
  filters = {},
  data,
  summary,
  orderBy,
  onChangeOrderBy = () => {},
  loading,
}) => {
  const isOperator = useIsOperator();

  const disabledColumns = useMemo(() => {
    const disabledColumnsForOperator = [
      COLUMN.sport,
      COLUMN.category,
      COLUMN.tournament,
      COLUMN.provider,
    ];
    let resultColumns = Object.keys(disabledColumnsConfig).filter(columnKey => {
      const column = disabledColumnsConfig[columnKey];

      return typeof column === 'function' ? column(filters) : column;
    });

    if (isOperator) {
      resultColumns = [...resultColumns, ...disabledColumnsForOperator];
    }
    return resultColumns;
  }, [filters, isOperator]);

  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  return (
    <Table
      id={null}
      data={data}
      columns={columns}
      order={order}
      initialToggledColumns={initialToggledColumns}
      columnsWidth={columnsWidthConfig}
      disabledColumns={disabledColumns}
      summary={summary}
      expandable={expandable}
      renderExpandedRow={renderExpandedRow}
      onReorder={onReorder}
      orderBy={orderBy}
      onChangeOrderBy={onChangeOrderBy}
      onChangeColumnsWidth={e => {
        console.log('changed', e);
      }}
      loading={loading}
      scrollable
    />
  );
};

export default GamingActivityTable;
