import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import AddFilterMenu from './AddFilterMenu';
import isEqual from 'lodash/isEqual';
import { getFilters } from '../../utils/schemaHelper';
import FiltersFormGroup from './FiltersFormGroup';
import Button from '../../v2/components/Button/Button';
import { useCreatePresetDialog } from '../../providers/CreatePresetDialogProvider';
import { LocalQueryContext } from '../../providers/LocalQueryProvider';
import BookmarkIcon from '../../icons/controls/BookmarkIcon';
import useQueryByPath, { HistoryMethodEnum } from '../../hooks/useQueryByPath';
import pickWithoutUndefined from '../../utils/pickWithoutUndefined';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm } from 'react-hook-form';
import useFiltersPreset from './useFiltersPreset';
import { useRoutes } from '../../providers/RoutesProvider';
import useAvailableAndVisibleFilters from './useAvailableAndVisibleFilters';
import ResetFiltersButton from './ResetFiltersButton';
import useResetOrders from '../../hooks/useResetOrders';
import getDefaultValue from '../../utils/prepareSchema/getDefaultValue';
import { useNavigate } from 'react-router-dom';
import getQueryString from '../../utils/getQueryString';
import { useUiComponentSchema } from '../../providers/UiComponentSchemaProvider';
import get from 'lodash/get';

export const getSearchQueryWithoutOrder = searchQuery => {
  const { order_by, order_by_asc_desc, offset, limit, ...queryWithoutOrder } =
    searchQuery;

  return queryWithoutOrder;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  filtersTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },
  filtersTop: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '&>*': {
      margin: '8px 8px 0 8px',
    },
  },

  bookmarkIcon: {
    fontSize: '11px',
    marginTop: 2,
    marginRight: 8,
  },

  bottomButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% + 16px)',
    marginRight: -theme.spacing(1),
    marginLeft: -theme.spacing(1),
  },
  bottomButtonsItem: {
    textTransform: 'initial',
  },
  bottomButtonsItemText: {
    minWidth: 'initial',
    marginRight: 0,
    marginLeft: 0,
  },
  [theme.breakpoints.down('md')]: {
    bottomButtons: {
      flexDirection: 'column',
    },
  },
}));

const FiltersForm = ({
  externalErrors,
  clearExternalErrors,
  onRefresh,
  path,
  dataLoading,
}) => {
  const classes = useStyles();
  const { localQuery, setLocalQuery } = useContext(LocalQueryContext);
  let { query, setQuery } = useQueryByPath(path);

  const uiSchema = useUiComponentSchema();
  const hidePresets = get(uiSchema, 'ui:options.hide_presets');

  const navigate = useNavigate();

  const routes = useRoutes();

  const responseErrors = useMemo(
    () =>
      externalErrors &&
      externalErrors.response &&
      externalErrors.response.form_errors,
    [externalErrors]
  );

  const routeParameters = routes[path].parameters;
  const routeResponses = routes[path].responses;
  const routeLinks =
    routeResponses && routeResponses[200] && routeResponses[200].links;

  const filters = useMemo(() => getFilters(routeParameters), [routeParameters]);

  const [saveFilters, activePreset] = useFiltersPreset();
  const initialFilters = useMemo(() => {
    return getSearchQueryWithoutOrder(query);
  }, [query]);

  const defaultSchemaValues = useMemo(
    () =>
      filters.reduce((defaultValues, f) => {
        defaultValues[f.name] = getDefaultValue(f.schema);
        return defaultValues;
      }, {}),
    [filters]
  );

  const defaultPresetValues = activePreset && activePreset.data.initial_filters;

  const defaultValues = useMemo(() => {
    return pickWithoutUndefined(defaultPresetValues || defaultSchemaValues);
  }, [defaultSchemaValues, defaultPresetValues]);

  const availableAndVisibleFilters = useAvailableAndVisibleFilters(
    filters,
    query
  );

  const { visibleFilters, availableFilters, showFilter, hideFilter } =
    availableAndVisibleFilters;

  // const defaultValuesFn = useMemo(() => {
  //   return async () => defaultValues;
  // }, [defaultValues]);

  const form = useForm({
    // defaultValues: defaultValuesFn,
  });

  const { handleSubmit, getValues, reset, setError, clearErrors, setValue } =
    form;

  useEffect(() => {
    if (initialFilters && defaultValues) {
      reset(defaultValues);
      reset(initialFilters, {
        keepDefaultValues: true,
      });
    }
  }, [initialFilters, reset, defaultValues]);

  useEffect(() => {
    clearErrors();

    if (responseErrors) {
      for (let field in responseErrors) {
        const filter = visibleFilters.find(item => item.name === field);
        if (typeof filter?.type === 'object') {
          for (let objectField in responseErrors[field]) {
            setError(`${field}.${objectField}`, {
              message: responseErrors[field][objectField],
            });
          }
        } else {
          setError(field, {
            message: responseErrors[field],
          });
        }
      }
    }
  }, [responseErrors, setError, clearErrors, visibleFilters]);

  const getWithHiddenDefault = query => {
    return Object.keys(query).reduce((acc, key) => {
      const hiddenFilter = availableFilters.find(filter => filter.name === key);

      if (hiddenFilter) {
        return {
          ...acc,
          [key]: defaultValues[key],
        };
      }

      return acc;
    }, query);
  };

  const resetOrders = useResetOrders();

  const onSubmit = filters => {
    const queryWithoutOrder = getSearchQueryWithoutOrder(query);
    const withHiddenDefault = getWithHiddenDefault(queryWithoutOrder);

    const queryWithFilters = {
      ...withHiddenDefault,
      ...filters,
      offset: 0,
    };

    setLocalQuery({
      ...localQuery,
      ...queryWithFilters,
      offset: 0,
    });

    // TODO: need review
    const linkPaths = routeLinks
      ? Object.values(routeLinks).reduce((acc, { path }) => {
          acc[path] = {
            ...queryWithFilters,
          };
          return acc;
        }, {})
      : {};

    if (
      isEqual(
        pickWithoutUndefined(queryWithoutOrder),
        pickWithoutUndefined(getSearchQueryWithoutOrder(queryWithFilters))
      )
    ) {
      if (onRefresh) {
        onRefresh();
      } else {
        resetOrders();
        setQuery(query, HistoryMethodEnum.REPLACE, Date.now());
      }
    } else {
      resetOrders({
        [path]: queryWithFilters,
        ...linkPaths, // TODO: need review
      });
    }
  };

  // TODO: need review

  useEffect(() => {
    if (activePreset || !onRefresh) {
      return;
    }

    const linkPaths = routeLinks
      ? Object.values(routeLinks).reduce((acc, { path }) => {
          acc[path] = {
            ...defaultSchemaValues,
          };
          return acc;
        }, {})
      : {};

    navigate({
      search: getQueryString(linkPaths, false),
    });
  }, [activePreset, navigate, routeLinks, defaultSchemaValues, onRefresh]);

  const { open: openCreatePreset } = useCreatePresetDialog();

  const persistFilters = useCallback(
    (values = {}) => {
      activePreset &&
        !activePreset.is_default &&
        saveFilters({
          ...getValues(),
          ...values,
        });

      clearExternalErrors();
    },
    [getValues, saveFilters, activePreset, clearExternalErrors]
  );

  const resetField = useCallback(
    (field, value) => {
      setValue(
        field,
        typeof value === 'undefined' ? defaultValues[field] : value
      );
      persistFilters({
        [field]: typeof value === 'undefined' ? defaultValues[field] : value,
      });
    },
    [setValue, defaultValues, persistFilters]
  );

  const onResetForm = useCallback(() => {
    reset({
      ...defaultValues,
    });
    saveFilters({
      ...defaultValues,
    });
  }, [reset, saveFilters, defaultValues]);

  return (
    <FormProvider {...form}>
      <div className={classes.root}>
        <div className={classes.root}>
          {!!visibleFilters.length ? (
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <FiltersFormGroup
                persistFilters={persistFilters}
                onResetFilterValue={(filter, value) => {
                  resetField(filter.name, value);
                }}
                fullWidth={true}
                onHideFilterClick={filter => {
                  resetField(filter.name);
                  hideFilter(filter);
                }}
                filters={visibleFilters.filter(
                  filter => filter.type === 'search_select'
                )}
              />

              <FiltersFormGroup
                persistFilters={persistFilters}
                onResetFilterValue={(filter, value) => {
                  resetField(filter.name, value);
                }}
                onHideFilterClick={filter => {
                  resetField(filter.name);
                  hideFilter(filter);
                }}
                filters={visibleFilters.filter(
                  filter =>
                    filter.type !== 'boolean' &&
                    filter.type !== 'search_select' &&
                    filter.type !== 'object'
                )}
              />

              <FiltersFormGroup
                persistFilters={persistFilters}
                onResetFilterValue={(filter, value) => {
                  resetField(filter.name, value);
                }}
                onHideFilterClick={filter => {
                  resetField(filter.name);
                  hideFilter(filter);
                }}
                filters={visibleFilters.filter(
                  filter => filter.type === 'boolean'
                )}
              />

              <FiltersFormGroup
                persistFilters={persistFilters}
                onResetFilterValue={(filter, value) => {
                  resetField(filter.name, value);
                }}
                onHideFilterClick={filter => {
                  resetField(filter.name);
                  hideFilter(filter);
                }}
                filters={visibleFilters.filter(
                  filter => filter.type === 'object'
                )}
              />

              <div className={classes.bottomButtons}>
                <div>
                  {!!availableFilters.length && (
                    <AddFilterMenu
                      labelText={'Add more filters'}
                      filters={availableFilters}
                      onFilterClick={showFilter}
                    />
                  )}
                </div>

                <div className={classes.formActions}>
                  <ResetFiltersButton
                    className={clsx(
                      classes.bottomButtonsItem,
                      classes.bottomButtonsItemText
                    )}
                    onClick={onResetForm}
                  />
                  {activePreset && !hidePresets && (
                    <Button
                      variant="contained"
                      color="lightBlue"
                      className={classes.bottomButtonsItem}
                      onClick={() => {
                        openCreatePreset(getValues());
                      }}
                    >
                      <BookmarkIcon className={classes.bookmarkIcon} />
                      Save as Preset
                    </Button>
                  )}

                  <Button
                    disabled={dataLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.bottomButtonsItem}
                    data-cy={'apply-filters'}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </form>
          ) : (
            !!availableFilters.length && (
              <form className={classes.form}>
                <div className={classes.bottomButtons}>
                  <div>
                    <AddFilterMenu
                      labelText={'Add filter'}
                      filters={availableFilters}
                      onFilterClick={showFilter}
                    />
                  </div>
                </div>
              </form>
            )
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default FiltersForm;
