import makeStyles from '@mui/styles/makeStyles';
import { memo } from 'react';
import UndoIcon from '../../../icons/controls/UndoIcon';

const useStyles = makeStyles({
  undoButton: {
    borderRadius: '50%',
    backgroundColor: '#E0EBF8',
    cursor: 'pointer',
    width: 28,
    height: 28,
    marginRight: '16px',
  },
});

const UndoChangeButton = ({ onClick = () => {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.undoButton} onClick={onClick}>
      <UndoIcon />
    </div>
  );
};

export default memo(UndoChangeButton);
