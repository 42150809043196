import React, { forwardRef, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import TableBox from './TableBox';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useResizeObserver from 'use-resize-observer';
import SimpleBar from 'simplebar-react';
import TableEmptyPlaceholder from './TableEmptyPlaceholder';
import TableLoadingPlaceholder from './TableLoadingPlaceholder';
import Button from '../Button/Button';
import Scrollbar from '../../../components/Scrollbar/Scrollbar';

const SummaryLabel = styled('div')`
  background: transparent;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.2px;
  padding-left: 16px;
  padding-top: 8px;
  margin-bottom: 4px;
`;

const useStyles = makeStyles({
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 4,
  },
});

const FancyTable = forwardRef(
  (
    {
      id = 'id',
      tableRef,
      loading,
      renderHeaderRow,
      renderRow,
      renderSummaryRow,
      data,
      scrollable,
      summary,
      error,
      onUndo = () => {},
      withUndo = false,
      limit,
      showApplyRequiredPlaceholder,
      isCollapsible = false,
      collapseLimit = 2,
      onCollapse = () => {},
      isInitialExpanded = true,
      rowsMaxHeight,
    },
    ref
  ) => {
    const classes = useStyles();
    const scrollableNodeRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(null);
    const [isCollapsed, setCollapsed] = useState(!isInitialExpanded);

    useResizeObserver({
      ref: scrollableNodeRef,
      onResize: rect => {
        setContainerWidth(rect.width);
      },
    });

    const renderTable = useMemo(() => {
      const scrollbar = scrollableNodeRef.current;

      const renderTableComponent = () => {
        if (error) {
          return (
            <TableEmptyPlaceholder
              error
              fixed={scrollable}
              containerWidth={scrollable && containerWidth}
            />
          );
        }

        if (data.length === 0 && showApplyRequiredPlaceholder) {
          return (
            <TableEmptyPlaceholder
              showApplyRequiredPlaceholder
              fixed={scrollable}
              containerWidth={scrollable && containerWidth}
            />
          );
        }

        if (data.length === 0 && !loading) {
          return (
            <TableEmptyPlaceholder
              fixed={scrollable}
              containerWidth={scrollable && containerWidth}
            />
          );
        }

        if (loading) {
          return <TableLoadingPlaceholder rowHeight={40} limit={limit} />;
        }

        return (
          <SimpleBar style={{ maxHeight: rowsMaxHeight }}>
            {data
              .slice(0, isCollapsed ? collapseLimit : data.length)
              .map((rowData, rowIndex) => {
                // can pass id as array to create unique rowId from multiple data fields
                const rowId =
                  id === null
                    ? rowIndex
                    : Array.isArray(id)
                    ? id.reduce((acc, idPart) => {
                        return `${acc}${rowData[idPart]}`;
                      }, '')
                    : rowData[id];

                return (
                  <div
                    key={rowId}
                    data-cy-table-row={rowId}
                    className={clsx('tableRow')}
                  >
                    {renderRow({
                      rowIndex,
                      rowData,
                      rowId,
                      summary,
                      withUndo,
                      onUndo,
                    })}
                  </div>
                );
              })}
          </SimpleBar>
        );
      };

      return (
        <TableBox
          scrollable={scrollable}
          scrollbar={scrollbar}
          containerWidth={scrollbar && containerWidth}
          ref={tableRef}
        >
          {renderHeaderRow}
          {renderTableComponent()}

          {renderSummaryRow ? (
            <div>
              <SummaryLabel>Summary</SummaryLabel>
              {renderSummaryRow}
            </div>
          ) : null}
        </TableBox>
      );
    }, [
      data,
      tableRef,
      id,
      loading,
      renderHeaderRow,
      renderRow,
      renderSummaryRow,
      scrollable,
      summary,
      scrollableNodeRef,
      containerWidth,
      withUndo,
      onUndo,
      error,
      limit,
      collapseLimit,
      isCollapsed,
      showApplyRequiredPlaceholder,
      rowsMaxHeight,
    ]);

    const renderCollapseButton = useMemo(() => {
      if (!isCollapsible || error || loading || data.length <= collapseLimit) {
        return null;
      }

      return (
        <Box className={classes.showMoreContainer}>
          <Button
            variant={'text'}
            onClick={() => {
              setCollapsed(!isCollapsed);
              onCollapse();
            }}
          >
            {isCollapsed ? 'Show more' : 'Show less'}
          </Button>
        </Box>
      );
    }, [
      collapseLimit,
      data.length,
      error,
      loading,
      isCollapsible,
      isCollapsed,
      onCollapse,
      classes,
    ]);

    if (scrollable) {
      return (
        <>
          <Scrollbar scrollableNodeRef={scrollableNodeRef}>
            {renderTable}
          </Scrollbar>
          {renderCollapseButton}
        </>
      );
    }

    return (
      <>
        {renderTable}
        {renderCollapseButton}
      </>
    );
  }
);

export default FancyTable;
