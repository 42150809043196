import React, { useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PresetCard from '../../../uiComponents/Filters/PresetCard';
import { usePresets } from '../../providers/PresetsProvider';
import PresetsSkeleton from './PresetsSkeleton';
import useDraggableInPortal from '../../hooks/useDraggableInPortal';
import { useApplyRequired } from '../../providers/ApplyRequiredProvider';
import useFiltersQuery from '../../hooks/useFiltersQuery';

const useStyles = makeStyles(() => ({
  presetCardsContainer: {
    paddingTop: 12,
  },

  presetCardsRoot: {
    minWidth: '100%',
    position: 'relative',
    marginLeft: -24,
    marginRight: -24,

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 16,
      background: 'linear-gradient(-90deg, rgba(255, 255, 255, 0), #fff)',
      height: '100%',
      left: 8,
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 16,
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0), #fff)',
      height: '100%',
      right: 8,
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
    },
  },
  presetCards: {
    display: 'block',
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const Presets = ({ tableName }) => {
  const classes = useStyles();
  const [_, setFiltersQuery] = useFiltersQuery();
  const { setApplyRequired } = useApplyRequired(tableName);
  const renderDraggable = useDraggableInPortal();

  const {
    presets,
    setActivePresetId,
    activePreset,
    removePreset,
    reorderPresets,
  } = usePresets();

  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onApplyPreset = useCallback(
    preset => {
      setActivePresetId(preset.id);
      setFiltersQuery({ ...preset.data.filters });
      setApplyRequired(false);
    },
    [setActivePresetId, setFiltersQuery, setApplyRequired]
  );

  const [isDragging, setIsDragging] = useState(false);

  const onBeforeDragStart = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const onDragEnd = useCallback(
    result => {
      setIsDragging(false);

      if (!result.destination) {
        return;
      }

      const ordered = reorder(
        presets,
        result.source.index,
        result.destination.index
      );

      reorderPresets(ordered);
    },
    [setIsDragging, reorder, presets, reorderPresets]
  );

  const deletePresetHandle = useCallback(
    id => {
      removePreset(id);
    },
    [removePreset]
  );

  return (
    <div className={classes.presetCardsContainer}>
      <DragDropContext
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <div className={classes.presetCardsRoot}>
              <div
                className={classes.presetCards}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {presets ? (
                  presets.map((preset, index) => {
                    return (
                      <Draggable
                        key={preset.id}
                        draggableId={preset.id}
                        index={index}
                      >
                        {renderDraggable((provided, snapshot) => (
                          <PresetCard
                            isDragging={isDragging}
                            draggableProvided={provided}
                            draggableSnapshot={snapshot}
                            isActive={
                              activePreset && preset.id === activePreset.id
                            }
                            preset={preset}
                            onApply={() => {
                              onApplyPreset(preset);
                            }}
                            onRemove={() => deletePresetHandle(preset.id)}
                          />
                        ))}
                      </Draggable>
                    );
                  })
                ) : (
                  <PresetsSkeleton />
                )}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Presets;
