import { isEmpty, isEqual, omit } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

//in some cases we need to adjust columns width by maximal cell content size
//in our realization we need to use js for it
//template generated with 'auto' in template and than calculate max after columns added into DOM
const useTableMaxContentSizesInColumns = ({
  tableRef = {},
  toggledColumns = [],
  loading = false,
  data,
  columnWidth,
  setColumnWidth = () => {},
  fixedColumns = {},
  initialColumnWidth = {},
}) => {
  const columnAdjustedSizes = useRef({});

  useEffect(() => {
    setColumnWidth({});
    columnAdjustedSizes.current = {};
  }, [data, loading, setColumnWidth]);

  useEffect(() => {
    if (!isEmpty(tableRef) && !loading) {
      const table = tableRef.current;
      const tableParent = table?.parentNode;
      const row = table?.querySelector('.tableRow');

      const rowWidth = row?.getBoundingClientRect().width;
      const tableParentWidth = tableParent?.getBoundingClientRect().width;

      const maxColumnSizes = toggledColumns.reduce(
        (accumulator, currentValue) => {
          if (
            fixedColumns[currentValue] &&
            initialColumnWidth[currentValue] &&
            isEmpty(columnAdjustedSizes.current)
          ) {
            return {
              ...accumulator,
              [currentValue]: +initialColumnWidth[currentValue].slice(0, -2),
            };
          }

          const columnCells = [...table.querySelectorAll(`#${currentValue}`)];

          if (!isEmpty(columnCells)) {
            const cellsWidths = columnCells.map(
              value => +value.getBoundingClientRect().width
            );

            return {
              ...accumulator,
              [currentValue]: Math.ceil(Math.max(...cellsWidths)),
            };
          }

          return accumulator;
        },
        {}
      );

      const { px, ...currentSizes } = columnWidth;

      if (!isEmpty(maxColumnSizes) && !isEqual(currentSizes, maxColumnSizes)) {
        columnAdjustedSizes.current = maxColumnSizes;
        setColumnWidth({
          ...maxColumnSizes,
          px: rowWidth && tableParentWidth && rowWidth > tableParentWidth,
        });
      }
    }
  }, [
    tableRef,
    columnWidth,
    setColumnWidth,
    toggledColumns,
    loading,
    columnAdjustedSizes,
    fixedColumns,
    initialColumnWidth,
  ]);

  return columnAdjustedSizes.current;
};

export default useTableMaxContentSizesInColumns;
