import RelatedContingencyAutocomplete, {
  RelatedContingencySelectMode,
} from './RelatedContingencyAutocomplete';
import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Checkbox from '../Checkbox';
import {
  FancySelectOption,
  FancySelectOptionRight,
} from '../FancySelect/FancySelect';

export const COMPETITORS_QUERY = gql`
  query getCompetitorsNames(
    $query: String!
    $limit: Int
    $offset: Int
    $categories_ids: [ID!]
    $competitors_ids: [ID!]
    $tournaments_ids: [ID!]
    $events_ids: [ID!]
  ) {
    data: getCompetitorsNames(
      query: $query
      limit: $limit
      offset: $offset
      categories_ids: $categories_ids
      tournaments_ids: $tournaments_ids
      competitors_ids: $competitors_ids
      events_ids: $events_ids
    ) {
      label
      value
    }
  }
`;

const CompetitorAutocomplete = ({
  withSameOption,
  value = {
    type: RelatedContingencySelectMode.LIST,
    value: [],
  },
  categories,
  tournaments,
  events,
  onChange,
  ...props
}) => {
  const params = useMemo(
    () => ({
      categories_ids: categories,
      tournaments_ids: tournaments,
      events_ids: events,
    }),
    [categories, tournaments, events]
  );

  const mode = value.type;

  const onSameMode = useCallback(() => {
    onChange({
      value: [],
      type:
        value.type === RelatedContingencySelectMode.SAME
          ? RelatedContingencySelectMode.LIST
          : RelatedContingencySelectMode.SAME,
    });
  }, [value, onChange]);

  return (
    <RelatedContingencyAutocomplete
      allLabel={'All Competitors'}
      extraOptions={
        withSameOption && (
          <>
            <FancySelectOption onClick={onSameMode}>
              Same Competitor{' '}
              <FancySelectOptionRight>
                <Checkbox value={mode === RelatedContingencySelectMode.SAME} />
              </FancySelectOptionRight>
            </FancySelectOption>
          </>
        )
      }
      label={'Select Competitor'}
      gqlQuery={COMPETITORS_QUERY}
      params={params}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default CompetitorAutocomplete;
