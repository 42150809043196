import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const AppWrapper = ({ store }) => {
  return (
    <Router>
      <Provider store={store}>
        {/*<Routes>*/}
        {/*  <Route path="/">*/}
        <App />
        {/*</Route>*/}
        {/*<Route path="/v2">*/}
        {/*  <AppV2 />*/}
        {/*</Route>*/}
        {/*</Routes>*/}
      </Provider>
    </Router>
  );
};

export default AppWrapper;
