import React, { forwardRef } from 'react';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const IconsByCode = {};

function importAll(r) {
  r.keys().forEach(key => (IconsByCode[key] = r(key).default));
}
importAll(require.context(`!@svgr/webpack!./menu`, false, /\.svg$/));

const getIconPathByName = name => `./${name}.svg`;

const useStyles = makeStyles({
  menuIconContainer: {},
});

const MenuIcon = forwardRef(({ name, ...props }, ref) => {
  const classes = useStyles();

  if (!name) {
    // TODO design icon fallback
    return null;
  }

  const Icon = IconsByCode[getIconPathByName(name)];

  return (
    <div className={classes.menuIconContainer}>
      <SvgIcon viewBox={'0 0 32 32'} {...props}>
        {Icon && <Icon ref={ref} />}
      </SvgIcon>
    </div>
  );
});

export default MenuIcon;
