import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid/Grid';
import FormGroup from '../../../../../components/Form/FormGroup';
import BannerTitleField from '../../BannerFields/BannerTitleField';
import BannerImageTabs from '../../BannerImageTabs/BannerImageTabs';
import LocaleTabs from '../../LocaleTabs/LocaleTabs';
import FormGroupLabel from '../../../../../components/Form/FormGroupLabel';
import FormField from '../../../../../components/Form/FormField';
import Stack from '@mui/material/Stack/Stack';
import HeroBannerEventPreview from '../../BannerImagePreview/HeroBannerEventPreview';
import FancyEventAutocomplete from '../../../../../components/Inputs/EventAutocomplete/FancyEventAutocomplete';
import ImagePreviewProvider from '../../../providers/ImagePreviewProvider';

const EventView = ({ prefix, extraParams }) => {
  const { watch } = useFormContext();
  const selectedBrand = watch('brand');

  return (
    <Stack direction={'column'} spacing={1}>
      <ImagePreviewProvider>
        <LocaleTabs
          name={`${prefix}.locales`}
          content={(localeField, localeIndex) => (
            <Fragment
              key={localeField.locale.country + localeField.locale.lang}
            >
              <Grid item xs={12}>
                <FormGroup large>
                  <Grid container spacing={2} direction={'column'}>
                    <Grid item xs={12}>
                      <FormGroupLabel>Add event</FormGroupLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          <FormField
                            name={`${prefix}.locales.${localeIndex}.event`}
                          >
                            <FancyEventAutocomplete
                              required
                              label={'Event'}
                              params={extraParams}
                            />
                          </FormField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup large>
                  <Grid container>
                    <BannerTitleField
                      maxLength={40}
                      name={`${prefix}.locales.${localeIndex}.title`}
                    />
                  </Grid>
                </FormGroup>
              </Grid>

              {selectedBrand && (
                <Grid item xs={12}>
                  <BannerImageTabs
                    prefix={`${prefix}.locales.${localeIndex}.images`}
                    preview={HeroBannerEventPreview}
                    previewFields={[`${prefix}.locales.${localeIndex}.title`]}
                  />
                </Grid>
              )}
            </Fragment>
          )}
        />
      </ImagePreviewProvider>
    </Stack>
  );
};

export default EventView;
