import { Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SportIcon from '../../../icons/SportIcon/SportIcon';
import Tooltip from '../../Tooltip/Tooltip';
import StringField from '../StringField/StringField';

const useStyles = makeStyles({
  categoryField: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
});

const TemplateCategoryField = ({ name, id, total, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.categoryField} onClick={onClick}>
      {/* <Tooltip title={name}>
        <SportIcon name={id} />
      </Tooltip> */}
      <StringField>{name}</StringField>
      <Badge color="default" badgeContent={total} />
    </div>
  );
};

export default TemplateCategoryField;
