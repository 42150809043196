import { gql } from '@apollo/client';
import marginLadderFragment from '../fragments/marginLadderFragment';

const getMarginLadderDetailsQuery = gql`
  ${marginLadderFragment}
  query getMarginLadder($ladderId: LadderId!) {
    data: getMarginLadder(ladder_id: $ladderId) {
      ...MarginLadderFragment
    }
  }
`;

export default getMarginLadderDetailsQuery;
