import React, { useContext, useMemo } from 'react';
import ExtendableField from './ExtendableField';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import FormattedValueText from './FormattedValueText';
import { UiOptionsContext } from './UiOptionsProvider';
import { usePageProvider } from '../../v2/components/Page/Page';

const useStyles = makeStyles({
  linkField: {
    color: '#1581FF',
    letterSpacing: '0.2px',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '11px',
  },
});

const LinkField = ({ children }) => {
  const { value } = useContext(UiOptionsContext);
  const { link, externalLink, openNewTab } = value;
  const classes = useStyles();

  const linkTarget = useMemo(() => {
    if (externalLink && openNewTab) {
      return '_blank';
    }

    return '_self';
  }, [openNewTab, externalLink]);
  const { currentPage } = usePageProvider();

  return (
    <ExtendableField>
      {externalLink && openNewTab ? (
        <Link
          component={'a'}
          className={classes.linkField}
          href={externalLink && externalLink.text}
          target={linkTarget}
        >
          {children || <FormattedValueText />}
        </Link>
      ) : link || externalLink ? (
        <Link
          target={linkTarget}
          component={RouterLink}
          className={classes.linkField}
          to={link || externalLink.text}
          state={{
            from: currentPage,
          }}
        >
          {children || <FormattedValueText />}
        </Link>
      ) : (
        <FormattedValueText />
      )}
    </ExtendableField>
  );
};

export default LinkField;
