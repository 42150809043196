import React from 'react';
import DateInput from '../../../components/inputs/DateInput';
import { useFormContext } from 'react-hook-form';

const DateFilter = ({ form, uiSchema: { format_date }, ...props }) => {
  const { setValue } = useFormContext();

  return (
    <DateInput
      {...props}
      margin="dense"
      formatDate={format_date}
      onChange={(v = '') => setValue(props.name, v)}
    />
  );
};

export default DateFilter;
