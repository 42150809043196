import DataProvider from '../../providers/DataProvider';
import FieldList from './FieldList';
import React, { useContext } from 'react';
import { LinksContext } from '../../providers/LinksProvider';

const DataFieldList = ({ path, params, routes, ...props }) => {
  const links = useContext(LinksContext);

  return (
    <DataProvider path={path} params={params} routes={routes} {...props}>
      <FieldList links={links} />
    </DataProvider>
  );
};

export default DataFieldList;
