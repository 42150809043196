import React from 'react';
import { TableCell, TableRow as MuiTableRow } from '@mui/material';
import EmptyStateImage from '../../img/EmptyStateImage';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  emptyStateCellRoot: {
    display: 'block',
    width: '100%',
  },
  emptyStateRowRoot: {
    display: 'block',
    position: 'sticky',
    left: 0,
    width: '100%',
  },
  emptyState: {
    height: 206,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '&>span': {
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '13px',
      letterSpacing: '0.2px',
      marginTop: 16,
    },
  },
});

const TableEmptyState = ({ firstSkipped }) => {
  const classes = useStyles();

  return (
    <MuiTableRow
      classes={{
        root: classes.emptyStateRowRoot,
      }}
    >
      <TableCell
        colSpan={'100%'}
        align={'center'}
        classes={{
          root: classes.emptyStateCellRoot,
        }}
      >
        <div className={classes.emptyState}>
          <EmptyStateImage />

          {firstSkipped ? (
            <span>Apply filters to show results</span>
          ) : (
            <span>Nothing to show in the table</span>
          )}
        </div>
      </TableCell>
    </MuiTableRow>
  );
};

export default TableEmptyState;
