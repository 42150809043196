import { Box } from '@mui/material';
import { useTreeSelectStyles } from '../styles/TreeSelect.styles';

const SelectMenu = ({ autoSize = false, style, children }) => {
  const classes = useTreeSelectStyles();

  return (
    <Box className={classes.treeSelectMenu} style={style}>
      {autoSize ? <>{children}</> : <Box>{children}</Box>}
    </Box>
  );
};
export default SelectMenu;
