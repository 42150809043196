import { createAction } from 'redux-actions';
import { getActiveSchemaUrl } from '../reducers/schemaUrls';

export const refreshToken = createAction('REFRESH_TOKEN');

export const refreshTokenCmd = token => (dispatch, getState) => {
  const activeSchemaUrl = getActiveSchemaUrl(getState());
  dispatch(
    refreshToken({
      activeSchemaUrl,
      token,
    })
  );
};
