import { gql } from '@apollo/client';

const OUTCOMES_QUERY = gql`
  query getOutcomes($eventId: ID!, $filters: FilterEventMarketsOutcomes!) {
    data: getEventOutcomes(event_id: $eventId, filters: $filters) {
      items {
        event_id
        market_id
        sport_id
        outcome_id
        specifiers
        market_name
        outcome_name
        open_bets
        open_single_bets
        result
        dead_heat_factor
      }
      total
    }
  }
`;

export default OUTCOMES_QUERY;
