import { gql } from '@apollo/client';

const getSourcesPreset = gql`
  query getSourcesPreset($presetId: PresetId!) {
    data: getSourcesPreset(presetId: $presetId) {
      nodeSelector {
        sportId
        categoryId
        tournamentId
        eventId
        market {
          marketId
          specifier
        }
      }
      provider
      priority
      enabled
    }
  }
`;

export default getSourcesPreset;
