import Select from '../Select/Select';
import { forwardRef } from 'react';

const rejectReasons = [
  {
    label: 'Operator reject',
    value: 'operator_reject',
  },
  {
    label: 'Bonus reject',
    value: 'bonus_reject',
  },
  {
    label: 'Betby RM reject',
    value: 'betby_rm_reject',
  },
  {
    label: 'Liability reject',
    value: 'liability_reject',
  },
  {
    label: 'MTS reject',
    value: 'mts_reject',
  },
];

const RejectReasonSelect = forwardRef(({ multiple, ...props }, ref) => {
  return (
    <Select
      ref={ref}
      label={'Reject reason'}
      multiple={multiple}
      options={rejectReasons}
      {...props}
    />
  );
});

export default RejectReasonSelect;
