import React, { forwardRef } from 'react';
import Select from '../Select/Select';

const rejectCodes = [
  // {
  //   label: '101: User is not authorized',
  //   value: 101,
  // },
  // {
  //   label: '116: Total odds exceed 10000',
  //   value: 116,
  // },
  // {
  //   label: '117: Invalid odds',
  //   value: 117,
  // },
  {
    label: '132: Bonus has expired or doesnt exist',
    value: 132,
  },
  {
    label: '201: The event or market is not available',
    value: 201,
  },
  {
    label: '202: User has been blocked by Betby or the Operator',
    value: 202,
  },
  {
    label: '211: Odds changed',
    value: 211,
  },
  {
    label: '221: Min stake is lower than limit',
    value: 221,
  },
  {
    label: '231: Max stake exceeded',
    value: 231,
  },
  {
    label: '241: Related contingency',
    value: 241,
  },
  {
    label: '280: Declined by the operator',
    value: 280,
  },
  {
    label: '282: Not enough money',
    value: 282,
  },
  {
    label: '291: Limited by risk management',
    value: 291,
  },
  {
    label: '292: Risk Management is unavailable',
    value: 292,
  },
];

const RejectCodeSelect = forwardRef(({ multiple, ...props }, ref) => {
  return (
    <Select
      ref={ref}
      label={'Rejection Code'}
      multiple={multiple}
      options={rejectCodes}
      valueToString={false}
      withTooltip
      {...props}
    />
  );
});

export default RejectCodeSelect;
