import { useDispatch, useSelector } from 'react-redux';
import { getActiveSchemaUrl, getSchemaUrls } from '../reducers/schemaUrls';
import React, { useCallback, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import {
  IS_SHOW_FEATURE_BRANCHES,
  SCHEMA_LABELS_MAP,
} from '../constants/schemaUrls';
import MenuItem from '@mui/material/MenuItem';
import { ADD_SCHEMA_URL } from '../constants/appConstants';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '../v2/components/Button/Button';
import Dialog from '@mui/material/Dialog';
import useIsOpen from '../hooks/useIsOpen';
import { TextField } from '@mui/material';
import { isUrlValid } from '../utils/schemaHelper';
import ZoomTransition from '../utils/transitions/ZoomTransition';
import CloseIcon from '../icons/controls/CloseIcon';
import ExpandIcon from '../icons/controls/ExpandIcon';
import Tooltip from '@mui/material/Tooltip';
import LoadingIndicator from './Autocomplete/LoadingIndicator';
import { useSessionStorage } from '../hooks/useStorage';
import useFeatureBranches from '../v2/hooks/useFeatureBranches';

const useStyles = makeStyles(theme => ({
  menuButtons: {
    pointerEvents: 'none',
    justifyContent: 'flex-end',
    marginBottom: -theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  applyButton: {
    pointerEvents: 'initial',
  },

  envInputGroup: {
    marginBottom: 8,
  },
  textFieldRoot: {
    textTransform: 'capitalize',
  },
  customMenuItemRoot: {
    color: '#8496AB',
    '&$customMenuItemSelected': {
      color: theme.palette.text.primary,
    },
  },
  customMenuItemSelected: {},
}));

const EnvInput = ({ loading }) => {
  const dispatch = useDispatch();
  const schemaUrls = useSelector(getSchemaUrls);
  const activeSchemaUrl = useSelector(getActiveSchemaUrl);
  const { open, close, isOpen } = useIsOpen();
  const {
    open: openCustomSchema,
    close: closeCustomSchema,
    isOpen: isOpenCustomSchema,
  } = useIsOpen();
  const [customSchemaUrl, setCustomSchemaUrl] = useState('');

  const [sessionSchemaUrl, setSessionSchemaUrl] = useSessionStorage(
    'sessionSchemaUrl',
    null
  );

  const { beEnvs } = useFeatureBranches();

  const classes = useStyles();

  const labelKey = 'label';
  const valueKey = 'value';
  const descriptionKey = 'value';
  const label = 'Environment';
  const placeholder = 'Choose environment';
  const margin = 'dense';

  const items = IS_SHOW_FEATURE_BRANCHES
    ? beEnvs.map(({ name, url }) => {
        const schemaUrl = `${url}/api/v1/schema.json`;

        return {
          [labelKey]: name,
          [valueKey]: schemaUrl,
        };
      })
    : schemaUrls.map(url => ({
        [labelKey]:
          SCHEMA_LABELS_MAP[url] ||
          (isUrlValid(url) ? new URL(url).hostname : url),
        [valueKey]: url,
      }));

  const addSchemaUrlCb = useCallback(
    schemaUrl => {
      setSessionSchemaUrl(schemaUrl);
      dispatch({
        type: ADD_SCHEMA_URL,
        meta: {
          schemaUrl,
        },
      });
    },
    [setSessionSchemaUrl, dispatch]
  );

  return (
    <Grid container spacing={2} className={classes.envInputGroup}>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth={true}>
          <Dialog
            TransitionComponent={ZoomTransition}
            onClose={closeCustomSchema}
            maxWidth="md"
            aria-labelledby="add-env-dialog-title"
            open={isOpenCustomSchema}
          >
            <DialogTitle id="add-env-dialog-title">
              Add custom schema
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true}>
                <TextField
                  value={customSchemaUrl}
                  onChange={e => {
                    setCustomSchemaUrl(e.target.value);
                  }}
                  placeholder={'http://'}
                  margin={'dense'}
                  label="Schema url"
                  variant="outlined"
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                disableRipple
                onClick={closeCustomSchema}
                variant={'text'}
              >
                Cancel
              </Button>
              <Button
                disableRipple
                onClick={() => {
                  addSchemaUrlCb(customSchemaUrl);
                  closeCustomSchema();
                }}
                variant={'contained'}
                color={'primary'}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <TextField
            classes={{
              root: classes.textFieldRoot,
            }}
            disabled={loading}
            variant={'outlined'}
            placeholder={placeholder}
            value={activeSchemaUrl}
            onChange={e => {
              if (!e.target.value) {
                openCustomSchema();
              } else {
                addSchemaUrlCb(e.target.value);
              }
            }}
            select
            InputProps={{
              endAdornment: loading && <LoadingIndicator />,
            }}
            margin={margin}
            label={label}
            SelectProps={{
              IconComponent: isOpen ? CloseIcon : ExpandIcon,
              open: isOpen,
              onOpen: open,
              onClose: close,
              MenuProps: {
                style: {
                  marginTop: 4,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
          >
            <MenuItem
              classes={{
                root: classes.customMenuItemRoot,
                selected: classes.customMenuItemSelected,
              }}
            >
              Add custom url...
            </MenuItem>
            {items &&
              items.map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option[valueKey]}
                  value={option[valueKey]}
                >
                  {descriptionKey ? (
                    <Tooltip title={option[descriptionKey]} enterDelay={300}>
                      <span>{option[labelKey]}</span>
                    </Tooltip>
                  ) : (
                    option[labelKey]
                  )}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EnvInput;
