import React from 'react';
import usePaletteColor from '../../hooks/usePaletteColor';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  verticalStripDecorator: {
    width: 4,
    height: 34,
    marginRight: 8,
    paddingLeft: 4,
    borderRadius: 2,
  },
});

const VerticalStripDecorator = ({ color }) => {
  const backgroundColor = usePaletteColor(color);
  const classes = useStyles();

  return (
    <div
      className={classes.verticalStripDecorator}
      style={{
        backgroundColor,
      }}
    />
  );
};

export default VerticalStripDecorator;
