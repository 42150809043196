import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import RemoveIcon from '../../../icons/RemoveIcon';

const useStyles = makeStyles({
  deletePairButton: {
    backgroundColor: '#f3f4f7',
    borderRadius: '4px',
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  removeIcon: {
    height: 16,
    width: 16,
    color: 'transparent',
  },
});

const DeleteLadderButton = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      disableRipple
      variant={'contained'}
      color="secondary"
      className={clsx(classes.deletePairButton, className)}
      {...props}
    >
      <RemoveIcon color={'#ff000f'} className={classes.removeIcon} />
    </Button>
  );
};

export default DeleteLadderButton;
