import { gql } from '@apollo/client';

const marginLaddersAutocompleteQuery = gql`
  query getMarginLadderList($sportId: SportId, $query: String) {
    data: getMarginLadderList(sport_id: $sportId, name: $query) {
      id
      name
    }
  }
`;

export default marginLaddersAutocompleteQuery;
