import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import AlertSuccessIcon from '../../icons/AlertSuccessIcon.svg';
import AlertWarningIcon from '../../icons/AlertWarningIcon.svg';
import AlertErrorIcon from '../../icons/AlertErrorIcon.svg';
import { AlertVariant } from './NotificationAlert';

const useStyles = makeStyles({
  alertIcon: {
    height: 24,
    width: 24,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  alertSuccess: {
    backgroundImage: `url(${AlertSuccessIcon})`,
  },
  alertWarning: {
    backgroundImage: `url(${AlertWarningIcon})`,
  },

  alertError: {
    backgroundImage: `url(${AlertErrorIcon})`,
  },
});

const AlertIcon = ({ className, variant }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(className, classes.alertIcon, {
        [classes.alertSuccess]: variant === AlertVariant.SUCCESS,
        [classes.alertWarning]: variant === AlertVariant.WARNING,
        [classes.alertError]: variant === AlertVariant.ERROR,
      })}
    />
  );
};

export default AlertIcon;
