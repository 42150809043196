import React, { forwardRef, useContext } from 'react';
import { UiOptionsContext } from './UiOptionsProvider';
import BadgeField from './BadgeField';
import StringField from './StringField/StringField';
import { ComponentEnum } from './FieldUtils';
import LinkField from './LinkField';
import IconField from './IconField/IconField';
import BadgeLinkField from './BadgeLinkField';
import { Tooltip } from '@mui/material';
import BooleanField from './BooleanField';
import CodeField from './CodeField';
import ImageField from './ImageField';
import CountdownField from './CountdownField';
import BooleanGroupField from './BooleanGroupField/BooleanGroupField';
import BooleanInputField from './BooleanInputField';
import WatchField from './WatchField/WatchField';
import TestField from './TestField/TestField';
import makeStyles from '@mui/styles/makeStyles';
import { Markup } from 'react-render-markup';
import MarginInputField from './MarginField/MarginInputField';

export const ComponentMap = {
  [ComponentEnum.BADGE]: <BadgeField />,
  [ComponentEnum.BADGE_LINK]: <BadgeLinkField />,
  [ComponentEnum.LINK]: <LinkField />,
  [ComponentEnum.STRING]: <StringField />,
  [ComponentEnum.COUNTDOWN]: <CountdownField />,
  [ComponentEnum.ICON]: <IconField />,
  [ComponentEnum.BOOLEAN]: <BooleanField />,
  [ComponentEnum.BOOLEAN_INPUT]: <BooleanInputField />,
  [ComponentEnum.BOOLEAN_GROUP_INPUT]: <BooleanGroupField />,
  [ComponentEnum.CODE]: <CodeField />,
  [ComponentEnum.IMAGE]: <ImageField />,
  [ComponentEnum.WATCH]: <WatchField />,
  [ComponentEnum.TEST_FIELD]: <TestField />,
  [ComponentEnum.MARGIN_INPUT]: <MarginInputField />,
};

const useStyles = makeStyles({
  tooltipContent: {
    overflowWrap: 'anywhere',
    maxWidth: 600,
  },
});

export const WithTooltip = forwardRef(
  ({ children, tooltipText, className, ...props }, ref) => {
    return (
      <div {...props} ref={ref} className={className}>
        {children}
      </div>
    );
  }
);

const FieldValueContainer = () => {
  const classes = useStyles();
  const { value } = useContext(UiOptionsContext);
  const { tooltip } = value;

  const FieldComponent = ComponentMap[value.component] || null;

  if (tooltip) {
    const tooltipText = typeof tooltip === 'string' ? tooltip : tooltip.text;

    return (
      <Tooltip
        title={<Markup markup={tooltipText} />}
        classes={{
          tooltip: classes.tooltipContent,
        }}
      >
        <WithTooltip>{FieldComponent}</WithTooltip>
      </Tooltip>
    );
  }

  return FieldComponent;
};

export default FieldValueContainer;
