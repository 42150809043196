import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { capitalize } from 'lodash';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import saveMarginPreset from '../../../../../../gql/sportbook-config/templates/mutations/preset/saveMarginPreset';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import TextField from '../../../../TextField';
import MenuTitle from '../../../components/MenuTitle';
import SavePresetButton from '../../../components/SavePresetButton';
import SelectContainer from '../../../components/SelectContainer';
import VirtualizedMenu from '../../../components/VirtualizedMenu';
import MarginMarketOption from '../Options/MarginMarketOption';

const MarginMarketSelect = ({
  templateId,
  nodePath = [],
  setPath = () => {},
  selectKey,
  nodeIndex,
  options,
  marketType,
}) => {
  const sportId = nodePath.sportId;
  const selectedId = nodePath.marketId;

  const { getValues } = useFormContext();

  const lineType = 'prematch';

  const template = getValues('template');

  const [displayOptions, setDisplayOptions] = useState(options);
  const [saveMarginPresetCallback] = useMutation(saveMarginPreset);
  const { showToast } = useToasts();

  const onMarginPresetSave = useCallback(
    name => {
      const commands = Object.values(template);

      const presetCommands = commands.map(value => ({
        nodeSelector: value.nodeSelector,
        live: value.liveMargin && value.liveMargin.value,
        prematch: value.prematchMargin && value.prematchMargin.value,
      }));

      saveMarginPresetCallback({
        variables: {
          input: {
            name,
            sportId,
            presets: presetCommands,
          },
        },
      })
        .then(data => {
          data &&
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={2000}>
                Preset saved successfully
              </NotificationAlert>
            );
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something want wrong
            </NotificationAlert>
          );
        });
    },
    [template, saveMarginPresetCallback, showToast, sportId]
  );

  const onChange = useCallback(
    e => {
      const inputValue = e.target.value.toLowerCase();

      setDisplayOptions(
        options.filter(
          option => option && option.name.toLowerCase().includes(inputValue)
        )
      );
    },
    [setDisplayOptions, options]
  );

  // useEffect(() => {
  //   if (!isEqual(preset, initialPreset)) {
  //     const commands = preset.commands;
  //     const config = commands.reduce((acc, value) => {
  //       preset.itemIds.forEach(id => {});
  //     });
  //   }
  // }, [preset]);

  return (
    <SelectContainer style={{ width: '350px' }}>
      <TextField
        style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
        label={`Search ${capitalize(marketType)} Market`}
        onChange={onChange}
        clearButton={true}
      />
      <Box display={'flex'}>
        <MenuTitle name={'Market'} style={{ flex: 11 }} />
        <MenuTitle name={'PM'} style={{ flex: 2 }} />
        <MenuTitle name={'Live'} style={{ flex: 2, color: '#FF000F' }} />
      </Box>
      <VirtualizedMenu
        containerStyle={{
          height: 'calc(100% - 175px)',
        }}
        rowCount={displayOptions.length}
        itemRender={({ measure, index }) => {
          const option = displayOptions[index];

          return (
            <MarginMarketOption
              key={selectKey + '-' + option.marketId}
              nodePath={nodePath}
              selectKey={selectKey}
              setPath={setPath}
              nodeIndex={nodeIndex}
              selected={option.marketId === selectedId}
              itemValue={option}
              lineType={lineType}
            />
          );
        }}
      />
      <SavePresetButton templateType={'margin'} onSave={onMarginPresetSave} />
    </SelectContainer>
  );
};

export default MarginMarketSelect;
