import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import UserMenuContainer from '../containers/UserMenuContainer';
import { getUser } from '../reducers/users';
import { useSelector } from 'react-redux';

const styles = {
  right: {
    flex: 'auto',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  progress: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    height: 2,
  },
};

const TopMenu = ({ loading, classes }) => {
  const user = useSelector(getUser);
  return (
    <>
      <div className={classes.right}>{user && <UserMenuContainer />}</div>
      {loading && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
    </>
  );
};

TopMenu.propTypes = {
  loading: PropTypes.bool,
  showThemeModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(TopMenu);
