import FancyAutocomplete from '../FancyAutocomplete/FancyAutocomplete';
import getBoostedOddsOutcomes from '../../../gql/Promo/queries/getBoostedOddsOutcomes';
import { forwardRef, useMemo } from 'react';

const FancyOutcomeSelect = forwardRef(
  ({ eventId, brandId, marketId, specifiers, ...props }, ref) => {
    // "query: undefined" is used here to overwrite default { query: "" } param added by useAutocompleteQuery hook in FancyAutocomplete component
    const params = useMemo(
      () => ({ query: undefined, eventId, brandId, marketId, specifiers }),
      [eventId, brandId, marketId, specifiers]
    );

    const disabled = !eventId || !brandId || !marketId;

    return (
      <FancyAutocomplete
        ref={ref}
        disabled={disabled}
        gqlQuery={getBoostedOddsOutcomes}
        label={'Select Outcome'}
        valueKey={'outcome_id'}
        labelKey={'outcome_name'}
        multiple={false}
        params={params}
        skipQuery={disabled}
        {...props}
      />
    );
  }
);

export default FancyOutcomeSelect;
