import { Autocomplete, Stack } from '@mui/material';
import TextField from '../../../../components/Inputs/TextField';
import React, { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import TranslationsList from '../TranslationsList';
import useDebounced from '../../../../hooks/useDebounced';
import {
  useLangsAPI,
  useTournaments,
} from '../../../../hooks/event-management';
import {
  mapDataToAutocompleteValue,
  mapLangsToTranslations,
  sortLanguages,
  isCustomOption,
} from '../../utils';
import ErrorWidget from '../../../../components/ErrorWidget';
import getLanguageInfo from '../../../../gql/EventsGroups/queries/getLanguageInfo';
import EventFormAutocompleteOption from '../EventFormAutocompleteOption';

const TournamentAutocomplete = ({ sportId, languages, categoryId }) => {
  const { setValue, watch } = useFormContext();

  const tournament = watch('tournament');

  const { getTournamentLangs, error } = useLangsAPI();

  const {
    tournaments,
    refetch: refetchTournaments,
    error: tournamentsError,
  } = useTournaments({
    skip: !categoryId,
    variables: {
      query: '',
      sportIds: [sportId],
      ...(categoryId && { categoriesIds: [categoryId] }),
      limit: 15,
    },
  });

  const { data: { getLanguageInfo: languagesData } = {} } =
    useQuery(getLanguageInfo);

  const [inputValue, setInputValue] = useState('');
  const [isExpandedLanguages, setExpandedLanguages] = useState(false);
  const debouncedTournamentSearch = useDebounced(setInputValue, 500);

  useEffect(() => {
    if (inputValue && categoryId) {
      refetchTournaments({
        query: inputValue,
        sportIds: [sportId],
        ...(categoryId && {
          categoriesIds: [categoryId],
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const value = useMemo(
    () => mapDataToAutocompleteValue(tournament),
    [tournament]
  );

  const handleApplyCustomValue = async event => {
    const value = event.target.value;
    const primaryTranslation = tournament?.langs?.find(
      l => l.language === 'English'
    ).translation;
    const existingOption = categoryId
      ? tournaments.find(({ name }) => {
          const segments = name.split('/');
          const label = segments[segments.length - 1].trim();
          return label === value;
        })
      : undefined;

    if (value) {
      if (value !== primaryTranslation) {
        if (existingOption) {
          const { data } = await getTournamentLangs({
            sportId,
            tournamentId: existingOption.value,
            ...(categoryId && {
              categoryId: categoryId,
            }),
          });
          setValue('tournament', {
            ...data.getTournamentLangs,
            langs: mapLangsToTranslations(
              sortLanguages(data.getTournamentLangs.langs),
              languagesData
            ),
          });
        } else {
          setValue('tournament', {
            id: null,
            langs: [
              {
                language: 'English',
                translation: value,
              },
            ],
          });
        }
      }
    } else {
      setValue('tournament', null);
    }
  };

  if (error || tournamentsError) {
    return <ErrorWidget />;
  }

  return (
    <Stack gap={'15px'}>
      <Stack gap={'4px'} direction={'row'}>
        <Stack gap={'2px'} sx={{ width: '100%' }}>
          <Autocomplete
            value={value}
            freeSolo
            filterOptions={options => options}
            getOptionLabel={option => {
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            onChange={async (event, newValue, reason) => {
              if (reason === 'selectOption') {
                const { data } = await getTournamentLangs({
                  sportId,
                  tournamentId: newValue?.value,
                  ...(categoryId && {
                    categoryId: categoryId,
                  }),
                });
                setValue('tournament', {
                  ...data.getTournamentLangs,
                  langs: mapLangsToTranslations(
                    sortLanguages(data.getTournamentLangs.langs),
                    languagesData
                  ),
                });
              }
            }}
            onBlur={async event => {
              handleApplyCustomValue(event);
            }}
            options={tournaments.map(c => ({ label: c.name, value: c.id }))}
            renderOption={(props, option) => (
              <EventFormAutocompleteOption option={option} {...props} />
            )}
            sx={{ width: '100%' }}
            renderInput={params => (
              <TextField
                label={'Tournament'}
                shrinkable
                onKeyDown={async event => {
                  if (event.key === 'Enter' && event.target.value) {
                    handleApplyCustomValue(event);
                  }
                }}
                helperText={
                  isCustomOption(tournament)
                    ? 'New tournament will be added'
                    : value?.value
                }
                FormHelperTextProps={{
                  onClick: e => {
                    e.stopPropagation();
                  },
                  onMouseDown: e => {
                    e.stopPropagation();
                  },
                }}
                {...params}
              />
            )}
            onInputChange={(_, newInputValue) => {
              debouncedTournamentSearch(newInputValue);
            }}
          />
        </Stack>
      </Stack>
      {tournament?.langs && (
        <TranslationsList
          name={'tournament'}
          langs={tournament.langs}
          languages={languages}
          isExpanded={isExpandedLanguages}
          setExpanded={setExpandedLanguages}
          onChange={langs => {
            setValue('tournament', {
              id: tournament?.id,
              langs: langs,
            });
          }}
        />
      )}
    </Stack>
  );
};

export default TournamentAutocomplete;
