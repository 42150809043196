import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useRawSchema } from './RawSchemaProvider';
import { getActiveSchemaUrl } from '../reducers/schemaUrls';
import { useSelector } from 'react-redux';
import { ApiHostContext } from './ApiHostContext';

const ApiHostProvider = ({ children }) => {
  const activeSchemaUrl = useSelector(getActiveSchemaUrl);
  const fallbackUrl = new URL(activeSchemaUrl).hostname;
  const { apiHost = `https://${fallbackUrl}`, apiBaseUrl = fallbackUrl } =
    useRawSchema();

  const value = useMemo(() => {
    return {
      apiBaseUrl,
      apiHost,
    };
  }, [apiBaseUrl, apiHost]);

  return (
    <ApiHostContext.Provider value={value}>{children}</ApiHostContext.Provider>
  );
};

export const useApiHost = () => {
  return useContext(ApiHostContext);
};

export default ApiHostProvider;
