import { gql } from '@apollo/client';

const GetCategoriesNames = gql`
  query getCategoriesNames(
    $query: String!
    $sport_ids: [ID!]
    $exclude_sport_ids: Boolean
    $categories_ids: [ID!]
    $limit: Int
    $offset: Int
  ) @api(name: rm) {
    data: getCategoriesNames(
      query: $query
      sport_ids: $sport_ids
      exclude_sport_ids: $exclude_sport_ids
      categories_ids: $categories_ids
      limit: $limit
      offset: $offset
    ) {
      label
      value
    }
  }
`;

export default GetCategoriesNames;
