import BannerPreview from './BannerPreview';
import BannerPreviewPlaceholder from './BannerPreviewPlaceholder';
import { forwardRef } from 'react';

const TextBannerPreview = forwardRef(({ active, onClick, ...props }, ref) => {
  return (
    <BannerPreview
      title={'Text'}
      active={active}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      <BannerPreviewPlaceholder
        style={{
          height: 8,
          width: 136,
          marginTop: 11,
          borderRadius: 1,
        }}
      />
      <BannerPreviewPlaceholder
        style={{
          height: 8,
          width: 136,
          marginTop: 8,
          borderRadius: 1,
        }}
      />
    </BannerPreview>
  );
});

export default TextBannerPreview;
