import React, { useState } from 'react';

import {
  Stack,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import check from '../../../assets/check.svg';
import cross from '../../../assets/cross.svg';
import passwordIcon from '../../../assets/password.svg';
import eye from '../../../assets/eye.svg';
import eyeOff from '../../../assets/eye-off.svg';
import { LoadingButton } from '@mui/lab';
import { ERROR_CODES } from '../../../utils';
import errorIcon from '../../../assets/error.svg';
import { passRequirements } from '../../../constants';

const ChangePasswordForm = ({ onSubmit, onCancel }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      passwordRepeat: '',
    },
    validationSchema: object({
      oldPassword: string().required('Enter your old password'),
      password: string()
        .min(8, passRequirements.charLength)
        .matches(/[A-Z]/, passRequirements.uppercase)
        .matches(/[a-z]/, passRequirements.lowercase)
        .matches(/[0-9]/, passRequirements.number)
        .matches(/[!@#$%^&*(),.?":{}|<>]/, passRequirements.specialChar)
        .required(passRequirements.required),
      passwordRepeat: string()
        .test('password_same', passRequirements.bothMatch, function (value) {
          const { password } = this.parent;

          return password === value;
        })
        .required(passRequirements.repeat),
    }),
    onSubmit: values => {
      setError(null);

      onSubmit(values, errors => {
        const codes = errors ? errors.map(e => e.code) : [];
        if (codes.includes(ERROR_CODES.THE_SAME_PASSWORD)) {
          setError('You can not use the same password');
        }

        if (codes.includes(ERROR_CODES.INVALID_CREDENTIALS)) {
          setError('Old password is incorrect.');
        }
      });
    },
  });

  const validatePassword = name => {
    const errors = [];

    if (name === 'passwordRepeat') {
      if (
        formik.values.password !== formik.values.passwordRepeat ||
        formik.values.passwordRepeat === ''
      ) {
        errors.push(passRequirements.bothMatch);
      }
    }

    if (formik.values[name].length < 8) {
      errors.push(passRequirements.charLength);
    }

    if (!/[A-Z]/.test(formik.values[name])) {
      errors.push(passRequirements.uppercase);
    }

    if (!/[a-z]/.test(formik.values[name])) {
      errors.push(passRequirements.lowercase);
    }

    if (!/[0-9]/.test(formik.values[name])) {
      errors.push(passRequirements.number);
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(formik.values[name])) {
      errors.push(passRequirements.specialChar);
    }

    return errors;
  };

  const handleToggleVisibility = type => {
    if (type === 'repeat') {
      setShowPasswordRepeat(prevState => !prevState);
    } else if (type === 'old') {
      setShowPasswordOld(prevState => !prevState);
    } else {
      setShowPassword(prevState => !prevState);
    }
  };

  const renderConstraint = (name, content) => {
    const errors = validatePassword(name);
    const isValid = !errors.includes(content);

    return (
      <Stack direction={'row'} alignItems={'center'}>
        {isValid ? (
          <img src={check} style={{ padding: '9px 8px ' }} />
        ) : (
          <img src={cross} style={{ padding: '9px' }} />
        )}
        <Typography
          color={isValid ? '#008545' : '#8496AB'}
          fontSize={'12px'}
          letterSpacing={'-.2px'}
          lineHeight={'16px'}
        >
          {content}
        </Typography>
      </Stack>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Stack gap={'16px'}>
        <TextField
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          name={'oldPassword'}
          error={
            formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
          }
          type={showPasswordOld ? 'text' : 'password'}
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: '14px',
            },
            '& .MuiInputAdornment-root': {
              width: '30px',
              '&.MuiInputAdornment-positionStart': {
                color: '#1481ff',
              },
              '&.MuiInputAdornment-positionEnd': {
                '& .MuiSvgIcon-root': {
                  fontSize: '18px',
                },
              },
            },
          }}
          label={'Old password'}
          InputProps={{
            type: showPasswordOld ? 'text' : 'password',
            startAdornment: (
              <InputAdornment position="start">
                <img src={passwordIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleToggleVisibility('old')}
                  onMouseDown={e => e.preventDefault()}
                >
                  <img src={showPasswordOld ? eye : eyeOff} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!formik.values.oldPassword,
            style: {
              overflow: 'hidden',
              marginLeft: formik.values.oldPassword ? 0 : 34,
            },
          }}
        />
        <Stack gap={'4px'}>
          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            name={'password'}
            error={formik.touched.password && Boolean(formik.errors.password)}
            label={'New password'}
            type={showPassword ? 'text' : 'password'}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '14px',
              },
              '& .MuiInputAdornment-root': {
                width: '30px',
                '&.MuiInputAdornment-positionStart': {
                  color: '#1481ff',
                },
                '&.MuiInputAdornment-positionEnd': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px',
                  },
                },
              },
            }}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <img src={passwordIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleToggleVisibility('password')}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <img src={showPassword ? eye : eyeOff} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !!formik.values.password,
              style: {
                overflow: 'hidden',
                marginLeft: formik.values.password ? 0 : 34,
              },
            }}
          />
          <Stack gap={'4px'}>
            {renderConstraint('password', passRequirements.charLength)}
            {renderConstraint('password', passRequirements.uppercase)}
            {renderConstraint('password', passRequirements.lowercase)}
            {renderConstraint('password', passRequirements.number)}
            {renderConstraint('password', passRequirements.specialChar)}
          </Stack>
        </Stack>
        <Stack gap={'4px'}>
          <TextField
            value={formik.values.passwordRepeat}
            onChange={formik.handleChange}
            name={'passwordRepeat'}
            error={
              formik.touched.passwordRepeat &&
              Boolean(formik.errors.passwordRepeat)
            }
            type={showPasswordRepeat ? 'text' : 'password'}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '14px',
              },
              '& .MuiInputAdornment-root': {
                width: '30px',
                '&.MuiInputAdornment-positionStart': {
                  color: '#1481ff',
                },
                '&.MuiInputAdornment-positionEnd': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px',
                  },
                },
              },
            }}
            label={'Repeat new password'}
            InputProps={{
              type: showPasswordRepeat ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <img src={passwordIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleToggleVisibility('repeat')}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <img src={showPasswordRepeat ? eye : eyeOff} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !!formik.values.passwordRepeat,
              style: {
                overflow: 'hidden',
                marginLeft: formik.values.passwordRepeat ? 0 : 34,
              },
            }}
          />
          {renderConstraint('passwordRepeat', passRequirements.bothMatch)}
        </Stack>
        {error && (
          <Stack
            direction={'row'}
            gap={'8px'}
            padding={'12px'}
            sx={{
              backgroundColor: '#FFE6E8',
              borderRadius: '8px',
            }}
            alignItems={'flex-start'}
          >
            <img src={errorIcon} />
            <Typography
              fontSize={'14px'}
              color={'#820008'}
              lineHeight={'20px'}
              letterSpacing={'0.2px'}
            >
              {error}
            </Typography>
          </Stack>
        )}
        <Stack gap={'8px'}>
          <LoadingButton
            disabled={Object.keys(formik.errors).length > 0}
            type={'submit'}
            variant={'contained'}
            sx={{
              width: '100%',
            }}
          >
            Confirm
          </LoadingButton>
          <Button onClick={onCancel} variant={'text'}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ChangePasswordForm;
