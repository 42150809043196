import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import React, { useCallback, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {
  getEditPath,
  getMethodAndUrl,
  getParams,
  getTo,
  isDownloadLink,
  isDirectLink,
  isEditLink,
  isModalAction,
  toEndpoint,
  replaceParamsInDirectLink,
} from '../../utils/schemaHelper';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesContext } from '../../providers/RoutesProvider';
import { showLinkModal } from '../../actions/modalActions';
import useConfirmationPrompt from '../../hooks/useConfirmationPrompt';
import { editData, fetchDynamic } from '../../actions/dataActions';
import MoreVertIcon from '../../icons/controls/MoreVertIcon';
import { useRawSchema } from '../../providers/RawSchemaProvider';
import { usePageProvider } from '../../v2/components/Page/Page';

const useStyles = makeStyles(theme => ({
  destructiveMenuItem: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      background: '#FFE5E7',
    },
  },

  externalLink: {
    width: '100%',
    padding: '0 16px 0 16px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'none',
  },

  rowActionsButton: {},

  rowActionsButtonRoot: {
    transition: 'color 0.3s ease-in-out',
    color: '#031224',
    '&:hover': {
      color: '#8496AB',
      backgroundColor: 'initial',
    },
  },
  rowActionsButtonIcon: {
    fontSize: '10px',
  },
}));

const TableRowActions = ({
  responseBody,
  actions,
  path,
  operationId,
  fetchData,
  requestQuery,
}) => {
  const navigate = useNavigate();
  const requestPath = useParams();

  const [anchorEl, setAnchorEl] = React.useState();
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const schemaState = useRawSchema();

  function handleMenuClick(event) {
    if (event.currentTarget) {
      // @ts-ignore
      setAnchorEl(event.currentTarget);
    }
  }

  function handleMenuClose() {
    // @ts-ignore
    setAnchorEl(null);
  }

  const dispatch = useDispatch();

  const processDelete = useCallback(
    url =>
      dispatch(
        fetchDynamic(schemaState, {
          method: 'delete',
          url,
        })
      ),
    [dispatch, schemaState]
  );

  const processDownload = useCallback(
    data => dispatch(fetchDynamic(schemaState, { ...data })),
    [dispatch, schemaState]
  );

  const processEdit = useCallback(
    (editPath, body) => {
      return dispatch(
        editData({
          apiHost: schemaState.apiHost,
          path,
          editPath,
          body,
          operationId,
        })
      );
    },
    [dispatch, schemaState, path, operationId]
  );

  const processModal = useCallback(
    data => dispatch(showLinkModal(data)),
    [dispatch]
  );

  const routes = useContext(RoutesContext);

  const { openConfirmation } = useConfirmationPrompt();

  const { currentPage } = usePageProvider();

  const shouldBeOpenedInDifferentTab = action => {
    return (
      action.link.route.summary === 'Outcomes' ||
      action.link.route.summary === 'Update score'
    );
  };

  const resolveLinkToOpenInDifferentTab = action => {
    if (action.link.route.summary === 'Outcomes') {
      return replaceParamsInDirectLink({
        link: action.link,
        requestQuery,
        requestPath,
        responseBody,
      });
    } else if (action.link.route.summary === 'Update score') {
      return getTo({
        link: action.link,
        routes,
        requestQuery,
        requestPath,
        responseBody,
      });
    }
  };

  return (
    <>
      <div className={classes.rowActionsButton}>
        <IconButton
          classes={{
            root: classes.rowActionsButtonRoot,
          }}
          disableRipple={true}
          size={'small'}
          onClick={handleMenuClick}
        >
          <MoreVertIcon className={classes.rowActionsButtonIcon} />
        </IconButton>
      </div>

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            minWidth: 200,
            borderRadius: 8,
            boxShadow:
              '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
          },
        }}
      >
        {actions.map(action => {
          const cb = () => {
            return new Promise(async resolve => {
              if (isModalAction(action)) {
                processModal({
                  link: action.link,
                  responseBody,
                  requestQuery,
                  requestPath,
                });
              } else if (get(action.link, 'path', '').endsWith('/get')) {
                if (isDownloadLink(action.link, routes)) {
                  processDownload(
                    getMethodAndUrl({
                      path: action.link.path,
                      ...getParams({
                        link: action.link,
                        requestQuery,
                        responseBody,
                        requestPath,
                      }),
                    })
                  );
                } else if (isDirectLink(action.link)) {
                  const linkTo = replaceParamsInDirectLink({
                    link: action.link,
                    requestQuery,
                    requestPath,
                    responseBody,
                  });

                  navigate(linkTo, {
                    state: {
                      from: currentPage,
                    },
                  });
                } else {
                  const linkTo = getTo({
                    link: action.link,
                    routes,
                    requestQuery,
                    requestPath,
                    responseBody,
                  });

                  navigate(linkTo, {
                    state: {
                      from: currentPage,
                    },
                  });
                }
              } else if (isEditLink(action.link)) {
                const editPath = getEditPath({
                  editLink: action.link,
                  entity: responseBody,
                  query: requestQuery,
                  requestPath,
                });

                const { query } = getParams({
                  link: action.link,
                  requestQuery,
                  requestPath,
                  responseBody,
                });

                const res = await processEdit(editPath, query);
                resolve(res);
              } else if (get(action.link, 'path', '').endsWith('/delete')) {
                const { params } = getParams({
                  link: action.link,
                  requestQuery,
                  requestPath,
                  responseBody,
                });

                const url = toEndpoint({
                  path: action.link.path,
                  params,
                });

                const res = await processDelete(url);
                resolve(res);
              }
            });
          };

          return shouldBeOpenedInDifferentTab(action) ? (
            <MenuItem
              key={action.link.operationId}
              style={{
                padding: 0,
              }}
              classes={{
                root: clsx({
                  [classes.destructiveMenuItem]:
                    action.uiSchema && action.uiSchema.variant === 'danger',
                }),
              }}
            >
              <a
                className={classes.externalLink}
                target={'_blank'}
                href={resolveLinkToOpenInDifferentTab(action)}
              >
                {(action.uiSchema && action.uiSchema.label) ||
                  action.link.route.summary}
              </a>
            </MenuItem>
          ) : (
            <MenuItem
              classes={{
                root: clsx({
                  [classes.destructiveMenuItem]:
                    action.uiSchema && action.uiSchema.variant === 'danger',
                }),
              }}
              key={action.link.operationId}
              onClick={async () => {
                if (action.uiSchema && action.uiSchema.confirmation) {
                  openConfirmation({
                    buttonLabel:
                      action.uiSchema && action.uiSchema.confirmation.label,
                    title: action.link.route.summary,
                    message:
                      action.uiSchema &&
                      action.uiSchema.confirmation.confirmText,
                    destructive:
                      action.uiSchema && action.uiSchema.variant === 'danger',
                    callback: async () => {
                      await cb();
                      fetchData();
                    },
                  });
                } else {
                  await cb();
                  fetchData();
                }

                handleMenuClose();
              }}
            >
              {(action.uiSchema && action.uiSchema.label) ||
                action.link.route.summary}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default TableRowActions;
