import isObject from 'lodash/isObject';

const removeEmpty = obj =>
  Array.isArray(obj)
    ? obj.map(item =>
        Array.isArray(item) || typeof item === 'object'
          ? removeEmpty(item)
          : item
      )
    : isObject(obj)
    ? Object.keys(obj)
        .filter(k => obj[k] !== null && obj[k] !== undefined)
        .reduce(
          (newObj, k) => ({
            ...newObj,
            [k]: typeof obj[k] === 'object' ? removeEmpty(obj[k]) : obj[k],
          }),
          {}
        )
    : obj;

export default removeEmpty;
