import { Box } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import ExpandIcon from '../../../../../icons/controls/ExpandIcon';
import usePrioritySelectStyles from '../styles/PrioritySelect.styles';
import FancyOptionTitle from './FancyOptionTitle';

const TournamentItemWithPriority = ({
  option,
  newOption = {},
  textEllipse = false,
}) => {
  const classes = usePrioritySelectStyles();
  const tournamentCaptionClasses = clsx(classes.optionName, {
    [classes.shortedOptionName]: textEllipse || newOption.tournamentId,
  });

  return (
    <Box className={classes.itemContainer}>
      {`P${newOption.priority || option.priority}`}
      <FancyOptionTitle
        tooltip={true}
        name={newOption.name || option.name}
        code={isEmpty(newOption) ? option.countryCode : newOption.countryCode}
        underline={newOption.tournamentId}
        textClassName={tournamentCaptionClasses}
        containerClassName={classes.optionContainer}
      >
        {newOption.name || option.name}
      </FancyOptionTitle>
      {newOption.tournamentId && (
        <Fragment>
          <ExpandIcon
            style={{ transform: 'rotate(270deg)', filter: 'brightness(0%)' }}
          />
          <FancyOptionTitle
            tooltip={true}
            name={option.name}
            code={option.countryCode}
            textClassName={tournamentCaptionClasses}
            containerClassName={classes.optionContainer}
          >
            {option.name}
          </FancyOptionTitle>
        </Fragment>
      )}
    </Box>
  );
};

export default TournamentItemWithPriority;
