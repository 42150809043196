import { useApolloClient } from '@apollo/client';
import { createContext, useContext, useState } from 'react';
import getCoverageTemplate from '../v2/gql/sportbook-config/templates/queries/getCoverageTemplate';
import getDuplicateCoverageTemplate from '../v2/gql/sportbook-config/templates/queries/getDuplicateCoverageTemplate';
import getMarginTemplate from '../v2/gql/sportbook-config/templates/queries/getMarginTemplate';
import getSourcesTemplate from '../v2/gql/sportbook-config/templates/queries/getSourcesTemplate';

export const initialNodePath = {
  sportId: null,
  categoryId: null,
  tournamentId: null,
  eventId: null,
  market: {
    marketId: null,
    specifier: null,
  },
};

export const defaultNodePath = {
  live: initialNodePath,
  prematch: initialNodePath,
};

export const initialPreset = {
  commands: [],
  selectType: '',
  idKey: '',
  itemIds: [],
};

export const initialData = {
  live: {},
  prematch: {},
};

export const initialChangedMarginItems = {
  sports: {},
  categories: {},
  tournaments: {},
};

export const lineParentCommands = {
  sport: [],
  category: [],
  tournament: [],
  event: [],
};

export const initialParentCommands = {
  live: lineParentCommands,
  prematch: lineParentCommands,
};

export const initialSelectedCoverageItem = {
  id: null,
  status: true,
};

export const initialSelectedCoverageItemsConfig = {
  sport: initialSelectedCoverageItem,
  // we need to store status for all categories, not only for selected one
  category: {},
  tournament: { ...initialSelectedCoverageItem, categoryId: null },
  event: initialSelectedCoverageItem,
};

export const initialSelectedCoverageItems = {
  live: initialSelectedCoverageItemsConfig,
  prematch: initialSelectedCoverageItemsConfig,
};

export const TreeSelectContext = createContext({
  nodePath: defaultNodePath,
  setNodePath: () => {},
  preset: initialPreset,
  setPreset: () => {},
  data: initialData,
  setData: () => {},
  changedMarginItems: {},
  setChangedMarginItems: () => {},
  treeSelectLoading: null,
  setTreeSelectLoading: () => {},
  coverageMarketGroups: [],
  setCoverageMarketGroups: () => {},
  parentSourcesCommands: initialParentCommands,
  setParentSourcesCommands: () => {},
  presetApplied: false,
  setPresetApplied: () => {},
  treeSelectCrashed: false,
  selectedCoverageItemsConfig: initialSelectedCoverageItemsConfig,
  setSelectedCoverageItemsConfig: () => {},
  setTreeSelectCrashed: () => {},
  getSourcesTemplateQuery: () => {},
  getCoverageTemplateQuery: () => {},
  getMarginTemplateQuery: () => {},
});

export const useTreeSelectContext = () => {
  return useContext(TreeSelectContext);
};

const TreeSelectProvider = ({ children }) => {
  const client = useApolloClient();

  const [nodePath, setNodePath] = useState(defaultNodePath);
  const [preset, setPreset] = useState(initialPreset);
  const [data, setData] = useState(initialData);
  const [treeSelectLoading, setTreeSelectLoading] = useState(null);
  const [changedMarginItems, setChangedMarginItems] = useState({});
  const [coverageMarketGroups, setCoverageMarketGroups] = useState([]);
  const [selectedCoverageItemsConfig, setSelectedCoverageItemsConfig] =
    useState(initialSelectedCoverageItems);
  const [parentSourcesCommands, setParentSourcesCommands] = useState(
    initialParentCommands
  );
  const [presetApplied, setPresetApplied] = useState(false);
  const [treeSelectCrashed, setTreeSelectCrashed] = useState(false);

  //async lazy query
  const getSourcesTemplateQuery = async variables => {
    setTreeSelectLoading(true);
    return client.query({
      query: getSourcesTemplate,
      fetchPolicy: 'no-cache',
      variables,
    });
  };

  //async lazy query
  const getCoverageTemplateQuery = async (variables, duplicate = false) => {
    setTreeSelectLoading(true);
    return client.query({
      query: duplicate ? getDuplicateCoverageTemplate : getCoverageTemplate,
      fetchPolicy: 'no-cache',
      variables,
    });
  };

  //async lazy query
  const getMarginTemplateQuery = async variables => {
    setTreeSelectLoading(true);
    return client.query({
      query: getMarginTemplate,
      fetchPolicy: 'no-cache',
      variables,
    });
  };

  return (
    <TreeSelectContext.Provider
      value={{
        nodePath,
        setNodePath,
        preset,
        setPreset,
        data,
        setData,
        changedMarginItems,
        setChangedMarginItems,
        treeSelectLoading,
        setTreeSelectLoading,
        coverageMarketGroups,
        setCoverageMarketGroups,
        parentSourcesCommands,
        setParentSourcesCommands,
        presetApplied,
        setPresetApplied,
        treeSelectCrashed,
        setTreeSelectCrashed,
        selectedCoverageItemsConfig,
        setSelectedCoverageItemsConfig,
        getSourcesTemplateQuery,
        getCoverageTemplateQuery,
        getMarginTemplateQuery,
      }}
    >
      {children}
    </TreeSelectContext.Provider>
  );
};

export default TreeSelectProvider;
