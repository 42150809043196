import React, { forwardRef } from 'react';
import AutocompleteChips from '../AutocompleteChips/AutocompleteChips';
import Autocomplete from '../Autocomplete/Autocomplete';

const url = '/api/v1/PromoAdmin/promo/restrictions';

const prepareOptions = groups => {
  return groups.reduce((acc, group) => {
    return [
      ...acc,
      ...group.options.map(option => ({ ...option, type: group.label })),
    ];
  }, []);
};

const PromoRestrictionAutocomplete = forwardRef(
  (
    {
      name,
      value,
      onChange,
      helperText,
      error,
      disabled = false,
      label = 'Restrictions',
      required,
      multiple = false,
      additionalParams,
    },
    ref
  ) => {
    if (multiple) {
      return (
        <AutocompleteChips
          proxyValue={false}
          prepareOptions={prepareOptions}
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          helperText={helperText}
          error={error}
          disabled={disabled}
          label={label}
          required={required}
          url={url}
          valueKey={'value'}
        />
      );
    }

    return (
      <Autocomplete
        prepareOptions={prepareOptions}
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        helperText={helperText}
        error={error}
        disabled={disabled}
        label={label}
        required={required}
        url={url}
        additionalParams={additionalParams}
      />
    );
  }
);

export default PromoRestrictionAutocomplete;
