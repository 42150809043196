import { gql } from '@apollo/client';

const getBoostedOddsOutcomes = gql`
  query getBoostedOddsOutcomes(
    $brandId: BrandId!
    $eventId: EventId!
    $marketId: MarketId!
    $specifiers: String!
  ) {
    data: getBoostedOddsOutcomes(
      brand_id: $brandId
      event_id: $eventId
      market_id: $marketId
      specifiers: $specifiers
    ) {
      outcome_id
      outcome_name
      specifiers
    }
  }
`;

export default getBoostedOddsOutcomes;
