import React, { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  colorPickerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  colorPickerItem: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    borderWidth: 3,
    borderColor: '#fff',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  colorPickerItemRoot: {
    borderRadius: '50%',
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    transition: 'border-color 0.2s ease-in-out',
    '&:hover:not($colorPickerItemRootActive)': {
      borderColor: '#E8F2FF',
    },
  },
  colorPickerItemRootActive: {
    borderColor: '#1581FF',
  },
});

export const colorPickerColors = [
  '#FF8989',
  '#FFB489',
  '#FFE589',
  '#EEFF89',
  '#CBFF89',
  '#89FFC6',
  '#89F1FF',
  '#89A3FF',
  '#CB89FF',
  '#FF89FA',
  '#BFCDDE',
];

const ColorPicker = forwardRef(
  ({ colors = colorPickerColors, value, name, onChange }, ref) => {
    const classes = useStyles();

    return (
      <div className={classes.colorPickerRoot} ref={ref}>
        {colors.map(color => (
          <div
            onClick={() => {
              onChange(color);
            }}
            className={clsx(classes.colorPickerItemRoot, {
              [classes.colorPickerItemRootActive]: value === color,
            })}
            key={color}
          >
            <div
              className={classes.colorPickerItem}
              style={{
                backgroundColor: color,
              }}
            />
          </div>
        ))}
      </div>
    );
  }
);

export default ColorPicker;
