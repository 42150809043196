import { Box, ClickAwayListener, Popper } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useContext, useRef, useState } from 'react';
import ContextMenuItem from '../../../../../components/ContextMenuItem';
import { ConfirmationPromptContext } from '../../../../../providers/ConfirmationPromptProvider';
import { useToasts } from '../../../../providers/ToastsProvider';
import ExpandButton from '../../../Button/ExpandButton';
import NotificationAlert, {
  AlertVariant,
} from '../../../Notifications/NotificationAlert';
import usePrioritySelectStyles from '../styles/PrioritySelect.styles';
import ModalTitle from './ModalTitle';
import PrioritySelectionModalContent from './PrioritySelectionModalContent';

const PrioritySelect = ({
  option,
  options = [],
  itemIndex,
  priority = 1,
  disabled,
  onSubmit,
}) => {
  const classes = usePrioritySelectStyles();

  const { openConfirmation, closeConfirmation } = useContext(
    ConfirmationPromptContext
  );
  const { showToast } = useToasts();

  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedTournament = useRef({});

  const onPopperClose = useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  const onSelectOpen = useCallback(
    e => {
      setExpanded(currState => !currState);
      setAnchorEl(e.currentTarget);
    },
    [setExpanded, setAnchorEl]
  );

  const onPrioritySelect = useCallback(
    ({ value }) => {
      setExpanded(false);
      const startIndex = itemIndex;
      const endIndex = +value - 1;
      onSubmit(startIndex, endIndex);
    },
    [setExpanded, onSubmit, itemIndex]
  );

  const onModalClose = useCallback(() => {
    closeConfirmation();
    selectedTournament.current = {};
  }, [selectedTournament, closeConfirmation]);

  const onPrioritySelectionClick = useCallback(() => {
    openConfirmation({
      buttonLabel: 'Save',
      title: <ModalTitle title={'Priority selection'} onClose={onModalClose} />,
      message: (
        <PrioritySelectionModalContent
          currentTournament={option}
          selectedTournament={selectedTournament}
          options={options}
        />
      ),
      callback: async () => {
        const selectedOption = selectedTournament.current;
        const startIndex = itemIndex;
        const endIndex = options.findIndex(
          currentOption =>
            currentOption.tournamentId === selectedOption.tournamentId
        );
        onSubmit(startIndex, endIndex).then(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS} timeout={5000}>
              {'Priority changed successfully'}
            </NotificationAlert>
          );
        });
        selectedTournament.current = {};
      },
    });
  }, [
    openConfirmation,
    onModalClose,
    onSubmit,
    selectedTournament,
    showToast,
    option,
    options,
    itemIndex,
  ]);

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(classes.label, classes.leftButton, {
          [classes.disabled]: disabled,
        })}
        onClick={!disabled && onPrioritySelectionClick}
      >{`P${priority}`}</Box>
      <Box
        className={clsx(classes.label, classes.rightButton, {
          [classes.disabled]: disabled,
        })}
      >
        <ExpandButton expanded={expanded} onClick={!disabled && onSelectOpen} />
        {expanded && (
          <Popper
            id="priorities-autocomplete"
            open={expanded}
            anchorEl={anchorEl}
            className={classes.popper}
          >
            <ClickAwayListener onClickAway={onPopperClose}>
              <Box className={classes.popperItemsContainer}>
                {options.map(option => (
                  <ContextMenuItem
                    disabled={option.priority === priority}
                    style={{ borderRadius: 8 }}
                    onClick={e =>
                      onPrioritySelect({ e, value: option.priority })
                    }
                  >
                    {`P${option.priority}`}
                  </ContextMenuItem>
                ))}
              </Box>
            </ClickAwayListener>
          </Popper>
        )}
      </Box>
    </Box>
  );
};

export default PrioritySelect;
