import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Grid,
  WindowScroller,
} from 'react-virtualized';
import PropTypes from 'prop-types';
import TableBox from './TableBox';
import './VirtualizedTable.css';
import { useTableBulkContext } from '../../providers/TableBulkProvider';
import TableEmptyPlaceholder from './TableEmptyPlaceholder';
import { useTableAnimatedRowContext } from '../../providers/TableAnimatedRowProvider';
import clsx from 'clsx';
import TableLoadingPlaceholder from './TableLoadingPlaceholder';
import mergeRefs from '../../../utils/mergeRefs';
import Scrollbar from '../../../components/Scrollbar/Scrollbar';

function defaultCellRangeRenderer(_ref) {
  var cellCache = _ref.cellCache,
    cellRenderer = _ref.cellRenderer,
    columnSizeAndPositionManager = _ref.columnSizeAndPositionManager,
    columnStartIndex = _ref.columnStartIndex,
    columnStopIndex = _ref.columnStopIndex,
    deferredMeasurementCache = _ref.deferredMeasurementCache,
    horizontalOffsetAdjustment = _ref.horizontalOffsetAdjustment,
    isScrolling = _ref.isScrolling,
    isScrollingOptOut = _ref.isScrollingOptOut,
    parent = _ref.parent,
    rowSizeAndPositionManager = _ref.rowSizeAndPositionManager,
    rowStartIndex = _ref.rowStartIndex,
    rowStopIndex = _ref.rowStopIndex,
    styleCache = _ref.styleCache,
    verticalOffsetAdjustment = _ref.verticalOffsetAdjustment,
    visibleColumnIndices = _ref.visibleColumnIndices,
    visibleRowIndices = _ref.visibleRowIndices;
  var renderedCells = []; // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
  // User cannot scroll beyond these size limitations.
  // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
  // We should never cache styles for compressed offsets though as this can lead to bugs.
  // See issue #576 for more.

  var areOffsetsAdjusted =
    columnSizeAndPositionManager.areOffsetsAdjusted() ||
    rowSizeAndPositionManager.areOffsetsAdjusted();
  var canCacheStyle = !isScrolling && !areOffsetsAdjusted;

  for (var rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
    var rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

    for (
      var columnIndex = columnStartIndex;
      columnIndex <= columnStopIndex;
      columnIndex++
    ) {
      var columnDatum =
        columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
      var isVisible =
        columnIndex >= visibleColumnIndices.start &&
        columnIndex <= visibleColumnIndices.stop &&
        rowIndex >= visibleRowIndices.start &&
        rowIndex <= visibleRowIndices.stop;
      var key = ''.concat(rowIndex, '-').concat(columnIndex);
      var style = void 0; // Cache style objects so shallow-compare doesn't re-render unnecessarily.

      if (canCacheStyle && styleCache[key]) {
        style = styleCache[key];
      } else {
        // In deferred mode, cells will be initially rendered before we know their size.
        // Don't interfere with CellMeasurer's measurements by setting an invalid size.
        if (
          deferredMeasurementCache &&
          !deferredMeasurementCache.has(rowIndex, columnIndex)
        ) {
          // Position not-yet-measured cells at top/left 0,0,
          // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
          // Positioning them further to the right/bottom influences their measured size.
          style = {
            height: 'auto',
            left: 0,
            position: 'absolute',
            top: 0,
            width: 'auto',
          };
        } else {
          style = {
            height: rowDatum.size,
            left: columnDatum.offset + horizontalOffsetAdjustment,
            position: 'absolute',
            top: rowDatum.offset + verticalOffsetAdjustment,
            width: columnDatum.size,
          };
          styleCache[key] = style;
        }
      }

      var cellRendererParams = {
        columnIndex: columnIndex,
        isScrolling: isScrolling,
        isVisible: isVisible,
        key: key,
        parent: parent,
        rowIndex: rowIndex,
        style: style,
      };
      var renderedCell = void 0; // Avoid re-creating cells while scrolling.
      // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
      // If a scroll is in progress- cache and reuse cells.
      // This cache will be thrown away once scrolling completes.
      // However if we are scaling scroll positions and sizes, we should also avoid caching.
      // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
      // For more info refer to issue #395
      //
      // If isScrollingOptOut is specified, we always cache cells.
      // For more info refer to issue #1028

      if (
        (isScrollingOptOut || isScrolling) &&
        !horizontalOffsetAdjustment &&
        !verticalOffsetAdjustment
      ) {
        if (!cellCache[key]) {
          cellCache[key] = cellRenderer(cellRendererParams);
        } else {
          console.log('got from cache', cellRendererParams);
        }

        renderedCell = cellCache[key]; // If the user is no longer scrolling, don't cache cells.
        // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
      } else {
        renderedCell = cellRenderer(cellRendererParams);
      }

      if (renderedCell == null || renderedCell === false) {
        continue;
      }

      if (process.env.NODE_ENV !== 'production') {
        // warnAboutMissingStyle(parent, renderedCell);
      }

      renderedCells.push(renderedCell);
    }
  }

  return renderedCells;
}

function cellRangeRenderer({
  cellCache, // Temporary cell cache used while scrolling
  cellRenderer, // Cell renderer prop supplied to Grid
  columnSizeAndPositionManager, // @see CellSizeAndPositionManager,
  columnStartIndex, // Index of first column (inclusive) to render
  columnStopIndex, // Index of last column (inclusive) to render
  horizontalOffsetAdjustment, // Horizontal pixel offset (required for scaling)
  isScrolling, // The Grid is currently being scrolled
  rowSizeAndPositionManager, // @see CellSizeAndPositionManager,
  rowStartIndex, // Index of first row (inclusive) to render
  rowStopIndex, // Index of last row (inclusive) to render
  scrollLeft, // Current horizontal scroll offset of Grid
  scrollTop, // Current vertical scroll offset of Grid
  styleCache, // Temporary style (size & position) cache used while scrolling
  verticalOffsetAdjustment, // Vertical pixel offset (required for scaling)
}) {
  const renderedCells = [];

  for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
    // This contains :offset (top) and :size (height) information for the cell
    let rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

    for (
      let columnIndex = columnStartIndex;
      columnIndex <= columnStopIndex;
      columnIndex++
    ) {
      // This contains :offset (left) and :size (width) information for the cell
      let columnDatum =
        columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);

      // Be sure to adjust cell position in case the total set of cells is too large to be supported by the browser natively.
      // In this case, Grid will shift cells as a user scrolls to increase cell density.
      let left = columnDatum.offset + horizontalOffsetAdjustment;
      let top = rowDatum.offset + verticalOffsetAdjustment;

      // The rest of the information you need to render the cell are contained in the data.
      // Be sure to provide unique :key attributes.
      let key = `${rowIndex}-${columnIndex}`;
      let height = rowDatum.size;
      let width = columnDatum.size;

      // Now render your cell and additional UI as you see fit.
      // Add all rendered children to the :renderedCells Array.
    }
  }

  return renderedCells;
}

const VirtualizedTable = forwardRef(
  (
    {
      id = 'id',
      data = [],
      renderRow,
      renderHeaderRow,
      getRowClassName = () => {},
      rowActions,
      loading,
      gridTemplate,
      rowHeight = 42,
      expandable,
      overscanRowCount = 5,
      tableOffsetTop,
      registerChild,
      onSectionRendered,
      scrollable,
    },
    ref
  ) => {
    const cacheRef = useRef(
      new CellMeasurerCache({
        defaultHeight: rowHeight,
        minHeight: rowHeight,
        fixedWidth: true,
      })
    );

    const windowScrollerRef = useRef(null);
    const gridRef = useRef(null);
    const scrollableNodeRef = useRef(null);
    const tableBoxRef = useRef(null);
    const savedScrollValue = useRef(0);

    const bulk = useTableBulkContext();

    const { animatedRows } = useTableAnimatedRowContext();

    const clearRowHeightCache = useCallback((rowIndex = false) => {
      if (rowIndex === false) {
        cacheRef.current.clearAll();
      } else {
        cacheRef.current.clear(rowIndex, 0);
      }
    }, []);

    const recalculateSizes = useCallback(rowIndex => {
      if (!rowIndex && windowScrollerRef.current) {
        windowScrollerRef.current.updatePosition();
      }

      gridRef.current &&
        gridRef.current.recomputeGridSize({ rowIndex, columnIndex: 0 });
    }, []);

    if (ref) {
      ref.current = {
        recalculateSizes,
        clearRowHeightCache,
        cache: cacheRef.current,
        tableBoxEl: tableBoxRef.current,
      };
    }

    useEffect(() => {
      if (!loading) {
        recalculateSizes();
      }
    }, [loading, tableOffsetTop, recalculateSizes]);

    useEffect(() => {
      // setInterval(recalculateSizes, 2000);
    }, [recalculateSizes]);

    const cellRenderer = useCallback(
      ({
        columnIndex, // Horizontal (column) index of cell
        // isScrolling, // The Grid is currently being scrolled
        isVisible, // This cell is visible within the grid (eg it is not an overscanned cell)
        key, // Unique key within array of cells
        parent, // Reference to the parent Grid (instance)
        rowIndex, // Vertical (row) index of cell
        style, // Style object to be applied to cell (to position it);
      }) => {
        const rowData = data[rowIndex];
        const rowId = rowData[id];

        return (
          <CellMeasurer
            cache={cacheRef.current}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}
          >
            <div
              key={rowId}
              className={clsx('tableRow', {
                tableRowAnimated: animatedRows && animatedRows[rowId],
              })}
              style={style}
            >
              {renderRow({ rowIndex, rowData, rowId, isVisible })}
            </div>
          </CellMeasurer>
        );
      },
      [data, renderRow, animatedRows, id]
    );

    const renderTable = useMemo(
      () =>
        ({
          height,
          isScrolling,
          registerChild: registerChildWindowScroller,
          onChildScroll,
          scrollTop,
        }) => {
          const scrollbar = scrollableNodeRef.current;

          return (
            <TableBox
              ref={mergeRefs(
                registerChildWindowScroller,
                registerChild,
                tableBoxRef
              )}
              scrollable={scrollable}
              containerWidth={scrollbar && scrollbar.clientWidth}
            >
              {renderHeaderRow}
              {data.length === 0 && !loading ? (
                <TableEmptyPlaceholder />
              ) : loading ? (
                <TableLoadingPlaceholder />
              ) : (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <Grid
                      onSectionRendered={onSectionRendered}
                      deferredMeasurementCache={cacheRef.current}
                      cellRangeRenderer={defaultCellRangeRenderer}
                      ref={gridRef}
                      overscanRowCount={overscanRowCount}
                      isScrolling={isScrolling}
                      autoHeight={true}
                      cellRenderer={cellRenderer}
                      isScrollingOptOut={false}
                      columnCount={1}
                      columnWidth={width}
                      height={height}
                      width={width}
                      rowCount={loading ? 5 : data.length}
                      rowHeight={cacheRef.current.rowHeight}
                      scrollTop={scrollTop}
                      onScroll={onChildScroll}
                    />
                  )}
                </AutoSizer>
              )}
            </TableBox>
          );
        },

      [
        renderHeaderRow,
        cellRenderer,
        loading,
        data,
        overscanRowCount,
        onSectionRendered,
        registerChild,
        scrollable,
        scrollableNodeRef,
      ]
    );

    if (scrollable) {
      return (
        <Scrollbar scrollableNodeRef={scrollableNodeRef}>
          <WindowScroller scrollElement={window} ref={windowScrollerRef}>
            {renderTable}
          </WindowScroller>
        </Scrollbar>
      );
    }

    return (
      <WindowScroller scrollElement={window} ref={windowScrollerRef}>
        {renderTable}
      </WindowScroller>
    );
  }
);

VirtualizedTable.propTypes = {
  tableOffsetTop: PropTypes.number,
};
VirtualizedTable.defaultProps = {
  tableOffsetTop: 0,
};

export default VirtualizedTable;
