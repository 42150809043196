import { gql } from '@apollo/client';
import MERGER_GROUP_FRAGMENT from '../fragments/mergerGroupFragment';

const getGroups = gql`
  ${MERGER_GROUP_FRAGMENT}
  query getGroups(
    $date: DatetimeRange
    $sport: [SportId!]
    $status: [GroupStatus!]
    $providers: [Provider!]
    $query: String
    $isOutright: Boolean
    $limit: Int!
    $offset: Int!
  ) {
    data: getGroups(
      date: $date
      sport: $sport
      status: $status
      providers: $providers
      query: $query
      isOutright: $isOutright
      limit: $limit
      offset: $offset
    ) {
      items {
        ...MergerGroupFragment
      }
      total
    }
  }
`;

export default getGroups;
