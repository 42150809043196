import React, { useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '../../../components/Button/Button';
import TextField from '../../../components/Inputs/TextField';
import FancyAutocomplete from '../../../components/Inputs/FancyAutocomplete/FancyAutocomplete';
import useDebounced from '../../../hooks/useDebounced';
import SPORTS_AUTOCOMPLETE_QUERY from '../../../gql/TradingTool/queries/SPORTS_AUTOCOMPLETE_QUERY';

const useStyles = makeStyles({
  marginLadderActionsPanel: {
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
  actionsPanelButtons: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  creatLadderButton: {
    marginRight: 8,
    textTransform: 'none',
  },
  ladderSearchField: {
    marginLeft: 8,
  },
  ladderSearchFieldInput: {
    paddingRight: 32,
  },
});

const MarginLadderActionsPanel = ({
  filters,
  onSearchSport,
  onSearchLadder,
  onCreateButtonClick,
}) => {
  const classes = useStyles();
  const [sportSearchValue, setSportSearchValue] = useState(filters.sport);
  const [ladderSearchValue, setLadderSearchValue] = useState(filters.name);
  const debouncedSearchLadder = useDebounced(onSearchLadder, 600);

  const handleSportSearch = useCallback(
    value => {
      if (value) {
        const { id, name } = value;
        setSportSearchValue({ id, name });
        onSearchSport({ id, name });
      } else {
        setSportSearchValue(null);
        onSearchSport(null);
      }
    },
    [onSearchSport]
  );

  const handleLadderSearch = useCallback(
    value => {
      setLadderSearchValue(value);
      debouncedSearchLadder(value);
    },
    [debouncedSearchLadder]
  );

  return (
    <Box className={classes.marginLadderActionsPanel}>
      <Box className={classes.actionsPanelButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onCreateButtonClick}
          className={classes.creatLadderButton}
        >
          Create Ladder
        </Button>
      </Box>
      <FancyAutocomplete
        gqlQuery={SPORTS_AUTOCOMPLETE_QUERY}
        label={'Select sport'}
        valueKey="id"
        labelKey="name"
        multiple={false}
        checkValueOptions={false}
        value={sportSearchValue}
        onChange={handleSportSearch}
        alwaysShowClearButton
      />
      <TextField
        label="Search ladder"
        value={ladderSearchValue}
        onChange={({ target: { value } }) => handleLadderSearch(value)}
        withSearchIcon
        clearButton
        onClear={() => handleLadderSearch('')}
        className={classes.ladderSearchField}
        extraClasses={{
          input: classes.ladderSearchFieldInput,
        }}
      />
    </Box>
  );
};

export default MarginLadderActionsPanel;
