import { gql } from '@apollo/client';

const getMarginPresetNames = gql`
  query getMarginPresetNames($sportId: SportId!) {
    data: getMarginPresetNames(sportId: $sportId) {
      id
      name
    }
  }
`;

export default getMarginPresetNames;
