import { gql } from '@apollo/client';
import MERGER_GROUP_FRAGMENT from '../fragments/mergerGroupFragment';

const editEvent = gql`
  ${MERGER_GROUP_FRAGMENT}
  mutation editEvent(
    $sportId: SportId!
    $groupId: GroupId!
    $eventId: EventId!
    $descType: DescType!
    $eventEditModel: EventEditModel!
  ) {
    editEvent(
      sportId: $sportId
      groupId: $groupId
      eventId: $eventId
      descType: $descType
      eventEditModel: $eventEditModel
    ) {
      ...MergerGroupFragment
    }
  }
`;

export default editEvent;
