import AltDateWidget from './AltDateWidget';
import AltDateTimeWidget from './AltDateTimeWidget';
import BaseInput from './BaseInput';
import CheckboxWidget from './CheckboxWidget';
import CheckboxesWidget from './CheckboxesWidget';
import ColorWidget from './ColorWidget';
import DateWidget from './DateWidget';
import DateTimeWidget from './DateTimeWidget';
import EmailWidget from './EmailWidget';
import FileWidget from './FileWidget';
import HiddenWidget from './HiddenWidget';
import PasswordWidget from './PasswordWidget';
import RadioWidget from './RadioWidget';
import RangeWidget from './RangeWidget';
import TextWidget from './TextWidget';
import URLWidget from './URLWidget';
import UpDownWidget from './UpDownWidget';
import MarginSettings from './MarginSettings';
import ImageUploader from './ImageUploader';
import ImageCropUploader from './ImageCropUploader';
import AutocompleteWidget from './AutocompleteWidget';
import SearchSelectWidget from './SearchSelectWidget';
import FileUpload from './FileUpload';
import QRCodeWidget from './QRCodeWidget';
import Codemirror from './Codemirror';
import OrderListWidget from '../../../containers/PavoForm/widgets/OrderListWidget/OrderListWidget';
import TransferList from '../../../containers/PavoForm/widgets/TransferList/TransferList';
import TextareaWidget from './TextareaWidget';
import SelectWidget from '../../../containers/PavoForm/widgets/SelectWidget';

const widgets = {
  BaseInput,
  PasswordWidget,
  RadioWidget,
  UpDownWidget,
  RangeWidget,
  SelectWidget,
  TextWidget,
  DateWidget,
  DateTimeWidget,
  AltDateWidget,
  AltDateTimeWidget,
  EmailWidget,
  URLWidget,
  TextareaWidget,
  HiddenWidget,
  ColorWidget,
  FileWidget,
  CheckboxWidget,
  CheckboxesWidget,
  MarginSettings,
  ImageUploader,
  ImageCropUploader,
  FileUpload,
  autocomplete: AutocompleteWidget,
  search_select: SearchSelectWidget,
  qrcode: QRCodeWidget,
  codemirror: Codemirror,
  order_list: OrderListWidget,
  transferList: TransferList,
};

export default widgets;
