import { gql } from '@apollo/client';

const getCategoryLangs = gql`
  query getCategoryLangs($sportId: SportId!, $categoryId: CategoryId!) {
    getCategoryLangs(sportId: $sportId, categoryId: $categoryId) {
      langs
      id
      countryCode
    }
  }
`;

export default getCategoryLangs;
