import NotificationToast from './NotificationToast';

const NotificationToastList = ({ toasts }) => {
  return (
    <div
      style={{
        top: 50,
        right: 0,
        maxWidth: '560px',
        position: 'fixed',
        zIndex: 1100,
        pointerEvents: 'none',
      }}
    >
      {toasts.map(({ key, render }) => {
        return <NotificationToast key={key}>{render}</NotificationToast>;
      })}
    </div>
  );
};

export default NotificationToastList;
