import { forwardRef } from 'react';
import Select from '../Select/Select';
import useAutocompleteData from '../../../hooks/useAutocompleteData';

const UserSelect = forwardRef(({ multiple, ...props }, ref) => {
  const { data: options = [] } = useAutocompleteData({
    url: '/api/v1/AuthServiceAdmin/user_emails',
    withoutParams: true,
  });

  return (
    <Select
      ref={ref}
      label={'Updated by'}
      multiple={multiple}
      options={options}
      labelKey={'email'}
      valueKey={'id'}
      {...props}
    />
  );
});

export default UserSelect;
