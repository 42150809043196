import { useApolloClient, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React, { useCallback, useMemo } from 'react';
import RangeDateInput from '../../../../../../components/inputs/RangeDateInput';
import ArrayChipFilter from '../../../../../../uiComponents/Filters/FilterFields/ArrayChipFilter';
import BetTypeSelect from '../../../../../components/Inputs/BetTypeSelect/BetTypeSelect';
import BonusTypeSelect from '../../../../../components/Inputs/BonusTypeSelect/BonusTypeSelect';
import BrandSelect from '../../../../../components/Inputs/BrandSelect/BrandSelect';
import Checkbox from '../../../../../components/Inputs/Checkbox';
import DeviceSelect from '../../../../../components/Inputs/DeviceSelect/DeviceSelect';
import NumberRangeInput from '../../../../../components/Inputs/NumberRangeInput';
import OperatorSelect from '../../../../../components/Inputs/OperatorSelect/OperatorSelect';
import Select from '../../../../../components/Inputs/Select/Select';
import SportsSelect from '../../../../../components/Inputs/SportsSelect/SportsSelect';
import TextField from '../../../../../components/Inputs/TextField';
import TableActionCards from '../../../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../../../components/TablePagination/TablePagination';
import getGamingActivityList from '../../../../../gql/Reports/queries/getGamingActivityList';
import getOperatorGamingActivityList from '../../../../../gql/Reports/queries/getOperatorGamingActivityList';
import useFilters from '../../../../../hooks/useFilters';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import useTableColumnToggle from '../../../../../hooks/useTableColumnToggle';
import useTableOrderByChange from '../../../../../hooks/useTableOrderByChange';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { useIsOperator } from '../../../../../providers/OperatorProvider';
import GamingActivityL2 from './GamingActivityL2';
import GamingActivityTable, {
  initialToggledColumns,
} from './GamingActivityTable';
import ClickhouseAutocomplete, {
  ClickhouseAutocompleteMethod,
} from './components/Inputs/ClickhouseAutocomplete';
import GAGroupBySelect, {
  GA_GROUP_BY,
} from './components/Inputs/GAGroupBySelect';
import { BET_STATUS } from '../../../../BetHistory/BetHistory';
import { tableNames } from '../../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../../components/ErrorWidget';

const groupByToFilterMap = {
  [GA_GROUP_BY.day]: ({ created_at__day }) => ({
    filterBetDay: created_at__day,
  }),
  [GA_GROUP_BY.month]: ({ created_at__month }) => ({
    filterBetMonth: created_at__month,
  }),
  [GA_GROUP_BY.week]: ({ created_at__week }) => ({
    filterBetWeek: created_at__week,
  }),
  [GA_GROUP_BY.year]: ({ created_at__year }) => ({
    filterBetYear: created_at__year,
  }),
  [GA_GROUP_BY.operatorId]: ({ operatorId }) => ({
    filterOperatorId: [operatorId],
  }),
  [GA_GROUP_BY.brandId]: ({ brand_id }) => ({
    filterBrandId: [brand_id],
  }),
  [GA_GROUP_BY.playerId]: ({ playerId }) => ({
    filterPlayerId: [playerId],
  }),
  [GA_GROUP_BY.provider]: ({ provider }) => ({
    filterMarketProvider: [provider],
  }),
  [GA_GROUP_BY.sportId]: ({ sportId }) => ({
    filterSportId: [sportId],
  }),
  [GA_GROUP_BY.categoryId]: ({ categoryId }) => ({
    filterCategoryId: [categoryId],
  }),
  [GA_GROUP_BY.tournamentId]: ({ tournamentId }) => ({
    filterTournamentId: [tournamentId],
  }),
  [GA_GROUP_BY.eventId]: ({ eventId }) => ({
    filterEventId: [eventId],
  }),
  [GA_GROUP_BY.marketId]: ({ marketId }) => ({
    filterMarketId: [marketId],
  }),
  [GA_GROUP_BY.marketName]: ({ marketName }) => ({
    filterMarketName: marketName,
  }),
  [GA_GROUP_BY.device]: ({ deviceType }) => ({
    filterDeviceType: [deviceType],
  }),
  [GA_GROUP_BY.betStatus]: ({ betStatus }) => ({
    filterBetStatus: [betStatus],
  }),
  [GA_GROUP_BY.bonusType]: ({ bonusType }) => ({
    filterBonusType: [bonusType],
  }),
  [GA_GROUP_BY.country]: ({ country }) => ({
    filterCountry: [country],
  }),
  [GA_GROUP_BY.language]: ({ lang }) => ({
    filterLang: [lang],
  }),
  [GA_GROUP_BY.playerCcf]: ({ playerCcf }) => ({
    filterPlayerCcf: [playerCcf],
  }),
  [GA_GROUP_BY.betType]: ({ betType }) => ({
    filterBetType: [betType],
  }),
  [GA_GROUP_BY.currency]: ({ currency }) => ({
    filterCurrency: [currency],
  }),
  [GA_GROUP_BY.layout]: ({ sourceLayout }) => ({
    filterLayout: [sourceLayout],
  }),
  [GA_GROUP_BY.page]: ({ sourcePage }) => ({
    filterPage: [sourcePage],
  }),
  [GA_GROUP_BY.section]: ({ sourceSection }) => ({
    filterSection: [sourceSection],
  }),
  [GA_GROUP_BY.isLive]: ({ isLive }) => ({
    filterIsLive: isLive,
  }),
};

const GamingActivityTab = ({ eventId }) => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.GamingActivityTab,
    onChangeLimit: () => {},
  });

  const isOperator = useIsOperator();

  const [toggledColumns, onToggleColumns] = useTableColumnToggle(
    initialToggledColumns
  );

  const client = useApolloClient();

  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange();

  const onResubmit = useCallback(() => {
    client.refetchQueries({
      include: [
        isOperator ? getOperatorGamingActivityList : getGamingActivityList,
      ],
    });
  }, [client, isOperator]);

  const defaultFilterValues = useMemo(() => {
    return {
      groupBy: GA_GROUP_BY.day,
      groupByL2: [],
      testPlayers: 'exclude',
      excludeFake: false,
      rangeDate: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeCompareDate: {
        rangeFrom: null,
        rangeTo: null,
      },
      filterOperatorId: [],
      filterSportId: [],
      filterCategoryName: [],
      filterCategoryId: [],
      filterTournamentName: [],
      filterTournamentId: [],
      filterEventName: [],
      filterEventId: [eventId],
      filterMarketName: [],
      filterMarketId: [],
      filterOutcomeName: [],
      filterOutcomeId: [],
      filterPlayerUsername: [],
      filterLang: [],
      filterCountry: [],
      filterCurrency: [],
      filterBetType: [],
      filterBonusType: [],
      filterBonusTemplateId: '',
      filterDeviceType: [],
      filterBetStatus: [],
      filterSportType: [],
      filterPlayerId: [],
      filterPlayerIdExclude: [],
      filterLayout: [],
      filterPage: [],
      filterSection: [],
      filterLive: [],
      rangeStake: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeOdd: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeTurnover: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeAvgStake: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeGgr: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeMargin: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeBets: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangePlayers: {
        rangeFrom: null,
        rangeTo: null,
      },
      filterMarketProvider: [],
      rangeDiffBetSettle: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeDiffBetStart: {
        rangeFrom: null,
        rangeTo: null,
      },
      rangeCcfBet: {
        rangeFrom: null,
        rangeTo: null,
      },
    };
  }, [eventId]);

  const { filtersCard, filters } = useFilters({
    tableName: tableNames.GamingActivityTab,
    resetOffset: setOffset,
    onResubmit,
    defaultValues: defaultFilterValues,
    filters: [
      {
        name: 'testPlayers',
        label: 'Test players',
        // fullWidth: false,
        component: (
          <Select
            options={[
              {
                label: 'Exclude',
                value: 'exclude',
              },
              {
                label: 'Include',
                value: 'include',
              },
              {
                label: 'Lookup',
                value: 'lookup',
              },
            ]}
          />
        ),
        toggled: false,
      },
      {
        name: 'groupBy',
        label: 'Group Level 1',
        component: <GAGroupBySelect isOperator={isOperator} />,
        toggled: true,
      },
      {
        name: 'groupByL2',
        label: 'Group Level 2',
        component: ({ groupBy }) => <GAGroupBySelect exclude={[groupBy]} />,
        deps: ['groupBy'],
        toggled: true,
      },
      {
        name: 'rangeCompareDate',
        label: 'Compare Date',
        component: <RangeDateInput />,
        toggled: true,
      },
      {
        name: 'filterOperatorId',
        label: 'Choose Operator',
        component: <OperatorSelect multiple />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'filterBrandId',
        label: 'Choose Brand',
        component: ({ filterOperatorId: operator, ...props }) => {
          return <BrandSelect operatorIds={operator} multiple {...props} />;
        },
        deps: ['filterOperatorId'],
        hidden: isOperator,
        toggled: true,
      },
      {
        name: 'rangeDate',
        label: 'Date',
        component: <RangeDateInput />,
        toggled: true,
      },
      {
        name: 'filterSportId',
        label: 'Sport',
        component: <SportsSelect multiple />,
        hidden: isOperator,
      },
      {
        name: 'filterCategoryName',
        label: 'Category',
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterCategoryId',
        label: 'Category ID',
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterTournamentName',
        label: 'Tournament',
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterTournamentId',
        label: 'Tournament ID',
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterEventName',
        label: 'Event',
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterEventId',
        label: 'Event ID',
        component: <ArrayChipFilter />,
        hidden: isOperator,
      },
      {
        name: 'filterMarketName',
        label: 'Market',
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterMarketId',
        label: 'Market ID',
        component: <ArrayChipFilter />,
      },
      {
        name: 'filterOutcomeName',
        label: 'Outcome',
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterOutcomeId',
        label: 'Outcome ID',
        component: <ArrayChipFilter />,
      },
      {
        name: 'filterPlayerUsername',
        label: 'Player Username',
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterLang',
        label: 'Language',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.LANG}
            multiple
          />
        ),
      },
      {
        name: 'filterCountry',
        label: 'Country',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.COUNTRY}
            multiple
          />
        ),
      },
      {
        name: 'filterCurrency',
        label: 'Currency',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.CURRENCY}
            multiple
          />
        ),
      },
      {
        name: 'filterBetType',
        label: 'Bet Type',
        component: <BetTypeSelect multiple />,
      },
      {
        name: 'filterBonusType',
        label: 'Bonus Type',
        component: <BonusTypeSelect multiple />,
      },
      {
        name: 'filterBonusTemplateId',
        label: 'Bonus Template ID',
        component: <TextField />,
      },
      {
        name: 'filterDeviceType',
        label: 'Device Type',
        component: <DeviceSelect multiple />,
      },
      {
        name: 'filterBetStatus',
        label: 'Bet Status',
        // Todo reuse component from bestream
        component: (
          <Select
            multiple
            options={[
              {
                label: 'Open',
                value: BET_STATUS.OPEN,
              },
              {
                label: 'Lost',
                value: BET_STATUS.LOST,
              },
              {
                label: 'New',
                value: BET_STATUS.NEW,
              },
              {
                label: 'Won',
                value: BET_STATUS.WON,
              },
              {
                label: 'Refund',
                value: BET_STATUS.REFUND,
              },
              {
                label: 'Cashed out',
                value: BET_STATUS.CASHED_OUT,
              },
              {
                label: 'Canceled',
                value: BET_STATUS.CANCELED,
              },
              {
                label: 'Half-Won',
                value: BET_STATUS.HALF_WON,
              },
              {
                label: 'Half-Lost',
                value: BET_STATUS.HALF_LOST,
              },
              {
                label: 'Vip Take Request',
                value: BET_STATUS.VIP_TAKE_REQUESTED,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'filterSportType',
        label: 'Sport Type',
        component: (
          <Select
            multiple
            options={[
              { label: 'E-sport', value: 'esport' },
              { label: 'Virtual', value: 'virtual' },
            ]}
          />
        ),
      },
      {
        name: 'filterPlayerId',
        label: 'Betby Player ID',
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterPlayerIdExclude',
        label: 'Exclude Player ID',
        component: <ArrayChipFilter />,
        toggled: true,
      },
      {
        name: 'filterLayout',
        label: 'Layout',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.LAYOUT}
            multiple
          />
        ),
        hidden: isOperator,
      },
      {
        name: 'filterPage',
        label: 'Page',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.PAGE}
            multiple
          />
        ),
      },
      {
        name: 'filterSection',
        label: 'Section',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.SECTION}
            multiple
          />
        ),
      },
      {
        name: 'filterLive',
        label: 'Live/Prematch',
        component: (
          <Select
            multiple
            options={[
              { label: 'Live', value: 'live' },
              { label: 'Prematch', value: 'prematch' },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'rangeStake',
        label: 'Bet Stake (€)',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeOdd',
        label: 'Bet Odd',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeTurnover',
        label: 'Turnover',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeAvgStake',
        label: 'Avg Stake',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeGgr',
        label: 'GGR (€)',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeMargin',
        label: 'Margin (%)',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeBets',
        label: 'Bets #',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangePlayers',
        label: 'Players #',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'filterMarketProvider',
        label: 'Provider',
        component: (
          <ClickhouseAutocomplete
            method={ClickhouseAutocompleteMethod.PROVIDER}
            multiple
          />
        ),
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'rangeDiffBetSettle',
        label: 'Timeframe(Bet, Settlement), sec',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeDiffBetStart',
        label: 'Timeframe(Bet, Start), hour',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'rangeCcfBet',
        label: 'CCF (Bet Time)',
        component: <NumberRangeInput fullWidth />,
      },
      {
        name: 'excludeFake',
        label: 'Exclude Fake',
        component: <Checkbox />,
      },
    ],
  });

  const actions = useMemo(() => {
    return [filtersCard];
  }, [filtersCard]);

  const inputFilters = useMemo(() => {
    const { groupByL2, ...withoutGroup } = filters;
    return {
      ...withoutEmpty(withoutGroup),
      filterEventId: eventId,
      withTotals: true,
      orderBy,
      orderByAscDesc,
      limit,
      offset,
    };
  }, [eventId, filters, orderBy, limit, offset, orderByAscDesc]);

  const groupL2 = filters.groupByL2;

  const filtersL2 = useMemo(() => {
    return {
      ...withoutEmpty(inputFilters),

      filterEventId: eventId,
      withTotals: true,
      groupBy: groupL2,
    };
  }, [eventId, groupL2, inputFilters]);

  const {
    data: { data: gamingActivityData = {} } = {},
    loading,
    error,
  } = useQuery(
    isOperator ? getOperatorGamingActivityList : getGamingActivityList,
    {
      fetchPolicy: 'no-cache',
      variables: {
        inputFilters,
      },
    }
  );

  const groupFilter = groupByToFilterMap[filters.groupBy];

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TableActionCards animated actions={actions} />

      <Box mt={2}>
        <GamingActivityTable
          filters={inputFilters}
          toggledColumns={toggledColumns}
          onToggleColumns={onToggleColumns}
          data={gamingActivityData.items}
          summary={gamingActivityData.results && gamingActivityData.results[0]}
          expandable={!!filters.groupByL2}
          orderBy={orderBy}
          onChangeOrderBy={onChangeOrderBy}
          loading={loading}
          renderExpandedRow={(rowIndex, rowData) => {
            return (
              groupFilter && (
                <GamingActivityL2
                  filters={{ ...filtersL2, ...groupFilter(rowData) }}
                />
              )
            );
          }}
        />
      </Box>

      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={gamingActivityData.total}
      />
    </>
  );
};

export default GamingActivityTab;
