import React, { useState } from 'react';
import NewLoginForm from './components/NewLoginForm';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes';
import useAuth from '../../../../v2/hooks/auth';
import failureIcon from '../../assets/failure.svg';
import { useApiHost } from '../../../../providers/ApiHostProvider';
import useEnvironments from '../../../../v2/hooks/useEnvironments';
import { IS_SHOW_FEATURE_BRANCHES } from '../../../../constants/schemaUrls';
import { useDispatch } from 'react-redux';
import { login } from '../../../../actions/authActions';
import { useRoutes } from '../../../../providers/RoutesProvider';
import { ADD_SCHEMA_URL } from '../../../../constants/appConstants';
import { useSessionStorage } from '../../../../hooks/useStorage';
import isEmpty from 'lodash/isEmpty';
import { useRawSchema } from '../../../../providers/RawSchemaProvider';

const NewLoginPage = () => {
  const navigate = useNavigate();
  const { featureBranches, currentFeatureBranch, envs, currentEnv } =
    useEnvironments();
  const routes = useRoutes();
  const apiHost = useApiHost();
  const dispatch = useDispatch();
  const { reloadSchema } = useRawSchema();

  const [_, setSessionSchemaUrl] = useSessionStorage('sessionSchemaUrl', null);
  const { login: signin, loading } = useAuth();
  const [failure, setFailure] = useState(false);
  const TITLE = failure
    ? 'The login attempts limit has been exceeded'
    : 'Sign in';
  const CAPTION = failure
    ? 'The account has been blocked. To unblock the account, contact your account manager'
    : '';

  const handleChangeFeatureBranch = v => {
    const { pathname, search } = window.location;
    if (v === 'develop') {
      window.location.href = `https://backoffice-dev.sptenv.com${pathname}${search}`;
    } else {
      window.location.href = `https://backoffice-dev-${v}.dyn.sptenv.com${pathname}${search}`;
    }
  };

  const handleChangeENV = schemaUrl => {
    setSessionSchemaUrl(schemaUrl);
    dispatch({
      type: ADD_SCHEMA_URL,
      meta: {
        schemaUrl,
      },
    });
  };

  const handleSubmit = (credentials, onError) => {
    signin({
      ...credentials,
    })
      .then(response => {
        if (isEmpty(routes)) {
          reloadSchema(response.data.login.access_token);
        }

        dispatch(
          login(
            {
              payload: {
                data: {
                  token: response.data.login.access_token,
                  refresh_token: response.data.login.refresh_token,
                },
              },
            },
            {
              routes,
              apiHost,
            }
          )
        );
      })
      .catch(error => {
        onError(error.graphQLErrors);
      });
  };

  return (
    <Stack gap={'15px'} justifyContent={'center'} alignItems={'center'}>
      {failure && (
        <img src={failureIcon} style={{ marginBottom: '20px' }} alt="" />
      )}
      <Typography textAlign={'center'} fontWeight={600} fontSize={'24px'}>
        {TITLE}
      </Typography>
      <Typography
        textAlign={'center'}
        maxWidth={'320px'}
        color={'#93a3b5'}
        fontSize={'14px'}
        lineHeight={'20px'}
      >
        {CAPTION}
      </Typography>
      {!failure && (
        <NewLoginForm
          loading={loading}
          envs={envs}
          currentEnv={currentEnv}
          onChangeEnv={handleChangeENV}
          onChangeFeatureBranch={handleChangeFeatureBranch}
          branches={featureBranches}
          currentBranch={currentFeatureBranch}
          withFeatureBranches={IS_SHOW_FEATURE_BRANCHES}
          onForgotPassword={() => navigate(`../${paths.forgot}`)}
          onSubmit={handleSubmit}
          onLimitExceeded={() => setFailure(true)}
        />
      )}
    </Stack>
  );
};

export default NewLoginPage;
