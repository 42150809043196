import React, { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';

export const DeviceEnum = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  RETAIL: 'retail',
  TABLET: 'tablet',
  UNKNOWN: 'unknown',
};

const useStyles = makeStyles({
  deviceIconContainer: {
    display: 'flex',
    width: 20,
    height: 20,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const DeviceIcon = forwardRef(({ name, ...props }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      {...props}
      className={classes.deviceIconContainer}
      style={{
        backgroundImage: `url("/device-icons/${name}.svg")`,
      }}
    />
  );
});

export default DeviceIcon;
