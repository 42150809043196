import { useDispatch, useSelector } from 'react-redux';
import {
  getMethodAndUrl,
  getParameters,
  getPathByOperationId,
} from '../utils/schemaHelper';
import { getData, getError, getLoading } from '../reducers/data';
import { useCallback, useEffect, useMemo } from 'react';
import {
  debouncedGetRouteData,
  dynamicClearData,
} from '../actions/dataActions';
import isEqual from 'lodash/isEqual';
import usePrevious from './usePrevious';
import { useRoutes } from '../providers/RoutesProvider';
import { useApiHost } from '../providers/ApiHostProvider';

const isEmptyQueryParam = param => {
  return Array.isArray(param)
    ? !param.length
    : typeof param !== 'number' && !param;
};

const getEmptyQueryKeys = query => {
  return Object.keys(query).filter(key => isEmptyQueryParam(query[key]));
};

const useOperationIdData = (
  operationId,
  params,
  requestBody,
  requestQuery,
  clearOnUnmount = false
) => {
  const routes = useRoutes('data fetcher');
  const { apiHost } = useApiHost('data fetcher');

  const path = getPathByOperationId(routes, operationId);

  const queryParams = useMemo(
    () =>
      getParameters({
        params,
        requestBody,
        requestQuery,
      }),
    [params, requestBody, requestQuery]
  );

  const previousQueryParams = usePrevious(queryParams);

  const { method, url } = getMethodAndUrl({
    path,
    params,
    query: queryParams,
  });

  const emptyQueryParams = getEmptyQueryKeys(queryParams);
  const isEmptyQueryParams = emptyQueryParams.length;

  const dataUrl = method + url;
  const data = useSelector(state =>
    getData(state, routes, dataUrl, undefined, path)
  );
  const error = useSelector(state => getError(state, routes, dataUrl, path));
  const isLoading = useSelector(state =>
    getLoading(state, routes, dataUrl, path)
  );

  const dispatch = useDispatch();

  const fetchData = useCallback(
    () =>
      dispatch(
        debouncedGetRouteData(
          {
            routes,
            apiHost,
          },
          { method, url, path }
        )
      ),
    [dispatch, routes, apiHost, method, url, path]
  );

  useEffect(() => {
    if (!isEmptyQueryParams && !isEqual(previousQueryParams, queryParams)) {
      fetchData();
    }
  }, [queryParams, previousQueryParams, fetchData, isEmptyQueryParams]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        const storePath = (method === 'put' ? 'get' : method) + url;

        dispatch(
          dynamicClearData({
            meta: {
              operationId,
              path: storePath,
            },
          })
        );
      }
    };
  }, [clearOnUnmount, dispatch, method, url, operationId]);

  return { data, error, isLoading, emptyQueryParams, isEmptyQueryParams };
};

export default useOperationIdData;
