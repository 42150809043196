import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack/Stack';
import { useDropzone } from 'react-dropzone';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import Button from '../../../../components/Button/Button';
import BannerImagePreview from '../BannerImagePreview/BannerImagePreview';
import { useApiImage } from '../../../../../hooks/useApi';
import clsx from 'clsx';
import ImageCropModal from '../../../../components/ImageCropModal/ImageCropModal';
import useIsOpen from '../../../../../hooks/useIsOpen';
import ImageLibraryField from '../BannerFields/ImageLibraryField';
import { useApiHost } from '../../../../../providers/ApiHostProvider';
import FormField from '../../../../components/Form/FormField';
import Switch from '../../../../components/Inputs/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { useToasts } from '../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../components/Notifications/NotificationAlert';
import { useImagePreviewByName } from '../../providers/ImagePreviewProvider';
import useFileUpload from '../../../../hooks/useFileUpload';
import get from 'lodash/get';
import { useFormContext, useWatch } from 'react-hook-form';
import isNil from 'lodash/isNil';
import { usePrevious } from 'react-use';

const useStyles = makeStyles(theme => ({
  bannerImageUploader: {},

  imageDropzone: {
    height: 120,
    border: '1px dashed rgba(132, 150, 171, 0.3)',
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageDropzoneActive: {
    borderColor: '#1581FF',
  },

  uploadLink: {
    padding: 0,
  },

  uploadText: {
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '0.5px',
    marginTop: 'auto',
  },

  formatHint: {
    textAlign: 'center',
    color: '#8496AB',
    fontWeight: 500,
    marginBottom: 'auto',
  },

  imageSelect: {
    marginTop: 8,
  },

  bannerImageUploaderHidden: {
    '& $imageDropzone': {
      display: 'none',
    },
  },

  errorMsg: {
    color: theme.palette.error.main,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.5px',
  },

  bannerImageUploaderError: {
    '&>$imageDropzone': {
      border: `1px dashed ${theme.palette.error.main}`,
    },
  },
}));

const getCroppedImg = ({ image, pixelCrop }) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.start_x,
    pixelCrop.start_y,
    Math.ceil(pixelCrop.width),
    Math.ceil(pixelCrop.height),
    0,
    0,
    canvas.width,
    canvas.height
  );

  return canvas.toDataURL('image/png', 1);
};

export const isDefinedCrop = crop => {
  return !!(
    crop &&
    typeof crop.start_x === 'number' &&
    typeof crop.start_y === 'number' &&
    crop.width &&
    crop.height
  );
};

const getMeta = (url, cb) => {
  const img = new Image();
  img.onload = () => cb(img);
  img.onerror = err => cb(err);
  img.src = url;
};

const getImageAsArrayBuffer = async file => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        return resolve(reader.result);
      } else {
        return reject(new Error('Could not create arraybuffer'));
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

const BannerImageUploader = forwardRef(
  (
    {
      name,
      cropSize = {},
      preview,
      previewFields = [],
      previewSize,
      onChange,
      value = {},
      multipleUpload,
      onUploadedMultiple,
      onResetMultiple,
      uploadButtonLabel = 'Upload image',
      useGradient,
      previewClassName = null,
    },
    ref
  ) => {
    const {
      getValues,
      setValue,
      formState: { errors },
    } = useFormContext();

    const brand = useWatch({ name: `brand` });

    const brandId = brand && brand.id;
    const previousBrandId = usePrevious(brandId);

    const parentErrorPath = name.split('.');
    parentErrorPath.pop();

    const error = get(errors, parentErrorPath.join('.'));
    const errorMsg = error?.message;

    const classes = useStyles();

    const { previewValue, setPreviewValue } = useImagePreviewByName(name);

    const { uploadFiles } = useFileUpload({ brandId });

    const { showToast } = useToasts();

    const uploadMultipleModeRef = useRef(!!multipleUpload);

    const cacheCrop = useRef({});
    const cacheSize = useRef({});

    const getImg = useApiImage({});
    const sourceId = value && value.source_id;

    const gradientFieldName = `${name
      .split('.')
      .slice(0, -1)
      .join('.')}.use_gradient`;

    const { apiHost } = useApiHost();

    const crop = useMemo(
      () =>
        value && {
          start_x: value.start_x,
          start_y: value.start_y,
          width: value.width,
          height: value.height,
        },
      [value]
    );

    useEffect(() => {
      if (previousBrandId && brandId !== previousBrandId) {
        setValue(name, {});
        setPreviewValue('');
        onResetMultiple && onResetMultiple();
      }
    }, [
      setValue,
      onResetMultiple,
      setPreviewValue,
      name,
      previousBrandId,
      brandId,
    ]);

    const resolveImage = useCallback(
      imageId => {
        return new Promise(resolve => {
          imageId &&
            getImg({
              url: `api/v1/PromoAdmin/image_data/${imageId}`,
            }).then(res => {
              resolve(res);
            });
        });
      },
      [getImg]
    );

    useEffect(() => {
      if (!previewValue && sourceId) {
        const preview = `${apiHost}/api/v1/PromoAdmin/source_image/full/${sourceId}`;

        if (preview !== previewValue) {
          setPreviewValue(preview);
        }
      }
    }, [previewValue, setPreviewValue, sourceId, apiHost]);

    const uploadFile = useCallback(
      file => {
        return new Promise((resolve, reject) => {
          uploadFiles(file)
            .then(res => {
              return res.json();
            })
            .then(res => {
              if (res.success && res.success.length) {
                if (res?.success && res?.success[0]) {
                  resolve(res?.success[0]);
                }
              }
            })
            .catch(e => {
              reject(e);
            });
        });
      },
      [uploadFiles]
    );

    const onUploaded = useCallback(
      (result, preview) => {
        onChange({
          ...cacheCrop.current,
          source_id: result.object_id,
        });

        uploadMultipleModeRef.current &&
          onUploadedMultiple(result.object_id, preview, cacheSize.current);
      },
      [onChange, onUploadedMultiple]
    );

    const onChangeCrop = useCallback(
      (crop, originalSize) => {
        cacheSize.current = originalSize;
        cacheCrop.current = crop;
        onChange({
          ...value,
          ...crop,
        });
      },
      [onChange, value]
    );

    const onFileChosen = useCallback(
      file => {
        const preview = URL.createObjectURL(file);

        setValue(name, {});

        // TODO: try to fix by yup validation, currently hotfixed
        if (isNil(getValues(gradientFieldName))) {
          setValue(gradientFieldName, false); // otherwise value will be null
        }

        setPreviewValue(preview);

        uploadFile(file)
          .then(res => onUploaded(res, preview))
          .catch(e => {
            showToast(
              <NotificationAlert variant={AlertVariant.ERROR}>
                An error occurred while uploading image, try another one.
              </NotificationAlert>
            );
            setPreviewValue('');
          });
      },
      // eslint-disable-next-line
      [
        onUploaded,
        name,
        getValues,
        setValue,
        setPreviewValue,
        uploadFile,
        cropSize,
        value,
        onChange,
        gradientFieldName,
      ]
    );

    const onDrop = useCallback(
      files => {
        onFileChosen(files[0]);
      },
      [onFileChosen]
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      accept: 'image/*',
      multiple: false,
      disabled: !brandId,
    });

    const openMultiple = useCallback(
      (...args) => {
        uploadMultipleModeRef.current = true;

        open(...args);
      },
      [open]
    );

    const openSingle = useCallback(
      (...args) => {
        uploadMultipleModeRef.current = false;

        open(...args);
      },
      [open]
    );

    const onSelectLibraryImage = useCallback(
      value => {
        if (value) {
          cacheCrop.current = {};

          const preview = `${apiHost}/api/v1/PromoAdmin/source_image/full/${value.id}`;

          setValue(name, {});

          // TODO: try to fix by yup validation, currently hotfixed
          if (isNil(getValues(gradientFieldName))) {
            setValue(gradientFieldName, false); // otherwise value will be null
          }

          setPreviewValue(preview);
          onChange({
            source_id: value.id,
          });

          if (onUploadedMultiple) {
            getMeta(preview, img => {
              onUploadedMultiple(value.id, preview, {
                width: img.width,
                height: img.height,
              });
            });
          }
        }
      },
      [
        onChange,
        name,
        getValues,
        setValue,
        setPreviewValue,
        onUploadedMultiple,
        apiHost,
        gradientFieldName,
      ]
    );

    const {
      isOpen: isOpenCrop,
      close: onCloseCrop,
      open: openCrop,
    } = useIsOpen();

    const adjustCrop = useCallback(() => {
      openCrop();
    }, [openCrop]);

    return (
      <>
        <ImageCropModal
          width={cropSize.width}
          height={cropSize.height}
          open={isOpenCrop}
          onClose={onCloseCrop}
          onChange={onChangeCrop}
          src={previewValue}
          onChangeImage={onSelectLibraryImage}
        />
        {previewValue && (
          <Stack
            direction={'column'}
            spacing={3}
            alignItems={'center'}
            sx={{
              overflow: 'hidden',
            }}
          >
            <BannerImagePreview
              fields={previewFields}
              Component={preview}
              src={previewValue}
              onLoad={(img, initialCrop) => {
                const originalSize = {
                  width: img.naturalWidth,
                  height: img.naturalHeight,
                };
                onChangeCrop(initialCrop, originalSize);
              }}
              crop={crop}
              previewSize={previewSize || cropSize}
              cropSize={cropSize}
              className={previewClassName}
            />

            <Stack direction={'row'} spacing={1}>
              {multipleUpload && (
                <Button color={'lightBlue'} onClick={openMultiple}>
                  Upload image
                </Button>
              )}

              <Button color={'lightBlue'} onClick={openSingle}>
                {uploadButtonLabel}
              </Button>
              <Button variant={'text'} color={'primary'} onClick={adjustCrop}>
                Adjust crop
              </Button>

              {useGradient && (
                <FormControlLabel
                  control={
                    <FormField name={gradientFieldName}>
                      <Switch />
                    </FormField>
                  }
                  label={'Use gradient'}
                />
              )}
            </Stack>
          </Stack>
        )}

        <Stack
          className={clsx(classes.bannerImageUploader, {
            [classes.bannerImageUploaderHidden]: previewValue,
            [classes.bannerImageUploaderError]: error,
          })}
          direction={'column'}
          spacing={2}
        >
          <span className={classes.errorMsg}>{errorMsg}</span>
          <div
            data-cy={'image-dropzone'}
            ref={ref}
            className={clsx(classes.imageDropzone, {
              [classes.imageDropzoneActive]: isDragActive,
            })}
            {...getRootProps()}
          >
            <div className={classes.uploadText}>
              <Button
                variant={'text'}
                className={classes.uploadLink}
                disableRipple
              >
                Upload image
              </Button>{' '}
              or Drag and Drop here
            </div>
            <div className={classes.formatHint}>
              Image Size: {cropSize.width} x {cropSize.height} px (bigger images
              will be cropped). <br />
              Supported formats: JPG, PNG. Maximum size: 2 Mb
            </div>
          </div>

          <ImageLibraryField onChange={onSelectLibraryImage} />
        </Stack>
      </>
    );
  }
);

export default BannerImageUploader;
