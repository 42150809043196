import ArrowDownWardIcon from './ArrowDownwardIcon';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
  tableSortIcon: {
    fontSize: '9px',
    color: theme.palette.grey.main,
  },
}));

const TableSortIcon = props => {
  const classes = useStyles();

  return (
    <ArrowDownWardIcon
      {...props}
      className={classNames(classes.tableSortIcon, props.className)}
    />
  );
};

export default TableSortIcon;
