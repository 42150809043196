import { capitalize } from 'lodash';
import DateField from '../../../../components/Fields/DateField/DateField';
import EmptyField from '../../../../components/Fields/EmptyField/EmptyField';
import SportField from '../../../../components/Fields/SportField/SportField';
import StringField from '../../../../components/Fields/StringField/StringField';
import Table from '../../../../components/Table/Table';
import useTableColumnsReorder from '../../../../hooks/useTableColumnsReorder';

export const COLUMN = {
  sport: 'sport',
  name: 'name',
  status: 'status',
  user: 'user',
  lastUpdated: 'last_updated',
};

export const columnsWidthConfig = {
  [COLUMN.sport]: 0.75,
  [COLUMN.name]: 1.25,
  [COLUMN.status]: 1,
  [COLUMN.user]: 1,
  [COLUMN.lastUpdated]: 1,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.sport,
  COLUMN.name,
  COLUMN.status,
  COLUMN.user,
  COLUMN.lastUpdated,
];

export const initialToggledColumns = [
  COLUMN.sport,
  COLUMN.name,
  COLUMN.status,
  COLUMN.user,
  COLUMN.lastUpdated,
];

const CoverageTable = ({ data, loading, error }) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Name',
      key: COLUMN.name,
      render: ({ name: { id, name, type } }) => {
        const nameString = `${name} (${capitalize(type)})`;

        return id ? (
          <StringField title={nameString}>
            {/* <LinkField href={``}>{name}</LinkField>
             */}
            {nameString}
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Sport',
      key: COLUMN.sport,
      render: ({ sport }) => {
        return <SportField name={sport.name} id={sport.id} />;
      },
    },
    {
      label: 'Status',
      key: COLUMN.status,
      render: ({ status }) => {
        return status ? <StringField>{status}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'User',
      key: COLUMN.user,
      render: ({ user }) => {
        return user ? (
          <StringField title={user}>
            {/* <LinkField href={``}>{name}</LinkField>
             */}
            {user}
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Last Updated',
      key: COLUMN.lastUpdated,
      render: ({ lastUpdated }) => {
        return lastUpdated ? (
          <StringField>
            <DateField date={lastUpdated} format={'YYYY.MM.DD, HH:mm'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  return (
    <Table
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      configMenu={false}
      error={error}
    />
  );
};

export default CoverageTable;
