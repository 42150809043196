import { gql } from '@apollo/client';

const changeEventAvailability = gql`
  mutation changeEventAvailability(
    $eventId: EventId!
    $availability: Boolean!
  ) {
    changeEventAvailability(eventId: $eventId, availability: $availability)
  }
`;

export default changeEventAvailability;
