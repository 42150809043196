import React, { useCallback, useContext, useState } from 'react';
import { RoutesContext } from '../../providers/RoutesProvider';
import get from 'lodash/get';
import { DataContext } from '../../providers/DataProvider';
import makeStyles from '@mui/styles/makeStyles';
import ExpandIcon from '../../icons/controls/ExpandIcon';
import { alpha } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import TablePaginationActions from './TablePaginationActions';
import classNames from 'clsx';
import { LocalQueryContext } from '../../providers/LocalQueryProvider';
import useQueryByPath, { HistoryMethodEnum } from '../../hooks/useQueryByPath';
import MuiTablePagination from './MuiTablePagination';
import { usePresets } from '../../providers/PresetsProvider';
import useLocalStorageTableConfig, {
  tableConfigKeys,
} from '../../v2/hooks/useLocalStorageTableConfig';
import { getOperationIdByPath } from '../../utils/schemaHelper';

const useStyles = makeStyles(theme => ({
  tableContainer: {},

  pageNumberField: {
    width: 40,
    marginLeft: 8,
    marginRight: 24,
  },
  pageNumberFieldInput: {
    padding: '12px 2px',
    height: 'initial',
    textAlign: 'center',
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
  },
  displayedRowsLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  paginationGoTo: {
    display: 'flex',
    alignItems: 'center',
  },
  paginationGoToSkeleton: {
    margin: '0 4px',
    display: 'inline-block',
  },
  paginationActions: {
    marginLeft: 8,
  },
  paginationTotal: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.2px',
  },
  paginationLabel: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.2px',
  },
  numberLabel: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  singleLimitSelect: {
    marginRight: 0,
  },

  perPageLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  totalRowsLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    '&>span': {
      // fontFamily: `'Iosevka SS11', monospace`,
      // fontFeatureSettings: `'ss04' on`,
    },
  },
  paginationCaption: {
    '&:nth-child(4)': {
      marginLeft: 'auto',
    },
  },

  [theme.breakpoints.down('xs')]: {
    perPageLabel: {
      maxWidth: 93,
    },
  },
  [theme.breakpoints.down('sm')]: {
    paginationToolbar: {
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      height: 108,
    },
    paginationCaption: {
      // flexBasis: '50%',
      '&:last-of-type': {
        flex: 'initial',
        justifyContent: 'flex-start',
      },
    },
    limitSelectRoot: {
      marginRight: 0,
    },
  },
}));

const useActionButtonStyles = makeStyles(theme => ({
  root: {
    marginLeft: 8,
    padding: 14,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: '14px',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },

    '&$disabled': {
      backgroundColor: alpha(theme.palette.grey.main, 0.1),
      color: theme.palette.grey.main,
    },
  },
  disabled: {},
}));

const TablePagination = () => {
  const { path, data, loading } = useContext(DataContext);
  const routes = useContext(RoutesContext);
  const classes = useStyles();
  const actionButtonClasses = useActionButtonStyles();
  const [goToPageValue, setGoToPageValue] = useState(1);
  let { query, setQuery } = useQueryByPath(path);
  const { localQuery, setLocalQuery } = useContext(LocalQueryContext);
  const { activePreset, updatePreset } = usePresets();
  const operationId = getOperationIdByPath(routes, path);
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(operationId);

  const tableConfigLimit = getTableConfigValue(tableConfigKeys.limit);

  const {
    offset = 0,
    limit = activePreset ? activePreset.data.rows_per_page : tableConfigLimit,
  } = localQuery;

  const currentPage = Math.floor(offset / limit);

  const parameters = get(routes, `${path}.parameters`, []);

  const totalItems = data && Number.isInteger(data.total) ? data.total : null;

  const totalPages = totalItems && Math.ceil(totalItems / limit);

  const hasLimitPagination = parameters.find(
    f => f.in === 'query' && f.name === 'limit'
  );

  const hasPagination = !!(
    hasLimitPagination &&
    parameters.find(f => f.in === 'query' && f.name === 'offset')
  );

  const setLimit = useCallback(
    limit => {
      updatePreset(activePreset.id, {
        ...activePreset,
        data: {
          ...activePreset.data,
          rows_per_page: limit,
        },
      });
      setLocalQuery({
        ...localQuery,
        limit,
        offset: 0,
      });
      setQuery({
        ...query,
        limit,
        offset: 0,
      });
      setTableConfigValue(tableConfigKeys.limit, limit);
    },
    [
      updatePreset,
      query,
      setQuery,
      activePreset,
      setLocalQuery,
      localQuery,
      setTableConfigValue,
    ]
  );

  // useEffect(() => {
  //   if (query.limit !== limit) {
  //     setQuery({
  //       ...query,
  //       limit,
  //     });
  //   }
  // }, [limit, query, setQuery]);

  const setOffset = useCallback(
    page => {
      const offset = Math.round(Math.max(page * limit, 0));
      if (offset !== localQuery.offset) {
        setLocalQuery(
          {
            ...localQuery,
            offset,
          },
          HistoryMethodEnum.PUSH
        );
      }
    },
    [setLocalQuery, limit, localQuery]
  );

  const labelDisplayedRows = () => {
    if (hasLimitPagination && !hasPagination) {
      return null;
    }

    return (
      <>
        <span className={classes.displayedRowsLabel}>
          <span className={classes.paginationGoTo}>
            <span className={classes.paginationLabel}>Go to page</span>
            <TextField
              classes={{
                root: classes.pageNumberField,
              }}
              InputProps={{
                classes: {
                  input: classes.pageNumberFieldInput,
                },
                onKeyDown: e => {
                  if (e.key === 'Enter') {
                    setGoToPageValue(1);
                    const page = (parseInt(e.target.value) || 1) - 1;

                    if (page >= totalPages) {
                      return setOffset(totalPages - 1);
                    }

                    if (page < 0) {
                      return setOffset(0);
                    }

                    setOffset(page);
                  }
                },
              }}
              onChange={(e, value) => {
                setGoToPageValue(value);
              }}
              value={goToPageValue}
              variant={'outlined'}
            />
          </span>
          {typeof totalPages !== 'number' && loading ? (
            <Skeleton
              variant="rectangular"
              width={20}
              component={'span'}
              className={classes.paginationGoToSkeleton}
            />
          ) : (
            <span className={classes.paginationLabel}>
              <span className={classes.numberLabel}>{currentPage + 1}</span> of{' '}
              <span className={classes.numberLabel}>{totalPages || 0}</span>
            </span>
          )}
        </span>
      </>
    );
  };

  const [isPageSelectOpen, setIsPageSelectOpen] = useState(false);

  if (!hasLimitPagination && !hasPagination) {
    return null;
  }

  return (
    <MuiTablePagination
      ActionsComponent={
        hasLimitPagination && !hasPagination
          ? () => null
          : TablePaginationActions
      }
      backIconButtonProps={{
        classes: actionButtonClasses,
      }}
      nextIconButtonProps={{
        classes: actionButtonClasses,
      }}
      SelectProps={{
        IconComponent: isPageSelectOpen ? ExpandIcon : ExpandIcon,
        onOpen: () => setIsPageSelectOpen(true),
        onClose: () => setIsPageSelectOpen(false),
        MenuProps: {
          style: {
            marginTop: 4,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={
        <span className={classes.paginationTotal}>
          {(totalItems || loading) && (
            <span className={classes.totalRowsLabel}>
              Total rows:&nbsp;
              {!loading ? (
                <span>{totalItems}</span>
              ) : (
                <Skeleton
                  variant={'text'}
                  component={'span'}
                  width={20}
                  className={classes.paginationGoToSkeleton}
                />
              )}
            </span>
          )}
          <span className={classes.perPageLabel}>Rows per page</span>
        </span>
      }
      component="div"
      classes={{
        toolbar: classes.paginationToolbar,
        caption: classes.paginationCaption,
        actions: classes.paginationActions,
        selectRoot: classNames(classes.singleLimitSelect, {
          [classes.limitSelectRoot]: hasLimitPagination && !hasPagination,
        }),
      }}
      rowsPerPageOptions={[10, 25, 50, 100, 150]}
      count={totalItems || Infinity}
      onChangePage={(e, page) => setOffset(page)}
      page={currentPage}
      rowsPerPage={limit}
      onChangeRowsPerPage={e => {
        setLimit(e.target.value);
      }}
    />
  );
};

export default TablePagination;
