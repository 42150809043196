import makeStyles from '@mui/styles/makeStyles';
import useMeasure from 'react-use-measure';
import { animated, useSpring } from 'react-spring';
import ExpandIcon from '../../../icons/controls/ExpandIcon';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  actionCardExpansion: {},
  expansionSummary: {
    cursor: 'pointer',
    userSelect: 'none',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },
  expansionContent: {
    // overflow: 'hidden',
  },
  expandIcon: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    marginRight: 4,
  },
}));

const TableActionCardExpansion = ({
  isOpen,
  summary,
  children,
  onChange,
  onChangeHeight,
  initialHeight = 56,
}) => {
  const classes = useStyles();

  const [contentRef, { height: contentHeight }] = useMeasure();

  const styles = useSpring({
    height: isOpen ? contentHeight : 0,
    opacity: isOpen ? 1 : 0,
    immediate: true, // TODO remove
  });

  useEffect(() => {
    onChangeHeight &&
      onChangeHeight(
        isOpen ? contentHeight + initialHeight - 2 : initialHeight
      );
  }, [isOpen, onChangeHeight, contentHeight, initialHeight]);

  return (
    <div className={classes.root}>
      <div
        onClick={() => onChange(!isOpen)}
        className={classes.expansionSummary}
      >
        <ExpandIcon className={classes.expandIcon} expanded={isOpen} />

        {summary}
      </div>
      <animated.div style={styles} className={classes.expansionContent}>
        <div ref={contentRef}>{children}</div>
      </animated.div>
    </div>
  );
};

export default TableActionCardExpansion;
