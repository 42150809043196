import { useQuery } from '@apollo/client';
import { createContext, useContext } from 'react';
import getUserAccessRoles from '../gql/sportbook-config/getUserAccessRoles';

export const ACCESS_ROLE = {
  READ: 'read',
  WRITE: 'write',
  NO_RIGHTS: 'no_rights',
};

export const SportsBookUserAccessRolesContext = createContext(null);

export const useSportsBookUserAccessRoles = () => {
  const roles = useContext(SportsBookUserAccessRolesContext);

  return roles;
};

const SportsBookUserAccessRolesProvider = ({ children }) => {
  const { data = {}, loading } = useQuery(getUserAccessRoles);

  const roles =
    loading && !data.data
      ? {}
      : {
          coverage: data.data.coverageTemplate,
          sources: data.data.sourcesTemplate,
        };

  return (
    <SportsBookUserAccessRolesContext.Provider value={{ roles }}>
      {children}
    </SportsBookUserAccessRolesContext.Provider>
  );
};

export default SportsBookUserAccessRolesProvider;
