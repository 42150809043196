import useAutocompleteData from '../../../hooks/useAutocompleteData';
import Select from '../Select/Select';
import { forwardRef } from 'react';

const OperatorSelect = forwardRef(({ multiple, ...props }, ref) => {
  const { data: options = [] } = useAutocompleteData({
    url: '/api/v1/Autocomplete/operators',
    withoutParams: true,
  });

  return (
    <Select
      ref={ref}
      label={'Operator'}
      multiple={multiple}
      options={options}
      labelKey={'name'}
      valueKey={'id'}
      withTooltip
      {...props}
    />
  );
});

export default OperatorSelect;
