import { TableCell } from '@mui/material';
import React, { useState } from 'react';
import Field from '../Field/Field/Field';
import InlineTableDropdown from './InlineTableDropdown';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';
import NestedIndexesProvider from '../../providers/NestedIndexProvider';

const useStyles = makeStyles({
  inlineTableCellRoot: {
    position: 'relative',
  },
  parentColumnField: {
    zIndex: 1,
  },
});

const InlineTableDropdownRow = ({
  data,
  columnName,
  columns = [],
  cellClasses,
  cellStyles,
  parentData = [],
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  if (data.length === 1) {
    data = data[0];

    return (
      <NestedIndexesProvider
        indexes={{
          [columnName]: 0,
        }}
      >
        {columns.map(column => {
          return (
            <TableCell
              key={column.name}
              classes={cellClasses}
              align={column.align}
              style={cellStyles}
            >
              <Field
                align={column.align}
                schema={column.schema}
                uiSchema={column.uiSchema}
                fieldValue={parentData[column.name] || data[column.name]}
                fieldKey={column.name}
              />
            </TableCell>
          );
        })}
      </NestedIndexesProvider>
    );
  }

  const parentColumns = columns
    .filter(column => parentData[column.name])
    .map(column => {
      return {
        ...column,
        value: parentData[column.name],
      };
    });

  return (
    <>
      <TableCell
        style={cellStyles}
        colSpan={columns.length - parentColumns.length}
        classes={{
          root: classNames(classes.inlineTableCellRoot, cellClasses.root),
        }}
      >
        <InlineTableDropdown
          columnName={columnName}
          columns={columns}
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </TableCell>
      {parentColumns.map(column => {
        return (
          <TableCell
            key={column.name}
            classes={cellClasses}
            align={column.align}
            style={cellStyles}
          >
            <Field
              classes={{
                container: isOpen && classes.parentColumnField,
              }}
              align={column.align}
              schema={column.schema}
              uiSchema={column.uiSchema}
              fieldValue={column.value}
              fieldKey={column.name}
            />
          </TableCell>
        );
      })}
    </>
  );
};

export default InlineTableDropdownRow;
