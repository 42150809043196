import { withoutEmpty } from './usePresetFilters';
import { useMemo } from 'react';

const useTableQueryVariables = ({ filters, limit, offset }) => {
  return useMemo(() => {
    return {
      ...withoutEmpty(filters),
      limit,
      offset,
    };
  }, [limit, offset, filters]);
};

export default useTableQueryVariables;
