import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const EVENT_QUERY = gql`
  ${eventModelFragment}
  query getEvent($id: EventId!) {
    data: getEvent(id: $id) {
      ...EventModelFragment
    }
  }
`;

export default EVENT_QUERY;
