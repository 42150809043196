import { styled } from '@mui/system';

const CardContainer = styled('div')`
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 4px;
`;

const Card = ({ children, ...props }) => {
  return <CardContainer {...props}>{children}</CardContainer>;
};

export default Card;
