import IconButton from '@mui/material/IconButton';
import CloseIcon from '../icons/controls/CloseIcon';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  drawerHeader: {
    minHeight: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 14px',
  },
  closeDrawerButtonRoot: {
    padding: 0,
  },
  closeIcon: {
    fontSize: '28px',
  },
});

const DrawerHeader = ({ toggleDrawer }) => {
  const classes = useStyles();

  return (
    <div className={classes.drawerHeader}>
      <IconButton
        color="primary"
        classes={{
          root: classes.closeDrawerButtonRoot,
        }}
        onClick={toggleDrawer}
        size="large"
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </div>
  );
};

export default DrawerHeader;
