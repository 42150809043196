import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import useColor from '../../hooks/usePaletteColor';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles({
  booleanGroupInput: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 6,
    padding: 2,
    height: 24,
    boxSizing: 'border-box',
  },

  groupItem: {
    borderRadius: 4,
    color: '#031224',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px 0 2px',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.2px',
    textTransform: 'capitalize',
    fontWeight: 600,
    height: '100%',
    cursor: 'pointer',
    margin: 0,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontFamily: 'Inter',
    fontFeatureSettings: 'tnum',
    fontVariantNumeric: 'tabular-nums',
    transition: 'background-color ease-in 0.2s, opacity ease-in 0.2s',

    '&:disabled:not($optionDisabled)': {
      cursor: 'wait',
      opacity: 0.5,
    },
  },

  optionDisabled: {
    cursor: 'initial',
  },

  groupItemActive: {
    boxShadow:
      '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
    backgroundColor: '#fff',
    cursor: 'initial',
    transition: 'background-color ease-in 0.2s',
  },

  labelColor: {
    width: 3,
    height: 12,
    borderRadius: 1.5,
    backgroundColor: 'transparent',
    marginRight: 7,
  },
});

const GroupItemLabel = ({ className, color = 'transparent' }) => {
  const paletteColor = useColor(color);

  return (
    <div
      className={className}
      style={{
        backgroundColor: paletteColor,
      }}
    />
  );
};

const BooleanGroupInput = ({
  disabled,
  value = null,
  onChange,
  options = [
    {
      label: 'Active',
      activeColor: 'green',
      value: true,
      tooltip: null,
    },
    {
      label: 'Default',
      activeColor: 'green',
      value: null,
      tooltip: null,
    },
    {
      label: 'Inactive',
      activeColor: 'red',
      value: false,
      tooltip: null,
    },
  ],
}) => {
  const classes = useStyles();

  return (
    <div className={classes.booleanGroupInput}>
      {options.map(option => {
        const isActive =
          option.value === null
            ? option.value === value || typeof value === 'undefined'
            : option.value === value;
        const optionDisabled = value === option.value;

        const renderOption = (
          <button
            key={option.label}
            disabled={disabled}
            onClick={() => {
              !optionDisabled && onChange(option.value);
            }}
            className={clsx(classes.groupItem, {
              [classes.groupItemActive]: isActive,
              [classes.optionDisabled]: optionDisabled,
            })}
          >
            <GroupItemLabel
              className={classes.labelColor}
              color={isActive ? option.activeColor : undefined}
            />
            {option.label}
          </button>
        );

        if (option.tooltip) {
          const tooltipText =
            typeof option.tooltip === 'string'
              ? option.tooltip
              : option.tooltip.text;

          return (
            <Tooltip
              key={option.label}
              title={tooltipText}
              disableInteractive={true}
            >
              {renderOption}
            </Tooltip>
          );
        }

        return renderOption;
      })}
    </div>
  );
};

export default BooleanGroupInput;
