import Select from '../Select/Select';
import { forwardRef } from 'react';

const BonusTypeSelect = forwardRef(({ ...props }, ref) => {
  return (
    <Select
      ref={ref}
      options={[
        { label: 'Real', value: 'real' },
        { label: 'Freebet', value: 'freebet' },
        { label: 'Comboboost', value: 'comboboost' },
      ]}
      {...props}
    />
  );
});

export default BonusTypeSelect;
