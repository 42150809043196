import { Box } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import CopyField from '../../../../../Fields/CopyField/CopyField';
import DateField from '../../../../../Fields/DateField/DateField';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import Tooltip from '../../../../../Tooltip/Tooltip';
import Checkbox from '../../../../Checkbox';
import useCoverageStyles from '../../../styles/Coverage.style';
import { changePath } from '../../../utils/changePath';
import { clearPath, getKeySelector } from '../../../utils/getKeySelector';
import { isBoolean } from 'lodash';

const CoverageEventOption = ({
  templateId,
  nodePath = [],
  setPath = () => {},
  nodeIndex,
  selectKey,
  selected,
  itemValue,
  itemIndex,
  options,
  setDisplayOptions,
  configurationDisabled,
  lineType,
}) => {
  const classes = useCoverageStyles();
  const { showToast } = useToasts();

  const [searchParams] = useSearchParams();
  const duplicate = !!searchParams.get('duplicate');

  const { getValues, setValue } = useFormContext();
  const {
    setNodePath,
    setData,
    setTreeSelectLoading,
    getCoverageTemplateQuery,
    setSelectedCoverageItemsConfig,
    selectedCoverageItemsConfig: { [lineType]: selectedCoverageItemsConfig },
  } = useTreeSelectContext();
  const sportStatus = selectedCoverageItemsConfig.sport.status;
  const tournamentStatus = selectedCoverageItemsConfig.tournament.status;
  const tournamentCategoryId =
    selectedCoverageItemsConfig.tournament.categoryId || nodePath.categoryId;
  const categoryStatus = tournamentCategoryId
    ? selectedCoverageItemsConfig.category[tournamentCategoryId]
    : null;

  const eventInheritStatus = useMemo(() => {
    if (sportStatus === false) {
      return sportStatus;
    }
    if (categoryStatus === false) {
      return categoryStatus;
    }
    if (tournamentStatus === false) {
      return tournamentStatus;
    }
    return null;
  }, [sportStatus, categoryStatus, tournamentStatus]);

  const nodeSelector = withoutEmpty({
    sportId: nodePath.sportId,
    tournamentId: nodePath.tournamentId,
    eventId: itemValue.eventId,
  });
  const keySelector = getKeySelector({ nodeSelector, lineType });

  const template = getValues(`template`);
  const currentConfig = template[keySelector];
  const itemStatus = currentConfig ? currentConfig.status : itemValue.status;

  const [checked, setChecked] = useState(itemStatus);

  //on option click we need to highlight this option, after this request new lists of options
  //for get new option list we need to pass ids of previous selected items
  const onSelect = useCallback(() => {
    const prevNodePath = nodePath;
    const path = changePath({
      nodeId: itemValue.eventId,
      nodeKey: 'eventId',
      nodeIndex,
      nodePath,
      setNodePath,
      lineType,
    });
    getCoverageTemplateQuery(
      {
        templateId,
        path: clearPath(path),
        lineType,
      },
      duplicate
    )
      .then(data => {
        const newData = withoutEmpty(data.data.data);
        setTreeSelectLoading(false);

        setData(currentData => {
          return {
            ...currentData,
            [lineType]: {
              ...currentData[lineType],
              marketsToNode: newData.marketsToNode,
              markets: newData.markets,
            },
          };
        });
      })
      .catch(() => {
        setTreeSelectLoading(false);
        setNodePath(currentPath => ({
          ...currentPath,
          [lineType]: prevNodePath,
        }));
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can not fetch data
          </NotificationAlert>
        );
      });
  }, [
    itemValue,
    nodeIndex,
    nodePath,
    lineType,
    setNodePath,
    getCoverageTemplateQuery,
    setData,
    showToast,
    templateId,
    setTreeSelectLoading,
    duplicate,
  ]);

  const refreshSelectedItemStatus = status => {
    setSelectedCoverageItemsConfig(curr => ({
      ...curr,
      [lineType]: {
        ...curr[lineType],
        event: {
          ...curr[lineType].event,
          status,
        },
      },
    }));
  };

  const onChangeStatus = value => {
    const newStatus = value;

    setChecked(value);
    setDisplayOptions(currentOptions => {
      currentOptions.forEach((value, index) => {
        value.eventId == itemValue.eventId &&
          (currentOptions[index].status = newStatus);
      });
      return currentOptions;
    });
    setValue(`template.${keySelector}`, {
      lineType,
      nodeSelector,
      status: newStatus,
    });
    if (selected) {
      refreshSelectedItemStatus(value);
    }
  };

  // we need not to save the config if the item status id disabled by parent
  useEffect(() => {
    const template = getValues('template');
    const currentConfig = template[keySelector];

    if (currentConfig && isBoolean(eventInheritStatus)) {
      currentConfig.ignoreStatus = !eventInheritStatus;
      setValue(`template.${keySelector}`, currentConfig);
    }
  }, [eventInheritStatus, getValues, setValue, keySelector]);

  return (
    <Box className={classes.coverageEventContainer}>
      <Box className={classes.coverageEventSection}>
        <Checkbox
          // need to strict compare because it is possible to be null
          value={eventInheritStatus === false ? false : checked}
          onChange={onChangeStatus}
          disabled={
            configurationDisabled ||
            itemValue.readOnly ||
            eventInheritStatus === false
          }
        />
        <Box onClick={onSelect}>
          {itemValue.scheduled && (
            <DateField date={itemValue.scheduled} format={'YYYY.MM.DD HH:mm'} />
          )}
        </Box>
      </Box>
      <Box className={classes.coverageEventSection}>
        <CopyField value={itemValue.eventId} style={{ width: 'auto' }} />
        <Tooltip overflowOnly={true} title={itemValue.name}>
          <Box className={classes.coverageOptionCaption} onClick={onSelect}>
            {itemValue.name}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(CoverageEventOption);
