import { useTableApplyButton } from '../../hooks/useTableApplyButton';
import Button from '../../v2/components/Button/Button';
import TableInlinePopup from '../../v2/components/Table/TableInlinePopup';

const TableApplyButton = () => {
  const { isApplyVisible, onApplyClick } = useTableApplyButton();

  return isApplyVisible ? (
    <TableInlinePopup>
      <Button onClick={onApplyClick} disableRipple>
        Apply
      </Button>
    </TableInlinePopup>
  ) : (
    <></>
  );
};

export default TableApplyButton;
