import React from 'react';
import FieldLabel from '../FieldLabel';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'clsx';

const useStyles = makeStyles({
  withoutLabel: {
    // marginTop: 16,
  },
});

const FieldTemplate = ({ classNames, children, uiSchema = {} }) => {
  const fieldLabel = uiSchema['ui:title'];
  const hidden = uiSchema['ui:widget'] === 'hidden';
  const classes = useStyles();

  return (
    <div
      className={classnames(classNames, {
        [classes.withoutLabel]: !fieldLabel,
      })}
    >
      {fieldLabel && !hidden && <FieldLabel>{fieldLabel}</FieldLabel>}
      {children}
    </div>
  );
};

export default FieldTemplate;
