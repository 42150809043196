import { gql } from '@apollo/client';

export const CREATE_TEMPLATE_RESULT_TYPE = gql`
  fragment CreateTemplateResultType on CreateTemplateResult {
    templateId
    status
    error
  }
`;

const createSourcesTemplate = gql`
  ${CREATE_TEMPLATE_RESULT_TYPE}

  mutation createSourcesTemplate(
    $parentId: TemplateId
    $input: SourcesTemplateInput!
  ) {
    data: createSourcesTemplate(parentId: $parentId, input: $input) {
      ...CreateTemplateResultType
    }
  }
`;

export default createSourcesTemplate;
