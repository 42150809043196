import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 2,
    zIndex: 1,
  },
};

const LoadingIndicator = ({ classes }) => (
  <LinearProgress className={classes.root} />
);

export default withStyles(styles)(LoadingIndicator);
