import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  skeletonGroup: {
    display: 'flex',
    '&+$skeletonGroup': {
      marginTop: 16,
    },
  },

  skeletonItem: {
    borderRadius: 2,
    maxWidth: 'initial',
    '&+$skeletonItem': {
      marginLeft: 8,
    },
    height: 16,
  },
});

const TransferListSkeletonGroup = React.memo(({ width = 160 }) => {
  const classes = useStyles();
  const randomWidth = Math.random() * width + width / 2;

  return (
    <div className={classes.skeletonGroup}>
      <Skeleton
        className={classes.skeletonItem}
        animation="wave"
        variant="circular"
        style={{ width: 16 }}
      >
        &zwnj;
      </Skeleton>
      <Skeleton
        className={classes.skeletonItem}
        animation="wave"
        variant="rectangular"
        style={{ width: randomWidth }}
      >
        &zwnj;
      </Skeleton>
    </div>
  );
});

export default TransferListSkeletonGroup;
