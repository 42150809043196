import SvgIcon from '@mui/material/SvgIcon';
import React, { memo } from 'react';

const CopyIcon = memo(props => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} {...props}>
      <rect
        width="7"
        height="9"
        rx="1"
        transform="matrix(-1 0 0 1 17 6)"
        stroke="currentColor"
        fill={'transparent'}
      />
      <path
        d="M10 9H8C7.44772 9 7 9.44772 7 10V17C7 17.5523 7.44772 18 8 18H13C13.5523 18 14 17.5523 14 17V15"
        stroke="currentColor"
        fill={'transparent'}
      />
    </SvgIcon>
  );
});

export default CopyIcon;
