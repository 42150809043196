import { cloneElement, forwardRef } from 'react';
import { BannerViewMap } from '../BannerForm/BannerValidationSchema';

const BannerViewSelect = forwardRef(
  ({ value, onChange, options = [] }, ref) => {
    return options.map(view => {
      const onClick = () => {
        onChange(view);
      };

      return cloneElement(BannerViewMap[view], {
        key: view,
        view,
        ref,
        onClick,
        active: value === view,
      });
    });
  }
);

export default BannerViewSelect;
