import { gql } from '@apollo/client';

const MarketLiabilityFields = gql`
  fragment MarketLiabilityFields on MarketLiabilityTableItem {
    event
    eventId
    sport
    tournament
    category
    event
    market
    outcome
    sportId
    tournamentId
    categoryId
    eventId
    marketId
    outcomeId
    specifiers
    bets
    players
    turnoverExtended
    turnoverSingle
    turnoverCombo
    liabilitySingle
    liabilityCombo
  }
`;

const getMarketLiabilityList = gql`
  ${MarketLiabilityFields}
  query getMarketLiabilityList($inputFilters: MarketLiabilityTableFilters) {
    data: getMarketLiabilityList(inputFilters: $inputFilters) {
      items {
        ...MarketLiabilityFields
      }
      total
    }
  }
`;

export default getMarketLiabilityList;
