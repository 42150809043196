import { usePresets } from '../providers/PresetsProvider';
import { useCallback, useMemo } from 'react';
import useLocalStorageTableConfig, {
  tableConfigKeys,
  tableNames,
} from './useLocalStorageTableConfig';

const useTablePresetPaginationLimit = (tableName = tableNames.GenericTable) => {
  const { activePreset, updatePreset } = usePresets();
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(tableName);

  const tableConfigLimit = getTableConfigValue(tableConfigKeys.limit);

  const limit =
    (activePreset && activePreset.data.rows_per_page) || tableConfigLimit;

  const setLimit = useCallback(
    limit => {
      activePreset &&
        updatePreset(activePreset.id, {
          ...activePreset,
          data: {
            ...activePreset.data,
            rows_per_page: limit,
          },
        });
      setTableConfigValue(tableConfigKeys.limit, limit);
    },
    [updatePreset, activePreset, setTableConfigValue]
  );

  return useMemo(() => [limit, setLimit], [limit, setLimit]);
};

export default useTablePresetPaginationLimit;
