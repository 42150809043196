import React from 'react';
import classNames from 'clsx';
import { emphasize } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';

const styles = theme => ({
  tooltip: {
    maxWidth: 'none',
    textAlign: 'center',
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    height: 'auto',
    maxWidth: '100%',
  },
  label: {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)}px`,
    whiteSpace: 'normal',
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
});

const MultiValue = ({
  children,
  isFocused,
  removeProps,
  data,
  selectProps: { secondaryKey },
  classes,
}) => (
  <Tooltip
    title={
      <div>
        <div>{children}</div>
        <div>{data[secondaryKey]}</div>
      </div>
    }
    classes={{ tooltip: classes.tooltip }}
    interactive
  >
    <Chip
      tabIndex={-1}
      color="primary"
      label={children}
      className={classNames(classes.chip, {
        [classes.chipFocused]: isFocused,
      })}
      classes={{
        label: classes.label,
      }}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  </Tooltip>
);

export default withStyles(styles)(MultiValue);
