import Page from '../../components/Page/Page';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import BannerForm from './components/BannerForm/BannerForm';
import { useParams } from 'react-router-dom';
import useBannerData from './hooks/useBannerData';
import LinkField from '../../components/Fields/LinkField/LinkField';
import React, { useEffect, useState } from 'react';
import ErrorWidget from '../../components/ErrorWidget';

const PromoBannerDuplicatePage = () => {
  const { bannerId } = useParams();
  const [parentName, setParentName] = useState('');

  const { data, error } = useBannerData(bannerId);

  useEffect(() => {
    data().then(values => {
      setParentName(values.name);
    });
  }, [data]);

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Duplicate Banner'}>
      {parentName && (
        <BreadCrumbs
          crumbs={[
            {
              label: 'Banners',
              to: '/banners',
            },
            {
              label: (
                <>
                  Duplicate Banner «
                  <LinkField href={`/banners/${bannerId}`} target={'_blank'}>
                    {parentName}
                  </LinkField>
                  »
                </>
              ),
            },
          ]}
        />
      )}

      <BannerForm defaultValues={data} checkDirty={false} isDuplicate />
    </Page>
  );
};

export default PromoBannerDuplicatePage;
