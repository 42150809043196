import { gql } from '@apollo/client';

const getPlaceholders = gql`
  query getPlaceholders($input: PlaceholdersQuery!) {
    data: getPlaceholders(input: $input) {
      items
    }
  }
`;

export default getPlaceholders;
