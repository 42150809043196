import DateField from '../../../../components/Fields/DateField/DateField';
import EmptyField from '../../../../components/Fields/EmptyField/EmptyField';
import SportField from '../../../../components/Fields/SportField/SportField';
import StringField from '../../../../components/Fields/StringField/StringField';
import Table from '../../../../components/Table/Table';
import useTableColumnsReorder from '../../../../hooks/useTableColumnsReorder';

export const COLUMN = {
  sport: 'sport',
  tournament: 'tournament',
  market: 'market',
  prematch: 'prematch',
  live: 'live',
  user: 'user',
  lastUpdated: 'last_updated',
};

export const columnsWidthConfig = {
  [COLUMN.sport]: 0.75,
  [COLUMN.tournament]: 1.25,
  [COLUMN.market]: 0.75,
  [COLUMN.prematch]: 1,
  [COLUMN.live]: 1,
  [COLUMN.user]: 1,
  [COLUMN.lastUpdated]: 1,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.sport,
  COLUMN.tournament,
  COLUMN.market,
  COLUMN.prematch,
  COLUMN.live,
  COLUMN.user,
  COLUMN.lastUpdated,
];

export const initialToggledColumns = [
  COLUMN.sport,
  COLUMN.tournament,
  COLUMN.market,
  COLUMN.prematch,
  COLUMN.live,
  COLUMN.user,
  COLUMN.lastUpdated,
];

const MarginTable = ({ data, loading }) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Sport',
      key: 'sport',
      render: ({ sport }) => {
        return <SportField name={sport.name} id={sport.id} />;
      },
    },
    {
      label: 'Tournament',
      key: COLUMN.tournament,
      render: ({ tournament }) => {
        return tournament ? (
          <StringField title={tournament.name}>{tournament.name}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Market',
      key: COLUMN.market,
      render: ({ market }) => {
        return market ? (
          <StringField title={market.name}>{market.name}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'PM',
      key: COLUMN.prematch,
      render: ({ prematch }) => {
        return (
          prematch && <StringField title={prematch}>{prematch}</StringField>
        );
      },
    },
    {
      label: 'Live',
      key: COLUMN.live,
      render: ({ live }) => {
        return live && <StringField title={live}>{live}</StringField>;
      },
    },
    {
      label: 'User',
      key: COLUMN.user,
      render: ({ user }) => {
        return user ? (
          <StringField title={user}>{user}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Last Update',
      key: COLUMN.lastUpdated,
      render: ({ lastUpdated }) => {
        return lastUpdated ? (
          <StringField>
            <DateField date={lastUpdated} format={'YYYY.MM.DD, HH:mm'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  return (
    <Table
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      configMenu={false}
    />
  );
};

export default MarginTable;
