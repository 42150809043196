import React, { useState } from 'react';
import ExpandIcon from '../../../../icons/controls/ExpandIcon';
import TransferListItem from './TransferListItem';
import makeStyles from '@mui/styles/makeStyles';
import { animated, useSpring } from 'react-spring';
import Collapse from '@mui/material/Collapse';
import CheckBoxIcon from '../../../../icons/controls/CheckBoxIcon';
import CheckBoxActiveIcon from '../../../../icons/controls/CheckBoxActiveIcon';
import CheckboxIndeterminateIcon from '../../../../icons/controls/CheckboxIndeterminateIcon';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles({
  listGroup: {
    '&+$listGroup': {
      marginTop: 8,
    },
  },

  itemRoot: {
    '&:first-child': {
      marginTop: 16,
    },
  },

  listGroupTitle: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 9,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    fontWeight: 500,

    '&>span': {
      fontSize: '14px',
    },
  },

  expandIcon: {
    color: '#1581FF',
    marginRight: 5,
  },

  listGroupContent: {
    overflow: 'hidden',
  },

  groupControls: {
    marginLeft: 'auto',
    marginRight: '8px',
    overflow: 'visible',
    display: 'flex',
    height: 16,
  },
});

const TransferListGroup = React.memo(
  ({
    searchQuery,
    group,
    groupItems = [],
    groupItemsFiltered = [],
    valueKey,
    labelKey,
    descKey,
    setToggled,
    setGroupToggled,
  }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const collapseSpring = useSpring({
      transform: isOpen ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
      from: {
        transform: 'rotateZ(0deg)',
      },
    });

    const AnimatedExpandIcon = animated(ExpandIcon);

    const toggledTotal = groupItems.reduce((acc, currentItem) => {
      if (currentItem.toggled) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const isGroupToggled = toggledTotal === groupItemsFiltered.length;

    const groupControlsSpring = useSpring({
      opacity: isOpen || toggledTotal ? 1 : 0,
      visibility: isOpen || toggledTotal ? 'visible' : 'hidden',
    });

    return (
      <div key={group} className={classes.listGroup}>
        <div
          className={classes.listGroupTitle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <AnimatedExpandIcon
            classes={{
              root: classes.expandIcon,
            }}
            style={collapseSpring}
          />
          <span>{group}</span>
          <animated.div
            className={classes.groupControls}
            style={groupControlsSpring}
          >
            <Checkbox
              disableRipple
              icon={<CheckBoxIcon />}
              checkedIcon={<CheckBoxActiveIcon />}
              indeterminate={!!toggledTotal && !isGroupToggled}
              indeterminateIcon={<CheckboxIndeterminateIcon />}
              checked={isGroupToggled}
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                setGroupToggled(groupItemsFiltered, e.target.checked);
              }}
            />
          </animated.div>
        </div>
        <Collapse
          classes={{
            root: classes.listGroupContent,
          }}
          in={isOpen}
        >
          {groupItemsFiltered.map(item => {
            return (
              <TransferListItem
                classes={{
                  root: classes.itemRoot,
                }}
                searchQuery={searchQuery}
                key={item[valueKey]}
                description={item[descKey]}
                title={item[labelKey]}
                toggled={item.toggled}
                setToggled={setToggled}
                value={item[valueKey]}
              />
            );
          })}
        </Collapse>
      </div>
    );
  }
);

export default TransferListGroup;
