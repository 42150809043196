import getLangIconName from '../utils/getLangIconName';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { SvgIcon } from '@mui/material';

const IconsByCode = {};

function importAll(r) {
  r.keys().forEach(key => (IconsByCode[key] = r(key).default));
}
importAll(require.context(`!@svgr/webpack!./flags`, false, /\.svg$/));

const getIconPathByName = name => `./${name.toLowerCase()}.svg`;

const useStyles = makeStyles({
  langIconContainer: {
    overflow: 'hidden',
    height: 24,
    width: 24,
    borderRadius: '50%',
    boxSizing: 'border-box',
    border: '1px solid #8496AB',

    '&>svg': {
      marginTop: -1,
      marginLeft: -1,
    },
  },
});

const LanguageIcon = ({ name, ...props }) => {
  const classes = useStyles();
  const countryIcon = getLangIconName(name);

  const Icon = IconsByCode[getIconPathByName(countryIcon)];

  return (
    <div className={classes.langIconContainer}>
      <SvgIcon viewBox={'0 0 24 24'} {...props}>
        {Icon && <Icon />}
      </SvgIcon>
    </div>
  );
};

export default LanguageIcon;
