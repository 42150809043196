import React from 'react';
import { SvgIcon } from '@mui/material';

const CloseIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 16 16'} {...props}>
      <path d="M 5.355469 4.648438 C 5.160156 4.453125 4.84375 4.449219 4.648438 4.644531 C 4.453125 4.839844 4.449219 5.15625 4.644531 5.351562 Z M 10.585938 11.351562 C 10.777344 11.546875 11.09375 11.550781 11.292969 11.355469 C 11.488281 11.160156 11.488281 10.84375 11.296875 10.648438 Z M 11.355469 5.351562 C 11.550781 5.15625 11.546875 4.839844 11.351562 4.644531 C 11.15625 4.449219 10.839844 4.453125 10.644531 4.648438 Z M 4.703125 10.648438 C 4.511719 10.84375 4.511719 11.160156 4.707031 11.355469 C 4.90625 11.550781 5.222656 11.546875 5.414062 11.351562 Z M 4.644531 5.351562 L 10.585938 11.351562 L 11.296875 10.648438 L 5.355469 4.648438 Z M 10.644531 4.648438 L 4.703125 10.648438 L 5.414062 11.351562 L 11.355469 5.351562 Z M 10.644531 4.648438 " />
    </SvgIcon>
  );
};

export default CloseIcon;
