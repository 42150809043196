import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../providers/DataProvider';
import { UiOptionsContext } from '../uiComponents/Field/UiOptionsProvider';
import { useTableApplyContext } from '../providers/TableApplyProvider';
import { omitBy } from 'lodash';

export const checkMarginValid = value => {
  const numberValue = +value;

  return (numberValue >= 1 && numberValue <= 30) || value === '';
};

export const useMarginInput = ({ displayValue, fieldKey }) => {
  const { rowData } = useContext(UiOptionsContext);
  const { tableChanges, setTableChanges } = useTableApplyContext();
  const rowPayloadValue = tableChanges.data[rowData.id];
  const prevValue = rowPayloadValue || rowData;
  const marginValue = prevValue[fieldKey];

  const patchApplyPayloadValue = marginValue => {
    setTableChanges(currentValue => {
      const tableFieldValue = {
        ...prevValue,
        [fieldKey]: marginValue,
      };

      const data = {
        ...currentValue.data,
        [rowData.id]: tableFieldValue,
      };

      const payload = checkMarginValid(marginValue)
        ? {
            ...currentValue.payload,
            [rowData.id]: {
              ...prevValue,
              margin_live: checkMarginValid(tableFieldValue.margin_live)
                ? +tableFieldValue.margin_live || null
                : undefined,
              margin_prematch: checkMarginValid(tableFieldValue.margin_prematch)
                ? +tableFieldValue.margin_prematch || null
                : undefined,
              [fieldKey]: +marginValue || null,
            },
          }
        : { ...omitBy(currentValue.payload, value => value.id === rowData.id) };

      return {
        data,
        payload,
      };
    });
  };

  const [isMarginValid, setIsMarginValid] = useState(
    checkMarginValid(displayValue)
  );

  useEffect(() => {
    const numberValue = +marginValue;

    const isValid = checkMarginValid(numberValue);
    setIsMarginValid(isValid);
  }, [marginValue]);

  const onChange = e => {
    let inlineValue = e.target.value;

    // TODO: in some way after enter second digit after point '.' becomes ','. But this works fine
    inlineValue = inlineValue.replace(',', '.');

    const formattedValue = inlineValue.includes('.')
      ? inlineValue.split('.')[0] + '.' + inlineValue.split('.')[1][0]
      : inlineValue;

    patchApplyPayloadValue(formattedValue);
  };

  return {
    value: marginValue,
    onChange,
    isMarginValid,
  };
};
