import { useQuery } from '@apollo/client';
import React from 'react';
import getAttemptsList from '../../gql/brand-admin/queries/getAttemptsList';
import ErrorWidget from '../../components/ErrorWidget';
import { useState } from 'react';
import useDynamicQuery from '../../hooks/useDynamicQuery';
import FailedRequestAttemptsTable, {
  COLUMN,
  initialToggledColumns,
} from './FailedRequestAttemptsTable';

const FailedRequestAttempts = ({ request_id }) => {
  const [visibleColumns, setVisibleColumns] = useState(initialToggledColumns);

  const { query } = useDynamicQuery('getAttemptsList', {
    fields: visibleColumns,
    variables: {
      request_id: {
        type: 'ID',
        required: true,
      },
    },
    mapper: {
      [COLUMN.requestId]: ['request_id'],
      [COLUMN.respBody]: ['resp_body'],
      [COLUMN.statusCode]: ['status_code'],
    },
    template: fields => [{ items: ['id', ...fields] }, 'total'],
  });

  const { data, loading, error } = useQuery(query, {
    fetchPolicy: 'no-cache',
    variables: {
      request_id,
    },
  });

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <FailedRequestAttemptsTable
      initialToggledColumns={visibleColumns}
      data={data?.getAttemptsList?.items || []}
      loading={loading}
      onToggleColumns={setVisibleColumns}
    />
  );
};

export default FailedRequestAttempts;
