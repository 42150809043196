import { isEqual, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { initialPreset } from '../../../../../../../providers/TreeSelectProvider';
import { reorderDragItemsData } from '../../../components/DraggableSelectMenu';
import { getConfiguredSourcesOption } from '../../../utils/getConfiguredSourcesOption';
import { getItemsForDragList } from '../../../utils/getItemsForDragList';
import { getKeySelector } from '../../../utils/getKeySelector';

export const useSourcesTemplateOption = ({
  itemSources = [],
  isSourcesConfiguredInitially = false,
  parentConfig,
  setParentSourcesCommands = () => {},
  getFormStateValues = () => {},
  setFormStateValue = () => {},
  setPresetApplied = () => {},
  setPreset = () => {},
  presetApplied = false,
  selected = false,
  selectedKey = 'sport',
  nodeSelector,
}) => {
  const [template, lineType] = getFormStateValues([
    'template',
    'configLineType',
  ]);

  //for store template change using their selector in object key format
  const keySelector = getKeySelector({ nodeSelector, lineType });

  //for check actual changes
  const defaultList = getItemsForDragList(itemSources);

  const providerConfigs = template[keySelector];

  //configure market priorities with saved  template changes or default query resp data
  const prioritySources = useMemo(
    () =>
      getConfiguredSourcesOption({
        prioritySources: itemSources,
        providerConfigs: providerConfigs || [],
        parentConfig: parentConfig || [],
        isSourcesConfiguredInitially,
      }),
    [itemSources, parentConfig, providerConfigs, isSourcesConfiguredInitially]
  );

  const initialSourcesList = getItemsForDragList(prioritySources);

  const [isSourcesListOpen, setIsSourcesListOpen] = useState(false);
  const [sourcesList, setSourcesList] = useState(initialSourcesList);

  const getSourcesListValuesConfig = useCallback(config => {
    return config.map(value => ({
      provider: value.provider,
      enabled: value.enabled,
      priority: value.priority,
    }));
  }, []);

  const isNewConfigEqualParentConfig = useCallback(
    newConfig => {
      //if new template has provider with different enabled or priority in parent config this function returns true
      if (parentConfig) {
        const parentListValuesConfig = getSourcesListValuesConfig(parentConfig);
        const newListValuesConfig = getSourcesListValuesConfig(newConfig);
        return isEqual(parentListValuesConfig, newListValuesConfig);
      }
      return false;
    },
    [parentConfig, getSourcesListValuesConfig]
  );

  const setNewSourcesConfigsToFormState = useCallback(
    ({ sourcesList }) => {
      const newSourcesConfig = [];
      sourcesList.forEach((value, index) => {
        const defaultSourceValue = defaultList.find(
          defaultListValue => defaultListValue.key === value.key
        );

        if (!isEqual(value, defaultSourceValue) || value.toggled)
          newSourcesConfig.push({
            lineType,
            nodeSelector,
            provider: value.key,
            priority:
              index + 1 === defaultSourceValue.priority ? undefined : index + 1,
            enabled:
              defaultSourceValue.toggled === value.toggled
                ? undefined
                : value.toggled,
            isFormData: !isEqual(value, defaultSourceValue),
          });
      });

      //change config if this config not equal to parent config, and remove this config if new value equal to default
      if (
        isNewConfigEqualParentConfig(newSourcesConfig) ||
        isEqual(sourcesList, defaultList)
      ) {
        setFormStateValue('template', omit(template, keySelector));
      } else {
        setFormStateValue(`template.${keySelector}`, newSourcesConfig);
      }

      return newSourcesConfig;
    },
    [
      defaultList,
      keySelector,
      lineType,
      template,
      nodeSelector,
      setFormStateValue,
      isNewConfigEqualParentConfig,
    ]
  );

  const onStatusChange = useCallback(
    (key, isToggled) => {
      const newSourcesList = sourcesList.map(value => {
        if (value.key === key) {
          value.toggled = isToggled;
        }
        return value;
      });

      setSourcesList(newSourcesList);

      setNewSourcesConfigsToFormState({
        sourcesList: newSourcesList,
      });
    },
    [setNewSourcesConfigsToFormState, sourcesList]
  );

  const onReorder = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const newSourcesList = reorderDragItemsData({
        options: sourcesList,
        startIndex: result.source.index,
        endIndex: result.destination.index,
      });

      if (!isEqual(newSourcesList, sourcesList)) {
        setSourcesList(newSourcesList);

        setNewSourcesConfigsToFormState({
          sourcesList: newSourcesList,
        });
      }
    },
    [setNewSourcesConfigsToFormState, sourcesList]
  );

  const refreshSourcesList = () => {
    setSourcesList(initialSourcesList);
  };

  //to refresh with parent changes, if parent changed
  useEffect(() => {
    refreshSourcesList();
    // eslint-disable-next-line
  }, [parentConfig, lineType]);

  //to refresh with parent changes, if sources list becomes empty
  useEffect(() => {
    if (sourcesList.every(value => !value.enabled)) {
      refreshSourcesList();
    }
    // eslint-disable-next-line
  }, [prioritySources, lineType]);

  //to refresh and apply configs in preset
  useEffect(() => {
    if (presetApplied) {
      refreshSourcesList();
      setPreset(initialPreset);
      setPresetApplied(false);
    }
    // eslint-disable-next-line
  }, [presetApplied, setPresetApplied, setPreset]);

  useEffect(() => {
    if (selected) {
      setParentSourcesCommands(currentValue => ({
        ...currentValue,
        [lineType]: {
          ...currentValue[lineType],
          [selectedKey]: prioritySources.filter(value => value.enabled),
        },
      }));
    }
  }, [
    selected,
    selectedKey,
    lineType,
    prioritySources,
    setParentSourcesCommands,
  ]);

  return useMemo(
    () => ({
      sourcesList,
      toggledSourcesList: sourcesList.filter(value => value.toggled),
      setSourcesList,
      isSourcesListOpen,
      providerConfigs,
      setIsSourcesListOpen,
      setNewSourcesConfigsToFormState,
      onStatusChange,
      onReorder,
    }),
    [
      sourcesList,
      setSourcesList,
      isSourcesListOpen,
      providerConfigs,
      setIsSourcesListOpen,
      setNewSourcesConfigsToFormState,
      onStatusChange,
      onReorder,
    ]
  );
};
