import Select from '../../../../../../../components/Inputs/Select/Select';
import useAutocompleteData from '../../../../../../../hooks/useAutocompleteData';

export const ClickhouseAutocompleteMethod = {
  LANG: 'lang',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  LAYOUT: 'layout',
  PAGE: 'page',
  SECTION: 'section',
  PROVIDER: 'provider',
};

const ClickhouseAutocomplete = ({ method, ...props }) => {
  const { data: options = [], loading } = useAutocompleteData({
    url: `/api/v1/ReporterAPI/autocomplete/clickhouse?method=${method}`,
    withoutParams: true,
  });

  return (
    <Select
      {...props}
      options={options}
      valueKey={'string_id'}
      labelKey={'string_value'}
    />
  );
};

export default ClickhouseAutocomplete;
