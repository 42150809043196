import { gql } from '@apollo/client';

const groupItemFragment = gql`
  fragment GroupItemFragment on GroupItem {
    desc {
      id
      category
      tournament
      competitors
      raceNumber
    }
    provider
    parsers
    scheduled
    mainItem
    isAuto
    userMerged
    unmergable
    creatable
    inverted
  }
`;

export default groupItemFragment;
