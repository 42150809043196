import React from 'react';
import GlobalLoading from '../../components/GlobalLoading';
import withStyles from '@mui/styles/withStyles';

import RDPClientComponent from '../../components/RDPClient';

const styles = {
  display: {},
};

function RDPClient({ loading, data, classes }) {
  if (loading) return <GlobalLoading />;
  if (data)
    return (
      <RDPClientComponent
        endpointUrl={data.ws_endpoint}
        token={data.token}
        className={classes.display}
      />
    );
  return null;
}

export default withStyles(styles)(RDPClient);
