import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { cloneElement } from 'react';

const useStyles = makeStyles({
  filter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '&>*': {
      // flexGrow: 1,
    },
  },

  fullWidthFilter: {
    gridColumn: 1,
    gridColumnEnd: -1,
  },
});

const Filter = ({
  name,
  fullWidth = false,
  label,
  initialValue,
  onChange,
  children,
}) => {
  const classes = useStyles();

  const { control } = useFormContext();

  return (
    <div
      className={clsx(classes.filter, {
        [classes.fullWidthFilter]: fullWidth,
      })}
    >
      <Controller
        defaultValue={initialValue}
        name={name}
        control={control}
        render={({ field }) => {
          return (
            children &&
            cloneElement(children, {
              ...field,
              label,
              onChange: data => {
                field.onChange(data);
                onChange({
                  filedName: field.name,
                  oldValue: field.value,
                  newValue: data,
                });
              },
            })
          );
        }}
      />
    </div>
  );
};

export default Filter;
