const lightTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },
  palette: {
    text: {
      primary: '#031224',
      secondary: 'rgba(3, 18, 36, 0.5)',
      disabled: 'rgba(3, 18, 36, 0.5)',
      hint: 'rgba(3, 18, 36, 0.12)',
    },
    background: {
      default: '#F7F7F7',
    },
    common: {
      black: '#031224',
      white: '#fff',
    },
    primary: {
      main: '#1581FF',
      light: '#E8F2FF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E8F3FF',
      contrastText: '#1581FF',
    },
    success: {
      main: '#3FDE91',
      light: '#8CEBBD',
    },
    warning: {
      main: '#EDBE18',
      light: '#FBF2D1',
    },
    info: {
      main: '#00E0FF',
    },
    error: {
      main: '#FF000F',
      light: '#FFE6E8',
      contrastText: '#fff',
    },
    red: {
      100: '#FFE6E8',
      200: '#FFCCCF',
      500: '#FF000F',
    },

    blue: {
      100: '#E8F3FF',
      200: '#D0E6FF',
      500: '#1581FF',
    },

    grey: {
      main: '#8496AB',
      light: '#E6EAEE',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(132, 150, 171, 0.5)',
      },
    },

    MuiTooltip: {
      popper: {},
      tooltip: {
        opacity: 1,
        color: '#031224',
        backgroundColor: '#fff',
        boxShadow:
          '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
        borderRadius: 8,
        fontSize: '14px',
        letterSpacing: '0.2px',
        fontWeight: 500,
        padding: 12,
        maxWidth: 'none',
      },
    },

    MuiChip: {
      root: {
        backgroundColor: 'rgba(21, 129, 255, 0.1)',
        borderRadius: 4,
        height: 32,
      },
      deleteIcon: {
        fontSize: 16,
        color: '#1581FF',
        '&:hover': {
          color: '#76B1F6',
        },
      },
      label: {
        color: '#1581FF',
      },
    },

    MuiToolbar: {
      root: {
        minHeight: '48px!important',
        background: '#1581FF',
      },
    },

    MuiTablePagination: {
      select: {
        // fontFamily: `'Iosevka SS11', monospace`,
        // fontFeatureSettings: `'ss04' on`,
        lineHeight: '25px',
        paddingRight: 46,
        paddingLeft: 10,
        paddingTop: 7,
        paddingBottom: 8,
      },
      selectRoot: {},
      selectIcon: {
        top: 'initial',
        right: 10,
      },
      caption: {
        '&:last-of-type': {
          display: 'flex',
          justifyContent: 'flex-end',
          flex: 1,
        },
      },
      spacer: {
        flex: 0,
      },
      toolbar: {
        background: '#F7F7F7',
        paddingLeft: 0,
      },
    },

    MuiPaper: {
      root: {
        color: '#031224',
      },

      elevation1: {
        boxShadow: 'none',
      },

      elevation2: {
        boxShadow:
          '0px 2px 16px rgba(132, 150, 171, 0.25), 0px 8px 32px rgba(132, 150, 171, 0.16)',
      },
    },

    MuiTabs: {
      root: {
        background: '#E6EAEE',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        minHeight: 40,
      },

      indicator: {
        background: 'transparent',
      },
    },
    MuiTab: {
      root: {
        minHeight: 40,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        letterSpacing: '0.5px',
        textTransform: 'capitalize',

        '&.Mui-selected': {
          color: '#031224',
          background: '#fff',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        },
      },
    },
    MuiTable: {
      root: {
        background: '#F7F7F7',
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        paddingBottom: 8,
      },
    },
    MuiTableRow: {
      root: {
        borderRadius: 4,
      },

      head: {
        padding: 0,
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: '0 8px',
        whiteSpace: 'pre',

        '&:first-child': {
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
        },
        '&:last-child': {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        },
      },

      head: {
        fontWeight: 600,
        fontSize: '11px',
        lineHeight: '13px',
        letterSpacing: '0.2px',
        textTransform: 'capitalize',
        height: 38,
        zIndex: 2,
        whiteSpace: 'nowrap',
      },

      body: {
        backgroundColor: '#fff',
        letterSpacing: '0.2px',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '13px',
        color: '#031224',
      },
    },

    MuiAccordion: {
      root: {
        '&:before': {
          content: '""',
        },

        '&$expanded': {
          margin: 0,
          marginBottom: 8,
        },
      },
    },

    MuiAccordionSummary: {
      root: {
        minHeight: 'initial',
        padding: '4px 24px 4px 0',

        '&$expanded': {
          minHeight: 'initial',
        },
      },

      expandIcon: {
        marginRight: 0,
        padding: 0,
        '& svg': {
          color: '#1581FF',
          fontSize: '16px',
        },
      },

      content: {
        margin: 0,
        '&$expanded': {
          margin: 0,
        },
      },
    },

    MuiAccordionDetails: {
      root: {
        padding: '0 18px 0 64px',
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },

    MuiAutocomplete: {
      groupLabel: {
        textTransform: 'capitalize',
      },

      endAdornment: {},

      paper: {
        boxShadow: '0px 2px 16px rgba(132, 150, 171, 0.25)',
        borderRadius: 8,
      },

      listbox: {
        paddingTop: 0,
        paddingBottom: 0,
      },

      popupIndicator: {
        transition: 'transform 0.2s ease-in-out',
        marginRight: -5,
      },

      noOptions: {
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '0.2px',
      },

      option: {
        fontWeight: 500,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.2px',
        lineHeight: '28px',
        minHeight: 'unset',
        justifyContent: 'space-between',

        '&:hover': {
          backgroundColor: '#E8F2FF!important',
          color: '#1581FF!important',

          '& svg': {
            color: '#1581FF',
          },
        },

        '&[aria-selected="true"]': {
          backgroundColor: '#fff!important',
          color: '#1581FF',
        },
      },

      input: {
        background: '#F7F7F7',
        borderRadius: 4,
        color: '#031224',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.2px',

        '&.MuiInputBase-inputSizeSmall': {
          height: 30,
          fontSize: '11px',
        },
      },

      inputRoot: {
        flexWrap: 'nowrap',
        background: '#F7F7F7',
        borderRadius: 4,

        '&:not(.MuiInputBase-fullWidth)': {
          maxWidth: 352,
        },

        '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
          {
            paddingTop: 8,
            paddingBottom: 8,

            '& .MuiAutocomplete-input': {
              padding: '4px 4px',
            },
          },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#8496AB',
        },

        '&$focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#8496AB',
        },

        '&$disabled': {
          opacity: 0.5,
        },
      },
    },

    MuiMenu: {
      list: {
        paddingTop: 0,
        paddingBottom: 0,
      },

      paper: {
        boxShadow: '0px 2px 16px rgba(132, 150, 171, 0.25)',
      },
    },

    MuiMenuItem: {
      root: {
        fontWeight: 500,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.2px',
        textTransform: 'capitalize',
        paddingTop: 0,
        paddingBottom: 0,
        lineHeight: '40px',
        minHeight: 'unset',

        '&:hover': {
          background: '#E8F2FF',
          color: '#1581FF',
        },

        '&$selected': {
          backgroundColor: '#fff',
          color: '#1581FF',
        },
      },
    },

    MuiListItemText: {
      primary: {
        fontWeight: 500,
        color: '#031224',
        fontSize: '14px',
        letterSpacing: '0.2px',
      },
    },

    // Inputs

    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: '#E6EAEE',
        color: '#031224',
      },
    },

    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },

    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 24,
      },
      label: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
      },
    },

    MuiCheckbox: {
      root: {
        color: '#8496AB',

        '&:hover': {
          color: '#1581FF',
          background: 'unset',
        },
      },
    },

    MuiRadio: {
      root: {
        padding: 0,
        paddingRight: 8,
        '& svg': {
          fontSize: '18px',
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 60,
        padding: 10,
      },

      thumb: {
        boxShadow: 'none',
        width: 24,
        height: 24,
      },

      track: {
        opacity: 0.2,
        borderRadius: 9,
      },

      switchBase: {
        padding: 7,
        color: '#8496AB',
        '&+.MuiSwitch-track': {
          backgroundColor: '#8496AB',
          opacity: 0.2,
        },

        '&.Mui-checked': {
          color: '#1581FF',
          transform: 'translateX(22px)',

          '&+.MuiSwitch-track': {
            backgroundColor: '#1581FF',
          },
        },
      },

      // colorSecondary: {
      //   '&$disabled': {
      //     '& $thumb': {
      //       backgroundColor: '#C1CAD4',
      //     },
      //
      //     '& + $track': {
      //       opacity: 0.1,
      //       backgroundColor: '#8496AB',
      //     },
      //   },
      //
      //   '&$checked': {
      //     '& $thumb': {
      //       backgroundColor: '#1581FF',
      //     },
      //
      //     '& + $track': {
      //       backgroundColor: '#1581FF',
      //       opacity: 0.2,
      //     },
      //
      //     '&$disabled': {
      //       '& $thumb': {
      //         backgroundColor: '#D0E6FF',
      //       },
      //
      //       '& + $track': {
      //         opacity: 0.1,
      //         backgroundColor: '#1581FF',
      //       },
      //     },
      //   },
      //   '&:hover': {
      //     backgroundColor: 'initial',
      //     '& + $track': {
      //       opacity: 0.4,
      //     },
      //
      //     '&$checked': {
      //       '& + $track': {
      //         opacity: 0.4,
      //       },
      //     },
      //
      //     '&$disabled': {
      //       '& + $track': {
      //         opacity: 0.1,
      //       },
      //     },
      //   },
      // },
    },

    MuiSelect: {
      icon: {
        fontSize: '16px',
        color: '#1581FF',
        top: 'calc(50% - 8px)',
      },

      select: {
        borderRadius: 4,
        '&:focus': {
          borderRadius: 4,
          backgroundColor: 'initial',
          borderColor: '#8496AB',
        },
      },
    },

    MuiFormLabel: {
      root: {
        color: '#031224',

        '&$focused': {
          color: 'initial',
        },

        '&$disabled': {
          opacity: 0.5,
        },
      },
    },
    MuiInputLabel: {
      root: {},
      outlined: {
        overflow: 'visible',
        color: '#8496AB',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '15px',
        letterSpacing: '0.2px',
        transform: 'translate(14px, 13px) scale(1)',
        '&.Mui-focused': {
          color: '#8496AB',
        },
      },
      shrink: {
        transform: 'translate(14px, -5px) scale(0.75)!important',
      },
    },

    MuiInputBase: {
      root: {
        height: 40,
        background: '#F7F7F7',
        borderRadius: 4,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#8496AB',
        },

        '&$focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#8496AB',
        },

        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },

      input: {
        background: '#F7F7F7',
        borderRadius: 4,
        color: '#031224',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.2px',

        '&.MuiInputBase-inputSizeSmall': {
          height: 30,
          fontSize: '11px',
        },
      },

      inputMultiline: {
        height: 'auto!important',
      },

      multiline: {
        height: 'auto!important',
      },

      sizeSmall: {
        height: 30,
      },

      inputSizeSmall: {
        fontSize: '11px',
        height: 30,
      },
    },

    MuiDialog: {
      paper: {
        boxShadow: 'none',
        borderRadius: 8,
      },

      paperWidthSm: {
        maxWidth: 500,
        width: '100%',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: '32px 32px 8px 32px',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '29px',
        letterSpacing: '0.5px',
        overflowWrap: 'break-word',
      },
    },

    MuiDialogContent: {
      root: {
        padding: '8px 32px 0',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
      },
    },

    MuiDialogActions: {
      root: {
        padding: 32,
        '&>*+*': {
          marginLeft: 16,
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        background: '#F7F7F7',
        borderRadius: 4,
        borderColor: 'rgba(148, 162, 180, 0.3)',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#8496AB',
        },

        '&$focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#8496AB',
        },

        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },

      input: {
        background: '#F7F7F7',
        borderRadius: 4,
        color: '#031224',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.2px',
        paddingTop: 12.5,
        paddingBottom: 12.5,
        height: 40,
        boxSizing: 'border-box',
      },

      inputMarginDense: {
        paddingTop: 12,
        paddingBottom: 12,
      },

      notchedOutline: {
        borderColor: '#D9DEE3',

        '&:hover': {
          borderColor: '#8496AB',
        },
      },

      adornedEnd: {
        paddingRight: 0,
      },
    },

    MuiIconButton: {
      root: {},
    },

    MuiButton: {
      root: {
        padding: '12px 16px',
        textTransform: 'capitalize',
        borderRadius: 100,
        minWidth: 100,
        height: 40,
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 600,
        letterSpacing: '0.5px',
      },

      text: {
        padding: '8px 8px',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'rgba(21, 129, 255, 0.6)',
        },
      },

      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'rgba(21, 129, 255, 0.6)',
        },
      },

      contained: {
        boxShadow: 'none',
        fontWeight: 600,
        '&.Mui-disabled': {
          backgroundColor: '#F3F4F7',
          color: '#BCC5D1',
        },
      },

      containedPrimary: {
        '&:hover': {
          boxShadow: '0px 3px 12px rgba(0, 118, 255, 0.8)',
          backgroundColor: '#1581FF',
        },
      },

      containedSecondary: {
        backgroundColor: 'rgba(21, 129, 255, 0.1)',
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'rgba(21, 129, 255, 0.2)',
        },
      },

      containedError: {
        backgroundColor: 'rgba(255, 0, 15, 0.1)',
        boxShadow: 'none',
        color: '#FF000F',

        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'rgba(255, 0, 15, 0.2)',
        },
      },

      outlinedSecondary: {},
    },

    MuiPickersCalendar: {
      root: {
        minHeight: 200,
      },
      week: {
        margin: 0,
      },
      weekDayLabel: {
        color: '#8496AB',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '13px',
        textAlign: 'center',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        width: 40,
        margin: 0,
      },
    },

    MuiPickersCalendarHeader: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },

    MuiPopover: {
      paper: {
        boxShadow: '0px 2px 16px rgba(132, 150, 171, 0.25)',
        borderRadius: 8,
      },
    },

    MuiPickersDay: {
      root: {
        width: 40,
        height: 40,
        margin: 0,
        borderRadius: 8,

        '&:hover': {
          backgroundColor: '#fff',
          color: '#1581FF',
        },

        '&$selected, &:focus&$selected': {
          backgroundColor: '#E8F2FF',
          color: '#1581FF',

          '&:hover': {
            backgroundColor: 'rgba(21,129,255,0.15)',
            color: '#1581FF',
          },
        },
      },
      today: {
        backgroundColor: '#F3F4F7',

        '&:hover': {
          backgroundColor: '#F3F4F7',
        },

        '&:not($selected)': {
          border: 'none',
        },

        '&$selected': {
          backgroundColor: '#D3E7FF',
        },
      },
      dayLabel: {
        // fontFamily: `'Iosevka SS11', monospace`,
        // fontFeatureSettings: `'ss04' on`,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.2px',
        fontWeight: 'normal',
      },
    },

    MuiPickersDateRangeDay: {
      day: {
        transform: 'scale(1)',
      },
      rangeIntervalPreview: {
        border: 'none',
      },
      rangeIntervalDayPreview: {
        border: 'none',
      },
      dayOutsideRangeInterval: {
        '&:hover': {
          border: 'none',
        },
      },
      dayInsideRangeInterval: {
        color: '#1581FF',
        backgroundColor: '#E8F2FF',

        '&:hover': {
          backgroundColor: 'rgba(21,129,255,0.15)',
          color: '#1581FF',
        },
      },
      rangeIntervalDayPreviewEnd: {},
      rangeIntervalDayPreviewStart: {},
      rangeIntervalDayHighlight: {
        color: '#1581FF',
        backgroundColor: '#E8F2FF',

        '&:hover': {
          backgroundColor: 'rgba(21,129,255,0.15)',
          color: '#1581FF',
        },

        '&:first-child': {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },

        '&:last-child': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
      },

      rangeIntervalDayHighlightStart: {
        marginLeft: 'initial',
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },

      rangeIntervalDayHighlightEnd: {
        marginRight: 'initial',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },

    MuiPickersDesktopDateRangeCalendar: {
      calendar: {
        minHeight: 200,
      },

      rangeCalendarContainer: {
        '&:not(:last-child)': {
          borderRight: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter'],
  },
};

export default lightTheme;
