import React, { useContext } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import { ContainerBoundsContext } from '../../providers/ContainerWidthProvider';

const useStyles = makeStyles({
  summaryCell: {
    background: 'transparent',
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '14px',
    letterSpacing: '0.2px',
    paddingLeft: 16,
    paddingTop: 8,
  },

  summaryRoot: {
    position: 'sticky',
    left: 0,
  },
});

const TableSummary = () => {
  const classes = useStyles();

  const { width: containerBounds } = useContext(ContainerBoundsContext);

  return (
    <TableRow>
      <TableCell colSpan={'100%'} className={classes.summaryCell}>
        <div
          className={classes.summaryRoot}
          style={{
            width: '100%',
          }}
        >
          Summary
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableSummary;
