import {
  BannerType,
  BannerTypeLabelMap,
} from '../BannerForm/BannerValidationSchema';
import Select from '../../../../components/Inputs/Select/Select';
import { forwardRef } from 'react';

const options = Object.values(BannerType).map(type => ({
  label: BannerTypeLabelMap[type],
  value: type,
}));

const BannerTypeSelect = forwardRef(({ ...props }, ref) => {
  return (
    <Select
      ref={ref}
      {...props}
      label={'Select Banner Type'}
      options={options}
    />
  );
});

export default BannerTypeSelect;
