/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MuiCardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

import UnsupportedField from './UnsupportedField';
import {
  allowAdditionalItems,
  getDefaultFormState,
  getDefaultRegistry,
  getUiOptions,
  getWidget,
  isFilesArray,
  isFixedItems,
  isMultiSelect,
  optionsList,
  retrieveSchema,
  toIdSchema,
} from 'react-jsonschema-form/lib/utils';
import withStyles from '@mui/styles/withStyles';
import shortid from 'shortid';

const CardContent = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})(MuiCardContent);

class ArrayField extends Component {
  static defaultProps = {
    uiSchema: {},
    formData: [],
    idSchema: {},
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
  };

  state = {
    ids: [],
  };

  componentDidMount() {
    this.initIds();
  }

  componentDidUpdate(prevProps) {
    const { formData } = this.props;
    if (
      formData &&
      formData !== prevProps.formData &&
      formData.length !== this.state.ids.length
    ) {
      this.initIds();
    }
  }

  initIds = () => {
    if (!this.props.formData || !Array.isArray(this.props.formData)) {
      this.setState({ ids: [] });
      return;
    }

    this.setState({
      ids: this.props.formData.map(() => shortid.generate()),
    });
  };

  addRandomId = () =>
    this.setState(({ ids }) => ({
      ids: [...ids, shortid.generate()],
    }));

  addRandomIds = length => {
    this.setState(({ ids }) => ({
      ids: [...ids, ...new Array(length).fill(shortid.generate())],
    }));
  };

  removeId = index =>
    this.setState(({ ids }) => ({
      ids: [...ids.slice(0, index), ...ids.slice(index + 1)],
    }));

  isItemRequired(itemSchema) {
    if (Array.isArray(itemSchema.type)) {
      // While we don't yet support composite/nullable jsonschema types, it's
      // future-proof to check for requirement against these.
      return !itemSchema.type.includes('null');
    }
    // All non-null array item types are inherently required by design
    return itemSchema.type !== 'null';
  }

  canAddItem(formItems) {
    const { schema, uiSchema } = this.props;
    let { addable } = getUiOptions(uiSchema);
    if (addable !== false) {
      // if ui:options.addable was not explicitly set to false, we can add
      // another item if we have not exceeded maxItems yet
      if (schema.maxItems !== undefined) {
        addable = formItems.length < schema.maxItems;
      } else {
        addable = true;
      }
    }
    return addable;
  }

  onAddClick = event => {
    event.preventDefault();
    const { schema, formData, registry = getDefaultRegistry() } = this.props;
    const { definitions } = registry;
    let itemSchema = schema.items;
    if (isFixedItems(schema) && allowAdditionalItems(schema)) {
      itemSchema = schema.additionalItems;
    }
    this.addRandomId();
    this.props.onChange([
      ...(formData || []),
      getDefaultFormState(itemSchema, undefined, definitions) || '',
    ]);
  };

  onAdd = (defaultData = {}) => {
    const { schema, formData, registry = getDefaultRegistry() } = this.props;
    const { definitions } = registry;
    let itemSchema = schema.items;
    if (isFixedItems(schema) && allowAdditionalItems(schema)) {
      itemSchema = schema.additionalItems;
    }
    const isMultiple = Array.isArray(defaultData);

    if (isMultiple) {
      this.addRandomIds();
      this.props.onChange([
        ...(formData || []),
        ...defaultData.map(item =>
          getDefaultFormState(itemSchema, item, definitions)
        ),
      ]);
    } else {
      this.addRandomId();
      this.props.onChange([
        ...(formData || []),
        getDefaultFormState(itemSchema, defaultData, definitions),
      ]);
    }
  };

  onDropIndexClick = index => {
    const { formData, onChange } = this.props;
    this.removeId(index);
    onChange(formData.filter((_, i) => i !== index));
  };

  onReorderClick = (index, newIndex) => {
    return event => {
      if (event) {
        event.preventDefault();
        event.target.blur();
      }
      const { formData, onChange } = this.props;
      onChange(
        formData.map((item, i) => {
          if (i === newIndex) {
            return formData[index];
          } else if (i === index) {
            return formData[newIndex];
          } else {
            return item;
          }
        })
      );
    };
  };

  onReorder = (startIndex, endIndex) => {
    const { formData, onChange } = this.props;

    const result = Array.from(formData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    onChange(result);
  };

  onChangeForId = id => value => {
    const { formData, onChange } = this.props;
    const newFormData = formData.map((item, i) => {
      // We need to treat undefined items as nulls to have validation.
      // See https://github.com/tdegrunt/jsonschema/issues/206
      const jsonValue = typeof value === 'undefined' ? null : value;
      const index = this.state.ids.indexOf(id);
      return index === i ? jsonValue : item;
    });
    onChange(newFormData);
  };

  onSelectChange = value => {
    this.props.onChange(value);
  };

  render() {
    const {
      schema,
      uiSchema,
      idSchema,
      formData,
      registry = getDefaultRegistry(),
    } = this.props;

    const { definitions, widgets } = registry;
    if (!schema.hasOwnProperty('items')) {
      return (
        <UnsupportedField
          schema={schema}
          idSchema={idSchema}
          reason="Missing items definition"
        />
      );
    }
    const { widget, ...options } = getUiOptions(uiSchema);
    if (widget === 'autocomplete') {
      const Widget = getWidget(schema, widget, widgets);
      return (
        <Widget
          options={options}
          value={formData}
          label={schema.title}
          formData={this.props.formContext.formData}
          onChange={this.onSelectChange}
          isMulti
        />
      );
    }
    if (isFixedItems(schema)) {
      return this.renderFixedArray();
    }

    if (isFilesArray(schema, uiSchema, definitions)) {
      return this.renderFiles();
    }
    if (
      (uiSchema['ui:options'] && uiSchema['ui:options'].uniqueItems) ||
      isMultiSelect(schema, definitions)
    ) {
      return this.renderMultiSelect();
    }

    return this.renderNormalArray();
  }

  renderNormalArray() {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      formContext,
      onBlur,
      onFocus,
    } = this.props;
    const title =
      schema.title === undefined ? upperFirst(lowerCase(name)) : schema.title;
    const { ArrayFieldTemplate, definitions, fields } = registry;
    const { TitleField, DescriptionField } = fields;
    const formDataArray = formData ? formData : [];
    const description = uiSchema['ui:description'] || schema.description;

    const arrayProps = {
      canAdd: this.canAddItem(formData),
      items: this.state.ids.length
        ? formDataArray.map((item, index) => {
            const itemSchema = retrieveSchema(schema.items, definitions, item);
            const itemErrorSchema = errorSchema
              ? errorSchema[index]
              : undefined;
            const itemIdPrefix = idSchema.$id + '_' + index;
            const itemIdSchema = toIdSchema(
              itemSchema,
              itemIdPrefix,
              definitions,
              item
            );
            return this.renderArrayFieldItem({
              index,
              canMoveUp: index > 0,
              canMoveDown: index < formDataArray.length - 1,
              itemSchema: itemSchema,
              itemIdSchema,
              itemErrorSchema,
              itemData: item,
              itemUiSchema: uiSchema.items,
              autofocus: autofocus && index === 0,
              onBlur,
              onFocus,
            });
          })
        : [],
      name,
      DescriptionField,
      disabled,
      idSchema,
      uiSchema,
      onAddClick: this.onAddClick,
      onAdd: this.onAdd,
      readonly,
      required,
      schema,
      title,
      description,
      TitleField,
      formContext,
      formDataArray,
    };

    return (
      <ArrayFieldTemplate
        {...arrayProps}
        error={
          !!(errorSchema && errorSchema.__errors && errorSchema.__errors.length)
        }
        helperText={
          (errorSchema && errorSchema.__errors && errorSchema.__errors[0]) || ''
        }
      />
    );
  }

  renderMultiSelect() {
    const {
      schema,
      idSchema,
      uiSchema,
      formData,
      disabled,
      readonly,
      autofocus,
      onBlur,
      onFocus,
      required,
      registry = getDefaultRegistry(),
      errorSchema,
    } = this.props;

    const items = this.props.formData;
    const { widgets, definitions, formContext } = registry;
    const itemsSchema = retrieveSchema(schema.items, definitions, formData);
    const enumOptions = itemsSchema.enum ? optionsList(itemsSchema) : {};
    const { widget = 'select', ...options } = {
      ...getUiOptions(uiSchema),
      enumOptions,
    };
    const Widget = getWidget(schema, widget, widgets);
    const Container =
      uiSchema['ui:title'] || schema.title ? CardContent : Fragment;

    return (
      <Container>
        <Typography variant="h5">
          {upperFirst(lowerCase(uiSchema['ui:title'] || schema.title))}
        </Typography>

        {(uiSchema['ui:description'] || schema.description) && (
          <Typography>
            {uiSchema['ui:description'] || schema.description}
          </Typography>
        )}

        <Widget
          errorSchema={errorSchema}
          id={idSchema && idSchema.$id}
          multiple
          onChange={this.onSelectChange}
          onBlur={onBlur}
          onFocus={onFocus}
          options={options}
          schema={schema}
          value={items}
          disabled={disabled}
          readonly={readonly}
          required={required}
          formContext={formContext}
          autofocus={autofocus}
        />
      </Container>
    );
  }

  renderFiles() {
    const {
      schema,
      uiSchema,
      idSchema,
      name,
      disabled,
      readonly,
      autofocus,
      onBlur,
      onFocus,
      registry = getDefaultRegistry(),
    } = this.props;
    const title = schema.title || upperFirst(lowerCase(name));
    const items = this.props.formData;
    const { widgets, formContext } = registry;
    const { widget = 'files', ...options } = getUiOptions(uiSchema);
    const Widget = getWidget(schema, widget, widgets);
    return (
      <Widget
        options={options}
        id={idSchema && idSchema.$id}
        multiple
        onChange={this.onSelectChange}
        onBlur={onBlur}
        onFocus={onFocus}
        schema={schema}
        title={title}
        value={items}
        disabled={disabled}
        readonly={readonly}
        formContext={formContext}
        autofocus={autofocus}
      />
    );
  }

  renderFixedArray() {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      onBlur,
      onFocus,
    } = this.props;
    const title = schema.title || upperFirst(lowerCase(name));
    let items = this.props.formData;
    const { ArrayFieldTemplate, definitions, fields } = registry;
    const { TitleField } = fields;
    const itemSchemas = schema.items.map((item, index) =>
      retrieveSchema(item, definitions, formData[index])
    );
    const additionalSchema = allowAdditionalItems(schema)
      ? retrieveSchema(schema.additionalItems, definitions, formData)
      : null;
    const description = uiSchema['ui:description'] || schema.description;

    if (!items || items.length < itemSchemas.length) {
      // to make sure at least all fixed items are generated
      items = items || [];
      items = items.concat(new Array(itemSchemas.length - items.length));
    }

    // These are the props passed into the render function
    const arrayProps = {
      canAdd: this.canAddItem(items) && additionalSchema,
      disabled,
      idSchema,
      formData,
      items: this.state.ids.length
        ? items.map((item, index) => {
            const additional = index >= itemSchemas.length;
            const itemSchema = additional
              ? retrieveSchema(schema.additionalItems, definitions, item)
              : itemSchemas[index];
            const itemIdPrefix = idSchema.$id + '_' + index;

            const itemIdSchema = toIdSchema(
              itemSchema,
              itemIdPrefix,
              definitions,
              item
            );
            const itemUiSchema = additional
              ? uiSchema.additionalItems || {}
              : Array.isArray(uiSchema.items)
              ? uiSchema.items[index]
              : uiSchema.items || {};
            const itemErrorSchema = errorSchema
              ? errorSchema[index]
              : undefined;

            return this.renderArrayFieldItem({
              index,
              canRemove: additional,
              canMoveUp: index >= itemSchemas.length + 1,
              canMoveDown: additional && index < items.length - 1,
              itemSchema,
              itemData: item,
              itemUiSchema,
              itemIdSchema,
              itemErrorSchema,
              autofocus: autofocus && index === 0,
              onBlur,
              onFocus,
            });
          })
        : [],
      name,
      onAddClick: this.onAddClick,
      onAdd: this.onAdd,
      readonly,
      required,
      schema,
      uiSchema,
      title,
      description,
      TitleField,
    };

    return <ArrayFieldTemplate {...arrayProps} />;
  }

  renderArrayFieldItem(props) {
    const {
      index,
      canRemove = true,
      canMoveUp = true,
      canMoveDown = true,
      itemSchema,
      itemData,
      itemUiSchema,
      itemIdSchema,
      itemErrorSchema,
      autofocus,
      onBlur,
      onFocus,
    } = props;
    const {
      disabled,
      readonly,
      uiSchema,
      registry = getDefaultRegistry(),
    } = this.props;

    const {
      fields: { SchemaField },
    } = registry;
    const { orderable, removable } = {
      orderable: true,
      removable: true,
      ...uiSchema['ui:options'],
    };
    const has = {
      moveUp: orderable && canMoveUp,
      moveDown: orderable && canMoveDown,
      remove: removable && canRemove,
    };
    has.toolbar = Object.keys(has).some(key => has[key]);

    const field = (
      <SchemaField
        schema={itemSchema}
        uiSchema={itemUiSchema}
        formData={itemData}
        errorSchema={itemErrorSchema}
        idSchema={itemIdSchema}
        required={this.isItemRequired(itemSchema)}
        onChange={this.onChangeForId(this.state.ids[index])}
        onBlur={onBlur}
        onFocus={onFocus}
        registry={this.props.registry}
        disabled={this.props.disabled}
        readonly={this.props.readonly}
        autofocus={autofocus}
      />
    );

    return {
      children:
        itemSchema.type === 'object' ? (
          field
        ) : (
          <CardContent>{field}</CardContent>
        ),
      disabled,
      hasToolbar: has.toolbar,
      hasMoveUp: has.moveUp,
      hasMoveDown: has.moveDown,
      hasRemove: has.remove,
      index,
      key: this.state.ids[index],
      onDropIndexClick: this.onDropIndexClick,
      onReorderClick: this.onReorderClick,
      onReorder: this.onReorder,
      readonly,
    };
  }
}

ArrayField.propTypes = {
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.shape({
    'ui:options': PropTypes.shape({
      addable: PropTypes.bool,
      orderable: PropTypes.bool,
      removable: PropTypes.bool,
    }),
  }),
  idSchema: PropTypes.object,
  errorSchema: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  formData: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  registry: PropTypes.shape({
    widgets: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    ).isRequired,
    fields: PropTypes.objectOf(PropTypes.func).isRequired,
    definitions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
  }),
};

export default ArrayField;
