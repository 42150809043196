import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

const useFieldOnChangeDepsReset = (field, value) => {
  const { setValue } = useFormContext();

  const resetValue = useCallback(
    (field, value) => {
      setValue(field, value);
    },
    [setValue]
  );

  return useCallback(() => {
    typeof Array.isArray(field)
      ? field.map((f, index) =>
          resetValue(f, Array.isArray(value) ? value[index] : value)
        )
      : resetValue(field, value);
  }, [resetValue, field, value]);
};

export default useFieldOnChangeDepsReset;
