import { gql } from '@apollo/client';
import groupItemFragment from './groupItemFragment';

const mergerGroupFragment = gql`
  ${groupItemFragment}
  fragment MergerGroupFragment on Group {
    groupId
    sport {
      id
      name
    }
    booked {
      desc {
        id
        category
        tournament
        raceNumber
        competitors
      }
      isAuto
    }
    descType
    groupItems {
      ...GroupItemFragment
    }
    scheduled
    status
  }
`;

export default mergerGroupFragment;
