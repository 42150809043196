import React, { useContext } from 'react';
import { UiOptionsContext } from './UiOptionsProvider';
import classnames from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import usePaletteColor from '../../hooks/usePaletteColor';
import { Markup } from 'react-render-markup';

const useStyles = makeStyles(theme => ({
  belowZero: {
    color: theme.palette.error.main,
  },
  aboveZero: {
    color: theme.palette.success.main,
  },
  bold: {
    fontWeight: 600,
  },
  code: {
    whiteSpace: 'pre',
    fontFamily: 'monospace',
  },
  number: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
  },
}));

const FormattedValueText = ({ color: textColor, children }) => {
  const { value, colored } = useContext(UiOptionsContext);
  const classes = useStyles();
  const { format, fontWeight, type, formatArgs = {} } = value.label;
  const { color } = value;

  const text = format
    ? format(parseFloat(value.label.text), formatArgs)
    : value.label.text;

  const className = classnames({
    [classes.belowZero]: colored && value.data < 0,
    [classes.aboveZero]: colored && value.data > 0,
    [classes.bold]: fontWeight === 'bold',
    [classes.code]: type === 'code',
    [classes.number]: type === 'number',
  });

  const valueTextColor = usePaletteColor(textColor || color);

  return (
    <div
      className={className}
      style={{
        color: valueTextColor,
      }}
    >
      <Markup markup={children || text} />
    </div>
  );
};

export default FormattedValueText;
