import dayjs from 'dayjs';

export const getValueFromTimePicker = (date, fmt) => {
  return date && !isNaN(date.getTime())
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
    : undefined;
};

export const getValueForTimePicker = value =>
  value ? (typeof value === 'string' ? value.replace('Z', '') : value) : null;

export const formatToDatetimeString = value =>
  dayjs(value).utc().format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
