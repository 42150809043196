import React, { useCallback } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '../../v2/components/Button/Button';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import ColorPicker from '../../components/inputs/ColorPicker';
import { usePresets } from '../../providers/PresetsProvider';
import { Controller, useForm } from 'react-hook-form';
import get from 'lodash/get';

const useStyles = makeStyles({
  infoText: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      paddingTop: 0,
    },
    '&>*': {
      margin: '8px 0',
    },
  },
});

const PresetForm = ({ presetData = {}, onCancel, onSave, errorMessage }) => {
  const classes = useStyles();
  const { activePreset } = usePresets();
  const initialName = activePreset && `${activePreset.name} (Copy)`;

  const form = useForm({
    defaultValues: {
      name: (presetData && presetData.name) || initialName,
      color:
        (activePreset && activePreset.data && activePreset.data.color) ||
        '#BFCDDE',
    },
  });

  const { handleSubmit, control } = form;

  const onSubmit = useCallback(
    values => {
      onSave({
        ...presetData,
        operation_id: activePreset.operation_id,
        name: values.name,
        data: {
          ...presetData.data,
          color: values.color,
          initial_filters: get(presetData, ['data', 'initial_filters'], {}),
        },
      });
    },
    [onSave, presetData, activePreset]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <div className={classes.infoText}>
          <span>
            You are going to save filters, sorting and table settings to reuse
            them again later just in one click.
          </span>
          <span>Fill in the preset name</span>
        </div>

        <Controller
          control={control}
          name={'color'}
          render={({ field }) => <ColorPicker {...field} />}
        />

        <Controller
          name="name"
          required
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errorMessage}
              helperText={errorMessage}
              label={'Preset name'}
              type={'text'}
              variant="outlined"
              margin="dense"
              InputProps={{}}
              fullWidth={true}
              {...field}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          onClick={() => onCancel()}
          variant={'text'}
          color={'primary'}
        >
          Cancel
        </Button>
        <Button
          type={'submit'}
          disableRipple
          variant={'contained'}
          color={'primary'}
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default PresetForm;
