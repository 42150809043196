import { gql } from '@apollo/client';
import { CONTINGENCY_ITEM_FIELDS } from './RELATED_CONTINGENCIES_QUERY';

const getRelatedContingency = gql`
  ${CONTINGENCY_ITEM_FIELDS}
  query getRelatedContingency($id: ID!) {
    data: getRelatedContingency(id: $id) {
      id
      left {
        ...ContingencyItemFields
      }
      right {
        ...ContingencyItemFields
      }
      active
      rule_type
      user_email
      update_date
    }
  }
`;

export default getRelatedContingency;
