module.exports = {
  schema: {
    title: 'Files',
    type: 'object',
    properties: {
      file: {
        type: 'string',
        format: 'data-url',
        title: 'Single file',
      },
      files: {
        type: 'array',
        title: 'Multiple files',
        items: {
          type: 'string',
          format: 'data-url',
        },
      },
    },
  },
  uiSchema: {},
  formData: {},
};
