import { useContext } from 'react';
import MarginInput from '../../../components/inputs/MarginInput';
import { useRouteCheck } from '../../../hooks/useRouteCheck';
import { UiOptionsContext } from '../UiOptionsProvider';
import MarginField from './MarginField';

const MarginInputField = () => {
  const { value } = useContext(UiOptionsContext);

  // check schema for put request to consider field component
  const { isRouteProvided } = useRouteCheck();

  // TODO: decided to use check rights to display this component on FE side.
  return isRouteProvided ? (
    <MarginInput displayValue={value.data} fieldKey={value.key} />
  ) : (
    <MarginField />
  );
};

export default MarginInputField;
