import { gql } from '@apollo/client';
import { CREATE_TEMPLATE_RESULT_TYPE } from './createSourcesTemplate';

const createCoverageTemplate = gql`
  ${CREATE_TEMPLATE_RESULT_TYPE}
  mutation createCoverageTemplate(
    $parentId: TemplateId
    $input: CoverageTemplateInput!
  ) {
    data: createCoverageTemplate(parentId: $parentId, input: $input) {
      ...CreateTemplateResultType
    }
  }
`;

export default createCoverageTemplate;
