import { isEmpty } from 'lodash';

export const getLastParentCommands = ({
  allParentCommands = {},
  excludedKeys = [],
}) => {
  return Object.keys(allParentCommands)
    .reverse()
    .reduce((accumulator, currentValue) => {
      const currentCommands = allParentCommands[currentValue];

      if (
        (isEmpty(excludedKeys) || !excludedKeys.includes(currentValue)) &&
        !isEmpty(currentCommands) &&
        isEmpty(accumulator)
      ) {
        return currentCommands;
      }
      return accumulator;
    }, []);
};
