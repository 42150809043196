import { ActionCardTypeEnum } from '../components/ActionCards';
import get from 'lodash/get';
import {
  getFilters,
  getOperationIdByPath,
  getParams,
  toEndpoint,
} from '../utils/schemaHelper';
import { useContext, useState } from 'react';
import { RoutesContext } from '../providers/RoutesProvider';
import { useUiComponentSchema } from '../providers/UiComponentSchemaProvider';
import { DataContext } from '../providers/DataProvider';
import { usePresets } from '../providers/PresetsProvider';
import TableFiltersActionCard from '../v2/components/TableActionCards/TableFiltersActionCard';
import FiltersActionCard from '../uiComponents/Filters/FiltersActionCard';
import TablePresetsActionCard from '../v2/components/TableActionCards/TablePresetsActionCard';
import Presets from '../uiComponents/Filters/Presets';
import TableCreateActionCard from '../v2/components/TableActionCards/TableCreateActionCard';
import TableExportActionCard from '../v2/components/TableActionCards/TableExportActionCard';
import useLocalStorageTableConfig, {
  tableConfigKeys,
} from '../v2/hooks/useLocalStorageTableConfig';

const useActionCards = (path, actions) => {
  const { presets } = usePresets();
  const routes = useContext(RoutesContext);
  const operationId = getOperationIdByPath(routes, path);

  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(operationId);

  const isPresetsOpened = getTableConfigValue(tableConfigKeys.isPresetsOpened);
  const isFiltersOpened = getTableConfigValue(tableConfigKeys.isFiltersOpened);

  const [isPresetsExpanded, setPresetsExpanded] = useState(isPresetsOpened);
  const [isFiltersExpanded, setFiltersExpanded] = useState(isFiltersOpened);

  const uiSchema = useUiComponentSchema();

  const {
    data,
    params: requestPath,
    query,
    fetchData,
    params,
    error,
    loading,
  } = useContext(DataContext);

  const hasFilters =
    path &&
    !get(uiSchema, 'ui:options.hide_filters') &&
    !!getFilters(routes[path].parameters).length;

  const hidePresets = get(uiSchema, 'ui:options.hide_presets');

  const postHref = path && path.replace(/\/get$/, '/post');

  const createLink =
    routes[postHref] && `${toEndpoint({ path: postHref, params })}/post`;

  const exportAction =
    actions &&
    actions.find(
      action =>
        action.uiSchema && get(action, 'uiSchema.component') === 'Export'
    );

  const actionCards = [];

  if (!uiSchema) {
    return actionCards;
  }

  if (createLink) {
    actionCards.push({
      type: ActionCardTypeEnum.CREATE,
      key: ActionCardTypeEnum.CREATE,
      span: 2,
      height: 56,
      render: style => (
        <TableCreateActionCard style={style} link={createLink} />
      ),
    });
  }

  if (exportAction) {
    const params = getParams({
      link: exportAction.link,
      requestQuery: query,
      responseBody: data,
      requestPath,
    });

    actionCards.push({
      type: ActionCardTypeEnum.EXPORT,
      key: ActionCardTypeEnum.EXPORT,
      span: 2,
      height: 56,
      render: style => (
        <TableExportActionCard
          style={style}
          params={params.params}
          query={params.query}
          link={exportAction.link}
        />
      ),
    });
  }

  if (presets && presets.length >= 1 && hasFilters && !hidePresets) {
    actionCards.push({
      type: ActionCardTypeEnum.PRESETS,
      key: ActionCardTypeEnum.PRESETS,
      span: isPresetsExpanded ? 4 : 1,
      render: (style, onChangeHeight) => (
        <TablePresetsActionCard
          isOpen={isPresetsExpanded}
          onChange={expanded => {
            setPresetsExpanded(expanded);
            setTableConfigValue(tableConfigKeys.isPresetsOpened, expanded);
          }}
          onChangeHeight={onChangeHeight}
          style={style}
        >
          <Presets />
        </TablePresetsActionCard>
      ),
    });
  }

  if (hasFilters) {
    actionCards.push({
      type: ActionCardTypeEnum.FILTERS,
      key: ActionCardTypeEnum.FILTERS,
      span: isFiltersExpanded ? 4 : 1,
      render: (style, onChangeHeight) => (
        <TableFiltersActionCard
          style={style}
          isOpen={isFiltersExpanded}
          onChange={expanded => {
            setFiltersExpanded(expanded);
            setTableConfigValue(tableConfigKeys.isFiltersOpened, expanded);
          }}
          onChangeHeight={onChangeHeight}
        >
          <FiltersActionCard
            error={error}
            path={path}
            onRefresh={fetchData}
            dataLoading={loading}
          />
        </TableFiltersActionCard>
      ),
    });
  }

  return actionCards;
};

export default useActionCards;
