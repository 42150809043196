import React, { forwardRef } from 'react';
import MobileDeviceIcon from './devices/MobileDeviceIcon';
import DesktopDeviceIcon from './devices/DesktopDeviceIcon';
import RetailDeviceIcon from './devices/RetailDeviceIcon';
import UnknownDeviceIcon from './devices/UnknownDeviceIcon';
import makeStyles from '@mui/styles/makeStyles';

export const DeviceEnum = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  RETAIL: 'retail',
  UNKNOWN: 'unknown',
};

const IconByDevice = {
  [DeviceEnum.MOBILE]: MobileDeviceIcon,
  [DeviceEnum.DESKTOP]: DesktopDeviceIcon,
  [DeviceEnum.RETAIL]: RetailDeviceIcon,
  [DeviceEnum.UNKNOWN]: UnknownDeviceIcon,
};

const useStyles = makeStyles({
  deviceIconContainer: {
    display: 'flex',
    color: '#8496AB',
    '&>svg': {
      fontSize: '20px',
    },
  },
});

const DeviceIcon = forwardRef(({ name, ...props }, ref) => {
  const Icon = IconByDevice[name] || UnknownDeviceIcon;
  const classes = useStyles();

  return (
    <div ref={ref} {...props} className={classes.deviceIconContainer}>
      <Icon />
    </div>
  );
});

export default DeviceIcon;
