import zipObject from 'lodash/zipObject';

export const SCHEMA_URLS = (
  process.env.REACT_APP_SCHEMA_URLS ||
  process.env.STORYBOOK_REACT_APP_SCHEMA_URLS ||
  ''
).split(',');

export const SCHEMA_LABELS =
  process.env.REACT_APP_SCHEMA_LABELS &&
  process.env.REACT_APP_SCHEMA_LABELS.split(',');

export const SCHEMA_LABELS_MAP = zipObject(SCHEMA_URLS, SCHEMA_LABELS);

export const IS_SHOW_FEATURE_BRANCHES =
  !!process.env.REACT_APP_DYNAMIC_ENVS_URL;

export const SHOW_DEV_SECTION = !!process.env.REACT_APP_DYNAMIC_ENVS_URL;
