import React, { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import getBase64FromFile from '../../../utils/getBase64FromFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const FileUpload = ({ label, errorSchema, onChange, options: { accept } }) => {
  const [filename, setFilename] = useState('');
  const classes = useStyles();

  return (
    <Fragment>
      <Button fullWidth variant="contained" color="primary" component="label">
        {label}
        <AttachmentIcon className={classes.buttonIcon} />
        <input
          type="file"
          accept={accept}
          className={classes.input}
          onChange={e => {
            setFilename(e.target.files[0].name);
            getBase64FromFile(e.target.files[0]).then(onChange);
          }}
          onClick={event => {
            // To allow upload same files
            event.target.value = null;
          }}
        />
      </Button>
      {filename && <Typography>{filename}</Typography>}
      {errorSchema.__errors && errorSchema.__errors[0] && (
        <Typography color="error">
          {errorSchema.__errors && errorSchema.__errors[0]}
        </Typography>
      )}
    </Fragment>
  );
};

export default FileUpload;
