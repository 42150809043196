import FancyAutocomplete from '../FancyAutocomplete/FancyAutocomplete';
import GetTournamentsNames from '../../../gql/RiskManagement/queries/getTournamentsNames';
import { forwardRef } from 'react';

const FancyTournamentAutocomplete = forwardRef(({ ...props }, ref) => {
  return (
    <FancyAutocomplete
      ref={ref}
      gqlQuery={GetTournamentsNames}
      label={'Tournament'}
      valueKey={'value'}
      labelKey={'label'}
      multiple={false}
      {...props}
    />
  );
});

export default FancyTournamentAutocomplete;
