import {
  IS_SHOW_FEATURE_BRANCHES,
  SCHEMA_LABELS_MAP,
} from '../../constants/schemaUrls';
import { isUrlValid } from '../../utils/schemaHelper';
import { useSelector } from 'react-redux';
import { getActiveSchemaUrl, getSchemaUrls } from '../../reducers/schemaUrls';
import useFeatureBranches from './useFeatureBranches';

const useEnvironments = () => {
  const { feBranches, currentFeatureBranch, beEnvs, loading } =
    useFeatureBranches();

  const schemaUrls = useSelector(getSchemaUrls);
  const activeSchemaUrl = useSelector(getActiveSchemaUrl);

  const backendEnvs = IS_SHOW_FEATURE_BRANCHES
    ? beEnvs.map(({ name, url }) => {
        const schemaUrl = `${url}/api/v1/schema.json`;

        return {
          label: name,
          value: schemaUrl,
        };
      })
    : schemaUrls.map(url => ({
        label:
          SCHEMA_LABELS_MAP[url] ||
          (isUrlValid(url) ? new URL(url).hostname : url),
        value: url,
      }));

  return {
    featureBranches: feBranches,
    currentFeatureBranch,
    envs: backendEnvs,
    currentEnv: activeSchemaUrl,
    loading,
  };
};

export default useEnvironments;
