import toNumber from 'lodash/toNumber';
import toNumberOrNull from './ToNumberOrNull';

const IsValidRange = (value, min, max) => {
  value = toNumberOrNull(value);
  min = toNumber(min);
  max = toNumber(max);

  if (!value && value !== 0) {
    return true;
  } else {
    if (typeof min !== 'undefined' && value < min) return false;
    if (typeof max !== 'undefined' && value > max) return false;
  }

  return true;
};

export default IsValidRange;
