import { gql } from '@apollo/client';

const refreshToken = gql`
  mutation refreshToken($token: JwtToken!) {
    refreshToken(token: $token) {
      access_token
      refresh_token
    }
  }
`;

export default refreshToken;
