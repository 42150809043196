import React from 'react';
import CalendarIcon from '../../../icons/controls/CalendarIcon';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  datePickerAdornment: {
    marginRight: 12,
    marginTop: 2,
    cursor: 'pointer',

    '&>svg': {
      fontSize: 17,
    },
  },

  datePickerAdornmentDisabled: {
    pointerEvents: 'none',
  },
});
const DatePickerAdornment = ({ disabled, onClick }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.datePickerAdornment, {
        [classes.datePickerAdornmentDisabled]: disabled,
      })}
      onClick={onClick}
    >
      <CalendarIcon />
    </div>
  );
};

export default DatePickerAdornment;
