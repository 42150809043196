import AutocompleteSearchInput from '../Inputs/Search/AutocompleteSearchInput';
import SearchInput from '../Inputs/Search/SearchInput';
import TableActionCard from './TableActionCard';

const TableSearchInputActionCard = ({
  style,
  inline = false,
  label = 'Input',
  searchValue = '',
  setSearchValue = () => {},
  options,
  multiple,
  metaData,
}) => {
  const SearchComponent = options ? AutocompleteSearchInput : SearchInput;

  return (
    <TableActionCard style={style} inline={inline} search>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchComponent
          label={label}
          multiple={multiple}
          setSearchValue={setSearchValue}
          options={options}
        />
        {metaData && metaData()}
      </div>
    </TableActionCard>
  );
};

export default TableSearchInputActionCard;
