import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import getCoveragePreset from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePreset';
import getCoveragePresetNames from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePresetNames';
import {
  ApplyPreset,
  DisabledItemArea,
  DraggableSelectItem,
  DraggableSelectMenu,
  MenuCaption,
  MenuTitle,
  SelectContainer,
  SelectSearch,
  VirtualizedMenu,
} from '../../../components/';
import { useCoverageTemplateSelect } from '../../../hooks/coverage/select/useCoverageTemplateSelect';
import CoverageCategoryOption from '../Options/CoverageCategoryOption';
import { isBoolean, isEmpty, isEqual } from 'lodash';

const CoverageCategorySelect = ({
  templateId,
  selectKey,
  nodeIndex,
  options,
}) => {
  const { getValues, setValue } = useFormContext();

  const [lineType, template] = getValues(['lineType', 'template']);
  const {
    nodePath: { [lineType]: nodePath },
    setSelectedCoverageItemsConfig,
  } = useTreeSelectContext();

  const selectedId = nodePath.categoryId;

  const {
    displayOptions,
    dragDropDisabled,
    optionsFullListRef,
    isCoverageWriter,
    isPresetsAvailable,
    setDisplayOptions,
    onSearchFieldChange,
    saveReorderChanges,
    applyChanges,
  } = useCoverageTemplateSelect({
    options,
    getFormValues: getValues,
    setFormValue: setValue,
    pathToSelect: { sportId: nodePath.sportId },
    idKey: 'categoryId',
  });

  //apply changes for each option (to save order)
  useEffect(() => {
    setDisplayOptions(applyChanges());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineType, nodePath, template]);

  // TODO: implement into hook
  useEffect(() => {
    const template = getValues('template');

    const turnedOffOptions = options.reduce((accumulator, currentValue) => {
      const statusValue = { value: currentValue.status };

      if (!isEmpty(template)) {
        const itemSelector = {
          sportId: nodePath.sportId,
          categoryId: currentValue.categoryId,
        };
        const configValue = Object.values(template).find(value =>
          isEqual(value.nodeSelector, itemSelector)
        );

        if (configValue && isBoolean(configValue.status))
          statusValue.value = configValue.status;
      }

      if (statusValue.value === false) {
        return {
          ...accumulator,
          [currentValue.categoryId]: statusValue.value,
        };
      }
      return accumulator;
    }, {});

    setSelectedCoverageItemsConfig(curr => ({
      ...curr,
      [lineType]: {
        ...curr[lineType],
        category: { ...turnedOffOptions },
      },
    }));
  }, [
    options,
    lineType,
    setSelectedCoverageItemsConfig,
    getValues,
    nodePath.sportId,
  ]);

  // TODO: implement into hook
  useEffect(() => {
    const template = getValues('template');

    const turnedOffOptions = options.reduce((accumulator, currentValue) => {
      const statusValue = { value: currentValue.status };

      if (!isEmpty(template)) {
        const itemSelector = {
          sportId: nodePath.sportId,
          categoryId: currentValue.categoryId,
        };
        const configValue = Object.values(template).find(value =>
          isEqual(value.nodeSelector, itemSelector)
        );

        if (configValue && isBoolean(configValue.status))
          statusValue.value = configValue.status;
      }

      if (statusValue.value === false) {
        return {
          ...accumulator,
          [currentValue.categoryId]: statusValue.value,
        };
      }
      return accumulator;
    }, {});

    setSelectedCoverageItemsConfig(curr => ({
      ...curr,
      [lineType]: {
        ...curr[lineType],
        category: { ...turnedOffOptions },
      },
    }));
  }, [
    options,
    lineType,
    setSelectedCoverageItemsConfig,
    getValues,
    nodePath.sportId,
  ]);

  return (
    <SelectContainer style={{ width: '350px' }}>
      <SelectSearch
        label={`Search Category`}
        onSearchFieldChange={onSearchFieldChange}
      />
      <MenuCaption>
        <MenuTitle name={'Category'} />
        {isPresetsAvailable && (
          <ApplyPreset
            templateType={'coverage'}
            selectKey={'category'}
            menuOptions={options}
            sportId={nodePath.sportId}
            idKey={'categoryId'}
            getPresetNamesQuery={getCoveragePresetNames}
            getPresetQuery={getCoveragePreset}
          />
        )}
      </MenuCaption>
      <DraggableSelectMenu
        afterDrop={saveReorderChanges}
        options={displayOptions}
        setDisplayOptions={setDisplayOptions}
      >
        <VirtualizedMenu
          rowCount={displayOptions.length}
          itemRender={({ index }) => {
            const option = displayOptions[index];

            return (
              <Box position={'relative'}>
                {option.readOnly && <DisabledItemArea />}
                <DraggableSelectItem
                  selected={option.categoryId === selectedId}
                  index={index}
                  itemValue={option}
                  idKey={'categoryId'}
                  virtualized
                >
                  <CoverageCategoryOption
                    key={selectKey + '-' + option.categoryId}
                    templateId={templateId}
                    nodePath={nodePath}
                    nodeIndex={nodeIndex}
                    selected={option.categoryId === selectedId}
                    itemValue={option}
                    itemIndex={index}
                    lineType={lineType}
                    options={options}
                    optionsFullListRef={optionsFullListRef}
                    setDisplayOptions={setDisplayOptions}
                    dragDropDisabled={dragDropDisabled}
                    configurationDisabled={!isCoverageWriter}
                  />
                </DraggableSelectItem>
              </Box>
            );
          }}
        />
      </DraggableSelectMenu>
    </SelectContainer>
  );
};

export default CoverageCategorySelect;
