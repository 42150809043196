import React, { useCallback, useEffect, useReducer } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TransferListColumn from './TransferListColumn';
import ArrowRight from '../../../../icons/controls/ArrowRight';
import ArrowLeft from '../../../../icons/controls/ArrowLeft';
import useOperationIdData from '../../../../hooks/useOperationIdData';
import xorBy from 'lodash/xorBy';

const useStyles = makeStyles({
  transferList: {
    display: 'flex',
    minHeight: 400,
  },

  transferListControls: {
    marginTop: 96,
    padding: '0 8px',
  },

  transferButtons: {
    display: 'flex',
    flexDirection: 'column',
  },

  transferButton: {
    height: 100,
    width: 40,
    backgroundColor: '#1581FF',
    boxSizing: 'border-box',
    borderRadius: 50,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',

    '&+$transferButton': {
      marginTop: 8,
    },

    '& svg': {
      height: 16,
      color: '#fff',
      transition: 'color 0.2s ease-in-out',
    },

    '&:disabled': {
      backgroundColor: '#F3F4F7',
      '& svg': {
        color: 'rgba(132, 150, 171, 0.5)',
      },
    },
  },

  resizeHandle: {},

  emptyLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    maxWidth: 530,
    margin: '100px auto 0px',

    '&>svg': {
      position: 'absolute',
      right: 30,
      bottom: '100%',
    },

    '&>span': {
      color: '#8496AB',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.2px',
      textAlign: 'center',
      marginLeft: -14,
      width: '196px',
    },
  },
});

const getWithSelected = data => {
  return data.map(item => ({
    ...item,
    toggled: false,
  }));
};

const toggledFilter = item => item.toggled;

const TransferList = ({
  name,
  uiSchema = {},
  formContext: { formData, onChange },
  formDataArray: selectedItemKeys,
}) => {
  const classes = useStyles();

  const { 'ui:options': uiOptions } = uiSchema;
  const {
    operationId,
    params,
    group_key,
    info_key,
    desc_key,
    label_key,
    titleNotSelected,
    titleSelected,
    value_key,
  } = uiOptions;

  const { data, isLoading, emptyQueryParams, isEmptyQueryParams } =
    useOperationIdData(operationId, params, formData, formData, true);

  const [{ items }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'toggleGroup': {
          return {
            ...state,
            items: state.items.reduce((acc, item) => {
              if (
                action.group.find(
                  groupItem => item[value_key] === groupItem[value_key]
                )
              ) {
                return [
                  ...acc,
                  {
                    ...item,
                    toggled: action.toggled,
                  },
                ];
              }

              return [...acc, item];
            }, []),
          };
        }
        case 'toggle': {
          return {
            ...state,
            items: state.items.reduce((acc, item) => {
              if (item[value_key] === action.value) {
                return [
                  ...acc,
                  {
                    ...item,
                    toggled: action.toggled,
                  },
                ];
              }

              return [...acc, item];
            }, []),
          };
        }
        case 'load': {
          return {
            items: action.items,
          };
        }
        default: {
          return state;
        }
      }
    },
    {
      items: [],
    }
  );

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'load',
        items: getWithSelected(data.items),
      });
    }
  }, [data, group_key]);

  const setToggled = useCallback(
    (value, toggled = true) => {
      dispatch({
        type: 'toggle',
        toggled,
        value,
      });
    },
    [dispatch]
  );

  const setGroupToggled = useCallback(
    (group, toggled = true) => {
      dispatch({
        type: 'toggleGroup',
        toggled,
        group,
      });
    },
    [dispatch]
  );

  const chosenItems = items.filter(item =>
    selectedItemKeys.find(key => key === item[value_key])
  );

  const availableItems = xorBy(items, chosenItems, value_key);

  const chosenItemsToggled = chosenItems.filter(toggledFilter);
  const availableItemsToggled = availableItems.filter(toggledFilter);

  const transferAdd = useCallback(() => {
    setGroupToggled(availableItemsToggled, false);
    onChange({
      formData: {
        ...formData,
        [name]: [
          ...formData[name],
          ...availableItemsToggled.map(item => item[value_key]),
        ],
      },
    });
  }, [
    name,
    formData,
    onChange,
    availableItemsToggled,
    setGroupToggled,
    value_key,
  ]);

  const transferRemove = useCallback(() => {
    setGroupToggled(chosenItemsToggled, false);
    onChange({
      formData: {
        ...formData,
        [name]: formData[name].filter(item => {
          return !chosenItemsToggled.find(
            chosenItem => chosenItem[value_key] === item
          );
        }),
      },
    });
  }, [
    name,
    formData,
    onChange,
    chosenItemsToggled,
    setGroupToggled,
    value_key,
  ]);

  return (
    <div className={classes.transferList}>
      <TransferListColumn
        counterLabel={chosenItems.length}
        groupKey={group_key}
        items={chosenItems}
        setToggled={setToggled}
        setGroupToggled={setGroupToggled}
        title={titleSelected}
        valueKey={value_key}
        labelKey={label_key}
        descKey={desc_key}
        infoKey={info_key}
        emptyLabel={
          <div className={classes.emptyLabel}>
            <svg
              width="176"
              height="54"
              viewBox="0 0 176 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M175.352 4.3591C175.548 4.16465 175.549 3.84807 175.355 3.652L172.186 0.456951C171.992 0.260887 171.675 0.259584 171.479 0.454041C171.283 0.648498 171.282 0.965078 171.476 1.16114L174.293 4.00119L171.453 6.81795C171.257 7.01241 171.255 7.32898 171.45 7.52505C171.644 7.72111 171.961 7.72242 172.157 7.52796L175.352 4.3591ZM1.37153 53.3346C8.73118 45.1628 26.2692 32.8444 54.9255 22.6222C83.5689 12.4045 123.294 4.29129 174.998 4.50409L175.002 3.5041C123.191 3.29086 83.3502 11.4208 54.5895 21.6803C25.8418 31.9351 8.13746 44.3277 0.628466 52.6654L1.37153 53.3346Z"
                fill="#8496AB"
              />
            </svg>

            <span>Nothing added yet, choose items from the list</span>
          </div>
        }
      />
      <div className={classes.transferListControls}>
        <div className={classes.transferButtons}>
          <button
            onClick={transferAdd}
            type="button"
            className={classes.transferButton}
            disabled={!availableItemsToggled.length}
          >
            <ArrowLeft />
          </button>
          <button
            onClick={transferRemove}
            type="button"
            className={classes.transferButton}
            disabled={!chosenItemsToggled.length}
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <TransferListColumn
        loading={isLoading}
        counterLabel={
          chosenItems.length
            ? `${availableItems.length} left`
            : availableItems.length
        }
        groupKey={group_key}
        items={availableItems}
        setToggled={setToggled}
        setGroupToggled={setGroupToggled}
        title={titleNotSelected}
        valueKey={value_key}
        labelKey={label_key}
        descKey={desc_key}
        infoKey={info_key}
        isEmpty={isEmptyQueryParams && !isLoading}
        emptyLabel={
          <div className={classes.emptyLabel}>
            {isEmptyQueryParams ? (
              <span>Select {emptyQueryParams.join(',')} first</span>
            ) : (
              <span>Nothing to show</span>
            )}
          </div>
        }
      />
    </div>
  );
};

export default TransferList;
