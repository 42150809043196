import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import AddIconCircle from '../../icons/controls/AddIconCircle';

const styles = theme => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  filtersLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '0.5px',
  },
});

const AddFilterMenu = ({ filters, onFilterClick, classes, labelText }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <div>
      <Button
        aria-owns={anchorEl ? 'add-filter-menu' : null}
        aria-haspopup="true"
        onClick={({ target }) => setAnchorEl(target)}
      >
        <AddIconCircle className={classes.buttonIcon} />
        <Typography noWrap className={classes.filtersLabel}>
          {labelText}
        </Typography>
      </Button>
      <Menu
        id="add-filter-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {filters.map(f => (
          <MenuItem
            key={f.name}
            onClick={() => {
              onFilterClick(f);
              setAnchorEl(null);
            }}
          >
            {f.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withStyles(styles)(AddFilterMenu);
