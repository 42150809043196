import { forwardRef } from 'react';

const InlineDrugDropMenuContainer = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

export default InlineDrugDropMenuContainer;
