import { useLazyQuery } from '@apollo/client';
import { useCallback, useMemo, useRef } from 'react';
import getCoverageTemplateMarkets from '../../../../../../../gql/sportbook-config/templates/queries/getCoverageTemplateMarkets';
import getDuplicateCoverageTemplate from '../../../../../../../gql/sportbook-config/templates/queries/getDuplicateCoverageTemplate';
import useDebounced from '../../../../../../../hooks/useDebounced';

export const useCoverageMarketsFetch = ({
  allMarkets = [],
  setAllMarkets = () => {},
  tabKey = 'allMarkets',
  duplicate = false,
  templateId,
  nodePath,
  lineType,
  options,
  displayOptions = [],
  searchValue,
  onMarketsPageFetch,
}) => {
  const marketsPaginationRef = useRef({
    markets_limit: 30,
    markets_offset: 0,
  });

  const totalKey =
    tabKey === 'allMarkets' ? 'totalMarkets' : 'totalMainMarkets';
  const totalMarkets = useRef(options[totalKey]);

  const [fetchMarkets, { loading }] = useLazyQuery(
    duplicate ? getDuplicateCoverageTemplate : getCoverageTemplateMarkets,
    {
      onCompleted: data => {
        if (data.data.markets) {
          const loadedMarkets = data.data.markets['allMarkets'];
          if (marketsPaginationRef.current.markets_offset === 0) {
            setAllMarkets(loadedMarkets);
          } else {
            setAllMarkets(currentValue => [...currentValue, ...loadedMarkets]);
          }
          onMarketsPageFetch();
        } else {
          setAllMarkets([]);
        }
        totalMarkets.current = data.data.markets[totalKey];
      },
      fetchPolicy: 'no-cache',
    }
  );

  const debouncedFetchMarkets = useDebounced(fetchMarkets, 300);

  const fetchMarketsPage = useCallback(
    ({ searchValue = '' }) => {
      return debouncedFetchMarkets({
        variables: {
          templateId,
          path: nodePath,
          lineType,
          markets_limit: marketsPaginationRef.current.markets_limit,
          markets_offset: marketsPaginationRef.current.markets_offset,
          search_query: searchValue,
        },
      });
    },
    [debouncedFetchMarkets, lineType, nodePath, templateId]
  );

  const loadRows = useCallback(
    async ({ startIndex, stopIndex }) => {
      if (
        stopIndex + 29 > displayOptions.length &&
        displayOptions.length < totalMarkets.current &&
        marketsPaginationRef.current.markets_limit <= displayOptions.length
      ) {
        marketsPaginationRef.current.markets_offset = startIndex;
        fetchMarketsPage({ searchValue });
      }
    },
    [displayOptions.length, fetchMarketsPage, searchValue]
  );

  return useMemo(
    () => ({
      fetchMarkets,
      fetchMarketsPage,
      loadRows,
      loading,
      marketsPaginationRef,
      marketsLimit: marketsPaginationRef.current.markets_limit,
      marketsOffset: marketsPaginationRef.current.markets_offset,
    }),
    [fetchMarkets, fetchMarketsPage, loadRows, loading, marketsPaginationRef]
  );
};
