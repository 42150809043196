import { gql } from '@apollo/client';

const getSourcesPresetNames = gql`
  query getSourcesPresetNames($sportId: StrSportId!) {
    data: getSourcesPresetNames(sportId: $sportId) {
      id
      name
    }
  }
`;

export default getSourcesPresetNames;
