import { Box } from '@mui/material';
import clsx from 'clsx';
import BadgeField from '../../../Fields/BadgeField/BadgeField';
import Tooltip from '../../../Tooltip/Tooltip';
import useTreeSelectStyles from '../styles/TreeSelect.styles';

const FancyOptionTitle = ({
  name,
  code,
  countryIcon = true,
  total,
  underline,
  onClick = () => {},
  tooltip = false,
  textClassName,
  containerClassName,
  children,
}) => {
  const classes = useTreeSelectStyles();

  const TitleContainer = ({ className, children }) => {
    if (!tooltip) {
      return <Box className={className}>{children}</Box>;
    }

    return (
      <Tooltip overflowOnly={true} title={name}>
        <div className={className}>{children}</div>
      </Tooltip>
    );
  };

  return (
    <Box
      className={clsx(classes.fancySelectOption, containerClassName)}
      onClick={onClick}
    >
      <Box className={classes.flagField}>
        {countryIcon && (
          <Box
            sx={{
              backgroundImage: `url("https://files-immutable-4cbc033nbd3.sptpub.com/flags_by_code/${
                code || 'international'
              }.svg")`,
            }}
            className={classes.bigCountryIcon}
          />
        )}
        <Box className={textClassName}>
          <TitleContainer
            className={clsx(classes.fancyOptionLabelContainer, {
              [classes.underlinedContainer]: underline,
            })}
          >
            {children}
          </TitleContainer>
        </Box>
      </Box>
      {!!total && (
        <BadgeField style={{ borderRadius: '8px' }}>{total}</BadgeField>
      )}
    </Box>
  );
};

export default FancyOptionTitle;
