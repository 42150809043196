import FancyAutocomplete from '../FancyAutocomplete/FancyAutocomplete';
import getBoostedOddsMarkets from '../../../gql/Promo/queries/getBoostedOddsMarkets';
import { forwardRef, useMemo } from 'react';

const FancyMarketSelect = forwardRef(({ eventId, brandId, ...props }, ref) => {
  // "query: undefined" is used here to overwrite default { query: "" } param added by useAutocompleteQuery hook in FancyAutocomplete component
  const params = useMemo(
    () => ({ query: undefined, eventId, brandId }),
    [eventId, brandId]
  );
  const disabled = !eventId || !brandId;

  return (
    <FancyAutocomplete
      ref={ref}
      disabled={disabled}
      gqlQuery={getBoostedOddsMarkets}
      label={'Select Market'}
      valueKey={'uniqueId'}
      labelKey={'name'}
      uniqueKey={['id', 'name', 'specifiers']}
      multiple={false}
      params={params}
      skipQuery={disabled}
      {...props}
    />
  );
});

export default FancyMarketSelect;
