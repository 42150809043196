import FancySelect from '../FancySelect/FancySelect';
import { useCallback, useMemo, useState } from 'react';
import useAutocompleteQuery from '../../../../hooks/useAutocompleteQuery';
import { useProxyValue } from '../AutocompleteChips/AutocompleteChips';
import FancyAutocompleteAllOption from './FancyAutocompleteAllOption';
import isEmpty from 'lodash/isEmpty';

function compareOptions(a, b) {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }

  return 0;
}

const FancyMultiAutocomplete = ({
  name,
  gqlQuery,
  params,
  label,
  allLabel = 'All',
  valueKey = 'value',
  labelKey = 'label',
  value = {
    exclude: false,
    items: [],
  },
  onChange,
  keepSelectedOptions = true,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const selectValue = useMemo(() => {
    return value.items || [];
  }, [value]);

  const queryParams = useMemo(() => {
    return {
      ...params,
      [name]: selectValue.map(item => item),
    };
  }, [name, params, selectValue]);

  const {
    data: options = [],
    previousData: previousOptions = [],
    loading,
    setQuery: onChangeSearch,
  } = useAutocompleteQuery(gqlQuery, queryParams);

  const excludeMode = !!value.exclude;

  const onChangeExcludeMode = useCallback(
    exclude => {
      onChange({
        items: [],
        exclude,
      });
    },
    [onChange]
  );

  const onToggleExcludeMode = useCallback(() => {
    onChangeExcludeMode(!excludeMode);
  }, [excludeMode, onChangeExcludeMode]);

  const onChangeSelect = useCallback(
    selectValue => {
      const getUniqNewOptions = (oldOptions = []) => {
        return selectValue.reduce((acc, option) => {
          if (oldOptions.some(oldValue => oldValue.value === option.value)) {
            return acc;
          }
          return [...acc, option];
        }, []);
      };

      console.log('onchange', {
        exclude: excludeMode,
        items: selectValue.map(item => item[valueKey]),
      });
      keepSelectedOptions &&
        setSelectedOptions(currentValue => [
          ...currentValue,
          ...getUniqNewOptions(currentValue),
        ]);
      onChange({
        exclude: excludeMode,
        items: selectValue.map(item => item[valueKey]),
      });
    },
    [excludeMode, onChange, valueKey, setSelectedOptions, keepSelectedOptions]
  );

  const selectOptions = useMemo(() => {
    return (
      options.length ? options : previousOptions.length ? previousOptions : []
    ).sort(compareOptions);
  }, [options, previousOptions]);

  const isOptionSelected = useCallback(
    option => {
      const selected = !!selectValue.find(item => item === option[valueKey]);
      if (excludeMode) {
        return !selected;
      } else {
        return selected;
      }
    },
    [selectValue, excludeMode, valueKey]
  );

  const isOptionDisabled = useCallback(() => {}, []);

  const optionsForProxy = options.length
    ? options
    : previousOptions.length
    ? previousOptions
    : [];

  const [proxyValue, proxyOnChange] = useProxyValue(
    selectValue,
    () => {},
    keepSelectedOptions
      ? [...optionsForProxy, ...selectedOptions]
      : optionsForProxy,
    valueKey,
    true
  );

  const getInputPlaceholder = useCallback(() => {
    const labels =
      proxyValue.length && proxyValue.map(item => item[labelKey]).join(', ');

    if (excludeMode) {
      if (labels) {
        return `${allLabel}, excl. ${labels}`;
      }
      return allLabel;
    }

    if (labels) {
      return `${labels}`;
    }

    return !isEmpty(proxyValue) ? allLabel : '';
  }, [allLabel, proxyValue, excludeMode, labelKey]);

  return (
    <FancySelect
      loading={!selectOptions.length && loading}
      label={label}
      onChange={onChangeSelect}
      value={proxyValue}
      options={selectOptions}
      isOptionSelected={isOptionSelected}
      isOptionDisabled={isOptionDisabled}
      getInputPlaceholder={getInputPlaceholder}
      onChangeSearch={onChangeSearch}
      extraOptions={
        <>
          <FancyAutocompleteAllOption
            label={allLabel}
            onToggle={onToggleExcludeMode}
            toggled={excludeMode === true}
          />
        </>
      }
    />
  );
};

export default FancyMultiAutocomplete;
