import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const DownloadIcon = ({ className, disabled = false }) => {
  const color = disabled ? 'rgba(0, 0, 0, 0.26)' : '#1581FF';

  return (
    <SvgIcon
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1V11" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M2 15L14 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 8L8 11L5 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default DownloadIcon;
