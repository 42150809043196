import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  extraLinkIcon: {
    backgroundImage: `url("/icons/ExtraLinkIcon.svg")`,
    backgroundRepeat: 'no-repeat',
    height: 14,
    width: 14,
  },
});

const ExtraLinkIcon = ({ ...props }) => {
  const classes = useStyles();
  return <div {...props} className={classes.extraLinkIcon} />;
};

export default ExtraLinkIcon;
