import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';
import ExpandIcon from '../icons/controls/ExpandIcon';
import AccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
  AccordionSummaryRoot: {
    justifyContent: 'start',
    padding: 0,
    minHeight: 0,
    marginBottom: 1,
    marginTop: 1,

    '&$AccordionSummaryExpanded': {
      minHeight: 0,
    },
  },
  AccordionSummaryContent: {
    flexGrow: 0,
    margin: 0,

    '&$AccordionSummaryExpanded': {
      margin: 0,
    },
  },

  AccordionSummaryExpanded: {},

  AccordionSummaryExpandIcon: {
    padding: 0,
    marginLeft: 8,
  },

  expandIcon: {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },

  expansionDetailsRoot: {
    padding: 0,
  },

  AccordionRoot: {
    '&+$AccordionRoot $AccordionSummaryRoot': {
      marginTop: 24,
    },
  },
}));

const Accordion = ({ summary, expanded, onChange, children, ...props }) => {
  const classes = useStyles();

  return (
    <MuiAccordion
      {...props}
      expanded={expanded}
      onChange={onChange}
      classes={{
        root: classNames(
          classes.AccordionRoot,
          props.classes && props.classes.root
        ),
      }}
    >
      <AccordionSummary
        classes={{
          root: classes.AccordionSummaryRoot,
          content: classes.AccordionSummaryContent,
          expandIcon: classes.AccordionSummaryExpandIcon,
          expanded: classes.AccordionSummaryExpanded,
        }}
        expandIcon={<ExpandIcon className={classes.expandIcon} />}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.expansionDetailsRoot,
        }}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
