import { isNaN } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import TextField from '../../TextField';

const NumberInputWithResetToDefault = ({
  inputStyle,
  color = 'black',
  value,
  defaultValue,
  setValue,
  ...props
}) => {
  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {
    value && setShowResetButton(+value !== defaultValue);
  }, [value, defaultValue]);

  const onChange = useCallback(
    e => {
      const value = +e.target.value;
      if (!isNaN(value)) {
        setValue(value);
        setShowResetButton(value !== defaultValue);
      }
    },
    [defaultValue, setShowResetButton, setValue]
  );

  const onResetToDefault = useCallback(() => {
    setValue(defaultValue);
    setShowResetButton(false);
  }, [defaultValue, setValue]);

  return (
    <TextField
      inputStyle={{ ...inputStyle, color: !showResetButton && color }}
      value={value}
      onChange={onChange}
      onResetToDefault={onResetToDefault}
      withResetToDefault={showResetButton}
      InputLabelProps={{
        style: {
          overflow: 'hidden',
        },
      }}
      {...props}
    />
  );
};

export default NumberInputWithResetToDefault;
