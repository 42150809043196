import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import NumberField from './NumberField';
import toNumberOrNull from './utils/ToNumberOrNull';
import isValidRange from './utils/IsValidRange';
import isNil from 'lodash/isNil';

const useStyles = makeStyles({
  pickerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  pickerInputs: {
    display: 'flex',

    '&>*+*': {
      marginLeft: 16,
    },
  },

  pickerFooter: {
    display: 'flex',
    marginTop: 16,
    justifyContent: 'space-between',
  },

  errorText: {
    letterSpacing: '0.5px',
    fontSize: '11px',
    lineHeight: '14px',
    color: '#FF000F',
    fontWeight: 500,
  },

  applyButton: {
    marginLeft: 'auto',
  },
});

const NumberRangePicker = ({
  focusRef,
  secondFocusRef,
  setValidationError,
  clearErrors,
  value = {},
  onChange,
  minFrom,
  maxFrom,
  minTo,
  maxTo,
  helperText,
}) => {
  const classes = useStyles();

  const buttonRef = useRef(null);
  const [numberRange, setNumberRange] = useState(value);

  const rangeFrom = numberRange.rangeFrom;
  const rangeTo = numberRange.rangeTo;

  const isValidFrom = useMemo(
    () => isValidRange(rangeFrom, minFrom, maxFrom),
    [rangeFrom, minFrom, maxFrom]
  );
  const isValidTo = useMemo(
    () => isValidRange(rangeTo, minTo, maxTo),
    [rangeTo, minTo, maxTo]
  );

  const isValidValues = isValidFrom && isValidTo;
  const isInvalidOrder =
    rangeTo && rangeFrom && parseInt(rangeFrom) > parseInt(rangeTo);
  const isInvalid = !isValidValues || isInvalidOrder;

  useEffect(() => {
    rangeTo && rangeFrom && isInvalid ? setValidationError() : clearErrors();
  }, [
    isInvalid,
    setValidationError,
    clearErrors,
    minFrom,
    maxTo,
    rangeFrom,
    rangeTo,
  ]);

  // useEffect(() => {
  //   setNumberRange(value);
  // }, [value, setNumberRange]);

  const handleApply = useCallback(() => {
    isValidValues &&
      onChange({
        rangeFrom: toNumberOrNull(rangeFrom),
        rangeTo: toNumberOrNull(rangeTo),
      });
  }, [onChange, rangeFrom, rangeTo, isValidValues]);

  const handleFocusSecond = useCallback(() => {
    if (secondFocusRef.current) {
      secondFocusRef.current.focus();
      secondFocusRef.current.select();
    }
  }, [secondFocusRef]);

  return (
    <div className={classes.pickerContainer}>
      <div className={classes.pickerInputs}>
        <NumberField
          ref={focusRef}
          error={!isValidFrom}
          label={'From'}
          value={numberRange.rangeFrom}
          onComplete={handleFocusSecond}
          onChange={rangeFrom =>
            setNumberRange({
              ...numberRange,
              rangeFrom,
            })
          }
        />
        <NumberField
          ref={secondFocusRef}
          error={!isValidTo}
          label={'To'}
          value={numberRange.rangeTo}
          onComplete={handleApply}
          onChange={rangeTo =>
            setNumberRange({
              ...numberRange,
              rangeTo,
            })
          }
        />
      </div>

      <div className={classes.pickerFooter}>
        <span className={classes.errorText}>{isInvalid && helperText}</span>

        <Button
          disabled={isInvalid}
          className={classes.applyButton}
          ref={buttonRef}
          onClick={handleApply}
          color="primary"
          disableRipple
          variant={'contained'}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default NumberRangePicker;
