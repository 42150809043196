import TextField from '@mui/material/TextField';
import React, { forwardRef, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  numberInput: {
    height: 40,
    boxSizing: 'border-box',
    appearance: 'textfield',

    '&::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    '&::-webkit-inner-outer-button': {
      appearance: 'none',
    },
  },
});

const NumberField = forwardRef(
  ({ error, min, max, onComplete, step = 1, value, onChange, label }, ref) => {
    const classes = useStyles();

    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        onComplete && onComplete();
      }
    };

    const handleChange = useCallback(
      value => {
        onChange(value);
      },
      [onChange]
    );

    return (
      <TextField
        onKeyDown={handleKeyDown}
        label={label}
        inputProps={{
          min,
          max,
          step,
          ref,
        }}
        InputProps={{
          classes: {
            input: classes.numberInput,
          },
        }}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
        }}
        error={error}
        value={value}
        type={'number'}
        margin={'dense'}
        variant="outlined"
        onChange={e => handleChange(e.target.value)}
        onFocus={() => {}}
      />
    );
  }
);

export default NumberField;
