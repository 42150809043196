import { gql } from '@apollo/client';

const COMPETITORS_AUTOCOMPLETE_QUERY = gql`
  query getCompetitors($sportId: SportId!, $query: String) {
    data: getCompetitors(sport_id: $sportId, name: $query) {
      id
      name
    }
  }
`;

export default COMPETITORS_AUTOCOMPLETE_QUERY;
