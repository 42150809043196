import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NestedTableComponent from './NestedTableComponent';

const useStyles = makeStyles({
  tabsRoot: {
    backgroundColor: '#E6EAEE',
    borderRadius: 4,
    padding: 0,
    minHeight: 'initial',
  },
  tabContent: {
    marginTop: 8,
  },
  tabRoot: {
    transition: 'background-color 0.2s ease-in',
    backgroundColor: '#E6EAEE',
    display: 'flex',
    alignItems: 'center',
    minHeight: 'initial',
    height: 32,

    '&:hover:not($tabSelected)': {
      backgroundColor: '#1581FF',
      opacity: 1,

      '& $tabWrapper': {
        color: '#fff',
      },
    },

    '&$tabSelected': {
      backgroundColor: '#fff!important',
      borderRadius: 0,

      '& $tabWrapper': {
        color: '#031224',
      },
    },
  },
  tabWrapper: {
    transition: 'color 0.2s ease-in',
    color: '#031224',
    textTransform: 'capitalize',
    letterSpacing: '0.5px',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
  },
  tabSelected: {},
});

const NestedTableTabs = ({ items }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Tabs
        classes={{
          root: classes.tabsRoot,
        }}
        value={activeTab}
        onChange={(e, value) => {
          setActiveTab(value);
        }}
        component={'div'}
      >
        {items.map((item, index) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              key={item.title}
              value={index}
              label={item.label}
            />
          );
        })}
      </Tabs>
      <div className={classes.tabContent}>
        <NestedTableComponent component={items[activeTab]} />
      </div>
    </>
  );
};

export default NestedTableTabs;
