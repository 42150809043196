import React from 'react';
import { SvgIcon } from '@mui/material';

const DesktopDeviceIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 20 11'} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1C2 0.447715 2.44772 0 3 0H17C17.5523 0 18 0.447715 18 1V9H20V10C20 10.5523 19.5523 11 19 11H1C0.447716 11 0 10.5523 0 10V9H2V1ZM3 9H7C7 9.55228 7.44772 10 8 10H12C12.5523 10 13 9.55228 13 9H17V1H3V9Z"
      />
    </SvgIcon>
  );
};

export default DesktopDeviceIcon;
