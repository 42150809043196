import React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
  textFieldContainer: {
    position: 'relative',
  },
  textFieldRoot: {
    maxWidth: 400,
  },
  numberFieldRoot: {
    width: 80,
  },
  textFieldRootMultiline: {
    maxWidth: 'initial',
    height: 'initial',
  },
  textFieldLimitLabel: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    position: 'absolute',
    right: 12,
    marginTop: -23,
    color: theme.palette.grey.main,
    backgroundColor: '#F7F7F7',
    paddingLeft: 4,
    borderTopLeftRadius: 4,
  },
  formHelperTextRoot: {
    marginLeft: '8px 0 0 10px',
    fontWeight: 500,
    fontSize: '11px',
    letterSpacing: '0.5px',
    color: '#8496AB',
  },
  textInputWithLimit: {
    paddingBottom: '16px!important',
  },
}));

const BaseInput = props => {
  const classes = useStyles();

  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    options,
    schema,
    errorSchema,
    formContext,
    registry,
    multiline,
    maxLength,
    ...inputProps
  } = props;

  const type = options.inputType || inputProps.type || 'text';

  let _onChange = ({ target: { value } }) => {
    return props.onChange(value === '' ? options.emptyValue : value);
  };

  let _onBlur = onBlur && (event => onBlur(inputProps.id, event.target.value));
  let _onFocus =
    onFocus && (event => onFocus(inputProps.id, event.target.value));

  if (type === 'select') {
    _onChange = onChange;
    _onBlur = onBlur;
    _onFocus = onFocus;
  }

  return (
    <div className={classes.textFieldContainer}>
      <TextField
        margin={'dense'}
        variant="outlined"
        fullWidth
        disabled={disabled}
        autoFocus={autofocus}
        value={value == null ? '' : value}
        error={
          !!(errorSchema && errorSchema.__errors && errorSchema.__errors.length)
        }
        helperText={
          (errorSchema && errorSchema.__errors && errorSchema.__errors[0]) ||
          options.help ||
          null
        }
        FormHelperTextProps={{
          classes: {
            root: classes.formHelperTextRoot,
          },
        }}
        classes={{
          root: classNames(classes.textFieldRoot, {
            [classes.textFieldRootMultiline]: multiline,
          }),
          input: {},
        }}
        InputProps={{
          classes: {
            root: {
              [classes.textInputWithLimit]: multiline && maxLength,
            },
          },
          readOnly: readonly,
        }}
        inputProps={{
          maxLength,
        }}
        {...inputProps}
        multiline={multiline}
        type={type}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
      />

      {maxLength && multiline && (
        <div className={classes.textFieldLimitLabel}>
          {(value && value.length) || 0}/{maxLength}
        </div>
      )}
    </div>
  );
};

BaseInput.defaultProps = {
  type: 'text',
  required: false,
  disabled: false,
  readonly: false,
  autofocus: false,
};

export default BaseInput;
