import React, { lazy, Suspense } from 'react';
import GlobalLoading from './GlobalLoading';
import ErrorBoundary from './ErrorBoundary';

const InnerECharts = lazy(() => import('./InnerECharts'));

const ECharts = props => (
  <ErrorBoundary>
    <Suspense fallback={<GlobalLoading />}>
      <InnerECharts {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default ECharts;
