import React from 'react';
import BadgeField from './BadgeField';
import LinkField from './LinkField';

const BadgeLinkField = () => {
  return (
    <LinkField>
      <BadgeField />
    </LinkField>
  );
};

export default BadgeLinkField;
