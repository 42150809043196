import PresetCard from './PresetCard';
import React, { useCallback, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { usePresets } from '../../providers/PresetsProvider';

const useStyles = makeStyles(() => ({
  presetCardsRoot: {
    minWidth: '100%',
    position: 'relative',
    marginLeft: -24,
    marginRight: -24,
    overflow: 'hidden',
    paddingTop: 12,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 16,
      background: 'linear-gradient(-90deg, rgba(255, 255, 255, 0), #fff)',
      height: '100%',
      left: 8,
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 16,
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0), #fff)',
      height: '100%',
      right: 8,
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
    },
  },
  presetCards: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const Presets = () => {
  const classes = useStyles();
  const containerRef = useRef();

  const {
    presets,
    setActivePresetId,
    activePreset,
    removePreset,
    reorderPresets,
  } = usePresets();

  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onApplyPreset = useCallback(
    preset => {
      setActivePresetId(preset.id);
    },
    [setActivePresetId]
  );

  const [isDragging, setIsDragging] = useState(false);

  const onBeforeDragStart = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const onDragEnd = useCallback(
    result => {
      setIsDragging(false);

      if (!result.destination) {
        return;
      }

      const ordered = reorder(
        presets,
        result.source.index,
        result.destination.index
      );

      reorderPresets(ordered);
    },
    [setIsDragging, reorder, presets, reorderPresets]
  );

  const deletePresetHandle = useCallback(
    id => {
      removePreset(id);
    },
    [removePreset]
  );

  return (
    <DragDropContext
      onBeforeDragStart={onBeforeDragStart}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="droppable" direction="horizontal">
        {provided => (
          <div className={classes.presetCardsRoot} ref={containerRef}>
            <div
              className={classes.presetCards}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {presets &&
                presets.map((preset, index) => {
                  return (
                    <Draggable
                      key={preset.id}
                      draggableId={preset.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          provided.draggableProps.style.left =
                            provided.draggableProps.style.left -
                            (containerRef.offsetLeft -
                              containerRef.offsetWidth / 2);
                          provided.draggableProps.style.top =
                            provided.draggableProps.style.top -
                            (containerRef.offsetTop -
                              containerRef.offsetHeight / 2);
                        }
                        return (
                          <PresetCard
                            isDragging={isDragging}
                            draggableProvided={provided}
                            draggableSnapshot={snapshot}
                            isActive={
                              activePreset && preset.id === activePreset.id
                            }
                            preset={preset}
                            onApply={() => {
                              onApplyPreset(preset);
                            }}
                            onRemove={() => deletePresetHandle(preset.id)}
                          />
                        );
                      }}
                    </Draggable>
                  );
                })}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Presets;
