import Box from '@mui/material/Box';

const PopularNowTextPreview = ({ src, fields: [title] }) => {
  return (
    <Box
      sx={{
        background: `url(${src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: '8px',
        borderRadius: '12px',

        width: '128px',
        height: '88px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          fontFamily: 'IBM Plex Sans',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.03em',
          textShadow: '0px 4px 8px rgba(0, 0, 0, 0.6)',
          color: '#fff',
          overflowWrap: 'anywhere',
        }}
      >
        {title}
      </Box>
    </Box>
  );
};

export default PopularNowTextPreview;
