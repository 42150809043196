import BooleanField from '../../components/Fields/BooleanField/BooleanField';
import dayjs from 'dayjs';
import Scrollbar from '../../../components/Scrollbar/Scrollbar';
import CopyField from '../../components/Fields/CopyField/CopyField';
import EmptyField from '../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../components/Fields/LinkField/LinkField';
import StringField from '../../components/Fields/StringField/StringField';
import Table from '../../components/Table/Table';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import { format, parseISO, toDate } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DateField from '../../components/Fields/DateField/DateField';

export const COLUMN = {
  ID: 'id',
  STATUS: 'status',
  LIVE: 'live',
  ROLLBACK: 'rollback',
  FORCED: 'forced',
  SPORT: 'sport',
  CATEGORY: 'category',
  SEASON: 'season',
  MARKET: 'market',
  K: 'k',
  BETRADAR_ID: 'betradar_id',
  BETRADAR_H2H: 'betradar_h2h',
  SCORE: 'score',
  PLAYER_ID: 'player_id',
  EVENT: 'event',
  MARKET_ID: 'market_id',
  OUTCOME: 'outcome',
  TOURNAMENT: 'tournament',
  STAGE_ID: 'stage_id',
  SEASON_ID: 'season_id',
  VOID_REASON_N: 'void_reason_n',
  VOID_REASON: 'void_reason',
  MARKET_PROVIDERS: 'market_provider',
  SETTLED_AT: 'settled_at',
  EVENT_SCHEDULED: 'event_time',
  IS_BET_BUILDER: 'isBetBuilder',
};

export const columnsWidthConfig = {
  [COLUMN.STATUS]: 0.35,
  [COLUMN.SETTLED_AT]: 0.5,
  [COLUMN.EVENT_SCHEDULED]: 1,
  [COLUMN.LIVE]: 0.3,
  [COLUMN.ROLLBACK]: 0.4,
  [COLUMN.FORCED]: 0.4,
  [COLUMN.SPORT]: 0.7,
  [COLUMN.CATEGORY]: 0.5,
  [COLUMN.TOURNAMENT]: 0.6,
  [COLUMN.MARKET]: 1,
  [COLUMN.OUTCOME]: 0.6,
  [COLUMN.K]: 0.3,
  [COLUMN.SCORE]: 0.3,
  [COLUMN.EVENT]: 0.75,
  [COLUMN.BETRADAR_ID]: 0.5,
  [COLUMN.VOID_REASON]: 0.6,
  [COLUMN.SEASON]: 0.4,
  [COLUMN.MARKET_PROVIDERS]: 0.75,
  [COLUMN.BETRADAR_H2H]: 0.75,
  [COLUMN.PLAYER_ID]: 0.5,
  [COLUMN.MARKET_ID]: 0.5,
  [COLUMN.STAGE_ID]: 0.5,
  [COLUMN.SEASON_ID]: 0.5,
  [COLUMN.VOID_REASON_N]: 0.75,
};

export const columnsOrderConfig = [
  COLUMN.STATUS,
  COLUMN.SETTLED_AT,
  COLUMN.EVENT_SCHEDULED,
  COLUMN.LIVE,
  COLUMN.ROLLBACK,
  COLUMN.FORCED,
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.K,
  COLUMN.SCORE,
  COLUMN.EVENT,
  COLUMN.BETRADAR_ID,
  COLUMN.VOID_REASON,
  COLUMN.SEASON,
  COLUMN.MARKET_PROVIDERS,
  COLUMN.BETRADAR_H2H,
  COLUMN.PLAYER_ID,
  COLUMN.MARKET_ID,
  COLUMN.STAGE_ID,
  COLUMN.SEASON_ID,
  COLUMN.VOID_REASON_N,
  COLUMN.IS_BET_BUILDER,
];

export const initialToggledColumns = [
  COLUMN.STATUS,
  COLUMN.SETTLED_AT,
  COLUMN.EVENT_SCHEDULED,
  COLUMN.LIVE,
  COLUMN.ROLLBACK,
  COLUMN.FORCED,
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.K,
  COLUMN.SCORE,
  COLUMN.EVENT,
  COLUMN.BETRADAR_ID,
  COLUMN.VOID_REASON,
  COLUMN.SEASON,
  COLUMN.MARKET_PROVIDERS,
  // COLUMN.BETRADAR_H2H,
  // COLUMN.PLAYER_ID,
  // COLUMN.MARKET_ID,
  // COLUMN.STAGE_ID,
  // COLUMN.SEASON_ID,
  // COLUMN.VOID_REASON_N,
  // COLUMN.IS_BET_BUILDER
];

const disabledColumnsForOperator = [
  COLUMN.MARKET_PROVIDERS,
  COLUMN.BETRADAR_ID,
];

const BetHistoryDetailsTable = ({
  data,
  loading,
  isOperator = false,
  onHeightChanged,
}) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Status',
      key: COLUMN.STATUS,
      render: ({ status }) => {
        return <StringField>{status}</StringField>;
      },
    },

    {
      label: 'Settled At',
      key: COLUMN.SETTLED_AT,
      render: ({ settledAt }) => {
        if (settledAt) {
          const UTCDate = format(
            utcToZonedTime(parseISO(settledAt), 'UTC'),
            'yyyy/MM/dd HH:mm:ss'
          );

          return <StringField>{UTCDate}</StringField>;
        }

        return <EmptyField />;
      },
    },
    {
      label: 'Event Scheduled',
      key: COLUMN.EVENT_SCHEDULED,
      render: ({ eventScheduled }) => {
        if (eventScheduled) {
          const UTCDate = format(
            utcToZonedTime(parseISO(eventScheduled), 'UTC'),
            'yyyy/MM/dd HH:mm:ss'
          );

          return <StringField>{UTCDate}</StringField>;
        }

        return <EmptyField />;
      },
    },
    {
      label: 'Live',
      key: COLUMN.LIVE,
      style: { textAlign: 'center' },
      render: ({ live }) => {
        return <BooleanField active={live} />;
      },
    },
    {
      label: 'Rollback',
      key: COLUMN.ROLLBACK,
      style: { textAlign: 'center' },
      render: ({ rollback }) => {
        return <BooleanField active={rollback} />;
      },
    },
    {
      label: 'Forced',
      key: COLUMN.FORCED,
      style: { textAlign: 'center' },
      render: ({ forced }) => {
        return <BooleanField active={forced} />;
      },
    },
    {
      label: 'Sport',
      key: COLUMN.SPORT,
      style: { textAlign: 'center' },
      render: ({ sport }) => {
        return <StringField>{sport}</StringField>;
      },
    },
    {
      label: 'Category',
      key: COLUMN.CATEGORY,
      render: ({ category }) => {
        return category ? (
          <StringField>{category}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Season',
      key: COLUMN.SEASON,
      render: ({ season }) => {
        return season ? <StringField>{season}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'Market',
      key: COLUMN.MARKET,
      render: ({ market }) => {
        return market ? <StringField>{market}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'K',
      key: COLUMN.K,
      style: { textAlign: 'center' },
      justifyContent: 'center',
      render: ({ k }) => {
        return k ? <StringField>{k}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'Betradar Id',
      key: COLUMN.BETRADAR_ID,
      render: ({ betradarId }) => {
        return <StringField>{betradarId}</StringField>;
      },
    },
    {
      label: 'Betradar H 2 H',
      key: COLUMN.BETRADAR_H2H,
      render: ({ betradarH2H }) => {
        return betradarH2H ? (
          <StringField>{betradarH2H}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Score',
      key: COLUMN.SCORE,
      render: ({ score }) => {
        return score ? <StringField>{score}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'Player Id',
      key: COLUMN.PLAYER_ID,
      render: ({ playerId }) => {
        return playerId ? (
          <StringField>{playerId}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Event',
      key: COLUMN.EVENT,
      render: ({ event, eventId }) => {
        return (
          <StringField title={event}>
            <CopyField value={eventId}>
              <LinkField href={`/events/${eventId}`}>{event}</LinkField>
            </CopyField>
          </StringField>
        );
      },
    },
    {
      label: 'Market Id',
      key: COLUMN.MARKET_ID,
      render: ({ marketId }) => {
        return marketId ? (
          <StringField>{marketId}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Outcome',
      key: COLUMN.OUTCOME,
      render: ({ outcome }) => {
        return outcome ? <StringField>{outcome}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'Tournament',
      key: COLUMN.TOURNAMENT,
      render: ({ tournament }) => {
        return tournament ? (
          <StringField>{tournament}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Stage Id',
      key: COLUMN.STAGE_ID,
      render: ({ stageId }) => {
        return stageId ? <StringField>{stageId}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'Season Id',
      key: COLUMN.SEASON_ID,
      render: ({ seasonId }) => {
        return seasonId ? (
          <StringField>{seasonId}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Void Reason N',
      key: COLUMN.VOID_REASON_N,
      justifyContent: 'flex-end',
      render: ({ voidReasonN }) => {
        return voidReasonN ? (
          <StringField>{voidReasonN}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Void Reason',
      key: COLUMN.VOID_REASON,
      render: ({ voidReason }) => {
        return voidReason ? (
          <StringField>{voidReason}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Market Providers',
      key: COLUMN.MARKET_PROVIDERS,
      render: ({ marketProviders }) => {
        return marketProviders ? (
          <StringField>{marketProviders}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  const getColumnsForOperator = (columns, nameKey) => {
    return isOperator
      ? columns.filter(
          value =>
            !disabledColumnsForOperator.includes(
              nameKey ? value[nameKey] : value
            )
        )
      : columns;
  };

  return (
    <Table
      initialToggledColumns={getColumnsForOperator(initialToggledColumns)}
      loading={loading}
      data={data}
      columnsWidth={columnsWidthConfig}
      order={order}
      onReorder={onReorder}
      columns={getColumnsForOperator(columns, 'key')}
      scrollable
      adaptiveColumns
    />
  );
};

export default BetHistoryDetailsTable;
