import * as React from 'react';
import { useContext } from 'react';

export const TableSummaryContext = React.createContext(false);

const TableSummaryProvider = ({ children, summary }) => {
  return (
    <TableSummaryContext.Provider value={summary}>
      {children}
    </TableSummaryContext.Provider>
  );
};

export const useTableSummaryContext = () => useContext(TableSummaryContext);

export default TableSummaryProvider;
