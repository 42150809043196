import { createContext, useContext, useMemo } from 'react';
import { getUserToken } from '../../reducers/users';
import { useSelector } from 'react-redux';

export const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const token = useSelector(getUserToken);

  const providerValue = useMemo(() => {
    return {
      token,
    };
  }, [token]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
