import React, { useCallback, useState, useRef } from 'react';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import isElementOverflowed from '../../utils/isElementOverflowed';

const CustomTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
});

const Tooltip = ({ tooltipClassName, overflowOnly, children, ...rest }) => {
  const [renderTooltip, setRenderTooltip] = useState(false);
  const ref = useRef(null);

  const onMouseEnter = useCallback(() => {
    if (!overflowOnly) {
      setRenderTooltip(true);
    } else if (ref.current && isElementOverflowed(ref.current)) {
      setRenderTooltip(true);
    }
  }, [overflowOnly]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      style={{
        overflow: 'hidden',
      }}
      className={tooltipClassName}
      ref={ref}
    >
      {!renderTooltip && children}
      {renderTooltip && <CustomTooltip {...rest}>{children}</CustomTooltip>}
    </div>
  );
};

Tooltip.propTypes = {
  tooltipClassName: PropTypes.string,
  overflowOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Tooltip.defaultProps = {
  tooltipClassName: null,
  overflowOnly: false,
};

export default Tooltip;
