import React from 'react';
import EnumArrayFieldTemplate from './EnumArrayFieldTemplate';
import UiComponentSchemaProvider from '../../../providers/UiComponentSchemaProvider';
import SchemaProvider from '../../../providers/SchemaProvider';
import ArrayFieldTemplate from './ArrayFieldTemplate';

const ArrayFieldTemplateContainer = ({
  items,
  uiSchema,
  schema,
  formData,
  onAddClick,
  ...props
}) => {
  const { 'ui:widget': uiWidget } = uiSchema;

  return (
    <UiComponentSchemaProvider value={uiSchema}>
      <SchemaProvider value={schema}>
        {uiWidget === 'LanguageArray' ? (
          <EnumArrayFieldTemplate
            formData={formData}
            onAddClick={onAddClick}
            items={items}
          />
        ) : (
          <ArrayFieldTemplate
            formData={formData}
            onAddClick={onAddClick}
            items={items}
          />
        )}
      </SchemaProvider>
    </UiComponentSchemaProvider>
  );
};

export default ArrayFieldTemplateContainer;
