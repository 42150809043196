import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LockIcon = props => {
  return (
    <SvgIcon viewBox={'0 0 10 13'} {...props}>
      <rect y="6" width="10" height="7" rx="1" fill="#1581FF" />
      <path
        d="M9 3C9 1.34315 7.65685 0 6 0H4C2.34315 0 1 1.34315 1 3V5H3V3C3 2.44772 3.44772 2 4 2H6C6.55228 2 7 2.44772 7 3V5H9V3Z"
        fill="#1581FF"
      />
    </SvgIcon>
  );
};

export default LockIcon;
