import { FormProvider, useForm } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import FiltersMenu from './FiltersMenu';
import Filter from './Filter';
import Button from '../Button/Button';
import BookmarkIcon from '../../../icons/controls/BookmarkIcon';
import Stack from '@mui/material/Stack/Stack';
import { useCallback, useEffect, useMemo } from 'react';
import { useCreatePresetDialog } from '../../../providers/CreatePresetDialogProvider';
import FormFieldDepsProvider from '../Form/FormFieldDepsProvider';
import { isArray, isEmpty, isEqual } from 'lodash';
import useDebounced from '../../hooks/useDebounced';

export const isFilterEmpty = value => {
  if (!value) {
    return true;
  }

  if (isArray(value)) {
    return isEmpty(value);
  }

  if (typeof value === 'object') {
    const objectFilterEmptyValue = { rangeFrom: null, rangeTo: null };

    return isEqual(value, objectFilterEmptyValue);
  }
};

const useStyles = makeStyles(theme => ({
  filtersGrid: {
    paddingTop: 16,
    display: 'grid',
    gridColumnGap: 16,
    gridRowGap: 24,

    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    },
  },

  bookmarkIcon: {
    fontSize: '11px',
  },

  filtersBottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gridColumn: 1,
    gridColumnEnd: -1,
  },
}));

const Filters = ({
  hidePresets,
  initialValues,
  defaultValues,
  filters = [],
  extraFilters = true,
  onToggle = () => {},
  onSubmit = () => {},
  isDefaultPreset = true,
  onChangePresetFilters,
  hideAddButton = false,
}) => {
  const classes = useStyles();

  const defaultValuesFn = useMemo(() => {
    return async () => defaultValues;
  }, [defaultValues]);

  const methods = useForm({
    defaultValues: defaultValuesFn,
  });

  const {
    handleSubmit,
    formState,
    setValue,
    resetField,
    reset,
    watch,
    getValues,
  } = methods;
  const isDirty = formState.isDirty;

  const resetToDefault = useCallback(() => {
    reset(defaultValues);
    if (!isDefaultPreset && onChangePresetFilters) {
      onChangePresetFilters(defaultValues);
    }
  }, [reset, isDefaultPreset, onChangePresetFilters, defaultValues]);

  useEffect(() => {
    if (initialValues && defaultValues) {
      reset(defaultValues);
      reset(initialValues, {
        keepDefaultValues: true,
      });
    }
  }, [initialValues, reset, defaultValues]);

  const onSubmitCb = useMemo(() => {
    return handleSubmit(onSubmit);
  }, [onSubmit, handleSubmit]);

  const { open: openCreatePreset } = useCreatePresetDialog();

  const clearHiddenFilters = useCallback(
    filters => {
      const hiddenFilters = filters.filter(f => !f.toggled).map(f => f.name);

      hiddenFilters.forEach(filterName => resetField(filterName));
    },
    [resetField]
  );

  const onToggleFilter = useCallback(
    filters => {
      clearHiddenFilters(filters);
      onToggle(filters);
    },
    [onToggle, clearHiddenFilters]
  );

  const onFilterChange = useCallback(
    ({ oldValue, newValue }) => {
      if (
        !isDefaultPreset &&
        onChangePresetFilters &&
        !isEqual(oldValue, newValue)
      ) {
        onChangePresetFilters({ ...getValues() });
      }
    },
    [isDefaultPreset, getValues, onChangePresetFilters]
  );

  const debouncedFilterChange = useDebounced(onFilterChange, 1200);

  return (
    <FormProvider {...methods}>
      <form className={classes.filtersGrid} onSubmit={onSubmitCb}>
        {filters.map(
          ({
            name,
            fullWidth,
            label,
            component,
            toggled,
            initialValue,
            deps,
            hidden,
          }) =>
            !hidden &&
            toggled && (
              <Filter
                key={name}
                name={name}
                fullWidth={fullWidth}
                label={label}
                initialValue={initialValue}
                onChange={debouncedFilterChange}
              >
                {component ? (
                  deps ? (
                    <FormFieldDepsProvider
                      watch={watch}
                      deps={deps}
                      toggled={toggled}
                      getFilterValue={() => getValues(name)}
                      setFilterValue={value => setValue(name, value)}
                      resetFilter={() => resetField(name)}
                      isDefaultPreset={isDefaultPreset}
                    >
                      {component}
                    </FormFieldDepsProvider>
                  ) : (
                    component
                  )
                ) : null}
              </Filter>
            )
        )}
        {extraFilters && (
          <FiltersMenu
            hideAddButton={hideAddButton}
            filters={filters}
            onToggle={onToggleFilter}
          />
        )}

        <div className={classes.filtersBottomActions}>
          <Stack spacing={1} direction={'row'}>
            <Button
              disabled={!isDirty}
              color="primary"
              variant={'text'}
              onClick={resetToDefault}
            >
              Reset
            </Button>
            {!hidePresets && (
              <Button
                variant="contained"
                color="lightBlue"
                onClick={() => {
                  openCreatePreset(getValues());
                }}
              >
                <BookmarkIcon className={classes.bookmarkIcon} />
                Save as Preset
              </Button>
            )}

            <Button
              // disabled={dataLoading}
              type="submit"
              variant="contained"
              color="primary"
              data-cy={'apply-filters'}
            >
              Apply
            </Button>
          </Stack>
        </div>
      </form>
    </FormProvider>
  );
};

export default Filters;
