import useAutocompleteData from '../../../hooks/useAutocompleteData';
import Select from '../Select/Select';
import { forwardRef } from 'react';

const TemplateSelect = forwardRef(({ ...props }, ref) => {
  const { data: options = [] } = useAutocompleteData({
    url: '/api/v1/RiskManagementAdmin/suggestions/templates',
    withoutParams: true,
  });

  return (
    <Select
      ref={ref}
      label={'Template'}
      options={options}
      labelKey={'label'}
      valueKey={'value'}
      {...props}
    />
  );
});

export default TemplateSelect;
