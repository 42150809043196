import { createFilterOptions } from '@mui/material/Autocomplete';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useAutocompleteQuery from '../../../../hooks/useAutocompleteQuery';
import useAutocompleteData from '../../../hooks/useAutocompleteData';
import useDebounced from '../../../hooks/useDebounced';
import useKeyedArray from '../../../hooks/useKeyedArray';
import ChipList from '../../ChipList/ChipList';
import Autocomplete from '../Autocomplete';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';

export const getOptionLabel = option => {
  return option && option.name;
};

export const isOptionEqualToValue = valueKey => (option, value) => {
  return option.value[valueKey] === value[valueKey];
};

const getProxyOptionValue = (value, valueKey, options) => {
  return options.find(option => option && value && option[valueKey] === value);
};

const ChipTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
});

export const useProxyValue = (
  value,
  onChange,
  options,
  valueKey,
  multiple = false
) => {
  const proxyValue = useMemo(() => {
    return multiple
      ? !value || (value.length && !options.length)
        ? []
        : value
            .map(item => getProxyOptionValue(item, valueKey, options))
            .filter(proxyItem => !isUndefined(proxyItem)) // filter undefined results in case values is missed in option
      : getProxyOptionValue(value, valueKey, options) || null;
  }, [value, options, valueKey, multiple]);

  const proxyOnChange = useCallback(
    value => {
      onChange(
        multiple
          ? (value || []).map(v => v[valueKey])
          : value && value[valueKey]
      );
    },
    [onChange, valueKey, multiple]
  );

  return useMemo(
    () => [proxyValue, proxyOnChange],
    [proxyValue, proxyOnChange]
  );
};

export const AutocompleteChipsValueProxy = ({
  value,
  onChange,
  valueKey,
  multiple,
  children,
  ...props
}) => {
  const [cachedOptions, setCachedOptions] = useState([]);

  const [proxyValue, proxyOnChange] = useProxyValue(
    value,
    onChange,
    cachedOptions || [],
    valueKey,
    multiple
  );

  const onChangeCb = useCallback(
    value => {
      setCachedOptions(multiple ? [...value] : [value]);

      proxyOnChange(value);
    },
    [proxyOnChange, multiple]
  );

  return cloneElement(children, {
    value: proxyValue,
    onChange: onChangeCb,
    multiple,
    ...props,
  });
};

const AutocompleteChips = forwardRef(
  (
    {
      dataKey,
      name,
      value,
      onChange,
      helperText,
      error,
      disabled = false,
      label = 'Search',
      required,
      url,
      gqlQuery,
      params: additionalParams,
      valueKey = 'value',
      prepareOptions,
      proxyValue = true,
      groupBy = option => option.type,
      getOptionLabel = option => {
        return option && option.name;
      },
      fullWidth = true,
      filterOptions: overrideFilterOptions,
      disableFilterOptions = false,
      hideSelected = true,
    },
    ref
  ) => {
    const [searchQuery, setSearchQuery] = useState('');

    const debouncedSetSearchQuery = useDebounced(setSearchQuery, 200);

    const [innerInputValue, setInnerInputValue] = useState('');

    useEffect(() => {
      debouncedSetSearchQuery(innerInputValue);
    }, [debouncedSetSearchQuery, innerInputValue]);

    const params = useMemo(() => {
      return {
        query: searchQuery,
        ...additionalParams,
      };
    }, [searchQuery, additionalParams]);

    const idList = useMemo(() => {
      if (proxyValue) {
        return value;
      }

      return value ? value.map(item => item[valueKey]) : [];
    }, [proxyValue, value, valueKey]);

    const { data: options = [], loading } = gqlQuery
      ? useAutocompleteQuery(gqlQuery, params, prepareOptions)
      : useAutocompleteData({
          url,
          params,
          idList,
          idKey: valueKey,
          dataKey,
          prepareOptions,
        });

    // const persistedOptionsParams = useMemo(() => {
    //   return {
    //     id_list: value,
    //   };
    // }, [value]);
    //
    // const {
    //   data: { items: persistedOptions = [] },
    //   loading: loadingPersistedOptions,
    // } = useAutocompleteData(url, persistedOptionsParams);

    const filterOptions = useMemo(
      () =>
        createFilterOptions({
          stringify: option => getOptionLabel(option),
        }),
      [getOptionLabel]
    );

    const getFilterOptions = useCallback(
      (...args) => {
        const filtered = disableFilterOptions
          ? options
          : (overrideFilterOptions && overrideFilterOptions(...args)) ||
            filterOptions(...args);

        return hideSelected
          ? filtered.filter(
              item => !value.find(v => v[valueKey] === item[valueKey])
            )
          : filtered;
      },
      [
        disableFilterOptions,
        filterOptions,
        hideSelected,
        options,
        overrideFilterOptions,
        value,
        valueKey,
      ]
    );

    const { items, append, removeByKey } = useKeyedArray(
      value,
      onChange,
      valueKey
    );

    const onInputChange = useCallback((e, v, reason) => {
      if (reason === 'input') {
        setInnerInputValue(v);
      }
    }, []);

    const handleClearOnBlur = useCallback(() => {
      setInnerInputValue('');
    }, []);

    const Component = (
      <Autocomplete
        ref={ref}
        disabled={disabled}
        popupIcon={null}
        required={required}
        clearOnBlur
        options={options}
        filterSelectedOptions
        blurOnSelect
        isOptionEqualToValue={isOptionEqualToValue(valueKey)}
        filterOptions={getFilterOptions}
        loading={loading}
        renderTags={() => null}
        getOptionLabel={getOptionLabel}
        groupBy={groupBy}
        value={null}
        onBlur={handleClearOnBlur}
        fullWidth={fullWidth}
        InputProps={{
          name,
          label,
          helperText,
          error,
          required,
        }}
        onInputChange={onInputChange}
        inputValue={innerInputValue}
        onChange={(e, value) => {
          if (value !== null) {
            append(value);
            setInnerInputValue('');
          }
        }}
        chips={
          <ChipList
            items={items}
            onChange={onChange}
            removeByKey={removeByKey}
            label={getOptionLabel}
            renderChip={(chip, data) => {
              return (
                <ChipTooltip
                  key={data && data.value}
                  title={
                    <div>
                      <div>{getOptionLabel(data)}</div>
                    </div>
                  }
                >
                  {chip}
                </ChipTooltip>
              );
            }}
          />
        }
      />
    );

    if (proxyValue) {
      return (
        <FancySelectValueProxy
          multiple
          value={value}
          options={options}
          onChange={onChange}
          valueKey={valueKey}
        >
          {Component}
        </FancySelectValueProxy>
      );
    }

    return Component;
  }
);

AutocompleteChips.propTypes = {
  dataKey: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  url: PropTypes.string,
  gqlQuery: PropTypes.string,
  params: PropTypes.object,
  valueKey: PropTypes.string,
  prepareOptions: PropTypes.func,
  proxyValue: PropTypes.bool,
  groupBy: PropTypes.func,
  getOptionLabel: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default AutocompleteChips;
