import { UiOptionsContext } from '../UiOptionsProvider';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getByIndexes } from '../../Table/TableUtils';
import { NestedIndexesContext } from '../../../providers/NestedIndexProvider';
import { DataContext } from '../../../providers/DataProvider';
import BooleanGroupInput from '../../../components/inputs/BooleanGroupInput';
import useInlineEdit from '../../../hooks/UseInlineEdit';
import { LinksContext } from '../../../providers/LinksProvider';

const BooleanGroupField = () => {
  const { value } = useContext(UiOptionsContext) || {};

  const { editLink, key, options } = value;

  const requestPath = useParams();

  const {
    method,
    url,
    operationId,
    query: requestQuery,
    data,
  } = useContext(DataContext);

  const indexes = useContext(NestedIndexesContext);
  const entity = getByIndexes(data, indexes);

  const links = useContext(LinksContext);

  const { isLoading: submitting, edit } = useInlineEdit({
    link: links[editLink],
    entity,
    requestQuery,
    requestPath,
    operationId,
    method,
    url,
  });

  return (
    <BooleanGroupInput
      disabled={submitting}
      onChange={async value => {
        await edit({
          [key]: value,
        });
      }}
      options={options}
      value={value.data}
    />
  );
};

export default BooleanGroupField;
