export const getTableActions = (links, uiSchema = {}) => {
  const actions = [];
  const rowActions = [];

  for (let operationId in links) {
    const actionUiSchema = uiSchema[operationId];
    const link = links[operationId];

    if (actionUiSchema && actionUiSchema.type === 'table_action') {
      actions.push({
        link,
        uiSchema: actionUiSchema,
      });
    } else {
      rowActions.push({
        link,
        uiSchema: actionUiSchema,
      });
    }
  }

  return {
    actions,
    rowActions,
  };
};
