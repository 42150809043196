import { useQuery } from '@apollo/client';
import getGroupsFilters from '../../gql/EventsGroups/queries/getGroupsFilters';

const useGroupsFilters = () => {
  const { data, loading, error } = useQuery(getGroupsFilters);

  let filters;

  if (data) {
    filters = data.getGroupsFilters;
  }

  return { filters, loading, error };
};

export default useGroupsFilters;
