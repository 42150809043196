import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment } from 'react';
import {
  IS_SHOW_FEATURE_BRANCHES,
  SCHEMA_LABELS,
} from '../constants/schemaUrls';
import EnvInput from './EnvInput';
import NavigationMenu from '../v2/components/Navigation/NavigationMenu';
import FeatureBranchInput from '../v2/components/Login/FeatureBranchInput';

const useStyles = makeStyles(theme => ({
  schemaPicker: {
    padding: `0 ${theme.spacing(3)}`,
  },
  branchInoutWrap: {
    padding: `0 ${theme.spacing(3)}`,
    marginTop: -40,
  },
}));

const DrawerMenu = ({ loading, groups, activeRoute, onMenuItemClick }) => {
  const classes = useStyles();

  return (
    <Fragment>
      {IS_SHOW_FEATURE_BRANCHES && (
        <div className={classes.branchInoutWrap}>
          <FeatureBranchInput />
        </div>
      )}
      {SCHEMA_LABELS && (
        <div className={classes.schemaPicker}>
          <EnvInput loading={loading} />
        </div>
      )}
      <NavigationMenu
        onMenuItemClick={() => {
          onMenuItemClick && onMenuItemClick();
        }}
        activeRoute={activeRoute}
        groups={groups}
      />
    </Fragment>
  );
};

export default DrawerMenu;
