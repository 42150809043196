import { useCallback, useMemo, useState } from 'react';
import useLocalStorageTableConfig, {
  tableConfigKeys,
  tableNames,
} from './useLocalStorageTableConfig';

const useTablePagination = ({
  tableName = tableNames.GenericTable,
  onChangeLimit = () => {},
  resetOffsetOnLimitChange = true,
}) => {
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(tableName);

  const tableConfigLimit = getTableConfigValue(tableConfigKeys.limit);

  const [limit, setLimit] = useState(tableConfigLimit || initialLimit);
  const [offset, setOffset] = useState(0);

  const onChangeLimitCb = useCallback(
    limit => {
      setLimit(limit);
      resetOffsetOnLimitChange && setOffset(0);
      onChangeLimit(limit);
      setTableConfigValue(tableConfigKeys.limit, limit);
    },
    [onChangeLimit, resetOffsetOnLimitChange, setTableConfigValue]
  );

  return useMemo(
    () => ({
      setLimit: onChangeLimitCb,
      limit,
      offset,
      setOffset,
    }),
    [limit, onChangeLimitCb, offset, setOffset]
  );
};

export default useTablePagination;
