import React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.overline,
  },
});

const GroupHeading = ({ children, classes }) => (
  <ListSubheader component="div" className={classes.root}>
    {children}
  </ListSubheader>
);

export default withStyles(styles)(GroupHeading);
