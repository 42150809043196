import EmptyField from '../../../../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../../../../components/Fields/LinkField/LinkField';
import StringField from '../../../../../components/Fields/StringField/StringField';
import Table from '../../../../../components/Table/Table';
import useTableColumnsReorder from '../../../../../hooks/useTableColumnsReorder';
import CurrencyField from '../../../../../components/Fields/CurrencyField/CurrencyField';
import { useIsOperator } from '../../../../../providers/OperatorProvider';

export const COLUMN = {
  EVENT_ID: 'eventId',
  EVENT: 'event',
  MARKET: 'market',
  OUTCOME: 'outcome',
  SPECIFIERS: 'specifiers',
  PLAYERS: 'players',
  BETS: 'bets',
  LIABILITY_SINGLE: 'liabilitySingle',
  LIABILITY_COMBO: 'liabilityCombo',
  SPORT: 'sport',
  CATEGORY: 'category',
  TOURNAMENT: 'tournament',
  SPORT_ID: 'sportId',
  TOURNAMENT_ID: 'tournamentId',
  CATEGORY_ID: 'categoryId',
  MARKET_ID: 'marketId',
  OUTCOME_ID: 'outcomeId',
  TURNOVER_EXTENDED: 'turnoverExtended',
  TURNOVER_SINGLE: 'turnoverSingle',
  TURNOVER_COMBO: 'turnoverCombo',
};

export const columnsWidthConfig = {
  [COLUMN.EVENT_ID]: 0.4,
  [COLUMN.EVENT]: 0.8,
  [COLUMN.MARKET]: 0.4,
  [COLUMN.OUTCOME]: 0.3,
  [COLUMN.SPECIFIERS]: 0.5,
  [COLUMN.PLAYERS]: 0.2,
  [COLUMN.BETS]: 0.15,
  [COLUMN.LIABILITY_SINGLE]: 0.35,
  [COLUMN.LIABILITY_COMBO]: 0.36,
  [COLUMN.SPORT]: 0.3,
  [COLUMN.CATEGORY]: 0.25,
  [COLUMN.TOURNAMENT]: 0.3,
  [COLUMN.SPORT_ID]: 0.25,
  [COLUMN.TOURNAMENT_ID]: 0.5,
  [COLUMN.CATEGORY_ID]: 0.5,
  [COLUMN.MARKET_ID]: 0.3,
  [COLUMN.OUTCOME_ID]: 0.6,
  [COLUMN.TURNOVER_EXTENDED]: 0.55,
  [COLUMN.TURNOVER_SINGLE]: 0.5,
  [COLUMN.TURNOVER_COMBO]: 0.5,
};

export const columnsMinWidthConfig = {
  [COLUMN.EVENT_ID]: '100px',
  [COLUMN.EVENT]: '160px',
  [COLUMN.MARKET]: '70px',
  [COLUMN.OUTCOME]: '85px',
  [COLUMN.SPECIFIERS]: '90px',
  [COLUMN.PLAYERS]: '75px',
  [COLUMN.BETS]: '70px',
  [COLUMN.LIABILITY_SINGLE]: '125px',
  [COLUMN.LIABILITY_COMBO]: '125px',
  [COLUMN.SPORT]: '65px',
  [COLUMN.CATEGORY]: '85px',
  [COLUMN.TOURNAMENT]: '100px',
  [COLUMN.MARKET_ID]: '85px',
  [COLUMN.OUTCOME_ID]: '100px',
  [COLUMN.SPORT_ID]: '75px',
  [COLUMN.TOURNAMENT_ID]: '155px',
  [COLUMN.CATEGORY_ID]: '155px',
  [COLUMN.TURNOVER_EXTENDED]: '140px',
  [COLUMN.TURNOVER_SINGLE]: '130px',
  [COLUMN.TURNOVER_COMBO]: '130px',
};

export const columnsOrderConfig = [
  COLUMN.EVENT_ID,
  COLUMN.EVENT,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.SPECIFIERS,
  COLUMN.PLAYERS,
  COLUMN.BETS,
  COLUMN.LIABILITY_SINGLE,
  COLUMN.LIABILITY_COMBO,
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.SPORT_ID,
  COLUMN.TOURNAMENT_ID,
  COLUMN.CATEGORY_ID,
  COLUMN.MARKET_ID,
  COLUMN.OUTCOME_ID,
  COLUMN.TURNOVER_EXTENDED,
  COLUMN.TURNOVER_SINGLE,
  COLUMN.TURNOVER_COMBO,
];

export const initialToggledColumns = [
  COLUMN.EVENT_ID,
  COLUMN.EVENT,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.SPECIFIERS,
  COLUMN.PLAYERS,
  COLUMN.BETS,
  COLUMN.LIABILITY_SINGLE,
  COLUMN.LIABILITY_COMBO,

  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.SPORT_ID,
  COLUMN.CATEGORY_ID,
  COLUMN.TOURNAMENT_ID,
  COLUMN.CATEGORY_ID,

  COLUMN.MARKET_ID,
  COLUMN.OUTCOME_ID,
  COLUMN.TURNOVER_EXTENDED,
  COLUMN.TURNOVER_SINGLE,
  COLUMN.TURNOVER_COMBO,
];

const disabledColumnsForOperator = [
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.SPORT_ID,
  COLUMN.CATEGORY_ID,
  COLUMN.TOURNAMENT_ID,
];

const MarketLiabilityTable = ({
  data,
  loading,
  rowActions,
  toggledColumns: initialToggledColumns,
  orderBy,
  onChangeOrderBy,
}) => {
  const isOperator = useIsOperator();

  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Event ID',
      key: COLUMN.EVENT_ID,
      render: ({ eventId }) => {
        return eventId ? (
          <StringField title={eventId}>
            <LinkField href={`/events/${eventId}`} copy>
              {eventId}
            </LinkField>
          </StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'event_id',
    },
    {
      label: 'Event',
      key: COLUMN.EVENT,
      render: ({ event }) => {
        return event ? (
          <StringField title={event}>{event}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'event',
    },
    {
      label: 'Market',
      key: COLUMN.MARKET,
      render: ({ market }) => {
        return market ? (
          <StringField title={market}>{market}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'market',
    },
    {
      label: 'Outcome',
      key: COLUMN.OUTCOME,
      render: ({ outcome }) => {
        return outcome ? (
          <StringField title={outcome}>{outcome}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'outcome',
    },
    {
      label: 'Specifiers',
      key: COLUMN.SPECIFIERS,
      render: ({ specifiers }) => {
        return specifiers ? (
          <StringField title={specifiers}>{specifiers}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'specifiers',
    },
    {
      label: 'Players',
      key: COLUMN.PLAYERS,
      justifyContent: 'flex-end',
      render: ({ players }) => {
        return players ? <StringField>{players}</StringField> : <EmptyField />;
      },
      sortkey: 'players',
    },
    {
      label: 'Bets',
      key: COLUMN.BETS,
      justifyContent: 'flex-end',
      render: ({ bets, eventId, marketId, outcomeId }) => {
        return bets ? (
          <LinkField
            href={`/api/v1/ReporterAPI/report/outcome/detail/${eventId}/${marketId}/${outcomeId}/get`}
          >
            {bets}
          </LinkField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'bets',
    },
    {
      label: 'Liability Single',
      key: COLUMN.LIABILITY_SINGLE,
      justifyContent: 'flex-end',
      render: ({ liabilitySingle }) => {
        return liabilitySingle ? (
          <CurrencyField amount={liabilitySingle} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'liability_single',
    },
    {
      label: 'Liability Combo',
      key: COLUMN.LIABILITY_COMBO,
      justifyContent: 'flex-end',
      render: ({ liabilityCombo }) => {
        return liabilityCombo ? (
          <CurrencyField amount={liabilityCombo} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'liability_combo',
    },
    {
      label: 'Sport',
      key: COLUMN.SPORT,
      render: ({ sport }) => {
        return sport ? <StringField>{sport}</StringField> : <EmptyField />;
      },
      sortkey: 'sport',
    },
    {
      label: 'Category',
      key: COLUMN.CATEGORY,
      render: ({ category }) => {
        return category ? (
          <StringField>{category}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'category',
    },
    {
      label: 'Tournament',
      key: COLUMN.TOURNAMENT,
      render: ({ tournament }) => {
        return tournament ? (
          <StringField title={tournament}>{tournament}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'tournament',
    },
    {
      label: 'Sport Id',
      key: COLUMN.SPORT_ID,
      render: ({ sportId }) => {
        return sportId ? <StringField>{sportId}</StringField> : <EmptyField />;
      },
      sortkey: 'sport_id',
    },
    {
      label: 'Tournament Id',
      key: COLUMN.TOURNAMENT_ID,
      render: ({ tournamentId }) => {
        return tournamentId ? (
          <StringField title={tournamentId}>{tournamentId}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'tournament_id',
    },
    {
      label: 'Category Id',
      key: COLUMN.CATEGORY_ID,
      render: ({ categoryId }) => {
        return categoryId ? (
          <StringField title={categoryId}>{categoryId}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'category_id',
    },
    {
      label: 'Market Id',
      key: COLUMN.MARKET_ID,
      render: ({ marketId }) => {
        return marketId ? (
          <StringField>{marketId}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'market_id',
    },
    {
      label: 'Outcome Id',
      key: COLUMN.OUTCOME_ID,
      render: ({ outcomeId }) => {
        return outcomeId ? (
          <StringField title={outcomeId}>{outcomeId}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'outcome_id',
    },
    {
      label: 'Turnover (Extended)',
      key: COLUMN.TURNOVER_EXTENDED,
      render: ({ turnoverExtended }) => {
        return turnoverExtended ? (
          <CurrencyField amount={turnoverExtended} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'turnover_extended',
    },
    {
      label: 'Turnover (Single)',
      key: COLUMN.TURNOVER_SINGLE,
      render: ({ turnoverSingle }) => {
        return turnoverSingle ? (
          <CurrencyField amount={turnoverSingle} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'turnover_single',
    },
    {
      label: 'Turnover (Combo)',
      key: COLUMN.TURNOVER_COMBO,
      render: ({ turnoverCombo }) => {
        return turnoverCombo ? (
          <CurrencyField amount={turnoverCombo} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'turnover_combo',
    },
  ].map(column => ({ ...column, resizable: true }));

  return (
    <Table
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      minWidthConfig={columnsMinWidthConfig}
      columns={columns}
      rowActions={rowActions}
      orderBy={orderBy}
      onChangeOrderBy={onChangeOrderBy}
      scrollable={true}
      disabledColumns={isOperator && disabledColumnsForOperator}
    />
  );
};

export default MarketLiabilityTable;
