import { Box } from '@mui/material';
import SportField from '../../../Fields/SportField/SportField';
import useTreeSelectStyles from '../styles/TreeSelect.styles';

const SportOption = ({
  name,
  id,
  selected,
  withTooltip = true,
  onClick = () => {},
}) => {
  const classes = useTreeSelectStyles();

  return (
    <Box className={classes.sportOptionContainer} onClick={onClick}>
      <SportField
        bigIcon
        withTooltip={withTooltip}
        name={name && name}
        white={selected}
        id={id}
        captionClassName={classes.sportOptionCaption}
      />
    </Box>
  );
};

export default SportOption;
