import { useEffect } from 'react';
import { asNumber } from 'react-jsonschema-form/lib/utils';

const useCorrectDateEffect = (value, onChange) =>
  useEffect(() => {
    if (value && typeof value !== 'number') {
      const numberValue = asNumber(value);

      onChange(typeof numberValue === 'undefined' ? null : numberValue);
    }
  }, [value, onChange]);

export default useCorrectDateEffect;
