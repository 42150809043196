import { createContext, useContext } from 'react';

export const TableExpandRowContext = createContext({});

export const useTableExpandRowContext = () => {
  return useContext(TableExpandRowContext);
};

const TableExpandRowProvider = ({ value, children }) => {
  return (
    <TableExpandRowContext.Provider value={value}>
      {children}
    </TableExpandRowContext.Provider>
  );
};

export default TableExpandRowProvider;
