import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { ConfirmationPromptContext } from '../../../../providers/ConfirmationPromptProvider';
import { OrderDirection } from '../../../components/Table/TableHeaderColumn';
import cancelBetslips from '../../../gql/Reports/mutations/cancelBetslips';
import getBetHistoryList from '../../../gql/Reports/queries/getBetHistoryList';
import getOperatorBetHistoryList from '../../../gql/Reports/queries/getOperatorBetHistoryList';
import {
  useTableOrderByChange,
  useTablePagination,
  useTablePresetPagination,
} from '../../../hooks';
import useExportActionCard from '../../../hooks/useExportActionCard';
import { withoutEmpty } from '../../../hooks/usePresetFilters';
import usePresetsActionCard from '../../../hooks/usePresetsActionCard';
import { BET_STATUS, TEST_PLAYER } from '../BetHistory';
import { getBeginningOfMonth } from '../utils/getBeginningOfMonth';
import useBetHistoryFilters from './useBetHistoryFilters';
import useCancelationNotifications from './useCancelationNotifications';
import { useApplyRequired } from '../../../providers/ApplyRequiredProvider';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';

const useBetHistory = ({
  eventId,
  withPresets = false,
  withExportCSV = false,
  isOperator,
  isCancelBetsAllowed,
}) => {
  const { openConfirmation } = useContext(ConfirmationPromptContext);
  const { isApplyRequired } = useApplyRequired(tableNames.BetHistory);

  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange({
    initialOrderBy: 'bet_timestamp',
    initialOrderByRotation: OrderDirection.DESC,
  });

  const { limit, setLimit, offset, setOffset } = withPresets
    ? useTablePresetPagination({ tableName: tableNames.BetHistory })
    : useTablePagination({
        tableName: tableNames.BetHistory,
      });

  const [
    cancelBetslipsCb,
    { loading: cancelBetslipsLoading, error: cancelBetslipsError },
  ] = useMutation(cancelBetslips, {
    refetchQueries: ['getBetHistoryList'],
  });
  const { cancelationNotification, errorNotification } =
    useCancelationNotifications();

  const presetsActionCard = usePresetsActionCard({
    tableName: tableNames.BetHistory,
    spanMin: 1,
  });
  const exportActionCard = useExportActionCard();

  const refetchHistoryRef = useRef(() => {});

  const refetchHistory = useCallback(() => {
    return refetchHistoryRef.current();
  }, []);

  const customDefaultBetDate = useMemo(() => {
    return (
      withPresets && {
        rangeFrom: getBeginningOfMonth() || null,
        rangeTo: null,
      }
    );
  }, [withPresets]);

  const { filtersCard, filters } = useBetHistoryFilters({
    eventId,
    withPresets,
    resetOffset: setOffset,
    onResubmit: refetchHistory,
    isOperator,
    customDefaultBetDate,
  });

  const inputFilters = useMemo(() => {
    const preparedFilters = filters => {
      const { excludeFake, ...queryFilters } = filters;

      //we also handle it inside TestPlayersFilterBetHistory, but we have case when this component not mounted (testPlayers filter hidden)
      if (
        (queryFilters.filterPlayerId?.length > 0 ||
          queryFilters.filterExtPlayerId?.length > 0) &&
        queryFilters.testPlayers !== TEST_PLAYER.INCLUDE
      ) {
        queryFilters.testPlayers = TEST_PLAYER.INCLUDE;
      }

      if (!queryFilters.rBetDate && customDefaultBetDate) {
        queryFilters.rBetDate = customDefaultBetDate;
      }

      return withoutEmpty(queryFilters);
    };

    return {
      filterEventId: eventId,
      limit,
      offset,
      orderBy,
      orderByAscDesc,
      ...preparedFilters(filters),
    };
  }, [
    eventId,
    limit,
    filters,
    offset,
    orderBy,
    orderByAscDesc,
    customDefaultBetDate,
  ]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(isOperator ? getOperatorBetHistoryList : getBetHistoryList, {
    variables: {
      inputFilters,
    },
    fetchPolicy: 'no-cache',
    skip: isApplyRequired,
  });

  refetchHistoryRef.current = refetch;

  const actions = useMemo(() => {
    let result = [filtersCard];

    if (withPresets) {
      result = [presetsActionCard, ...result];
    }

    if (withExportCSV) {
      result = [exportActionCard, ...result];
    }

    return result;
  }, [
    filtersCard,
    presetsActionCard,
    withPresets,
    withExportCSV,
    exportActionCard,
  ]);

  const items = useMemo(() => {
    return (data && data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  const betslipCancelConfiguration = data => {
    openConfirmation({
      buttonLabel: 'Cancel Bet',
      title: 'Betslip cancel',
      message: `Do you want to cancel this bet?`,
      callback: async () => {
        cancelBetslipsCb({
          variables: {
            betslipsToCancel: [
              {
                playerId: data.playerId,
                betId: data.betId,
              },
            ],
          },
        })
          .then(({ data: { data: responseData } = {} }) => {
            const isBetCanceled =
              data.betStatus === BET_STATUS.CANCELED ||
              data.betStatus === BET_STATUS.REJECTED;
            const alreadyCanceled = isBetCanceled ? 1 : 0;
            const betsCount = alreadyCanceled ? 0 : 1;

            cancelationNotification({
              data: responseData,
              betsCount,
              alreadyCanceled,
            });
          })
          .catch(errorNotification);
      },
    });
  };

  const rowActions = isCancelBetsAllowed && [
    {
      key: 'cancelBet',
      label: 'Betslip Cancel',
      onClick: betslipCancelConfiguration,
    },
  ];

  return {
    cancelBetslipsError,
    items,
    loading,
    error,
    rowActions,
    orderBy,
    onChangeOrderBy,
    limit,
    offset,
    setLimit,
    setOffset,
    total,
    actions,
  };
};

export default useBetHistory;
