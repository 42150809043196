export const RESPONSE_TYPE_ENUM = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

const getResponseTypeByCode = code => {
  switch (code) {
    case 400:
      return RESPONSE_TYPE_ENUM.WARNING;
    case 500:
      return RESPONSE_TYPE_ENUM.ERROR;
    default:
      return RESPONSE_TYPE_ENUM.INFO;
  }
};

export default getResponseTypeByCode;
