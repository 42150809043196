import { useCallback, useMemo, useState } from 'react';

const useTableOrderByChange = ({
  initialOrderBy = [],
  initialOrderByRotation = 'DESC',
} = {}) => {
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [orderByAscDesc, setOrderByAscDesc] = useState(initialOrderByRotation);

  const onChangeOrderBy = useCallback(
    value => {
      const columnKey = value.field;
      const newDirection = value.direction;
      setOrderBy([columnKey]);
      setOrderByAscDesc(newDirection);
    },
    [setOrderBy, setOrderByAscDesc]
  );

  return useMemo(
    () => ({
      onChangeOrderBy,
      orderBy,
      orderByAscDesc,
    }),
    [onChangeOrderBy, orderBy, orderByAscDesc]
  );
};

export default useTableOrderByChange;
