import { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import {
  getPathByOperationId,
  getResponseSchemas,
  getXStreamByPath,
} from '../utils/schemaHelper';
import WebsocketWorker from '../workers/WebsocketWorker';
import * as Comlink from 'comlink';
import { useRawSchema } from '../providers/RawSchemaProvider';
import useSearch from './useSearch';
import getQuery from '../utils/getQuery';

const useWebSocket = (url, onChannelMessage, onCmdMessage, onUiComponent) => {
  const [socketStatus, setSocketStatus] = useState(null);
  const connectionRef = useRef(null);
  const { routes, loading: isSchemaLoading } = useRawSchema();
  const search = useSearch();
  const query = useMemo(() => {
    return getQuery(search);
  }, [search]);

  const onMessage = useCallback(
    data => {
      if (data.channel) {
        const path = getPathByOperationId(routes, data.channel);
        const xStream = getXStreamByPath(routes, path);
        const { uiSchema: responseUiSchema, schema: responseSchema } =
          getResponseSchemas(xStream);

        if (
          responseSchema &&
          responseUiSchema &&
          responseUiSchema['ui:component'] &&
          responseUiSchema['ui:component'] === 'NotificationPopup'
        ) {
          return onUiComponent(data, responseSchema, responseUiSchema, path);
        }

        return onChannelMessage(
          {
            ...data,
          },
          routes,
          query
        );
      }

      if (data.command) {
        return onCmdMessage(data.command, data.payload);
      }
    },
    [routes, onChannelMessage, query, onUiComponent, onCmdMessage]
  );

  useEffect(() => {
    WebsocketWorker.updateCallback(Comlink.proxy(onMessage));
  }, [onMessage]);

  useEffect(() => {
    if (!isSchemaLoading) {
      WebsocketWorker.open(
        url,
        Comlink.proxy(onMessage),
        Comlink.proxy(setSocketStatus)
      );
    }

    return () => {
      WebsocketWorker.close();
    };
    // eslint-disable-next-line
  }, [url, isSchemaLoading]);

  const sendSocketMessage = useCallback(data => {
    connectionRef.current && connectionRef.current.sendMessage(data);
  }, []);

  return {
    socketStatus,
    sendSocketMessage,
  };
};

export default useWebSocket;
