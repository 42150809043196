import JSONSchemaForm from 'react-jsonschema-form';
import FieldTemplate from '../containers/Form/templates/FieldTemplate';
import ObjectFieldTemplate from '../containers/Form/templates/ObjectFieldTemplate';
import ArrayOfFieldsTemplate from '../containers/Form/templates/ArrayOfFieldsTemplate';
import widgets from './DynamicForm/widgets';
import fields from './DynamicForm/fields';
import Button from '../v2/components/Button/Button';
import React, { useCallback, useState } from 'react';
import { useUpdate } from '../uiComponents/withUpdate';
import { useDispatch } from 'react-redux';
import { login } from '../actions/authActions';
import { getLinksAndSchemas } from '../utils/schemaHelper';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { processUpdate } from '../actions/dataActions';
import { useApiHost } from '../providers/ApiHostProvider';

const useStyles = makeStyles({
  loginFormActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0',
  },
});

const LoginForm = ({ route, routes }) => {
  const classes = useStyles();
  const { apiHost } = useApiHost('data fetcher');
  const { schema, uiSchema } = getLinksAndSchemas(routes, route);
  const [submitting, setSubmitting] = useState(false);

  const { updateData } = useUpdate(route);
  const dispatch = useDispatch();
  const loginCb = useCallback(
    data => {
      dispatch(
        login(data, {
          routes,
          apiHost,
        })
      );
    },
    [dispatch, routes, apiHost]
  );

  const processUpdateCb = useCallback(
    data =>
      dispatch(
        processUpdate(data, {
          routes,
          apiHost,
        })
      ),
    [dispatch, routes, apiHost]
  );

  const onLoginSubmit = useCallback(
    async data => {
      setSubmitting(true);
      const result = await updateData(data);

      await processUpdateCb({ data: result, path: route });

      setSubmitting(false);
      loginCb(result);
    },
    [loginCb, updateData, processUpdateCb, setSubmitting, route]
  );

  return (
    <JSONSchemaForm
      uiSchema={uiSchema}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      ArrayFieldTemplate={ArrayOfFieldsTemplate}
      widgets={widgets}
      fields={fields}
      schema={schema}
      onSubmit={data => {
        onLoginSubmit(data.formData);
      }}
      showErrorList={false}
    >
      <div className={classes.loginFormActions}>
        <Button
          disabled={submitting}
          variant={'contained'}
          type="submit"
          color={'primary'}
        >
          Login
          {submitting && (
            <>
              &nbsp;
              <CircularProgress size={16} />
            </>
          )}
        </Button>
      </div>
    </JSONSchemaForm>
  );
};

export default LoginForm;
