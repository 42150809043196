import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '../../../../icons/controls/CheckBoxIcon';
import CheckBoxActiveIcon from '../../../../icons/controls/CheckBoxActiveIcon';
import CheckboxIndeterminateIcon from '../../../../icons/controls/CheckboxIndeterminateIcon';
import React, { useCallback } from 'react';
import { animated } from 'react-spring';
import HighlightedLabel from '../../../../components/Autocomplete/HighlightedLabel';
import clsx from 'clsx';

const useStyles = makeStyles({
  item: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    padding: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,

    '&:last-child': {
      marginBottom: 8,
    },
  },

  itemContent: {
    overflowWrap: 'anywhere',
  },

  itemActions: {},

  itemTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    fontWeight: 500,
  },

  itemDescription: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#8496AB',
    marginTop: 4,
    fontFamily: 'Courier Prime',
  },
});

const TransferListItem = React.memo(
  ({
    style,
    title,
    description,
    toggled = false,
    setToggled,
    searchQuery,
    value,
    classes: classesOverrides = {},
  }) => {
    const classes = useStyles();

    const onToggleHandle = useCallback(
      e => {
        setToggled(value, e.target.checked);
      },
      [setToggled, value]
    );

    return (
      <animated.div
        className={clsx(classes.item, classesOverrides.root)}
        style={style}
      >
        <div className={classes.itemContent}>
          <div className={classes.itemTitle}>
            <HighlightedLabel text={title} query={searchQuery} />
          </div>
          <div className={classes.itemDescription}>
            <HighlightedLabel text={description} query={searchQuery} />
          </div>
        </div>
        <div className={classes.itemActions}>
          <Checkbox
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxActiveIcon />}
            indeterminateIcon={<CheckboxIndeterminateIcon />}
            checked={toggled}
            onChange={onToggleHandle}
          />
        </div>
      </animated.div>
    );
  }
);

export default TransferListItem;
