import makeStyles from '@mui/styles/makeStyles';
import EventPrematchPendingIconSvg from '../../v2/icons/EventPrematchPendingIcon.svg';

const useStyles = makeStyles({
  prematchIcon: {
    backgroundImage: `url(${EventPrematchPendingIconSvg})`,
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },
});

const EventPrematchPendingIcon = ({ ...props }) => {
  const classes = useStyles();
  return <div {...props} className={classes.prematchIcon} />;
};

export default EventPrematchPendingIcon;
