import { gql } from '@apollo/client';

const SPORTS_AUTOCOMPLETE_QUERY = gql`
  query getSports($query: String, $limit: Int) {
    data: getSports(name: $query, limit: $limit) {
      id
      name
    }
  }
`;

export default SPORTS_AUTOCOMPLETE_QUERY;
