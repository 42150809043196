import { forwardRef } from 'react';
import getAutoEvents from '../../../gql/Promo/queries/getAutoEvents';
import AutocompleteChips from '../AutocompleteChips/AutocompleteChips';

const EventTypeEnum = {
  EVENT: 'event',
  TOURNAMENT: 'tournament',
};

const EventTypeLabel = {
  [EventTypeEnum.EVENT]: 'Events',
  [EventTypeEnum.TOURNAMENT]: 'Tournaments',
};

const AutoEventsAutocomplete = forwardRef(
  ({ multiple = false, onChange, value, ...props }, ref) => {
    return (
      <AutocompleteChips
        ref={ref}
        groupBy={({ item_type }) => item_type}
        gqlQuery={getAutoEvents}
        valueKey={'id'}
        getOptionLabel={({ label }) => label}
        onChange={onChange}
        value={value}
        proxyValue={false}
        disableFilterOptions
        {...props}
      />
    );
  }
);

export default AutoEventsAutocomplete;
