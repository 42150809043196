import { isBoolean, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearPath, getKeySelector } from '../../../utils/getKeySelector';

export const useCoverageTemplateOption = ({
  itemValue = {},
  options = [],
  optionsFullListRef,
  nodeSelector = {},
  getFormValues = () => {},
  setFormValue = () => {},
  setDisplayOptions = () => {},
  tabKey,
  idKey,
  inheritStatus,
}) => {
  const lineType = getFormValues('configLineType');

  const keySelector = getKeySelector({ nodeSelector, lineType });

  const [checked, setChecked] = useState(itemValue.status);
  const selectOptions = tabKey ? options[tabKey] : options;
  const defaultOption = selectOptions.find(
    item => item[idKey] === itemValue[idKey]
  );

  //we have to create configs for 'sport' tab and 'category'
  //status need to be same in two tabs, but priority can be different
  const saveTournamentStatus = useCallback(
    newStatus => {
      const template = getFormValues('template');
      const excludedCategorySelector = omit(nodeSelector, 'categoryId');

      const sportKeySelector = getKeySelector({
        nodeSelector: excludedCategorySelector,
        lineType,
        tabKey: 'sport',
      });

      const categoryKeySelector = getKeySelector({
        nodeSelector: excludedCategorySelector,
        lineType,
        tabKey: 'category',
      });

      const currentCategoryTournamentConfig =
        template[categoryKeySelector] || {};
      const currentSportTournamentConfig = template[sportKeySelector] || {};

      setFormValue(`template.${sportKeySelector}`, {
        lineType,
        nodeSelector: clearPath(excludedCategorySelector),
        status: newStatus,
        priority: currentSportTournamentConfig.priority || undefined,
      });
      setFormValue(`template.${categoryKeySelector}`, {
        lineType,
        nodeSelector: {
          ...nodeSelector,
          categoryId: itemValue.categoryId || nodeSelector.categoryId,
        },
        status: newStatus,
        priority: currentCategoryTournamentConfig.priority || undefined,
      });
    },
    [getFormValues, itemValue, nodeSelector, setFormValue, lineType]
  );

  const onChangeStatus = useCallback(
    value => {
      const template = getFormValues('template');

      const newStatus = value;
      const config = template[keySelector] || {};

      setChecked(value);

      setDisplayOptions(currentOptions => {
        const newOptions = structuredClone(currentOptions);

        newOptions.forEach((value, index) => {
          if (value[idKey] === itemValue[idKey]) {
            newOptions[index].status = newStatus;
          }
        });

        //in displayOptions can be filtered list, than we need to find and change curr option in the full list
        optionsFullListRef.current = optionsFullListRef.current.map(value => {
          if (value[idKey] === itemValue[idKey]) {
            return {
              ...value,
              status: newStatus,
            };
          }
          return value;
        });

        return newOptions;
      });
      if (idKey === 'tournamentId') {
        saveTournamentStatus(newStatus);
      } else {
        setFormValue(`template.${keySelector}`, {
          lineType,
          nodeSelector,
          priority: config.priority || undefined,
          status: newStatus === defaultOption.status ? undefined : newStatus,
        });
      }
    },
    [
      lineType,
      idKey,
      itemValue,
      keySelector,
      nodeSelector,
      defaultOption.status,
      optionsFullListRef,
      saveTournamentStatus,
      setDisplayOptions,
      setFormValue,
      getFormValues,
    ]
  );

  // we need not to save the config if the item status id disabled by parent
  useEffect(() => {
    const template = getFormValues('template');
    const currentConfig = template[keySelector];

    if (
      (idKey === 'categoryId' || idKey === 'tournamentId') &&
      currentConfig &&
      isBoolean(inheritStatus)
    ) {
      currentConfig.ignoreStatus = !inheritStatus;
      setFormValue(`template.${keySelector}`, currentConfig);
    }
  }, [inheritStatus, getFormValues, setFormValue, idKey, keySelector]);

  return useMemo(
    () => ({ checked, setChecked, onChangeStatus }),
    [checked, setChecked, onChangeStatus]
  );
};
